import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConfirmationService, Message, MessageService } from 'primeng/api';
import { EmailRequestDto, EmailRequestNewDto } from 'src/app/api/email-request';
import { Product, User } from 'src/app/api/product';
import { ProductSDLC, ProductSDLCPutDto, ProductSDLCRequestDto, SDLCPrimaryContact } from 'src/app/api/product-sdlc';
import { EmailTemplateService } from 'src/app/layout/service/email/email-template.service';
import { GraphExplorerService } from 'src/app/layout/service/graphexplorer/graph-explorer.service';
import { LogService } from 'src/app/layout/service/log.service';
import { EmailService } from 'src/app/layout/service/product/email.service';
import { ProductSDLCService } from 'src/app/layout/service/product/product-sdlc.service';
import { ProductService } from 'src/app/layout/service/product/products.service';
import { ToEmailService } from 'src/app/layout/service/product/to-email.service';
import { ProductSelectionService } from 'src/app/product.selection.service';

@Component({
  selector: 'app-dast',
  templateUrl: './dast.component.html',
  styleUrls: ['./dast.component.scss'],
})
export class DASTComponent implements OnInit {

  productId: string = '';
  productName: string = '';
  productReference: string = '';
  
  isLoading = true;
  
  tier: string = '';
  selectedProduct: Product | null = null;

  productSDLCDAST: ProductSDLC[] = [];
  editedProductSDLC: ProductSDLCPutDto = {};
  productDAST: ProductSDLCRequestDto = {};

  filteredProductSDLCDAST: ProductSDLC[] = [];

  productSDLCTools: any[] = [];
  productSDLCRequestStatuses: any[] = [];
  productSDLCPracticeStatuses: any[] = [];
  productSDLCEnvironments: any[] = [];
  productSDLCScanTypes: any[] = [];

  hasSelectedOption: boolean = false;
  selectedDastId: number | undefined;
  selectedDastOption: ProductSDLCPutDto = {};

  status: any[] = [];
  selectedStatus: string | null = null;

  submitted: boolean = false;
  dastDialog: boolean = false;

  dastSDLCPracticeId: number | undefined;

  messages: Message[] = [];
  messages2: Message[] = [];
  messages3: Message[] = [];

  hasApprovedDASTMessage: boolean = false;

  userDialog: boolean = false;
  users: User[] = [];
  sdlcPrimaryContacts: SDLCPrimaryContact[] = [];

  selectedUser: User | null = null;

  sdlcNewDastContacts: User[] = [];
  userDialogAction: string = '';
  message = '';

  constructor(private route: ActivatedRoute, private productSDLCService: ProductSDLCService, private messageService: MessageService,
    private productService: ProductService, private graphExplorerService: GraphExplorerService,
    private confirmationService: ConfirmationService, 
    private emailService: EmailService, private productSelectionService: ProductSelectionService, private toEmailService: ToEmailService,
    private logService: LogService, private emailTemplateService: EmailTemplateService) { }

    ngOnInit() {
      this.route.params.subscribe(params => {
        this.productId = params['id'];
  
        this.productSelectionService.getSelectedProduct().subscribe((product) => {
          this.productName = product?.Name || '';
          this.productReference = product?.ProductRef || '';
        });

        this.fetchDASTData();
      });
  
      this.productSDLCService.getAllSDLCTools("DAST").subscribe((sdlcTools) => {
        this.productSDLCTools = sdlcTools.slice().sort((a, b) => {
            if (a.Name === 'Tenable WAS') return -1;
            if (b.Name === 'Tenable WAS') return 1;
            return 0; // Keep the order for other options
        });
    });
    
  
      this.productSDLCService.getAllSDLCRequestStatuses().subscribe((sdlcRequestStatus) => {
        this.productSDLCRequestStatuses = sdlcRequestStatus;
      });

      this.productSDLCService.getAllSDLCEnvironments().subscribe((sdlcEnvironments) => {
        this.productSDLCEnvironments = sdlcEnvironments;
      });

      this.productSDLCService.getAllSDLCNPDASTScanTypes().subscribe((sdlcScanTypes) => {
        this.productSDLCScanTypes = sdlcScanTypes;
      });      
  
      // this.productSDLCService.getAllSDLCPracticeMaturityLevels().subscribe((sdlcPracticeStatus) => {
      //   this.productSDLCPracticeStatuses = sdlcPracticeStatus;
      //   console.log(this.productSDLCPracticeStatuses);
      // });

      
      this.productSDLCService.getSDLCPractice("DAST").subscribe((sdlcPractice) => {
        if(sdlcPractice.length > 0){
          this.dastSDLCPracticeId = sdlcPractice[0].Id;
        }
          this.productSDLCService.getMaturityLevelByPracticeId(this.dastSDLCPracticeId).subscribe((sdlcPracticeStatus) => {
            this.productSDLCPracticeStatuses = sdlcPracticeStatus;
            this.sortOptions();
          });
      });
        
      this.productService.getProductById(this.productId).subscribe((product) => {
        this.selectedProduct = product;
        this.tier = this.selectedProduct?.ProductMetric?.TierName || '';
        this.isLoading = false;
      });

      this.getAllUsers();
  
      this.messages = [{ severity: 'info', summary: 'Information', detail: 'Select DAST from the menu to view' }];
      this.messages2 = [{ severity: 'warn', summary: 'Warning', detail: 'Currently not following the DAST Practice! Please request or wait for approval.' }];
    }

    sortOptions() {
      this.productSDLCPracticeStatuses.sort((a, b) => a.Order - b.Order);
    }

    getAllUsers() {
      this.graphExplorerService.getAllADUsers().subscribe((users) => {
        this.users = users;
      });
    }

    searchUsers(event?: any) {
      if (event.filter) {
        this.graphExplorerService.searchADUsers(event.filter).subscribe((users) => {
          this.users = users;
        });
      } else {
        this.getAllUsers();
      }
    }

    getPracticeStatusNameById(id: string): string {
      const status = this.productSDLCPracticeStatuses.find(status => status.Id === id);
      return status ? status.Name : '';
    }
  
    getToolNameById(toolId: string | undefined): string {
      const selectedTool = this.productSDLCTools.find(tool => tool.Id === toolId);
      return selectedTool ? selectedTool.Name : '';
    }

    getEnvironmentNameById(environmentId: number): string {
      const selectedEnvironment = this.productSDLCEnvironments.find(environment => environment.Id === environmentId);
      return selectedEnvironment ? selectedEnvironment.Name : '';
    }

    getScanTypeNameById(scanTypeId: number | undefined): string {
      const selectedScanType = this.productSDLCScanTypes.find(scanType => scanType.Id === scanTypeId);
      return selectedScanType ? selectedScanType.Name : '';
    }

    isTenableWASSelected = false;

    onToolChange(event: any) {
      const toolName = this.getToolNameById(this.productDAST.SDLCToolId);
      this.isTenableWASSelected = toolName === 'Tenable WAS';
      if (this.isTenableWASSelected) {
        this.productDAST.SDLCNPDastScanTypeId = 2; // Set to 'Authenticated'
        this.messages3 = [];
      } else {
        this.productDAST.SDLCNPDastScanTypeId = undefined;
        this.messages3 = [{ severity: 'info', summary: 'Optional Tool Selected', detail: 'Please note this tool is optional. Tenable WAS is a requirement.' }];
      }
    }

    showDastDetails(selectedDast: ProductSDLC) {
      // Set the selected DAST data to be displayed in the second splitter form
      this.selectedDastOption = selectedDast;
      this.hasSelectedOption = true;
      this.selectedDastOption.EnrolDate = this.selectedDastOption.EnrolDate ? new Date(this.selectedDastOption.EnrolDate) : undefined;
      this.selectedDastOption.NextReviewDate = this.selectedDastOption.NextReviewDate ? new Date(this.selectedDastOption.NextReviewDate) : undefined;
  
      this.selectedDastId = selectedDast.Id;
      this.editedProductSDLC = {...this.selectedDastOption};

      this.fetchProductSDLCContacts();
    }

    fetchProductSDLCContacts() {
      if (this.selectedDastId) {
        this.productSDLCService.getSDLCPrimaryContacts(this.selectedDastId).subscribe((sdlcPrimaryContacts) => {
          this.sdlcPrimaryContacts = sdlcPrimaryContacts;
          // I need this.sdlcPrimaryContacts to only contain the 
        });
      }
    }

    fetchDASTData(shouldReset: boolean = true, setSelectedStatus?: string | null) {
      this.productSDLCService.getProductSDLCDAST(this.productId).subscribe((sdlcDAST) => {
        if (sdlcDAST && sdlcDAST.value.length > 0){
          this.productSDLCDAST = sdlcDAST.value;
          this.filteredProductSDLCDAST = this.productSDLCDAST;
  
          const hasApprovedDAST = this.productSDLCDAST.some(dast => dast.SDLCRequestStatusName === 'Approved');

          if (setSelectedStatus) {
            this.selectedStatus = this.selectedStatus;
            this.onStatusChange(shouldReset);
          }
  
          if (hasApprovedDAST && !setSelectedStatus) {
            this.selectedStatus = 'Approved';
            this.hasApprovedDASTMessage = true;
            this.onStatusChange(shouldReset);
          } else {
            this.selectedStatus = 'Requested';
            this.onStatusChange(shouldReset);
          }

          this.showDastDetails(this.filteredProductSDLCDAST[0]);

        }
      });
      this.selectedStatus = 'Requested';
    }

    openNew() {
      this.productDAST = {};
      this.submitted = false;
      this.dastDialog = true;
    }
  
    hideDialog() {
      this.dastDialog = false;
      this.submitted = false;
      this.sdlcNewDastContacts = [];
    }

    openUserDialog(action: string) {
      this.userDialogAction = action;
      this.userDialog = true;
    }
  
    hideUserDialog() {
      this.userDialog = false;
      this.selectedUser = null;
    }
  
    saveNewDAST() {
      this.submitted = true;
  
      if (this.productDAST.SDLCToolId) {
        this.productDAST.ProductId = this.productId;
        this.productDAST.SDLCPracticeId = this.dastSDLCPracticeId;
        this.productDAST.EnrolDate = this.convertToUTC(this.productDAST.EnrolDate);
        this.productDAST.NextReviewDate = this.convertToUTC(this.productDAST.NextReviewDate);

        this.productDAST.SDLCRequestStatusId = this.productSDLCRequestStatuses.find(status => status.Name === 'Requested')?.Id;
  
        this.productSDLCService.createProductSDLC(this.productDAST).subscribe((newDast) => {
          const dastId = newDast.Id;
          this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Created DAST Request', life: 3000 });
          
          this.sdlcNewDastContacts.forEach((contact) => {
            const productUser = {
              UserGraphId: contact.id,
              Name: contact.displayName,
              Mail: contact.displayName,
              ProductSDLCId: dastId
            };
            
            if (productUser.UserGraphId && productUser.Name && productUser.Mail && dastId) {
              this.productSDLCService.createSDLCPrimaryContact(productUser).subscribe((response) => {
                this.messageService.add({ severity: 'success', summary: 'Successful', detail: `${productUser.Name} added`, life: 3000 });
                this.logService.sendInfoMessage('CREATE', 'product/' + this.productId + '/sdlc/dast/' + dastId + "/users", productUser);
              });
            }
          });
          
          const toEmails = this.toEmailService.getToEmails();
          
          const ccEmails = this.sdlcNewDastContacts.map(contact => contact.mail);
          
          const emailContent = this.emailTemplateService.generateEmailContent('DAST', this.productName, this.productId, this.productReference, dastId, 'sdlc/dast');
          this.sendEmail(toEmails, ccEmails, `DAST Created - ${this.productName} (${this.productId})`, emailContent);
            
          this.logService.sendInfoMessage('CREATE', 'product/' + this.productId + '/sdlc/dast/', this.productDAST);

          this.dastDialog = false;
          this.productDAST = {};
          this.sdlcNewDastContacts = [];
          this.selectedDastId = undefined;
          this.submitted = false;
          this.messages3 = [];
          
          this.fetchDASTData(true, 'Requested');
        });
      }
    }

    addNewDastContact() {
      if (this.selectedUser != null) {
        const userExists = this.sdlcNewDastContacts.some(contact => contact.id === this.selectedUser!.id);
        if (userExists) {
          this.messageService.add({ severity: 'warn', summary: 'Warning', detail: 'User already added as new contact', life: 3000 });
        } else {
          this.sdlcNewDastContacts.push(this.selectedUser);
          this.hideUserDialog();
        }
      }
      this.selectedUser = null;
    }
  
    removeNewDastContact(contact: User) {
      const index = this.sdlcNewDastContacts.indexOf(contact);
      if (index !== -1) {
          this.sdlcNewDastContacts.splice(index, 1);
      }
    }
  
    onStatusChange(shouldReset: boolean = true) {
      if (shouldReset) {
        this.selectedDastId = undefined;
        this.selectedDastOption = {};
        this.editedProductSDLC = {};
        this.sdlcPrimaryContacts = [];
        this.hasSelectedOption = false;
      }
  
      if (this.selectedStatus) {
        this.filteredProductSDLCDAST = this.productSDLCDAST.filter(dast => dast.SDLCRequestStatusName === this.selectedStatus);
      } else {
        // If no status selected, reset the array to the original data
        this.filteredProductSDLCDAST = this.productSDLCDAST;
      }
    }
    
    onSubmit() {
      if (this.submitted) {
        return;
      }
  
      this.submitted = true;
  
      if (this.selectedDastOption && this.selectedDastId) {
        this.editedProductSDLC.EnrolDate = this.convertToUTC(this.editedProductSDLC.EnrolDate);
        this.editedProductSDLC.NextReviewDate = this.convertToUTC(this.editedProductSDLC.NextReviewDate);
        
        this.productSDLCService.updateProductSDLC(this.selectedDastId, this.editedProductSDLC).subscribe((response) => {
          this.messageService.add({ severity: 'success', summary: 'DAST updated successfully', });
          this.submitted = false;
          this.fetchDASTData(false);
          this.logService.sendInfoMessage('UPDATE', 'product/' + this.productId + '/sdlc/dast/' + this.editedProductSDLC, this.selectedDastOption);
  
        }, (error) => {
          this.messageService.add({ severity: 'error', summary: 'Error! Possibly no changes made to the form. Please try again.', });
          this.submitted = false;
          console.error('Error updating SDLC', error);
        });
      } else {
        this.messageService.add({ severity: 'error', summary: 'Select a practice from the left menu', });
        this.submitted = false;
      }
    }
  
    getToolImageUrl(toolName: string | undefined): string {
      switch (toolName) {
        case 'ZAP':
          return 'assets/layout/images/Zap-logo.png';
        case 'Acunetix':
          return 'assets/layout/images/Acunetix-logo.png';
        case 'Tenable WAS':
          return 'assets/layout/images/TenableWAS-logo.png';
        case 'Nessus':
          return 'assets/layout/images/Nessus-logo.png';
        default:
          return 'assets/layout/images/default-tool-logo.png'; // Fallback image
      }
    }

    addPrimaryContact() {
      if (this.selectedUser && this.productId) {
        const userExists = this.sdlcPrimaryContacts.some(contact => contact.UserGraphId === this.selectedUser!.id);
        if (userExists) {
          this.messageService.add({ severity: 'warn', summary: 'Warning', detail: 'User already added', life: 3000 });
        } else {
          const productUser = {
            UserGraphId: this.selectedUser.id,
            Name: this.selectedUser.displayName,
            Mail: this.selectedUser.mail,
            ProductSDLCId: this.selectedDastId
          };
    
          if (productUser.UserGraphId && productUser.Name && productUser.Mail && productUser.ProductSDLCId && this.selectedDastId) {
            this.productSDLCService.createSDLCPrimaryContact(productUser).subscribe((response) => {
              this.messageService.add({ severity: 'success', summary: 'Successful', detail: `${productUser.Name} added`, life: 3000 });
              this.logService.sendInfoMessage('CREATE', 'product/' + this.productId + '/sdlc/dast/' + this.selectedDastId + "/users", productUser);
    
              this.fetchProductSDLCContacts();
              this.hideUserDialog();
            });
          }
        }
      }
    }
  
    removeUser(contact: SDLCPrimaryContact) {
      this.confirmationService.confirm({
          message: 'Are you sure you want to remove this user?',
          accept: () => {
              // User confirmed, perform deletion
              const index = this.sdlcPrimaryContacts.indexOf(contact);
              if (index !== -1 && contact.Id) {
                  this.sdlcPrimaryContacts.splice(index, 1);
                  // Perform the actual deletion from your backend here if necessary
                  this.productSDLCService.deleteSDLCPrimaryContact(contact.Id).subscribe((response) => {
                    this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'User removed', life: 3000 });
                    this.logService.sendInfoMessage('DELETE', 'product/' + this.productId + '/sdlc/dast/' + this.selectedDastId + "/users" + contact.Id, null, contact);
                  });
              }
          },
          reject: () => {
            this.messageService.add({ severity: 'error', summary: 'Rejected', detail: 'You have rejected' });
        }
      });
   }

   deleteDast(dast: ProductSDLC) {
    this.confirmationService.confirm({
        message: `Are you sure you want to delete this DAST? (Id: ${dast.Id})`,
        header: 'Delete Confirmation',
        icon: 'pi pi-info-circle',
        acceptButtonStyleClass:"p-button-danger p-button-text",
        rejectButtonStyleClass:"p-button-text p-button-text",
        acceptIcon:"none",
        rejectIcon:"none",
        accept: () => {
            // User confirmed, perform deletion
            if (dast.Id) {
                this.productSDLCService.deleteProductSDLC(dast.Id).subscribe((response) => {
                    this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'DAST deleted', life: 3000 });
                    this.fetchDASTData(false, this.selectedStatus);
                    this.logService.sendInfoMessage('DELETE', 'product/' + this.productId + '/sdlc/dast/' + dast.Id, null, dast);
                });
            }
        }
    });
  }

       // Emails

       sendEmail(toEmails: string[], ccEmails: string[], subject: string, htmlContent: string) {
        const emailRequest: EmailRequestNewDto = {
            toEmails: toEmails,
            ccEmails: ccEmails,
            subject: subject,
            htmlContent: htmlContent,
            plainTextContent: '' // Assuming no plain text content in this case
        };
    
        this.emailService.sendEmailNew(emailRequest).subscribe(response => {
            console.log('Email sent successfully', response);
        }, error => {
            console.error('Error sending email', error);
        });
      }

      convertToUTC(date: Date | undefined): Date | undefined {
        if (date) {
          return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
        }
        return undefined;
      }
}
