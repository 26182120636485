import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ToolExternalService } from 'src/app/layout/service/tool-external.service';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import 'chartjs-adapter-date-fns';
import { Router } from '@angular/router';

@Component({
  selector: 'snyk-vuln-trend-widget',
  templateUrl: './snyk-vuln-trend-widget.component.html',
  styleUrls: ['./snyk-vuln-trend-widget.component.scss']
})

export class SnykVulnTrendWidgetComponent implements OnInit, OnChanges {

  @Input() data: any;
  pieData: any;
  pieOptions: any;
  snykReportingLevel: string = '';
  snykVulnloading: boolean = true;
  snykLink: string = '';
  snykVulnerabilitiesAvailable: boolean = false;
  SnykVulnCollapsed: boolean = false;

  components: string[] = [];
  hasMultipleComponents: boolean = false;
  sourceData: any[] = [];
  selectedComponent: any;
  selectedSlugName: string = '';

  isInitialized: boolean = false;
  activeTab: number = 0;
  isOptionsExpanded: boolean = false;

  chartScale: string = 'alltime';
  chartCritical: boolean = true;
  chartHigh: boolean = true;
  chartMedium: boolean = true;
  chartLow: boolean = true;

  summary: SnapshotSummary = new SnapshotSummary();
  widgetContext: string = '';

  constructor(private toolExternalService: ToolExternalService, private router: Router) { }

  ngOnInit(): void {
    this.widgetContext = this.data.objectId;
    this.initialiseWidgetOnce();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data']) {
      if (this.widgetContext !== this.data.objectId) {
        this.isInitialized = false;
        this.widgetContext = this.data.objectId;
      }
      this.resetWidget();
      this.initialiseWidgetOnce();
    }
  }

  private initialiseWidgetOnce(): void {
    if (!this.isInitialized) {
      this.initialiseWidget();
      this.isInitialized = true;
    }
  }


  initialiseWidget() {
    this.pieOptions = {
      responsive: true,
      plugins: {
        legend: {
          display: false
        },
        tooltip: {
          mode: 'index',
          intersect: false
        }
      },
      scales: {
        x: {
          grid: {
            display: false 
          },
          type: 'time',
          time: {
            unit: 'day'
          }
        },
        y: {
          beginAtZero: true,
        }
      }
    };

    //convert this.productId to a number
    const prodNumber = parseInt(this.data.objectId);
    const productType = this.data?.productType ?? null;
    const currentUrl = this.router.url;

    this.toolExternalService.getSnykVulnerabilityTrendData(prodNumber, productType).pipe(
      catchError((error) => {
        if (error.status === 404) {
          this.snykReportingLevel = `No Snyk data available`;
          this.pieData = {
            labels: [],
            datasets: []
          };
        } else {
          console.error('An error occurred:', error);
          this.snykReportingLevel = "Unexpected error occured loading Snyk data";
          this.pieData = {
            labels: [],
            datasets: []
          };
        }
        this.snykVulnloading = false;
        return of(null);
      })
    ).subscribe((snykData) => {
      if (!snykData) {
        this.snykVulnloading = false;
        return;
      }

      const pt = snykData?.productType ?? "";

      if (pt === 'Product') {
        this.snykReportingLevel = "";
      } else if (pt === 'Suite' && currentUrl.includes('/suite/')) {
        this.snykReportingLevel = "Snyk data not available for this suite";
        this.snykVulnerabilitiesAvailable = false;
        return;
      } else {
        this.snykReportingLevel = "Snyk data not available at this product level. Showing data for parent suite: " + snykData.objectName;
      }

      this.sourceData = snykData.data;      
      this.components = [];
      for (const dataItem of snykData.data) {
        this.components.push(dataItem.slug ?? "??");
      }
      this.selectedComponent = this.sourceData[0];
      this.selectedSlugName = this.selectedComponent.slug;
      this.hasMultipleComponents = this.components.length > 1;

      if (this.selectedComponent.results.length > 0) {
        this.snykVulnerabilitiesAvailable = true;
      }

      this.summary = this.populateSummary();
      this.populateData();

      this.snykVulnloading = false;
    });
  }

  toggleSnykVulnCollapse() {
    this.SnykVulnCollapsed = !this.SnykVulnCollapsed;
  }

  selectTab(index: number) {
    this.activeTab = index;
  }

  onScaleSelectionChange(value: string): void {
    this.populateData(); 
  }

  toggleChartOptions() {
    this.isOptionsExpanded = !this.isOptionsExpanded;
  }

  getSeverityClass(label: string, baseClass: string): string {
    //remove spaces from Label
    label = label.replace(/\s/g, '');
    //Make label lower case
    label = label.toLowerCase();
    const severityClass = 'pieWidget' + label;
    return `${baseClass} ${severityClass}`;
  }

  onDropdownChange(event: any) {
    const selectedValue = event.value;
    this.selectedSlugName = selectedValue;

    for (const component of this.sourceData) {
      if (component.slug === selectedValue) {
        this.selectedComponent = component;
        break;
      }
    }

    this.summary = this.populateSummary();
    this.populateData();
    
  }

  populateData() {

    this.pieData = this.transformDataToChartData(this.selectedComponent.results);
  }

  resetWidget() {
    this.snykVulnloading = true;
    this.snykReportingLevel = '';
    this.snykLink = '';
    this.snykVulnerabilitiesAvailable = false;
    this.SnykVulnCollapsed = false;
    this.components = [];
    this.hasMultipleComponents = false;
    this.sourceData = [];
    this.selectedComponent = {};
    this.selectedSlugName = '';
    this.isInitialized = false;
    this.activeTab = 0;
    this.isOptionsExpanded = false;
    this.chartScale = 'alltime';
    this.chartCritical = true;
    this.chartHigh = true;
    this.chartMedium = true;
    this.chartLow = true;
    this.summary = new SnapshotSummary();
  }

  absify(value: number): number {
    return Math.abs(value);
  }


populateSummary() {
  const summary = new SnapshotSummary();
  const results = this.selectedComponent.results;

  if (results.length === 0) {
    return summary; // No data to process
  }

  // Assume results are sorted by date in descending order
  const latestResult = results[0];
  const sevenDaysAgo = new Date();
  sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);
  const thirtyDaysAgo = new Date();
  thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
  const sixMonthsAgo = new Date();
  sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);

  let result7DaysAgo: any = null;
  let result30DaysAgo: any = null;
  let result6MonthsAgo: any = null;

  for (const result of results) {
    const resultDate = new Date(result.day);

    if (resultDate >= sevenDaysAgo) {
      result7DaysAgo = result;
    }

    if (resultDate >= thirtyDaysAgo) {
      result30DaysAgo = result;
    }

    if (resultDate >= sixMonthsAgo) {
      result6MonthsAgo = result;
    } else {
      break;
    }
  }

  // Populate v7Days
  if (result7DaysAgo) {
    summary.v7Days.hasSegment = true;
    summary.v7Days.Critical = latestResult.severity.critical - result7DaysAgo.severity.critical;
    summary.v7Days.High = latestResult.severity.high - result7DaysAgo.severity.high;
    summary.v7Days.Medium = latestResult.severity.medium - result7DaysAgo.severity.medium;
    summary.v7Days.Low = latestResult.severity.low - result7DaysAgo.severity.low;
  }

  // Populate v30Days
  if (result30DaysAgo) {
    summary.v30Days.hasSegment = true;
    summary.v30Days.Critical = latestResult.severity.critical - result30DaysAgo.severity.critical;
    summary.v30Days.High = latestResult.severity.high - result30DaysAgo.severity.high;
    summary.v30Days.Medium = latestResult.severity.medium - result30DaysAgo.severity.medium;
    summary.v30Days.Low = latestResult.severity.low - result30DaysAgo.severity.low;
  }

  // Populate v6Months
  if (result6MonthsAgo) {
    summary.v6Months.hasSegment = true;
    summary.v6Months.Critical = latestResult.severity.critical - result6MonthsAgo.severity.critical;
    summary.v6Months.High = latestResult.severity.high - result6MonthsAgo.severity.high;
    summary.v6Months.Medium = latestResult.severity.medium - result6MonthsAgo.severity.medium;
    summary.v6Months.Low = latestResult.severity.low - result6MonthsAgo.severity.low;
  }

  return summary;
}


  transformDataToChartData(data: any[]): any {
    const labels: string[] = [];
    const criticalData: number[] = [];
    const highData: number[] = [];
    const mediumData: number[] = [];
    const lowData: number[] = [];

    // Get the current date
    const now = new Date();

    // Filter the data based on the selected chartScale
    const filteredData = data.filter(result => {
      const resultDate = new Date(result.day);
      const daysDiff = Math.floor((now.getTime() - resultDate.getTime()) / (1000 * 60 * 60 * 24));

      switch (this.chartScale) {
        case 'last7days':
          return daysDiff < 7;
        case 'last30days':
          return daysDiff < 30;
        case 'last6months':
          return daysDiff < 180;
        case 'alltime':
          return true; // Include all data
        default:
          return false;
      }
    });

    // Extract labels and series data for each severity level
    filteredData.forEach(result => {
      labels.push(result.day);
      if (this.chartCritical) {
        criticalData.push(result.severity.critical);
      }
      if (this.chartHigh) {
        highData.push(result.severity.high);
      }
      if (this.chartMedium) {
        mediumData.push(result.severity.medium);
      }
      if (this.chartLow) {
        lowData.push(result.severity.low);
      }
    });

    const documentStyle = getComputedStyle(document.documentElement);

    // Create the chart data object
    const datasets: any[] = [];

    if (this.chartCritical) {
      datasets.push({
        label: 'Critical',
        data: criticalData,
        fill: false,
        borderColor: documentStyle.getPropertyValue('--critical'),
        tension: 0.1,
        borderWidth: 2,
        pointRadius: 0
      });
    }

    if (this.chartHigh) {
      datasets.push({
        label: 'High',
        data: highData,
        fill: false,
        borderColor: documentStyle.getPropertyValue('--high'),
        tension: 0.1,
        borderWidth: 2,
        pointRadius: 0
      });
    }

    if (this.chartMedium) {
      datasets.push({
        label: 'Medium',
        data: mediumData,
        fill: false,
        borderColor: documentStyle.getPropertyValue('--medium'),
        tension: 0.1,
        borderWidth: 2,
        pointRadius: 0
      });
    }

    if (this.chartLow) {
      datasets.push({
        label: 'Low',
        data: lowData,
        fill: false,
        borderColor: documentStyle.getPropertyValue('--low'),
        tension: 0.1,
        borderWidth: 2,
        pointRadius: 0
      });
    }

    const chartData: any = {
      labels: labels,
      datasets: datasets
    };

    return chartData;
  }
}

export class SnapshotSummary {
  v7Days: SnapshotSummarySegment;
  v30Days: SnapshotSummarySegment;
  v6Months: SnapshotSummarySegment;

  constructor() {
    this.v7Days = new SnapshotSummarySegment();
    this.v30Days = new SnapshotSummarySegment();
    this.v6Months = new SnapshotSummarySegment();
  }
}

export class SnapshotSummarySegment {
  hasSegment: boolean;
  Critical: number;
  High: number;
  Medium: number;
  Low: number;

  constructor() {
    this.hasSegment = false;
    this.Critical = 0;
    this.High = 0;
    this.Medium = 0;
    this.Low = 0;
  }
}
