import { Component, OnInit } from '@angular/core';
import { ProductNFRService } from 'src/app/layout/service/product/product-nfr.service';
import { ActivatedRoute } from '@angular/router';
import { Message, MessageService } from 'primeng/api';
import { NFRCategoryDto, NonFunctionalRequirementDto, NonFunctionalRequirementRequestDto } from 'src/app/api/product-nfr';

@Component({
  selector: 'nfrs',
  templateUrl: './nfrs.html'
})
export class NFRComponent implements OnInit {

  nfrCategories: NFRCategoryDto[] = [];

  requirements: NonFunctionalRequirementDto[] = [];
  messages: Message[] = [];
  newRequirementDialog: boolean = false;
  UpdateRequirementDialog: boolean = false;
  submitted: boolean = false;

  newName: string = '';
  selectedCategory: number = 0;
  newDescription: string = '';
  newPriority: string = '';
  newDefault: string = '';
  existingId: number = 0;

  filteredCategories: NFRCategoryDto[] = [];
  filteredRequirements: NonFunctionalRequirementDto[] = [];

  constructor(private route: ActivatedRoute, private nfrService: ProductNFRService, private messageService: MessageService) { }

  ngOnInit(): void {

    this.nfrService.getAllNFRCategories().subscribe(
      (data: NFRCategoryDto[]) => {
        this.nfrCategories = data;

        this.nfrService.getAllNonFunctionalRequirements().subscribe(
          (data: any) => {
            this.requirements = data;

            //order the requirements by category ID and then by priority, handling possible null values
            this.requirements.sort((a, b) => (a.NFRCategoryId ?? 0) - (b.NFRCategoryId ?? 0) || (a.Priority ?? 0) - (b.Priority ?? 0));
           
            this.updateFilteredRequirements();

          });
      });



  }

  openNewDialog() {
    this.newRequirementDialog = true;
  }

  hideDialog() {
    this.newRequirementDialog = false;
    this.clearData();
  }

  openUpdateDialog(item: NonFunctionalRequirementDto) {
    this.existingId = item.Id ?? 0;
    this.newName = item.Name ?? '';
    this.selectedCategory = item.NFRCategoryId ?? 0;
    this.newDescription = item.Description ?? '';
    this.newDefault = item.Default ?? '';
    this.newPriority = item.Priority?.toString() ?? '';
    this.UpdateRequirementDialog = true;
  }

  hideExistingDialog() {
    this.UpdateRequirementDialog = false;
    this.clearData();
  }

  addNewRequirement() {
    this.submitted = true;
    if (!this.newName) {
      return;
    }
    if (!this.newDescription) {
      return;
    }
    if (!this.newPriority) {
      return;
    }
    if (this.selectedCategory === 0) {
      return;
    }

    const request: NonFunctionalRequirementRequestDto = {
      NFRCategoryId: this.selectedCategory,
      Name: this.newName,
      Description: this.newDescription,
      Default: this.newDefault,
      Priority: parseInt(this.newPriority)
    };

    this.nfrService.createNonFunctionalRequirement(request).subscribe(
      (data: any) => {

        const createdRequirement: NonFunctionalRequirementDto = {
          Id: data.id,
          NFRCategoryId: data.nfrCategoryId,
          Name: data.name,
          Description: data.description,
          Default: data.default,
          Priority: data.priority
        }

        this.requirements.push(createdRequirement);
        this.requirements.sort((a, b) => (a.NFRCategoryId ?? 0) - (b.NFRCategoryId ?? 0) || (a.Priority ?? 0) - (b.Priority ?? 0));
        this.updateFilteredRequirements();

        this.newRequirementDialog = false;
        this.submitted = false;
        this.clearData();
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Requirement added successfully' });
      },
      (error: any) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error while adding requirement' });
      });
  }

  updateRequirement() {
    this.submitted = true;
    if (!this.newName) {
      return;
    }
    if (!this.newDescription) {
      return;
    }
    if (!this.newPriority) {
      return;
    }
    if (this.selectedCategory === 0) {
      return;
    }

    const request: NonFunctionalRequirementRequestDto = {
      NFRCategoryId: this.selectedCategory,
      Name: this.newName,
      Description: this.newDescription,
      Default: this.newDefault,
      Priority: parseInt(this.newPriority)
    };


    this.nfrService.updateNonFunctionalRequirement(this.existingId, request).subscribe(
      (data: any) => {

        const index = this.requirements.findIndex(x => x.Id === this.existingId);
        this.requirements[index].Default = this.newDefault;
        this.requirements[index].Description = this.newDescription;
        this.requirements[index].Name = this.newName;
        this.requirements[index].Priority = parseInt(this.newPriority);
        this.requirements[index].NFRCategoryId = this.selectedCategory;

        this.UpdateRequirementDialog = false;
        this.submitted = false;
        this.clearData();
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Requirement updated successfully' });
      },
      (error: any) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error while updating requirement' });
      });
  }

  deleteRequirement(item: NonFunctionalRequirementDto) {

    const id: number = item.Id ?? 0;
    //escape if id is 0
    if (id === 0) {
      return;
    }
    this.nfrService.deleteNonFunctionalRequirement(id).subscribe(
      (data: any) => {
        this.requirements = this.requirements.filter(requirement => requirement.Id !== id);
        this.updateFilteredRequirements();
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Requirement deleted' });
      },
      (error: any) => {
        this.messageService.add({
          severity: 'error', summary: 'Error', detail: 'Error while deleting requirement'
        });
      });
  }

  getCategoryName(id: number): string {
    const category = this.nfrCategories.find(x => x.Id === id);
    return category?.Name ?? '';
  }

  shortenDescription(description: string): string {

    if (!description) {
      return '';
    }

    return description.length > 50 ? description.substr(0, 50) + '...' : description;
  }

  onCategoriesChange(event: any) {
    this.filteredCategories = event.value;
    this.updateFilteredRequirements();
  }

  updateFilteredRequirements() {
    this.filteredRequirements = this.filteredCategories.length > 0 ? this.requirements.filter(x => this.filteredCategories.map(y => y.Id).includes(x.NFRCategoryId ?? 0)) : this.requirements;
  }

  clearData() {
    this.newName = '';
    this.newDescription = '';
    this.newDefault = '';
    this.newPriority = '';
    this.selectedCategory = 0;
  }

}
