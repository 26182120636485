import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-upload-findings',
  templateUrl: './upload-findings.component.html',
  styleUrls: ['./upload-findings.component.scss']
})
export class UploadFindingsComponent {
  baseApiUrl: string = environment.baseApiUrl;
  selectedFile: File | null = null;

  constructor(private http: HttpClient, private messageService: MessageService) {}
  
  onFileSelected(event: any) {
    this.selectedFile = event.target.files[0];
  }

  onUpload() {
    if (this.selectedFile) {
      const formData = new FormData();
      formData.append('file', this.selectedFile, this.selectedFile.name);

      this.http.post(`${this.baseApiUrl}/api/PentestFindingsUpload`, formData).subscribe(
        (response) => {
          this.messageService.add({severity: 'success', summary: 'Success', detail: 'Upload Pentest Findings Successful'});
        },
        (error) => {
          this.messageService.add({severity: 'error', summary: 'Error', detail: 'Upload Pentest Findings Failed!'});
        }
      );
    }
  }

  onUploadOneDay() {
    if (this.selectedFile) {
      const formData = new FormData();
      formData.append('file', this.selectedFile, this.selectedFile.name);

      this.http.post(`${this.baseApiUrl}/api/PentestFindingsUpload/UploadOneDay`, formData).subscribe(
        (response) => {
          this.messageService.add({severity: 'success', summary: 'Success', detail: 'Upload One Day Findings Successful'});
        },
        (error) => {
          this.messageService.add({severity: 'error', summary: 'Error', detail: 'Upload One Day Findings Failed!'});
        }
      );
    }
  }
}
