import { Component, ViewChild, OnInit, ElementRef, HostListener } from '@angular/core';
import { SearchService } from 'src/app/layout/service/search.service';
import { Router } from '@angular/router';
import { Observable, Subject, of, tap, startWith } from 'rxjs';
import { debounceTime, switchMap, catchError } from 'rxjs/operators';
import { SearchResult } from 'src/app/api/searchResult';

@Component({
  selector: 'app-freetextsearch',
  templateUrl: './freetextsearch.component.html',
  styleUrls: ['./freetextsearch.component.css']
})
export class FreeTextSearchComponent implements OnInit {

  @ViewChild('searchInput') searchInput!: ElementRef<HTMLInputElement>;
  searchResults$: Observable<SearchResult[]> = new Observable<SearchResult[]>();
  private searchTerms = new Subject<string>();
  isActive: boolean = false;
  isEmpty: boolean = false;
  selectedIndex: number = -1;
  results: SearchResult[] = [];
  private keydownTimeout: any;

  constructor(
    private searchService: SearchService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.searchResults$ = this.searchTerms.pipe(
      debounceTime(300),
      startWith(''),
      switchMap(term => {
        const trimmedTerm = term.trim();
        if (trimmedTerm.length === 0) {
          this.isActive = false;
          this.isEmpty = false;
          return of([]);
        }
        return this.searchService.getSearch(trimmedTerm).pipe(
          catchError(() => of([])),
          tap(results => {
            this.results = results;
            this.isActive = results.length > 0;
            this.isEmpty = results.length === 0;
          })
        );
      })
    );
  }

  search(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    this.searchTerms.next(inputElement.value);
  }

  getIconClass(type: string): string {
    switch (type) {
      case 'Suite': return 'apps';
      case 'Product': return 'inventory';
      default: return 'question_mark';
    }
  }

  getTypeLabel(type: string): string {
    switch (type) {
      case 'Suite': return 'Suite';
      case 'Product': return 'Product';
      default: return 'Unknown';
    }
  }

  hideResults(): void {
    this.isActive = false;
    this.isEmpty = false;
    this.selectedIndex = -1; // Reset the selected index when hiding results
  }

  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent): void {
    const target = event.target as Node;
    if (!this.searchInput.nativeElement.contains(target)) {
      this.hideResults();
    }
  }

  onSelect(result: SearchResult): void {
    console.log("Changing page via the search bar.");
    const url = result.type === 'Product'
      ? `/product/${result.id}/dashboard`
      : result.type === 'Suite'
        ? `/suite/${result.id}/dashboard`
        : '/';

    this.isActive = false;
    this.searchInput.nativeElement.value = '';
    this.router.navigate([url]);
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyDown(event: KeyboardEvent): void {
    // Clear previous timeout if any
    if (this.keydownTimeout) {
      clearTimeout(this.keydownTimeout);
    }

    // Set a timeout to debounce keydown events
    this.keydownTimeout = setTimeout(() => {
      if (!this.isActive || this.results.length === 0) return;

      switch (event.key) {
        case 'ArrowDown':
          this.selectedIndex = (this.selectedIndex + 1) % this.results.length;
          this.scrollToSelected();
          break;
        case 'ArrowUp':
          this.selectedIndex = (this.selectedIndex - 1 + this.results.length) % this.results.length;
          this.scrollToSelected();
          break;
        case 'Enter':
          if (this.selectedIndex >= 0 && this.selectedIndex < this.results.length) {
            this.onSelect(this.results[this.selectedIndex]);
          }
          break;
        default:
          break;
      }
    }, 100); // Adjust debounce time as needed
  }

  scrollToSelected(): void {
    const container = this.searchInput.nativeElement.nextElementSibling?.querySelector('.search-results') as HTMLElement;
    const selectedElement = container?.children[this.selectedIndex] as HTMLElement;

    if (container && selectedElement) {
      const containerRect = container.getBoundingClientRect();
      const selectedRect = selectedElement.getBoundingClientRect();

      if (selectedRect.top < containerRect.top) {
        container.scrollTop -= (containerRect.top - selectedRect.top);
      } else if (selectedRect.bottom > containerRect.bottom) {
        container.scrollTop += (selectedRect.bottom - containerRect.bottom);
      }
    }
  }
}
