<div class="widgetCard" [class.collapsed]="SonarMetricsCollapsed">
  <div class="widgetCardHeader" (click)="toggleSnykVulnCollapse()">
    <h5>Code Quality Metrics Trend</h5>
    <div class="chevron">
      <i [class]="SonarMetricsCollapsed ? 'chevron-down' : 'chevron-up'"></i>
    </div>
  </div>
  <ng-container *ngIf="!SonarMetricsCollapsed">
    <ng-container *ngIf="sonarLoading; else content">
      <p-skeleton width="10%" height="1rem" margin="5px"></p-skeleton>
    </ng-container>
    <ng-template #content>
      <span class="reportingLevelLabel">{{ sonarReportingLevel }}</span>
      <div class="reportingLevelLabel" *ngIf="hasMultipleComponents">
        <p-dropdown appendTo="body" [(ngModel)]="selectedComponentName" [options]="components" placeholder="Select" class="uniform-width-input" [style]="{'minWidth':'40%', 'maxWidth': '400px'}" (onChange)="onDropdownChange($event)">
          <ng-template pTemplate="selectedItem">
            <span *ngIf="selectedComponentName">{{ selectedComponentName }}</span>
          </ng-template>
          <ng-template let-option pTemplate="item">
            <span>{{option}}</span>
          </ng-template>
        </p-dropdown>
      </div>
      <div class="pieWidgetContainer" *ngIf="sonarMetricsAvailable">
        <div class="vertical-tabs-container">
          <div class="vertical-tabs-nav">
            <button pButton class="vertical-tab-button" (click)="selectTab(0)" [class.active]="activeTab === 0">
              <span class="text">Overview</span>
              <i class="pi pi-table icon"></i>
            </button>
            <button pButton class="vertical-tab-button" (click)="selectTab(1)" [class.active]="activeTab === 1">
              <span class="text">Chart</span>
              <i class="pi pi-chart-line icon"></i>
            </button>
          </div>
          <div class="vertical-tabs-content">
            <div *ngIf="activeTab === 0">
              <table class="summary-table">
                <thead>
                  <tr>
                    <th class="pieWidgetHeader pieWidgetCell"></th>
                    <th class="pieWidgetHeader pieWidgetCell">vs. 7 days ago</th>
                    <th class="pieWidgetHeader pieWidgetCell">vs. 30 days ago</th>
                    <th class="pieWidgetHeader pieWidgetCell">vs. 6 months ago</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Lines of Code</td>
                    <td [ngClass]="{'greyed-out': !ncloc_data.v7Days.hasSegment}">
                      <span *ngIf="ncloc_data.v7Days.hasSegment">
                        <span class="neutralvalue">
                          <span *ngIf="ncloc_data.v7Days.Value === 0" class="zero">-</span>
                          <span *ngIf="ncloc_data.v7Days.Value != 0">{{ absify(ncloc_data.v7Days.Value) }}</span>
                          <span *ngIf="ncloc_data.v7Days.Value > 0">&nbsp;▲</span>
                          <span *ngIf="ncloc_data.v7Days.Value < 0">&nbsp;▼</span>
                        </span>
                      </span>
                    </td>
                    <td [ngClass]="{'greyed-out': !ncloc_data.v30Days.hasSegment}">
                      <span *ngIf="ncloc_data.v30Days.hasSegment">
                        <span class="neutralvalue">
                          <span *ngIf="ncloc_data.v30Days.Value === 0" class="zero">-</span>
                          <span *ngIf="ncloc_data.v30Days.Value != 0">{{ absify(ncloc_data.v30Days.Value) }}</span>
                          <span *ngIf="ncloc_data.v30Days.Value > 0">&nbsp;▲</span>
                          <span *ngIf="ncloc_data.v30Days.Value < 0">&nbsp;▼</span>
                        </span>
                      </span>
                    </td>
                    <td [ngClass]="{'greyed-out': !ncloc_data.v6Months.hasSegment}">
                      <span *ngIf="ncloc_data.v6Months.hasSegment">
                        <span class="neutralvalue">
                          <span *ngIf="ncloc_data.v6Months.Value === 0" class="zero">-</span>
                          <span *ngIf="ncloc_data.v6Months.Value != 0">{{ absify(ncloc_data.v6Months.Value) }}</span>
                          <span *ngIf="ncloc_data.v6Months.Value > 0">&nbsp;▲</span>
                          <span *ngIf="ncloc_data.v6Months.Value < 0">&nbsp;▼</span>
                        </span>
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>Tech Debt</td>
                    <td [ngClass]="{'greyed-out': !techdebt_data.v7Days.hasSegment}">
                      <span *ngIf="techdebt_data.v7Days.hasSegment">
                        <span [ngClass]="{'positive': techdebt_data.v7Days.Value > 0, 'negative': techdebt_data.v7Days.Value < 0}">
                          <span *ngIf="techdebt_data.v7Days.Value === 0" class="zero">-</span>
                          <span *ngIf="techdebt_data.v7Days.Value != 0">{{ convertTechDebtToDays(techdebt_data.v7Days.Value) }}</span>
                          <span *ngIf="techdebt_data.v7Days.Value > 0">&nbsp;▲</span>
                          <span *ngIf="techdebt_data.v7Days.Value < 0">&nbsp;▼</span>
                        </span>
                      </span>
                    </td>
                    <td [ngClass]="{'greyed-out': !techdebt_data.v30Days.hasSegment}">
                      <span *ngIf="techdebt_data.v30Days.hasSegment">
                        <span [ngClass]="{'positive': techdebt_data.v30Days.Value > 0, 'negative': techdebt_data.v30Days.Value < 0}">
                          <span *ngIf="techdebt_data.v30Days.Value === 0" class="zero">-</span>
                          <span *ngIf="techdebt_data.v30Days.Value != 0">{{ convertTechDebtToDays(techdebt_data.v30Days.Value) }}</span>
                          <span *ngIf="techdebt_data.v30Days.Value > 0">&nbsp;▲</span>
                          <span *ngIf="techdebt_data.v30Days.Value < 0">&nbsp;▼</span>
                        </span>
                      </span>
                    </td>
                    <td [ngClass]="{'greyed-out': !techdebt_data.v6Months.hasSegment}">
                      <span *ngIf="techdebt_data.v6Months.hasSegment">
                        <span [ngClass]="{'positive': techdebt_data.v6Months.Value > 0, 'negative': techdebt_data.v6Months.Value < 0}">
                          <span *ngIf="techdebt_data.v6Months.Value === 0" class="zero">-</span>
                          <span *ngIf="techdebt_data.v6Months.Value != 0">{{ convertTechDebtToDays(techdebt_data.v6Months.Value) }}</span>
                          <span *ngIf="techdebt_data.v6Months.Value > 0">&nbsp;▲</span>
                          <span *ngIf="techdebt_data.v6Months.Value < 0">&nbsp;▼</span>
                        </span>
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>Complexity</td>
                    <td [ngClass]="{'greyed-out': !complexity_data.v7Days.hasSegment}">
                      <span *ngIf="complexity_data.v7Days.hasSegment">
                        <span [ngClass]="{'positive': complexity_data.v7Days.Value > 0, 'negative': complexity_data.v7Days.Value < 0}">
                          <span *ngIf="complexity_data.v7Days.Value === 0" class="zero">-</span>
                          <span *ngIf="complexity_data.v7Days.Value != 0">{{ absify(complexity_data.v7Days.Value) }}</span>
                          <span *ngIf="complexity_data.v7Days.Value > 0">&nbsp;▲</span>
                          <span *ngIf="complexity_data.v7Days.Value < 0">&nbsp;▼</span>
                        </span>
                      </span>
                    </td>
                    <td [ngClass]="{'greyed-out': !complexity_data.v30Days.hasSegment}">
                      <span *ngIf="complexity_data.v30Days.hasSegment">
                        <span [ngClass]="{'positive': complexity_data.v30Days.Value > 0, 'negative': complexity_data.v30Days.Value < 0}">
                          <span *ngIf="complexity_data.v30Days.Value === 0" class="zero">-</span>
                          <span *ngIf="complexity_data.v30Days.Value != 0">{{ absify(complexity_data.v30Days.Value) }}</span>
                          <span *ngIf="complexity_data.v30Days.Value > 0">&nbsp;▲</span>
                          <span *ngIf="complexity_data.v30Days.Value < 0">&nbsp;▼</span>
                        </span>
                      </span>
                    </td>
                    <td [ngClass]="{'greyed-out': !complexity_data.v6Months.hasSegment}">
                      <span *ngIf="complexity_data.v6Months.hasSegment">
                        <span [ngClass]="{'positive': complexity_data.v6Months.Value > 0, 'negative': complexity_data.v6Months.Value < 0}">
                          <span *ngIf="complexity_data.v6Months.Value === 0" class="zero">-</span>
                          <span *ngIf="complexity_data.v6Months.Value != 0">{{ absify(complexity_data.v6Months.Value) }}</span>
                          <span *ngIf="complexity_data.v6Months.Value > 0">&nbsp;▲</span>
                          <span *ngIf="complexity_data.v6Months.Value < 0">&nbsp;▼</span>
                        </span>
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>Hotspots</td>
                    <td [ngClass]="{'greyed-out': !hotspot_data.v7Days.hasSegment}">
                      <span *ngIf="hotspot_data.v7Days.hasSegment">
                        <span [ngClass]="{'positive': hotspot_data.v7Days.Value > 0, 'negative': hotspot_data.v7Days.Value < 0}">
                          <span *ngIf="hotspot_data.v7Days.Value === 0" class="zero">-</span>
                          <span *ngIf="hotspot_data.v7Days.Value != 0">{{ absify(hotspot_data.v7Days.Value) }}</span>
                          <span *ngIf="hotspot_data.v7Days.Value > 0">&nbsp;▲</span>
                          <span *ngIf="hotspot_data.v7Days.Value < 0">&nbsp;▼</span>
                        </span>
                      </span>
                    </td>
                    <td [ngClass]="{'greyed-out': !hotspot_data.v30Days.hasSegment}">
                      <span *ngIf="hotspot_data.v30Days.hasSegment">
                        <span [ngClass]="{'positive': hotspot_data.v30Days.Value > 0, 'negative': hotspot_data.v30Days.Value < 0}">
                          <span *ngIf="hotspot_data.v30Days.Value === 0" class="zero">-</span>
                          <span *ngIf="hotspot_data.v30Days.Value != 0">{{ absify(hotspot_data.v30Days.Value) }}</span>
                          <span *ngIf="hotspot_data.v30Days.Value > 0">&nbsp;▲</span>
                          <span *ngIf="hotspot_data.v30Days.Value < 0">&nbsp;▼</span>
                        </span>
                      </span>
                    </td>
                    <td [ngClass]="{'greyed-out': !hotspot_data.v6Months.hasSegment}">
                      <span *ngIf="hotspot_data.v6Months.hasSegment">
                        <span [ngClass]="{'positive': hotspot_data.v6Months.Value > 0, 'negative': hotspot_data.v6Months.Value < 0}">
                          <span *ngIf="hotspot_data.v6Months.Value === 0" class="zero">-</span>
                          <span *ngIf="hotspot_data.v6Months.Value != 0">{{ absify(hotspot_data.v6Months.Value) }}</span>
                          <span *ngIf="hotspot_data.v6Months.Value > 0">&nbsp;▲</span>
                          <span *ngIf="hotspot_data.v6Months.Value < 0">&nbsp;▼</span>
                        </span>
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>Coverage</td>
                    <td [ngClass]="{'greyed-out': !coverage_data.v7Days.hasSegment}">
                      <span *ngIf="coverage_data.v7Days.hasSegment">
                        <span [ngClass]="{'negative': coverage_data.v7Days.Value > 0, 'positive': coverage_data.v7Days.Value < 0}">
                          <span *ngIf="coverage_data.v7Days.Value === 0" class="zero">-</span>
                          <span *ngIf="coverage_data.v7Days.Value != 0">{{ absify(coverage_data.v7Days.Value) }}%</span>
                          <span *ngIf="coverage_data.v7Days.Value > 0">&nbsp;▲</span>
                          <span *ngIf="coverage_data.v7Days.Value < 0">&nbsp;▼</span>
                        </span>
                      </span>
                    </td>
                    <td [ngClass]="{'greyed-out': !coverage_data.v30Days.hasSegment}">
                      <span *ngIf="coverage_data.v30Days.hasSegment">
                        <span [ngClass]="{'negative': coverage_data.v30Days.Value > 0, 'positive': coverage_data.v30Days.Value < 0}">
                          <span *ngIf="coverage_data.v30Days.Value === 0" class="zero">-</span>
                          <span *ngIf="coverage_data.v30Days.Value != 0">{{ absify(coverage_data.v30Days.Value) }}%</span>
                          <span *ngIf="coverage_data.v30Days.Value > 0">&nbsp;▲</span>
                          <span *ngIf="coverage_data.v30Days.Value < 0">&nbsp;▼</span>
                        </span>
                      </span>
                    </td>
                    <td [ngClass]="{'greyed-out': !coverage_data.v6Months.hasSegment}">
                      <span *ngIf="coverage_data.v6Months.hasSegment">
                        <span [ngClass]="{'negative': coverage_data.v6Months.Value > 0, 'positive': coverage_data.v6Months.Value < 0}">
                          <span *ngIf="coverage_data.v6Months.Value === 0" class="zero">-</span>
                          <span *ngIf="coverage_data.v6Months.Value != 0">{{ absify(coverage_data.v6Months.Value) }}%</span>
                          <span *ngIf="coverage_data.v6Months.Value > 0">&nbsp;▲</span>
                          <span *ngIf="coverage_data.v6Months.Value < 0">&nbsp;▼</span>
                        </span>
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>Reliability</td>
                    <td [ngClass]="{'greyed-out': !reliability_data.v7Days.hasSegment}">
                      <span *ngIf="reliability_data.v7Days.hasSegment">
                        <span [ngClass]="{'positive': reliability_data.v7Days.Value > 0, 'negative': reliability_data.v7Days.Value < 0}">
                          <span *ngIf="reliability_data.v7Days.Value === 0" class="zero">-</span>
                          <span *ngIf="reliability_data.v7Days.Value != 0">{{ absify(reliability_data.v7Days.Value) }}</span>
                          <span *ngIf="reliability_data.v7Days.Value < 0">&nbsp;▲</span>
                          <span *ngIf="reliability_data.v7Days.Value > 0">&nbsp;▼</span>
                        </span>
                      </span>
                    </td>
                    <td [ngClass]="{'greyed-out': !reliability_data.v30Days.hasSegment}">
                      <span *ngIf="reliability_data.v30Days.hasSegment">
                        <span [ngClass]="{'positive': reliability_data.v30Days.Value > 0, 'negative': reliability_data.v30Days.Value < 0}">
                          <span *ngIf="reliability_data.v30Days.Value === 0" class="zero">-</span>
                          <span *ngIf="reliability_data.v30Days.Value != 0">{{ absify(reliability_data.v30Days.Value) }}</span>
                          <span *ngIf="reliability_data.v30Days.Value < 0">&nbsp;▲</span>
                          <span *ngIf="reliability_data.v30Days.Value > 0">&nbsp;▼</span>
                        </span>
                      </span>
                    </td>
                    <td [ngClass]="{'greyed-out': !reliability_data.v6Months.hasSegment}">
                      <span *ngIf="reliability_data.v6Months.hasSegment">
                        <span [ngClass]="{'positive': reliability_data.v6Months.Value > 0, 'negative': reliability_data.v6Months.Value < 0}">
                          <span *ngIf="reliability_data.v6Months.Value === 0" class="zero">-</span>
                          <span *ngIf="reliability_data.v6Months.Value != 0">{{ absify(reliability_data.v6Months.Value) }}</span>
                          <span *ngIf="reliability_data.v6Months.Value < 0">&nbsp;▲</span>
                          <span *ngIf="reliability_data.v6Months.Value > 0">&nbsp;▼</span>
                        </span>
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>Maintainability</td>
                    <td [ngClass]="{'greyed-out': !maintainability_data.v7Days.hasSegment}">
                      <span *ngIf="maintainability_data.v7Days.hasSegment">
                        <span [ngClass]="{'positive': maintainability_data.v7Days.Value > 0, 'negative': maintainability_data.v7Days.Value < 0}">
                          <span *ngIf="maintainability_data.v7Days.Value === 0" class="zero">-</span>
                          <span *ngIf="maintainability_data.v7Days.Value != 0">{{ absify(maintainability_data.v7Days.Value) }}</span>
                          <span *ngIf="maintainability_data.v7Days.Value < 0">&nbsp;▲</span>
                          <span *ngIf="maintainability_data.v7Days.Value > 0">&nbsp;▼</span>
                        </span>
                      </span>
                    </td>
                    <td [ngClass]="{'greyed-out': !maintainability_data.v30Days.hasSegment}">
                      <span *ngIf="maintainability_data.v30Days.hasSegment">
                        <span [ngClass]="{'positive': maintainability_data.v30Days.Value > 0, 'negative': maintainability_data.v30Days.Value < 0}">
                          <span *ngIf="maintainability_data.v30Days.Value === 0" class="zero">-</span>
                          <span *ngIf="maintainability_data.v30Days.Value != 0">{{ absify(maintainability_data.v30Days.Value) }}</span>
                          <span *ngIf="maintainability_data.v30Days.Value < 0">&nbsp;▲</span>
                          <span *ngIf="maintainability_data.v30Days.Value > 0">&nbsp;▼</span>
                        </span>
                      </span>
                    </td>
                    <td [ngClass]="{'greyed-out': !maintainability_data.v6Months.hasSegment}">
                      <span *ngIf="maintainability_data.v6Months.hasSegment">
                        <span [ngClass]="{'positive': maintainability_data.v6Months.Value > 0, 'negative': maintainability_data.v6Months.Value < 0}">
                          <span *ngIf="maintainability_data.v6Months.Value === 0" class="zero">-</span>
                          <span *ngIf="maintainability_data.v6Months.Value != 0">{{ absify(maintainability_data.v6Months.Value) }}</span>
                          <span *ngIf="maintainability_data.v6Months.Value < 0">&nbsp;▲</span>
                          <span *ngIf="maintainability_data.v6Months.Value > 0">&nbsp;▼</span>
                        </span>
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>Security</td>
                    <td [ngClass]="{'greyed-out': !security_data.v7Days.hasSegment}">
                      <span *ngIf="security_data.v7Days.hasSegment">
                        <span [ngClass]="{'positive': security_data.v7Days.Value > 0, 'negative': security_data.v7Days.Value < 0}">
                          <span *ngIf="security_data.v7Days.Value === 0" class="zero">-</span>
                          <span *ngIf="security_data.v7Days.Value != 0">{{ absify(security_data.v7Days.Value) }}</span>
                          <span *ngIf="security_data.v7Days.Value < 0">&nbsp;▲</span>
                          <span *ngIf="security_data.v7Days.Value > 0">&nbsp;▼</span>
                        </span>
                      </span>
                    </td>
                    <td [ngClass]="{'greyed-out': !security_data.v30Days.hasSegment}">
                      <span *ngIf="security_data.v30Days.hasSegment">
                        <span [ngClass]="{'positive': security_data.v30Days.Value > 0, 'negative': security_data.v30Days.Value < 0}">
                          <span *ngIf="security_data.v30Days.Value === 0" class="zero">-</span>
                          <span *ngIf="security_data.v30Days.Value != 0">{{ absify(security_data.v30Days.Value) }}</span>
                          <span *ngIf="security_data.v30Days.Value < 0">&nbsp;▲</span>
                          <span *ngIf="security_data.v30Days.Value > 0">&nbsp;▼</span>
                        </span>
                      </span>
                    </td>
                    <td [ngClass]="{'greyed-out': !security_data.v6Months.hasSegment}">
                      <span *ngIf="security_data.v6Months.hasSegment">
                        <span [ngClass]="{'positive': security_data.v6Months.Value > 0, 'negative': security_data.v6Months.Value < 0}">
                          <span *ngIf="security_data.v6Months.Value === 0" class="zero">-</span>
                          <span *ngIf="security_data.v6Months.Value != 0">{{ absify(security_data.v6Months.Value) }}</span>
                          <span *ngIf="security_data.v6Months.Value < 0">&nbsp;▲</span>
                          <span *ngIf="security_data.v6Months.Value > 0">&nbsp;▼</span>
                        </span>
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>Security Review</td>
                    <td [ngClass]="{'greyed-out': !securityReview_data.v7Days.hasSegment}">
                      <span *ngIf="securityReview_data.v7Days.hasSegment">
                        <span [ngClass]="{'positive': securityReview_data.v7Days.Value > 0, 'negative': securityReview_data.v7Days.Value < 0}">
                          <span *ngIf="securityReview_data.v7Days.Value === 0" class="zero">-</span>
                          <span *ngIf="securityReview_data.v7Days.Value != 0">{{ absify(securityReview_data.v7Days.Value) }}</span>
                          <span *ngIf="securityReview_data.v7Days.Value < 0">&nbsp;▲</span>
                          <span *ngIf="securityReview_data.v7Days.Value > 0">&nbsp;▼</span>
                        </span>
                      </span>
                    </td>
                    <td [ngClass]="{'greyed-out': !securityReview_data.v30Days.hasSegment}">
                      <span *ngIf="securityReview_data.v30Days.hasSegment">
                        <span [ngClass]="{'positive': securityReview_data.v30Days.Value > 0, 'negative': securityReview_data.v30Days.Value < 0}">
                          <span *ngIf="securityReview_data.v30Days.Value === 0" class="zero">-</span>
                          <span *ngIf="securityReview_data.v30Days.Value != 0">{{ absify(securityReview_data.v30Days.Value) }}</span>
                          <span *ngIf="securityReview_data.v30Days.Value < 0">&nbsp;▲</span>
                          <span *ngIf="securityReview_data.v30Days.Value > 0">&nbsp;▼</span>
                        </span>
                      </span>
                    </td>
                    <td [ngClass]="{'greyed-out': !securityReview_data.v6Months.hasSegment}">
                      <span *ngIf="securityReview_data.v6Months.hasSegment">
                        <span [ngClass]="{'positive': securityReview_data.v6Months.Value > 0, 'negative': securityReview_data.v6Months.Value < 0}">
                          <span *ngIf="securityReview_data.v6Months.Value === 0" class="zero">-</span>
                          <span *ngIf="securityReview_data.v6Months.Value != 0">{{ absify(securityReview_data.v6Months.Value) }}</span>
                          <span *ngIf="securityReview_data.v6Months.Value < 0">&nbsp;▲</span>
                          <span *ngIf="securityReview_data.v6Months.Value > 0">&nbsp;▼</span>
                        </span>
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div *ngIf="activeTab === 1">

              <p-dropdown appendTo="body" [(ngModel)]="selectedMeasure" [options]="availableMeasures" placeholder="Select" class="uniform-width-input" [style]="{'minWidth':'40%', 'maxWidth': '400px', 'margin-bottom': '10px'}" (onChange)="onMeasureDropdownChange($event)">
                <ng-template pTemplate="selectedItem">
                  <span *ngIf="selectedMeasure">{{ selectedMeasure }}</span>
                </ng-template>
                <ng-template let-option pTemplate="item">
                  <span>{{option}}</span>
                </ng-template>
              </p-dropdown>

              <p-chart type="line" width="500px" height="200px" [data]="pieData" [options]="pieOptions"></p-chart>

              <div class="p-message p-message-info component" *ngIf="chartMessage != ''">
                <div class="chart-explainer">
                  {{chartMessage}}
                </div>                
              </div>

              <!-- Chart Options Start -->
              <div class="chart-options-header" (click)="toggleChartOptions()">
                <span>Chart Options</span>
                <i class="toggle-icon">
                  <i class="pi" [ngClass]="{'pi-chevron-down': !isOptionsExpanded, 'pi-chevron-up': isOptionsExpanded}"></i>
                </i>
              </div>
              <div class="chart-options-content" *ngIf="isOptionsExpanded">
                <div class="flex flex-wrap gap-3 justify-center scale-options">
                  <div class="flex align-items-center">
                    <p-radioButton name="chartscale"
                                   value="last7days"
                                   [(ngModel)]="chartScale"
                                   (ngModelChange)="onScaleSelectionChange($event)"
                                   inputId="option1" />
                    <label for="option1" class="ml-2">
                      Last 7 Days
                    </label>
                  </div>

                  <div class="flex align-items-center">
                    <p-radioButton name="chartscale"
                                   value="last30days"
                                   [(ngModel)]="chartScale"
                                   (ngModelChange)="onScaleSelectionChange($event)"
                                   inputId="option2" />
                    <label for="option2" class="ml-2">
                      Last 30 Days
                    </label>
                  </div>

                  <div class="flex align-items-center">
                    <p-radioButton name="chartscale"
                                   value="last6months"
                                   [(ngModel)]="chartScale"
                                   (ngModelChange)="onScaleSelectionChange($event)"
                                   inputId="option3" />
                    <label for="option3" class="ml-2">
                      Last 6 Months
                    </label>
                  </div>

                  <div class="flex align-items-center">
                    <p-radioButton name="chartscale"
                                   value="alltime"
                                   [(ngModel)]="chartScale"
                                   (ngModelChange)="onScaleSelectionChange($event)"
                                   inputId="option4" />
                    <label for="option4" class="ml-2">
                      All-Time
                    </label>
                  </div>
                </div>
              </div>
              <!-- Chart Options End -->
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </ng-container>
</div>
