<div class="widgetCard" [class.collapsed]="isCollapsed">
  <div class="widgetCardHeader" (click)="toggleWidgetCollapse()">
    <h5>Current Tenable WAS Issues</h5>
    <div class="chevron">
      <i [class]="isCollapsed ? 'chevron-down' : 'chevron-up'"></i>
    </div>
  </div>
  <ng-container *ngIf="!isCollapsed">
    <ng-container *ngIf="wasIssuesLoading; else wasIssuesContent">
      <p-skeleton width="10%" height="1rem" margin="5px"></p-skeleton>
    </ng-container>
    <ng-template #wasIssuesContent>
      <span class="reportingLevelLabel">{{ wasReportingLevel }}</span>
      <div class="reportingLevelLabel" *ngIf="hasTargets">
        <p-dropdown appendTo="body" [(ngModel)]="selectedTarget" [options]="components" placeholder="Select" class="uniform-width-input" [style]="{'minWidth':'60%', 'maxWidth': '400px'}" (onChange)="onDropdownChange($event)">
          <ng-template pTemplate="selectedItem">
            <span *ngIf="selectedTarget">{{ selectedTarget }}</span>
          </ng-template>
          <ng-template let-option pTemplate="item">
            <span>{{option}}</span>
          </ng-template>
        </p-dropdown>
      </div>
      <div style="padding:10px;" *ngIf="wasIssuesAvailable">
        <div class="productdetails-attribute">
          <div class="productdetails-label">Latest Scan:</div>
          <div class="productdetails-value">{{ scanDate }}&nbsp;&nbsp;&nbsp;<a href="{{ scanUrl }}" target="_blank" class="pi pi-external-link"></a></div>
        </div>
        <div class="productdetails-attribute">
          <div class="productdetails-label">Config Name:</div>
          <div class="productdetails-value">{{ selectedItem.latestScan.name }}</div>
        </div>
        <div class="productdetails-attribute">
          <div class="productdetails-label">Scan Type:</div>
          <div class="productdetails-value">{{ getScanType(selectedItem.latestScan.name) }}</div>
        </div>
      </div>

      <div class="pieWidgetContainer" *ngIf="wasIssuesAvailable">
        <div class="pieWidgetLeft">
          <p-chart type="doughnut" width="250px" height="250px" [data]="wasPieData" [options]="pieOptions"></p-chart>
        </div>
        <div class="pieWidgetRight">
          <p-table [value]="wasPieData.datasets[0].data" responsiveLayout="scroll">
            <ng-template pTemplate="header">
              <tr>
                <th class="pieWidgetCell pieWidgetVulnCell pieWidgetHeader">Severity</th>
                <th class="pieWidgetCell pieWidgetVulnCell pieWidgetHeader">Count</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-data let-i="rowIndex">
              <tr>
                <td [ngClass]="getSeverityClass(wasPieData.labels[i], 'pieWidgetCell pieWidgetVulnCell')">
                  {{ wasPieData.labels[i] }}
                </td>
                <td class="pieWidgetCell pieWidgetCellValue">
                  <a [routerLink]="['/vulnerabilities', 'search']"
                     [queryParams]="getQueryParams(wasPieData.labels[i])"
                     class="no-decoration">
                    {{ data }}
                    </a>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
    </ng-template>
  </ng-container>
</div>
