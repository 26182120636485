import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EmailTemplateService {

  generateEmailContent(type: string, productName: string, productId: string, productReference: string, id: string, link: string): string {
    return `
      <div style="font-family: Arial, sans-serif; line-height: 1.6; color: #333;">
        <h2 style="color: #0056b3;">${type} Request Created</h2>
        <p>
          A ${type} request has been successfully created for:
        </p>
        <table style="border-collapse: collapse; width: 100%; margin-bottom: 20px;">
          <tr>
            <td style="padding: 8px; border: 1px solid #ddd; font-weight: bold;">Product ID:</td>
            <td style="padding: 8px; border: 1px solid #ddd;">${productId}</td>
          </tr>
          <tr>
            <td style="padding: 8px; border: 1px solid #ddd; font-weight: bold;">Product Name:</td>
            <td style="padding: 8px; border: 1px solid #ddd;">${productName}</td>
          </tr>
          <tr>
            <td style="padding: 8px; border: 1px solid #ddd; font-weight: bold;">Product Reference:</td>
            <td style="padding: 8px; border: 1px solid #ddd;">${productReference}</td>
          </tr>
            <tr>
            <td style="padding: 8px; border: 1px solid #ddd; font-weight: bold;">${type} Entry Id</td>
            <td style="padding: 8px; border: 1px solid #ddd;">${id}</td>
          </tr>
          <tr>
            <td style="padding: 8px; border: 1px solid #ddd; font-weight: bold;">Link to ${type} Page:</td>
            <td style="padding: 8px; border: 1px solid #ddd;">
                <a href="${environment.redirectUri}/product/${productId}/${link}" style="color: #0056b3; text-decoration: none;">Go to Page</a>
            </td>
          </tr>
        </table>
        <div style="margin-top: 20px; padding: 10px; background-color: #f9f9f9; border: 1px solid #ddd;">
          <p style="font-size: 14px; color: #555;">This is an automated message. If you have any questions, please reach out to the relevant contacts.</p>
        </div>
      </div>
    `;
  }
}
