<div class="widgetCard" [class.collapsed]="isCollapsed">
  <div class="widgetCardHeader" (click)="toggleWidgetCollapse()">
    <h5>Current Code Quality Metrics</h5>
    <div class="chevron">
      <i [class]="isCollapsed ? 'chevron-down' : 'chevron-up'"></i>
    </div>
  </div>
  <ng-container *ngIf="!isCollapsed">
    <ng-container *ngIf="sonarMetricsLoading; else sonarMetricsContent">
      <p-skeleton width="10%" height="1rem" margin="5px"></p-skeleton>
    </ng-container>
    <ng-template #sonarMetricsContent>
      <span class="reportingLevelLabel">{{ sonarReportingLevel }}</span>
      <div class="reportingLevelLabel" *ngIf="hasMultipleComponents">
        <p-dropdown appendTo="body" [(ngModel)]="selectedMetricName"  [options]="components" placeholder="Select" class="uniform-width-input" [style]="{'minWidth':'40%', 'maxWidth': '400px'}" (onChange)="onDropdownChange($event)">
          <ng-template pTemplate="selectedItem">
            <span *ngIf="selectedMetricName">{{ selectedMetricName }}</span>
          </ng-template>
          <ng-template let-option pTemplate="item">
            <span>{{option}}</span>
          </ng-template>
        </p-dropdown>
      </div>
      <div class="metricsWidgetContainer" *ngIf="sonarMetricsAvailable">

        <div class="metricsWidgetLeftSide">
          <div class="metrics">
            <div class="metricRow">
              <span class="metricLabel">Lines of code:</span>
              <span class="metricValue"><strong>{{ sonarMeasuresLoc }}</strong></span>
            </div>
            <div class="metricRow">
              <span class="metricLabel">Technical Debt:</span>
              <span class="metricValue"><strong>{{ sonarMeasuresTechDebt }}</strong></span>
            </div>
            <div class="metricRow">
              <span class="metricLabel">Complexity:</span>
              <span class="metricValue"><strong>{{ sonarMeasuresComplexity }}</strong></span>
            </div>
            <div class="metricRow">
              <span class="metricLabel">Hotspots:</span>
              <span class="metricValue"><strong>{{ sonarMeasuresSecurityHotspots }}</strong></span>
            </div>
            <div class="metricRow">
              <span class="metricLabel">Code Coverage:</span>
              <span class="metricValue">&nbsp;</span>
            </div>
          </div>
          <div class="bottomMetrics">
            <div class="metricBox">
              <ngx-gauge [value]="sonarMeasuresCoverage"
                         [label]="'%'"
                         [max]="100"
                         [type]="'full'"
                         accesskey=""
                         [size]="120"
                         [ngClass]="{'gaugeRed' : sonarMeasuresCoverage < 50,'gaugeYellow': sonarMeasuresCoverage >= 50 && sonarMeasuresCoverage < 80,'gaugeGreen': sonarMeasuresCoverage >= 80}"
                         [foregroundColor]="getGaugeColor()"
                         [backgroundColor]="'#e9ecef'"
                         [cap]="'round'"
                         [thick]="5">
              </ngx-gauge>
            </div>
          </div>
        </div>
        <div class="metricsWidgetRightSide">
          <div class="gradeBox reliability" [ngClass]="getGradeClass(sonarMeasuresReliability)">
            <span>Reliability</span>
            <strong>{{ getGradeLetter(sonarMeasuresReliability) }}</strong>
          </div>
          <div class="gradeBox maintainability" [ngClass]="getGradeClass(sonarMeasuresMaintainability)">
            <span>Maintainability</span>
            <strong>{{ getGradeLetter(sonarMeasuresMaintainability) }}</strong>
          </div>
          <div class="gradeBox security" [ngClass]="getGradeClass(sonarMeasuresSecurity)">
            <span>Security</span>
            <strong>{{ getGradeLetter(sonarMeasuresSecurity) }}</strong>
          </div>
          <div class="gradeBox securityReview" [ngClass]="getGradeClass(sonarMeasuresSecurityReview)">
            <span>Security Review</span>
            <strong>{{ getGradeLetter(sonarMeasuresSecurityReview) }}</strong>
          </div>
        </div>
      </div>
    </ng-template>
  </ng-container>
</div>
