import { Component } from '@angular/core';

@Component({
  selector: 'app-useful-tools',
  standalone: false,
  templateUrl: './useful-tools.component.html',
  styleUrl: './useful-tools.component.scss'
})
export class UsefulToolsComponent {

}
