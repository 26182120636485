<h1>{{ arbCode }}</h1>

<ng-container *ngIf="isARBLoading">
  <p-skeleton width="10%" height="1rem"></p-skeleton>
</ng-container>


<div class="productdetails-container" *ngIf="!isARBLoading">
  <div class="productdetails-top-row">
    <div class="productdetails-left-column">
      <p-panel>
        <ng-template pTemplate="header">
          <div class="details-panel-header-container">
            <span class="font-bold details-panel-header-title">
              Details
            </span>
          </div>
        </ng-template>
        <div class="productdetails-attribute">
          <div class="productdetails-label">Code:</div>
          <div class="productdetails-value">{{ arb.code }}</div>
        </div>
        <div class="productdetails-attribute">
          <div class="productdetails-label">Title:</div>
          <div class="productdetails-value">{{ arb.title }}</div>
        </div>
        <div class="productdetails-attribute">
          <div class="productdetails-label">Date:</div>
          <div class="productdetails-value">{{ niceDate(arb.date) }}</div>
        </div>
        <div class="productdetails-attribute">
          <div class="productdetails-label">Chair:</div>
          <div class="productdetails-value">{{ arb.chair }}</div>
        </div>
        <div class="productdetails-attribute">
          <div class="productdetails-label">Products:</div>
          <div class="productdetails-value">
            <ng-container *ngFor="let item of findProduct(arb.products)">
              <ng-container *ngIf="item.ID">
                <a [routerLink]="['/product', item.ID, 'dashboard']" target="_blank" class="no-decoration">
                  <p-tag value="{{ item.Name }}" [styleClass]="'cursor-pointer product-badge producttype-' + item.Type"
                         pTooltip="{{ item.Name }}" tooltipPosition="top"></p-tag>
                </a>
              </ng-container>
              <ng-container *ngIf="!item.ID">
                <p-tag value="{{ item.Name }}" [styleClass]="'cursor-pointer product-badge producttype-' + item.Type"
                       pTooltip="{{ item.Name }}" tooltipPosition="top"></p-tag>
              </ng-container>
            </ng-container>
          </div>
        </div>
        <div class="productdetails-attribute">
          <div class="productdetails-label">Suite:</div>
          <ng-container *ngIf="arb.suite && findSuite(arb.suite) !== null">
            <a [routerLink]="['/suite', findSuite(arb.suite)?.Id ?? '', 'dashboard']" target="_blank" class="no-decoration">
              <p-tag value="{{ findSuite(arb.suite)?.Name ?? arb.suite }}"
                     [styleClass]="'cursor-pointer suite-badge suite-' + tagify(findSuite(arb.suite)?.Name ?? arb.suite)"
                     pTooltip="Suite"
                     tooltipPosition="top">
              </p-tag>
            </a>
          </ng-container>
          <ng-container *ngIf="findSuite(arb.suite) == null">
            <div class="productdetails-value">{{ arb.suite }}</div>
          </ng-container>
        </div>
        <div class="productdetails-attribute">
          <div class="productdetails-label">Status:</div>
          <div class="productdetails-value">
            <div [ngClass]="'arbstatus-badge arb-' + arb.currentStatus ">
              {{ arb.currentStatus }}
            </div>
          </div>
        </div>
      </p-panel>

    </div>
    <div class="productdetails-right-column">
      <p-panel>
        <ng-template pTemplate="header">
          <div class="details-panel-header-container">
            <span class="font-bold details-panel-header-title">
              Links
            </span>
          </div>
        </ng-template>
        <div class="productdetails-attribute">
          <div class="productdetails-label">Edit Details Link:</div>
          <div class="productdetails-value"><a href="https://irissoftware.sharepoint.com/sites/EngineeringIntranet/Lists/Historical%20ARBs/DispForm.aspx?ID={{ arb.id }}&e=Yd0lxO" target="_blank" class="pi pi-external-link" title="Link to SharePoint Edit Page"></a></div>
        </div>
        <div class="productdetails-attribute">
          <div class="productdetails-label">Solution Design Pack:</div>
          <div class="productdetails-value"><a href="{{ arb.packUrl }}" target="_blank" class="pi pi-external-link" title="Link to Minutes"></a></div>
        </div>
        <div class="productdetails-attribute">
          <div class="productdetails-label">Minutes:</div>
          <div class="productdetails-value"><a href="{{ arb.url }}" target="_blank" class="pi pi-external-link" title="Link to Minutes"></a></div>
        </div>
      </p-panel>
    </div>
  </div>
</div>

<h2 *ngIf="hasARB && !isARBLoading">Follow-Up Actions</h2>

<p-table [value]="arb.actions" *ngIf="hasARB && !isARBLoading && arb.actions.length > 0">
  <ng-template pTemplate="header">
    <tr>
      <th style="width:5%"></th>
      <th style="width:75%">Follow-Up Action Item</th>
      <th style="width:20%">Status</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-action>
    <tr>
      <td>
        <a href="{{ getActionItemEditURL(action.id) }}" class="pi pi-pencil" target="_blank" title="Edit Action Item"></a>
      </td>
      <td>{{action.description}}</td>
      <td>
        <div [ngClass]="'arbactionstatus-badge arbaction-' + tagify(action.status) ">
          {{ action.status }}
        </div>
      </td>
    </tr>
  </ng-template>
</p-table>

<div *ngIf="hasARB && !isARBLoading && arb.actions.length == 0">
  <div>No follow-up actions.</div>
</div>

<div *ngIf="!hasARB && !isARBLoading">
  <div>ARB details not found.</div>
</div>
