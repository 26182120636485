<h1>
  Vulnerability Search
</h1>

<p-toast></p-toast>
<p-messages [(value)]="messages" [enableService]="false" [closable]="true"></p-messages>

<div *ngIf="isLoading">
  <p-skeleton width="10%" height="1rem"></p-skeleton>
</div>

<div *ngIf="!isLoading">

  <div>
    <h3>Filters</h3>
  </div>

  <div style="display: flex; flex-direction: column; margin-bottom: 20px;">
    <div style="display: flex; flex-wrap: wrap; gap: 5px; max-width: 100%;">
      <p-multiSelect [options]="tiers"
                     [(ngModel)]="selectedTiers"
                     placeholder="Select Tiers"
                     optionLabel="Name"
                     display="chip"
                     [showToggleAll]="false"
                     (onChange)="onTiersChange($event)">
      </p-multiSelect>
      <p-multiSelect [options]="divisions"
                     [(ngModel)]="selectedDivisions"
                     placeholder="Select Divisions"
                     optionLabel="Name"
                     display="chip"
                     [showToggleAll]="false"
                     (onChange)="onDivisionsChange($event)">
      </p-multiSelect>
      <p-multiSelect [options]="suites"
                     [(ngModel)]="selectedSuites"
                     placeholder="Select Suites"
                     optionLabel="Name"
                     display="chip"
                     [showToggleAll]="false"
                     (onChange)="onSuitesChange($event)">
      </p-multiSelect>
      <p-multiSelect [options]="products"
                     [(ngModel)]="selectedProducts"
                     placeholder="Select Products"
                     optionLabel="Name"
                     display="chip"
                     [showToggleAll]="false"
                     (onChange)="onProductsChange($event)">
      </p-multiSelect>
      <p-multiSelect [options]="severities"
                     [(ngModel)]="selectedSeverities"
                     placeholder="Select Severities"
                     optionLabel="Name"
                     display="chip"
                     [showToggleAll]="false"
                     (onChange)="onSeveritiesChange($event)">
      </p-multiSelect>
      <p-multiSelect [options]="types"
                     [(ngModel)]="selectedTypes"
                     placeholder="Select Type"
                     optionLabel="Name"
                     display="chip"
                     [showToggleAll]="false"
                     (onChange)="onTypeChange($event)">
      </p-multiSelect>
      <p-multiSelect [options]="ages"
                     [(ngModel)]="selectedAges"
                     placeholder="Select Age"
                     optionLabel="Name"
                     display="chip"
                     [showToggleAll]="false"
                     (onChange)="onAgedChange($event)">
      </p-multiSelect>
      <p-multiSelect [options]="tools"
                     [(ngModel)]="selectedTools"
                     placeholder="Select Tools"
                     optionLabel="Name"
                     display="chip"
                     [showToggleAll]="false"
                     (onChange)="onToolsChange($event)">
      </p-multiSelect>
      <p-multiSelect [options]="practices"
                     [(ngModel)]="selectedPractices"
                     placeholder="Select Practices"
                     optionLabel="Name"
                     display="chip"
                     [showToggleAll]="false"
                     (onChange)="onPracticesChange($event)">
      </p-multiSelect>
    </div>
    <div style="margin-top: 10px; display: flex; flex-direction: row; gap: 10px; min-width:500px">

      <label for="chipsTerms" style="text-align:center; vertical-align:middle">Key term:</label>

      <p-chips id="chipsTerms"
               (onAdd)="onKeyTermsAdd($event)"
               (onRemove)="onKeyTermsRemove($event)"
               [(ngModel)]="keyTerms">
      </p-chips>
      <p-multiSelect [options]="countries"
                     [(ngModel)]="selectedCountries"
                     placeholder="Select Countries"
                     optionLabel="Name"
                     display="chip"
                     [showToggleAll]="false"
                     (onChange)="onCountriesChange($event)">
      </p-multiSelect>
      <p-multiSelect [options]="bases"
                     [(ngModel)]="selectedBases"
                     placeholder="Select Base Vulnerabilities"
                     display="chip"
                     [showToggleAll]="false"
                     (onChange)="onBasesChange($event)">
      </p-multiSelect>

      <p-checkbox [binary]="true"
                  [(ngModel)]="showActiveOnly"
                  name="showActiveOnly"
                  (onChange)="onShowActiveOnlyChange($event)"
                  label="Active Projects Only">
      </p-checkbox>

      <p-checkbox [binary]="true"
                  [(ngModel)]="showFixableOnly"
                  name="showFixableOnly"
                  (onChange)="onShowFixableOnlyChange($event)"
                  label="Fixable Issues Only [Snyk]">
      </p-checkbox>
    </div>
  </div>
  <div style="margin-top:10px; margin-bottom:20px">
    <button pButton pRipple label="Search" icon="pi pi-search" (click)="doSearch()" title="Search"></button>
    <button pButton pRipple icon="pi pi-icon-button pi-clipboard" (click)="copyUrl()" style="margin-left:10px" title="Copy Link to Clipboard"></button>
  </div>

  <div *ngIf="isReady">

    <div>
      <h3>Filter Totals</h3>
    </div>

    <div *ngIf="dataIsLoading">
      <p-skeleton width="10%" height="1rem"></p-skeleton>
    </div>

    <div class="content" *ngIf="!dataIsLoading">
      <div style="margin-bottom: 5px; margin-top: 5px; text-align: right; font-size: smaller">
        <p-radioButton name="group"
                       value="Vulnerabilities"
                       label="Product Vulnerabilities"
                       [(ngModel)]="selectedValue"
                       (onClick)="onValueChange()"
                       styleClass="custom-radio-button"></p-radioButton>
        <span style="margin: 0 10px;"></span>
        <p-radioButton name="group"
                       value="Instances"
                       label="Instances"
                       [(ngModel)]="selectedValue"
                       (onClick)="onValueChange()"
                       styleClass="custom-radio-button"></p-radioButton>
      </div>
      <table>
        <tr>
          <th style="background-color: var(--critical)">Critical</th>
          <th style="background-color: var(--high)">High</th>
          <th style="background-color: var(--medium)">Medium</th>
          <th style="background-color: var(--low)">Low</th>
        </tr>
        <tr>
          <td class="totalCell totalCritical">{{criticals}}</td>
          <td class="totalCell totalHigh">{{highs}}</td>
          <td class="totalCell totalMedium">{{mediums}}</td>
          <td class="totalCell totalLow">{{lows}}</td>
        </tr>
      </table>
    </div>

    <div style="margin-top:20px">
      <h3>Results</h3>
    </div>

    <div *ngIf="dataIsLoading">
      <p-skeleton width="10%" height="1rem"></p-skeleton>
    </div>

    <div style="margin-top:20px" *ngIf="!dataIsLoading">

      <div style="margin-bottom:5px; text-align:right">
        <button type="button" icon="pi pi-file-export" title="Export as CSV" class="p-element p-ripple p-button-success p-button p-component p-button-icon-only ml-7" (click)="buildCSV()">
          <span class="p-button-icon pi pi-file-export" aria-hidden="true"></span>
        </button>
      </div>

      <p-table [value]="data"
               [paginator]="true"
               [rows]="rowsPerPage"
               [sortField]="sortField"
               [sortOrder]="sortOrder"
               (sort)="onSort($event)">
        <ng-template pTemplate="header">
          <tr>
            <th style="width:20%" pSortableColumn="lensObjectCode">
              Product
              <p-sortIcon [field]="'lensObjectCode'"></p-sortIcon>
            </th>
            <th style="width:7%" pSortableColumn="rankedSeverity">
              Severity
              <p-sortIcon [field]="'rankedSeverity'"></p-sortIcon>
            </th>
            <th style="width:10%" pSortableColumn="toolName">
              Tool Name
              <p-sortIcon [field]="'toolName'"></p-sortIcon>
            </th>
            <th style="width:8%" pSortableColumn="age">
              Age
              <p-sortIcon [field]="'age'"></p-sortIcon>
            </th>
            <th style="width:9%" pSortableColumn="instances">
              Instances
              <p-sortIcon [field]="'instances'"></p-sortIcon>
            </th>
            <th style="width:41%">Synopsis</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData>
          <tr>
            <td style="width:20%">
              <a [routerLink]="['/vulnerabilities', rowData.id, 'details']"
                 [queryParams]="{ toolId: rowData.toolName }"
                 class="no-decoration">
                [{{ rowData.lensProductTypeName }}] - {{ rowData.lensObjectName }} ({{ rowData.lensObjectCode }})
              </a>
            </td>
            <td style="width:7%" class="{{ rowData.severity.toLowerCase() }}Col">
              {{ rowData.severity }}
            </td>
            <td style="width:10%">
              <a [routerLink]="['/vulnerabilities', rowData.id, 'details']"
                 [queryParams]="{ toolId: rowData.toolName }"
                 class="no-decoration">
                {{ shortenToolName(rowData.toolName) }}
              </a>
            </td>
            <td style="width:8%">
              <a [routerLink]="['/vulnerabilities', rowData.id, 'details']"
                 [queryParams]="{ toolId: rowData.toolName }"
                 class="no-decoration">
                {{ rowData.age }}
              </a>
            </td>
            <td style="width:9%">
              <a [routerLink]="['/vulnerabilities', rowData.id, 'details']"
                 [queryParams]="{ toolId: rowData.toolName }"
                 class="no-decoration">
                {{ rowData.instances }}
              </a>
            </td>
            <td style="width:41%">
              <a [routerLink]="['/vulnerabilities', rowData.id, 'details']"
                 [queryParams]="{ toolId: rowData.toolName }"
                 class="no-decoration">
                {{ enhancedSynopsis(rowData) }}
              </a>
            </td>
          </tr>
        </ng-template>
      </p-table>
      <div style="margin-top: 10px;">
        <label for="rowsPerPage">Rows per page: </label>
        <p-dropdown id="rowsPerPage"
                    [options]="rowsOptions"
                    [(ngModel)]="rowsPerPage"
                    (onChange)="onRowsPerPageChange($event.value)"
                    placeholder="Select rows per page"></p-dropdown>
      </div>
    </div>
  </div>
</div>

