import { HttpClient, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, map, catchError, throwError } from "rxjs";
import { ProductTechSpec, TechSpecAuthenticationMechanismRequestDto, TechSpecBacklogToolRequestDto, TechSpecBuildToolRequestDto, TechSpecDeploymentToolRequestDto, TechSpecEnvironmentRequestDto, TechSpecHostingProviderRequestDto, TechSpecSourceControlProviderRequestDto, TechSpecSourceControlTypeRequestDto, TechSpecStackRequestDto, TechStackDto, TechStackRequestDto } from "src/app/api/product-tech-spec";
import { DistributionDto, TechSpecDistributionRequestDto, TechSpecPlatformRequestDto, ProductTechSpecPutDto } from "src/app/api/product-tech-spec";
import { environment } from "src/environments/environment";

@Injectable(
    {
        providedIn: 'root'
    }
)

export class ProductTechSpecService {
    baseApiUrl: string = environment.baseApiUrl;
    constructor(private http: HttpClient) { }

    getProductTechSpecById(techSpecId: number): Observable<ProductTechSpec> {
      return this.http.get<ProductTechSpec>(this.baseApiUrl + `/api/ProductTechSpecs/${techSpecId}?$expand=TechSpecDistributions, TechSpecStacks, TechSpecBuildTools, TechSpecDeploymentTools, TechSpecSourceControlProviders, TechSpecSourceControlTypes, TechSpecBacklogTools, TechSpecHostingProviders, TechSpecPlatforms, TechSpecEnvironments, TechSpecAuthenticationMechanisms`);
    }

    updateProductTechSpec(id: number, techSpecPutDto: ProductTechSpecPutDto): Observable<ProductTechSpecPutDto> {
      return this.http.put<ProductTechSpecPutDto>(this.baseApiUrl + `/api/ProductTechSpecs/${id}`, techSpecPutDto);
    }

    // updateProductTechSpec(id: number, techSpecPutDto: ProductTechSpecPutDto): Observable<string> {
    //   return this.http.put<ProductTechSpecPutDto>(this.baseApiUrl + `/api/ProductTechSpecs/${id}`, techSpecPutDto, { observe: 'response' })
    //     .pipe(
    //       catchError((error) => {
    //         console.error(error);
    //         return throwError(error);
    //       }),
    //       map((response: HttpResponse<ProductTechSpecPutDto>) => {
    //         if (response.status === 204) {
    //           return 'No changes were made.';
    //         } else if (response.status === 200) {
    //           return 'Update successful.';
    //         }
    //         // Handle other status codes if needed
  
    //         return 'Unknown response status.';
    //       })
    //     );
    // }

    // Distribution

    getAllDistributions(): Observable<DistributionDto[]> {
      return this.http.get<any>(this.baseApiUrl + `/api/Distributions`).pipe(
        map((response) => {
          // Check if the response has a "value" property
          if (response && response.value) {
            // Return the array of objects from the "value" property
            return response.value;
          } else {
            // If there is no "value" property, return the entire response
            return response;
          }
        })
      );
    }

    addTechSpecDistribution(techSpecDistributionRequest: TechSpecDistributionRequestDto): Observable<TechSpecDistributionRequestDto> {
      return this.http.post<TechSpecDistributionRequestDto>(this.baseApiUrl + `/api/TechSpecDistributions`, techSpecDistributionRequest);
    }

    removeTechSpecDistribution(id: number): Observable<any> {
      return this.http.delete<any>(this.baseApiUrl + `/api/TechSpecDistributions/${id}`);
    }


    // Tech Stack

    getAllTechStacks(): Observable<TechStackDto[]> {
      return this.http.get<any>(this.baseApiUrl + `/api/TechStacks`).pipe(
        map((response) => {
          // Check if the response has a "value" property
          if (response && response.value) {
            // Return the array of objects from the "value" property
            return response.value;
          } else {
            // If there is no "value" property, return the entire response
            return response;
          }
        })
      );
    }

    addTechSpecStack(techSpecStackRequest: TechSpecStackRequestDto): Observable<TechSpecStackRequestDto> {
      return this.http.post<TechSpecStackRequestDto>(this.baseApiUrl + `/api/TechSpecStacks`, techSpecStackRequest);
    }

    removeTechSpecStack(id: number): Observable<any> {
      return this.http.delete<any>(this.baseApiUrl + `/api/TechSpecStacks/${id}`);
    }

    // Build Tool

    getAllBuildTools(): Observable<any> {
      return this.http.get<any>(this.baseApiUrl + `/api/BuildTools`).pipe(
        map((response) => {
          // Check if the response has a "value" property
          if (response && response.value) {
            // Return the array of objects from the "value" property
            return response.value;
          } else {
            // If there is no "value" property, return the entire response
            return response;
          }
        })
      );
    }

    addTechSpecBuildTool(techSpecBuildToolRequest: TechSpecBuildToolRequestDto): Observable<TechSpecBuildToolRequestDto> {
      return this.http.post<TechSpecBuildToolRequestDto>(this.baseApiUrl + `/api/TechSpecBuildTools`, techSpecBuildToolRequest);
    }

    removeTechSpecBuildTool(id: number): Observable<any> {
      return this.http.delete<any>(this.baseApiUrl + `/api/TechSpecBuildTools/${id}`);
    }

    // Deployment Tool

    getAllDeploymentTools(): Observable<any> {
      return this.http.get<any>(this.baseApiUrl + `/api/DeploymentTools`).pipe(
        map((response) => {
          // Check if the response has a "value" property
          if (response && response.value) {
            // Return the array of objects from the "value" property
            return response.value;
          } else {
            // If there is no "value" property, return the entire response
            return response;
          }
        })
      );
    }

    addTechSpecDeploymentTool(techSpecDeploymentToolRequest: TechSpecDeploymentToolRequestDto): Observable<TechSpecDeploymentToolRequestDto> {
      return this.http.post<TechSpecDeploymentToolRequestDto>(this.baseApiUrl + `/api/TechSpecDeploymentTools`, techSpecDeploymentToolRequest);
    }

    removeTechSpecDeploymentTool(id: number): Observable<any> {
      return this.http.delete<any>(this.baseApiUrl + `/api/TechSpecDeploymentTools/${id}`);
    }

    // Source Control Provider

    getAllSourceControlProviders(): Observable<any> {
      return this.http.get<any>(this.baseApiUrl + `/api/SourceControlProviders`).pipe(
        map((response) => {
          // Check if the response has a "value" property
          if (response && response.value) {
            // Return the array of objects from the "value" property
            return response.value;
          } else {
            // If there is no "value" property, return the entire response
            return response;
          }
        })
      );
    }

    addTechSpecSourceControlProvider(techSpecSourceControlProviderRequest: TechSpecSourceControlProviderRequestDto): Observable<TechSpecSourceControlProviderRequestDto> {
      return this.http.post<TechSpecSourceControlProviderRequestDto>(this.baseApiUrl + `/api/TechSpecSourceControlProviders`, techSpecSourceControlProviderRequest);
    }

    removeTechSpecSourceControlProvider(id: number): Observable<any> {
      return this.http.delete<any>(this.baseApiUrl + `/api/TechSpecSourceControlProviders/${id}`);
    }

    // Source Control Type

    getAllSourceControlTypes(): Observable<any> {
      return this.http.get<any>(this.baseApiUrl + `/api/SourceControlTypes`).pipe(
        map((response) => {
          // Check if the response has a "value" property
          if (response && response.value) {
            // Return the array of objects from the "value" property
            return response.value;
          } else {
            // If there is no "value" property, return the entire response
            return response;
          }
        })
      );
    }

    addTechSpecSourceControlType(techSpecSourceControlTypeRequest: TechSpecSourceControlTypeRequestDto): Observable<TechSpecSourceControlTypeRequestDto> {
      return this.http.post<TechSpecSourceControlTypeRequestDto>(this.baseApiUrl + `/api/TechSpecSourceControlTypes`, techSpecSourceControlTypeRequest);
    }

    removeTechSpecSourceControlType(id: number): Observable<any> {
      return this.http.delete<any>(this.baseApiUrl + `/api/TechSpecSourceControlTypes/${id}`);
    }

    // Backlog Tool

    getAllBacklogTools(): Observable<any> {
      return this.http.get<any>(this.baseApiUrl + `/api/BacklogTools`).pipe(
        map((response) => {
          // Check if the response has a "value" property
          if (response && response.value) {
            // Return the array of objects from the "value" property
            return response.value;
          } else {
            // If there is no "value" property, return the entire response
            return response;
          }
        })
      );
    }

    addTechSpecBacklogTool(techSpecBacklogToolRequest: TechSpecBacklogToolRequestDto): Observable<TechSpecBacklogToolRequestDto> {
      return this.http.post<TechSpecBacklogToolRequestDto>(this.baseApiUrl + `/api/TechSpecBacklogTools`, techSpecBacklogToolRequest);
    }

    removeTechSpecBacklogTool(id: number): Observable<any> {
      return this.http.delete<any>(this.baseApiUrl + `/api/TechSpecBacklogTools/${id}`);
    }

    // Hosting Provider

    getAllHostingProviders(): Observable<any> {
      return this.http.get<any>(this.baseApiUrl + `/api/HostingProviders`).pipe(
        map((response) => {
          // Check if the response has a "value" property
          if (response && response.value) {
            // Return the array of objects from the "value" property
            return response.value;
          } else {
            // If there is no "value" property, return the entire response
            return response;
          }
        })
      );
    }

    addTechSpecHostingProvider(techSpecHostingProviderRequest: TechSpecHostingProviderRequestDto): Observable<TechSpecHostingProviderRequestDto> {
      return this.http.post<TechSpecHostingProviderRequestDto>(this.baseApiUrl + `/api/TechSpecHostingProviders`, techSpecHostingProviderRequest);
    }

    removeTechSpecHostingProvider(id: number): Observable<any> {
      return this.http.delete<any>(this.baseApiUrl + `/api/TechSpecHostingProviders/${id}`);
    }

    // Platform

    getAllPlatforms(): Observable<any> {
      return this.http.get<any>(this.baseApiUrl + `/api/Platforms`).pipe(
        map((response) => {
          // Check if the response has a "value" property
          if (response && response.value) {
            // Return the array of objects from the "value" property
            return response.value;
          } else {
            // If there is no "value" property, return the entire response
            return response;
          }
        })
      );
    }

    addTechSpecPlatform(techSpecPlatformRequest: TechSpecPlatformRequestDto): Observable<TechSpecPlatformRequestDto> {
      return this.http.post<TechSpecPlatformRequestDto>(this.baseApiUrl + `/api/TechSpecPlatforms`, techSpecPlatformRequest);
    }

    removeTechSpecPlatform(id: number): Observable<any> {
      return this.http.delete<any>(this.baseApiUrl + `/api/TechSpecPlatforms/${id}`);
    }

    // Environment Type

    getAllEnvironmentTypes(): Observable<any> {
      return this.http.get<any>(this.baseApiUrl + `/api/EnvironmentTypes`).pipe(
        map((response) => {
          // Check if the response has a "value" property
          if (response && response.value) {
            // Return the array of objects from the "value" property
            return response.value;
          } else {
            // If there is no "value" property, return the entire response
            return response;
          }
        })
      );
    }

    addTechSpecEnvironment(techSpecEnvironmentRequest: TechSpecEnvironmentRequestDto): Observable<TechSpecEnvironmentRequestDto> {
      return this.http.post<TechSpecEnvironmentRequestDto>(this.baseApiUrl + `/api/TechSpecEnvironments`, techSpecEnvironmentRequest);
    }

    removeTechSpecEnvironment(id: number): Observable<any> {
      return this.http.delete<any>(this.baseApiUrl + `/api/TechSpecEnvironments/${id}`);
    }

    // Authentication Mechanisms

    getAllAuthenticationMechanisms(): Observable<any> {
      return this.http.get<any>(this.baseApiUrl + `/api/AuthenticationMechanisms`).pipe(
        map((response) => {
          // Check if the response has a "value" property
          if (response && response.value) {
            // Return the array of objects from the "value" property
            return response.value;
          } else {
            // If there is no "value" property, return the entire response
            return response;
          }
        })
      );
    }

    addTechSpecAuthenticationMechanism(techSpecAuthenticationMechanismRequest: TechSpecAuthenticationMechanismRequestDto): Observable<TechSpecAuthenticationMechanismRequestDto> {
      return this.http.post<TechSpecAuthenticationMechanismRequestDto>(this.baseApiUrl + `/api/TechSpecAuthenticationMechanisms`, techSpecAuthenticationMechanismRequest);
    }

    removeTechSpecAuthenticationMechanism(id: number): Observable<any> {
      return this.http.delete<any>(this.baseApiUrl + `/api/TechSpecAuthenticationMechanisms/${id}`);
    }

    // Add entries
    addTechStack(techStack: TechStackRequestDto): Observable<TechStackRequestDto> {
      return this.http.post<TechStackRequestDto>(this.baseApiUrl + `/api/TechStacks`, techStack);
    }
}