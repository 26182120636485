<h1>{{ assetName }}</h1>
<p-toast></p-toast>
<ng-container *ngFor="let item of asset?.system_types">
  <p-tag value="{{ item }}" class="asset-details-tag-alt"
         pTooltip="{{ item }}" tooltipPosition="top"></p-tag>
</ng-container>
<ng-container *ngFor="let provider of platformTags">
  <p-tag value="{{ provider }}" [styleClass]="'cursor-pointer hostingprovider-badge provider-' + tagify(provider)"
         pTooltip="{{ provider }}" tooltipPosition="top"></p-tag>
</ng-container>
<ng-container *ngFor="let platform of opSystemTags">
  <p-tag value="{{ platform }}" [styleClass]="'cursor-pointer techplatform-badge platform-' + tagify(platform)"
         pTooltip="{{ platform }}" tooltipPosition="top"></p-tag>
</ng-container>

<p-messages [(value)]="messages" [enableService]="false" [closable]="true"></p-messages>


<div class="productdetails-container">
  <div class="productdetails-top-row">
    <div class="productdetails-left-column">
      <p-panel>
        <ng-template pTemplate="header">
          <div class="details-panel-header-container">
            <span class="font-bold details-panel-header-title">
              Network
            </span>
          </div>
        </ng-template>
        <div class="productdetails-attribute">
          <div class="productdetails-label">Network name:</div>
          <div class="productdetails-value">{{ asset?.network_name ?? "" }}</div>
        </div>
        <div class="productdetails-attribute">
          <div class="productdetails-label">Network ID:</div>
          <div class="productdetails-value">{{ asset?.network_id ?? "" }}</div>
        </div>
        <div class="productdetails-attribute">
          <div class="productdetails-label">Host Names:</div>
          <div class="productdetails-value">
            <ng-container *ngFor="let item of asset?.hostnames">
              <p-tag value="{{ item }}" class="asset-details-tag"
                     pTooltip="{{ item }}" tooltipPosition="top"></p-tag>
            </ng-container>
          </div>
        </div>
        <div class="productdetails-attribute">
          <div class="productdetails-label">FQDNs:</div>
          <div class="productdetails-value">
            <ng-container *ngFor="let item of asset?.fqdns">
              <p-tag value="{{ item }}" class="asset-details-tag"
                     pTooltip="{{ item }}" tooltipPosition="top"></p-tag>
            </ng-container>
          </div>
        </div>
        <div class="productdetails-attribute">
          <div class="productdetails-label">IPs (v4):</div>
          <div class="productdetails-value">
            <ng-container *ngFor="let item of asset?.ipv4s">
              <p-tag value="{{ item }}" class="asset-details-tag"
                     pTooltip="{{ item }}" tooltipPosition="top"></p-tag>
            </ng-container>
          </div>
        </div>
        <div class="productdetails-attribute">
          <div class="productdetails-label">IPs (v6):</div>
          <div class="productdetails-value">
            <ng-container *ngFor="let item of asset?.ipv6s">
              <p-tag value="{{ item }}" class="asset-details-tag"
                     pTooltip="{{ item }}" tooltipPosition="top"></p-tag>
            </ng-container>
          </div>
        </div>
        <div class="productdetails-attribute">
          <div class="productdetails-label">Netbios Names:</div>
          <div class="productdetails-value">
            <ng-container *ngFor="let item of asset?.netbios_names">
              <p-tag value="{{ item }}" class="asset-details-tag"
                     pTooltip="{{ item }}" tooltipPosition="top"></p-tag>
            </ng-container>
          </div>
        </div>
      </p-panel>
      <p-panel styleClass="details-panel">
        <ng-template pTemplate="header">
          <div class="details-panel-header-container">
            <span class="font-bold details-panel-header-title">
              Technology
            </span>
          </div>
        </ng-template>
        <div class="productdetails-attribute">
          <div class="productdetails-label">Operating Systems:</div>
          <div class="productdetails-value">
            <ng-container *ngFor="let item of asset?.operating_systems">
              <p-tag value="{{ item }}" class="asset-details-tag"
                     pTooltip="{{ item }}" tooltipPosition="top"></p-tag>
            </ng-container>
          </div>
        </div>
        <div class="productdetails-attribute">
          <div class="productdetails-label">Installed Software:</div>
          <div class="productdetails-value">
            <ng-container *ngFor="let item of asset?.installed_software">
              <p-tag value="{{ item }}" class="asset-details-tag"
                     pTooltip="{{ item }}" tooltipPosition="top"></p-tag>
            </ng-container>
          </div>
        </div>
      </p-panel>
    </div>
    <div class="productdetails-right-column">
      <p-panel *ngIf="isAzure">
        <ng-template pTemplate="header">
          <div class="details-panel-header-container">
            <span class="font-bold details-panel-header-title">
              Azure
            </span>
          </div>
        </ng-template>
        <div style="position:relative; width:100%; overflow:hidden;">
          <!-- Container for the image -->
          <div style="position:absolute; top:0; right:0;">
            <img src="/assets/layout/images/badgelogos/azure_logo_full.png" height="50" width="auto" />
          </div>
          <div style="margin-right: 200px;">
            <div class="productdetails-attribute" style="clear:right;">
              <div class="productdetails-label">Azure VM ID:</div>
              <div class="productdetails-value">{{ asset?.azure_vm_id }}</div>
            </div>
            <div class="productdetails-attribute">
              <div class="productdetails-label">Azure Resource ID:</div>
              <div class="productdetails-value">{{ asset?.azure_resource_id }}</div>
            </div>
          </div>
        </div>
      </p-panel>
      <p-panel *ngIf="isAWS">
        <ng-template pTemplate="header">
          <div class="details-panel-header-container">
            <span class="font-bold details-panel-header-title">
              AWS
            </span>
          </div>
        </ng-template>
        <div style="position:relative; width:100%; overflow:hidden;">
          <!-- Container for the image -->
          <div style="position:absolute; top:0; right:0;">
            <img src="/assets/layout/images/badgelogos/aws.png" height="100" width="auto" />
          </div>
          <div style="margin-right: 70px;">
            <div class="productdetails-attribute" style="clear:right;">
              <div class="productdetails-label">EC2 Instance AMI ID:</div>
              <div class="productdetails-value">{{ asset?.aws_ec2_instance_ami_id }}</div>
            </div>
            <div class="productdetails-attribute">
              <div class="productdetails-label">EC2 Instance ID:</div>
              <div class="productdetails-value">{{ asset?.aws_ec2_instance_id }}</div>
            </div>
            <div class="productdetails-attribute">
              <div class="productdetails-label">Availability Zone:</div>
              <div class="productdetails-value">{{ asset?.aws_availability_zone }}</div>
            </div>
            <div class="productdetails-attribute">
              <div class="productdetails-label">Region:</div>
              <div class="productdetails-value">{{ asset?.aws_region }}</div>
            </div>
            <div class="productdetails-attribute">
              <div class="productdetails-label">VPC ID:</div>
              <div class="productdetails-value">{{ asset?.aws_vpc_id }}</div>
            </div>
            <div class="productdetails-attribute">
              <div class="productdetails-label">Instance Group Name:</div>
              <div class="productdetails-value">{{ asset?.aws_ec2_instance_group_name }}</div>
            </div>
            <div class="productdetails-attribute">
              <div class="productdetails-label">Instance State:</div>
              <div class="productdetails-value">{{ asset?.aws_ec2_instance_state_name }}</div>
            </div>
            <div class="productdetails-attribute">
              <div class="productdetails-label">Instance Type:</div>
              <div class="productdetails-value">{{ asset?.aws_ec2_instance_type }}</div>
            </div>
            <div class="productdetails-attribute">
              <div class="productdetails-label">Subnet ID:</div>
              <div class="productdetails-value">{{ asset?.aws_subnet_id }}</div>
            </div>
            <div class="productdetails-attribute">
              <div class="productdetails-label">Product Code:</div>
              <div class="productdetails-value">{{ asset?.aws_ec2_product_code }}</div>
            </div>
            <div class="productdetails-attribute">
              <div class="productdetails-label">EC2 Name:</div>
              <div class="productdetails-value">{{ asset?.aws_ec2_name }}</div>
            </div>
          </div>
        </div>
      </p-panel>
      <p-panel [styleClass]="' ' + (isAWS || isAzure ? 'details-panel' : '')">

        <ng-template pTemplate="header">
          <div class="details-panel-header-container">
            <span class="font-bold details-panel-header-title">
              Dates
            </span>
          </div>
        </ng-template>
        <div class="productdetails-attribute">
          <div class="productdetails-label">Created:</div>
          <div class="productdetails-value">{{ niceDate(asset?.created_at) }}</div>
        </div>
        <div class="productdetails-attribute">
          <div class="productdetails-label">Updated:</div>
          <div class="productdetails-value">{{ niceDate(asset?.updated_at) }}</div>
        </div>
        <div class="productdetails-attribute">
          <div class="productdetails-label">First Seen:</div>
          <div class="productdetails-value">{{ niceDate(asset?.first_seen) }}</div>
        </div>
        <div class="productdetails-attribute">
          <div class="productdetails-label">Last seen:</div>
          <div class="productdetails-value">{{ niceDate(asset?.last_seen) }}</div>
        </div>
        <div class="productdetails-attribute">
          <div class="productdetails-label">First Scan:</div>
          <div class="productdetails-value">{{ niceDate(asset?.first_scan_time) }}</div>
        </div>
        <div class="productdetails-attribute">
          <div class="productdetails-label">Last Scan:</div>
          <div class="productdetails-value">{{ niceDate(asset?.last_scan_time) }}</div>
        </div>
        <div class="productdetails-attribute">
          <div class="productdetails-label">Last Authenticated Scan:</div>
          <div class="productdetails-value">{{ niceDate(asset?.last_authenticated_scan_date) }}</div>
        </div>
        <div class="productdetails-attribute">
          <div class="productdetails-label">Last Licenced Scan:</div>
          <div class="productdetails-value">{{ niceDate(asset?.last_licensed_scan_date) }}</div>
        </div>
      </p-panel>
      <p-panel styleClass="details-panel">
        <ng-template pTemplate="header">
          <div class="details-panel-header-container">
            <span class="font-bold details-panel-header-title">
              Tags
            </span>
          </div>
        </ng-template>
        <p-table [value]="tags">
          <ng-template pTemplate="header">
            <tr>
              <th>Key</th>
              <th>Value</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-tag>
            <tr>
              <td class="font-bold">{{ tag.key }}</td>
              <td>{{ tag.value }}</td>
            </tr>
          </ng-template>
        </p-table>
      </p-panel>
      <p-panel styleClass="details-panel">
        <ng-template pTemplate="header">
          <div class="details-panel-header-container">
            <span class="font-bold details-panel-header-title">
              Tenable Link
            </span>
          </div>
        </ng-template>
        <div class="productdetails-attribute">
          <div class="productdetails-label">Asset Information:</div>
          <div class="productdetails-value"><a href="{{ getTenableLink(assetId) }}" target="_blank" class="pi pi-external-link"></a>&nbsp;<a href="{{ getTenableLink(assetId) }}" target="_blank">Link</a></div>
        </div>
      </p-panel>
    </div>
  </div>
</div>
