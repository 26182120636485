<p-progressSpinner class="progress-spinner" *ngIf="isLoading"></p-progressSpinner>

<div class="dashboard-header-wrapper">
  <div class="dashboard-header-title">
    <div class="flex justify-between items-center">
      <h1 class="flex align-items-center">
        {{ suite.Name }}
        <i class="pi cursor-pointer ml-3 text-2xl"
          [ngClass]="{'pi-star-fill text-yellow-500': isFavourite(suiteId), 'pi-star': !isFavourite(suiteId)}"
          (click)="toggleFavourite(suiteId)"></i>
      </h1>
    </div>    

    <div class="grid">
      <div class="col-12 lg:col-6">
        <p-toast></p-toast>
        <div class="flex flex-wrap gap-4">
          <h5>
            <strong>Suite: </strong> [{{ suite.Id }}] - {{ suite.SuiteRef }}
          </h5>
        </div>
      </div>
    </div>
  </div>
  <div class="dashboard-header-logo">
    <ng-container *ngIf="suite.Logo">
      <img [src]="suite.Logo" alt="Suite Logo">
    </ng-container>
  </div>
</div>

<div class="flex justify-content-end flex-grow-0 align-items-end">
  <button pButton pRipple label="Delete Suite" icon="pi pi-trash" class="p-button-danger mt-5"
          *appHasRole="['Lens.Admin']" (click)="deleteSuite()" [disabled]="products.length > 0"></button>
  <p-confirmDialog></p-confirmDialog>
</div>

<h4>Products</h4>
<ng-container *ngIf="products.length > 0">
  <p-table #dt1 [value]="products" [columns]="cols" dataKey="Id" [rows]="10" [rowHover]="true" styleClass="p-datatable-gridlines" [paginator]="true" [globalFilterFields]="cols"
           [showCurrentPageReport]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" responsiveLayout="scroll"
           sortMode="multiple" stateStorage="session" stateKey="statedemo-session" [rowsPerPageOptions]="[10, 20, 30, 40]">

    <ng-template pTemplate="caption">
      <div class="flex justify-content-between flex-column sm:flex-row">
        <button pButton label="Clear" class="p-button-outlined mb-2" icon="pi pi-filter-slash" (click)="clear(dt1)"></button>

        <span class="p-input-icon-left mb-2">
          <i class="pi pi-search"></i>
          <input pInputText type="text" #filter (input)="onGlobalFilter(dt1, $event)" placeholder="Search Keyword" class="w-full" />
        </span>
      </div>
    </ng-template>

    <ng-template pTemplate="header" let-columns>
      <tr>
        <th style="min-width: 8rem" pSortableColumn="Id">
          <div class="flex justify-content-between align-items-center">
            <div>
              ID
              <p-sortIcon field="Id"></p-sortIcon>
            </div>
            <p-columnFilter type="text" field="Id" display="menu" placeholder="Search by ID" (click)="$event.stopPropagation()"></p-columnFilter>
          </div>
        </th>

        <th style="min-width: 12rem" pSortableColumn="Name">
          <div class="flex justify-content-between align-items-center">
            <div>
              Name
              <p-sortIcon field="Name"></p-sortIcon>
            </div>
            <p-columnFilter type="text" field="Name" display="menu" placeholder="Search by name" (click)="$event.stopPropagation()"></p-columnFilter>
          </div>
        </th>

        <th style="min-width: 12rem" pSortableColumn="ProductRef">
          <div class="flex justify-content-between align-items-center">
            <div>
              ProdRef
              <p-sortIcon field="ProductRef"></p-sortIcon>
            </div>
            <p-columnFilter type="text" field="ProductRef" display="menu" placeholder="Search by ref" (click)="$event.stopPropagation()"></p-columnFilter>
          </div>
        </th>
        <th style="min-width: 12rem" pSortableColumn="DivisionName">
          <div class="flex justify-content-between align-items-center">
            <div>
              Division
              <p-sortIcon field="DivisionName"></p-sortIcon>
            </div>
          </div>
        </th>
        <th style="min-width: 13rem" pSortableColumn="ProductTypeName">
          <div class="flex justify-content-between align-items-center">
            <div>
              Product Type
              <p-sortIcon field="ProductTypeName"></p-sortIcon>
            </div>
          </div>
        </th>
        <th style="min-width: 10rem" pSortableColumn="ActiveStatus">
          <div class="flex justify-content-between align-items-center">
            <div>
              Active Status
              <p-sortIcon field="ActiveStatus"></p-sortIcon>
            </div>
          </div>
        </th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-product>
      <tr>
        <td><a [routerLink]="['/product', product.Id, 'dashboard']">{{product.Id}}</a></td>
        <td><a [routerLink]="['/product', product.Id, 'dashboard']">{{product.Name}}</a></td>
        <td><a [routerLink]="['/product', product.Id, 'dashboard']">{{product.ProductRef}}</a></td>
        <td><span [class]="'division-badge division-' + tagify(product.DivisionName)">{{product.DivisionName}}</span></td>
        <td><a [routerLink]="['/product', product.Id, 'dashboard']">{{product.ProductTypeName}}</a></td>
        <td>
          <i class="pi" [ngClass]="{'true-icon pi-check-circle text-green-500': product.ActiveStatus, 'false-icon pi-times-circle text-pink-500': !product.ActiveStatus}"></i>
        </td>

      </tr>
    </ng-template>

    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="10">Loading product data. Please wait...</td>
      </tr>
    </ng-template>
    <ng-template pTemplate="loadingbody">
      <tr>
        <td colspan="10">Loading product data. Please wait...</td>
      </tr>
    </ng-template>
  </p-table>
</ng-container>
<ng-container *ngIf="products.length == 0 && !isProductsLoading">
  <span>There are no products in this suite</span>
</ng-container>
<ng-container *ngIf="isProductsLoading">
  <p-skeleton width="10%" height="1rem"></p-skeleton>
</ng-container>

<h4>Metrics</h4>
<div class="grid-container">
  <snyk-vuln-widget [data]="widgetData"></snyk-vuln-widget>
  <snyk-vuln-trend-widget [data]="widgetData"></snyk-vuln-trend-widget>
  <sonar-metrics-widget [data]="widgetData"></sonar-metrics-widget>
  <sonar-metrics-trend-widget [data]="widgetData"></sonar-metrics-trend-widget>
  <snyk-licence-widget [data]="widgetData"></snyk-licence-widget>
  <snyk-licence-trend-widget [data]="widgetData"></snyk-licence-trend-widget>
  <sonar-issues-widget [data]="widgetData"></sonar-issues-widget>
  <was-issues-widget [data]="widgetData"></was-issues-widget>
</div>
