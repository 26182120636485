import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http'; // Import HttpClient to fetch package.json
import { AppMainComponent } from '../app-main/app.main.component';

@Component({
  selector: 'app-footer',
  templateUrl: './app.footer.component.html',
})
export class AppFooterComponent {
  version: string = '';

  constructor(public appMain: AppMainComponent, private http: HttpClient) {
    var pjson = require('../../../../package.json');
    this.version = pjson.version;
  }

  // fetchAppVersion() {
  //   this.http.get('../../package.json').subscribe((data: any) => {
  //     var pjson = require('./package.json');
  //     this.version = pjson
  //     console.log(pjson.version);
  //   });
  // }
}
