import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, map } from "rxjs";
import { HostingEnvironmentDto, OneDayTestBatchNumberDto, OneDayTestBatchStatusDto, OneDayTestContact, OneDayTestTargetTypeRequestDto, ProductOneDayPentest, ProductOneDayPentestPutDto, ProductOneDayPentestRequestDto, TestRemediationStatusDto, TestTargetTypeDto, TestTriggerDto, OneDayTestContactRequestDto, OneDayTestBatchNumberRequestDto } from "src/app/api/product-onedaypentest";
import { environment } from "src/environments/environment";

@Injectable(
    {
        providedIn: 'root'
    }
)

export class ProductOneDayPentestService {
    baseApiUrl: string = environment.baseApiUrl;
    constructor(private http: HttpClient) { }


    getAllUnassignedOneDayPentests(): Observable<ProductOneDayPentest[]> {
        return this.http.get<any>(`${this.baseApiUrl}/api/ProductOneDayPentests?$filter=OneDayTestBatchNumberId eq null`).pipe(
            map((response) => {
            // Check if the response has a "value" property
            if (response && response.value) {
                // Return the array of objects from the "value" property
                return response.value;
            } else {
                // If there is no "value" property, return the entire response
                return response;
            }
            })
        )
    }

    getPentestsByBatchId(batchNumberId: number): Observable<ProductOneDayPentest[]> {
        return this.http.get<any>(`${this.baseApiUrl}/api/ProductOneDayPentests?$filter=OneDayTestBatchNumberId eq ${batchNumberId}&$expand=OneDayTestContacts`).pipe(
            map((response) => {
            // Check if the response has a "value" property
            if (response && response.value) {
                // Return the array of objects from the "value" property
                return response.value;
            } else {
                // If there is no "value" property, return the entire response
                return response;
            }
            })
        )
    }


    getProductOneDayPentests(productID: string): Observable<any> {
        return this.http.get<ProductOneDayPentest>(`${this.baseApiUrl}/api/ProductOneDayPentests/${productID}?$expand=OneDayTestTargetTypes, OneDayTestBatchNumber`);
    }

    getProductOneDayPentestById(id: number | undefined): Observable<any> {
        return this.http.get<any>(this.baseApiUrl + `/api/ProductOneDayPentests?$expand=OneDayTestTargetTypes&filter=Id eq ${id}`);
    }

    updateProductOneDayPentest(id: number, productOneDayPentestPutDto: ProductOneDayPentestPutDto): Observable<ProductOneDayPentestPutDto> {
        return this.http.put<ProductOneDayPentestPutDto>(`${this.baseApiUrl}/api/ProductOneDayPentests/${id}/`, productOneDayPentestPutDto);
    }

    createProductOneDayPentest(productOneDayPentestRequestDto: ProductOneDayPentestRequestDto): Observable<any> {
        return this.http.post<any>(`${this.baseApiUrl}/api/ProductOneDayPentests/`, productOneDayPentestRequestDto);
    }

    deleteProductOneDayPentest(id: number): Observable<any> {
        return this.http.delete<any>(`${this.baseApiUrl}/api/ProductOneDayPentests/${id}`);
    }

    getAllOneDayTestBatchNumbers(): Observable<OneDayTestBatchNumberDto[]> {
        return this.http.get<any>(`${this.baseApiUrl}/api/OneDayTestBatchNumbers/`).pipe(
            map((response) => {
            // Check if the response has a "value" property
            if (response && response.value) {
                // Return the array of objects from the "value" property
                return response.value;
            } else {
                // If there is no "value" property, return the entire response
                return response;
            }
            })
        );
    }

    createOneDayTestBatchNumber(oneDayTestBatchNumberRequestDto: OneDayTestBatchNumberRequestDto): Observable<OneDayTestBatchNumberRequestDto> {
        return this.http.post<OneDayTestBatchNumberRequestDto>(`${this.baseApiUrl}/api/OneDayTestBatchNumbers/`, oneDayTestBatchNumberRequestDto);
    }

    updateOneDayTestBatchNumber(id: number, oneDayTestBatchNumberRequestDto: OneDayTestBatchNumberRequestDto): Observable<OneDayTestBatchNumberRequestDto> {
        return this.http.put<OneDayTestBatchNumberRequestDto>(`${this.baseApiUrl}/api/OneDayTestBatchNumbers/${id}/`, oneDayTestBatchNumberRequestDto);
    }

    deleteOneDayTestBatchNumber(id: number): Observable<any> {
        return this.http.delete<any>(`${this.baseApiUrl}/api/OneDayTestBatchNumbers/${id}/`);
    }



    getAllOneDayTestBatchStatuses(): Observable<OneDayTestBatchStatusDto[]> {
        return this.http.get<any>(`${this.baseApiUrl}/api/OneDayTestBatchStatuses/`).pipe(
            map((response) => {
            // Check if the response has a "value" property
            if (response && response.value) {
                // Return the array of objects from the "value" property
                return response.value;
            } else {
                // If there is no "value" property, return the entire response
                return response;
            }
            })
        );
    }

    getAllTestRemediationStatuses(): Observable<TestRemediationStatusDto[]> {
        return this.http.get<any>(`${this.baseApiUrl}/api/TestRemediationStatuses/`).pipe(
            map((response) => {
            // Check if the response has a "value" property
            if (response && response.value) {
                // Return the array of objects from the "value" property
                return response.value;
            } else {
                // If there is no "value" property, return the entire response
                return response;
            }
            })
        );
    }

    getAllTestTargetTypes(): Observable<TestTargetTypeDto[]> {
        return this.http.get<any>(`${this.baseApiUrl}/api/TestTargetTypes/`).pipe(
            map((response) => {
            // Check if the response has a "value" property
            if (response && response.value) {
                // Return the array of objects from the "value" property
                return response.value;
            } else {
                // If there is no "value" property, return the entire response
                return response;
            }
            })
        );
    }

    addOneDayTestTargetType(oneDayTestTargetTypeRequest: OneDayTestTargetTypeRequestDto): Observable<OneDayTestTargetTypeRequestDto> {
        return this.http.post<OneDayTestTargetTypeRequestDto>(`${this.baseApiUrl}/api/OneDayTestTargetTypes/`, oneDayTestTargetTypeRequest);
    }

    removeOneDayTestTargetType(id: number): Observable<any> {
        return this.http.delete<any>(`${this.baseApiUrl}/api/OneDayTestTargetTypes/${id}/`);
    }

    getAllTestTriggers(): Observable<TestTriggerDto[]> {
        return this.http.get<any>(`${this.baseApiUrl}/api/TestTriggers/`).pipe(
            map((response) => {
            // Check if the response has a "value" property
            if (response && response.value) {
                // Return the array of objects from the "value" property
                return response.value;
            } else {
                // If there is no "value" property, return the entire response
                return response;
            }
            })
        );
    }

    getAllHostingEnvironments(): Observable<HostingEnvironmentDto[]> {
        return this.http.get<any>(`${this.baseApiUrl}/api/HostingEnvironments/`).pipe(
            map((response) => {
            // Check if the response has a "value" property
            if (response && response.value) {
                // Return the array of objects from the "value" property
                return response.value;
            } else {
                // If there is no "value" property, return the entire response
                return response;
            }
            })
        );
    }

    getOneDayTestContacts(ProductOneDayPentestId: number): Observable<OneDayTestContact[]> {
        return this.http.get<any>(`${this.baseApiUrl}/api/OneDayTestContacts/?$filter=ProductOneDayPentestId eq ${ProductOneDayPentestId}`).pipe(
            map((response) => {
            // Check if the response has a "value" property
            if (response && response.value) {
                // Return the array of objects from the "value" property
                return response.value;
            } else {
                // If there is no "value" property, return the entire response
                return response;
            }
            })
        );
    }

    createOneDayTestContact(OneDayTestContactRequestDto: OneDayTestContactRequestDto): Observable<OneDayTestContactRequestDto> {
        return this.http.post<any>(`${this.baseApiUrl}/api/OneDayTestContacts/`, OneDayTestContactRequestDto);
    }

    deleteOneDayTestContact(id: number): Observable<any> {
        return this.http.delete<any>(`${this.baseApiUrl}/api/OneDayTestContacts/${id}`);
    }
}