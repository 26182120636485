<div class="widgetCard" [class.collapsed]="SnykVulnCollapsed">
  <div class="widgetCardHeader" (click)="toggleSnykVulnCollapse()">
    <h5>Current Snyk Vulnerabilities</h5>
    <div class="chevron">
      <i [class]="SnykVulnCollapsed ? 'chevron-down' : 'chevron-up'"></i>
    </div>
  </div>
  <ng-container *ngIf="!SnykVulnCollapsed">
    <ng-container *ngIf="snykVulnloading; else content">
      <p-skeleton width="10%" height="1rem" margin="5px"></p-skeleton>
    </ng-container>
    <ng-template #content>
      <span class="reportingLevelLabel">{{ snykReportingLevel }}</span>
      <div class="reportingLevelLabel" *ngIf="hasMultipleComponents">
        <p-dropdown appendTo="body" [(ngModel)]="selectedSlugName" [options]="components" placeholder="Select" class="uniform-width-input" [style]="{'minWidth':'40%', 'maxWidth': '400px'}" (onChange)="onDropdownChange($event)">
          <ng-template pTemplate="selectedItem">
            <span *ngIf="selectedSlugName">{{ selectedSlugName }}</span>
          </ng-template>
          <ng-template let-option pTemplate="item">
            <span>{{option}}</span>
          </ng-template>
        </p-dropdown>
      </div>
      <div class="pieWidgetContainer" *ngIf="snykVulnerabilitiesAvailable">
        <div class="pieWidgetLeft">
          <p-chart type="doughnut" width="250px" height="250px" [data]="pieData" [options]="pieOptions"></p-chart>
        </div>
        <div class="pieWidgetRight">
          <p-table [value]="pieData.datasets[0].data" responsiveLayout="scroll">
            <ng-template pTemplate="header">
              <tr>
                <th class="pieWidgetCell pieWidgetVulnCell pieWidgetHeader">Severity</th>
                <th class="pieWidgetCell pieWidgetVulnCell pieWidgetHeader">Count</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-data let-i="rowIndex">
              <tr>
                <td [ngClass]="getSeverityClass(pieData.labels[i], 'pieWidgetCell pieWidgetVulnCell')">
                  {{ pieData.labels[i] }}
                </td>
                <td class="pieWidgetCell pieWidgetCellValue">
                  <a [routerLink]="['/vulnerabilities', 'search']"
                     [queryParams]="getQueryParams(pieData.labels[i])"
                     class="no-decoration">
                    {{ data }}
                  </a>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
    </ng-template>
  </ng-container>
</div>
