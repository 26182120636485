import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MenuItem, Message, MessageService } from 'primeng/api';
import { ProductContact, ProductContactRequestDto, User } from 'src/app/api/product';
import { GraphExplorerService } from 'src/app/layout/service/graphexplorer/graph-explorer.service';
import { ProductService } from 'src/app/layout/service/product/products.service';
import { LogService } from 'src/app/layout/service/log.service';
import { ReportRecipientsService } from 'src/app/layout/service/product/report-recipients.service';
import { forkJoin } from 'rxjs';

const GRAPH_USERS_ENDPOINT = 'https://graph.microsoft.com/v1.0/users';

@Component({
  selector: 'app-product-team',
  templateUrl: './product-team.component.html',
  styleUrls: ['product-team.component.scss']
})

export class ProductTeamComponent implements OnInit {
  users: User[] = [];
  productContacts: ProductContact[] = [];
  userPhotos: { [key: string]: string } = {};

  selectedUser: User | null = null;

  searchKeyword: string = '';

  userDialog: boolean = false;

  items: MenuItem[] | undefined;

  productId: string = '';

  messages: Message[] = [];

  productContactGroups: any[] = [];
  filteredProductContacts: any[] = [];
  selectedContactGroup: string | null = null;

  productReportRecipients: any[] = [];
  reportTypes: any[] = [];

  constructor(private http: HttpClient, private route: ActivatedRoute, private graphExplorerService: GraphExplorerService,
    private productService: ProductService, private reportRecipientService: ReportRecipientsService,
    private messageService: MessageService, private logService: LogService) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.productId = params['id'];
    });

    this.items = [
      // {
      //   label: 'Update',
      //   icon: 'pi pi-pencil',
      //   command: () => {
      //     // this.update();
      //   }
      // },
      {
        label: 'Delete',
        icon: 'pi pi-times',
        command: (productUser: any) => {
          this.deleteProductUser(productUser);
        }
      }
    ];

    this.getAllUsers();
    this.getAllReportTypes();

    this.getContactsWithSubscriptions();


    this.productService.getAllProductContactGroups().subscribe((productSDLCContactGroups) => {
      this.productContactGroups = productSDLCContactGroups;
    });

  }

  getContactsWithSubscriptions() {
    forkJoin({
      reportRecipients: this.reportRecipientService.getProductReportRecipients(this.productId),
      productContacts: this.productService.getProductContacts(this.productId)
    }).subscribe(({ reportRecipients, productContacts }) => {
      this.productReportRecipients = reportRecipients;
      this.productContacts = productContacts;
      this.filteredProductContacts = productContacts;

      // Check if each contact is part of the Vulnerability Report
      this.productContacts.forEach(contact => {
        contact.reports = contact.reports || {};
        // Dynamically set report participation for each report type
        this.reportTypes.forEach(reportType => {
          // Check if the user is a recipient of this report type and store the result
          contact.reports[reportType.Name] = this.isReportRecipient(contact, reportType.Name);
        });

        if (contact.UserGraphId) {
          this.loadUserPhoto(contact.UserGraphId);
        }
      });
    });
  }

  getAllUsers() {
    this.graphExplorerService.getAllADUsers().subscribe((users) => {
      this.users = users;
    });
  }

  getAllReportTypes() {
    this.reportRecipientService.getAllReportTypes().subscribe((data) => {
      this.reportTypes = data;
    });
  }

  getProductReportRecipients() {
    this.reportRecipientService.getProductReportRecipients(this.productId).subscribe((productReportRecipients) => {
      this.productReportRecipients = productReportRecipients;
    });
  }

  getProductContacts() {
    this.productService.getProductContacts(this.productId).subscribe((productContacts) => {
      this.productContacts = productContacts;
      this.filteredProductContacts = productContacts;
    });
  }

  isReportRecipient(contact: ProductContact, reportTypeName: string): boolean {
    console.log(reportTypeName);
    // Loop through the report recipients to find a match by email for the given report type
    return this.productReportRecipients.some(report =>
      report.ReportTypeName === reportTypeName &&
      report.Products.some((product: any) =>
        product.ToEmails.some((email: any) => email.Mail.toLowerCase() === contact.Mail?.toLowerCase()) ||
        product.CCEmails.some((email: any) => email.Mail.toLowerCase() === contact.Mail?.toLowerCase())
      )
    );
  }

  getProductContactGroupNameById(id: number): string {
    const group = this.productContactGroups.find((group) => group.Id === id);
    return group ? group.Name : '';
  }

  loadUserPhoto(userId: string) {
    this.graphExplorerService.getUserPhoto(userId).subscribe((photoBlob) => {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.userPhotos[userId] = e.target.result;
      };
      reader.readAsDataURL(photoBlob);
    });
  }

  searchUsers(event?: any) {
    if (event.filter) {
      this.graphExplorerService.searchADUsers(event.filter).subscribe((users) => {
        this.users = users;
      });
    } else {
      this.getAllUsers();
    }
  }

  addUser() {
    const productUser = {
      UserGraphId: this.selectedUser?.id,
      Name: this.selectedUser?.displayName,
      Mail: this.selectedUser?.mail,
      productId: this.productId,
      ProductContactGroupId: this.selectedUser?.ProductContactGroupId
    };

    if (this.selectedUser && this.productId && productUser.UserGraphId && productUser.Name) {
      this.productService.createProductContact(productUser).subscribe((response) => {
        this.messageService.add({ severity: 'success', summary: 'Successful', detail: `${productUser.Name} added`, life: 3000 });
        this.logService.sendInfoMessage('CREATE', 'product/' + this.productId + '/team/', productUser);

        this.getContactsWithSubscriptions();
        if (this.selectedUser?.ProductContactGroupId) {
          this.selectedUser.ProductContactGroupId = 0;
        }
      });
    }

    this.selectedUser = null;
  }

  openUserDialog() {
    if (this.selectedUser?.ProductContactGroupId) {
      this.selectedUser.ProductContactGroupId = 0;
    }

    this.userDialog = true;
  }

  hideDialog() {
    this.userDialog = false;
    this.selectedUser = null;
  }

  toggleReportRecipient(user: any) {
    user.IsReportRecipient = !user.IsReportRecipient;

    const updatedUser: ProductContactRequestDto = {
      ...user,
      IsReportRecipient: user.IsReportRecipient
    };

    this.productService.updateProductContact(user.Id, updatedUser).subscribe({
      next: () => {
        console.log(`Successfully updated IsReportRecipient for ${user.Name}`);
      },
      error: (err) => {
        console.error('Error updating IsReportRecipient:', err);
        user.IsReportRecipient = !user.IsReportRecipient;
      }
    });
  }

  deleteProductUser(productUser: any) {
    if (productUser && productUser.Id) {
      this.productService.deleteProductContact(productUser.Id).subscribe((response) => {
        this.messageService.add({ severity: 'success', summary: 'Successful', detail: `${productUser.Name} removed`, life: 3000 });
        this.logService.sendInfoMessage('DELETE', 'product/' + this.productId + '/team/' + productUser.Id, null, productUser);

        this.productContacts = this.productContacts.filter(contact => contact.Id !== productUser.Id);
        this.filteredProductContacts = this.filteredProductContacts.filter(contact => contact.Id !== productUser.Id);

        this.productReportRecipients = this.productReportRecipients.filter(recipient => {
          return recipient.ProductContactId !== productUser.Id;
        });

        this.productContacts.forEach(contact => {
          contact.reports = contact.reports || {};

          this.reportTypes.forEach(reportType => {
            contact.reports[reportType.Name] = this.isReportRecipient(contact, reportType.Name);
          });

          if (contact.UserGraphId) {
            this.loadUserPhoto(contact.UserGraphId);
          }
        });

      });
    }
  }

  onContactGroupChange() {
    if (this.selectedContactGroup) {
      this.filteredProductContacts = this.productContacts.filter(contact => contact.ProductContactGroupName === this.selectedContactGroup);
    } else {
      this.filteredProductContacts = this.productContacts;
    }
  }

  emailUser(email: string) {
    window.location.href = `mailto:${email}`;
  }
}
