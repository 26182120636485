import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Message, MessageService } from 'primeng/api';
import { ArchitectureService } from 'src/app/layout/service/architecture/architecture.service';
import { ProductService } from 'src/app/layout/service/product/products.service';
import { ArbDto } from "src/app/api/architecture";
import { catchError } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'app-suite-arbs',
  templateUrl: './suite-arbs.component.html'
})
export class SuiteArbsComponent implements OnInit {
  suiteId: string = '';
  messages: Message[] = [];
  hasProductRef: boolean = true;
  hasARBs: boolean = false;
  arbs: ArbDto[] = [];
  isARBLoading: boolean = true;

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private messageService: MessageService,
    private architectureService: ArchitectureService,
    private productService: ProductService
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.suiteId = params['suiteId'];
      this.isARBLoading = true;
      this.arbs = [];

      this.productService.getSuiteById(this.suiteId).subscribe((product) => {

        const objectRef = product.SuiteRef;

        if (objectRef) {
          this.hasProductRef = true;
          this.architectureService.getHistoricARBs(objectRef).pipe(
            catchError((error) => {
              if (error.status != 404) {
                console.log('Error fetching ARBs:', error);
              }
              this.hasARBs = false;
              this.isARBLoading = false;
              return of(null);
            })
          ).subscribe((historicArbs) => {
            this.hasARBs = true;
            this.arbs = historicArbs.sort((a: any, b: any) => {
              return new Date(b.date).getTime() - new Date(a.date).getTime();
            });
            this.isARBLoading = false;
          });

        } else {
          console.log("Object has no product ref");
          this.hasARBs = false;
          this.hasProductRef = false;
          this.isARBLoading = false;
        }
      });      

    });
  }

    niceDate(date?: Date): string {
    if (date == null) {
      return "";
    }
    const options: Intl.DateTimeFormatOptions = {
      day: '2-digit',
      month: 'long',
      year: 'numeric'
    };
    return new Date(date).toLocaleString('en-GB', options);
  }

    toggleRow(arb: any) {
    arb.expanded = !arb.expanded;
  }

    tagify(option: string | undefined): string {
    if (!option) {
      return '';
    }
    let tagValue = option.trim();
    tagValue = tagValue.replace(/\s+/g, '_');
    tagValue = tagValue.replace(/[^a-zA-Z0-9_]/g, '');
    return tagValue;
  }

getCompletedActions(arb: ArbDto): string {
  if (arb.actions == null) {
    return "n/a";
  }

  const completedActions = arb.actions.filter((action) => {
    return action.status === "Completed";
  });

  return `${completedActions.length} of ${arb.actions.length}`;
}

  getActionItemEditURL(id: string): string {
    return "https://irissoftware.sharepoint.com/sites/EngineeringIntranet/Lists/Architecture%20Review%20Boards%20Followup/DispForm.aspx?ID=" + id;
  }

}
