<p-toast></p-toast>

<ng-container *ngIf="getFilteredItems().length <= 0; else tableTemplate">
  <p-message severity="info" text="No software has been added with tracking versions enabled. Please go to the details tab to add software."></p-message>
</ng-container>

<ng-template #tableTemplate>

<div class="metrics-container p-mb-4 p-d-flex p-jc-between p-flex-wrap">
  <p-card class="dashboard-card" *ngIf="!isLoading; else metricSkeleton">
    <div class="metrics-container p-mb-4 p-d-flex p-jc-between p-flex-wrap">
      <p-card class="dashboard-card">
        <div class="p-d-flex p-ai-center p-jc-between p-flex-column p-p-3">
          <div class="p-d-flex p-ai-center">
            <i class="pi pi-briefcase p-mr-2 p-text-primary" style="font-size: 2rem;"></i>
            <div>
              <h5 class="card-title">Total Software</h5>
              <h3 class="metric-value primary-color-text">{{ totalSoftware }}</h3>
            </div>
          </div>
        </div>
      </p-card>
    
      <p-card class="dashboard-card">
        <div class="p-d-flex p-ai-center p-jc-between p-flex-column p-p-3">
          <div class="p-d-flex p-ai-center">
            <i class="pi pi-hashtag p-mr-2 p-text-success" style="font-size: 2rem;"></i>
            <div>
              <h5 class="card-title">Total Versions</h5>
              <h3 class="metric-value primary-color-text">{{ totalVersions }}</h3>
            </div>
          </div>
        </div>
      </p-card>
    
      <p-card class="dashboard-card" (click)="filterVersionsNotTagged()" [ngClass]="{'active-filter': showOnlyUntagged}">
        <div class="p-d-flex p-ai-center p-jc-between p-flex-column p-p-3">
          <div class="p-d-flex p-ai-center">
            <i class="pi pi-times p-mr-2 p-text-danger" style="font-size: 2rem;"></i>
            <div>
              <h5 class="card-title">Total Untagged Versions</h5>
              <h3 class="metric-value primary-color-text">{{ totalVersionsNotTagged }}</h3>
            </div>
          </div>
        </div>
      </p-card>
    </div>
  </p-card>
  <ng-template #metricSkeleton>
    <p-card class="dashboard-card">
      <div class="p-d-flex p-ai-center p-jc-between p-flex-column p-p-3">
        <p-skeleton width="100%" height="2rem"></p-skeleton>
      </div>
    </p-card>
  </ng-template>
</div>


<button class="mb-1" pButton label="Export to CSV" icon="pi pi-file" (click)="exportExcel()"></button>

  <p-table #table [value]="getFilteredItems()" responsiveLayout="scroll" [rows]="10" [paginator]="true">
    <ng-template pTemplate="header">
      <tr>
        <!-- Logo Column -->
        <th></th>
        <!-- Name Column -->
        <th pSortableColumn="name">
          <div class="flex justify-content-between align-items-center">
            <div>
                Name
                <p-sortIcon field="name"></p-sortIcon>
            </div>
            <p-columnFilter type="text" field="softwareDetails.name" display="menu" placeholder="Search by Name" (click)="$event.stopPropagation()"></p-columnFilter>
          </div>
        </th>
        <th pSortableColumn="techStackGroup">
          Tech Stack Group
        </th>
        <th pSortableColumn="vendor">
          <div class="flex justify-content-between align-items-center">
            <div>
                Vendor
                <p-sortIcon field="vendor"></p-sortIcon>
            </div>
            <p-columnFilter type="text" field="softwareDetails.vendor" display="menu" placeholder="Search by Vendor" (click)="$event.stopPropagation()"></p-columnFilter>
          </div>
        </th>
        <th>
          Versions
        </th>
        <th>Actions</th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-item>
      <tr *ngIf="!isLoading; else rowSkeleton">
        <!-- Logo Column -->
        <td>
          <div class="p-d-flex p-ai-center">
            <!-- Logo with error handling -->
            <img *ngIf="item.softwareDetails.displaySetting?.logo" 
                [src]="item.softwareDetails.displaySetting.logo" 
                alt="{{ item.softwareDetails.name }} logo" 
                class="p-mr-2" 
                style="width: 48px; height: 48px; object-fit: contain;"
                (error)="handleLogoError($event)">
          </div>
        </td>
        <!-- Name Column -->
        <td>
          <strong class="text-lg">{{ item.softwareDetails.name }}</strong>
        </td>
        <!-- Tech Stack Group Column -->
        <td>
          <div *ngIf="item.techStackGroups && item.techStackGroups.length > 0" class="tag-container">
            <ng-container *ngFor="let group of item.techStackGroups">
              <p-tag [value]="group.name" styleClass="custom-tag"></p-tag>
            </ng-container>
          </div>
        </td>
        <td><strong class="text-md text-primary">{{ item.softwareDetails.vendorName }}</strong></td>
        <td>
          <ng-container *ngIf="!item.isEditing">
            <ng-container *ngIf="(originalVersions[item.softwareDetails.id] ?? []).length > 0; else noVersion">
              <div class="chip-container">
                <p-chip *ngFor="let version of originalVersions[item.softwareDetails.id]" 
                        [label]="version.version"
                        styleClass="custom-chip cursor-pointer"
                        (click)="openSupportingUrl(version.supportingUrl)">
                  <!-- Add external link icon if supportingUrl exists -->
                  <i *ngIf="version.supportingUrl" class="pi pi-external-link p-ml-2" style="font-size: 0.75rem;"></i>
                </p-chip>
              </div>
            </ng-container>
          
            <ng-template #noVersion>
              <p-tag severity="danger" value="Version not selected"></p-tag>
            </ng-template>
          </ng-container>

          <ng-container *ngIf="item.isEditing">
              <p-multiSelect 
                appendTo="body"
                [options]="versions[item.softwareDetails.id].versions || []"
                optionLabel="version"
                dataKey="id"
                [(ngModel)]="editedVersions[item.softwareDetails.id]"
                (onChange)="handleVersionChange(item, $event)"
                placeholder="Select Versions">
              </p-multiSelect>
          </ng-container>
        </td>
        <td>
          <div class="button-group">
            <ng-container *ngIf="!item.isEditing; else editModeActions">
              <button pButton type="button" icon="pi pi-pencil" class="p-button-rounded p-button-secondary" (click)="item.isEditing = true"></button>
            </ng-container>
            <ng-template #editModeActions>
              <button pButton type="button" icon="pi pi-check" class="p-button-rounded p-button-success" (click)="saveChanges(item)"></button>
              <button pButton type="button" icon="pi pi-times" class="p-button-rounded p-button-danger" (click)="cancelChanges(item)"></button>
            </ng-template>
          </div>
        </td>      
      </tr>
      <ng-template #rowSkeleton>
        <tr>
          <td><p-skeleton width="100%" height="1.5rem"></p-skeleton></td>
          <td><p-skeleton width="100%" height="1.5rem"></p-skeleton></td>
          <td><p-skeleton width="100%" height="1.5rem"></p-skeleton></td>
          <td><p-skeleton width="100%" height="1.5rem"></p-skeleton></td>
          <td><p-skeleton width="100%" height="1.5rem"></p-skeleton></td>
          <td><p-skeleton width="100%" height="1.5rem"></p-skeleton></td>
        </tr>
      </ng-template>
    </ng-template>
  </p-table>
</ng-template>

<p-dialog header="Reason for Removing Version" [(visible)]="displayCommentModal" [modal]="true" [style]="{ width: '40vw' }">
  <div class="p-fluid">
    <!-- Display software and version information in a styled container -->
    <p-fieldset legend="Software Details" class="mb-3">
      <div class="p-text-secondary">
        <strong>Software:</strong> {{ versionToRemove?.softwareName }}<br>
        <strong>Version:</strong> {{ versionToRemove?.version }}
      </div>
    </p-fieldset>

    <!-- Comment input -->
    <div class="p-field mt-2">
      <label for="comment">Comment</label>
      <textarea id="comment" pInputTextarea [(ngModel)]="comment" placeholder="Enter your comment here..." rows="6" class="w-full"></textarea>
    </div>
  </div>

  <ng-template pTemplate="footer">
    <div class="p-d-flex p-jc-end">
      <button pButton type="button" label="Cancel" icon="pi pi-times" (click)="onCommentModalCancel()" class="p-button-text p-mr-2"></button>
      <button pButton type="button" label="Submit" icon="pi pi-check" (click)="onCommentModalSubmit()" class="p-button-success"></button>
    </div>
  </ng-template>
</p-dialog>
