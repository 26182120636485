import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { FavouritesService } from 'src/app/layout/service/favourite.service';
import { ProductService } from 'src/app/layout/service/product/products.service';
import { ArchitectureService } from 'src/app/layout/service/architecture/architecture.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  productTotal: any = '-';
  divisionTotal: any = '-';
  suiteTotal: any = '-';
  ARBTotal: any = '-';
  technologyTotal: any = '-';
  isLoading: boolean = true;

  productFavourites: any[] = [];
  favouritedProducts: any[] = [];
  isLoadingFavourites: boolean = true; // Flag to track if favourites are loading

  suiteFavourites: any[] = [];
  isLoadingSuites: boolean = true; // Flag to track if suites are loading
  favouritedSuites: any[] = [];


  cards = [
    {
      label: 'Total Products',
      value: this.productTotal,
      bgClass: 'bg-blue-100 border-round',
      iconClass: 'pi pi-shopping-bag text-blue-500 text-xl'
    },
    {
      label: 'Total Divisions',
      value: this.divisionTotal,
      bgClass: 'bg-orange-100 border-round',
      iconClass: 'pi pi-globe text-orange-500 text-xl'
    },
    {
      label: 'Total Suites',
      value: this.suiteTotal,
      bgClass: 'bg-cyan-100 border-round',
      iconClass: 'pi pi-home text-cyan-500 text-xl'
    },
    {
      label: 'Total ARBs',
      value: this.ARBTotal,
      bgClass: 'bg-green-100 border-round',
      iconClass: 'pi pi-sitemap text-green-500 text-xl'
    }
    //{
    //  label: 'Technologies Used',
    //  value: this.technologyTotal,
    //  bgClass: 'bg-purple-100 border-round',
    //  iconClass: 'pi pi-desktop text-purple-500 text-xl'
    //}
  ];

  constructor(private productsService: ProductService, private router: Router, private cookieService: CookieService,
    private favouritesService: FavouritesService, private architectureService: ArchitectureService) { }

  ngOnInit(): void {
    this.loadData();
    this.loadProductFavourites();
    this.loadSuiteFavourites();
  }

  loadData(): void {
    this.productsService.getProductCount().subscribe(
      (count: number) => {
        this.cards[0].value = count;
        this.isLoading = false;
      },
      (error) => {
        console.error('Error fetching product count:', error);
        this.isLoading = false;
      }
    );

    this.productsService.getDivisionCount().subscribe(
      (count: number) => {
        this.cards[1].value = count;
        this.isLoading = false;
      },
      (error) => {
        console.error('Error fetching division count:', error);
        this.isLoading = false;
      }
    );

    this.productsService.getSuiteCount().subscribe(
      (count: number) => {
        this.cards[2].value = count;
        this.isLoading = false;
      },
      (error) => {
        console.error('Error fetching suite count:', error);
        this.isLoading = false;
      }
    );

    this.architectureService.getAllARBsCount().subscribe(
      (count: number) => {
        this.cards[3].value = count;
        this.ARBTotal = count;
        this.isLoading = false;
      },
      (error) => {
        console.error('Error fetching ARB count:', error);
        this.isLoading = false;
      }
    );

    // Assuming you have a similar method for technology count
    // this.productsService.getTechnologyCount().subscribe(
    //   (count: number) => {
    //     this.technologyTotal = count;
    //     this.isLoading = false;
    //   },
    //   (error) => {
    //     console.error('Error fetching technology count:', error);
    //     this.isLoading = false;
    //   }
    // );
  }

  loadProductFavourites() {
    this.productFavourites = this.favouritesService.getProductFavourites();
    this.fetchProductFavourites();
  }

  fetchProductFavourites() {
    if (this.productFavourites.length === 0) {
      this.isLoadingFavourites = false;
      return;
    }

    let loadedCount = 0;

    this.productFavourites.forEach((id) => {
      this.productsService.getFavouritesById(id).subscribe(
        (product) => {
          this.favouritedProducts.push(product);
          loadedCount++;
          if (loadedCount === this.productFavourites.length) {
            this.isLoadingFavourites = false;
          }
        },
        (error) => {
          loadedCount++;
          if (loadedCount === this.productFavourites.length) {
            this.isLoadingFavourites = false;
          }
        }
      );
    });
  }

  loadSuiteFavourites() {
    this.suiteFavourites = this.favouritesService.getSuiteFavourites();
    this.fetchSuiteFavourites();
  }

  fetchSuiteFavourites() {
    if (this.suiteFavourites.length === 0) {
      this.isLoadingSuites = false;
      return;
    }

    let loadedCount = 0;

    this.suiteFavourites.forEach((id) => {
      this.productsService.getSuiteByIdSimple(id).subscribe(
        (suite) => {
          this.favouritedSuites.push(suite);
          loadedCount++;
          if (loadedCount === this.suiteFavourites.length) {
            this.isLoadingSuites = false;
          }
        },
        (error) => {
          loadedCount++;
          if (loadedCount === this.suiteFavourites.length) {
            this.isLoadingSuites = false;
          }
        }
      );
    });
  }

  trimOption(option: string): string {
    return option ? option.trim() : '';
  }

  tagify(option: string | undefined): string {
    if (!option) {
      return '';
    }
    let tagValue = option.trim();
    tagValue = tagValue.replace(/\s+/g, '_');
    tagValue = tagValue.replace(/[^a-zA-Z0-9_]/g, '');
    return tagValue;
  }

  redirectToProduct(product: any) {
    this.router.navigate(['/product', product.Id, 'dashboard']);
  }
  
  redirectToProductsPage(): void {
    this.router.navigate(['home/products']); // Navigate to the 'products' route
  }

  redirectToSuite(suite: any) {
    this.router.navigate(['/suite', suite.Id, 'dashboard']);
  }

  redirectToSuitesPage(): void {
    this.router.navigate(['home/suites']);
  }

  redirectToARBsPage(): void {
    this.router.navigate(['home/arb-list']);
  }
}
