import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Message, MessageService } from 'primeng/api';
import { ProductLinksService } from 'src/app/layout/service/product/product-links.service';
import { LinkDto } from "src/app/api/links";
import { Observable } from 'rxjs';
import { LogService } from 'src/app/layout/service/log.service';

@Component({
  selector: 'app-product-links',
  templateUrl: './product-links.component.html'
})
export class ProductLinksComponent implements OnInit {
  links: LinkDto[] = [];
  productId: string = '';
  newLinkDialog: boolean = false;
  editLinkDialog: boolean = false;
  messages: Message[] = [];
  newUrl: string = '';
  newName: string = '';
  editUrl: string = '';
  editName: string = '';
  editingLink: LinkDto | null = null;
  validationErrors: any = {
    newName: '',
    newUrl: ''
  };

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private messageService: MessageService,
    private productLinksService: ProductLinksService,
    private logService: LogService
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.productId = params['id'];
      this.getLinks().subscribe((response: any) => {
        this.links = response.value;
      });
      this.messages = [{ severity: 'info', summary: 'Information', detail: 'Links can be used to share any information relevant to the product such as documentation, backlogs or dashboards.' }];

    });
  }

  openNewLinkDialog() {
    this.newLinkDialog = true;
  }
  hideDialog() {
    this.newLinkDialog = false;
  }

  openEditLinkDialog(link: LinkDto) {
    this.editName = link.Name ?? '';
    this.editUrl = link.Url ?? '';
    this.editingLink = link;
    this.editLinkDialog = true;
  }

  hideEditDialog() {
    this.editLinkDialog = false;
  }

  deleteLink(link: LinkDto) {
    console.log('Delete link');
    if (link.Id === undefined) {
      console.error('Link missing an ID');
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Could not delete link', life: 3000 });
      return;
    }

    this.productLinksService.deleteProductLink(link.Id).subscribe(
      (response: any) => {
        console.log('Successfully deleted Link:', response);
        this.messageService.add({ severity: 'success', summary: 'Successful', detail: `Link deleted`, life: 3000 });
        this.links = this.links.filter(val => val.Id !== link.Id);
        this.logService.sendInfoMessage('DELETE', 'product/' + this.productId + '/links/' + link.Id, null, link);
      },
      (error) => {
        console.error('Error deleting Link:', error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Could not delete link', life: 3000 });
      }
    );
  }

  getLinks(): Observable<LinkDto[]> {
    console.log("Getting links");
    return this.productLinksService.getProductLinks(this.productId);
  }

  updateLink() {
    this.clearValidationErrors();
    let isValid = true;

    if (!this.editName) {
      this.validationErrors.newName = 'Please provide a link name.';
      isValid = false;
    }

    if (!this.editUrl) {
      this.validationErrors.newUrl = 'Please provide a link URL.';
      isValid = false;
    }

    //validate that newUrl is a valid URL
    if (this.editUrl && !this.editUrl.match(/^(http|https):\/\/[^ "]+$/)) {
      this.validationErrors.newUrl = 'Please provide a valid URL.';
      isValid = false;
    }

    if(!isValid) {
      return;
    }
    
    if (!this.editingLink) {
      console.error('No link to update');
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Could not update link', life: 3000 });
      return;
    }

    let updatedLink: LinkDto = {
      ProductId: parseInt(this.productId),
      Name: this.editName,
      Url: this.editUrl,
      Id: this.editingLink?.Id
    };

    this.productLinksService.updateProductLink(this.editingLink?.Id ?? 0,updatedLink).subscribe(
      (response: any) => {
        console.log('Successfully added new Link:', response);
        this.messageService.add({ severity: 'success', summary: 'Successful', detail: `New link added`, life: 3000 });
        this.logService.sendInfoMessage('UPDATE', 'product/' + this.productId + '/links/' + updatedLink, this.editingLink);
        this.getLinks().subscribe((response: any) => {
          this.links = response.value;
        });
      },
      (error) => {
        console.error('Error adding new Link:', error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Could not add new link', life: 3000 });
      }
    );

    this.editingLink = null;
    this.editName = '';
    this.editUrl = '';
    this.editLinkDialog = false;

  }

  addNewLink() {
    this.clearValidationErrors();
    let isValid = true;

    if (!this.newName) {
      this.validationErrors.newName = 'Please provide a link name.';
      isValid = false;
    }

    if (!this.newUrl) {
      this.validationErrors.newUrl = 'Please provide a link URL.';
      isValid = false;
    }

    //validate that newUrl is a valid URL
    if (this.newUrl && !this.newUrl.match(/^(http|https):\/\/[^ "]+$/)) {
      this.validationErrors.newUrl = 'Please provide a valid URL.';
      isValid = false;
    }

    if (isValid) {

      const newLink: LinkDto = {
        ProductId: parseInt(this.productId),
        Name: this.newName,
        Url: this.newUrl
      };

      this.productLinksService.addProductLink(newLink).subscribe(
        (response: any) => {
          console.log('Successfully added new Link:', response);
          this.messageService.add({ severity: 'success', summary: 'Successful', detail: `New link added`, life: 3000 });
          this.logService.sendInfoMessage('CREATE', 'product/' + this.productId + '/links/', newLink);
          //refresh links
          this.getLinks().subscribe((response: any) => {
            this.links = response.value;
          });
        },
        (error) => {
          console.error('Error adding new Link:', error);
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Could not add new link', life: 3000 });
        }
      );

      this.newName = '';
      this.newUrl = '';
      this.newLinkDialog = false;

    }
  }

  clearValidationErrors() {
    this.validationErrors = {
      newName: '',
      newUrl: ''
    };
  }
}

