<p-panel header="Admin Dashboard">
  <div class="dashboard-links">
    <p-card header="SDLC Approval" styleClass="dashboard-card">
      <p>Manage SDLC approvals.</p>
      <a href="/admin/sdlc/approval" target="_blank">
        <button pButton type="button" icon="pi pi-check-circle" label="Go to SDLC Approval"></button>
      </a>
    </p-card>

    <p-card header="One Day Pentest Manager" styleClass="dashboard-card">
      <p>Manage one-day pentests and schedules.</p>
      <a href="/admin/sdlc/onedaymanager" target="_blank">
        <button pButton type="button" icon="pi pi-calendar" label="Go to One Day Manager"></button>
      </a>
    </p-card>

    <p-card header="Upload Findings" styleClass="dashboard-card">
      <p>Upload findings for pentests and 1d pentests.</p>
      <a href="/admin/sdlc/uploadfindings" target="_blank">
        <button pButton type="button" icon="pi pi-upload" label="Go to Upload Findings"></button>
      </a>
    </p-card>

    <p-card header="Practices" styleClass="dashboard-card">
      <p>Manage and review SDLC practices.</p>
      <a href="/admin/sdlc/practices" target="_blank">
        <button pButton type="button" icon="pi pi-book" label="Go to Practices"></button>
      </a>
    </p-card>

    <p-card header="NFR Categories" styleClass="dashboard-card">
      <p>Manage and review NFR Categories.</p>
      <a href="/admin/nfrs/categories" target="_blank">
        <button pButton type="button" icon="pi pi-book" label="Go to Categories"></button>
      </a>
    </p-card>

    <p-card header="NFRs" styleClass="dashboard-card">
      <p>Manage and review Non-Functional Requirements.</p>
      <a href="/admin/nfrs/requirements" target="_blank">
        <button pButton type="button" icon="pi pi-book" label="Go to NFRs"></button>
      </a>
    </p-card>
  </div>
  </p-panel>
