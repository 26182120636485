<p-dialog header="{{ dialogTitle }}" [(visible)]="visible" modal="modal" (onHide)="resetFields()" [style]="{ width: '400px' }">
    <div class="p-fluid">
        <div class="p-field p-grid">
            <div class="p-col-12 p-md-9">
                <input id="entryName" type="text" [(ngModel)]="entryName" pInputText placeholder="Enter {{ placeholderText }} name" class="w-full" (keydown.enter)="addEntry()" required />
            </div>
        </div>
        <div class="p-field p-grid p-justify-end">
            <div class="p-col-12 p-md-3">
                <button (click)="addEntry()" pButton label="Add" icon="pi pi-plus" class="mt-1 p-button-success"></button>
            </div>
        </div>
    </div>
</p-dialog>
