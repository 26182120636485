<h1>
  ARBs
  <a href="https://irissoftware.sharepoint.com/sites/EngineeringIntranet/SitePages/architecture-review-board.aspx" target="_blank">
    <p-button icon="pi pi-question"></p-button>
  </a>
</h1>

<p-toast></p-toast>

<p-messages [(value)]="messages" [enableService]="false" [closable]="true"></p-messages>

<div class="flex justify-content-end mb-2">
  <button pButton pRipple label="Request a New ARB" icon="pi pi-plus" class="p-button-success mb-2" onclick="window.location.href='https://forms.office.com/pages/responsepage.aspx?id=ZHU-fLibGkGc4ndc6r2IvqzDPFUR2wdEqbNQrhyI_QBUQUs3RVM0WDZVTFJLQ1JMNEZQTEdPU0hBSi4u'"></button>
</div>

<ng-container *ngIf="isARBLoading">
  <p-skeleton width="10%" height="1rem"></p-skeleton>
</ng-container>

<div *ngIf="hasARBs">
  <p-table [value]="arbs">
    <ng-template pTemplate="header">
      <tr>
        <th style="width:3%"></th>
        <th style="width:10%">Code</th>
        <th style="width:10%">Date</th>
        <th style="width:47%">Title</th>
        <th style="width:15%">Status</th>
        <th style="width:15%">Completed Actions</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-arb>
      <tr>
        <td>
          <div *ngIf="arb.actions && arb.actions.length > 0">
            <button (click)="toggleRow(arb)" class="arb-expand-collapse-btn" title="View Action Items">
              <i class="pi" [ngClass]="{'pi-chevron-down': arb.expanded, 'pi-chevron-right': !arb.expanded}"></i>
            </button>
          </div>
        </td>
        <td>
          <a [routerLink]="['/arb', arb.code, 'details']">{{arb.code}}</a>
          <span *ngIf="!arb.url">{{arb.code}}</span>
        </td>
        <td>
          <a [routerLink]="['/arb', arb.code, 'details']">{{niceDate(arb.date)}}</a>
          <span *ngIf="!arb.url">{{niceDate(arb.date)}}</span>
        </td>
        <td>
          <a [routerLink]="['/arb', arb.code, 'details']">{{arb.title}}</a>
          <span *ngIf="!arb.url">{{arb.title}}</span>
        </td>
        <td>
          <div [ngClass]="'arbstatus-badge arb-' + arb.currentStatus ">
            {{ arb.currentStatus }}
          </div>
        </td>
        <td>
          {{ getCompletedActions(arb) }}
        </td>
      </tr>
      <tr *ngIf="arb.expanded">
        <td colspan="4">
          <div class="arb-sub-table-container">
            <p-table [value]="arb.actions">
              <ng-template pTemplate="header">
                <tr>
                  <th style="width:5%"></th>
                  <th style="width:75">Follow-Up Action Item</th>
                  <th style="width:20%">Status</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-action>
                <tr>
                  <td>
                    <a href="{{ getActionItemEditURL(action.id) }}" class="pi pi-pencil" target="_blank" title="Edit Action Item"></a>
                  </td>
                  <td>{{action.description}}</td>
                  <td>
                    <div [ngClass]="'arbactionstatus-badge arbaction-' + tagify(action.status) ">
                      {{ action.status }}
                    </div>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>

<div *ngIf="!hasProductRef && !isARBLoading">
  <div>This suite does not have a reference. A reference is required to link to historic ARBs.</div>
</div>
<div *ngIf="!hasARBs && hasProductRef && !isARBLoading">
  <div>This suite does not have any ARBs.</div>
</div>
