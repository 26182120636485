<h1>Portfolio Overview</h1>

<p-toast></p-toast>

<div *ngIf="isLoading">
  <p-skeleton width="10%" height="1rem"></p-skeleton>
</div>

<div *ngIf="!isLoading">

  <div style="display: flex; justify-content: space-between; align-items: center; margin-bottom: 10px;">
    <div style="display: flex; gap: 5px;">
      <p-multiSelect [options]="tiers"
                     [(ngModel)]="selectedTiers"
                     placeholder="Select Tiers"
                     optionLabel="Name"
                     display="chip"
                     [showToggleAll]="false"
                     (onChange)="onTiersChange($event)">
      </p-multiSelect>

      <p-multiSelect [options]="divisions"
                     [(ngModel)]="selectedDivisions"
                     placeholder="Select Divisions"
                     optionLabel="Name"
                     display="chip"
                     [showToggleAll]="false"
                     (onChange)="onDivisionsChange($event)">
      </p-multiSelect>

      <p-multiSelect [options]="suites"
                     [(ngModel)]="selectedSuites"
                     placeholder="Select Suites"
                     optionLabel="Name"
                     display="chip"
                     [showToggleAll]="false"
                     (onChange)="onSuitesChange($event)">
      </p-multiSelect>

      <p-multiSelect [options]="products"
                     [(ngModel)]="selectedProducts"
                     placeholder="Select Products"
                     optionLabel="Name"
                     display="chip"
                     [showToggleAll]="false"
                     (onChange)="onProductsChange($event)">
      </p-multiSelect>
    </div>
  </div>
</div>

<h2>Fields to Include</h2>

<div style="margin-top:10px; margin-bottom:20px">
  <button pButton pRipple label="Select All" icon="pi pi-check-square" class="selectorButton" (click)="selectAll()" title="Select All"></button>
  <button pButton pRipple label="Unselect All" icon="pi pi-times-circle" class="selectorButton" (click)="unselectAll()" title="Unselect All"></button>
</div>

<div class="checkbox-container">
  <div *ngFor="let column of checkboxColumns" class="checkbox-column scrollable">
    <h4>{{ column.title }}</h4>
    <div *ngFor="let field of column.fields">
      <p-checkbox [label]="field.description"
                  [value]="field.systemName"
                  [binary]="true"
                  [(ngModel)]="field.checked"
                  [disabled]="field.mandatory"
                  class="custom-checkbox"
                  (onChange)="onCheckboxChange(field)">
      </p-checkbox>
    </div>
  </div>
</div>

<div style="margin-top:10px; margin-bottom:20px">
  <button pButton pRipple label="Generate Report" icon="pi pi-file" (click)="generateReport()" title="Generate Report"></button>
</div>
