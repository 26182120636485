<h1>1d Pentest Manager</h1>

<div class="grid">
  <div class="col-12">
    <div class="card px-6 py-6">
      <p-toast></p-toast>
      <p-toolbar styleClass="mb-4">
        <ng-template pTemplate="left">
          <div class="my-2">
            <button pButton pRipple label="New" icon="pi pi-plus" class="p-button-success mr-2" (click)="openNew()"></button>
          </div>
        </ng-template>
      </p-toolbar>

      <p-table #dt [value]="oneDayTestBatchNumbers" [columns]="cols" responsiveLayout="scroll" [rows]="10" [globalFilterFields]="cols" [paginator]="true" [rowsPerPageOptions]="[10,20,30]" [showCurrentPageReport]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [rowHover]="true" dataKey="Id">
        <ng-template pTemplate="caption">
          <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 class="m-0">Manage Batches</h5>
            <span class="block mt-2 md:mt-0 p-input-icon-left">
              <!-- <i class="pi pi-search"></i> -->
              <!-- <input pInputText type="text" (input)="onGlobalFilter(dt, $event)" placeholder="Search..."  class="w-full sm:w-auto"/> -->
            </span>
          </div>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th pSortableColumn="Id">Id <p-sortIcon field="Id"></p-sortIcon></th>
            <th pSortableColumn="BatchStatus">Batch Status <p-sortIcon field="BatchStatus"></p-sortIcon></th>
            <th pSortableColumn="StartDate">Start Date <p-sortIcon field="StartDate"></p-sortIcon></th>
            <th pSortableColumn="EndDate">End Date <p-sortIcon field="EndDate"></p-sortIcon></th>
            <th pSortableColumn="SupplierContact">Supplier Contact <p-sortIcon field="SupplierContact"></p-sortIcon></th>
            <th></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-batch>
            <tr>
              <td style="width:14%; min-width:10rem;"><span class="p-column-title">Id</span>
                {{batch.Id || batch.id}}
              </td>
              <td style="width:14%; min-width:10rem;">
                <span class="p-column-title">Batch Status</span>
                {{batch.OneDayTestBatchStatusName}}
              </td>
              <td style="width:14%; min-width:8rem;">
                <span class="p-column-title">Start Date</span>
                {{batch.StartDate | date: 'dd/MM/yyyy'}}
              </td>
              <td style="width:14%; min-width:10rem;">
                <span class="p-column-title">End Date</span>
                {{batch.EndDate | date: 'dd/MM/yyyy'}}
              </td>
              <td style="width:14%; min-width:10rem;">
                <span class="p-column-title">Supplier Contact</span>
                {{batch.SupplierContact}}
              </td>
              <td>
                <div class="flex">
                  <button pButton pRipple icon="pi pi-eye" class="p-button-rounded p-button-info mr-2" (click)="viewAssignedPentests(batch)"></button>
                  <button pButton pRipple icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2" (click)="editBatch(batch)"></button>
                  <button pButton pRipple icon="pi pi-trash" class="p-button-rounded p-button-danger" (click)="deleteBatch(batch)"></button>
                </div>
              </td>
            </tr>
          </ng-template>
      </p-table>
    </div>

    <h2>Unassigned 1d Pentests</h2>
    <div class="card px-6 py-6">
      <p-table [value]="unassignedBatches" [columns]="unassignedCols" responsiveLayout="scroll" [rows]="10" [paginator]="true" [rowsPerPageOptions]="[10,20,30]" [showCurrentPageReport]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [rowHover]="true" dataKey="Id">
        <ng-template pTemplate="caption">
          <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 class="m-0">Assign to Batches</h5>
          </div>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th pSortableColumn="Id">Id <p-sortIcon field="Id"></p-sortIcon></th>
            <th pSortableColumn="ProductId">Product ID <p-sortIcon field="ProductId"></p-sortIcon></th>
            <th pSortableColumn="ProductName">Product Name <p-sortIcon field="ProductName"></p-sortIcon></th>
            <th></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-batch>
          <tr>
            <td style="width:14%; min-width:10rem;"><span class="p-column-title">Id</span>
              {{batch.Id}}
            </td>
            <td style="width:14%; min-width:10rem;"><span class="p-column-title">Product ID</span>
              {{batch.ProductId}}
            </td>
            <td style="width:14%; min-width:10rem;"><span class="p-column-title">Product Name</span>
              {{batch.ProductName}}
            </td>
            <td>
              <div class="flex">
                <button pButton pRipple icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2" (click)="editUnassignedBatch(batch)"></button>
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>

    <p-dialog [(visible)]="oneDayBatchDialog" [style]="{width: '450px'}" header="One Day Batch Details" [modal]="true" class="p-fluid">
      <ng-template pTemplate="content">
        <div class="field">
          <label for="BatchStatus">Batch Status</label>
          <p-dropdown appendTo="body" [(ngModel)]="oneDayTestBatch.OneDayTestBatchStatusId" inputId="BatchStatus" [options]="oneDayTestBatchStatuses" placeholder="Select" optionValue="Id">
            <ng-template pTemplate="selectedItem">
              <span>{{ getBatchNameById(oneDayTestBatch.OneDayTestBatchStatusId) }}</span>
            </ng-template>
            <ng-template let-option pTemplate="item">
              <span>{{option.Name}}</span>
            </ng-template>
          </p-dropdown>
        </div>

        <div class="field">
          <label for="BatchStartDate">Start Date</label>
          <p-calendar appendTo="body" [showIcon]="true" [(ngModel)]="oneDayTestBatch.StartDate" id="BatchStartDate" dateFormat="dd/mm/yy"></p-calendar>
        </div>

        <div class="field">
          <label for="BatchEndDate">End Date</label>
          <p-calendar appendTo="body" [showIcon]="true" [(ngModel)]="oneDayTestBatch.EndDate" id="BatchEndDate" dateFormat="dd/mm/yy"></p-calendar>
        </div>

        <div class="field">
          <label for="SupplierContact">Supplier Contact</label>
          <textarea id="SupplierContact" pInputTextarea [(ngModel)]="oneDayTestBatch.SupplierContact" required rows="3" cols="20"></textarea>
        </div>
      </ng-template>

      <ng-template pTemplate="footer">
        <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="hideDialog()"></button>
        <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text" (click)="saveNewBatch()"></button>
      </ng-template>
    </p-dialog>

    <p-dialog [(visible)]="deleteBatchDialog" header="Confirm" [modal]="true" [style]="{width: '450px'}">
      <div class="flex align-items-center justify-content-center">
        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"></i>
        <span *ngIf="oneDayBatchNumber">Are you sure you want to delete <b>{{oneDayBatchNumber.Id}}</b>?</span>
      </div>
      <ng-template pTemplate="footer">
        <button pButton pRipple icon="pi pi-times" class="p-button-text" label="No" (click)="deleteBatchDialog = false"></button>
        <button pButton pRipple icon="pi pi-check" class="p-button-text" label="Yes" (click)="confirmDeleteBatch()"></button>
      </ng-template>
    </p-dialog>

    <p-dialog [(visible)]="unassignedBatchDialog" [style]="{width: '450px'}" header="Edit Unassigned Batch" [modal]="true" class="p-fluid">
      <ng-template pTemplate="content">
        <!-- <div class="field">
          <label for="ProductId">Product ID</label>
          <input id="ProductId" type="text" pInputText [(ngModel)]="oneDayUnassignedPentest.ProductId" disabled />
        </div>
        <div class="field">
          <label for="ProductName">Product Name</label>
          <input id="ProductName" type="text" pInputText [(ngModel)]="oneDayUnassignedPentest.ProductName" disabled />
        </div> -->
        <div class="field">
          <label for="BatchNumberId">Batch Number ID</label>
          <p-dropdown appendTo="body" [(ngModel)]="oneDayUnassignedPentest.OneDayTestBatchNumberId" inputId="BatchNumberId" [options]="oneDayTestBatchNumbers" placeholder="Select" optionValue="Id">
            <ng-template pTemplate="selectedItem">
              <span>{{ oneDayUnassignedPentest.OneDayTestBatchNumberId }}</span>
            </ng-template>
            <ng-template let-option pTemplate="item">
              <span>{{option.Id}}</span>
            </ng-template>
          </p-dropdown>
        </div>
      </ng-template>

      <ng-template pTemplate="footer">
        <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="hideDialog()"></button>
        <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text" (click)="saveAssignedBatch()"></button>
      </ng-template>
    </p-dialog>
  </div>
</div>


<p-dialog [(visible)]="assignedPentestsDialog" [style]="{width: '850px'}" header="Assigned Pentests" [modal]="true" class="p-fluid">
  <ng-template pTemplate="content">
      <p-table [value]="assignedPentests" responsiveLayout="scroll">
          <ng-template pTemplate="header">
              <tr>
                  <th pSortableColumn="Id">1d PT Id<p-sortIcon field="Id"></p-sortIcon></th>
                  <th pSortableColumn="ProductId">Product Id <p-sortIcon field="ProductId"></p-sortIcon></th>
                  <th pSortableColumn="ProductName">Product Name <p-sortIcon field="ProductName"></p-sortIcon></th>
                  <th pSortableColumn="OneDayTestContacts">Primary Contacts<p-sortIcon field="OneDayTestContacts"></p-sortIcon></th>
                  <th>Actions</th>
              </tr>
          </ng-template>
          <ng-template pTemplate="body" let-pentest>
              <tr>
                  <td>{{pentest.Id}}</td>
                  <td>{{pentest.ProductId}}</td>
                  <td>{{pentest.ProductName}}</td>
                  <td>
                      <ng-container *ngFor="let contact of pentest.OneDayTestContacts; let i = index">
                          <div *ngIf="i > 0"> </div>
                          <div>
                              <strong>{{contact.Name}}</strong> ({{contact.Mail}})
                          </div>
                      </ng-container>
                  </td>
                    <td>
                      <button pButton pRipple icon="pi pi-minus" class="p-button-danger p-button-sm" 
                              (click)="removeFromBatch(pentest)"></button>
                  </td>
              </tr>
          </ng-template>
      </p-table>
  </ng-template>
  <ng-template pTemplate="footer">
      <button pButton pRipple label="Close" icon="pi pi-times" class="p-button-text" (click)="hideAssignedPentestsDialog()"></button>
  </ng-template>
</p-dialog>
