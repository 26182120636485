<h1>Team</h1>

<p-toast></p-toast>

<div class="flex justify-content-between mb-2">
	<p-dropdown [options]="productContactGroups" [(ngModel)]="selectedContactGroup"
				placeholder="Filter Title" (onChange)="onContactGroupChange()" optionLabel="Name" optionValue="Name" [showClear]="true">
		<ng-template let-option pTemplate="item">
			<div>
				{{ option.Name }}
			</div>
		</ng-template>
		<ng-template let-option pTemplate="selectedItem">
			<div>
				{{ option.Name }}
			</div>
		</ng-template>
	</p-dropdown>
	<button pButton pRipple label="Add User" icon="pi pi-plus" class="p-button-success mb-2" (click)="openUserDialog()"></button>
</div>

<p-dialog [(visible)]="userDialog" [modal]="true" [style]="{width: '750px'}" header="Add new user" [contentStyle]="{'overflow': 'visible'}" [modal]="true" class="p-fluid">
    <ng-template pTemplate="content">
        <div class="field">
            <p-dropdown [options]="users" scrollHeight="300px" [(ngModel)]="selectedUser" [style]="{'width':'100%'}" optionLabel="displayName" [filter]="true" (onFilter)="searchUsers($event)" [showClear]="true" placeholder="Select a User">
                <ng-template pTemplate="selectedItem">
                    <div class="flex align-items-center gap-2" *ngIf="selectedUser">
                        <div>{{ selectedUser.displayName }}</div>
                    </div>
                </ng-template>
                <ng-template let-user pTemplate="item">
                    <div class="flex align-items-center gap-2">
                        <img src="assets/layout/images/iris-circle-logo.png" alt="" width="60px" height="" class="shadow-2 border-circle">
                        <div class="flex w-full gap-2 flex-column p-4">
                            <div>{{ user.displayName }}</div>
                            <div>({{ user.mail }})</div>
                        </div>
                    </div>
                </ng-template>
            </p-dropdown>
        </div>

        <div class="field" *ngIf="selectedUser">
            <p-dropdown appendTo="body" [(ngModel)]="selectedUser.ProductContactGroupId" [options]="productContactGroups" placeholder="Select" optionValue="Id" [required]="true">
				<ng-template pTemplate="selectedItem">
					<span>
						{{ getProductContactGroupNameById(selectedUser.ProductContactGroupId || 0)}}
					</span>
				</ng-template>
				<ng-template let-option pTemplate="item">
					<span>{{option.Name}}</span>
				</ng-template>
			</p-dropdown>
        </div>
    </ng-template>

    <ng-template pTemplate="footer">
        <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="hideDialog()"></button>
        <button pButton pRipple label="Add" icon="pi pi-check" class="p-button-text" (click)="addUser()"></button>
    </ng-template>
</p-dialog>

<!-- List -->

<div class="grid">
	<div class="col-12">
		<div class="card">
			<h5>Members</h5>
			<p-dataView #dv [value]="filteredProductContacts" [rows]="9" filterBy="displayName" layout="grid" [paginator]="true" [rowsPerPageOptions]="[10, 20, 30]">
				<ng-template let-productUsers pTemplate="gridItem">
					<div class="grid grid-nogutter">
						<div class="col-12 md:col-4" *ngFor="let user of productUsers; let first = first">
							<div class="card m-3 border-1 surface-border card-background">
								<div class="flex flex-wrap gap-2 align-items-center justify-content-end mb-2">
									<div class="flex gap-2">
										<div>
											<div (click)="op2.toggle($event)">
											  <i class="pi pi-ellipsis-v cursor-pointer"></i>
											</div>
											<p-overlayPanel class="flex flex-column" #op2 [showCloseIcon]="false">
											  <div><button type="button" pButton icon="pi pi-envelope" class="p-button-info mb-2" (click)="emailUser(user.Mail)"></button></div>
											  <div><button type="button" pButton icon="pi pi-trash" class="p-button-danger" (click)="deleteProductUser(user)"></button></div>
											</p-overlayPanel>
										  </div>
										</div>
									</div>
								<div class="flex flex-column align-items-center text-center mb-3">
									<img [src]="userPhotos[user.UserGraphId] || 'assets/layout/images/iris-circle-logo.png'" alt="Profile Picture" width="100px" class="shadow-2 my-3 mx-0 border-circle"/>
									<div class="text-2xl font-bold">{{user.Name}}</div>
									<div class="">{{user.Mail}}</div>
								</div>
								<div class="flex justify-content-center">
									<span class="text-2xl font-bold" style="color: #ffffff;">{{ user.ProductContactGroupName || "Member" }}</span>
								</div>
							</div>
						</div>
					</div>
				</ng-template>
			</p-dataView>
		</div>
	</div>
</div>