import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CookieService } from 'ngx-cookie-service'; // Import CookieService

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { AppTopbarComponent } from './layout/app-topbar/app.topbar.component';
import { AppMenuComponent } from './layout/app-menu/app.menu.component';
import { AppMainComponent } from './layout/app-main/app.main.component';
import { AppMenuitemComponent } from './layout/app-menu/app.menuitem.component';
import { NotfoundComponent } from './layout/notfound/notfound.component';

import { DashboardComponent } from './layout/components/home/dashboard/dashboard.component';
import { ProductMasterListComponent } from './layout/components/home/product-master-list/product-master-list.component';
import { SuiteMasterListComponent } from './layout/components/home/suite-master-list/suite-master-list.component';
import { TechRadarComponent } from './layout/components/home/tech-radar/tech-radar.component';
import { ApprovedSoftwareComponent } from './layout/components/home/approved-software/approved-software.component';
import { ProductToolRefsComponent } from './layout/components/product/product-tool-refs/product-tool-refs.component';
import { SDLCDashboardComponent } from './layout/components/product/product-sdlc/dashboard/dashboard.component';

import { SnykVulnWidgetComponent } from './layout/components/widgets/snyk-vuln/snyk-vuln-widget.component';
import { SnykVulnTrendWidgetComponent } from './layout/components/widgets/snyk-vuln-trend/snyk-vuln-trend-widget.component';
import { SnykLicenceWidgetComponent } from './layout/components/widgets/snyk-licence/snyk-licence-widget.component';
import { SnykLicenceTrendWidgetComponent } from './layout/components/widgets/snyk-licence-trend/snyk-licence-trend-widget.component';
import { SonarIssuesWidgetComponent } from './layout/components/widgets/sonar-issues/sonar-issues-widget.component';
import { SonarMetricsWidgetComponent } from './layout/components/widgets/sonar-metrics/sonar-metrics-widget.component';
import { SonarMetricsTrendWidgetComponent } from './layout/components/widgets/sonar-metrics-trend/sonar-metrics-trend-widget.component';
import { WasIssuesWidgetComponent } from './layout/components/widgets/was-issues/was-issues-widget.component';

import { PracticeMaturityLevelsComponent } from './layout/components/admin/sdlcpractices/practicematuritylevels/practicematuritylevels.component';
import { SDLCPracticeMaturityComponent } from './layout/components/admin/sdlcpractices/sdlcpracticematurity/sdlcpracticematurity.component';
import { PracticeComponent } from './layout/components/admin/sdlcpractices/practice/practice.component';

import { ButtonModule } from 'primeng/button';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { CardModule } from 'primeng/card';
import { RadioButtonModule } from 'primeng/radiobutton';
import { InputSwitchModule } from 'primeng/inputswitch';
import { MenuService } from './layout/service/app.menu.service';
import { ConfigService } from './layout/service/app.config.service';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { TableModule } from 'primeng/table';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { RippleModule } from 'primeng/ripple';
import { TabViewModule } from 'primeng/tabview';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { MultiSelectModule } from 'primeng/multiselect';
import { ContextMenuModule } from 'primeng/contextmenu';
import { CheckboxModule } from 'primeng/checkbox';
import { TagModule } from 'primeng/tag';
import { DialogModule } from 'primeng/dialog';
import { PanelModule } from 'primeng/panel';
import { ToastModule } from 'primeng/toast';
import { CalendarModule } from 'primeng/calendar';
import { AutoCompleteModule } from "primeng/autocomplete";
import { InputNumberModule } from 'primeng/inputnumber';
import { MenubarModule } from 'primeng/menubar';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ProductDashboardComponent } from './layout/components/product/product-dashboard/product-dashboard.component';
import { ProductLinksComponent } from './layout/components/product/product-links/product-links.component';
import { ProductSDLCComponent } from './layout/components/product/product-sdlc/product-sdlc.component';
import { ConfirmationService, FilterService, MessageService } from 'primeng/api';
import { MessagesModule } from 'primeng/messages';
import { ToolbarModule } from 'primeng/toolbar';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { DataViewModule } from 'primeng/dataview';
import { MenuModule } from 'primeng/menu';
import { ChipModule } from 'primeng/chip';
import { ChipsModule } from 'primeng/chips';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogService } from 'primeng/dynamicdialog';
import { FileUploadModule } from 'primeng/fileupload'
import { SkeletonModule } from 'primeng/skeleton';
import { NgxGaugeModule } from 'ngx-gauge';
import { MeterGroupModule } from 'primeng/metergroup';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { TreeModule } from 'primeng/tree';
import { TreeTableModule } from 'primeng/treetable';
import { ListboxModule } from 'primeng/listbox';
import { AccordionModule } from 'primeng/accordion';
import { SplitterModule } from 'primeng/splitter';
import { ChartModule } from 'primeng/chart';
import { DASTComponent } from './layout/components/product/product-sdlc/dast/dast.component';
import { ThreatModellingComponent } from './layout/components/product/product-sdlc/threat-modelling/threat-modelling.component';
import { SCAComponent } from './layout/components/product/product-sdlc/sca/sca.component';
import { SASTComponent } from './layout/components/product/product-sdlc/sast/sast.component';
import { NPDASTComponent } from './layout/components/product/product-sdlc/npdast/npdast.component';
import { OneDayPentestComponent } from './layout/components/product/product-sdlc/oneday-pentest/oneday-pentest.component';
import { PentestComponent } from './layout/components/product/product-sdlc/pentest/pentest.component';
import { IncidentResponseComponent } from './layout/components/product/product-sdlc/incident-response/incident-response.component';
import { ProductTeamComponent } from './layout/components/product/product-team/product-team.component';
import { Paginator, PaginatorModule } from 'primeng/paginator';
// import { TableDemoComponent } from './tabledemo.component';
// import { TableDemoRoutingModule } from './tabledemo-routing.module';
import { environment } from "src/environments/environment";


import { MsalGuard, MsalInterceptor, MsalModule, MsalRedirectComponent } from "@azure/msal-angular";
import { InteractionType, PublicClientApplication } from "@azure/msal-browser";
import { ProfileService } from './layout/service/profile.service';
import { OverlayModule } from 'primeng/overlay';
import { SDLCApprovalComponent } from './layout/components/admin/sdlcapproval/sdlcapproval.component';
import { HasRoleDirective } from './has-role.directive';
import { OnedayPentestManagerComponent } from './layout/components/admin/oneday-pentest-manager/oneday-pentest-manager.component';
import { UploadFindingsComponent } from './layout/components/admin/upload-findings/upload-findings.component';
import { ProductDetailsComponent } from './layout/components/product/product-details/product-details.component';
import { ProductAssetsComponent } from './layout/components/product/product-assets/product-assets.component';
import { AssetDetailsComponent } from './layout/components/asset/asset-details/asset-details.component';
import { AppConfigComponent } from './layout/app-config/app.config.component';

import { SuiteDashboardComponent } from './layout/components/suite/suite-dashboard/suite-dashboard.component';
import { SuiteDetailsComponent } from './layout/components/suite/suite-details/suite-details.component';
import { SuiteToolRefsComponent } from './layout/components/suite/suite-tool-refs/suite-tool-refs.component';
import { SuiteAssetsComponent } from './layout/components/suite/suite-assets/suite-assets.component';
import { FavouritesService } from './layout/service/favourite.service';
import { AppFooterComponent } from './layout/app-footer/app.footer.component';

import { ProductArbsComponent } from './layout/components/product/product-architecture/product-arbs.component';
import { SuiteArbsComponent } from './layout/components/suite/suite-architecture/suite-arbs.component';
import { ArbListComponent } from './layout/components/arbs/list/arb-list.component';
import { ArbDetailsComponent } from './layout/components/arbs/details/arb-details.component';
import { FreeTextSearchComponent } from './layout/components/search/freetextsearch.component';

import { SDLCMaturityReport } from './layout/components/reports/sdlcmaturity/sdlcmaturityreport.component';
import { PortfolioOverviewReport} from './layout/components/reports/portfoliooverview/portfoliooverview.component';

import { VulnDetailsComponent } from './layout/components/vulnerabilities/vuln-details/vuln-details.component';
import { VulnSearchComponent } from './layout/components/vulnerabilities/vuln-search/vuln-search.component';
import { AdminDashboardComponent } from './layout/components/admin/admin-dashboard/admin-dashboard.component';
import { ProductTechPortfolioService } from './layout/service/product/product-tech-portfolio.service';
import { AddEntryDialogComponent } from './layout/components/reusable/dialog/add-entry-dialog.component';
import { UsefulToolsComponent } from './layout/components/home/useful-tools/useful-tools.component';
import { MasterVulnReportReport } from './layout/components/reports/mastervulnreport/mastervulnreport.component';

import { VulnerabilityTreeComponent } from './layout/components/vulnerabilities/vuln-tree/vuln-tree.component';
import { ReportRecipientComponent } from './layout/components/reports/report-recipient/report-recipient.component';
import { ReportSubscriptionComponent } from './layout/components/reports/report-subscription/report-subscription.component';

const isIE =
  window.navigator.userAgent.indexOf("MSIE ") > -1 ||
  window.navigator.userAgent.indexOf("Trident/") > -1;

const redirectUri = environment.redirectUri;
const baseApiUrl = environment.baseApiUrl;

const applicationIdUri = "api://42795f50-75ed-40c5-b3d0-70782607f507/";


@NgModule({ declarations: [
        AppComponent,
        AppMainComponent,
        AppMenuComponent,
        AppFooterComponent,
        AppMenuitemComponent,
        NotfoundComponent,
        DashboardComponent,
        ProductMasterListComponent,
        TechRadarComponent,
        ApprovedSoftwareComponent,
        AppTopbarComponent,
        ProductDashboardComponent,
        ProductLinksComponent,
        ProductSDLCComponent,
        ThreatModellingComponent,
        SCAComponent,
        DASTComponent,
        SASTComponent,
        NPDASTComponent,
        OneDayPentestComponent,
        PentestComponent,
        IncidentResponseComponent,
        ProductTeamComponent,
        SDLCApprovalComponent,
        HasRoleDirective,
        OnedayPentestManagerComponent,
        UploadFindingsComponent,
        ProductToolRefsComponent,
        ProductDetailsComponent,
        AppConfigComponent,
        SuiteDashboardComponent,
        SuiteMasterListComponent,
        SnykVulnWidgetComponent,
        SnykLicenceWidgetComponent,
        SonarIssuesWidgetComponent,
        SonarMetricsWidgetComponent,
        WasIssuesWidgetComponent,
        SuiteDetailsComponent,
        SuiteToolRefsComponent,
        ProductAssetsComponent,
        AssetDetailsComponent,
        SuiteAssetsComponent,
        ProductArbsComponent,
        SuiteArbsComponent,
        ArbListComponent,
        ArbDetailsComponent,
        SnykVulnTrendWidgetComponent,
        SnykLicenceTrendWidgetComponent,
        SonarMetricsTrendWidgetComponent,
        FreeTextSearchComponent,
        SDLCDashboardComponent,
        VulnDetailsComponent,
        PracticeMaturityLevelsComponent,
        SDLCPracticeMaturityComponent,
        PracticeComponent,
        SDLCMaturityReport,
        VulnSearchComponent,
        AdminDashboardComponent,
        UsefulToolsComponent,
        PortfolioOverviewReport,
        VulnSearchComponent,
        AddEntryDialogComponent,
        MasterVulnReportReport,
        VulnerabilityTreeComponent,
        ReportRecipientComponent,
        ReportSubscriptionComponent
    ],
    bootstrap: [AppComponent, MsalRedirectComponent],
    exports: [HasRoleDirective], imports: [BrowserModule,
        CommonModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        ButtonModule,
        RadioButtonModule,
        InputSwitchModule,
        DropdownModule,
        FormsModule,
        TableModule,
        InputTextModule,
        RippleModule,
        TabViewModule,
        DropdownModule,
        DragDropModule,
        ToggleButtonModule,
        MultiSelectModule,
        ContextMenuModule,
        CheckboxModule,
        TagModule,
        DialogModule,
        ToastModule,
        InputTextareaModule,
        CalendarModule,
        InputNumberModule,
        MenubarModule,
        TieredMenuModule,
        AutoCompleteModule,
        ChartModule,
        SplitterModule,
        CardModule,
        MessagesModule,
        AccordionModule,
        ProgressSpinnerModule,
        ToolbarModule,
        OverlayModule,
        OverlayPanelModule,
        DataViewModule,
        MenuModule,
        ChipModule,
        ChipsModule,
        ConfirmDialogModule,
        PaginatorModule,
        FileUploadModule,
        SkeletonModule,
        PanelModule,
        NgxGaugeModule,
        MeterGroupModule,
        DragDropModule,
        TreeModule,
        TreeTableModule,
        ListboxModule,
        MsalModule.forRoot(new PublicClientApplication({
            auth: {
                clientId: environment.clientId, // Application (client) ID from the app registration
                authority: `https://login.microsoftonline.com/${environment.tenantId}/oauth2/v2.0/authorize`, // The Azure cloud instance and the app's sign-in audience (tenant ID, common, organizations, or consumers)
                redirectUri: `${redirectUri}/api/auth/callback/azure-ad`, // This is your redirect URI
            },
            cache: {
                cacheLocation: "localStorage",
                storeAuthStateInCookie: isIE, // Set to true for Internet Explorer 11
            },
        }), {
            interactionType: InteractionType.Redirect, // MSAL Guard Configuration
            authRequest: {
                scopes: ["user.read", "openid", "profile", `${applicationIdUri}/Product.ReadWrite`],
            },
        }, {
            interactionType: InteractionType.Redirect, // MSAL Interceptor Configuration
            protectedResourceMap: new Map([
                ["https://graph.microsoft.com/v1.0/me", ["user.read", "profile"]],
                ["https://graph.microsoft.com/v1.0/users", ["user.readbasic.all"]],
                [`${baseApiUrl}/api`, [`${applicationIdUri}/Product.ReadWrite`]],
            ]),
        })], providers: [ConfigService, MenuService, MessageService, CookieService,
        MsalGuard, ProfileService, ConfirmationService, DialogService, FavouritesService,
        FilterService, ProductTechPortfolioService, {
            provide: HTTP_INTERCEPTORS,
            useClass: MsalInterceptor,
            multi: true,
        }, provideHttpClient(withInterceptorsFromDi())] })
export class AppModule { }
