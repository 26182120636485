// src/app/app-config/app.config.component.ts
import { Component, OnDestroy, OnInit, NgZone } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import { Subscription } from 'rxjs';
import { AppConfig } from '../appconfig';
import { AppComponent } from '../../app.component';
import { AppMainComponent } from '../app-main/app.main.component';
import { CookieService } from 'ngx-cookie-service';
import { ChangeDetectorRef } from '@angular/core';
import { ConfigService } from '../service/app.config.service';

@Component({
  selector: 'app-config',
  templateUrl: './app.config.component.html',
})
export class AppConfigComponent implements OnInit, OnDestroy {
  scale: number = 14;
  scales: any[] = [12, 13, 14, 15, 16];
  config!: AppConfig;
  subscription!: Subscription;
  configButtonClickSubscription!: Subscription;

  constructor(
    public app: AppComponent,
    public appMain: AppMainComponent,
    public configService: ConfigService,
    public primengConfig: PrimeNGConfig,
    private cookieService: CookieService,
    private cd: ChangeDetectorRef,
    private ngZone: NgZone
  ) {}

  ngOnInit() {
    this.config = this.configService.config;
    this.subscription = this.configService.configUpdate$.subscribe((config) => {
      this.config = config;
      this.scale = 14;
      this.applyScale();
    });

    this.configButtonClickSubscription = this.configService.configButtonClick$.subscribe(() => {
      this.onConfigButtonClick(new MouseEvent('click'));
    });

    this.ngZone.runOutsideAngular(() => {
      setTimeout(() => {
        this.ngZone.run(() => {
          const savedTheme = this.cookieService.get('theme');
          if (savedTheme) {
            const savedDarkMode = this.cookieService.get('dark') === 'true';
            this.changeTheme(savedTheme, savedDarkMode);
          }
          this.cd.detectChanges();
        });
      }, 0);
    });
  }

  onConfigButtonClick(event: MouseEvent) {
    this.appMain.configActive = !this.appMain.configActive;
    this.appMain.configClick = true;
    event.preventDefault();
  }

  incrementScale() {
    this.scale++;
    this.applyScale();
  }

  decrementScale() {
    this.scale--;
    this.applyScale();
  }

  applyScale() {
    document.documentElement.style.fontSize = this.scale + 'px';
  }

  onRippleChange(ripple: boolean) {
    this.primengConfig.ripple = ripple;
    this.configService.updateConfig({ ...this.config, ...{ ripple } });
  }

  onInputStyleChange() {
    this.configService.updateConfig(this.config);
  }

  changeTheme(theme: string, dark: boolean) {
    let themeElement = document.getElementById('theme-css');
    themeElement?.setAttribute('href', 'assets/theme/' + theme + '/theme.css');
    this.configService.updateConfig({ ...this.config, ...{ theme, dark } });

    const expirationDate = new Date();
    expirationDate.setFullYear(expirationDate.getFullYear() + 10);

    this.cookieService.set('theme', theme, expirationDate);
    this.cookieService.set('dark', dark.toString(), expirationDate);
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.configButtonClickSubscription) {
      this.configButtonClickSubscription.unsubscribe();
    }
  }
}
