<h1>Suites</h1>
<p-toast></p-toast>

<button pButton pRipple label="Add Suite" icon="pi pi-plus" class="p-button-success mt-5 mb-5"
        *appHasRole="['Lens.Admin']" (click)="openNew()"></button>

<p-table #dt1 [value]="suites" [columns]="cols" dataKey="Id" [rows]="10" [rowHover]="true" styleClass="p-datatable-gridlines" [paginator]="true" [globalFilterFields]="cols"
         [showCurrentPageReport]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" responsiveLayout="scroll"
         sortMode="multiple" stateStorage="session" stateKey="statedemo-session" [rowsPerPageOptions]="[10, 20, 30, 40]">

  <ng-template pTemplate="caption">
    <div class="flex justify-content-between flex-column sm:flex-row">
      <button pButton label="Clear" class="p-button-outlined mb-2" icon="pi pi-filter-slash" (click)="clear(dt1)"></button>

      <span class="p-input-icon-left mb-2">
        <i class="pi pi-search"></i>
        <input pInputText type="text" #filter (input)="onGlobalFilter(dt1, $event)" placeholder="Search Keyword" class="w-full" />
      </span>
    </div>
  </ng-template>

  <ng-template pTemplate="header" let-columns>
    <tr>
      <th style="min-width: 6rem; max-width: 6rem; width:6rem;">
        Favourite
    </th>
      <th style="min-width: 8rem; max-width: 8rem; width:8rem;" pSortableColumn="Id">
        <div class="flex justify-content-between align-items-center">
          <div>
            ID
            <p-sortIcon field="Id"></p-sortIcon>
          </div>
          <p-columnFilter type="text" field="Id" display="menu" placeholder="Search by ID" (click)="$event.stopPropagation()"></p-columnFilter>
        </div>
      </th>
      <th style="min-width: 8rem" pSortableColumn="Name">
        <div class="flex justify-content-between align-items-center">
          <div>
            Name
            <p-sortIcon field="Name"></p-sortIcon>
          </div>
          <p-columnFilter type="text" field="Name" display="menu" placeholder="Search by Name" (click)="$event.stopPropagation()"></p-columnFilter>
        </div>
      </th>
    </tr>
  </ng-template>

  <ng-template pTemplate="body" let-suite>
    <tr>
      <td>
        <i class="pi cursor-pointer text-2xl" [ngClass]="{'pi-star-fill text-yellow-500': isFavourite(suite), 'pi-star': !isFavourite(suite)}"
           (click)="toggleFavourite(suite)"></i>
      </td>
      <td><a [routerLink]="['/suite', suite.Id, 'dashboard']">{{suite.Id}}</a></td>
      <td><a [routerLink]="['/suite', suite.Id, 'dashboard']">{{suite.Name}}</a></td>
    </tr>
  </ng-template>

  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="10">Loading suite data. Please wait...</td>
    </tr>
  </ng-template>
  <ng-template pTemplate="loadingbody">
    <tr>
      <td colspan="10">Loading suite data. Please wait...</td>
    </tr>
  </ng-template>
</p-table>


<p-dialog [(visible)]="suiteDialog" [style]="{width: '600px'}" header="Add Suite" [modal]="true" class="p-fluid">
  <ng-template pTemplate="content">

    <div class="field">
      <label for="name">Name</label>
      <input type="text" pInputText id="name" [(ngModel)]="suite.Name" required autofocus [ngClass]="{'ng-invalid ng-dirty' : submitted && !suite.Name}" />
      <small class="ng-dirty ng-invalid" *ngIf="submitted && !suite.Name">Name is required.</small>
    </div>

    <div class="field">
      <label for="suiteRef">Reference</label>
      <input type="text" pInputText id="suiteRef" [(ngModel)]="suite.SuiteRef" />
    </div>
  </ng-template>
    <ng-template pTemplate="footer">
      <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="hideDialog()"></button>
      <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text" (click)="saveSuite()"></button>
    </ng-template>
</p-dialog>
