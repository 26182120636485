import { HttpClient, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, map, catchError, throwError } from "rxjs";
import { PortfolioDatabaseRequestDto, PortfolioProgrammingLanguageRequestDto, PortfolioUXUIRequestDto, ProductTechPortfolio, ProductTechPortfolioPutDto, TechPortfolioDatabaseDto, TechPortfolioDatabaseRequestDto, TechPortfolioProgrammingLanguageDto, TechPortfolioProgrammingLanguageRequestDto, TechPortfolioUXUIDto, TechPortfolioUXUIRequestDto } from "src/app/api/product-tech-portfolio";
import { environment } from "src/environments/environment";

@Injectable(
    {
        providedIn: 'root'
    }
)

export class ProductTechPortfolioService {
    baseApiUrl: string = environment.baseApiUrl;
    constructor(private http: HttpClient) { }

    getProductTechPortfolioById(techPortfolioId: number): Observable<ProductTechPortfolio> {
      return this.http.get<ProductTechPortfolio>(this.baseApiUrl + `/api/ProductTechPortfolios/${techPortfolioId}?$expand=TechPortfolioDatabases, TechPortfolioProgrammingLanguages, TechPortfolioUXUIs`);
    }

    updateProductTechPortfolio(id: number, techPortfolioPutDto: ProductTechPortfolioPutDto): Observable<ProductTechPortfolioPutDto> {
      return this.http.put<ProductTechPortfolioPutDto>(this.baseApiUrl + `/api/ProductTechPortfolios/${id}`, techPortfolioPutDto);
    }


    // Database

    getAllDatabases(): Observable<TechPortfolioDatabaseDto[]> {
      return this.http.get<any>(this.baseApiUrl + `/api/PortfolioDatabases`).pipe(
        map((response) => {
          // Check if the response has a "value" property
          if (response && response.value) {
            // Return the array of objects from the "value" property
            return response.value;
          } else {
            // If there is no "value" property, return the entire response
            return response;
          }
        })
      );
    }

    addTechPortfolioDatabase(techPortfolioDatabaseRequest: TechPortfolioDatabaseRequestDto): Observable<TechPortfolioDatabaseRequestDto> {
      return this.http.post<TechPortfolioDatabaseRequestDto>(this.baseApiUrl + `/api/TechPortfolioDatabases`, techPortfolioDatabaseRequest);
    }

    removeTechPortfolioDatabase(techPortfolioDatabaseId: number): Observable<TechPortfolioDatabaseDto> {
        return this.http.delete<TechPortfolioDatabaseDto>(this.baseApiUrl + `/api/TechPortfolioDatabases/${techPortfolioDatabaseId}`);
    }

    // Programming Language

    getAllProgrammingLanguages(): Observable<TechPortfolioProgrammingLanguageDto[]> {
      return this.http.get<any>(this.baseApiUrl + `/api/PortfolioProgrammingLanguages`).pipe(
        map((response) => {
          // Check if the response has a "value" property
          if (response && response.value) {
            // Return the array of objects from the "value" property
            return response.value;
          } else {
            // If there is no "value" property, return the entire response
            return response;
          }
        })
      );
    }

    addTechPortfolioProgrammingLanguage(techPortfolioProgrammingLanguageRequest: TechPortfolioProgrammingLanguageRequestDto): Observable<TechPortfolioProgrammingLanguageRequestDto> {
      return this.http.post<TechPortfolioProgrammingLanguageRequestDto>(this.baseApiUrl + `/api/TechPortfolioProgrammingLanguages`, techPortfolioProgrammingLanguageRequest);
    }

    removeTechPortfolioProgrammingLanguage(techPortfolioProgrammingLanguageId: number): Observable<TechPortfolioProgrammingLanguageDto> {
        return this.http.delete<TechPortfolioProgrammingLanguageDto>(this.baseApiUrl + `/api/TechPortfolioProgrammingLanguages/${techPortfolioProgrammingLanguageId}`);
    }

    // UX/UI

    getAllUXUIs(): Observable<TechPortfolioUXUIDto[]> {
      return this.http.get<any>(this.baseApiUrl + `/api/PortfolioUXUIs`).pipe(
        map((response) => {
          // Check if the response has a "value" property
          if (response && response.value) {
            // Return the array of objects from the "value" property
            return response.value;
          } else {
            // If there is no "value" property, return the entire response
            return response;
          }
        })
      );
    }

    addTechPortfolioUXUI(techPortfolioUXUIRequest: TechPortfolioUXUIRequestDto): Observable<TechPortfolioUXUIRequestDto> {
      return this.http.post<TechPortfolioUXUIRequestDto>(this.baseApiUrl + `/api/TechPortfolioUXUIs`, techPortfolioUXUIRequest);
    }

    removeTechPortfolioUXUI(techPortfolioUXUIId: number): Observable<TechPortfolioUXUIDto> {
        return this.http.delete<TechPortfolioUXUIDto>(this.baseApiUrl + `/api/TechPortfolioUXUIs/${techPortfolioUXUIId}`);
    }

    // Country

    getAllCountries(): Observable<any> {
      return this.http.get<any>(this.baseApiUrl + `/api/Countries`).pipe(
        map((response) => {
          // Check if the response has a "value" property
          if (response && response.value) {
            // Return the array of objects from the "value" property
            return response.value;
          } else {
            // If there is no "value" property, return the entire response
            return response;
          }
        })
      );
    }

    // Tenancy

    getAllTenancies(): Observable<any> {
      return this.http.get<any>(this.baseApiUrl + `/api/Tenancies`).pipe(
        map((response) => {
          // Check if the response has a "value" property
          if (response && response.value) {
            // Return the array of objects from the "value" property
            return response.value;
          } else {
            // If there is no "value" property, return the entire response
            return response;
          }
        })
      );
    }

    // AutomatedHA

    getAllAutomatedHAs(): Observable<any> {
      return this.http.get<any>(this.baseApiUrl + `/api/AutomatedHAs`).pipe(
        map((response) => {
          // Check if the response has a "value" property
          if (response && response.value) {
            // Return the array of objects from the "value" property
            return response.value;
          } else {
            // If there is no "value" property, return the entire response
            return response;
          }
        })
      );
    }

    // UXUIModernisation

    getAllUXUIModernisations(): Observable<any> {
        return this.http.get<any>(this.baseApiUrl + `/api/UXUIModernisations`).pipe(
            map((response) => {
            // Check if the response has a "value" property
            if (response && response.value) {
                // Return the array of objects from the "value" property
                return response.value;
            } else {
                // If there is no "value" property, return the entire response
                return response;
            }
            })
        );
    }

    // CustomizationEnabled

    getAllCustomizationsEnabled(): Observable<any> {
        return this.http.get<any>(this.baseApiUrl + `/api/CustomizationEnableds`).pipe(
            map((response) => {
            // Check if the response has a "value" property
            if (response && response.value) {
                // Return the array of objects from the "value" property
                return response.value;
            } else {
                // If there is no "value" property, return the entire response
                return response;
            }
            })
        );
    }

    // ReleaseCadence

    getAllReleaseCadences(): Observable<any> {
        return this.http.get<any>(this.baseApiUrl + `/api/ReleaseCadences`).pipe(
            map((response) => {
            // Check if the response has a "value" property
            if (response && response.value) {
                // Return the array of objects from the "value" property
                return response.value;
            } else {
                // If there is no "value" property, return the entire response
                return response;
            }
            })
        );
    }

    // LevelOfTestAutomation

    getAllLevelOfTestAutomations(): Observable<any> {
        return this.http.get<any>(this.baseApiUrl + `/api/LevelOfTestAutomations`).pipe(
            map((response) => {
            // Check if the response has a "value" property
            if (response && response.value) {
                // Return the array of objects from the "value" property
                return response.value;
            } else {
                // If there is no "value" property, return the entire response
                return response;
            }
            })
        );
    }

    // Code Volatility

    getAllCodeVolatilities(): Observable<any> {
        return this.http.get<any>(this.baseApiUrl + `/api/CodeVolatilities`).pipe(
            map((response) => {
            // Check if the response has a "value" property
            if (response && response.value) {
                // Return the array of objects from the "value" property
                return response.value;
            } else {
                // If there is no "value" property, return the entire response
                return response;
            }
            })
        );
    }

    // NetNewOrExistingCode

    getAllNetNewOrExistingCodes(): Observable<any> {
        return this.http.get<any>(this.baseApiUrl + `/api/NetNewOrExistingCodes`).pipe(
            map((response) => {
            // Check if the response has a "value" property
            if (response && response.value) {
                // Return the array of objects from the "value" property
                return response.value;
            } else {
                // If there is no "value" property, return the entire response
                return response;
            }
            })
        );
    }

    // Adding entries

    addDatabase(database: PortfolioDatabaseRequestDto): Observable<PortfolioDatabaseRequestDto> {
      return this.http.post<PortfolioDatabaseRequestDto>(this.baseApiUrl + `/api/PortfolioDatabases`, database);
    }

    addProgrammingLanguage(programmingLanguage: PortfolioProgrammingLanguageRequestDto): Observable<PortfolioProgrammingLanguageRequestDto> {
      return this.http.post<PortfolioProgrammingLanguageRequestDto>(this.baseApiUrl + `/api/PortfolioProgrammingLanguages`, programmingLanguage);
    }

    addUXUI(uxui: PortfolioUXUIRequestDto): Observable<PortfolioUXUIRequestDto> {
      return this.http.post<PortfolioUXUIRequestDto>(this.baseApiUrl + `/api/PortfolioUXUIs`, uxui);
    }
}