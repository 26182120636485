<h1>{{ practiceName }} Maturity Levels</h1>

<p-messages [(value)]="messages" [enableService]="false" [closable]="true"></p-messages>

<div class="flex justify-content-end mb-2">
  <button pButton pRipple label="Add Tool Maturity Level" icon="pi pi-plus" class="p-button-success mb-2" (click)="openNewDialog()"></button>
</div>

<div cdkDropList (cdkDropListDropped)="onDrop($event)">
  <p-table [value]="maturityLevels">
    <ng-template pTemplate="header">
      <tr>
        <th></th> <!-- Column for drag handle -->
        <th>Name</th>
        <th>Order</th>
        <th>Actions</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-item let-rowIndex="rowIndex">
      <tr cdkDrag>
        <td>
          <i cdkDragHandle class="pi pi-bars" style="cursor: move;"></i>
        </td>
        <td>{{ item.Name }}</td>
        <td>{{ item.Order }}</td>
        <td>
          <button type="button" pbutton="" icon="pi pi-trash" class="p-element p-button-danger p-button p-component p-button-icon-only" ng-reflect-icon="pi pi-trash" title="Delete Level" (click)="deleteMaturityLevel(item.Id)">
            <span class="p-button-icon pi pi-trash" aria-hidden="true"></span>
          </button>
        </td> <!-- Add a delete button -->
      </tr>
    </ng-template>
  </p-table>
</div>

  <p-dialog [(visible)]="newLevelDialog" [modal]="true" [style]="{width: '750px'}" header="Add new Maturity Level" [contentStyle]="{'overflow': 'visible'}" [modal]="true" class="p-fluid">
    <ng-template pTemplate="content">      
      <div class="field">
        <input type="text" pInputText placeholder="Name" [(ngModel)]="newName" [ngClass]="{'ng-invalid ng-dirty' : submitted && !newName}">
        <small class="ng-dirty ng-invalid" *ngIf="submitted && !newName">Name is required.</small>
      </div>
    </ng-template>
    <ng-template pTemplate="footer">
      <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="hideDialog()"></button>
      <button pButton pRipple label="Add" icon="pi pi-check" class="p-button-text" (click)="addNewMaturityLevel()"></button>
    </ng-template>

  </p-dialog>
