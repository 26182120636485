import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Message, MessageService } from 'primeng/api';
import { ProductService } from 'src/app/layout/service/product/products.service';
import { Suite, SuiteRequest, Product } from "src/app/api/product";

@Component({
  selector: 'app-suite-details',
  templateUrl: './suite-details.component.html'
})

export class SuiteDetailsComponent implements OnInit {
  suiteId: string = '';
  suite: Suite = {};

  editedSuite: any = {};
  editedLogo: string = '';

  editBasicDetails: boolean = false;
  editLogo: boolean = false;

  isSuiteDetailsLoaded: boolean = false;

  submitted: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private productService: ProductService,
    private messageService: MessageService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.suiteId = params['suiteId'];

      this.productService.getSuiteById(this.suiteId).subscribe((response: any) => {
        this.suite = response;
        this.isSuiteDetailsLoaded = true;
      });

      this.cdr.detectChanges();

    });
  }

  enableEditLogo(val: boolean) {
    this.editLogo = val;
    if (val) {
      this.editedLogo = this.suite?.Logo ?? '';
    } else {
      this.editedLogo = '';
    }
    this.cdr.detectChanges();
  }

  enableEditBasicDetails(val: boolean) {

    this.editBasicDetails = val;

    if (val) {
      // Create a copy of the product to edit
      this.editedSuite = { ...this.suite };
    } else {
      // ResetProduct if cancel is clicked
      this.editedSuite = null;
    }
  }

  openFileDialog() {
    const fileInput = document.getElementById('fileInput') as HTMLInputElement;
    fileInput.click();
  }

  onFileSelected(event: Event) {
    const fileInput = event.target as HTMLInputElement;
    if (fileInput.files && fileInput.files.length > 0) {
      const file = fileInput.files[0];
      if (file.size > 100 * 1024) { // 100 KB in bytes
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'File is too large. Please choose a smaller file.', life: 3000 });
      } else {
        this.convertToBase64(file);
      }
      this.cdr.detectChanges();
    }
  }

  convertToBase64(file: File) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.editedLogo = reader.result as string;
      if (this.suite) {
        this.suite.Logo = this.editedLogo;
      }
      console.log(this.editedLogo);
    };
    reader.onerror = (error) => {
      console.error('Error: ', error);
    };
  }

  clearLogo() {
    this.editedLogo = "";
    if (this.suite) {
      this.suite.Logo = "";
    }
  }

  updateLogo() {

    if (this.suite) {
      this.suite.Logo = this.editedLogo;

      const suiteRequest: SuiteRequest = {
        Id: this.suite.Id,
        SuiteRef: this.suite.SuiteRef,
        Name: this.suite.Name,
        Logo: this.editedLogo
      }

      this.productService.updateSuite(this.suiteId, suiteRequest).subscribe(
        (response: any) => {
          console.log(response);
          this.messageService.add({ severity: 'success', summary: 'Successful', detail: `Suite logo updated`, life: 3000 });
        },
        (error) => {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Could not update logo', life: 3000 });
        });

      this.editedLogo = '';
      this.editLogo = false;
      this.cdr.detectChanges();
    }
  }

  updateBasicDetails() {
    if (this.suite) {

      this.submitted = true;

      if (this.editedSuite.Name == null || this.editedSuite.Name.trim() === '') {
        return;
      }

      this.suite = { ...this.editedSuite};

      const suiteRequest: SuiteRequest = {
        Id: this.suite.Id,
        SuiteRef: this.editedSuite.SuiteRef,
        Name: this.editedSuite.Name,
        Logo: this.suite.Logo
      }

      this.productService.updateSuite(this.suiteId, suiteRequest).subscribe(
        (response: any) => {
          console.log(response);
          this.messageService.add({ severity: 'success', summary: 'Successful', detail: `Suite details updated`, life: 3000 });
        },
        (error) => {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Could not update suite details', life: 3000 });
        });

      this.editedSuite = {};
      this.editBasicDetails = false;
      this.submitted = false;
      this.cdr.detectChanges();
    }
  }
}
