import { Component, OnDestroy, OnInit } from '@angular/core';
import { QuicksightService } from 'src/app/layout/service/reporting/quicksight.service';

declare const QuickSightEmbedding: any;

@Component({
  selector: 'app-elements-quicksight',
  standalone: true,
  templateUrl: './elements-quicksight.component.html',
  styleUrls: ['./elements-quicksight.component.scss']
})
export class ElementsQuicksightComponent implements OnInit {
  private dashboard: any = null;
  private refreshInterval: any = null;

  constructor(private quicksightService: QuicksightService) {}

  ngOnInit() {
    this.initialize();
  }

  // ngOnDestroy() {
  //   this.destroy();
  // }

  private async initialize() {
    try {
      const embedUrl = await this.getEmbedUrl();
      await this.embedDashboard(embedUrl);
      this.setupAutoRefresh();
    } catch (error) {
      console.error('Failed to initialize dashboard:', error);
    }
  }

  private getEmbedUrl(): Promise<string> {
    return new Promise((resolve, reject) => {
      this.quicksightService.getQuicksightEmbedUrl().subscribe(
        (data) => {
          if (data && data.embedUrl) {
            resolve(data.embedUrl);
          } else {
            reject('Embed URL not found in response');
          }
        },
        (error) => {
          console.error('Error getting embed URL from service:', error);
          reject(error);
        }
      );
    });
  }

  private async embedDashboard(embedUrl: string) {
    try {
      const options = {
        url: embedUrl,
        container: '#dashboard-container',
        height: '850px',
        width: '100%',
        footerPaddingEnabled: true,
        printEnabled: true,
        loadingHeight: '800px',
        scrolling: 'no'
      };

      const embedContext = await QuickSightEmbedding.createEmbeddingContext();
      this.dashboard = await embedContext.embedDashboard(options);
    } catch (error) {
      console.error('Error embedding dashboard:', error);
    }
  }

  private setupAutoRefresh() {
    const REFRESH_INTERVAL = 8 * 60 * 60 * 1000; // Refresh every 8 hours
    
    this.refreshInterval = setInterval(async () => {
      try {
        const newUrl = await this.getEmbedUrl();
        await this.dashboard.setEmbedUrl(newUrl);
      } catch (error) {
        console.error('Error refreshing embed URL:', error);
      }
    }, REFRESH_INTERVAL);
  }

  private handleError(event: any) {
    console.error('Dashboard error:', event);
  }

  private handleLoad(event: any) {
    console.log('Dashboard loaded successfully');
  }

  // private destroy() {
  //   if (this.refreshInterval) {
  //     clearInterval(this.refreshInterval);
  //   }
  //   if (this.dashboard) {
  //     this.dashboard.dispose();
  //   }
  // }
}
