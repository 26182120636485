<h1>
  ARBs
  <a href="https://irissoftware.sharepoint.com/sites/EngineeringIntranet/SitePages/architecture-review-board.aspx" target="_blank">
    <p-button icon="pi pi-question"></p-button>
  </a>
</h1>

<p-toast></p-toast>

<p-messages [(value)]="messages" [enableService]="false" [closable]="true"></p-messages>

<div class="flex justify-content-end mb-2">
  <button pButton pRipple label="Request a New ARB" icon="pi pi-plus" class="p-button-success mb-2" onclick="window.open('https://forms.office.com/pages/responsepage.aspx?id=ZHU-fLibGkGc4ndc6r2IvqzDPFUR2wdEqbNQrhyI_QBUQUs3RVM0WDZVTFJLQ1JMNEZQTEdPU0hBSi4u')"></button>
</div>

<p-table #dt1 [value]="arbs" [columns]="cols" dataKey="code" [rows]="10" [rowHover]="true" styleClass="p-datatable-gridlines" [paginator]="true" [globalFilterFields]="cols"
         [showCurrentPageReport]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" responsiveLayout="scroll"
         sortMode="multiple" stateStorage="session" stateKey="statedemo-session" [rowsPerPageOptions]="[10, 20, 30]">

  <ng-template pTemplate="caption">
    <div class="flex justify-content-between flex-column sm:flex-row">
      <button pButton label="Clear" class="p-button-outlined mb-2" icon="pi pi-filter-slash" (click)="clear(dt1)"></button>

      <span class="p-input-icon-left mb-2">
        <i class="pi pi-search"></i>
        <input pInputText type="text" #filter (input)="onGlobalFilter(dt1, $event)" placeholder="Search Keyword" class="w-full" />
      </span>
    </div>
  </ng-template>

  <ng-template pTemplate="header" let-columns>
    <tr>
      <th style="min-width: 8rem; max-width: 8rem; width:8rem;" pSortableColumn="code">
        <div class="flex justify-content-between align-items-center">
          <div>
            Code
            <p-sortIcon field="code"></p-sortIcon>
          </div>
          <p-columnFilter type="text" field="code" display="menu" placeholder="Search by Code" (click)="$event.stopPropagation()"></p-columnFilter>
        </div>
      </th>
      <th style="min-width: 8rem" pSortableColumn="date">
        <div class="flex justify-content-between align-items-center">
          <div>
            Date
            <p-sortIcon field="date"></p-sortIcon>
          </div>
          <p-columnFilter type="text" field="date" display="menu" placeholder="Search by Date" (click)="$event.stopPropagation()"></p-columnFilter>
        </div>
      </th>
      <th style="min-width: 8rem" pSortableColumn="title">
        <div class="flex justify-content-between align-items-center">
          <div>
            Title
            <p-sortIcon field="title"></p-sortIcon>
          </div>
          <p-columnFilter type="text" field="title" display="menu" placeholder="Search by Title" (click)="$event.stopPropagation()"></p-columnFilter>
        </div>
      </th>
      <th style="min-width: 8rem" pSortableColumn="products">
        <div class="flex justify-content-between align-items-center">
          <div>
            Product(s)
            <p-sortIcon field="products"></p-sortIcon>
          </div>
          <p-columnFilter type="text" field="products" display="menu" placeholder="Search by Products" (click)="$event.stopPropagation()"></p-columnFilter>
        </div>
      </th>
      <th style="min-width: 8rem" pSortableColumn="currentStatus">
        <div class="flex justify-content-between align-items-center">
          <div>
            Status
            <p-sortIcon field="currentStatus"></p-sortIcon>
          </div>
          <p-columnFilter type="text" field="currentStatus" display="menu" placeholder="Search by Status" (click)="$event.stopPropagation()"></p-columnFilter>
        </div>
      </th>
    </tr>
  </ng-template>

  <ng-template pTemplate="body" let-arb>
    <tr>
      <td><a [routerLink]="['/arb', arb.code, 'details']">{{arb.code}}</a></td>
      <td><a [routerLink]="['/arb', arb.code, 'details']">{{niceDate(arb.date)}}</a></td>
      <td><a [routerLink]="['/arb', arb.code, 'details']">{{arb.title}}</a></td>
      <td>
        <ng-container *ngFor="let item of findProduct(arb.products)">
          <ng-container *ngIf="item.ID">
            <a [routerLink]="['/product', item.ID, 'dashboard']" target="_blank" class="no-decoration">
              <p-tag value="{{ item.Name }}" [styleClass]="'cursor-pointer product-badge producttype-' + item.Type"
                     pTooltip="{{ item.Name }}" tooltipPosition="top"></p-tag>
            </a>
          </ng-container>
          <ng-container *ngIf="!item.ID">
            <p-tag value="{{ item.Name }}" [styleClass]="'cursor-pointer product-badge producttype-' + item.Type"
                   pTooltip="{{ item.Name }}" tooltipPosition="top"></p-tag>
          </ng-container>
        </ng-container>

      </td>
      <td>

        <div [ngClass]="'arbstatus-badge arb-' + arb.currentStatus ">
          {{ arb.currentStatus }}
        </div>
      </td>
    </tr>
  </ng-template>

  <ng-template pTemplate="isARBLoading">
    <tr>
      <td colspan="10">Loading ARB data. Please wait...</td>
    </tr>
  </ng-template>
</p-table>
