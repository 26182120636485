import { Component, OnInit } from '@angular/core';
import { ProductNFRService } from 'src/app/layout/service/product/product-nfr.service';
import { ProductService } from 'src/app/layout/service/product/products.service';
import { ActivatedRoute } from '@angular/router';
import { Message, MessageService } from 'primeng/api';
import { NFRCategoryDto, ProductNFRRequestDto, ProductNFRDto, NonFunctionalRequirementDto } from 'src/app/api/product-nfr';

@Component({
  selector: 'product-nfrs',
  templateUrl: './nfrs.html',
  styleUrls: ['./nfrs.scss'],
})
export class ProductNFRComponent implements OnInit {

  objectId: number = 0;
  productType: string = '';
  productTypeId: number = 0;

  messages: Message[] = []; 

  categories: NFRCategoryDto[] = [];
  requirements: NonFunctionalRequirementDto[] = [];
  productNfrs: any[] = []; // Loaded from API

  editMode = false;

  tooltipNfrId: number | null = null;
  tooltipX = 0;
  tooltipY = 0;
  modalOpen = false;
  submitted = false;
  newNfrValue = '';
  newNFRName = '';
  newNFRDescription = '';
  selectedNfrId: number | null = null;

  constructor(private route: ActivatedRoute, private nfrService: ProductNFRService, private productService: ProductService, private messageService: MessageService) { }

  ngOnInit() {
    const parentRoute = this.route.parent;  // Access the parent route

    if (parentRoute) {
      parentRoute.params.subscribe(params => {
        if (parentRoute.snapshot.url[0]?.path === 'product') {
          this.productType = 'product';
          this.objectId = params['id'];
        } else if (parentRoute.snapshot.url[0]?.path === 'suite') {
          this.productType = 'suite';
          this.objectId = params['suiteId'];
        }

        this.productService.getAllProductTypes().subscribe((data: any) => {

          // Find the product type ID that matches the product type, case-insensitive
          const productType = data.find((type: any) => type.Name.toLowerCase() === this.productType.toLowerCase());
          this.productTypeId = productType.Id;

          this.nfrService.getProductNFRs(this.objectId, this.productTypeId).subscribe((data: any) => {
            this.productNfrs = data
          });
        });
      });

      this.nfrService.getAllNFRCategories().subscribe((data: any) => {
        this.categories = data;
      });

      this.nfrService.getAllNonFunctionalRequirements().subscribe((data: any) => {
        this.requirements = data;
      });

    }
  }

  get sortedCategories() {
    return this.categories.sort((a, b) => (a.Priority ?? 0) - (b.Priority ?? 0));
  }

  getRequirementsForCategory(categoryId: number) {
    return this.requirements
      .filter(nfr => nfr.NFRCategoryId === categoryId)
      .sort((a, b) => (a.Priority ?? 0) - (b.Priority ?? 0));
  }

  getNfrClass(nfrId: number) {
    return this.hasValue(nfrId) ? 'nfr-complete' : 'nfr-incomplete';
  }

  hasValue(nfrId: number): boolean {
    return this.productNfrs.some(nfr => nfr.NFRId === nfrId);
  }

  getValue(nfrId: number): string {
    const found = this.productNfrs.find(nfr => nfr.NFRId === nfrId);
    return found ? found.Value : '';
  }

  showTooltip(nfrId: number, event: MouseEvent) {
    this.tooltipNfrId = nfrId;

    const tooltipWidth = 250; // Matches CSS max-width
    const offset = 15; // Space between cursor and tooltip

    // Get scrolling offset
    const scrollX = window.scrollX || document.documentElement.scrollLeft;
    const scrollY = window.scrollY || document.documentElement.scrollTop;

    // Get screen size
    const screenWidth = window.innerWidth;
    const screenHeight = window.innerHeight;

    // Calculate initial position
    let x = event.clientX + offset + scrollX;
    let y = event.clientY + offset + scrollY;

    // Prevent tooltip from overflowing right
    if (x + tooltipWidth > screenWidth + scrollX) {
      x = screenWidth + scrollX - tooltipWidth - offset;
    }

    // Prevent tooltip from overflowing bottom
    if (y + 100 > screenHeight + scrollY) {
      y = screenHeight + scrollY - 100;
    }

    // Prevent tooltip from going off the top
    if (y < scrollY) {
      y = scrollY + offset;
    }

    this.tooltipX = x;
    this.tooltipY = y;
  }

  hideTooltip() {
    this.tooltipNfrId = null;
  }


  openAddModal(nfrId: number) {
    this.selectedNfrId = nfrId;
    this.newNfrValue = this.requirements.find(nfr => nfr.Id === nfrId)?.Default || '';
    this.newNFRName = this.requirements.find(nfr => nfr.Id === nfrId)?.Name || '';
    this.newNFRDescription = this.requirements.find(nfr => nfr.Id === nfrId)?.Description || '';
    this.modalOpen = true;
    this.editMode = false;
  }

  closeModal() {
    this.modalOpen = false;
    this.selectedNfrId = null;
  }

  saveNewValue() {

    if (this.selectedNfrId !== null) {

      this.submitted = true;

      if (this.newNfrValue === '') {
        return;
      };


      const request: ProductNFRRequestDto = {
        ObjectId: this.objectId,
        ProductTypeId: this.productTypeId,
        NFRId: this.selectedNfrId,
        Value: this.newNfrValue
      };

      if (this.editMode) {

        let productNfrId = 0;
        this.productNfrs.forEach((nfr) => {
          if (nfr.NFRId === this.selectedNfrId) {
            productNfrId = nfr.Id;
          }
        });

        this.nfrService.updateProductNFR(productNfrId, request).subscribe((data: any) => {
          this.productNfrs.find(nfr => nfr.NFRId === this.selectedNfrId).Value = this.newNfrValue;
          this.submitted = false;
          this.closeModal();
        });

      } else {

        this.nfrService.createProductNFR(request).subscribe((data: any) => {

          const newProductNfr: ProductNFRDto = {
            Id: data.id,
            NFRId: data.nfrId,
            ProductTypeId: data.productTypeId,
            ProductTypeName: data.productTypeName,
            ObjectId: data.objectId,
            Value: data.value
          }

          this.productNfrs.push(newProductNfr);
          this.submitted = false;
          this.closeModal();
        });
      }
    }
  }

  editNfr(nfrId: number) {
    this.selectedNfrId = nfrId;
    this.newNfrValue = this.productNfrs.find(nfr => nfr.NFRId === nfrId)?.Value || '';
    this.newNFRName = this.requirements.find(nfr => nfr.Id === nfrId)?.Name || '';
    this.newNFRDescription = this.requirements.find(nfr => nfr.Id === nfrId)?.Description || '';
    this.modalOpen = true;
    this.editMode = true;
  }

  deleteNfr(nfrId: number) {

    //Loop through all the productNfrs to get the Id of the productNfr to delete
    let productNfrId = 0;
    this.productNfrs.forEach((nfr) => {
      if (nfr.NFRId === nfrId) {
        productNfrId = nfr.Id;
      }
    });

    if (productNfrId !== 0) {
      this.nfrService.deleteProductNFR(productNfrId).subscribe((data: any) => {
        this.productNfrs = this.productNfrs.filter((nfr) => nfr.NFRId !== nfrId);
      });
    }

  }
}

