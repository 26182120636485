import { Component, OnInit } from '@angular/core';
import { SDLCPracticeMaturity, PracticeMaturityLevel, SDLCPracticeMaturityPutDto } from 'src/app/api/sdlc-maturity';
import { SDLCPracticeDto } from 'src/app/api/product-sdlc';
import { ProductMetricService } from 'src/app/layout/service/product/product-metric.service';
import { ProductSDLCService } from 'src/app/layout/service/product/product-sdlc.service';
import { ActivatedRoute } from '@angular/router';
import { Message, MessageService } from 'primeng/api';

@Component({
  selector: 'app-sdlcpracticematurity',
  templateUrl: './sdlcpracticematurity.component.html'
})
export class SDLCPracticeMaturityComponent implements OnInit {

  practiceId: string = '';
  practiceName: string = '';

  messages: Message[] = [];

  practiceMaturities: SDLCPracticeMaturity[] = [];
  practices: SDLCPracticeDto[] = [];
  maturityLevels: PracticeMaturityLevel[] = [];
  tiers: any[] = [];

  selectedMaturityLevel: { [key: number]: number | null } = {};

  constructor(private route: ActivatedRoute, private sdlcService: ProductSDLCService, private messageService: MessageService, private productMetricService: ProductMetricService) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.practiceId = params['practiceId'];

      this.sdlcService.getSDLCPracticeById(this.practiceId).subscribe(
        (data: any) => {
          this.practiceName = data.Name;

          this.sdlcService.getPracticeMaturityLevels(this.practiceId).subscribe(
            (data: PracticeMaturityLevel[]) => {
              this.maturityLevels = data.sort((a, b) => {
                if (a.Order === undefined && b.Order === undefined) return 0;
                if (a.Order === undefined) return 1; // Treat null as highest value
                if (b.Order === undefined) return -1; // Treat null as highest value
                return a.Order - b.Order;
              });
            }
          );

          this.sdlcService.getSDLCPracticeMaturities(this.practiceId).subscribe(
            (data: SDLCPracticeMaturity[]) => {
              this.practiceMaturities = data;
            });

            this.productMetricService.getAllTiers().subscribe((tiers) => {
              this.tiers = tiers;

              this.tiers.forEach(tier => {
                this.selectedMaturityLevel[tier.Id] = this.getSelectedMaturityLevel(tier.Id);
              });
            });
        });    
    });
  }

  getSelectedMaturityLevel(tierId: number): number | null {
    const practiceMaturity = this.practiceMaturities.find(pm => pm.TierId === tierId);
    return practiceMaturity ? practiceMaturity.MinimumMaturityLevelId ?? null : null;
  }

  onMaturityLevelChange(event: any, tierId: number) {
    const practiceMaturity = this.practiceMaturities.find(pm => pm.TierId === tierId);
    if (practiceMaturity) {
      practiceMaturity.MinimumMaturityLevelId = event.value;
    } else {
      this.practiceMaturities.push({ Id: 0, sdlcPracticeId: parseInt(this.practiceId), TierId: tierId, MinimumMaturityLevelId: event.value });
    }
  }

  saveMaturities() {

    this.practiceMaturities.forEach(pm => {

      const pmid: number = pm.Id ?? 0;

      const putItem: SDLCPracticeMaturityPutDto = {
        TierId: pm.TierId,
        sdlcPracticeId: parseInt(this.practiceId),
        MinimumMaturityLevelId: pm.MinimumMaturityLevelId
      };

      if (pm.Id === 0) {
        this.sdlcService.createSDLCPracticeMaturity(putItem).subscribe(
          (data: any) => {
            this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Maturity levels saved successfully.' });
          });
      } else {

        this.sdlcService.updateSDLCPracticeMaturity(pmid, putItem).subscribe(
          (data: any) => {
            this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Maturity levels saved successfully.' });
          });
      }
    });
  }
}
