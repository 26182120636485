<h1>Details</h1>
<p-toast></p-toast>

<div *ngIf="isSuiteDetailsLoaded">
  <div class="productdetails-container">
    <div class="productdetails-top-row">
      <div class="productdetails-left-column">
        <p-panel>
          <ng-template pTemplate="header">
            <div class="details-panel-header-container">
              <span class="font-bold details-panel-header-title">
                Details
              </span>
              <button type="button" pbutton="" icon="pi pi-pencil" class="p-element p-button p-component p-button-icon-only details-panel-header-edit-button" ng-reflect-icon="pi pi-pencil" title="Edit Details" (click)="enableEditBasicDetails(true)" [ngClass]="{'edit-button-hidden': editBasicDetails}">
                <span class="p-button-icon pi pi-pencil" aria-hidden="true" style="margin-right:10px"></span>
              </button>
            </div>
          </ng-template>
          <div class="productdetails-attribute">
            <div class="productdetails-label">Suite Name:</div>
            <div class="productdetails-value" *ngIf="!editBasicDetails">{{ suite.Name }}</div>
            <div class="productdetails-value" *ngIf="editBasicDetails && editedSuite">
              <input type="text" pInputText id="name" class="uniform-width-input" [(ngModel)]="editedSuite.Name" required autofocus [ngClass]="{'ng-invalid ng-dirty' : submitted && !editedSuite.Name}" />
            </div>
          </div>
          <div class="productdetails-attribute">
            <div class="productdetails-label">Suite Reference:</div>
            <div class="productdetails-value" *ngIf="!editBasicDetails">{{ suite.SuiteRef }}</div>
            <div class="productdetails-value" *ngIf="editBasicDetails && editedSuite">
              <input type="text" pInputText id="productRef" class="uniform-width-input" [(ngModel)]="editedSuite.SuiteRef" />
            </div>
          </div>
          <ng-template pTemplate="footer">
            <div *ngIf="editBasicDetails">
              <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="enableEditBasicDetails(false)"></button>
              <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text" (click)="updateBasicDetails()"></button>
            </div>
          </ng-template>
        </p-panel>
      </div>
      <div class="productdetails-right-column">
        <p-panel>
          <ng-template pTemplate="header">
            <div class="details-panel-header-container">
              <span class="font-bold details-panel-header-title">
                Suite Logo
              </span>
              <button type="button" pbutton="" icon="pi pi-pencil" class="p-element p-button p-component p-button-icon-only details-panel-header-edit-button" ng-reflect-icon="pi pi-pencil" title="Edit Logo" (click)="enableEditLogo(true)" [ngClass]="{'edit-button-hidden': editLogo}">
                <span class="p-button-icon pi pi-pencil" aria-hidden="true" style="margin-right:10px"></span>
              </button>
            </div>
          </ng-template>
          <img [src]="suite.Logo" *ngIf="suite?.Logo" class="product-details-logo-display" />
          <span *ngIf="!suite.Logo">(No logo provided)</span>

          <div class="logo-buttons" *ngIf="editLogo">
            <button type="button" pbutton="" icon="pi pi-upload" class="logo-button p-element p-button p-component p-button-icon-only details-panel-header-edit-button" ng-reflect-icon="pi pi-upload" title="Upload File" (click)="openFileDialog()">
              <span class="p-button-icon pi pi-upload" aria-hidden="true" style="margin-right:12px"></span>
            </button>
            <button type="button" pbutton="" icon="pi pi-trash" class="logo-button p-element p-button p-button-danger p-component p-button-icon-only details-panel-header-edit-button" ng-reflect-icon="pi pi-trash" title="Clear Logo" (click)="clearLogo()">
              <span class="p-button-icon pi pi-trash" aria-hidden="true" style="margin-right:12px"></span>
            </button>
            <input type="file" id="fileInput" (change)="onFileSelected($event)" accept="image/*" style="display: none;">
          </div>

          <ng-template pTemplate="footer">
            <div *ngIf="editLogo">
              <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="enableEditLogo(false)"></button>
              <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text" (click)="updateLogo()"></button>
            </div>
          </ng-template>
        </p-panel>
      </div>
    </div>
  </div>
</div>
<div *ngIf="!isSuiteDetailsLoaded">
  <p-skeleton width="10%" height="1rem"></p-skeleton>
</div>
