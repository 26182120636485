import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { LinkDto } from "src/app/api/links";

@Injectable(
  {
    providedIn: 'root'
  }
)

export class ProductLinksService {
  baseApiUrl: string = environment.baseApiUrl;
  constructor(private http: HttpClient) { }

  getProductLinks(productId: string): Observable<any> {
    return this.http.get<any>(`${this.baseApiUrl}/api/Links?productId=${productId}`);
  }

  addProductLink(productLink: LinkDto): Observable<any> {
    return this.http.post<any>(`${this.baseApiUrl}/api/Links`, productLink);
  }

  deleteProductLink(id: number): Observable<any> {
    return this.http.delete<any>(`${this.baseApiUrl}/api/Links/${id}`);
  }

  updateProductLink(id: number, productLink: LinkDto): Observable<any> {
    return this.http.put<any>(`${this.baseApiUrl}/api/Links/${id}`, productLink);
  }
}
