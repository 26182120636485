import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { EmailRequestDto, EmailRequestNewDto } from "src/app/api/email-request";

@Injectable({
    providedIn: 'root'
})
export class EmailService {
    baseApiUrl: string = environment.baseApiUrl;

    constructor(private http: HttpClient) { }

    sendEmail(emailRequestDto: EmailRequestDto): Observable<any> {
        return this.http.post(this.baseApiUrl + `/api/Email/`, emailRequestDto);
    }


    sendEmailNew(emailRequestDto: EmailRequestNewDto): Observable<any> {
        if (!environment.production) {
            console.log('Email sending is blocked in development environment');
            return new Observable();
        }
        return this.http.post(this.baseApiUrl + `/api/Email/`, emailRequestDto);
    }
}
