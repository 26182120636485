import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MenuItem, Message, MessageService } from 'primeng/api';
import { ProductContact, User } from 'src/app/api/product';
import { GraphExplorerService } from 'src/app/layout/service/graphexplorer/graph-explorer.service';
import { ProductService } from 'src/app/layout/service/product/products.service';
import { LogService } from 'src/app/layout/service/log.service';

const GRAPH_USERS_ENDPOINT = 'https://graph.microsoft.com/v1.0/users';


@Component({
  selector: 'app-product-team',
  templateUrl: './product-team.component.html',
  styleUrls: ['product-team.component.scss']
})

export class ProductTeamComponent implements OnInit {
  users: User[] = [];
  productContacts: ProductContact[] = [];
  userPhotos: { [key: string]: string } = {};

  selectedUser: User | null = null;

  searchKeyword: string = '';

  userDialog: boolean = false;

  items: MenuItem[] | undefined;

  productId: string = '';

  messages: Message[] = [];

  productContactGroups: any[] = [];
  filteredProductContacts: any[] = [];
  selectedContactGroup: string | null = null;
  
  constructor(private http: HttpClient, private route: ActivatedRoute, private graphExplorerService: GraphExplorerService, 
    private productService: ProductService, private messageService: MessageService, private logService: LogService) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.productId = params['id'];
    });

    this.items = [
      // {
      //   label: 'Update',
      //   icon: 'pi pi-pencil',
      //   command: () => {
      //     // this.update();
      //   }
      // },
      {
        label: 'Delete',
        icon: 'pi pi-times',
        command: (productUser: any) => {
          this.deleteProductUser(productUser);
        }
      }
    ];
    
    this.getAllUsers();
    this.getProductContacts();

    this.productService.getAllProductContactGroups().subscribe((productSDLCContactGroups) => {
      this.productContactGroups = productSDLCContactGroups;
    });

  }

  getAllUsers() {
    this.graphExplorerService.getAllADUsers().subscribe((users) => {
      this.users = users;
    });
  }

  getProductContacts() {
    this.productService.getProductContacts(this.productId).subscribe((productContacts) => {
      this.productContacts = productContacts;
      this.filteredProductContacts = productContacts;

      this.productContacts.forEach(user => {
        if (user.UserGraphId) { 
          this.loadUserPhoto(user.UserGraphId);
        }
      });
    });
  }

  getProductContactGroupNameById(id: number): string {
    const group = this.productContactGroups.find((group) => group.Id === id);
    return group ? group.Name : '';
  }

  loadUserPhoto(userId: string) {
    this.graphExplorerService.getUserPhoto(userId).subscribe((photoBlob) => {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.userPhotos[userId] = e.target.result;
      };
      reader.readAsDataURL(photoBlob);
    });
  }

  searchUsers(event?: any) {
    if (event.filter) {
      this.graphExplorerService.searchADUsers(event.filter).subscribe((users) => {
        this.users = users;
      });
    } else {
      this.getAllUsers();
    }
  }

  addUser() {
    const productUser = {
      UserGraphId: this.selectedUser?.id,
      Name: this.selectedUser?.displayName,
      Mail: this.selectedUser?.mail,
      productId: this.productId,
      ProductContactGroupId: this.selectedUser?.ProductContactGroupId
    };

    if (this.selectedUser && this.productId && productUser.UserGraphId && productUser.Name) {
      this.productService.createProductContact(productUser).subscribe((response) => {
        this.messageService.add({ severity: 'success', summary: 'Successful', detail: `${productUser.Name} added`, life: 3000 });
        this.logService.sendInfoMessage('CREATE', 'product/' + this.productId + '/team/', productUser);

        this.getProductContacts();
        if (this.selectedUser?.ProductContactGroupId) {
          this.selectedUser.ProductContactGroupId = 0;
        }
      });
    }

    this.selectedUser = null;
  }

  openUserDialog() {
    if (this.selectedUser?.ProductContactGroupId) {
      this.selectedUser.ProductContactGroupId = 0;
    }
    
    this.userDialog = true;
  }

  hideDialog() {
    this.userDialog = false;
    this.selectedUser = null;
  }

  deleteProductUser(productUser: any) {
    if (productUser && productUser.Id) {
      this.productService.deleteProductContact(productUser.Id).subscribe((response) => {
        this.messageService.add({ severity: 'success', summary: 'Successful', detail: `${productUser.Name} removed`, life: 3000 });
        this.logService.sendInfoMessage('DELETE', 'product/' + this.productId + '/team/' + productUser.Id, null, productUser);
        this.getProductContacts();
      });
    }
  }

  onContactGroupChange() {
    if (this.selectedContactGroup) {
      this.filteredProductContacts = this.productContacts.filter(contact => contact.ProductContactGroupName === this.selectedContactGroup);
    } else {
      this.filteredProductContacts = this.productContacts;
    }
  }

  emailUser(email: string) {
    window.location.href = `mailto:${email}`;
  }
}
