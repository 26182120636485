import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Message, MessageService } from 'primeng/api';
import { ArchitectureService } from 'src/app/layout/service/architecture/architecture.service';
import { ProductService } from 'src/app/layout/service/product/products.service';
import { ArbDto } from "src/app/api/architecture";
import { Suite } from 'src/app/api/product';
import { catchError } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-arb-details',
  templateUrl: './arb-details.component.html'
})

export class ArbDetailsComponent implements OnInit {
  arbCode: string = '';
  messages: Message[] = [];
  hasProductRef: boolean = true;
  isARBLoading: boolean = true;
  hasARB: boolean = false;
  arb: any;
  products: any[] = [];
  suites: any[] = [];

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private messageService: MessageService,
    private architectureService: ArchitectureService,
    private productService: ProductService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.arbCode = params['arbCode'];
      this.isARBLoading = true;
      this.hasARB = false;
      this.arb = null;

      this.productService.getAllProducts().subscribe((prods)=> {
        this.products = prods;
      });

      this.productService.getAllSuites().subscribe((suites) => {
        this.suites = suites;
      });

      this.architectureService.getARB(this.arbCode).pipe(
        catchError((error) => {
          if (error.status != 404) {
            console.log('Error fetching ARB:', error);
          }
          this.isARBLoading = false;
          this.hasARB = false;
          return of(null);
        })
      ).subscribe((arb) => {
        if(arb != null) {
          this.arb = arb;
          this.hasARB = true;
        }
        this.isARBLoading = false;
      });



   });

   }

     getActionItemEditURL(id: string): string {
    return "https://irissoftware.sharepoint.com/sites/EngineeringIntranet/Lists/Architecture%20Review%20Boards%20Followup/DispForm.aspx?ID=" + id;
  }

  tagify(option: string | undefined): string {
    if (!option) {
      return '';
    }
    let tagValue = option.trim();
    tagValue = tagValue.replace(/\s+/g, '_');
    tagValue = tagValue.replace(/[^a-zA-Z0-9_]/g, '');
    return tagValue;
  }

      niceDate(date?: Date): string {
    if (date == null) {
      return "";
    }
    const options: Intl.DateTimeFormatOptions = {
      day: '2-digit',
      month: 'long',
      year: 'numeric'
    };
    return new Date(date).toLocaleString('en-GB', options);
  }

  findSuite(suiteCode: string): Suite | null {

    let suite = this.suites.find(suite => suite.SuiteRef === suiteCode.trim());
    if (suite) {
      return suite;
    } else {
      return null;
    }
  }

  findProduct(productCode: string): any[] {

    let prodNames: any[] = [];

    const productCodes = productCode.split(',');

    for (let code of productCodes) {

      let product = this.products.find(product => product.ProductRef === code.trim());
      if (product) {

        const newProd = {
          Name: product.Name,
          Type: "product",
          ID: product.Id
        }

        prodNames.push(newProd);
      } else {

        const newProd = {
          Name: code,
          Type: "unknown",
          ID: ""
        }

        prodNames.push(newProd);
      }
    }

    return prodNames;
  }
}
