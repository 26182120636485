<h1>Non-Functional Requirements</h1>

<p-messages [(value)]="messages" [enableService]="false" [closable]="true"></p-messages>

<div class="flex justify-content-end mb-2">
  <button pButton pRipple label="Add Requirement" icon="pi pi-plus" class="p-button-success mb-2" (click)="openNewDialog()"></button>
</div>

<div style="display: flex; flex-direction: column; margin-bottom: 20px;">
  <div style="display: flex; flex-wrap: wrap; gap: 5px; max-width: 100%;">
    <p-multiSelect [options]="nfrCategories"
                   [(ngModel)]="filteredCategories"
                   placeholder="Select Categories"
                   optionLabel="Name"
                   display="chip"
                   [showToggleAll]="false"
                   (onChange)="onCategoriesChange($event)">
    </p-multiSelect>
  </div>
</div>

<div>
  <p-table [value]="filteredRequirements">
    <ng-template pTemplate="header">
      <tr>
        <th>ID</th>
        <th>Category</th>
        <th>Name</th>
        <th>Description</th>
        <th>Default</th>
        <th>Priority</th>
        <th>Actions</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-item let-rowIndex="rowIndex">
      <tr>
        <td>{{ item.Id }}</td>
        <td>{{ getCategoryName(item.NFRCategoryId) }}</td>
        <td>{{ item.Name }}</td>
        <td>{{ shortenDescription(item.Description) }}</td>
        <td>{{ item.Default }}</td>
        <td>{{ item.Priority }}</td>
        <td>
          <button type="button" pbutton="" icon="pi pi-pencil" class="p-element p-button p-component p-button-icon-only mr-2" ng-reflect-icon="pi pi-pencil" title="Update Requirement" (click)="openUpdateDialog(item)">
            <span class="p-button-icon pi pi-pencil" aria-hidden="true"></span>
          </button>
          <button type="button" pbutton="" icon="pi pi-trash" class="p-element p-button-danger p-button p-component p-button-icon-only" ng-reflect-icon="pi pi-trash" title="Delete Requirement" (click)="deleteRequirement(item)">
            <span class="p-button-icon pi pi-trash" aria-hidden="true"></span>
          </button>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>

<p-dialog [(visible)]="newRequirementDialog" [modal]="true" [style]="{width: '750px'}" header="Add new Non-Functional Requirement" [contentStyle]="{'overflow': 'visible'}" [modal]="true" class="p-fluid">
  <ng-template pTemplate="content">
    <div class="field">
      <p-dropdown [options]="nfrCategories"
                  [(ngModel)]="selectedCategory"
                  optionLabel="Name"
                  optionValue="Id"
                  [ngClass]="{'ng-invalid ng-dirty' : submitted && !selectedCategory}"
                  placeholder="Select a Category">
      </p-dropdown>
      <small class="ng-dirty ng-invalid" *ngIf="submitted && !selectedCategory">Category is required.</small>
    </div>
    <div class="field">
      <input type="text" pInputText placeholder="Name" [(ngModel)]="newName" [ngClass]="{'ng-invalid ng-dirty' : submitted && !newName}">
      <small class="ng-dirty ng-invalid" *ngIf="submitted && !newName">Name is required.</small>
    </div>
    <textarea pInputTextarea
              placeholder="Description"
              [(ngModel)]="newDescription"
              [ngClass]="{'ng-invalid ng-dirty' : submitted && !newDescription}"
              rows="4">
      </textarea>
    <div class="field">
      <input type="text" pInputText placeholder="Default" [(ngModel)]="newDefault">
    </div>
    <div class="field">
      <input type="text" pInputText placeholder="Priority" [(ngModel)]="newPriority" [ngClass]="{'ng-invalid ng-dirty' : submitted && !newPriority}">
      <small class="ng-dirty ng-invalid" *ngIf="submitted && !newPriority">Priority is required.</small>
    </div>
  </ng-template>
  <ng-template pTemplate="footer">
    <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="hideDialog()"></button>
    <button pButton pRipple label="Add" icon="pi pi-check" class="p-button-text" (click)="addNewRequirement()"></button>
  </ng-template>

</p-dialog>

<p-dialog [(visible)]="UpdateRequirementDialog" [modal]="true" [style]="{width: '750px'}" header="Update a Non-Functional Requirement" [contentStyle]="{'overflow': 'visible'}" [modal]="true" class="p-fluid">
  <ng-template pTemplate="content">
    <div class="field">
      <input type="text" pInputText placeholder="Name" [(ngModel)]="existingId" readonly="readonly">
    </div>
    <div class="field">
      <p-dropdown [options]="nfrCategories"
                  [(ngModel)]="selectedCategory"
                  optionLabel="Name"
                  optionValue="Id"
                  [ngClass]="{'ng-invalid ng-dirty' : submitted && !selectedCategory}"
                  placeholder="Select a Category">
      </p-dropdown>
      <small class="ng-dirty ng-invalid" *ngIf="submitted && !selectedCategory">Category is required.</small>
    </div>
    <div class="field">
      <input type="text" pInputText placeholder="Name" [(ngModel)]="newName" [ngClass]="{'ng-invalid ng-dirty' : submitted && !newName}">
      <small class="ng-dirty ng-invalid" *ngIf="submitted && !newName">Name is required.</small>
    </div>
    <div class="field">
      <textarea pInputTextarea
                placeholder="Description"
                [(ngModel)]="newDescription"
                [ngClass]="{'ng-invalid ng-dirty' : submitted && !newDescription}"
                rows="4">
      </textarea>
      <small class="ng-dirty ng-invalid" *ngIf="submitted && !newDescription">Description is required.</small>
    </div>
    <div class="field">
      <input type="text" pInputText placeholder="Default" [(ngModel)]="newDefault">
    </div>
    <div class="field">
      <input type="text" pInputText placeholder="Priority" [(ngModel)]="newPriority" [ngClass]="{'ng-invalid ng-dirty' : submitted && !newPriority}">
      <small class="ng-dirty ng-invalid" *ngIf="submitted && !newPriority">Priority is required.</small>
    </div>
  </ng-template>
  <ng-template pTemplate="footer">
    <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="hideExistingDialog()"></button>
    <button pButton pRipple label="Update" icon="pi pi-check" class="p-button-text" (click)="updateRequirement()"></button>
  </ng-template>

</p-dialog>
