import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProductSDLC, ProductSDLCPutDto, ProductSDLCRequestDto, SDLCPrimaryContact, SDLCRequestStatusDto } from 'src/app/api/product-sdlc';
import { ProductSDLCService } from 'src/app/layout/service/product/product-sdlc.service';
import { ConfirmationService, Message, MessageService } from 'primeng/api';
import { ProductSelectionService } from 'src/app/product.selection.service';
import { Product, User } from 'src/app/api/product';
import { ProductService } from 'src/app/layout/service/product/products.service';
import { GraphExplorerService } from 'src/app/layout/service/graphexplorer/graph-explorer.service';
import { EmailRequestDto, EmailRequestNewDto } from 'src/app/api/email-request';
import { EmailService } from 'src/app/layout/service/product/email.service';
import { ToEmailService } from 'src/app/layout/service/product/to-email.service';
import { LogService } from 'src/app/layout/service/log.service';
import { EmailTemplateService } from 'src/app/layout/service/email/email-template.service';

@Component({
  selector: 'app-sca',
  templateUrl: './sca.component.html'
})
export class SCAComponent implements OnInit {
  productId: string = '';
  productName: string = '';
  productReference: string = '';

  isLoading = true;
  
  tier: string = '';
  selectedProduct: Product | null = null;

  productSDLCSCA: ProductSDLC[] = [];
  editedProductSDLC: ProductSDLCPutDto = {};
  productSCA: ProductSDLCRequestDto = {};

  filteredProductSDLCSCA: ProductSDLC[] = [];

  productSDLCTools: any[] = [];
  productSDLCRequestStatuses: any[] = [];
  productSDLCPracticeStatuses: any[] = [];

  // selectedOption: string | null = null;
  hasSelectedOption: boolean = false;
  selectedScaId: number | undefined;
  selectedScaOption: ProductSDLCPutDto = {};

  status: any[] = [];
  selectedStatus: string | null = null;

  submitted: boolean = false;
  scaDialog: boolean = false;

  scaSDLCPracticeId: number | undefined;

  messages: Message[] = [];
  messages2: Message[] = [];

  hasApprovedSCAMessage: boolean = false;

  
  userDialog: boolean = false;
  users: User[] = [];
  sdlcPrimaryContacts: SDLCPrimaryContact[] = [];

  selectedUser: User | null = null;

  // onOptionClick(option: string, splitter: any) {
  //   this.selectedOption = option;
  //   splitter.collapse();
  // }

  sdlcNewScaContacts: User[] = [];
  userDialogAction: string = '';

  showAccessDeniedDialog: boolean = false;

  constructor(private route: ActivatedRoute, private productSDLCService: ProductSDLCService, private messageService: MessageService,
    private productSelectionService: ProductSelectionService, private productService: ProductService, private graphExplorerService: GraphExplorerService,
    private confirmationService: ConfirmationService, private emailService: EmailService, private toEmailService: ToEmailService, private logService: LogService,
    private emailTemplateService: EmailTemplateService) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.productId = params['id'];
      
      this.productSelectionService.getSelectedProduct().subscribe((product) => {
        this.productName = product?.Name || '';
        this.productReference = product?.ProductRef || '';
      });

      this.fetchSCAData();

      if (!this.isLoading && !(this.tier === 'Tier 0' || this.tier === 'Tier 1' || this.tier === 'Tier 2') || this.tier == '') {
        this.showAccessDeniedDialog = true;
      };
    });

    this.productSDLCService.getAllSDLCTools("SCA").subscribe((sdlcTools) => {
      this.productSDLCTools = sdlcTools;
    });

    this.productSDLCService.getAllSDLCRequestStatuses().subscribe((sdlcRequestStatus) => {
      this.productSDLCRequestStatuses = sdlcRequestStatus;
    });

    this.productSDLCService.getSDLCPractice("SCA").subscribe((sdlcPractice) => {
      if(sdlcPractice.length > 0){
        this.scaSDLCPracticeId = sdlcPractice[0].Id;
      }

      this.productSDLCService.getMaturityLevelByPracticeId(this.scaSDLCPracticeId).subscribe((sdlcPracticeStatus) => {
        this.productSDLCPracticeStatuses = sdlcPracticeStatus;
        this.sortOptions();
      });
    });
    

    this.productService.getProductById(this.productId).subscribe((product) => {
      this.selectedProduct = product;
      this.tier = this.selectedProduct?.ProductMetric?.TierName || '';
      this.isLoading = false;
    });

    this.getAllUsers();

    this.messages = [{ severity: 'info', summary: 'Information', detail: 'Select SCA from the menu to view' }];
    this.messages2 = [{ severity: 'warn', summary: 'Warning', detail: 'Currently not following the SCA Practice! Please request or wait for approval.' }];

  }

  sortOptions() {
    this.productSDLCPracticeStatuses.sort((a, b) => a.Order - b.Order);
  }

  getAllUsers() {
    this.graphExplorerService.getAllADUsers().subscribe((users) => {
      this.users = users;
    });
  }
  
  searchUsers(event?: any) {
    if (event.filter) {
      this.graphExplorerService.searchADUsers(event.filter).subscribe((users) => {
        this.users = users;
      });
    } else {
      this.getAllUsers();
    }
  }

  getPracticeStatusNameById(id: string): string {
    const status = this.productSDLCPracticeStatuses.find(status => status.Id === id);
    return status ? status.Name : '';
  }

  getToolNameById(toolId: string): string {
    const selectedTool = this.productSDLCTools.find(tool => tool.Id === toolId);
    return selectedTool ? selectedTool.Name : '';
  }

  showScaDetails(selectedSca: ProductSDLC) {
    // Set the selected SCA data to be displayed in the second splitter form
    this.selectedScaOption = selectedSca;
    this.hasSelectedOption = true;
    this.selectedScaOption.EnrolDate = this.selectedScaOption.EnrolDate ? new Date(this.selectedScaOption.EnrolDate) : undefined;
    this.selectedScaOption.NextReviewDate = this.selectedScaOption.NextReviewDate ? new Date(this.selectedScaOption.NextReviewDate) : undefined;

    this.selectedScaId = selectedSca.Id;
    this.editedProductSDLC = {...this.selectedScaOption};

    this.fetchProductSDLCContacts();
  }

  fetchProductSDLCContacts() {
    if (this.selectedScaId) {
      this.productSDLCService.getSDLCPrimaryContacts(this.selectedScaId).subscribe((sdlcPrimaryContacts) => {
        this.sdlcPrimaryContacts = sdlcPrimaryContacts;
      });
    }
  }

  fetchSCAData(shouldReset: boolean = true, setSelectedStatus?: string | null) {
    this.productSDLCService.getProductSDLCSCA(this.productId).subscribe((sdlcSCA) => {
      if (sdlcSCA && sdlcSCA.value.length > 0){
        this.productSDLCSCA = sdlcSCA.value;
        this.filteredProductSDLCSCA = this.productSDLCSCA;

        const hasApprovedSCA = this.productSDLCSCA.some(sca => sca.SDLCRequestStatusName === 'Approved');

        if (setSelectedStatus) {
          this.selectedStatus = this.selectedStatus;
          this.onStatusChange(shouldReset);
        }

        if (hasApprovedSCA && !setSelectedStatus) {
          this.selectedStatus = 'Approved';
          this.hasApprovedSCAMessage = true;
          this.onStatusChange(shouldReset);
        } else {
          this.selectedStatus = 'Requested';
          this.onStatusChange(shouldReset);
        }

        this.showScaDetails(this.filteredProductSDLCSCA[0]);

      }
    });
    this.selectedStatus = 'Requested';
  }

  openNew() {
    this.productSCA = {};
    this.submitted = false;
    this.scaDialog = true;
  }

  hideDialog() {
    this.scaDialog = false;
    this.submitted = false;
    this.sdlcNewScaContacts = [];
  }


  openUserDialog(action: string) {
    this.userDialogAction = action;
    this.userDialog = true;
  }

  hideUserDialog() {
    this.userDialog = false;
    this.selectedUser = null;
  }

  saveNewSCA() {
    // if (this.submitted) {
    //   return;
    // }

    this.submitted = true;

    if (this.productSCA.SDLCToolId) {
      this.productSCA.ProductId = this.productId;
      this.productSCA.SDLCPracticeId = this.scaSDLCPracticeId;
      this.productSCA.EnrolDate = this.convertToUTC(this.productSCA.EnrolDate);
      this.productSCA.NextReviewDate = this.convertToUTC(this.productSCA.NextReviewDate);
      
      this.productSCA.SDLCRequestStatusId = this.productSDLCRequestStatuses.find(status => status.Name === 'Requested')?.Id;

      this.productSDLCService.createProductSDLC(this.productSCA).subscribe((newSca) => {
        const scaId = newSca.Id;
        this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Created SCA Request', life: 3000 });
        this.logService.sendInfoMessage('CREATE', 'product/' + this.productId + '/sdlc/sca/', this.productSCA);

        this.sdlcNewScaContacts.forEach((contact) => {
          const productUser = {
            UserGraphId: contact.id,
            Name: contact.displayName,
            Mail: contact.displayName,
            ProductSDLCId: scaId
          };

          if (productUser.UserGraphId && productUser.Name && productUser.Mail && scaId) {
            this.productSDLCService.createSDLCPrimaryContact(productUser).subscribe((response) => {
              this.messageService.add({ severity: 'success', summary: 'Successful', detail: `${productUser.Name} added`, life: 3000 });
              this.logService.sendInfoMessage('CREATE', 'product/' + this.productId + '/sdlc/sca/' + scaId + "/users", productUser);
            });
          }
        });

        const toEmails = this.toEmailService.getToEmails();

        // Define the list of CC recipients
        const ccEmails = this.sdlcNewScaContacts.map(contact => contact.mail);

        const emailContent = this.emailTemplateService.generateEmailContent('SCA', this.productName, this.productId, this.productReference, scaId, 'sdlc/sca');
        this.sendEmail(toEmails, ccEmails, `SCA Created - ${this.productName} (${this.productId})`, emailContent);   

        this.scaDialog = false;
        this.productSCA = {};
        this.sdlcNewScaContacts = [];
        this.selectedScaId = undefined;
        this.submitted = false;

        this.fetchSCAData(true, 'Requested');
      });
    }
    // this.submitted = false;
  }

  addNewScaContact() {
    if (this.selectedUser != null) {
      const userExists = this.sdlcNewScaContacts.some(contact => contact.id === this.selectedUser!.id);
      if (userExists) {
        this.messageService.add({ severity: 'warn', summary: 'Warning', detail: 'User already added as new contact', life: 3000 });
      } else {
        this.sdlcNewScaContacts.push(this.selectedUser);
        this.hideUserDialog();
      }
    }
    this.selectedUser = null;
  }

  removeNewScaContact(contact: User) {
    const index = this.sdlcNewScaContacts.indexOf(contact);
    if (index !== -1) {
        this.sdlcNewScaContacts.splice(index, 1);
    }
  }

  onStatusChange(shouldReset: boolean = true) {
    // Filter the productSDLCSCA based on selectedStatus
    if (shouldReset) {
      this.selectedScaId = undefined;
      this.selectedScaOption = {};
      this.editedProductSDLC = {};
      this.sdlcPrimaryContacts = [];
      this.hasSelectedOption = false;
    }

    if (this.selectedStatus) {
      this.filteredProductSDLCSCA = this.productSDLCSCA.filter(sca => sca.SDLCRequestStatusName === this.selectedStatus);
    } else {
      // If no status selected, reset the array to the original data
      this.filteredProductSDLCSCA = this.productSDLCSCA;
    }
  }
  
  onSubmit() {
    if (this.submitted) {
      return;
    }

    this.submitted = true;

    if (this.selectedScaOption && this.selectedScaId) {
      this.editedProductSDLC.EnrolDate = this.convertToUTC(this.editedProductSDLC.EnrolDate);
      this.editedProductSDLC.NextReviewDate = this.convertToUTC(this.editedProductSDLC.NextReviewDate);
      this.productSDLCService.updateProductSDLC(this.selectedScaId, this.editedProductSDLC).subscribe((response) => {
        this.messageService.add({ severity: 'success', summary: 'SCA updated successfully', });
        this.logService.sendInfoMessage('UPDATE', 'product/' + this.productId + '/sdlc/sca/', this.editedProductSDLC, this.selectedScaOption);
        this.submitted = false;
        this.fetchSCAData(false);

      }, (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error! Possibly no changes made to the form. Please try again.', });
        this.submitted = false;
        console.error('Error updating SDLC', error);
      });
    } else {
      this.messageService.add({ severity: 'error', summary: 'Select a practice from the left menu', });
      this.submitted = false;
    }
  }

  getToolImageUrl(toolName: string | undefined): string {
    switch (toolName) {
      case 'Snyk':
        return 'assets/layout/images/Snyk-logo.png';
      // Add cases for other tools as needed
      default:
        return 'assets/layout/images/default-tool-logo.png'; // Fallback image
    }
  }

  addPrimaryContact() {
    if (this.selectedUser && this.productId) {
      const userExists = this.sdlcPrimaryContacts.some(contact => contact.UserGraphId === this.selectedUser!.id);
      if (userExists) {
        this.messageService.add({ severity: 'warn', summary: 'Warning', detail: 'User already added', life: 3000 });
      } else {
        const productUser = {
          UserGraphId: this.selectedUser.id,
          Name: this.selectedUser.displayName,
          Mail: this.selectedUser.mail,
          ProductSDLCId: this.selectedScaId
        };
  
        if (productUser.UserGraphId && productUser.Name && productUser.Mail && productUser.ProductSDLCId && this.selectedScaId) {
          this.productSDLCService.createSDLCPrimaryContact(productUser).subscribe((response) => {
            this.messageService.add({ severity: 'success', summary: 'Successful', detail: `${productUser.Name} added`, life: 3000 });
            this.logService.sendInfoMessage('CREATE', 'product/' + this.productId + '/sdlc/sca/' + this.selectedScaId + "/users", productUser);
            this.fetchProductSDLCContacts();
            this.hideUserDialog();
          });
        }
      }
    }
  }
  

  removeUser(contact: SDLCPrimaryContact) {
    this.confirmationService.confirm({
        message: 'Are you sure you want to remove this user?',
        accept: () => {
            // User confirmed, perform deletion
            const index = this.sdlcPrimaryContacts.indexOf(contact);
            if (index !== -1 && contact.Id) {
                this.sdlcPrimaryContacts.splice(index, 1);
                // Perform the actual deletion from your backend here if necessary
                this.productSDLCService.deleteSDLCPrimaryContact(contact.Id).subscribe((response) => {
                  this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'User removed', life: 3000 });
                  this.logService.sendInfoMessage('DELETE', 'product/' + this.productId + '/sdlc/sca/' + this.selectedScaId + "/users/" + contact.Id, null, contact);
                });
            }
        }
    });
 }


 deleteSca(sca: ProductSDLC) {
  this.confirmationService.confirm({
      message: 'Are you sure you want to delete this SCA?',
      accept: () => {
          // User confirmed, perform deletion
          if (sca.Id) {
              this.productSDLCService.deleteProductSDLC(sca.Id).subscribe((response) => {
                  this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'SCA deleted', life: 3000 });
                  this.logService.sendInfoMessage('DELETE', 'product/' + this.productId + '/sdlc/sca/' + sca.Id, null, sca);
                  this.fetchSCAData(false, this.selectedStatus);
              });
          }
      }
  });
}

 // Emails

 sendEmail(toEmails: string[], ccEmails: string[], subject: string, htmlContent: string) {
  const emailRequest: EmailRequestNewDto = {
      toEmails: toEmails,
      ccEmails: ccEmails,
      subject: subject,
      htmlContent: htmlContent,
      plainTextContent: '' // Assuming no plain text content in this case
  };

  this.emailService.sendEmailNew(emailRequest).subscribe(response => {
      console.log('Email sent successfully', response);
  }, error => {
      console.error('Error sending email', error);
  });
}

  convertToUTC(date: Date | undefined): Date | undefined {
    if (date) {
      return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
    }
    return undefined;
  }
}
