import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Suite, Product } from 'src/app/api/product';
import { ProductService } from 'src/app/layout/service/product/products.service';
import { ConfirmationService, Message, MessageService } from 'primeng/api';
import { Table } from 'primeng/table';
import { Router } from '@angular/router';
import { FavouritesService } from 'src/app/layout/service/favourite.service';

@Component({
  selector: 'app-suite-dashboard',
  templateUrl: './suite-dashboard.component.html',
  styleUrls: ['./suite-dashboard.component.scss']
})

export class SuiteDashboardComponent implements OnInit {
  suiteId: string = '';
  suite: Suite = {}
  cols: any[] = [];
  products: Product[] = [];
  @ViewChild('dt1') table!: Table;

  isProductsLoading: boolean = true;
  isLoading: boolean = true;
  widgetData: any = {};

  favourites: any[] = [];

  constructor(private route: ActivatedRoute,
    private productService: ProductService, private favouritesService: FavouritesService,
    private confirmationService: ConfirmationService, private router: Router) {}

  ngOnInit() {

    this.route.params.subscribe(params => {
      this.suiteId = params['suiteId'];

      this.resetState();

      this.cols = [
        { field: 'Id', header: 'ID', minWidth: 'min-width: 6rem', type: 'text' },
        { field: 'Name', header: 'Name', minWidth: 'min-width: 12rem', type: 'text' },
        { field: 'ProductRef', header: 'ProductRef', minWidth: 'min-width: 12rem', type: 'text' },
        { field: 'DivisionName', header: 'Division', minWidth: 'min-width: 12rem', type: 'text' },
        { field: 'ProductTypeName', header: 'Product Type', minWidth: 'min-width: 12rem', type: 'text' },
        { field: 'ActiveStatus', header: 'Active Status', minWidth: 'min-width: 12rem', type: 'boolean' }
      ]

      this.getSuiteById();
      this.widgetData = {
        objectId: this.suiteId,
        productType: "Suite"
      };

      this.isLoading = false;

      this.loadFavourites();
    });
  }

  onGlobalFilter(table: Table, event: Event) {
    table.filterGlobal((event.target as HTMLInputElement).value, 'contains');
  }

  clear(table: Table) {
    table.clear();
    this.table.filterGlobal('', 'contains');
  }

  getSuiteById() {
    this.productService.getSuiteById(this.suiteId).subscribe((suite) => {
      this.suite = suite;
      this.products = suite?.Products ?? [];
      this.isProductsLoading = false;
    });
  }

  tagify(option: string | undefined): string {
    if (!option) {
      return '';
    }
    let tagValue = option.trim();
    tagValue = tagValue.replace(/\s+/g, '_');
    tagValue = tagValue.replace(/[^a-zA-Z0-9_]/g, '');
    return tagValue;
  }

  resetState() {
    this.isLoading = true;
    this.widgetData = [];
    this.suite = {};
    this.products = [];

  }

  deleteSuite() {
    this.confirmationService.confirm({
      message: `Are you sure you want to delete this suite? (Id: ${this.suite.Id})`,
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      acceptButtonStyleClass: "p-button-danger p-button-text",
      rejectButtonStyleClass: "p-button-text p-button-text",
      acceptIcon: "none",
      rejectIcon: "none",
      accept: () => {
        // User confirmed, perform deletion
        if (this.suite.Id) {
          this.productService.deleteSuite(this.suite.Id).subscribe((response: any) => {
            this.router.navigate(['/home/suites']);
          });
        }
      }
    });
  }

  loadFavourites() {
    this.favourites = this.favouritesService.getSuiteFavourites();
  }

  isFavourite(suiteId: any): boolean {
    return this.favouritesService.isFavourite(this.favourites, suiteId);
  }

  toggleFavourite(suiteId: any) {
    this.favourites = this.favouritesService.toggleFavourite(this.favourites, suiteId);
    this.favouritesService.saveSuiteFavourites(this.favourites);
  }
}
