import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable(
  {
    providedIn: 'root'
  }
)

export class QuicksightService {
    baseApiUrl: string = environment.baseApiUrl;
    constructor(private http: HttpClient) { }

    // SDLC Practice Maturity
    getQuicksightEmbedUrl(): Observable<any> {
        return this.http.get<any>(`${this.baseApiUrl}/api/Reports/quicksight`);
    }
}