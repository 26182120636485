<div class="flex gap-2 align-items-center">
    <h1>Threat Modelling</h1>
    <a href="https://irissoftware.sharepoint.com/sites/EngineeringIntranet/SitePages/Threat-Modelling---An-Overview.aspx" target="_blank">
        <p-button icon="pi pi-question"></p-button>
    </a>
</div>


<p-toast></p-toast>

<div *ngIf="!hasProductSDLC">
    <p-messages [(value)]="messages" [enableService]="false" [closable]="false"></p-messages>
</div>

<button *ngIf="!hasProductSDLC" pButton pRipple label="New Request" icon="pi pi-plus" class="p-button-success" (click)="openNew()" [disabled]="hasProductSDLC"></button>

<div class="col-12">
    <div class="card">
        <h5>Threat Modelling Form</h5>
        <div class="p-fluid p-formgrid grid">
            <div class="field col-12 md:col-12">
                <p-confirmDialog></p-confirmDialog>
                <label for="primaryContact">Primary Contacts</label>
                <div>
                    <ng-container *ngFor="let contact of sdlcPrimaryContacts">
                        <p-chip styleClass="m-1">
                            <img src="assets/layout/images/iris-circle-logo.png" alt="" width="60px" height="" class="shadow-2 border-circle">
                            <span pTooltip={{contact.Mail}} tooltipPosition="bottom" [hideDelay]="2000">{{ contact.Name }}</span>
                            <button pButton icon="pi pi-times" class="p-button-rounded p-button-danger p-button-text" (click)="removeUser(contact)"></button>
                        </p-chip>
                    </ng-container>
                    <button *ngIf="hasProductSDLC" pButton pRipple icon="pi pi-plus" class="p-button-success p-button-rounded" (click)="openUserDialog('addTMContact')"></button>
                </div>
            </div>
            

            <div class="field col-12 md:col-4">
                <label for="enrolDate">Enrol Date</label>
                <p-calendar appendTo="body" [(ngModel)]="editedProductSDLC.EnrolDate" [showIcon]="true" id="eolDate" dateFormat="dd/mm/yy"></p-calendar>
            </div>
            <div class="field col-12 md:col-4">
                <label for="nextReviewDate">Next Review Date</label>
                <p-calendar appendTo="body" [(ngModel)]="editedProductSDLC.NextReviewDate" [showIcon]="true" id="nextReviewDate" dateFormat="dd/mm/yy"></p-calendar>
            </div>


            <div class="field col-12 md:col-6">
                <label for="tmTool">Tool</label>
                <p-dropdown appendTo="body" [(ngModel)]="editedProductSDLC.SDLCToolId" inputId="SDLCToolId" [options]="productSDLCTools" placeholder="Select" optionValue="Id">
                    <ng-template pTemplate="selectedItem">
                        <span *ngIf="editedProductSDLC.SDLCToolId">{{ getToolNameById(editedProductSDLC.SDLCToolId) }}</span>
                    </ng-template>
                    <ng-template let-option pTemplate="item">
                        <span>{{option.Name}}</span>
                    </ng-template>
                </p-dropdown>
            </div>
            
            <div class="field col-12 md:col-6">
                <label for="tmMaturity">Maturity</label>
                <!-- <p-dropdown optionLabel="tmMaturity"></p-dropdown> -->
                <p-dropdown appendTo="body" [(ngModel)]="editedProductSDLC.SDLCTMStatusId" inputId="SDLCToolId" [options]="productSDLCTMStatuses" placeholder="Select" optionValue="Id">
                    <ng-template pTemplate="selectedItem">
                        <span *ngIf="editedProductSDLC.SDLCTMStatusId">{{ getTMStatusNameById(editedProductSDLC.SDLCTMStatusId) }}</span>
                    </ng-template>
                    <ng-template let-option pTemplate="item">
                        <span>{{option.Name}}</span>
                    </ng-template>
                </p-dropdown>
            </div>


            <div class="field col-12 md:col-12">
                <label for="comment">Comment</label>
                <div><textarea [(ngModel)]="editedProductSDLC.Comment" pInputTextarea style="width: 100%" name="" id="" cols="30" rows="5"></textarea></div>
            </div>
        </div>
        <div style="display: flex; justify-content: flex-end;">
            <p-button *ngIf="hasProductSDLC" label="Submit" (click)="onSubmit()"></p-button>
        </div>
    </div>
</div>


<p-dialog [(visible)]="userDialog" [modal]="true" [style]="{width: '750px'}" header="Add new user" [contentStyle]="{'overflow': 'visible'}" [modal]="true" class="p-fluid">
    <ng-template pTemplate="content">
        <div class="field">
            <p-dropdown [options]="users" scrollHeight="300px" [(ngModel)]="selectedUser" [style]="{'width':'100%'}" optionLabel="displayName" [filter]="true" (onFilter)="searchUsers($event)" [showClear]="true" placeholder="Select a User">
                <ng-template pTemplate="selectedItem">
                    <div class="flex align-items-center gap-2" *ngIf="selectedUser">
                        <div>{{ selectedUser.displayName }}</div>
                    </div>
                </ng-template>
                <ng-template let-user pTemplate="item">
                    <div class="flex align-items-center gap-2">
                        <img src="assets/layout/images/iris-circle-logo.png" alt="" width="60px" height="" class="shadow-2 border-circle">
                        <div class="flex w-full gap-2 flex-column p-4">
                            <div>{{ user.displayName }}</div>
                            <div>({{ user.mail }})</div>
                        </div>
                    </div>
                </ng-template>
            </p-dropdown>
        </div>
    </ng-template>

    <ng-template pTemplate="footer">
        <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="hideDialog()"></button>
        <button *ngIf="userDialogAction === 'addTMContact'" pButton pRipple label="Add" icon="pi pi-check" class="p-button-text" (click)="addPrimaryContact()"></button>
        <button *ngIf="userDialogAction === 'addNewTMContact'" pButton pRipple label="Add" icon="pi pi-check" class="p-button-text" (click)="addNewTMContact()"></button>
    </ng-template>
</p-dialog>



<p-dialog [(visible)]="threatModellingDialog" [style]="{width: '750px'}" header="New Threat Modelling Form" [modal]="true" class="p-fluid">
    <ng-template pTemplate="content">

        <div class="field col-12 md:col-12">
            <p-confirmDialog></p-confirmDialog>
            <label for="primaryContact">Primary Contacts</label>
            <div>
                <ng-container *ngFor="let contact of tmNewContacts">
                    <p-chip styleClass="m-1">
                        <img src="assets/layout/images/iris-circle-logo.png" alt="" width="60px" height="" class="shadow-2 border-circle">
                        <span pTooltip={{contact.mail}} tooltipPosition="bottom" [hideDelay]="2000">{{ contact.displayName }}</span>
                        <button pButton icon="pi pi-times" class="p-button-rounded p-button-danger p-button-text" (click)="removeNewTMContact(contact)"></button>
                    </p-chip>
                </ng-container>
                <button pButton pRipple icon="pi pi-plus" class="p-button-success p-button-rounded" (click)="openUserDialog('addNewTMContact')"></button>
            </div>
        </div> 

        <div class="field col-12">
            <label for="enrolDate">Enrol Date</label>
            <p-calendar appendTo="body" [(ngModel)]="newProductSDLC.EnrolDate" [showIcon]="true" id="eolDate" dateFormat="dd/mm/yy"></p-calendar>
        </div>
        
        <div class="field col-12">
            <label for="nextReviewDate">Next Review Date</label>
            <p-calendar appendTo="body" [(ngModel)]="newProductSDLC.NextReviewDate" [showIcon]="true" id="eolDate" dateFormat="dd/mm/yy"></p-calendar>
        </div>

        <div class="field col-6">
            <label for="Tool">Tool</label>
            <p-dropdown appendTo="body" [(ngModel)]="newProductSDLC.SDLCToolId" inputId="SDLCToolId" 
                [options]="productSDLCTools" placeholder="Select" optionValue="Id" >
                <ng-template pTemplate="selectedItem">
                    <span *ngIf="newProductSDLC.SDLCToolId">{{ getToolNameById(newProductSDLC.SDLCToolId) }}</span>
                </ng-template>
                <ng-template let-option pTemplate="item">
                    <span>{{option.Name}}</span>
                </ng-template>
            </p-dropdown>
        </div>

        <div class="field col-6">
            <label for="SDLCTMStatus">Maturity</label>
            <p-dropdown appendTo="body" [(ngModel)]="newProductSDLC.SDLCTMStatusId" inputId="SDLCTMStatus" 
                [options]="productSDLCTMStatuses" placeholder="Select" optionValue="Id" >
                <ng-template pTemplate="selectedItem">
                    <span *ngIf="newProductSDLC.SDLCTMStatusId">{{ getTMStatusNameById(newProductSDLC.SDLCTMStatusId) }}</span>
                </ng-template>
                <ng-template let-option pTemplate="item">
                    <span>{{option.Name}}</span>
                </ng-template>
            </p-dropdown>
        </div>

        <div class="field col-12">
            <label for="comment">Comment</label>
            <div><textarea [(ngModel)]="newProductSDLC.Comment" pInputTextarea style="width: 100%" name="" id="" cols="30" rows="5"></textarea></div>
        </div>

    </ng-template>

    <ng-template pTemplate="footer">
        <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="hideDialog()"></button>
        <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text" (click)="saveNewThreatModelling()"></button>
    </ng-template>
</p-dialog>