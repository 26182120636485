import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Suite, SuiteRequest } from 'src/app/api/product';
import { ProductService } from 'src/app/layout/service/product/products.service';
import { Table } from 'primeng/table';
import { MessageService } from 'primeng/api';
import { CookieService } from 'ngx-cookie-service';
import { FavouritesService } from 'src/app/layout/service/favourite.service';

@Component({
  selector: 'app-suite-master-list',
  templateUrl: './suite-master-list.component.html'
})

export class SuiteMasterListComponent implements OnInit {

  suites: Suite[] = [];
  cols: any[] = [];
  @ViewChild('dt1') table!: Table;
  suiteDialog: boolean = false;
  suite: SuiteRequest = {};
  submitted: boolean = false;
  favourites: any[] = [];

  constructor(private productsService: ProductService, private messageService: MessageService, 
    private cdr: ChangeDetectorRef, private favouritesService: FavouritesService) { }

  ngOnInit(): void {

    this.cols = [
      { field: 'Id', header: 'ID', minWidth: 'min-width: 6rem', type: 'text' },
      { field: 'Name', header: 'Name', minWidth: 'min-width: 12rem', type: 'text' }
    ]

    this.productsService.getAllSuites().subscribe((suites) => {
      this.suites = suites.map(suite => ({ Id: suite.Id, Name: suite.Name }));
    });

    this.loadFavourites();
  }

  onGlobalFilter(table: Table, event: Event) {
    table.filterGlobal((event.target as HTMLInputElement).value, 'contains');
  }

  clear(table: Table) {
    table.clear();
    this.table.filterGlobal('', 'contains');
  }

  openNew() {
    this.suiteDialog = true;
    this.suite = {};
  }

  hideDialog() {
    this.suiteDialog = false;
    this.suite = {};
  }

  saveSuite() {

    this.submitted = true;
    if (this.suite.Name == null || this.suite.Name.trim() === '') {
      return;
    }

    const newSuite: SuiteRequest = {
      Name: this.suite.Name,
      SuiteRef: this.suite.SuiteRef
    };

    this.productsService.createSuite(newSuite).subscribe(
      (response: any) => {        

        const createdSuite: any = {
          Id: response.id,
          Name: response.name
        };

        this.suites.push(createdSuite);
        this.messageService.add({ severity: 'success', summary: 'Successful', detail: `Suite created`, life: 3000 });
      },
        (error) => {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Could not create suite', life: 3000 });
        });
      
    this.suite = {};
    this.submitted = false;
    this.suiteDialog = false;
    this.cdr.detectChanges();

  }

  loadFavourites() {
    this.favourites = this.favouritesService.getSuiteFavourites();
  }

  isFavourite(suite: any): boolean {
    return this.favouritesService.isFavourite(this.favourites, suite.Id);
  }

  toggleFavourite(suite: any) {
    this.favourites = this.favouritesService.toggleFavourite(this.favourites, suite.Id);
    this.favouritesService.saveSuiteFavourites(this.favourites);
  }
}
