<h1>Tool References</h1>

<p-toast></p-toast>

<p-messages [(value)]="messages" [enableService]="false" [closable]="true"></p-messages>

<div class="flex justify-content-end mb-2">
  <button pButton pRipple label="Add Tool Reference" icon="pi pi-plus" class="p-button-success mb-2" (click)="openNewRefDialog()"></button>
</div>

<div *ngIf="matchedProductToolRefs.length > 0; else noRefs">
  <p-table [value]="matchedProductToolRefs">
    <ng-template pTemplate="header">
      <tr>
        <th>Tool</th>
        <th>Reference</th>
        <th>Actions</th>
        <!-- Add more columns as per your data structure -->
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-productToolRef>
      <tr>
        <td>{{ productToolRef.SDLCToolName }}</td>
        <td>{{ productToolRef.Reference }}</td>
        <td>
          <button type="button" pbutton="" icon="pi pi-trash" class="p-element p-button-danger p-button p-component p-button-icon-only" ng-reflect-icon="pi pi-trash" title="Delete reference" (click)="deleteProductToolRef(productToolRef)">
            <span class="p-button-icon pi pi-trash" aria-hidden="true"></span>
          </button>
        </td> <!-- Add a delete button -->
      </tr>
    </ng-template>
  </p-table>
</div>
  <ng-template #noRefs>
    <div>No tool references have been added for this product yet</div>
  </ng-template>

  <p-dialog [(visible)]="newRefDialog" [modal]="true" [style]="{width: '750px'}" header="Add new Tool Reference" [contentStyle]="{'overflow': 'visible'}" [modal]="true" class="p-fluid">
    <ng-template pTemplate="content">
      <div class="field">
        <p-dropdown [options]="availableTools"
                    [(ngModel)]="selectedTool"
                    optionLabel="label"
                    optionValue="value"
                    [ngClass]="{'ng-invalid ng-dirty' : submitted && !selectedTool}"
                    placeholder="Select a tool">
        </p-dropdown>
        <small class="ng-dirty ng-invalid" *ngIf="submitted && !selectedTool">Tool is required.</small>
      </div>
      <p style="font-style:italic; padding:3px; margin-top:3px;">If there is more than one reference for this product in this tool, separate them with a comma.</p>
      <div class="field">
        <input type="text" pInputText placeholder="Reference" [(ngModel)]="newRef" [ngClass]="{'ng-invalid ng-dirty' : submitted && !newRef}">
        <small class="ng-dirty ng-invalid" *ngIf="submitted && !newRef">Reference is required.</small>
      </div>
    </ng-template>
    <ng-template pTemplate="footer">
      <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="hideDialog()"></button>
      <button pButton pRipple label="Add" icon="pi pi-check" class="p-button-text" (click)="addNewToolRef()"></button>
    </ng-template>

  </p-dialog>
