<p-progressSpinner class="progress-spinner" *ngIf="isLoading"></p-progressSpinner>

<div class="dashboard-header-wrapper">
  <div class="dashboard-header-title">
    <div class="flex justify-between items-center">
      <h1 class="flex align-items-center">
        <span class="cursor-pointer" (click)="showAliases()">{{ product.Name }}</span>
        <i class="pi cursor-pointer ml-3 text-2xl"
          [ngClass]="{'pi-star-fill text-yellow-500': isFavourite(productId), 'pi-star': !isFavourite(productId)}"
          (click)="toggleFavourite(productId)"></i>
      </h1>
    </div>    

    <div class="grid">
      <div class="col-12 lg:col-6">
        <p-toast></p-toast>
        <div class="flex flex-wrap gap-4">
          <h5>
            <strong>Product: </strong>[{{ productId }}] - {{ product.ProductRef }}
          </h5>
          <div class="flex gap-2">
            <ng-container *ngIf="product.SuiteName">
              <a [routerLink]="['/suite', product.SuiteId, 'dashboard']" target="_blank" class="no-decoration">
                <p-tag 
                  value="{{ product.SuiteName }}" 
                  [styleClass]="'cursor-pointer suite-badge suite-' + trimOption(product.SuiteName)"
                  pTooltip="Suite" 
                  tooltipPosition="top">
                </p-tag>
              </a>
            </ng-container>

            <!-- Show DivisionName tag if DivisionName exists -->
            <ng-container *ngIf="product.DivisionName">
              <p-tag value="{{ product.DivisionName }}" [styleClass]="'cursor-pointer division-badge division-' + trimOption(product.DivisionName)"
                     pTooltip="Division" tooltipPosition="top"></p-tag>
            </ng-container>

            <!-- Show ProductTypeName tag if ProductTypeName exists -->
            <ng-container *ngIf="product.ProductTypeName">
              <p-tag value="{{ product.ProductTypeName }}" [styleClass]="'cursor-pointer productType-badge productType-' + trimOption(product.ProductTypeName)"
                     pTooltip="Product Type" tooltipPosition="top"></p-tag>
            </ng-container>

            <ng-container *ngIf="product.ProductMetric?.TierName">
              <a [href]="'https://irissoftware.sharepoint.com/sites/EngineeringIntranet/SitePages/Secure-Software-Development-Lifecycle.aspx#product-tiers'" target="_blank">
                <p-tag value="{{ product.ProductMetric?.TierName }}" [styleClass]="'cursor-pointer tier-badge tier-' + tagify(product.ProductMetric?.TierName)"
                       pTooltip="Product Tier" tooltipPosition="top"></p-tag>
              </a>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="dashboard-header-logo">
    <ng-container *ngIf="logo">
      <img [src]="logo" alt="Product Logo">
    </ng-container>
  </div>
</div>

<div class="flex flex-wrap gap-0">
  <ng-container *ngFor="let distro of selectedDistributions">
    <p-tag value="{{ distro.DistributionName }}" [styleClass]="'cursor-pointer techdistribution-badge distro-' + tagify(distro.DistributionName)"
           pTooltip="{{ distro.DistributionName }}" tooltipPosition="top"></p-tag>
  </ng-container>
  <ng-container *ngFor="let provider of selectedProviders">
    <p-tag value="{{ provider.HostingProviderName }}" [styleClass]="'cursor-pointer hostingprovider-badge provider-' + tagify(provider.HostingProviderName)"
           pTooltip="{{ provider.HostingProviderName }}" tooltipPosition="top"></p-tag>
  </ng-container>
  <ng-container *ngFor="let platform of selectedPlatforms">
    <p-tag value="{{ platform.PlatformName }}" [styleClass]="'cursor-pointer techplatform-badge platform-' + tagify(platform.PlatformName)"
           pTooltip="{{ platform.PlatformName }}" tooltipPosition="top"></p-tag>
  </ng-container>
  <ng-container *ngFor="let stack of selectedTechStacks">
    <p-tag value="{{ stack.TechStackName }}" [styleClass]="'cursor-pointer techstack-badge stack-' + tagify(stack.TechStackName)"
           pTooltip="{{ stack.TechStackName }}" tooltipPosition="top"></p-tag>
  </ng-container>
  <ng-container *ngFor="let auth of selectedAuthenticationMechanisms">
    <p-tag value="{{ auth.AuthenticationMechanismName }}" [styleClass]="'cursor-pointer authMechanism-badge auth-' + tagify(auth.AuthenticationMechanismName)"
           pTooltip="{{ auth.AuthenticationMechanismName }}" tooltipPosition="top"></p-tag>
  </ng-container>

  <ng-container *ngFor="let db of selectedTechPortfolioDatabases">
    <p-tag value="{{ db.PortfolioDatabaseName }}" [styleClass]="'cursor-pointer techstack-badge stack-' + tagify(db.PortfolioDatabaseName)"
           pTooltip="{{ db.PortfolioDatabaseName }}" tooltipPosition="top"></p-tag>
  </ng-container>

  <ng-container *ngFor="let lang of selectedTechPortfolioApplicationLayer">
    <p-tag value="{{ lang.PortfolioProgrammingLanguageName }}" [styleClass]="'cursor-pointer techstack-badge stack-' + tagify(lang.PortfolioProgrammingLanguageName)"
           pTooltip="{{ lang.PortfolioProgrammingLanguageName }}" tooltipPosition="top"></p-tag>
  </ng-container>

  <ng-container *ngFor="let cl of selectedTechPortfolioClientLayer">
    <p-tag value="{{ cl.PortfolioUXUIName }}" [styleClass]="'cursor-pointer techstack-badge stack-' + tagify(cl.PortfolioUXUIName)"
           pTooltip="{{ cl.PortfolioUXUIName }}" tooltipPosition="top"></p-tag>
  </ng-container>
</div>

<p-dialog header="Product Aliases" [(visible)]="aliasDialogVisible" [modal]="true" [closable]="true" [style]="{width: '400px'}">
  <div class="p-fluid">
    <div class="p-field">
      <label for="alias">New Alias</label>
      <input id="alias" type="text" pInputText [(ngModel)]="newAlias" class="p-inputtext" placeholder="Enter alias">
    </div>
    <div class="p-field mt-2">
      <button pButton type="button" label="Add" icon="pi pi-plus" class="p-button-success" (click)="addAlias()"></button>
    </div>
  </div>

  <div class="alias-list mt-3">
    <ng-container *ngIf="aliases.length > 0; else noAliases">
      <div *ngFor="let alias of aliases" class="alias-item p-field">
        <span>{{ alias.Name }}</span>
        <button pButton type="button" icon="pi pi-times" class="p-button-rounded p-button-danger p-button-text" (click)="removeAlias(alias)"></button>
      </div>
    </ng-container>
    <ng-template #noAliases>
      <div class="p-text-center p-text-muted">
        No aliases added yet.
      </div>
    </ng-template>
  </div>
</p-dialog>


<div class="grid">
  <div class="col-12 md:col-8 mt-2">
    <div class="">{{ product.Description }}</div>
  </div>
</div>

<div class="grid-container">
  <snyk-vuln-widget [data]="widgetData"></snyk-vuln-widget>
  <snyk-vuln-trend-widget [data]="widgetData"></snyk-vuln-trend-widget>
  <sonar-metrics-widget [data]="widgetData"></sonar-metrics-widget>
  <sonar-metrics-trend-widget [data]="widgetData"></sonar-metrics-trend-widget>
  <snyk-licence-widget [data]="widgetData"></snyk-licence-widget>
  <snyk-licence-trend-widget [data]="widgetData"></snyk-licence-trend-widget>
  <sonar-issues-widget [data]="widgetData"></sonar-issues-widget>
  <was-issues-widget [data]="widgetData"></was-issues-widget>
</div>
