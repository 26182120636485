import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ToolExternalService } from 'src/app/layout/service/tool-external.service';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'snyk-vuln-widget',
  templateUrl: './snyk-vuln-widget.component.html'
})
export class SnykVulnWidgetComponent implements OnInit, OnChanges {

  @Input() data: any;
  pieData: any;
  pieOptions: any;
  snykReportingLevel: string = '';
  snykVulnloading: boolean = true;
  snykLink: string = '';
  snykVulnerabilitiesAvailable: boolean = false;
  SnykVulnCollapsed: boolean = false;

  components: string[] = [];
  hasMultipleComponents: boolean = false;
  sourceData: any[] = [];
  selectedComponent: any;
  selectedSlugName: string = '';

  isInitialized: boolean = false;
  widgetContext: string = '';
  sourceType: string = '';
  sourceObjectName: string = '';

  prodNumber: number = 0;

  routingProductType: string = "";
  routingObjectId: string = "";
  constructor(private toolExternalService: ToolExternalService, private router: Router ) { }

  ngOnInit(): void {
    this.widgetContext = this.data.objectId;
    this.initialiseWidgetOnce();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data']) {
      if (this.widgetContext !== this.data.objectId) {
        this.isInitialized = false;
        this.widgetContext = this.data.objectId;
      }
      this.resetWidget();
      this.initialiseWidgetOnce();
    }
  }

  private initialiseWidgetOnce(): void {
    if (!this.isInitialized) {
      this.initialiseWidget();
      this.isInitialized = true;
    }
  }

  initialiseWidget() {
    this.resetWidget();
    this.pieOptions = {
      plugins: {
        legend: {
          display: false
        }
      }
    }

    //convert this.productId to a number
    const prodNumber = parseInt(this.data.objectId);
    const productType = this.data?.productType ?? null;

    this.prodNumber = prodNumber;

    this.toolExternalService.getSnykVulnerabilityData(prodNumber, productType).pipe(
      catchError((error) => {
        if (error.status === 404) {
          this.snykReportingLevel = "No Snyk data available";
          this.pieData = {
            labels: [],
            datasets: []
          };
        } else {
          console.error('An error occurred:', error);
          this.snykReportingLevel = "Unexpected error occured loading Snyk data";
          this.pieData = {
            labels: [],
            datasets: []
          };
        }
        this.snykVulnloading = false;
        return of(null);
      })
    ).subscribe((snykData) => {
      if (!snykData) {
        this.snykVulnloading = false;
        return;
      }

      this.routingProductType = snykData.productType;
      this.routingObjectId = snykData.objectId;

      this.sourceType = snykData.productType;
      this.sourceObjectName = snykData.objectName;
      this.sourceData = snykData.data;
      this.components = [];
      for (const dataItem of snykData.data) {
        this.updateComponentDropdown(dataItem.slug ?? "??");
      }
      this.selectedComponent = this.sourceData[0];
      this.selectedSlugName = this.selectedComponent.slug;
      this.hasMultipleComponents = this.components.length > 1;

      this.populateData();

      this.snykVulnloading = false;
    });
  }

  toggleSnykVulnCollapse() {
    this.SnykVulnCollapsed = !this.SnykVulnCollapsed;
  }

  getSeverityClass(label: string, baseClass: string): string {
    //remove spaces from Label
    label = label.replace(/\s/g, '');
    //Make label lower case
    label = label.toLowerCase();
    const severityClass = 'pieWidget' + label;
    return `${baseClass} ${severityClass}`;
  }

  onDropdownChange(event: any) {
    const selectedValue = event.value;
    this.selectedSlugName = selectedValue;

    for (const component of this.sourceData) {
      if (component.slug === selectedValue) {
        this.selectedComponent = component;
        break;
      }
    }

    this.populateData();
  }

  getQueryParams(sev: string) {
    const params = {
      toolName: 'Snyk',
      suites: '',
      vulnerabilityTypes: 'Vulnerability',
      products: '',
      severities: this.getRankedSeverity(sev)

    };

    if (this.routingProductType === 'Suite') {
      params['suites'] = this.routingObjectId;
    } else {
      params['products'] = this.routingObjectId;
    }

    return params;
  }

  getRankedSeverity(sev: string): number {

    if (sev === 'Critical') {
      return 0;
    } else if (sev === 'High') {
      return 1;
    } else if (sev === 'Medium') {
      return 2;
    } else {
      return 3;
    }
  }



  private generateIssueLink(severity: number): string {
    // create a url from the root of this site with /vulnerabilities/search
    // and a query string of severity and issueType


    if (this.routingProductType == "Suite") {
      return window.location.origin + `/vulnerabilities/search?vulnerabilityTypes=Vulnerability&severities=${severity}&suites=${this.routingObjectId}&toolName=Snyk`;
    } else {
      return window.location.origin + `/vulnerabilities/search?vulnerabilityTypes=Vulnerability&severities=${severity}&products=${this.routingObjectId}&toolName=Snyk`;
    }
  }

  populateData() {

    if (this.selectedComponent.slug !== "") {
      this.snykLink = "https://app.snyk.io/org/" + this.selectedComponent.slug + "/reporting?v=1&context[page]=issues-detail&issue_status=%255B%2522Open%2522%255D&issue_by=Severity&table_issues_detail_cols=SEVERITY%257CSCORE%257CCVE%257CCWE%257CPROJECT%257CEXPLOIT%2520MATURITY%257CAUTO%2520FIXABLE%257CINTRODUCED%257CSNYK%2520PRODUCT&issue_type=%255B%2522Vulnerability%2522%255D";
    } else {
      this.snykLink = "https://app.snyk.io";
    }

    // Assigning values to variables
    let critical = this.selectedComponent.severities.critical;
    let high = this.selectedComponent.severities.high;
    let medium = this.selectedComponent.severities.medium;
    let low = this.selectedComponent.severities.low;

    const documentStyle = getComputedStyle(document.documentElement);

    this.pieData = {
      labels: ['Critical', 'High', 'Medium', 'Low'],
      datasets: [
        {
          data: [critical, high, medium, low],
          backgroundColor: [
            documentStyle.getPropertyValue('--critical'),
            documentStyle.getPropertyValue('--high'),
            documentStyle.getPropertyValue('--medium'),
            documentStyle.getPropertyValue('--low'),
          ],
          hoverBackgroundColor: [
            documentStyle.getPropertyValue('--critical-hover'),
            documentStyle.getPropertyValue('--high-hover'),
            documentStyle.getPropertyValue('--medium-hover'),
            documentStyle.getPropertyValue('--low-hover'),
          ],
        },
      ],
    };

    this.pieOptions = {
      plugins: {
        tooltip: {
          enabled: true
        }
      },
      onHover: (event: any, chartElement: any) => {
        event.native.target.style.cursor = chartElement[0] ? 'pointer' : 'default';
      },
      onClick: (event: MouseEvent, elements: any[]) => {
        if (elements.length > 0) {
          // Get the first clicked element
          const element = elements[0];
          const dataIndex = element.index;

          // Generate the URL based on the severity level and issue type
          const url = this.generateIssueLink(dataIndex);

          // Open the link in a new tab
          window.open(url, '_blank');

        }
      }
    };


    const currentUrl = this.router.url;
    
    if (critical !== 0 || high !== 0 || medium !== 0 || low !== 0) {
      this.snykVulnerabilitiesAvailable = true;
      if (this.sourceType === 'Product') {
        this.snykReportingLevel = "";  
      } else if (this.sourceType === 'Suite' && currentUrl.includes('/suite/')) {
        this.snykReportingLevel = "Snyk data not available for this suite";
        this.snykVulnerabilitiesAvailable = false;
        return;
      } else {
        this.snykReportingLevel = "Snyk data not available at this product level. Showing data for parent suite: " + this.sourceObjectName;
      }
    } else {
      this.snykReportingLevel = "Good news! There are currently no Snyk vulnerabilities";
      this.snykVulnerabilitiesAvailable = false;
    }

  }

  updateComponentDropdown(newComponent: string) {
    if (!this.components.includes(newComponent)) {
      this.components.push(newComponent);
    }
  }

  resetWidget() {
    this.snykVulnloading = true;
    this.snykReportingLevel = '';
    this.snykLink = '';
    this.snykVulnerabilitiesAvailable = false;
    this.SnykVulnCollapsed = false;
    this.components = [];
    this.hasMultipleComponents = false;
    this.sourceData = [];
    this.selectedComponent = {};
    this.selectedSlugName = '';
  }
}
