<div class="widgetCard" [class.collapsed]="isCollapsed">
  <div class="widgetCardHeader" (click)="toggleWidgetCollapse()">
    <h5>Current Licence Concerns</h5>
    <div class="chevron">
      <i [class]="isCollapsed ? 'chevron-down' : 'chevron-up'"></i>
    </div>
  </div>
  <ng-container *ngIf="!isCollapsed">
    <ng-container *ngIf="snykLicenceConcernsloading; else licenceContent">
      <p-skeleton width="10%" height="1rem" margin="5px"></p-skeleton>
    </ng-container>
    <ng-template #licenceContent>
      <span class="reportingLevelLabel">{{ snykLicenceReportingLevel }}</span>
      <div class="reportingLevelLabel" *ngIf="hasMultipleComponents">
        <p-dropdown appendTo="body" [(ngModel)]="selectedSlugName" [options]="components" placeholder="Select" class="uniform-width-input" [style]="{'minWidth':'40%', 'maxWidth': '400px'}" (onChange)="onDropdownChange($event)">
          <ng-template pTemplate="selectedItem">
            <span *ngIf="selectedSlugName">{{ selectedSlugName }}</span>
          </ng-template>
          <ng-template let-option pTemplate="item">
            <span>{{option}}</span>
          </ng-template>
        </p-dropdown>
      </div>
      <div class="pieWidgetContainer" *ngIf="snykLicenceConcernsAvailable">
        <div class="pieWidgetLeft">
          <p-chart type="doughnut" width="250px" height="250px" [data]="pieLicenceData" [options]="pieOptions"></p-chart>
        </div>
        <div class="pieWidgetRight">
          <p-table [value]="pieLicenceData.datasets[0].data" responsiveLayout="scroll">
            <ng-template pTemplate="header">
              <tr>
                <th class="pieWidgetCell pieWidgetLicenceCell pieWidgetHeader">Concern Type</th>
                <th class="pieWidgetCell pieWidgetHeader">Count</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-data let-i="rowIndex">
              <tr>
                <td [ngClass]="getSeverityClass(pieLicenceData.labels[i], 'pieWidgetCell pieWidgetLicenceCell')">
                  {{ pieLicenceData.labels[i] }}
                </td>
                <td class="pieWidgetCell pieWidgetCellValue">{{ data }}</td>
              </tr>
            </ng-template>
          </p-table>
          <span class="snykLink"><a href="{{ snykLicenceLink}}" target="_blank">View Licence Concerns in Snyk</a></span>
        </div>
      </div>
    </ng-template>
  </ng-container>
</div>
