<h1>
  SDLC Dashboard
  <a href="https://irissoftware.sharepoint.com/sites/EngineeringIntranet/SitePages/IRIS-Lens--SDLC-Maturity-Report.aspx" target="_blank">
    <p-button icon="pi pi-question"></p-button>
  </a>
</h1>

<p-toast></p-toast>

<div *ngIf="isLoading">
  <p-skeleton width="10%" height="1rem"></p-skeleton>
</div>

<p-messages [(value)]="messages" [enableService]="false" [closable]="true"></p-messages>

  <div *ngIf="!isLoading">

    <ng-container>
      <a [href]="'https://irissoftware.sharepoint.com/sites/EngineeringIntranet/SitePages/Secure-Software-Development-Lifecycle.aspx#product-tiers'" target="_blank">
        <p-tag value="{{ tierName }}" [styleClass]="'cursor-pointer tier-badge tier-' + tagify(tierName)"
               pTooltip="Product Tier" tooltipPosition="top"></p-tag>
      </a>
    </ng-container>

    <h3>Current SDLC Maturity</h3>
    <p-progressBar [value]="maturityPct | number: '1.0-0'"></p-progressBar>

    <h3>Detail</h3>

    <div class="grid-container">
      <div class="grid-item" *ngFor="let practice of reportedPractices">
        <div class="sdlc-item">
          <h4 class="sdlc-item-title">
            {{ practice.sdlcPracticeName }}
          </h4>
          <div class="productdetails-attribute">
            <div class="productdetails-label">Tool:</div>
            <div class="productdetails-value">{{ practice.sdlcToolName }}</div>
          </div>
          <div class="productdetails-attribute">
            <div class="productdetails-label">Expected Maturity:</div>
            <div class="productdetails-value">{{ practice.expectedMaturity }}</div>
          </div>
          <div class="productdetails-attribute">
            <div class="productdetails-label">Actual Maturity:</div>
            <div class="productdetails-value">{{ practice.actualMaturity }}</div>
          </div>
          <div class="productdetails-attribute">
            <div class="productdetails-label">SDLC Compliance:</div>
            <div class="productdetails-value">
              <span [ngClass]="
            practice.status === 'Achieving' ? 'status-badge achieved' :
            practice.status === 'Exempt' ? 'status-badge exempt' :
            'status-badge not-achieved'">
                {{ practice.status }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="grid-item" *ngFor="let practice of reportedPenTests">
        <div class="sdlc-item">
          <h4 class="sdlc-item-title">
            {{ practice.sdlcPracticeName }}
          </h4>
          <div class="productdetails-attribute">
            <div class="productdetails-label">Last Test Date:</div>
            <div class="productdetails-value">{{ cleanDate(practice.actualMaturity) }}</div>
          </div>
          <div class="productdetails-attribute">
            <div class="productdetails-label">Max. Wait in Days:</div>
            <div class="productdetails-value">{{ practice.minimumMaturityDays }}</div>
          </div>
          <div class="productdetails-attribute">
            <div class="productdetails-label">Next Due Date:</div>
            <div class="productdetails-value">{{ cleanDate(practice.expectedMaturity) }}</div>
          </div>
          <div class="productdetails-attribute" *ngIf="practice.status == 'In Progress'">
            <div class="productdetails-label">Current Test Status:</div>
            <div class="productdetails-value">{{ practice.progressName }}</div>
          </div>
          <div class="productdetails-attribute" *ngIf="practice.status == 'Not Achieving'">
            <div class="productdetails-label">Overdue By:</div>
            <div class="productdetails-value">{{ getOverdue(practice.expectedMaturity) }}</div>
          </div>
          <div class="productdetails-attribute">
            <div class="productdetails-label">SDLC Compliance:</div>
            <div class="productdetails-value">
              <span [ngClass]="
                  practice.status === 'Achieving' ? 'status-badge achieved' :
                  practice.status === 'In Progress' ? 'status-badge achieved' :
                  practice.status === 'Exempt' ? 'status-badge exempt' :
                  'status-badge not-achieved'">
                {{ practice.status }}
              </span>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
