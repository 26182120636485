import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Message, MessageService } from 'primeng/api';
import { ArchitectureService } from 'src/app/layout/service/architecture/architecture.service';
import { ProductService } from 'src/app/layout/service/product/products.service';
import { ArbDto } from "src/app/api/architecture";
import { catchError } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { Table } from 'primeng/table';

@Component({
  selector: 'app-arb-list',
  templateUrl: './arb-list.component.html'
})
export class ArbListComponent implements OnInit {
  hasARBs: boolean = false;
  arbs: ArbDto[] = [];
  messages: Message[] = [];
  isARBLoading: boolean = true;
  @ViewChild('dt1') table!: Table;
  cols: any[] = [];
  products: any[] = [];

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private messageService: MessageService,
    private architectureService: ArchitectureService,
    private productService: ProductService
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.isARBLoading = true;
      this.arbs = [];

      this.productService.getAllProducts().subscribe((prods)=> {
        this.products = prods;
      });          

      this.cols = [
      { field: 'code', header: 'Code', minWidth: 'min-width: 6rem', type: 'text' },
      { field: 'date', header: 'Date', minWidth: 'min-width: 12rem', type: 'text' },
      { field: 'title', header: 'Title', minWidth: 'min-width: 12rem', type: 'text' },
      { field: 'products', header: 'Products', minWidth: 'min-width: 12rem', type: 'text' },
      { field: 'currentStatus', header: 'Status', minWidth: 'min-width: 12rem', type: 'text' }

      ]

      this.architectureService.getAllARBs().pipe(
        catchError((error) => {
          if (error.status != 404) {
            console.log('Error fetching ARBs:', error);
          }
          this.hasARBs = false;
          this.isARBLoading = false;
          return of(null);
        })
      ).subscribe((historicArbs) => {
        this.hasARBs = true;
        this.arbs = historicArbs.sort((a: any, b: any) => {
          return new Date(b.date).getTime() - new Date(a.date).getTime();
        });
        this.table.filterGlobal('', 'contains');
        this.isARBLoading = false;
      });      

    });      
  }

    niceDate(date?: Date): string {
    if (date == null) {
      return "";
    }
    const options: Intl.DateTimeFormatOptions = {
      day: '2-digit',
      month: 'long',
      year: 'numeric'
    };
    return new Date(date).toLocaleString('en-GB', options);
  }

    onGlobalFilter(table: Table, event: Event) {
    table.filterGlobal((event.target as HTMLInputElement).value, 'contains');
  }

  findProduct(productCode: string): any[] {

    let prodNames: any[] = [];

    const productCodes = productCode.split(',');

    for (let code of productCodes) {

      let product = this.products.find(product => product.ProductRef === code.trim());
      if (product) {

        const newProd = {
          Name: product.Name,
          Type: "product",
          ID: product.Id
        }

        prodNames.push(newProd);
      } else {

        const newProd = {
          Name: code,
          Type: "unknown",
          ID: ""
        }

        prodNames.push(newProd);
      }
    }

    return prodNames;
  }

  clear(table: Table) {
    table.clear();
    this.table.filterGlobal('', 'contains');
  }

    tagify(option: string | undefined): string {
    if (!option) {
      return '';
    }
    let tagValue = option.trim();
    tagValue = tagValue.replace(/\s+/g, '_');
    tagValue = tagValue.replace(/[^a-zA-Z0-9_]/g, '');
    return tagValue;
  }
}
