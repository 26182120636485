<h1>Non-Functional Requirements
    <a href="https://irissoftware.sharepoint.com/sites/EngineeringIntranet/SitePages/Non-Functional-Requirements-Guide.aspx" target="_blank">
    <p-button icon="pi pi-question"></p-button>
  </a>
  </h1>

<div *ngFor="let category of sortedCategories">
  <h2 class="mt-3">{{ category.Name }}</h2>

  <div *ngFor="let nfr of getRequirementsForCategory(category.Id ?? 0)">
    <div [ngClass]="getNfrClass(nfr.Id ?? 0)" class="nfr-row">

          <!-- Add Button (Only if NFR has no value) -->

        <button *ngIf="!hasValue(nfr.Id ?? 0)" pButton pRipple icon="pi pi-plus" class="p-button-success p-button p-component p-button-icon-only p-element mb-2 small-green-btn" (click)="openAddModal(nfr.Id ?? 0)"></button>

    <!-- NFR Name with Tooltip -->
    <span class="nfr-name">
      {{ nfr.Name }}
      <span class="tooltip-icon" (mouseover)="showTooltip(nfr.Id ?? 0, $event)" (mouseout)="hideTooltip()">
        ?
      </span>
    </span>

    <!-- Tooltip -->
    <div *ngIf="tooltipNfrId === nfr.Id" class="tooltip" [style.top.px]="tooltipY" [style.left.px]="tooltipX">
      {{ nfr.Description }}
    </div>

    <!-- Centered Existing Value -->
    <span *ngIf="hasValue(nfr.Id ?? 0)" class="nfr-value">
      {{ getValue(nfr.Id ?? 0) }}
    </span>

    <!-- Edit & Delete Buttons (Only if NFR has a value) -->
    <div class="nfr-actions">
          <button *ngIf="hasValue(nfr.Id ?? 0)"  type="button" pbutton="" icon="pi pi-pencil" class="p-element p-button p-component p-button-icon-only mr-2" ng-reflect-icon="pi pi-pencil" title="Update Requirement" (click)="editNfr(nfr.Id ?? 0)">
            <span  class="p-button-icon pi pi-pencil" aria-hidden="true"></span>
          </button>
          <button *ngIf="hasValue(nfr.Id ?? 0)" type="button" pbutton="" icon="pi pi-trash" class="p-element p-button-danger p-button p-component p-button-icon-only" ng-reflect-icon="pi pi-trash" title="Delete Requirement" (click)="deleteNfr(nfr.Id ?? 0)">
            <span class="p-button-icon pi pi-trash" aria-hidden="true"></span>
          </button>
    </div>

    </div>
  </div>
</div>

<!-- Modal for Adding NFR Value -->

<p-dialog [(visible)]="modalOpen" [modal]="true" [style]="{width: '750px'}" header="Update a Non-Functional Requirement" [contentStyle]="{'overflow': 'visible'}" [modal]="true" class="p-fluid">
    <ng-template pTemplate="content">
  <h3>{{ newNFRName }}</h3>
    <p class="mb-5">{{ newNFRDescription }}</p>
   <div class="field">
      <input type="text" pInputText placeholder="NFR Value" [(ngModel)]="newNfrValue" [ngClass]="{'ng-invalid ng-dirty' : submitted && !newNfrValue}">
      <small class="ng-dirty ng-invalid" *ngIf="submitted && !newNfrValue">Value is required.</small>
   </div>
  </ng-template>
    <ng-template pTemplate="footer">
    <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="closeModal()"></button>
    <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text" (click)="saveNewValue()"></button>
  </ng-template>

