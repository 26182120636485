import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, Message, MessageService } from 'primeng/api';
import { Observable, switchMap } from 'rxjs';
import { EmailRequestDto, EmailRequestNewDto } from 'src/app/api/email-request';
import { User } from 'src/app/api/product';
import { PentestContact, PentestPreReqUserDto, ProductPentest, ProductPentestPutDto, ProductPentestRequestDto } from 'src/app/api/product-pentest';
import { PTTContactDto, ProductPTTDto, ProductPTTPutDto } from 'src/app/api/product-ptt';
import { GraphExplorerService } from 'src/app/layout/service/graphexplorer/graph-explorer.service';
import { LogService } from 'src/app/layout/service/log.service';
import { EmailService } from 'src/app/layout/service/product/email.service';
import { ProductPentestService } from 'src/app/layout/service/product/product-pentest.service';
import { ProductPTTService } from 'src/app/layout/service/product/product-ptt.service';
import { ProductSDLCService } from 'src/app/layout/service/product/product-sdlc.service';
import { ToEmailService } from 'src/app/layout/service/product/to-email.service';
import { ProductSelectionService } from 'src/app/product.selection.service';
import { environment } from 'src/environments/environment';
import { PentestSelectItem } from './pentest-select-item';

@Component({
  selector: 'app-pentest',
  templateUrl: './pentest.component.html',
  styleUrls: ['./pentest.component.scss']
})
export class PentestComponent implements OnInit {
  baseApiUrl: string = environment.baseApiUrl;

  productId: string = '';
  productReference: string | undefined;

  productPentests: ProductPentest[] = [];
  editedProductPentest: ProductPentestPutDto = {};
  productPentest: ProductPentestRequestDto = {};

  pentestRisks: any[] = [];
  pentestProgresses: any[] = [];
  pentestProviders: any[] = [];
  pentestPreReqx: any[] = [];
  pentestRequestedBy: any[] = [];

  pttCat: any[] = [];
  pttComplexity: any[] = [];
  pttPriority: any[] = [];
  pttProgress: any[] = [];
  pttSeverity: any[] = [];

  originalSelectedOption: ProductPentest = {};

  hasSelectedOption: boolean = false;
  selectedPentestId: number | undefined;
  selectedPentestOption: ProductPentestPutDto = {};

  submitted: boolean = false;
  pentestDialog: boolean = false;

  messages: Message[] = [];
  messages2: Message[] = [];

  productPentestById: ProductPentest = {};

  userDialog: boolean = false;
  users: User[] = [];
  pentestContacts: PentestContact[] = [];
  preReqxContacts: PentestPreReqUserDto[] = [];

  selectedUser: User | null = null;

  pentestNewContacts: User[] = [];
  preReqxNewContacts: User[] = [];
  userDialogAction: string = '';

  pentestDocuments: any[] = [];

  pttNewContacts: User[] = [];
  pttContacts: PTTContactDto[] = [];
  pttDialog: boolean = false;



  visibleProductPentests: ProductPentest[] = []; // Subset of items to display based on pagination
  currentPage: number = 1; // Current page
  pageSize: number = 5; // Number of items per page
  
  docTypes!: PentestSelectItem[];
  docStatuses!: PentestSelectItem[];

  selectedDocType: any; // Variable to hold the selected value
  selectedDocStatus: any; // Variable to hold the selected value


  pttContactDialog: boolean = false;
  pentestPTTDialog: boolean = false;

  pentestPTT: ProductPTTDto[] = [];

  productName: string | undefined;

  hasFindings: boolean = false;

  columns: any[] = [];

  constructor(private route: ActivatedRoute, private productPentestService: ProductPentestService, private messageService: MessageService,
    private productSDLCService: ProductSDLCService, private productPTTService: ProductPTTService, 
    private changeDetectorRef: ChangeDetectorRef, private graphExplorerService: GraphExplorerService,
    private confirmationService: ConfirmationService, private http: HttpClient, private router: Router, private emailService: EmailService,
    private productSelectionService: ProductSelectionService, private toEmailService: ToEmailService, private logService: LogService) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.productId = params['id'];

      this.productSelectionService.getSelectedProduct().subscribe(product => {
        if (product) {
          this.productName = product.Name;
          this.productReference = product.ProductRef;
          // console.log(this.productName);
        } else {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Pentest not found', life: 3000 });
        }
      });

      // Check if 'selectedPentestId' query parameter exists in the route
      this.route.queryParams.subscribe(params => {
        const selectedPentestId = this.route.snapshot.queryParams['id'];
        if (selectedPentestId) {
            // Call your method to select the pentest
            this.showPentestDetails(selectedPentestId);
        }
      });

      this.fetchPentestData();

      this.docTypes = [
        { label: 'Pentest Request', value: '24', sequence: 1},
        { label: 'Customer pentest', value: '6', sequence: 2 },
        { label: 'Vendor Proposal', value: '2', sequence: 3 },
        { label: 'Authorisation form', value: '8', sequence: 4 },
        { label: 'Purchase Order', value: '20', sequence: 5 },
        { label: 'Report', value: '3', sequence: 6 },
        { label: 'Report summary', value: '5', sequence: 7 },
        { label: 'Retest report', value: '4', sequence: 8 },
        { label: 'Suppport document', value: '9', sequence: 9 },
      ];

      this.docStatuses = [
        { label: 'In Progress', value: 'In Progress' },
        { label: 'Active', value: 'Active' },
        { label: 'Withdrawn', value: 'Withdrawn' },
        { label: 'Archived', value: 'Archived' }
      ];
    });

    this.productPentestService.getAllPentestRisks().subscribe(pentestRisk => {
      this.pentestRisks = pentestRisk;
    });

    this.productPentestService.getAllPentestProgress().subscribe(pentestProgress => {
      this.pentestProgresses = pentestProgress;
    });

    this.productPentestService.getAllPentestProviders().subscribe(pentestProvider => {
      this.pentestProviders = pentestProvider;
    });

    this.productPentestService.getAllPreReqxs().subscribe(pentestPreReqx => {
      this.pentestPreReqx = pentestPreReqx;
    });

    this.productPentestService.getAllPentestRequestedBy().subscribe(pentestRequestedBy => {
      this.pentestRequestedBy = pentestRequestedBy;
    });

    // Pentest PTT

    this.productPTTService.getAllPTTCat().subscribe(pttCat => {
      this.pttCat = pttCat;
    });

    this.productPTTService.getAllPTTComplexity().subscribe(pttComplexity => {
      this.pttComplexity = pttComplexity;
    });

    this.productPTTService.getAllPTTPriority().subscribe(pttPriority => {
      this.pttPriority = pttPriority;
    });

    this.productPTTService.getAllPTTProgress().subscribe(pttProgress => {
      this.pttProgress = pttProgress;
    });

    this.productPTTService.getAllPTTSeverity().subscribe(pttSeverity => {
      this.pttSeverity = pttSeverity;
    });


    this.getAllUsers();

    this.messages = [{ severity: 'info', summary: 'Information', detail: 'Select Pentest from the menu to view' }];

    this.columns = [
      { field: 'ProductPentestId', header: 'PentestId', filter: true, width: '12rem' },
      { field: 'SeverityName', header: 'Severity', filter: true, width: '10rem' },
      { field: 'CVSS', header: 'CVSS', filter: true, width: '8rem' },
      { field: 'PTTProgressName', header: 'Progress', filter: true, width: '12rem' },
      { field: 'Issue', header: 'Issue', filter: true, width: '20rem' },
      { field: 'Remediation', header: 'Remediation', filter: true, width: '15rem' },
      { field: 'PriorityName', header: 'Priority', filter: true, width: '10rem' },
      { field: 'FixDate', header: 'Fix Date (ETA)', filter: true, width: '12rem' },
      { field: 'FixDateNum', header: 'Fix Date (Num)', filter: true, width: '12rem' },
      { field: 'ComplexityName', header: 'Complexity', filter: true, width: '12rem' },
      { field: 'XRef', header: 'XRef', filter: true, width: '10rem' },
      { field: 'Ref', header: 'Ref', filter: true, width: '10rem' },
      { field: 'RefOld', header: 'RefOld', filter: true, width: '10rem' },
      { field: 'CatName', header: 'Cat', filter: true, width: '10rem' },
      { field: 'AssignedTo', header: 'Assigned to', filter: true, width: '15rem' },
      { field: 'Comment', header: 'Comment', filter: true, width: '20rem' },
      { field: 'TestDate', header: 'Test Date', filter: true, width: '12rem' },
      { field: 'NextAction', header: 'Next Action', filter: true, width: '15rem' },
      { field: 'Vector', header: 'Vector', filter: true, width: '15rem' },
      { field: 'Created', header: 'Created', filter: true, width: '12rem' }
    ];
  }

  getAllUsers() {
    this.graphExplorerService.getAllADUsers().subscribe(users => {
      this.users = users;
    });
  }

  searchUsers(event?: any) {
    if (event.filter) {
      this.graphExplorerService.searchADUsers(event.filter).subscribe((users) => {
        this.users = users;
      });
    } else {
      this.getAllUsers();
    }
  }

  getPentestRiskNameById(id: number): string {
    const pentestRisk = this.pentestRisks.find(x => x.Id === id);
    return pentestRisk?.Name || '';
  }

  getPentestProgressNameById(id: number): string {
    const pentestProgress = this.pentestProgresses.find(x => x.Id === id);
    return pentestProgress?.Name || '';
  }

  getPentestProgressNameByIdEmail(id: number | undefined): string {
    const pentestProgress = this.pentestProgresses.find(x => x.Id === id);
    return pentestProgress?.Name || '';
  }

  getPentestProviderNameById(id: number): string {
    const pentestProvider = this.pentestProviders.find(x => x.Id === id);
    return pentestProvider?.Name || '';
  }

  getPentestPreReqxNameById(id: number): string {
    const pentestPreReqx = this.pentestPreReqx.find(x => x.Id === id);
    return pentestPreReqx?.Name || '';
  }

  getPentestRequestedByNameById(id: number): string {
    const pentestRequestedBy = this.pentestRequestedBy.find(x => x.Id === id);
    return pentestRequestedBy?.Name || '';
  }

  showPentestDetails(selectedPentestId: number) {
    // Fetch the ProductPentest object using the provided ID
    this.productPentestService.getProductPentestById(selectedPentestId).subscribe((productPentest) => {
        const selectedPentest = productPentest.value[0]; // Assuming you receive an array of productPentest, adjust accordingly if needed
        
        this.selectedPentestOption = selectedPentest;

        this.selectedPentestId = selectedPentest.Id;

        this.selectedPentestOption.TestDate = this.selectedPentestOption.TestDate ? new Date(this.selectedPentestOption.TestDate) : undefined;
        this.selectedPentestOption.RetestDate = this.selectedPentestOption.RetestDate ? new Date(this.selectedPentestOption.RetestDate) : undefined;

        this.selectedPentestOption.RemediationsCompleted = this.selectedPentestOption.RemediationsCompleted ? new Date(this.selectedPentestOption.RemediationsCompleted) : undefined;

        this.hasSelectedOption = true;

        this.editedProductPentest = { ...this.selectedPentestOption};

        this.fetchPentestDataById();

        this.refreshPTT()

        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: { id: this.selectedPentestId },
            queryParamsHandling: 'merge' // to keep existing query params
        });
    });
  }


  fetchPentestData() {
    this.productPentestService.getProductPentest(this.productId).subscribe((productPentests) => {
      this.productPentests = productPentests.value;
      if (this.productPentests) {
        this.productPentests.sort((a, b) => (b.Id || 0) - (a.Id || 0));
      }
      this.updateVisibleItems();

      if (this.productPentests.length > 0) {
        this.showPentestDetails(this.productPentests[0].Id ?? 0);
      };   
    });


    if (this.selectedPentestId !== undefined) {
      this.fetchPentestDocuments();
      this.fetchPTTContacts();
    }
  }

  fetchPentestDocuments() {
    if (this.selectedPentestId !== undefined) {
      this.productPentestService.getProductPentestDocuments(this.productId, this.selectedPentestId.toString()).subscribe((pentestDocuments) => {
        this.pentestDocuments = pentestDocuments;
      });
    }
  }

  fetchPentestDataById() {
    if(this.selectedPentestId !== undefined)
    {
      this.productPentestService.getProductPentestById(this.selectedPentestId).subscribe((productPentest) => {
        this.productPentestById = productPentest.value[0];
        this.originalSelectedOption = this.productPentestById;

      });
      this.fetchPentestContacts(); //new
      this.fetchPreReqxContacts(); //new
      this.fetchPentestDocuments();
    }
  }

  fetchPentestContacts() {
    if (this.selectedPentestId) {
      this.productPentestService.getPentestContacts(this.selectedPentestId).subscribe((pentestContacts) => {
        this.pentestContacts = pentestContacts;
        // I need this.sdlcPrimaryContacts to only contain the 
      });
    }
  }

  fetchPreReqxContacts() {
    if (this.selectedPentestId) {
      this.productPentestService.getPreReqUserContacts(this.selectedPentestId).subscribe((preReqxContacts) => {
        this.preReqxContacts = preReqxContacts;
      });
    }
  }

  openNew() {
    this.editedProductPentest = {};
    this.submitted = false;
    this.pentestDialog = true;
  }

  hideDialog() {
    this.pentestDialog = false;
    this.submitted = false;

    this.pentestNewContacts = [];
    this.preReqxNewContacts = [];
  }

  openUserDialog(action: string) {
    this.userDialogAction = action;
    this.userDialog = true;
  }

  hideUserDialog() {
    this.userDialog = false;
    this.selectedUser = null;
  }

  saveNewPentest() {
    this.submitted = true;

    if (this.productPentest && this.productPentest.ProgressId && this.productPentest.TestDate && this.productPentest.RequestedById) {
      this.productPentest.ProductId = this.productId;
      this.productPentest.TestDate = this.convertToUTC(this.productPentest.TestDate);
      this.productPentest.RetestDate = this.convertToUTC(this.productPentest.RetestDate);

      const requestedById = this.productPentest.RequestedById;
      const requestedByName = this.pentestRequestedBy.find(item => item.Id === requestedById)?.Name;

      this.productPentestService.createProductPentest(this.productPentest).subscribe((createdPentest: any) => {
        const newEntityId = createdPentest.Id;

        this.pentestNewContacts.forEach((contact) => {
          const productUser = {
            UserGraphId: contact.id,
            Name: contact.displayName,
            Mail: contact.mail,
            ProductPentestId: newEntityId
          };

          if (productUser.UserGraphId && productUser.Name && productUser.Mail && newEntityId) {
            this.productPentestService.createPentestContact(productUser).subscribe(() => {
              this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Contact Added', life: 3000 });
              this.logService.sendInfoMessage('CREATE', 'product/' + this.productId + '/sdlc/pentests/' + newEntityId + "/users", productUser);
            });
          }
        });

        this.preReqxNewContacts.forEach((contact) => {
          const productUser = {
            UserGraphId: contact.id,
            Name: contact.displayName,
            Mail: contact.mail,
            ProductPentestId: newEntityId
          };

          if (productUser.UserGraphId && productUser.Name && productUser.Mail && newEntityId) {
            this.productPentestService.createPreReqUserContact(productUser).subscribe(() => {
              this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Contact Added', life: 3000 });
            });
          }
        });

        const toEmails = this.toEmailService.getToEmails();

        // Define the list of CC recipients
        const ccEmails = this.pentestNewContacts.map(contact => contact.mail);

        
        var formatTestDate = this.productPentest.TestDate?.toString();

        // Format the date
        if (this.productPentest.TestDate) {
          formatTestDate = this.productPentest.TestDate.toLocaleDateString('en-GB', { day: '2-digit', month: 'long', year: 'numeric' }) || '';
        }
     
        this.sendEmail(
          toEmails,
          ccEmails,
          `Pentest Created - ${this.productName} (${this.productId})`,
          `
            <div style="font-family: Arial, sans-serif; line-height: 1.6; color: #333;">
              <h2 style="color: #0056b3;">Pentest Request Created Notification</h2>
              <p>A Pentest request has been successfully created for:</p>
              <table style="border-collapse: collapse; width: 100%; margin-bottom: 20px;">
                <tr>
                  <td style="padding: 8px; border: 1px solid #ddd; font-weight: bold;">Product Id:</td>
                  <td style="padding: 8px; border: 1px solid #ddd;">${this.productId}</td>
                </tr>
                <tr>
                  <td style="padding: 8px; border: 1px solid #ddd; font-weight: bold;">Product Name:</td>
                  <td style="padding: 8px; border: 1px solid #ddd;">${this.productName}</td>
                </tr>
                <tr>
                  <td style="padding: 8px; border: 1px solid #ddd; font-weight: bold;">Product Reference:</td>
                  <td style="padding: 8px; border: 1px solid #ddd;">${this.productReference}</td>
                </tr>
                <tr>
                  <td style="padding: 8px; border: 1px solid #ddd; font-weight: bold;">Pentest Entry ID:</td>
                  <td style="padding: 8px; border: 1px solid #ddd;">${newEntityId}</td>
                </tr>
                <tr>
                  <td style="padding: 8px; border: 1px solid #ddd; font-weight: bold;">Requested By:</td>
                  <td style="padding: 8px; border: 1px solid #ddd;">${requestedByName}</td>
                </tr>
                <tr>
                  <td style="padding: 8px; border: 1px solid #ddd; font-weight: bold;">Proposed Start Date:</td>
                  <td style="padding: 8px; border: 1px solid #ddd;">${formatTestDate}</td>
                </tr>
                <tr>
                  <td style="padding: 8px; border: 1px solid #ddd; font-weight: bold;">Link to Pentest Page:</td>
                  <td style="padding: 8px; border: 1px solid #ddd;">
                    <a href="${environment.redirectUri}/product/${this.productId}/sdlc/pentests?id=${newEntityId}" style="color: #0056b3; text-decoration: none;">Go to Page</a>
                  </td>
                </tr>
              </table>
              <div style="margin-top: 20px; padding: 10px; background-color: #f9f9f9; border: 1px solid #ddd;">
                <p style="font-size: 14px; color: #555;">This is an automated message. If you have any questions, please reach out to the relevant contacts.</p>
              </div>
            </div>
          `
        );        
        
        this.logService.sendInfoMessage('CREATE', 'product/' + this.productId + '/sdlc/pentests/', this.productPentest);

        this.uploadDefaultTemplate(newEntityId, requestedByName);
        this.fetchPentestData();
        this.pentestDialog = false;
        this.productPentest = {};
        this.pentestNewContacts = [];
        this.preReqxNewContacts = [];
        this.submitted = false;
        
        this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Pentest Created', life: 3000 });
      });
    }
  }

  uploadDefaultTemplate(pentestId: string, requestedByName: string) {
    let fileName: string;
  
    // Map requestedByName values to corresponding file names
    const fileMapping: { [key: string]: string } = {
      'IRIS': 'IRIS_Rqst.docx',
      'Archive': 'Archive Rqst.docx',
      'Customer': 'Customer Rqst.docx',
      // Add more mappings as needed
    };
  
    // Check if requestedByName exists in the mapping
    if (fileMapping.hasOwnProperty(requestedByName)) {
      fileName = fileMapping[requestedByName];
    } else {
      // Default to a generic file if requestedByName doesn't match any mapping
      return;
    }
  
    this.http.get(`assets/${fileName}`, { responseType: 'blob' }).subscribe((blob: Blob) => {
      const formData = new FormData();
  
      // Construct timestamp in the desired format
      const currentDate = new Date();
      const timestamp = currentDate.toISOString().slice(0, 19).replace(/[T]/g, ' ').replace(/[-:]/g, '_');
  
      const finalFileName = requestedByName === 'IRIS' ? `${this.productId} - Rqst - ${timestamp}.docx` : `${this.productId} - ${requestedByName} - ${timestamp}.docx`;

      formData.append('LensProductId', this.productId);
      formData.append('LensPentestId', pentestId);
      formData.append('file', blob, finalFileName);
      formData.append('Comment', 'Request Document');
      formData.append('IsInitialRequestForm', 'true');

      // Upload the file using the productPentestService and subscribe to the observable
      this.productPentestService.uploadDocument(this.productId, formData).subscribe(
        (response) => {
          this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Document Template Uploaded', life: 3000 });
        },
        (error) => {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error uploading document template', life: 3000 });
        }
      );
    });  
  }

  onFileUpload(event: any) {
    if (this.selectedPentestOption.Id) {
      const formData = new FormData();
      for (let file of event.files) {
        formData.append('file', file);
        // formData.append('PentestId', this.selectedPentestOption.Id);
        formData.append('LensProductId', this.productId);
        formData.append('LensPentestId', this.selectedPentestOption.Id.toString());
        formData.append('Comment', 'Uploaded from Lens');
      }
      this.productPentestService.uploadDocument(this.productId, formData).subscribe(
        response => {
          // console.log('File uploaded successfully!', response);
          this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'File Uploaded', life: 3000 });
          // Handle success
        },
        error => {
          // console.error('Error uploading file:', error);
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error uploading file', life: 3000 });
          // Handle error
        }
  
      );      
      this.fetchPentestDocuments();
    }
  }
  
  addNewPentestContact() {
    if (this.selectedUser != null) {
      const userExists = this.pentestNewContacts.some(contact => contact.id === this.selectedUser!.id);
      if (userExists) {
        this.messageService.add({ severity: 'warn', summary: 'Warning', detail: 'User already added as new contact', life: 3000 });
      } else {
        this.pentestNewContacts.push(this.selectedUser);
        this.hideUserDialog();
      }
    }
    this.selectedUser = null;
  }

  addNewPreReqUserContact() {
    if (this.selectedUser != null) {
      const userExists = this.preReqxNewContacts.some(contact => contact.id === this.selectedUser!.id);
      if (userExists) {
        this.messageService.add({ severity: 'warn', summary: 'Warning', detail: 'User already added as new contact', life: 3000 });
      } else {
        this.preReqxNewContacts.push(this.selectedUser);
        this.hideUserDialog();
      }
    }
    this.selectedUser = null;
  }

  removeNewPentestContact(contact: User) {
    const index = this.pentestNewContacts.indexOf(contact);
    if (index !== -1) {
        this.pentestNewContacts.splice(index, 1);
    }
  }

  removeNewPreReqUserContact(contact: User) {
    const index = this.preReqxNewContacts.indexOf(contact);
    if (index !== -1) {
        this.preReqxNewContacts.splice(index, 1);
    }
  }

  onSubmit() {
    if (this.submitted) {
      return;
    }

    this.submitted = true;

    if (this.selectedPentestOption && this.selectedPentestOption.Id != undefined) {
      this.editedProductPentest.TestDate = this.convertToUTC(this.editedProductPentest.TestDate);
      this.editedProductPentest.RetestDate = this.convertToUTC(this.editedProductPentest.RetestDate);
      this.editedProductPentest.RemediationsCompleted = this.convertToUTC(this.editedProductPentest.RemediationsCompleted);

      this.productPentestService.updateProductPentest(this.selectedPentestOption.Id, this.editedProductPentest).subscribe(() => {
        this.fetchPentestData();
        this.pentestDialog = false;
        this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Pentest Updated', life: 3000 });
        this.submitted = false;
        
        let toEmails = this.toEmailService.getToEmails();
        
        if(this.getPentestProgressNameByIdEmail(this.editedProductPentest.ProgressId) === '6. Scheduled') {
          toEmails = this.toEmailService.getToEmailsPentest();
        }

        const ccEmails = this.pentestContacts.map(contact => contact.Mail);

        this.sendEmail(
          toEmails,
          ccEmails,
          `Pentest Updated - ${this.productName} (${this.productId})`,
          `
            <div style="font-family: Arial, sans-serif; line-height: 1.6; color: #333;">
              <h2 style="color: #0056b3;">Pentest Update Notification</h2>
              <p>Pentest details have been updated for <strong>${this.productName}</strong> (Product Id: ${this.productId}).</p>
              <table style="border-collapse: collapse; width: 100%; margin-bottom: 20px;">
                <tr>
                  <td style="padding: 8px; border: 1px solid #ddd; font-weight: bold;">Product ID:</td>
                  <td style="padding: 8px; border: 1px solid #ddd;">${this.productId}</td>
                </tr>
                <tr>
                  <td style="padding: 8px; border: 1px solid #ddd; font-weight: bold;">Product Reference:</td>
                  <td style="padding: 8px; border: 1px solid #ddd;">${this.productReference}</td>
                </tr>
                <tr>
                  <td style="padding: 8px; border: 1px solid #ddd; font-weight: bold;">Pentest Entry Id:</td>
                  <td style="padding: 8px; border: 1px solid #ddd;">${this.selectedPentestOption.Id}</td>
                </tr>
                <tr>
                  <td style="padding: 8px; border: 1px solid #ddd; font-weight: bold;">Link to Pentest Page:</td>
                  <td style="padding: 8px; border: 1px solid #ddd;">
                    <a href="${environment.redirectUri}/product/${this.productId}/sdlc/pentests?id=${this.selectedPentestOption.Id}" style="color: #0056b3; text-decoration: none;">Go to Page</a>
                  </td>
                </tr>
              </table>
              <div style="margin-top: 20px; padding: 10px; background-color: #f9f9f9; border: 1px solid #ddd;">
                <p style="font-size: 14px; color: #555;">This is an automated message. If you have any questions, please reach out to the relevant contacts.</p>
              </div>
            </div>
          `
        );           
        
        this.logService.sendInfoMessage('UPDATE', 'product/' + this.productId + '/sdlc/pentests/', this.editedProductPentest, this.selectedPentestOption);

      }, (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error updating Pentest', life: 3000 });
        this.submitted = false;
      });
    }
  }

  addPentestContact() {
    if (this.selectedUser && this.productId) {
      const userExists = this.pentestContacts.some(contact => contact.UserGraphId === this.selectedUser!.id);
      if (userExists) {
        this.messageService.add({ severity: 'warn', summary: 'Warning', detail: 'User already added', life: 3000 });
      } else {
        const productUser = {
          UserGraphId: this.selectedUser.id,
          Name: this.selectedUser.displayName,
          Mail: this.selectedUser.mail,
          ProductPentestId: this.selectedPentestId
        };
  
        if (productUser.UserGraphId && productUser.Name && productUser.Mail && productUser.ProductPentestId && this.selectedPentestId) {
          this.productPentestService.createPentestContact(productUser).subscribe((response) => {
            this.messageService.add({ severity: 'success', summary: 'Successful', detail: `${productUser.Name} added`, life: 3000 });
            this.logService.sendInfoMessage('CREATE', 'product/' + this.productId + '/sdlc/pentests/' + this.selectedPentestId + "/users", productUser);

            this.fetchPentestContacts();
            this.hideUserDialog();
          });
        }
      }
    }
  }

  addPreReqUserContact() {
    if (this.selectedUser && this.productId) {
      const userExists = this.preReqxContacts.some(contact => contact.UserGraphId === this.selectedUser!.id);
      if (userExists) {
        this.messageService.add({ severity: 'warn', summary: 'Warning', detail: 'User already added', life: 3000 });
      } else {
        const productUser = {
          UserGraphId: this.selectedUser.id,
          Name: this.selectedUser.displayName,
          Mail: this.selectedUser.mail,
          ProductPentestId: this.selectedPentestId
        };
  
        if (productUser.UserGraphId && productUser.Name && productUser.Mail && productUser.ProductPentestId && this.selectedPentestId) {
          this.productPentestService.createPreReqUserContact(productUser).subscribe((response) => {
            this.messageService.add({ severity: 'success', summary: 'Successful', detail: `${productUser.Name} added`, life: 3000 });
  
            this.fetchPreReqxContacts();
            this.hideUserDialog();
          });
        }
      }
    }
  }

  removeUser(contact: PentestContact) {
    this.confirmationService.confirm({
        message: 'Are you sure you want to remove this user?',
        accept: () => {
            // User confirmed, perform deletion
            const index = this.pentestContacts.indexOf(contact);
            if (index !== -1 && contact.Id) {
                this.pentestContacts.splice(index, 1);
                this.productPentestService.deletePentestContact(contact.Id).subscribe((response) => {
                  this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'User removed', life: 3000 });
                });
            }
        }
    });
 }

 removePreReqUser(contact: PentestPreReqUserDto) {
  this.confirmationService.confirm({
      message: 'Are you sure you want to remove this user?',
      accept: () => {
          // User confirmed, perform deletion
          const index = this.preReqxContacts.indexOf(contact);
          if (index !== -1 && contact.Id) {
              this.preReqxContacts.splice(index, 1);
              this.productPentestService.deletePreReqUserContact(contact.Id).subscribe((response) => {
                this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'User removed', life: 3000 });
              });
          }
      }
  });
}

  onPageChange(event: any) {
    this.currentPage = event.page + 1;
    this.updateVisibleItems();
  }

  // Method to update the subset of items to display
  updateVisibleItems() {
    const startIndex = (this.currentPage - 1) * this.pageSize;
    const endIndex = startIndex + this.pageSize;
    this.visibleProductPentests = this.productPentests.slice(startIndex, endIndex);
  }

  redirectToSPLibrary() {
    window.open(`https://irissoftware.sharepoint.com/sites/EngineeringIntranet/Product%20Docs%20Template/Forms/AllItems.aspx?id=${this.productId}&FilterField1=LensPentestId&FilterValue1=${this.selectedPentestId}&FilterType1=Lookup`, '_blank')
  }




  onRowEditSave(document:any, selectedDocType: any, selectedDocStatus: any) {
    this.updateMetadata(this.productId, document.name, selectedDocType, selectedDocStatus);
  }

  updateMetadata(productId: string, fileName: string, selectedDocType: any, selectedDocStatus: any) {
    // Create JSON object with the metadata to update
    const metadataObject = {
        DocTypeLookupId: selectedDocType,
        DocStatus: selectedDocStatus
    };

    this.productPentestService.updateDocumentMetadata(productId, fileName, metadataObject).subscribe(
        response => {
          this.fetchPentestDataById();
            // console.log('Metadata updated successfully:', response);
            this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Metadata Updated', life: 3000 });
            // Handle success
        },
        error => {
            // console.error('Error updating metadata:', error);
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error updating metadata', life: 3000 });
            // Handle error
        }
    );
  }

  onDeleteDocument(document: any) {
    this.confirmationService.confirm({
        message: 'Are you sure you want to delete this document?',
        accept: () => {
            this.deleteDocument(this.productId, document.name);
        }
    });
  }

  deleteDocument(productId: string, fileName: string) {
    this.productPentestService.deleteDocument(productId, fileName).subscribe(
        response => {
            // console.log('Document deleted successfully:', response);
            this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Document Deleted', life: 3000 });
            // Handle success
            this.pentestDocuments = this.pentestDocuments.filter((doc) => doc.name !== fileName);
        },
        error => {
            // console.error('Error deleting document:', error);
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error deleting document', life: 3000 });
            // Handle error
        }
    );
  };


  showPTT() {
    // this.refreshPTT();
    this.pentestPTTDialog = true;
  }

  refreshPTT() {
    if (this.selectedPentestId) {
      this.productPTTService.getProductPTTByPentestId(this.selectedPentestId.toString()).subscribe((productPTT) => {
        this.pentestPTT = productPTT.value;
        if (this.pentestPTT.length > 0) {
          this.hasFindings = true;
        } else {
          this.hasFindings = false;
        }
      })
    };
  }

  displayFullTextDialog: boolean = false;
  selectedText: string = '';
  selectedTitle: string = '';

  viewFullText(text: string, title: string) {
    this.selectedText = text;
    this.selectedTitle = title;
    this.displayFullTextDialog = true;
  }

  onPTTEditSave(pentestPTTDto: ProductPTTPutDto) {
    if (pentestPTTDto.Id != undefined) {
      this.productPTTService.updateProductPTT(pentestPTTDto.Id, pentestPTTDto).subscribe(() => {
        this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'PTT Updated', life: 3000 });
      });
    }
  }

  editPTTDialog: boolean = false;
  editPentestPTT: ProductPTTPutDto = {};

  editPTT(pentestPTT: ProductPTTPutDto) {
    this.editPentestPTT = {...pentestPTT};
    this.editPentestPTT.FixDate = this.editPentestPTT.FixDate ? new Date(this.editPentestPTT.FixDate) : undefined;
    this.editPentestPTT.TestDate = this.editPentestPTT.TestDate ? new Date(this.editPentestPTT.TestDate) : undefined;

    this.fetchPTTContacts();

    this.editPTTDialog = true;
  }

  hideEditPTTDialog() {
    this.editPTTDialog = false;
  }

  updatePTT() {
    if (this.editPentestPTT.Id != undefined) {
      this.productPTTService.updateProductPTT(this.editPentestPTT.Id, this.editPentestPTT).subscribe(() => {

        this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'PTT Updated', life: 3000 });
        this.editPTTDialog = false;
        this.editPentestPTT = {};
        this.pttNewContacts = [];

        this.fetchPentestDataById();
        this.refreshPTT();
      });
    }
  }


  getStatus(status: string) {
    switch (status) {
        case 'Active':
            return 'success';
        case 'In Progress':
            return 'warning';
        case 'Withdrawn':
            return 'danger';
        case 'Archived':
            return 'info';

        default:
            return 'info';
    }
  }

  getSeverityNameById(id: number): string {
    const severity = this.pttSeverity.find(x => x.Id === id);
    return severity?.Name || '';
  }

  getCatNameById(id: number): string {
    const cat = this.pttCat.find(x => x.Id === id);
    return cat?.Name || '';
  }

  getComplexityNameById(id: number): string {
    const complexity = this.pttComplexity.find(x => x.Id === id);
    return complexity?.Name || '';
  }

  getPriorityNameById(id: number): string {
    const priority = this.pttPriority.find(x => x.Id === id);
    return priority?.Name || '';
  }

  getProgressNameById(id: number): string {
    const progress = this.pttProgress.find(x => x.Id === id);
    return progress?.Name || '';
  }

  addPTTContact() {
    const productUser = {
      UserGraphId: this.selectedUser?.id,
      Name: this.selectedUser?.displayName,
      Mail: this.selectedUser?.mail,
      ProductPTTId: this.editPentestPTT.Id // needs to be PTT Id
    };

    if (this.selectedUser && this.productId && productUser.UserGraphId && productUser.Name && productUser.ProductPTTId) {
      this.productPTTService.createPTTContact(productUser).subscribe(() => {
        this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Contact Added', life: 3000 });
        
        this.fetchPTTContacts();
      });
    }

    this.selectedUser = null;
  }

  fetchPTTContacts() {
    if (this.editPentestPTT.Id) {
      this.productPTTService.getPTTContacts(this.editPentestPTT.Id).subscribe((pttContacts) => {
        this.pttContacts = pttContacts;
        // I need this.sdlcPrimaryContacts to only contain the 
      });
    }
  }

  removePTTContact(contact: PTTContactDto) {
    this.confirmationService.confirm({
        message: 'Are you sure you want to remove this user?',
        accept: () => {
            // User confirmed, perform deletion
            const index = this.pttContacts.indexOf(contact);
            if (index !== -1 && contact.Id) {
              this.pttContacts.splice(index, 1);
              this.productPTTService.deletePTTContact(contact.Id).subscribe((response) => {
                this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'User removed', life: 3000 });
              });
            }
          }
    });
  }

  openPTTContactDialog() {
    this.pttContactDialog = true;
  }

  hidePTTContactDialog() {
    this.pttContactDialog = false;
  }

  deletePentest(pentest: ProductPentest) {
    this.confirmationService.confirm({
        message: `Are you sure you want to delete this Pentest? (Id: ${pentest.Id})`,
        header: 'Delete Confirmation',
        icon: 'pi pi-info-circle',
        acceptButtonStyleClass:"p-button-danger p-button-text",
        rejectButtonStyleClass:"p-button-text p-button-text",
        acceptIcon:"none",
        rejectIcon:"none",
        accept: () => {
            // User confirmed, perform deletion
            if (pentest.Id) {
                this.productPentestService.deleteProductPentest(pentest.Id).subscribe((response) => {
                    this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Pentest deleted', life: 3000 });
                    this.fetchPentestData();
                    this.logService.sendInfoMessage('DELETE', 'product/' + this.productId + '/sdlc/pentests/' + pentest.Id, null, pentest);
                });
            }
        },
        reject: () => {
          this.messageService.add({ severity: 'error', summary: 'Rejected', detail: 'You have rejected' });
      }
    });
  }

    deletePTTFinding(id: any) {
    this.confirmationService.confirm({
      message: `Are you sure you want to delete this Finding?`,
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      acceptButtonStyleClass:"p-button-danger p-button-text",
      rejectButtonStyleClass:"p-button-text p-button-text",
      acceptIcon:"none",
      rejectIcon:"none",
      accept: () => {
        // User confirmed, perform deletion
        if (id) {
          this.productPTTService.deletePTTFinding(id).subscribe((response) => {
            this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Finding deleted', life: 3000 });
            this.refreshPTT();
            this.logService.sendInfoMessage('DELETE', 'product/' + this.productId + '/sdlc/pentests/finding/', id);
          });
        }
      },
      reject: () => {
        this.messageService.add({ severity: 'error', summary: 'Rejected', detail: 'You have rejected' });
      }
    });
  }

        // Emails

        sendEmail(toEmails: string[], ccEmails: string[], subject: string, htmlContent: string) {
          const emailRequest: EmailRequestNewDto = {
              toEmails: toEmails,
              ccEmails: ccEmails,
              subject: subject,
              htmlContent: htmlContent,
              plainTextContent: '' // Assuming no plain text content in this case
          };
      
          this.emailService.sendEmailNew(emailRequest).subscribe(response => {
              console.log('Email sent successfully', response);
          }, error => {
              console.error('Error sending email', error);
          });
        }



        clearComponentState() {
          this.productPentests = [];
          this.editedProductPentest = {};
          this.productPentest = {};
        
          this.pentestRisks = [];
          this.pentestProgresses = [];
          this.pentestProviders = [];
          this.pentestPreReqx = [];
          this.pentestRequestedBy = [];
        
          this.pttCat = [];
          this.pttComplexity = [];
          this.pttPriority = [];
          this.pttProgress = [];
          this.pttSeverity = [];
        
          this.originalSelectedOption = {};
        
          this.hasSelectedOption = false;
          this.selectedPentestId = undefined;
          this.selectedPentestOption = {};
        
          this.submitted = false;
          this.pentestDialog = false;
        
          this.messages = [];
          this.messages2 = [];
        
          this.productPentestById = {};
        
          this.userDialog = false;
          this.users = [];
          this.pentestContacts = [];
          this.preReqxContacts = [];
        
          this.selectedUser = null;
        
          this.pentestNewContacts = [];
          this.preReqxNewContacts = [];
          this.userDialogAction = '';
        
          this.pentestDocuments = [];
        
          this.pttNewContacts = [];
          this.pttContacts = [];
          this.pttDialog = false;
        
          this.visibleProductPentests = [];
        }

        convertToUTC(date: Date | undefined): Date | undefined {
          if (date) {
            return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
          }
          return undefined;
        }
}
