import { Component } from '@angular/core';
import { VersionService } from '../../service/version.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-version',
  templateUrl: './version.component.html',
  styleUrls: ['./version.component.scss']
})
export class VersionComponent {
  commitId: string = '';

  constructor(private http: HttpClient) {}

  ngOnInit(): void {
    this.http.get('commit.txt', { responseType: 'text' }).subscribe(data => {
      this.commitId = data.trim();
    });
  }
}
