import { Component } from '@angular/core';

@Component({
  selector: 'app-approved-software',
  templateUrl: './approved-software.component.html',
  // styleUrls: ['./approved-software.component.css']
})
export class ApprovedSoftwareComponent {

}
