<div class="layout-topbar">
  <a class="p-link layout-menu-button layout-topbar-button" href="#" (click)="appMain.toggleMenu($event)">
    <i class="pi pi-bars"></i>
  </a>

    <a class="layout-topbar-logo ml-8" routerLink="">
      <img src="assets/layout/images/iris-logo.png" alt="logo" />
      <span>Lens</span>
    </a>
  
  
    <a class="p-link layout-topbar-menu-button layout-topbar-button" href="#" (click)="appMain.toggleTopMenu($event)">
      <i class="pi pi-ellipsis-v"></i>
    </a>
    
    <!--<div class="layout-topbar-menu-dropdown">
      <p-autoComplete [(ngModel)]="selectedProduct" [suggestions]="filteredProducts" (completeMethod)="filterProduct($event)" field="Name" [dropdown]="true" (onSelect)="onProductSelect($event)" [style]="{'width': '300px'}">
      </p-autoComplete>
    </div>-->
    <div class="layout-topbar-menu-dropdown" >
      <app-freetextsearch style="width:30em"></app-freetextsearch>
    </div>

      <div class="layout-topbar-menu" [ngClass]="{ 'layout-topbar-menu-mobile-active': appMain.topMenuActive }">
        <a style="cursor: pointer" id="layout-config-button"
           class="layout-topbar-button layout-config-button"
           (click)="onConfigButtonClick($event)">
          <i class="pi pi-sun"></i>
        </a>

        <!-- <a href="#" class="p-link layout-topbar-button">
      <i class="pi pi-calendar"></i>
      <span>Calendar</span>
    </a>
    <a href="#" class="p-link layout-topbar-button">
      <i class="pi pi-cog"></i>
      <span>Settings</span>
    </a> -->

        <div>
          <div (click)="op1.toggle($event)" class="layout-topbar-button">
            <!-- Removed the i tag and directly used img tag -->
            <img *ngIf="profilePhoto" [src]="profilePhoto" alt="Profile Photo" class="profile-photo" />
            <span *ngIf="!profilePhoto"><i class="pi pi-user"></i></span> <!-- Show default icon if profile photo is not available -->
            <span>Profile</span>
          </div>
          <p-overlayPanel #op1 [showCloseIcon]="false">
            <div class="">
              <p class="mb-2">User: <strong>{{profile?.mail}}</strong></p>
            </div>
            <!-- <p-tieredMenu #menu [model]="items" appendTo="body"></p-tieredMenu> -->
            <div  class="flex flex-column">
              <button pButton pRipple label="Admin" icon="pi pi-cog" class="p-button-secondary"
              *appHasRole="['Lens.Admin']" (click)="goToAdmin()"></button>
                      
                        <!-- Logout button -->
                        <button pButton pRipple label="Logout" icon="pi pi-sign-out" class="p-button-danger mt-1"
                (click)="logout()"></button>
            </div>
          </p-overlayPanel>
        </div>

        <button pButton pRipple icon="pi pi-sign-in" type="button" label="Login" class="p-button-outlined" *ngIf="!loginDisplay" (click)="login()"></button>
      </div>
    </div>
