import { Component, OnInit } from '@angular/core';
import { AppMainComponent } from '../app-main/app.main.component';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
import { ProductService } from 'src/app/layout/service/product/products.service';
import { ProductToolsRefService } from 'src/app/layout/service/product/product-tool-refs.service';
import { ToolExternalService } from 'src/app/layout/service/tool-external.service';

@Component({
  selector: 'app-menu',
  template: `
  <ng-container>
    <div class="layout-menu-container">
      <ul class="layout-menu" role="menu" (keydown)="onKeydown($event)">
        <div *ngIf="isProductLevel && logo" class="menu-logo">
          <img [src]="logo" class="scaled-image" />
        </div>
        <div *ngIf="isSuiteLevel && logo" class="menu-logo">
          <img [src]="logo" class="scaled-image" />
        </div>
        <div *ngIf="isProductLevel && product?.Name" class="menu-product-name">{{ product.Name }}</div>
        <div *ngIf="isSuiteLevel && suite?.Name" class="menu-product-name">{{ suite.Name }}</div>
        <li app-menu class="layout-menuitem-category" *ngFor="let item of model; let i = index" role="none">
          <div class="layout-menuitem-root-text" [attr.aria-label]="item.label">{{ item.label }}</div>
          <ul role="menu">
            <li app-menuitem *ngFor="let child of item.items" [item]="child" [index]="i" role="none">
              <div class="layout-menuitem-root-text" [attr.aria-label]="child.label">{{ child.label }}</div>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </ng-container>
  `,
})
export class AppMenuComponent implements OnInit {
  model!: any[];
  product: any | null = null;
  suite: any | null = null;
  isProductLevel: boolean = false;
  isSuiteLevel: boolean = false;
  includedInSDLCMaturity: boolean = false;
  logo: string = '';
  assetTags: string[] = [];
  assetMenu: any[] = [];
  detailsMenu: any[] = [];

  constructor(public appMain: AppMainComponent, private router: Router,
    private productService: ProductService, private ProductToolsRefService: ProductToolsRefService, private ToolExternalService: ToolExternalService) { }

  ngOnInit() {

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      const currentRoute = this.router.url;
      this.setMenuItems(currentRoute);      
    });

    // Initial setup on component initialization
    const currentRoute = this.router.url;
    this.setMenuItems(currentRoute);
  }

  setMenuItems(currentRoute: string) {
    const productIdRegex = /\/product\/([^\/]+)/;
    const suiteIdRegex = /\/suite\/([^\/]+)/;
    const assetIdRegex = /\/asset\/([^\/]+)/;

    const productIdMatch = currentRoute.match(productIdRegex);
    const suiteIdMatch = currentRoute.match(suiteIdRegex);
    const assetIdMatch = currentRoute.match(assetIdRegex);

    const productId = productIdMatch ? productIdMatch[1] : null;
    const suiteId = suiteIdMatch ? suiteIdMatch[1] : null;
    const assetId = assetIdMatch ? assetIdMatch[1] : null;

    if (productId) {
      this.productService.getProductById(productId).subscribe(
        (response: any) => {
          this.product = response;
          this.includedInSDLCMaturity = this.product?.IncludedInSDLCMaturity ?? false;
          this.isProductLevel = true;
          this.isSuiteLevel = false;
          this.logo = this.product?.Logo ?? '';

          if (this.includedInSDLCMaturity) {
            // Locate the 'SDLC' menu item and add 'Dashboard' to its items array
            const sdlcMenu = this.model[1].items.find((item: any) => item.label === 'SDLC');
            if (sdlcMenu && sdlcMenu.items) {
              sdlcMenu.items.unshift({
                label: 'Dashboard',
                icon: 'dashboard',
                routerLink: ['/product', productId, 'sdlc', 'dashboard'],
              });
            }
          }

          if (this.product?.SuiteId) {
            this.fetchSuiteData(this.product.SuiteId);

            // Conditionally add the new menu item if SuiteName is 'Elements'
            if (this.product?.SuiteName === 'Elements') {
              this.model[1].items.push({
                label: 'Quicksight',
                icon: 'space_dashboard',
                routerLink: ['/product', productId, 'elements-quicksight'],
              });
            }
          }
        }
      );

      this.model = [
        {
          items: [
            { label: 'Home', icon: 'home', routerLink: ['/home/dashboard'] },
            { label: 'Products', icon: 'inventory', routerLink: ['/home/products'] },
            {
              label: 'Reports', icon: 'analytics',
              items: [
                { label: 'SDLC Maturity', icon: 'checklist', routerLink: ['/reports/sdlcmaturity'] },
                { label: 'Portfolio Overview', icon: 'list', routerLink: ['/reports/portfoliooverview'] },
                { label: 'Vulnerability Report', icon: 'summarize', routerLink: ['/reports/mastervulnreport'] },
                { label: 'Tech Debt Report', icon: 'paid', routerLink: ['/reports/techdebt'] },
                { label: 'Report Recipients', icon: 'mail', routerLink: ['/reports/reportrecipients'] },
              ],
            }
          ],
        },
        {
          label: 'Product Menu',
          items: [
            { label: 'Dashboard', icon: 'dashboard', routerLink: ['/product', productId, 'dashboard'] },
            { label: 'Details', icon: 'info', routerLink: ['/product', productId, 'details'] },
            { label: 'Team', icon: 'group', routerLink: ['/product', productId, 'team'] },
            { label: 'Links', icon: 'link', routerLink: ['/product', productId, 'links'] },
            { label: 'NFRs', icon: 'fact_check', routerLink: ['/product', productId, 'nfrs'] },
            { label: 'Assets', icon: 'dns', routerLink: ['/product', productId, 'assets'] },
            { label: 'Vulnerabilities', icon: 'report', routerLink: ['/vulnerabilities', 'search'], queryParams: { products: productId } },            
            { label: 'SDLC', icon: 'security', routerLink: ['/product', productId, 'sdlc'], 
              items: [
                // { label: 'Dashboard', icon: 'dashboard', routerLink: ['/product', productId, 'sdlc', 'dashboard'] },
                { label: 'Threat Modelling', icon: 'model_training', routerLink: ['/product', productId, 'sdlc', 'threatmodelling'] },
                { label: 'SCA', icon: 'cases', routerLink: ['/product', productId, 'sdlc', 'sca'] },
                { label: 'SAST', icon: 'code', routerLink: ['/product', productId, 'sdlc', 'sast'] },
                { label: 'DAST', icon: 'screen_search_desktop', routerLink: ['/product', productId, 'sdlc', 'dast'] },
                { label: 'NP-DAST', icon: 'electric_bolt', routerLink: ['/product', productId, 'sdlc', 'npdast'] },
                { label: '1-Day Pentest', icon: 'looks_one', routerLink: ['/product', productId, 'sdlc', 'onedaypentest'] },
                { label: 'Pentests', icon: 'shield', routerLink: ['/product', productId, 'sdlc', 'pentests'] },
                { label: 'Incident Response', icon: 'crisis_alert', routerLink: ['/product', productId, 'sdlc', 'incidentresponse'] },
              ],
            },
            { label: 'Architecture', icon: 'architecture', routerLink: ['/product', productId, 'arch'],
              items: [
                { label: 'ARBs', icon: 'polyline', routerLink: ['/product', productId, 'architecture', 'arbs'] }
              ],
            },
            { label: 'Tool References', icon: 'handyman', routerLink: ['/product', productId, 'tool-refs'] },
          ],
        },
      ];
    } else if (suiteId) {
      // Customize the menu for the 'suite' route
      this.isProductLevel = false;

      this.productService.getSuiteById(suiteId).subscribe(
        (response: any) => {
          this.suite = response;
          this.isSuiteLevel = true;
          this.logo = this.suite?.Logo ?? '';

          // Conditionally add the new menu item if SuiteName is 'Elements'
          if (this.suite?.Name === 'Elements') {
            this.model[1].items.push({
            label: 'Quicksight',
            icon: 'space_dashboard',
            routerLink: ['/suite', suiteId, 'elements-quicksight'],
            });
          }
        }
      );

      this.model = [
        {
          items: [
            { label: 'Home', icon: 'home', routerLink: ['/home/dashboard'] },
            { label: 'Suites', icon: 'apps', routerLink: ['/home/suites'] },
            {
              label: 'Reports', icon: 'analytics',
              items: [
                { label: 'SDLC Maturity', icon: 'checklist', routerLink: ['/reports/sdlcmaturity'] },
                { label: 'Portfolio Overview', icon: 'list', routerLink: ['/reports/portfoliooverview'] },
                { label: 'Vulnerability Report', icon: 'summarize', routerLink: ['/reports/mastervulnreport'] },
                { label: 'Tech Debt Report', icon: 'paid', routerLink: ['/reports/techdebt'] },
                { label: 'Report Recipients', icon: 'mail', routerLink: ['/reports/reportrecipients'] },
              ],
            }
          ],
        },
        {
          label: 'Suite Menu',
          items: [
            { label: 'Dashboard', icon: 'dashboard', routerLink: ['/suite', suiteId, 'dashboard'] },
            { label: 'Details', icon: 'info', routerLink: ['/suite', suiteId, 'details'] },
            { label: 'NFRs', icon: 'fact_check', routerLink: ['/suite', suiteId, 'nfrs'] },
            { label: 'Assets', icon: 'dns', routerLink: ['/suite', suiteId, 'assets'] },
            { label: 'Vulnerabilities', icon: 'report', routerLink: ['/vulnerabilities', 'search'], queryParams: { suites: suiteId } },            
            { label: 'Architecture', icon: 'architecture', routerLink: ['/suite', suiteId, 'arch'],
              items: [
                { label: 'ARBs', icon: 'polyline', routerLink: ['/suite', suiteId, 'architecture', 'arbs'] }
              ],
            },
            { label: 'Tool References', icon: 'handyman', routerLink: ['/suite', suiteId, 'tool-refs'] },
          ],
        },
      ];
    } else if (assetId) {
      this.isProductLevel = false;
      this.detailsMenu = [];
      this.assetMenu = [];
      this.assetTags = [];

      this.detailsMenu.push({ label: 'Details', icon: 'info', routerLink: ['/asset', assetId, 'details'] });
      
      this.ToolExternalService.getAsset(assetId).subscribe((response: any) => {

        const hostname = response.hostnames[0];
        //this.detailsMenu.push({ label: 'Vulnerabilities', icon: 'report', routerLink: ['/vulnerabilities', 'search'], queryParams: { keyTerms: hostname } });

        //Get all of the asset tags for the asset
        for (const element of response.tags) {
          if (element.key == 'DevOps') {
            let assetTag = element.value;
            this.assetTags.push(assetTag);
          }
          if (element.key == 'Departments' && element.value == 'iSAMS') {
            this.assetTags.push("iSAMS");
          }
        }

        //Loop through the asset tags and marry them to a Product Tool Reference
        for(const asset of this.assetTags) {
          this.ProductToolsRefService.getProductToolRefsByReference(asset).subscribe((response: any) => {

            let data = response.value;

            for (const element of data) {
              let productToolRef = element;
              let ProductTypeName = productToolRef.ProductTypeName;
              if (ProductTypeName == "Product") {
                this.productService.getProductById(productToolRef.ObjectId).subscribe((response: any) => {                    
                  this.assetMenu.push({ label: response.Name, icon: 'inventory', routerLink: ['/product', productToolRef.ObjectId, 'assets'] });
                });                
              } 
              if (ProductTypeName == "Suite") {
                this.productService.getSuiteById(productToolRef.ObjectId).subscribe((response: any) => {
                  this.assetMenu.push({ label: response.Name, icon: 'apps', routerLink: ['/suite', productToolRef.ObjectId, 'assets'] });
                });   
              } 
            }
          });
        }        
      });



      this.model = [
        {
          items: [
            { label: 'Home', icon: 'home', routerLink: ['/home/dashboard'] },
            { label: 'Products', icon: 'inventory', routerLink: ['/home/products'] },
            { label: 'Suites', icon: 'apps', routerLink: ['/home/suites'] },
          ],
        },
        {
          label: 'Go Back to Parent',
          items: this.assetMenu,
        },
        {
          label: 'Asset Menu',
          items: this.detailsMenu,
        },
      ];

    } else {

      this.isProductLevel = false;
      this.isSuiteLevel = false;

      if (currentRoute.includes('vulnerabilities')) {
        this.model = [
          {
            label: 'Home',
            items: [{ label: 'Dashboard', icon: 'home', routerLink: ['/home/dashboard'] }],
          },
          {
            label: 'Pages',
            items: [
              { label: 'Home', icon: 'home', routerLink: ['/home/dashboard'] },
              { label: 'Products', icon: 'inventory', routerLink: ['/home/products'] },
              { label: 'Suites', icon: 'apps', routerLink: ['/home/suites'] },
              { label: 'Vulnerabilities', icon: 'report', routerLink: ['/vulnerabilities/search'] },
              {
                label: 'Reports', icon: 'analytics',
                items: [
                  { label: 'SDLC Maturity', icon: 'checklist', routerLink: ['/reports/sdlcmaturity'] },
                  { label: 'Portfolio Overview', icon: 'list', routerLink: ['/reports/portfoliooverview'] },
                  { label: 'Vulnerability Report', icon: 'summarize', routerLink: ['/reports/mastervulnreport'] },
                  { label: 'Tech Debt Report', icon: 'paid', routerLink: ['/reports/techdebt'] },
                  { label: 'Report Recipients', icon: 'mail', routerLink: ['/reports/reportrecipients'] },
                ],
              }
            ],
          }
        ];
      }
      else {

        this.model = [
          {
            label: 'Home',
            items: [{ label: 'Dashboard', icon: 'home', routerLink: ['/home/dashboard'] }],
          },
          {
            label: 'Pages',
            items: [
              { label: 'Products', icon: 'inventory', routerLink: ['/home/products'] },
              { label: 'Suites', icon: 'apps', routerLink: ['/home/suites'] },
              { label: 'ARB List', icon: 'polyline', routerLink: ['/home/arb-list'] },
              { label: 'Vulnerabilities', icon: 'report', routerLink: ['/vulnerabilities/search'] },
              { label: 'Tech Radar', icon: 'radar', routerLink: ['/home/tech-radar'] },
              { label: 'Approved Software', icon: 'beenhere', routerLink: ['/home/approved-software'] },
              { label: 'Useful Tools', icon: 'handyman', routerLink: ['/home/useful-tools'] },
              
              {
                label: 'Reports', icon: 'analytics',
                items: [
                  { label: 'SDLC Maturity', icon: 'checklist', routerLink: ['/reports/sdlcmaturity'] },
                  { label: 'Portfolio Overview', icon: 'list', routerLink: ['/reports/portfoliooverview'] },
                  { label: 'Vulnerability Report', icon: 'summarize', routerLink: ['/reports/mastervulnreport'] },
                  { label: 'Tech Debt Report', icon: 'paid', routerLink: ['/reports/techdebt'] },
                  { label: 'Report Recipients', icon: 'mail', routerLink: ['/reports/reportrecipients'] },
                ],
              }
            ]
          }
        ]
      }
    }
  }

  onKeydown(event: KeyboardEvent) {
    const nodeElement = <HTMLDivElement>event.target;
    if (event.code === 'Enter' || event.code === 'Space') {
      nodeElement.click();
      event.preventDefault();
    }
  }

  fetchSuiteData(suiteId: string) {
    if (suiteId !== '') {
      this.productService.getSuiteById(suiteId).subscribe((suite) => {
        this.suite = suite;

        //If the product itself doesn't have a logo, but the parent suite does, then use that
        if (this.logo == '') {
          this.logo = this.suite?.Logo ?? '';
        }
      });
    }
  }
}
