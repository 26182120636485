import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConfirmationService, Message, MessageService } from 'primeng/api';
import { EmailRequestDto, EmailRequestNewDto } from 'src/app/api/email-request';
import { User } from 'src/app/api/product';
import { ProductSDLC, ProductSDLCPutDto, ProductSDLCRequestDto, SDLCPrimaryContact } from 'src/app/api/product-sdlc';
import { EmailTemplateService } from 'src/app/layout/service/email/email-template.service';
import { GraphExplorerService } from 'src/app/layout/service/graphexplorer/graph-explorer.service';
import { LogService } from 'src/app/layout/service/log.service';
import { EmailService } from 'src/app/layout/service/product/email.service';
import { ProductSDLCService } from 'src/app/layout/service/product/product-sdlc.service';
import { ToEmailService } from 'src/app/layout/service/product/to-email.service';
import { ProductSelectionService } from 'src/app/product.selection.service';

@Component({
  selector: 'app-threat-modelling',
  templateUrl: './threat-modelling.component.html'
})
export class ThreatModellingComponent implements OnInit {
  productId: string = '';
  productName: string = '';
  productReference: string = '';

  productSDLC: ProductSDLC = {};

  productSDLCTools: any[] = [];
  productSDLCTMStatuses: any[] = [];

  hasProductSDLC: boolean = false;

  newProductSDLC: ProductSDLCRequestDto = {};
  editedProductSDLC: ProductSDLCPutDto = {};

  messages: Message[] = [];

  threatSDLCPracticeId: number | undefined;

  // New stuff

  userDialog: boolean = false;
  users: User[] = [];
  sdlcPrimaryContacts: SDLCPrimaryContact[] = [];

  selectedUser: User | null = null;

  userDialogAction = '';
  threatModellingDialog: boolean = false;
  tmNewContacts: User[] = [];

  constructor(private route: ActivatedRoute, private productSDLCService: ProductSDLCService, private graphExplorerService: GraphExplorerService, 
    private messageService: MessageService, private confirmationService: ConfirmationService, 
    private emailService: EmailService, private productSelectionService: ProductSelectionService, private toEmailService: ToEmailService,
    private logService: LogService, private emailTemplateService: EmailTemplateService) { }

  ngOnInit(): void {

    this.route.params.subscribe(params => {
      this.productId = params['id'];

      this.productSelectionService.getSelectedProduct().subscribe((product) => {
        this.productName = product?.Name || '';
        this.productReference = product?.ProductRef || '';
      });

      this.fetchSDLCData();
    });

    this.productSDLCService.getAllSDLCTools("Threats").subscribe((sdlcTools) => {
      this.productSDLCTools = sdlcTools;
    });

    this.productSDLCService.getSDLCPractice("Threats").subscribe((sdlcPractice) => {
      if(sdlcPractice.length > 0){
        this.threatSDLCPracticeId = sdlcPractice[0].Id;
      }

      this.productSDLCService.getMaturityLevelByPracticeId(this.threatSDLCPracticeId).subscribe((sdlcTMStatuses) => {
        this.productSDLCTMStatuses = sdlcTMStatuses;
      });
    });

    this.getAllUsers();
    this.messages = [{ severity: 'warn', summary: 'Warning', detail: 'No threat modelling for this product' }];

  }

  getAllUsers() {
    this.graphExplorerService.getAllADUsers().subscribe((users) => {
      this.users = users;
    });
  }
  
  searchUsers(event?: any) {
    if (event.filter) {
      this.graphExplorerService.searchADUsers(event.filter).subscribe((users) => {
        this.users = users;
      });
    } else {
      this.getAllUsers();
    }
  }

  fetchSDLCData() {
    this.productSDLCService.getProductSDLCThreatModelling(this.productId).subscribe((sdlcThreatModelling) => {
      if (sdlcThreatModelling && sdlcThreatModelling.value.length > 0) {
        this.productSDLC = sdlcThreatModelling["value"][0];
        this.productSDLC.EnrolDate = this.productSDLC.EnrolDate ? new Date(this.productSDLC.EnrolDate) : undefined;
        this.productSDLC.NextReviewDate = this.productSDLC.NextReviewDate ? new Date(this.productSDLC.NextReviewDate) : undefined;
        this.hasProductSDLC = true;

        this.editedProductSDLC = {...this.productSDLC};

        this.tmBefore = this.productSDLC;

        this.fetchProductSDLCContacts();

      } else {
        this.productSDLC = {};
        this.hasProductSDLC = false;
      }
    });
  }

  fetchProductSDLCContacts() {
    if (this.productSDLC.Id) {
      this.productSDLCService.getSDLCPrimaryContacts(this.productSDLC.Id).subscribe((sdlcPrimaryContacts) => {
        this.sdlcPrimaryContacts = sdlcPrimaryContacts;
      });
    }
  }

  openUserDialog(action: string) {
    this.userDialogAction = action;
    this.userDialog = true;
  }

  hideDialog() {
    this.userDialog = false;
    this.selectedUser = null;
  }

  getToolNameById(toolId: string): string {
    const selectedTool = this.productSDLCTools.find(tool => tool.Id === toolId);
    return selectedTool ? selectedTool.Name : '';
  }

  getTMStatusNameById(tmStatusId: string): string {
    const selectedTMStatus = this.productSDLCTMStatuses.find(tmStatus => tmStatus.Id === tmStatusId);
    return selectedTMStatus ? selectedTMStatus.Name : '';
  }

  onSubmit() {
    // Check if productSDLC exists
    if (this.hasProductSDLC && this.productSDLC.Id) {

      this.editedProductSDLC.EnrolDate = this.convertToUTC(this.editedProductSDLC.EnrolDate);
      this.editedProductSDLC.NextReviewDate = this.convertToUTC(this.editedProductSDLC.NextReviewDate);

      // Update existing productSDLC with a PUT request
      this.productSDLCService.updateProductSDLC(this.productSDLC.Id, this.editedProductSDLC).subscribe(
        (updatedProductSDLC) => {
          console.log(this.editedProductSDLC.EnrolDate);
          // Handle successful update
          this.productSDLCService.getProductSDLCThreatModelling(this.productId).subscribe((tm) => {
            this.tmAfter = tm.value[0];
            console.log(this.editedProductSDLC.EnrolDate);

            const htmlContent = this.createEmailContent(this.tmBefore, this.tmAfter);

            const toEmails = this.toEmailService.getToEmails();

            const ccEmails = this.sdlcPrimaryContacts.map(contact => contact.Mail);


            // console.log(this.tmBefore, this.tmAfter);
            this.sendEmail(toEmails, ccEmails, `Threat Modelling  Updated - ${this.productSDLC.ProductName} (${this.productId})`, htmlContent);

            this.logService.sendInfoMessage('UPDATE', 'product/' + this.productId + '/sdlc/threatmodelling/', this.tmAfter, this.tmBefore);
          });

          this.messageService.add({ severity: 'success', summary: 'Successful', detail: `Threat modelling updated`, life: 3000 });
          // console.log('Product SDLC updated:', updatedProductSDLC);

        },
        (error) => {
          // Handle update error
          console.error('Error updating product SDLC:', error);
        }
      );
    }
  }

  convertToUTC(date: Date | undefined): Date | undefined {
    if (date) {
      return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
    }
    return undefined;
  }
  

  addPrimaryContact() {
    if (this.selectedUser && this.productId) {
        // Check if the user is already in the list
        const userExists = this.sdlcPrimaryContacts.some(contact => contact.UserGraphId === this.selectedUser?.id);
        if (!userExists) {
            const productUser = {
                UserGraphId: this.selectedUser.id,
                Name: this.selectedUser.displayName,
                Mail: this.selectedUser.mail,
                ProductSDLCId: this.productSDLC.Id
            };

            if (productUser.UserGraphId && productUser.Name && this.productSDLC.Id) {
                this.productSDLCService.createSDLCPrimaryContact(productUser).subscribe((response) => {
                    this.messageService.add({ severity: 'success', summary: 'Successful', detail: `${productUser.Name} added`, life: 3000 });

                    this.fetchProductSDLCContacts();
                    this.hideDialog();
                });
            }
        } else {
            this.messageService.add({ severity: 'warn', summary: 'Warning', detail: 'User already added as primary contact', life: 3000 });
        }
    }
  }

  removeUser(contact: SDLCPrimaryContact) {
    this.confirmationService.confirm({
        message: 'Are you sure you want to remove this user?',
        accept: () => {
            // User confirmed, perform deletion
            const index = this.sdlcPrimaryContacts.indexOf(contact);
            if (index !== -1 && contact.Id) {
                this.sdlcPrimaryContacts.splice(index, 1);
                // Perform the actual deletion from your backend here if necessary
                this.productSDLCService.deleteSDLCPrimaryContact(contact.Id).subscribe((response) => {
                  this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'User removed', life: 3000 });
                  this.logService.sendInfoMessage('DELETE', 'product/' + this.productId + '/sdlc/threatmodelling/' + this.productSDLC.Id + "/users/" + contact.Id, null, contact);
                });
            }
        }
    });
 }

 openNew() {
    this.editedProductSDLC = {};
    this.threatModellingDialog = true;
  }

  addNewTMContact() {
    if (this.selectedUser != null) {
        // Check if the user is already in the list
        const userExists = this.tmNewContacts.some(contact => contact.id === this.selectedUser?.id);
        if (!userExists) {
            this.tmNewContacts.push(this.selectedUser);
        } else {
            this.messageService.add({ severity: 'warn', summary: 'Warning', detail: 'User already added as new contact', life: 3000 });
        }
    }
    this.selectedUser = null;
    this.hideDialog();
  }

  removeNewTMContact(contact: User) {
    const index = this.tmNewContacts.indexOf(contact);
    if (index !== -1) {
      this.tmNewContacts.splice(index, 1);
    }
  }

  saveNewThreatModelling() {
    this.newProductSDLC.ProductId = this.productId;
    this.newProductSDLC.SDLCPracticeId = this.threatSDLCPracticeId;
    this.newProductSDLC.EnrolDate = this.convertToUTC(this.newProductSDLC.EnrolDate);

    this.productSDLCService.createProductSDLC(this.newProductSDLC).subscribe(
      (createdProductSDLC: any) => {
        const newEntityId = createdProductSDLC.Id;

        this.tmNewContacts.forEach((contact) => {
          const productUser = {
            UserGraphId: contact.id,
            Name: contact.displayName,
            Mail: contact.mail,
            ProductSDLCId: newEntityId
          };

    
          if (productUser.UserGraphId && productUser.Name && productUser.Mail && newEntityId) {
            this.productSDLCService.createSDLCPrimaryContact(productUser).subscribe((response) => {
              this.messageService.add({ severity: 'success', summary: 'Successful', detail: `${productUser.Name} added`, life: 3000 });
            });
          }
        });


        const toEmails = this.toEmailService.getToEmails();

        // Define the list of CC recipients
        const ccEmails = this.tmNewContacts.map(contact => contact.mail);

        const emailContent = this.emailTemplateService.generateEmailContent('Threat Modelling', this.productName, this.productId, this.productReference, newEntityId, 'sdlc/threatmodelling');
        this.sendEmail(toEmails, ccEmails, `Threat Modelling Created - ${this.productName} (${this.productId})`, emailContent);

        this.logService.sendInfoMessage('CREATE', 'product/' + this.productId + '/sdlc/threatmodelling/' + this.newProductSDLC);

        this.fetchSDLCData();
      },
      (error) => {
        // Handle create error
        console.error('Error creating product SDLC:', error);
      }
    );

    this.threatModellingDialog = false;
    this.newProductSDLC = {};
  }

  // Emails
  tmBefore: ProductSDLC = {};
  tmAfter: ProductSDLC = {};
  emailCreation: EmailRequestDto = {};

  sendEmail(toEmails: string[], ccEmails: string[], subject: string, htmlContent: string) {
    const emailRequest: EmailRequestNewDto = {
        toEmails: toEmails,
        ccEmails: ccEmails,
        subject: subject,
        htmlContent: htmlContent,
        plainTextContent: '' // Assuming no plain text content in this case
    };

    this.emailService.sendEmailNew(emailRequest).subscribe(response => {
        console.log('Email sent successfully', response);
    }, error => {
        console.error('Error sending email', error);
    });
  }

  createEmailContent(before: ProductSDLC, after: ProductSDLC) {
    const primaryContactNames = this.sdlcPrimaryContacts.map(contact => contact.Name).join(', ');
  
    const formatDate = (date: Date | undefined) => {
      if (!date) return '';
      const d = new Date(date);
      const localDate = new Date(d.getTime() - (d.getTimezoneOffset() * 60000)); // Adjust for time zone offset
      const day = localDate.getDate().toString().padStart(2, '0');
      const month = (localDate.getMonth() + 1).toString().padStart(2, '0');
      const year = localDate.getFullYear().toString().slice(-2);
      return `${day}/${month}/${year}`;
    };
  
    const highlightIfChanged = (newValue: any, oldValue: any, isDate: boolean = false) => {
      const newFormatted = isDate ? formatDate(newValue) : newValue;
      const oldFormatted = isDate ? formatDate(oldValue) : oldValue;
      return newFormatted !== oldFormatted ? 'style="background-color: #e9e9e9;"' : '';
    };
  
    return `
      <h3>Product Threat Modelling</h3>
      <p>The following changes have been made to Threat Modelling for product ${this.productSDLC.ProductName}:</p>
      <table border="1">
        <thead>
          <tr>
            <th>Item</th>
            <th>New Value</th>
            <th>Previous Value</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Primary Contacts</td>
            <td>${primaryContactNames || ''}</td>
            <td>n/a</td>
          </tr>
          <tr ${highlightIfChanged(after.EnrolDate, before.EnrolDate, true)}>
            <td>Enrol Date</td>
            <td ${highlightIfChanged(after.EnrolDate, before.EnrolDate, true)}>${formatDate(after.EnrolDate)}</td>
            <td ${highlightIfChanged(after.EnrolDate, before.EnrolDate, true)}>${formatDate(before.EnrolDate)}</td>
          </tr>
          <tr ${highlightIfChanged(after.NextReviewDate, before.NextReviewDate, true)}>
            <td>Next Review Date</td>
            <td ${highlightIfChanged(after.NextReviewDate, before.NextReviewDate, true)}>${formatDate(after.NextReviewDate)}</td>
            <td ${highlightIfChanged(after.NextReviewDate, before.NextReviewDate, true)}>${formatDate(before.NextReviewDate)}</td>
          </tr>
          <tr ${highlightIfChanged(after.SDLCToolName, before.SDLCToolName)}>
            <td>SDLC Tool</td>
            <td ${highlightIfChanged(after.SDLCToolName, before.SDLCToolName)}>${after.SDLCToolName || ''}</td>
            <td ${highlightIfChanged(after.SDLCToolName, before.SDLCToolName)}>${before.SDLCToolName || ''}</td>
          </tr>
          <tr ${highlightIfChanged(after.SDLCTMStatusName, before.SDLCTMStatusName)}>
            <td>TM Status</td>
            <td ${highlightIfChanged(after.SDLCTMStatusName, before.SDLCTMStatusName)}>${after.SDLCTMStatusName || ''}</td>
            <td ${highlightIfChanged(after.SDLCTMStatusName, before.SDLCTMStatusName)}>${before.SDLCTMStatusName || ''}</td>
          </tr>
          <tr ${highlightIfChanged(after.Comment, before.Comment)}>
            <td>Comment</td>
            <td ${highlightIfChanged(after.Comment, before.Comment)}>${after.Comment || ''}</td>
            <td ${highlightIfChanged(after.Comment, before.Comment)}>${before.Comment || ''}</td>
          </tr>
        </tbody>
      </table>`;
  }
  
}
