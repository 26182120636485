<p-progressSpinner class="progress-spinner" *ngIf="isLoading"></p-progressSpinner>
<div class="flex gap-2 align-items-center">
    <h1>SAST</h1>
    <a href="https://irissoftware.sharepoint.com/sites/EngineeringIntranet/SitePages/SDLC-SAST.aspx" target="_blank">
        <p-button icon="pi pi-question"></p-button>
    </a>
</div>

<div>
    <div *ngIf="!hasSelectedOption && !hasApprovedSASTMessage">
        <p-messages [(value)]="messages2" [enableService]="false" [closable]="false"></p-messages>
    </div>
    <p-toast></p-toast>
    
    <div class="flex justify-content-between mb-2">
        <p-dropdown [options]="productSDLCRequestStatuses" [(ngModel)]="selectedStatus"
                    placeholder="Select Status" (onChange)="onStatusChange()" optionLabel="Name" optionValue="Name">
            <ng-template let-option pTemplate="item">
                <div [ngClass]="'sdlcrequeststatus-badge sdlc-' + option.Name">
                    {{ option.Name }}
                </div>
            </ng-template>
            <ng-template let-option pTemplate="selectedItem">
                <div [ngClass]="'sdlcrequeststatus-badge sdlc-' + option.Name">
                    {{ option.Name }}
                </div>
            </ng-template>
        </p-dropdown>
        <button pButton pRipple label="New Request" icon="pi pi-plus" class="p-button-success" *ngIf="(tier === 'Tier 0' || tier === 'Tier 1')" (click)="openNew()"></button>
    </div>
    <p-splitter [panelSizes]="[25, 75]" [style]="{ height: 'auto' }" styleClass="mb-5">
        <ng-template pTemplate let-splitter1>
            <div class="col flex flex-column align-items-center gap-3" style="height: 100%; overflow-y: auto;">
                <div [style.background-color]="sast.Id === selectedSastId ? '#e0ecff' : 'inherit'" class="card mb-0 border-round w-full" *ngFor="let sast of filteredProductSDLCSAST">
                    <div class="flex justify-content-between align-items-center mb-3 gap-5">
                        <div class="flex align-items-center justify-content-center border-round" [ngStyle]="{width: '6.5rem', height: '6.5rem'}">
                            <img [src]="getToolImageUrl(sast.SDLCToolName)" alt="" width="100%">
                        </div>
    
                        <div class="flex flex-column justify-content-center gap-1" [ngStyle]="{width: '8rem'}">
                            <span class="block text-900 font-medium text-xl">{{ sast.SDLCToolName }}</span>
                            <div class="text-500 font-medium">{{ sast.PracticeMaturityLevelName }}</div>
                            <div class="text-500 font-bold">{{ sast.Id }}</div>
                            <div [class]="'sdlcrequeststatus-badge sdlc-' + sast.SDLCRequestStatusName">{{ sast.SDLCRequestStatusName }}</div>
                        </div>
                        <div class="flex flex-column gap-2">
                            <button pButton icon="pi pi-chevron-right" class="p-button-outlined p-button-raised" (click)="showSastDetails(sast)"></button>
                            <button *appHasRole="['Lens.Admin']" pButton icon="pi pi-trash" class="p-button-outlined p-button-raised p-button-danger" (click)="deleteSast(sast)"></button>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
    
        <ng-template pTemplate let-splitter2>
            <div class="col flex justify-content-center">
                <div class="col-12">
                    <div class="card">
                        <div *ngIf="!hasSelectedOption && hasApprovedSASTMessage">
                            <p-messages [(value)]="messages" [enableService]="false" [closable]="true"></p-messages>
                        </div>
    
                        <div [hidden]="!hasSelectedOption">
                            <h5>SAST Form</h5>
                            <div class="p-fluid p-formgrid grid">
                            <div class="field col-12 md:col-6">
                                <label for="tmTool">Tool</label>
                                <p-dropdown appendTo="body" [(ngModel)]="editedProductSDLC.SDLCToolId" inputId="SDLCToolId" [options]="productSDLCTools" placeholder="Select" optionValue="Id">
                                    <ng-template pTemplate="selectedItem">
                                        <span *ngIf="editedProductSDLC.SDLCToolId">{{ getToolNameById(editedProductSDLC.SDLCToolId) }}</span>
                                    </ng-template>
                                    <ng-template let-option pTemplate="item">
                                        <span>{{option.Name}}</span>
                                    </ng-template>
                                </p-dropdown>
                            </div>
                            <div class="field col-12 md:col-4">
                                <label for="nextReviewDate">Next Review Date</label>
                                <p-calendar [(ngModel)]="editedProductSDLC.NextReviewDate" [showIcon]="true" id="nextReviewDate" dateFormat="dd/mm/yy"></p-calendar>
                            </div>
                            <div class="field col-12 md:col-6">
                                <label for="sastMaturity">Maturity</label>
                                <p-dropdown appendTo="body" [(ngModel)]="editedProductSDLC.PracticeMaturityLevelId" inputId="PracticeMaturityLevelId" [options]="productSDLCPracticeStatuses" placeholder="Select" optionValue="Id">
                                    <ng-template pTemplate="selectedItem">
                                        <span *ngIf="editedProductSDLC.PracticeMaturityLevelId">{{ getPracticeStatusNameById(editedProductSDLC.PracticeMaturityLevelId) }}</span>
                                    </ng-template>
                                    <ng-template let-option pTemplate="item">
                                        <span>{{option.Name}}</span>
                                    </ng-template>
                                </p-dropdown>
                            </div>
                            <div class="field col-12 md:col-12">
                                <p-confirmDialog></p-confirmDialog>
                                <label for="primaryContact">Primary Contacts</label>
                                <div>
                                    <ng-container *ngFor="let contact of sdlcPrimaryContacts">
                                        <p-chip styleClass="m-1">
                                            <img src="assets/layout/images/iris-circle-logo.png" alt="" width="60px" height="" class="shadow-2 border-circle">
                                            <span pTooltip={{contact.Mail}} tooltipPosition="bottom" [hideDelay]="2000">{{ contact.Name }}</span>
                                            <button *ngIf="(tier === 'Tier 0' || tier === 'Tier 1')" pButton icon="pi pi-times" class="p-button-rounded p-button-danger p-button-text" (click)="removeUser(contact)"></button>
                                        </p-chip>
                                    </ng-container>
                                    <button *ngIf="(tier === 'Tier 0' || tier === 'Tier 1')" pButton pRipple icon="pi pi-plus" class="p-button-success p-button-rounded" (click)="openUserDialog('addPrimaryContact')" [disabled]="!hasSelectedOption"></button>
                                </div>
                            </div>
                            <div class="field col-12 md:col-4">
                                <label for="enrolDate">Enrol Date</label>
                                <p-calendar [(ngModel)]="editedProductSDLC.EnrolDate" [showIcon]="true" id="eolDate" dateFormat="dd/mm/yy"></p-calendar>
                            </div>
                            <div class="field col-12 md:col-12">
                                <label for="comment">Comment</label>
                                <div><textarea [(ngModel)]="editedProductSDLC.Comment" pInputTextarea style="width: 100%" name="" id="" cols="30" rows="5"></textarea></div>
                            </div>
                                                </div>
                                                <div style="display: flex; justify-content: flex-end;">
                            <p-button *ngIf="(tier === 'Tier 0' || tier === 'Tier 1')" label="Submit" (click)="onSubmit()" [disabled]="!hasSelectedOption"></p-button>
                                                </div>
                        </div>
                </div>
            </div>
        </div>
        </ng-template>
    </p-splitter>
    
    
    
    <p-dialog [(visible)]="sastDialog" [style]="{width: '600px'}" header="Request new SAST Tool" [modal]="true" class="p-fluid">
        <ng-template pTemplate="content">
    
            <div class="field">
                <label for="tmTool">Tool</label>
                <p-dropdown appendTo="body" [(ngModel)]="productSAST.SDLCToolId" inputId="SDLCToolId" [options]="productSDLCTools" placeholder="Select" optionValue="Id" [required]="true" [ngClass]="{'ng-invalid ng-dirty' : submitted && !productSAST.SDLCToolId}">
                    <ng-template pTemplate="selectedItem">
                        <span *ngIf="productSAST.SDLCToolId">{{ getToolNameById(productSAST.SDLCToolId) }}</span>
                    </ng-template>
                    <ng-template let-option pTemplate="item">
                        <span>{{option.Name}}</span>
                    </ng-template>
                </p-dropdown>
            </div>
    
            <div class="field">
                <label for="sastMaturity">Maturity</label>
                <p-dropdown appendTo="body" [(ngModel)]="productSAST.PracticeMaturityLevelId" inputId="PracticeMaturityLevelId" [options]="productSDLCPracticeStatuses" placeholder="Select" optionValue="Id">
                    <ng-template pTemplate="selectedItem">
                        <span *ngIf="productSAST.PracticeMaturityLevelId">{{ getPracticeStatusNameById(productSAST.PracticeMaturityLevelId) }}</span>
                    </ng-template>
                    <ng-template let-option pTemplate="item">
                        <span>{{option.Name}}</span>
                    </ng-template>
                </p-dropdown>
            </div>
    
            <div class="field col-12 md:col-12">
                <p-confirmDialog></p-confirmDialog>
                <label for="primaryContact">Primary Contacts</label>
                <div>
                    <ng-container *ngFor="let contact of sdlcNewSastContacts">
                        <p-chip styleClass="m-1">
                            <img src="assets/layout/images/iris-circle-logo.png" alt="" width="60px" height="" class="shadow-2 border-circle">
                            <span pTooltip={{contact.mail}} tooltipPosition="bottom" [hideDelay]="2000">{{ contact.displayName }}</span>
                            <button pButton icon="pi pi-times" class="p-button-rounded p-button-danger p-button-text" (click)="removeNewSastContact(contact)"></button>
                        </p-chip>
                    </ng-container>
                    <button pButton pRipple icon="pi pi-plus" class="p-button-success p-button-rounded" (click)="openUserDialog('addNewSastContact')"></button>
                </div>
            </div>
    
            <div class="field">
                <label for="enrolDate">Enrol Date</label>
                <p-calendar appendTo="body" [(ngModel)]="productSAST.EnrolDate" [showIcon]="true" id="eolDate" dateFormat="dd/mm/yy"></p-calendar>
            </div>
    
            <div class="field">
                <label for="nextReviewDate">Next Review Date</label>
                <p-calendar appendTo="body" [(ngModel)]="productSAST.NextReviewDate" [showIcon]="true" id="nextReviewDate" dateFormat="dd/mm/yy"></p-calendar>
            </div>
    
            <div class="field col-12 md:col-12">
                <label for="comment">Comment</label>
                <div><textarea [(ngModel)]="productSAST.Comment" pInputTextarea style="width: 100%" name="" id="" cols="30" rows="5"></textarea></div>
            </div>
        </ng-template>
    
        <ng-template pTemplate="footer">
            <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="hideDialog()"></button>
            <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text" (click)="saveNewSAST()"></button>
        </ng-template>
    </p-dialog>
</div>

<p-dialog [(visible)]="userDialog" [modal]="true" [style]="{width: '750px'}" header="Add new user" [contentStyle]="{'overflow': 'visible'}" [modal]="true" class="p-fluid">
    <ng-template pTemplate="content">
        <div class="field">
            <p-dropdown [options]="users" scrollHeight="300px" [(ngModel)]="selectedUser" [style]="{'width':'100%'}" optionLabel="displayName" [filter]="true" (onFilter)="searchUsers($event)" [showClear]="true" placeholder="Select a User">
                <ng-template pTemplate="selectedItem">
                    <div class="flex align-items-center gap-2" *ngIf="selectedUser">
                        <div>{{ selectedUser.displayName }}</div>
                    </div>
                </ng-template>
                <ng-template let-user pTemplate="item">
                    <div class="flex align-items-center gap-2">
                        <img src="assets/layout/images/iris-circle-logo.png" alt="" width="60px" height="" class="shadow-2 border-circle">
                        <div class="flex w-full gap-2 flex-column p-4">
                            <div>{{ user.displayName }}</div>
                            <div>({{ user.mail }})</div>
                        </div>
                    </div>
                </ng-template>
            </p-dropdown>
        </div>
    </ng-template>

    <ng-template pTemplate="footer">
        <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="hideUserDialog()"></button>
        <button *ngIf="userDialogAction === 'addPrimaryContact'" pButton pRipple label="Add" icon="pi pi-check" class="p-button-text" (click)="addPrimaryContact()"></button>
        <button *ngIf="userDialogAction === 'addNewSastContact'" pButton pRipple label="Add" icon="pi pi-check" class="p-button-text" (click)="addNewSastContact()"></button>
    </ng-template>
</p-dialog>

<div *ngIf="!isLoading && !(tier === 'Tier 0' || tier === 'Tier 1') || tier == ''">
    <div class="alert alert-danger">
      <strong>Read only mode</strong> Only Tier 0 and Tier 1 products can request SAST.
    </div>
</div>