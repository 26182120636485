<h1>{{practiceName}} - Minimum Maturity Levels</h1>

<p-messages [(value)]="messages" [enableService]="false" [closable]="true"></p-messages>

<p-table [value]="tiers" *ngIf="maturityLevels.length > 0">
  <ng-template pTemplate="header">
    <tr>
      <th>Tier</th>
      <th>Minimum Maturity Level</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-item let-rowIndex="rowIndex">
    <tr>
      <td>{{ item.Name }}</td>
      <td>
        <p-dropdown appendTo="body"
                    [options]="maturityLevels"
                    placeholder="Select"
                    optionValue="Id"
                    optionLabel="Name"
                    class="uniform-width-input"
                    [style]="{'minWidth':'90%', 'maxWidth': '400px'}"
                    [(ngModel)]="selectedMaturityLevel[item.Id]"
                    (onChange)="onMaturityLevelChange($event, item.Id)">
        </p-dropdown>
      </td>
    </tr>
  </ng-template>
</p-table>

<div class="flex justify-content-end mt-2" *ngIf="maturityLevels.length > 0">
  <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-success mb-2" (click)="saveMaturities()"></button>
</div>

<div *ngIf="maturityLevels.length == 0">
  You need to create Practice Maturity Levels first.
</div>

