import { Component, OnInit } from '@angular/core';
import { ProductService } from 'src/app/layout/service/product/products.service';
import { ReportRecipientsService } from 'src/app/layout/service/product/report-recipients.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { MsalService } from '@azure/msal-angular';
import { UserSubscriptionRequestDto } from 'src/app/api/product';

@Component({
  selector: 'app-report-subscription',
  templateUrl: './report-subscription.component.html',
  styleUrls: ['./report-subscription.component.scss'],
  providers: [MessageService],
})
export class ReportSubscriptionComponent implements OnInit {
  subscriptions: any[] = [];
  suites: any[] = [];
  products: any[] = [];
  reportTypes: any[] = [];
  
  userGraphId: string = '';
  userName: string = '';
  userEmail: string = '';
  
  loading: boolean = false;

  // Dialog & Form State
  addSubscriptionDialog: boolean = false;
  step: number = 1;
  selectedType: string = ''; // 'Product' or 'Suite'
  selectedProductOrSuite: any = null;
  selectedReportType: any = null;

  typeOptions: any[] = [
    { label: 'Product', value: false }, // SuiteId is false for Product
    { label: 'Suite', value: true },   // SuiteId is true for Suite
  ];

  constructor(
    private reportRecipientService: ReportRecipientsService,
    private productService: ProductService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private msalService: MsalService
  ) {}

  ngOnInit(): void {
    this.getUserGraphId();
    this.getAllProducts();
    this.getAllSuites();
    this.getAllReportTypes();
  }

  getUserGraphId() {
    const account = this.msalService.instance.getAllAccounts()[0];
    if (account && account.idTokenClaims) {
      this.userGraphId = account.idTokenClaims['oid'] || '';
      this.userName = account.idTokenClaims['name'] || '';
      this.userEmail = (account.idTokenClaims['preferred_username'] || account.idTokenClaims['email'] as string) || '';
      this.getUserSubscriptions();
    } else {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Failed to retrieve user information.',
      });
    }
  }

  getAllProducts() {
    this.productService.getAllProductsTopbar().subscribe((data) => {
      this.products = data;
    });
  }

  getAllSuites() {
    this.productService.getAllSuites().subscribe((data) => {
      this.suites = data;
    });
  }

  getAllReportTypes() {
    this.reportRecipientService.getAllReportTypes().subscribe((data) => {
      this.reportTypes = data;
    });
  }

  getUserSubscriptions() {
    console.log(this.userGraphId);
    console.log(this.userName);
    console.log(this.userEmail);
    this.loading = true;
    this.reportRecipientService.getUserSubscriptions(this.userGraphId).subscribe(
      (data: any) => {
        this.subscriptions = data?.value || [];
        this.loading = false;
      },
      (error) => {
        this.loading = false;
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Failed to load subscriptions.',
        });
      }
    );
  }

  confirmUnsubscribe(subscriptionId: number) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to unsubscribe from this report?',
      accept: () => {
        this.unsubscribe(subscriptionId);
      },
    });
  }
  
  unsubscribe(subscriptionId: number) {
    this.reportRecipientService.deleteReportRecipient(subscriptionId).subscribe(
      () => {
        this.subscriptions = this.subscriptions.filter(
          (sub) => sub.Id !== subscriptionId
        );
        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Unsubscribed successfully.',
        });
      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Failed to unsubscribe.',
        });
      }
    );
  }

  openAddSubscriptionDialog() {
    this.addSubscriptionDialog = true;
    this.step = 1;
    this.selectedType = '';
    this.selectedProductOrSuite = null;
    this.selectedReportType = null;
  }

  addSubscription() {
    const newSubscription: UserSubscriptionRequestDto = {
      UserGraphId: this.userGraphId,
      Name: this.userName,
      Mail: this.userEmail,
      ProductId: this.selectedType === 'Product' ? this.selectedProductOrSuite?.Id : null,
      SuiteId: this.selectedType === 'Suite' ? this.selectedProductOrSuite?.Id : null,
      ReportTypeId: this.selectedReportType?.Id,
      IsToEmail: true,
    };

    this.reportRecipientService.addReportRecipient(newSubscription).subscribe(
      () => {
        this.addSubscriptionDialog = false;
        this.getUserSubscriptions(); // Refresh subscriptions
        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Subscription added successfully.',
        });
      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Failed to add subscription. It may already exist.',
        });
      }
    );
  }

  onDialogClose() {
    this.addSubscriptionDialog = false;
    this.resetForm();
  }
  
  resetForm() {
    this.step = 1;
    this.selectedType = '';
    this.selectedProductOrSuite = null;
    this.selectedReportType = null;
  }

  filterReportType(event: any) {
    // Apply the filter based on the selected Report Type
    // In this case, you can use the selectedReportType to filter your subscriptions.
    const filterValue = event.value ? event.value.Name : ''; // Get the selected report type name

    // Optionally, apply the filter to the subscriptions array here.
    // For example:
    if (filterValue) {
      this.subscriptions = this.subscriptions.filter(subscription => 
        subscription.ReportTypeName.includes(filterValue)
      );
    }
  }
}
