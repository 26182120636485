import { LogMessage, LogMessageContentUser, LogMessageContent } from "src/app/api/log";
import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { Injectable } from "@angular/core";

type ProfileType = {
  displayName?: string,
  surname?: string,
  userPrincipalName?: string,
  id?: string,
  mail?: string
};

type IPPayload = {
  ip: string
};

export type LogMessageStatus = 'INFO' | 'WARN' | 'ERROR';

export type LogMessageAction = 'CREATE' | 'UPDATE' | 'DELETE';

@Injectable(
    {
        providedIn: 'root'
    }
)

export class LogService {
  baseApiUrl: string = environment.baseApiUrl;
  GRAPH_ENDPOINT : string ='https://graph.microsoft.com/v1.0/me';
  profile?: ProfileType;
  ip?: string;

  constructor(private http: HttpClient) { }

  async sendInfoMessage(action: LogMessageAction, resource: string, newState?: any, oldState?: any) : Promise<void> {

    try{
      this.profile = await this.getProfile() ?? {};
    } catch (error) {
      console.error("Error retrieving profile:", error);
    }

    try{
      this.ip = await this.fetchIP();
    }catch (error) {
      console.error("Error retrieving IP address");
    }
    
    console.log("Building log objects");

    try{
      const logMessageUser: LogMessageContentUser = {
        name: this.profile?.displayName ?? "Unknown",
        email: this.profile?.mail ?? "Unknown",
      };

      const logMessageContent: LogMessageContent = {
        action: action,
        resource: resource,
        newState: newState,
        oldState: oldState,
        user: logMessageUser,
      };

      const timestamp = new Date().toISOString();

      const logMessage: LogMessage = {
        hostname: environment.baseApiUrl,
        timestamp: timestamp,
        ddsource: 'irislens',
        service: 'IRIS Lens',
        status: "INFO",
        "network.client.ip": this.ip,
        message: logMessageContent        
      };

      this.sendLog(logMessage).subscribe({
        next: () => {
          console.log("Log message sent successfully");
        },
        error: (error) => {
          console.error("Error sending log message:", error);
        }
      });

    } catch (error) {
      console.error("Error building log message:", error)      
    }
     
  }


  fetchIP(): Promise<string> {
    return new Promise((resolve, reject) => {
      this.http.get<IPPayload>("https://api.ipify.org?format=json")
        .subscribe({
          next: (payload: IPPayload) => {
            resolve(payload.ip);
          },
          error: (error) => {
            console.error(`Error retrieving IP address: ${error.message}`);
            resolve("");
          }
        });
    });
  }


  sendLog(message: LogMessage): Observable<any> {
    console.log("Sending Message");
    console.log(message);
    return this.http.post<any>(`${this.baseApiUrl}/api/log`, message);
  }

    getProfile(): Promise<ProfileType | null> {
    return new Promise((resolve, reject) => {
      this.http.get<ProfileType>(this.GRAPH_ENDPOINT)
        .subscribe({
          next: (payload: ProfileType) => {
            resolve(payload);
          },
          error: (error) => {
            console.error(`Error retrieving profile: ${error.message}`);
            resolve(null);
          }
        });
    });
  }
}
