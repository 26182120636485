<p-toast></p-toast>

<div>
    <h2>Upload Product Pentest Findings (XLSX File)</h2>
    <input type="file" (change)="onFileSelected($event)" />
    <button (click)="onUpload()">Upload</button>

    <a href="../../../../../assets/ProductPTT-Ingestion.xlsx" download>Download Template</a>
</div>


<div class="mt-8">
    <h2>Upload One Day Pentest Findings (XLSX File)</h2>
    <input type="file" (change)="onFileSelected($event)" />
    <button (click)="onUploadOneDay()">Upload</button>

    <a href="../../../../../assets/ProductOneDayPTT-Ingestion.xlsx" download>Download Template</a>
</div>