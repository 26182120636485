import { Component, OnInit } from '@angular/core';
import { PracticeMaturityLevel, PracticeMaturityLevelRequestDto } from 'src/app/api/sdlc-maturity';
import { ProductSDLCService } from 'src/app/layout/service/product/product-sdlc.service';
import { ActivatedRoute } from '@angular/router';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Message, MessageService } from 'primeng/api';

@Component({
  selector: 'app-practicematuritylevels',
  templateUrl: './practicematuritylevels.component.html'
})
export class PracticeMaturityLevelsComponent implements OnInit {

  practiceId: string = '';
  practiceName: string = '';

  messages: Message[] = [];

  newLevelDialog: boolean = false;
  newName: string = '';
  submitted: boolean = false;


  maturityLevels: PracticeMaturityLevel[] = [];

  constructor(private route: ActivatedRoute, private sdlcService: ProductSDLCService, private messageService: MessageService) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.practiceId = params['practiceId'];

      this.sdlcService.getSDLCPracticeById(this.practiceId).subscribe(
        (data: any) => {
          this.practiceName = data.Name;

          this.sdlcService.getPracticeMaturityLevels(this.practiceId).subscribe(
            (data: PracticeMaturityLevel[]) => {
              this.maturityLevels = data.sort((a, b) => {
                if (a.Order === undefined && b.Order === undefined) return 0;
                if (a.Order === undefined) return 1; // Treat null as highest value
                if (b.Order === undefined) return -1; // Treat null as highest value
                return a.Order - b.Order;
              });
            }
          );
        });
    });
  }

  onDrop(event: CdkDragDrop<any>) {
    moveItemInArray(this.maturityLevels, event.previousIndex, event.currentIndex);

    // Update order property
    this.maturityLevels.forEach((item, index) => {
      item.Order = index;

      if (item.Id != undefined) {
        this.sdlcService.updatePracticeMaturityLevel(item.Id, item).subscribe(
          (data: any) => {
          });
      }

    });


  }

  openNewDialog() {
    this.newLevelDialog = true;
  }
  hideDialog() {
    this.newLevelDialog = false;
  }

  deleteMaturityLevel(id: number) {

    this.sdlcService.deletePracticeMaturityLevel(id).subscribe(
      (data: any) => {
        this.maturityLevels = this.maturityLevels.filter(level => level.Id !== id);
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Maturity level deleted' });

        this.maturityLevels.forEach((item, index) => {
          item.Order = index;
        });
      });
  }

  addNewMaturityLevel() {

    this.submitted = true;

    if (!this.newName) {
      return;
    }

    const newLevel: PracticeMaturityLevelRequestDto = {
      Name: this.newName,
      SDLCPracticeId: this.practiceId,
      Order: this.maturityLevels.length
    };

    this.sdlcService.createPracticeMaturityLevel(newLevel).subscribe(
      (data: any) => {
        const newMatLevel: PracticeMaturityLevel = {
          Id: data.id,
          Name: data.name,
          Order: data.order,
          SDLCPracticeId: data.sdlcPracticeId
        }

        this.maturityLevels.push(newMatLevel);
        this.newName = '';
        this.newLevelDialog = false;
        this.submitted = false;

        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Maturity level added' });
      });
  }
}
