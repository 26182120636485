import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class FavouritesService {
  constructor(private cookieService: CookieService) {}

  getProductFavourites(): any[] {
    const favourites = this.cookieService.get('productFavourites');
    return favourites ? favourites.split(',') : [];
  }

  saveProductFavourites(favourites: any[]) {
    const expirationDate = new Date();
    expirationDate.setFullYear(expirationDate.getFullYear() + 10);
    this.cookieService.set('productFavourites', favourites.join(','), expirationDate);
  }

  getSuiteFavourites(): any[] {
    const favourites = this.cookieService.get('suiteFavourites');
    return favourites ? favourites.split(',') : [];
  }

  saveSuiteFavourites(favourites: any[]) {
    const expirationDate = new Date();
    expirationDate.setFullYear(expirationDate.getFullYear() + 10);
    this.cookieService.set('suiteFavourites', favourites.join(','), expirationDate);
  }

  toggleFavourite(favourites: any[], id: any): any[] {
    const index = favourites.indexOf(id.toString());
    if (index !== -1) {
      favourites.splice(index, 1);
    } else {
      favourites.push(id.toString());
    }
    return favourites;
  }

  isFavourite(favourites: any[], id: any): boolean {
    return favourites.includes(id.toString());
  }
}