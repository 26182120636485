<h1>NFR Categories</h1>

<p-messages [(value)]="messages" [enableService]="false" [closable]="true"></p-messages>

<div class="flex justify-content-end mb-2">
  <button pButton pRipple label="Add Category" icon="pi pi-plus" class="p-button-success mb-2" (click)="openNewDialog()"></button>
</div>

<div>
  <p-table [value]="categories">
    <ng-template pTemplate="header">
      <tr>
        <th>ID</th>
        <th>Name</th>
        <th>Priority</th>
        <th>Actions</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-item let-rowIndex="rowIndex">
      <tr>
        <td>{{ item.Id }}</td>
        <td>{{ item.Name }}</td>
        <td>{{ item.Priority }}</td>
        <td>
          <button type="button" pbutton="" icon="pi pi-pencil" class="p-element p-button p-component p-button-icon-only mr-2" ng-reflect-icon="pi pi-pencil" title="Update Category" (click)="openUpdateDialog(item)">
            <span class="p-button-icon pi pi-pencil" aria-hidden="true"></span>
          </button>
          <button type="button" pbutton="" icon="pi pi-trash" class="p-element p-button-danger p-button p-component p-button-icon-only" ng-reflect-icon="pi pi-trash" title="Delete Category" (click)="deleteCategory(item)">
            <span class="p-button-icon pi pi-trash" aria-hidden="true"></span>
          </button>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>

<p-dialog [(visible)]="newCategoryDialog" [modal]="true" [style]="{width: '750px'}" header="Add new NFR Category" [contentStyle]="{'overflow': 'visible'}" [modal]="true" class="p-fluid">
  <ng-template pTemplate="content">
    <div class="field">
      <input type="text" pInputText placeholder="Name" [(ngModel)]="newName" [ngClass]="{'ng-invalid ng-dirty' : submitted && !newName}">
      <small class="ng-dirty ng-invalid" *ngIf="submitted && !newName">Name is required.</small>
    </div>
    <div class="field">
      <input type="text" pInputText placeholder="Priority" [(ngModel)]="newPriority" [ngClass]="{'ng-invalid ng-dirty' : submitted && !newPriority}">
      <small class="ng-dirty ng-invalid" *ngIf="submitted && !newPriority">Priority is required.</small>
    </div>
  </ng-template>
  <ng-template pTemplate="footer">
    <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="hideDialog()"></button>
    <button pButton pRipple label="Add" icon="pi pi-check" class="p-button-text" (click)="addNewCategory()"></button>
  </ng-template>

</p-dialog>

<p-dialog [(visible)]="UpdateCategoryDialog" [modal]="true" [style]="{width: '750px'}" header="Update an NFR Category" [contentStyle]="{'overflow': 'visible'}" [modal]="true" class="p-fluid">
  <ng-template pTemplate="content">
    <div class="field">
      <input type="text" pInputText placeholder="Name" [(ngModel)]="existingId" readonly="readonly">
    </div>
    <div class="field">
      <input type="text" pInputText placeholder="Name" [(ngModel)]="newName" [ngClass]="{'ng-invalid ng-dirty' : submitted && !newName}">
      <small class="ng-dirty ng-invalid" *ngIf="submitted && !newName">Name is required.</small>
    </div>
    <div class="field">
      <input type="text" pInputText placeholder="Priority" [(ngModel)]="newPriority" [ngClass]="{'ng-invalid ng-dirty' : submitted && !newPriority}">
      <small class="ng-dirty ng-invalid" *ngIf="submitted && !newPriority">Priority is required.</small>
    </div>
  </ng-template>
  <ng-template pTemplate="footer">
    <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="hideExistingDialog()"></button>
    <button pButton pRipple label="Update" icon="pi pi-check" class="p-button-text" (click)="updateCategory()"></button>
  </ng-template>

</p-dialog>
