import { Component, OnInit, ViewChild, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Message, MessageService } from 'primeng/api';
import { ProductService } from 'src/app/layout/service/product/products.service';
import { ProductTechSpecService } from 'src/app/layout/service/product/product-tech-spec.service';
import { ProductMetricService } from 'src/app/layout/service/product/product-metric.service';
import { Alias, Product } from "src/app/api/product";
import { ProductTechSpec } from "src/app/api/product-tech-spec";
import { ProductMetric } from "src/app/api/product-metric";
import { TabView } from 'primeng/tabview';
import { LogService } from 'src/app/layout/service/log.service';
import { ProductTechPortfolio } from 'src/app/api/product-tech-portfolio';
import { ProductTechPortfolioService } from 'src/app/layout/service/product/product-tech-portfolio.service';
import { AddEntryDialogComponent } from '../../reusable/dialog/add-entry-dialog.component';
import { ApprovedSoftwareService } from 'src/app/layout/service/approved-software/approved-software.service';
import { SoftwareDto } from 'src/app/api/approved-software';
import { ProductSoftwareDto } from 'src/app/api/approved-software';
import { catchError, debounce, forkJoin, of, tap } from 'rxjs';
import { MsalService } from '@azure/msal-angular';

@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html'
})
export class ProductDetailsComponent implements OnInit, AfterViewInit {
  productId: string = '';
  selectedTab: string = '';
  product: Product | null = null;
  productTechSpec: ProductTechSpec | null = null;
  productMetric: any | null = null;
  productTechPortfolio: ProductTechPortfolio | null = null;
  messages: Message[] = [];
  aliases: string = '';
  isBasicLoaded: boolean = false;
  isMetricLoaded: boolean = false;
  isTechLoaded: boolean = false;
  isTechPortfolioLoaded: boolean = false;

  //Switches for putting panels into edit mode
  editBasicDetails: boolean = false;
  editDescription: boolean = false;
  editLogo: boolean = false;
  editStatus: boolean = false;
  editHosting: boolean = false;
  editBuilding: boolean = false;
  editSecMetrics: boolean = false;
  editCE: boolean = false;
  editSDLC: boolean = false;
  editPenTest: boolean = false;

  editProcess: boolean = false;
  editStrategy: boolean = false;
  editTechStack: boolean = false;
  editCustomisation: boolean = false;


  editedProduct: any = null;
  editedProductMetric: any = null;
  editedProductTechSpec: any = null;
  editedProductTechPortfolio: any = null;
  editingAliases: string[] = [];  
  editedDescription: string = '';  
  editedLogo: string = '';
  editedStatus: boolean = false;
  editedEoLDate: Date | undefined = undefined;
  editedDistributions: any[] = [];
  editedPlatforms: any[] = [];
  editedTechStacks: any[] = [];
  editedProviders: any[] = [];
  editedEnvironments: any[] = [];
  editedHostingDescription: string = '';
  editedSourceControlTypes: any[] = [];
  editedSourceControlProviders: any[] = [];
  editedBuildTools: any[] = [];
  editedDeploymentTools: any[] = [];
  editedBacklogTools: any[] = [];
  editedPipelineDescription: string = '';
  editedFunctionTypes: any[] = [];
  editedVulnerabilityTools: any[] = [];
  editedAuthenticationMechanisms: any[] = [];
  editedCEStatus: boolean = false;
  editedCEDescription: string = '';
  editedSDLCStatus: boolean = false;
  editedComponentManagementTool: number = 0;
  editedNumberOfComponents: number = 0;

  editedCustomerOlderVersion: string = '';
  editedJMLProcess: string = '';
  editedUserReportProcess: string = '';
  editedTechDebt: string = '';
  editedRandDResourceAllocation: string = '';
  editedRoadmapInitiatives: string = '';

  editedCustomizationEnabledMaintainers: string = '';
  editedDegreeOfCustomization: string = '';

  editedTenancy: number = 0;
  editedAutomatedHA: number = 0;
  editedUXUIModernisation: number = 0;
  editedCustomizationEnabled: number = 0;
  editedReleaseCadence: number = 0;
  editedLevelOfTestAutomation: number = 0;
  editedCodeVolatility: number = 0;
  editedNetNewOrExistingCode: number = 0;

  editedDatabases: any[] = [];
  editedApplications: any[] = [];
  editedUXUIs: any[] = [];
  editedProgrammingLanguages: any[] = [];

  suites: any[] = [];
  divisions: any[] = [];
  tiers: any[] = [];
  productTypes: any[] = [];
  distributions: any[] = [];
  platforms: any[] = [];
  techStacks: any[] = [];
  providers: any[] = [];
  environments: any[] = [];
  sourceControlTypes: any[] = [];
  sourceControlProviders: any[] = [];
  buildTools: any[] = [];
  deploymentTools: any[] = [];
  backlogTools: any[] = [];
  functionTypes: any[] = [];
  productDataTypes: any[] = [];
  secMonitoringTools: any[] = [];
  vulnerabilityTools: any[] = [];
  classifications: any[] = [];
  componentManagementTools: any[] = [];
  authenticationMechanisms: any[] = [];
  
  countries: any[] = [];
  tenancies: any[] = [];
  automatedHAs: any[] = [];
  uxuiModernisations: any[] = [];
  customizationsEnabled: any[] = [];
  releaseCadences: any[] = [];
  levelOfTestAutomations: any[] = [];
  codeVolatilities: any[] = [];
  netNewOrExistingCodes: any[] = [];
  databases: any[] = [];
  applications: any[] = [];
  uxuis: any[] = [];
  programmingLanguages: any[] = [];

  // Tech Portfolio
  selectedDatabases: any[] = [];
  selectedApplications: any[] = [];
  selectedUXUIs: any[] = [];
  selectedProgrammingLanguages: any[] = [];

  selectedDistributions: any[] = [];
  selectedPlatforms: any[] = [];
  selectedTechStacks: any[] = [];
  selectedProviders: any[] = [];
  selectedEnvironments: any[] = [];
  selectedSourceControlTypes: any[] = [];
  selectedSourceControlProviders: any[] = [];
  selectedBuildTools: any[] = [];
  selectedDeploymentTools: any[] = [];
  selectedBacklogTools: any[] = [];
  selectedFunctionTypes: any[] = [];
  selectedVulnerabilityTools: any[] = [];
  selectedAuthenticationMechanisms: any[] = [];

  productSoftwareLinks: ProductSoftwareDto[] = [];

  submitted: boolean = false;

  userGraphId: string = '';
  userName: string = '';
  userEmail: string = '';



  @ViewChild('tabView', { static: true }) tabView!: TabView;
  @ViewChild(AddEntryDialogComponent) addEntryDialog!: AddEntryDialogComponent; // Get reference to the dialog

  constructor(
    private route: ActivatedRoute,
    private productService: ProductService,
    private productTechSpecService: ProductTechSpecService,
    private productMetricService: ProductMetricService,
    private productTechPortfolioService: ProductTechPortfolioService,
    private approvedSoftwareService: ApprovedSoftwareService,
    private messageService: MessageService,
    private cdr: ChangeDetectorRef,
    private logService: LogService,
    private msalService: MsalService
  ) { }

  ngOnInit(): void {
    this.getUserGraphId();

    this.route.queryParams.subscribe(params => {
      this.selectedTab = params['tab'];
    });

    this.route.params.subscribe(params => {
      this.productId = params['id'];  
      this.productService.getProductById(this.productId).subscribe((response: any) => {
        this.product = response;
        if (this.product) {
          this.product.EoLDate = this.product.EoLDate ? new Date(this.product.EoLDate) : undefined;
          this.aliases = this.displayAliases(this.product.Aliases);
          this.isBasicLoaded = true;
        }        
        if (this.product?.ProductTechSpec?.Id) {
          this.productTechSpecService.getProductTechSpecById(this.product.ProductTechSpec.Id).subscribe((tsresponse: any) => {
            this.productTechSpec = tsresponse;
            if (this.productTechSpec) {
              this.selectedDistributions = this.productTechSpec.TechSpecDistributions?.map(item => item.DistributionId) ?? [];
              this.selectedPlatforms = this.productTechSpec.TechSpecPlatforms?.map(item => item.PlatformId) ?? [];
              this.selectedTechStacks = this.productTechSpec.TechSpecStacks?.map(item => item.TechStackId) ?? [];
              this.selectedProviders = this.productTechSpec.TechSpecHostingProviders?.map(item => item.HostingProviderId) ?? [];
              this.selectedEnvironments = this.productTechSpec.TechSpecEnvironments?.map(item => item.EnvironmentTypeId) ?? [];
              this.selectedSourceControlTypes = this.productTechSpec.TechSpecSourceControlTypes?.map(item => item.SourceControlTypeId) ?? [];
              this.selectedAuthenticationMechanisms = this.productTechSpec.TechSpecAuthenticationMechanisms?.map(item => item.AuthenticationMechanismId) ?? [];
              this.isTechLoaded = true;
            }
          });
        }
        if (this.product?.ProductMetric?.Id) {
          this.productMetricService.getProductMetricById(this.product.ProductMetric.Id).subscribe((pmresponse: any) => {
            this.productMetric = pmresponse;
            if (this.productMetric) {
              this.selectedFunctionTypes = this.productMetric.ProductMetricFunctionTypes?.map((item: any) => item.FunctionTypeId) ?? [];
              this.selectedVulnerabilityTools = this.productMetric.ProductMetricVulnerabilityManagements?.map((item: any) => item.VulnerabilityManagementId) ?? [];
              this.isMetricLoaded = true;
            }
          });
        }
        if (this.product?.ProductTechPortfolio?.Id) {
          this.productTechPortfolioService.getProductTechPortfolioById(this.product.ProductTechPortfolio.Id).subscribe((ptpresponse: any) => {
            this.productTechPortfolio = ptpresponse;
            if (this.productTechPortfolio) {
              this.isTechPortfolioLoaded = true;
            }
          });
        }
      });

      this.fetchProductSoftwareLinks();

      this.productService.getAllSuites().subscribe((suites) => {
        this.suites = suites.sort((a, b) => {
          const nameA = a.Name ?? '';
          const nameB = b.Name ?? '';
          return nameA.localeCompare(nameB);
        });
      });
      this.productService.getAllDivisions().subscribe((divisions) => {
        this.divisions = divisions.sort((a, b) => {
          const nameA = a.Name ?? '';
          const nameB = b.Name ?? '';
          return nameA.localeCompare(nameB);
        });
      });
      this.productService.getAllProductTypes().subscribe((productTypes) => {
        this.productTypes = productTypes;
      });
      this.productMetricService.getAllTiers().subscribe((tiers) => {
        this.tiers = tiers;
      });
      this.productMetricService.getAllProductDataTypes().subscribe((types) => {
        this.productDataTypes = types;
      });
      this.productTechSpecService.getAllDistributions().subscribe((distributions) => {
        this.distributions = distributions.sort((a, b) => {
          const nameA = a.Name ?? '';
          const nameB = b.Name ?? '';
          return nameA.localeCompare(nameB);
        });
      });
      this.productTechSpecService.getAllPlatforms().subscribe((platforms) => {
        this.platforms = platforms.sort((a:any, b:any) => {
          const nameA = a.Name ?? '';
          const nameB = b.Name ?? '';
          return nameA.localeCompare(nameB);
        });
      });
      this.productTechSpecService.getAllTechStacks().subscribe((techStacks) => {
        this.techStacks = techStacks.sort((a: any, b: any) => {
          const nameA = a.Name ?? '';
          const nameB = b.Name ?? '';
          return nameA.localeCompare(nameB);
        });
      });
      this.productTechSpecService.getAllHostingProviders().subscribe((providers) => {
        this.providers = providers.sort((a: any, b: any) => {
          const nameA = a.Name ?? '';
          const nameB = b.Name ?? '';
          return nameA.localeCompare(nameB);
        });
      });
      this.productTechSpecService.getAllEnvironmentTypes().subscribe((environments) => {
        this.environments = environments.sort((a: any, b: any) => {
          const nameA = a.Name ?? '';
          const nameB = b.Name ?? '';
          return nameA.localeCompare(nameB);
        });
      });
      this.productTechSpecService.getAllSourceControlTypes().subscribe((item) => {
        this.sourceControlTypes = item.sort((a: any, b: any) => {
          const nameA = a.Name ?? '';
          const nameB = b.Name ?? '';
          return nameA.localeCompare(nameB);
        });
      });

      this.productMetricService.getAllFunctionTypes().subscribe((item) => {
        this.functionTypes = item.sort((a: any, b: any) => {
          const nameA = a.Name ?? '';
          const nameB = b.Name ?? '';
          return nameA.localeCompare(nameB);
        });
      });
      this.productMetricService.getAllProdSecMonitorings().subscribe((item) => {
        this.secMonitoringTools = item.sort((a: any, b: any) => {
          const nameA = a.Name ?? '';
          const nameB = b.Name ?? '';
          return nameA.localeCompare(nameB);
        });
      });
      this.productMetricService.getAllVulnerabilityManagements().subscribe((item) => {
        this.vulnerabilityTools = item.sort((a: any, b: any) => {
          const nameA = a.Name ?? '';
          const nameB = b.Name ?? '';
          return nameA.localeCompare(nameB);
        });
      });
      this.productMetricService.getAllProductContributions().subscribe((item) => {
        this.classifications = item.sort((a: any, b: any) => {
          const nameA = a.Name ?? '';
          const nameB = b.Name ?? '';
          return nameA.localeCompare(nameB);
        });
      });
      this.productMetricService.getAllComponentManagements().subscribe((item) => {
        this.componentManagementTools = item.sort((a: any, b: any) => {
          const nameA = a.Name ?? '';
          const nameB = b.Name ?? '';
          return nameA.localeCompare(nameB);
        });
      });
      this.productTechSpecService.getAllAuthenticationMechanisms().subscribe((item) => {
        this.authenticationMechanisms = item.sort((a: any, b: any) => {
          const nameA = a.Name ?? '';
          const nameB = b.Name ?? '';
          return nameA.localeCompare(nameB);
        });
      });


    forkJoin({
      databases: this.approvedSoftwareService.getByTechGroupName("Data Platform").pipe(
        catchError(error => {
          console.error("Error fetching databases", error);
          return of([]); // Return empty array on error
        })
      ),
      applications: this.approvedSoftwareService.getByTechGroupName("Application").pipe(
        catchError(error => {
          console.error("Error fetching applications", error);
          return of([]);
        })
      ),
      programmingLanguages: this.approvedSoftwareService.getByTechGroupName("Programming Languages").pipe(
        catchError(error => {
          console.error("Error fetching programming languages", error);
          return of([]);
        })
      ),
      uxuis: this.approvedSoftwareService.getByTechGroupName("Front-End / Mobile").pipe(
        catchError(error => {
          console.error("Error fetching UX/UI", error);
          return of([]);
        })
      ),
      sourceControlProviders: this.approvedSoftwareService.getByTechGroupName("Source Control Providers").pipe(
        catchError(error => {
          console.error("Error fetching Source Control Providers", error);
          return of([]);
        })
      ),
      buildTools: this.approvedSoftwareService.getByTechGroupName("Build Tools").pipe(
        catchError(error => {
          console.error("Error fetching Build Tools", error);
          return of([]);
        })
      ),
      deploymentTools: this.approvedSoftwareService.getByTechGroupName("Deployment").pipe(
        catchError(error => {
          console.error("Error fetching Deployment Tools", error);
          return of([]);
        })
      ),
      backlogTools: this.approvedSoftwareService.getByTechGroupName("Backlog").pipe(
        catchError(error => {
          console.error("Error fetching Backlog Tools", error);
          return of([]);
        })
      ),
      softwareLinks: this.approvedSoftwareService.getProductSoftwareLinks(Number(this.productId)).pipe(
        catchError(error => {
          console.error("Error fetching Software Links", error);
          return of([]);
        })
      )
    }).subscribe(({ databases, applications, programmingLanguages, uxuis, sourceControlProviders, buildTools, deploymentTools, backlogTools, softwareLinks }) => {
    
      // Sort databases by name
      this.databases = databases.sort((a: SoftwareDto, b: SoftwareDto) => {
        return (a.name ?? '').localeCompare(b.name ?? '');
      });

      this.applications = applications.sort((a: SoftwareDto, b: SoftwareDto) => {
        return (a.name ?? '').localeCompare(b.name ?? '');
      });

      this.programmingLanguages = programmingLanguages.sort((a: SoftwareDto, b: SoftwareDto) => {
        return (a.name ?? '').localeCompare(b.name ?? '');
      });

      this.uxuis = uxuis.sort((a: SoftwareDto, b: SoftwareDto) => {
        return (a.name ?? '').localeCompare(b.name ?? '');
      });

      this.sourceControlProviders = sourceControlProviders.sort((a: SoftwareDto, b: SoftwareDto) => {
        return (a.name ?? '').localeCompare(b.name ?? '');
      });

      this.buildTools = buildTools.sort((a: SoftwareDto, b: SoftwareDto) => {
        return (a.name ?? '').localeCompare(b.name ?? '');
      });

      this.deploymentTools = deploymentTools.sort((a: SoftwareDto, b: SoftwareDto) => {
        return (a.name ?? '').localeCompare(b.name ?? '');
      });

      this.backlogTools = backlogTools.sort((a: SoftwareDto, b: SoftwareDto) => {
        return (a.name ?? '').localeCompare(b.name ?? '');
      });
    
        // Generic function to match software links
        const mapSoftwareLinks = (softwareList: SoftwareDto[], softwareLinks: any[], techStackGroupName: string) =>
          softwareLinks
            .filter(link => 
              // Check if any of the techStackGroups match the provided name
              link.techStackGroups.some((group: any) => group.name === techStackGroupName)
            )
            .map(link => {
              // Find the matched software in the softwareList
              const matchedSoftware = softwareList.find(software => software.id === link.softwareDetails.id);
              
              // Return the mapped object if the software is found
              return matchedSoftware
                ? {
                    id: link.softwareDetails.id,
                    productSoftwareId: link.productSoftwareId,
                    name: link.softwareDetails.name,
                    softwareDisplaySettings: link.softwareDetails.displaySetting,
                    techStackGroups: link.techStackGroups // Include all techStackGroups
                  }
                : null;
            })
            .filter(item => item !== null); // Filter out null values
        
        // Populate selected categories
        this.selectedDatabases = mapSoftwareLinks(this.databases, softwareLinks, "Data Platform");
        this.selectedApplications = mapSoftwareLinks(this.applications, softwareLinks, "Application");
        this.selectedProgrammingLanguages = mapSoftwareLinks(this.programmingLanguages, softwareLinks, "Programming Languages");
        this.selectedUXUIs = mapSoftwareLinks(this.uxuis, softwareLinks, "Front-End / Mobile");
        this.selectedSourceControlProviders = mapSoftwareLinks(this.sourceControlProviders, softwareLinks, "Source Control Providers");
        this.selectedBuildTools = mapSoftwareLinks(this.buildTools, softwareLinks, "Build Tools");
        this.selectedDeploymentTools = mapSoftwareLinks(this.deploymentTools, softwareLinks, "Deployment");
        this.selectedBacklogTools = mapSoftwareLinks(this.backlogTools, softwareLinks, "Backlog");
      });
      
      this.productTechPortfolioService.getAllCountries().subscribe((item) => {
        this.countries = item.sort((a: any, b: any) => {
          const nameA = a.Name ?? '';
          const nameB = b.Name ?? '';
          return nameA.localeCompare(nameB);
        });
      });
      this.productTechPortfolioService.getAllTenancies().subscribe((item) => {
        this.tenancies = item.sort((a: any, b: any) => {
          const nameA = a.Name ?? '';
          const nameB = b.Name ?? '';
          return nameA.localeCompare(nameB);
        });
      });
      this.productTechPortfolioService.getAllAutomatedHAs().subscribe((item) => {
        this.automatedHAs = item.sort((a: any, b: any) => {
          const nameA = a.Name ?? '';
          const nameB = b.Name ?? '';
          return nameA.localeCompare(nameB);
        });
      });
      this.productTechPortfolioService.getAllUXUIModernisations().subscribe((item) => {
        this.uxuiModernisations = item.sort((a: any, b: any) => {
          const nameA = a.Name ?? '';
          const nameB = b.Name ?? '';
          return nameA.localeCompare(nameB);
        });
      });
      this.productTechPortfolioService.getAllCustomizationsEnabled().subscribe((item) => {
        this.customizationsEnabled = item.sort((a: any, b: any) => {
          const nameA = a.Name ?? '';
          const nameB = b.Name ?? '';
          return nameA.localeCompare(nameB);
        });
      });
      this.productTechPortfolioService.getAllReleaseCadences().subscribe((item) => {
        this.releaseCadences = item.sort((a: any, b: any) => {
          const nameA = a.Name ?? '';
          const nameB = b.Name ?? '';
          return nameA.localeCompare(nameB);
        });
      });
      this.productTechPortfolioService.getAllLevelOfTestAutomations().subscribe((item) => {
        this.levelOfTestAutomations = item.sort((a: any, b: any) => {
          const nameA = a.Name ?? '';
          const nameB = b.Name ?? '';
          return nameA.localeCompare(nameB);
        });
      });
      this.productTechPortfolioService.getAllCodeVolatilities().subscribe((item) => {
        this.codeVolatilities = item.sort((a: any, b: any) => {
          const nameA = a.Name ?? '';
          const nameB = b.Name ?? '';
          return nameA.localeCompare(nameB);
        });
      });
      this.productTechPortfolioService.getAllNetNewOrExistingCodes().subscribe((item) => {
        this.netNewOrExistingCodes = item.sort((a: any, b: any) => {
          const nameA = a.Name ?? '';
          const nameB = b.Name ?? '';
          return nameA.localeCompare(nameB);
        });
      });
    });
  }

  ngAfterViewInit(): void {
    this.setActiveTab(this.selectedTab);
  }

  enableEditDescription(val: boolean) {
    this.editDescription = val;
    if (val) {
      this.editedDescription = this.product?.Description ?? '';
    } else {
      this.editedDescription = '';
    }
    this.cdr.detectChanges();
  }

  enableEditLogo(val: boolean) {
    this.editLogo = val;
    if (val) {
      this.editedLogo = this.product?.Logo ?? '';
    } else {
      this.editedLogo = '';
    }
    this.cdr.detectChanges();
  }

  enableEditCE(val: boolean) {
    this.editCE = val;
    if (val) {
      this.editedCEStatus = this.productMetric.InCEPlus;
      this.editedCEDescription = this.productMetric.CEPlusComment;
    } else {

    }
    this.cdr.detectChanges();
  }

  enableEditSDLC(val: boolean) {
    this.editSDLC = val;
    if (val) {
      this.editedComponentManagementTool = this.productMetric.ComponentManagementId;
      this.editedSDLCStatus = this.productMetric.SDLCUsage;
      this.editedNumberOfComponents = this.productMetric.NumberOfComponents;
    } 
    this.cdr.detectChanges();
  }

  enableEditPenTest(val: boolean) {
    this.editPenTest = val;
    if (val) {
      this.editedProductMetric = { ...this.productMetric };
    }
    this.cdr.detectChanges();
  }

  enableEditSecMetrics(val: boolean) {
  this.editSecMetrics = val;
  if (val) {
    this.editedFunctionTypes = this.selectedFunctionTypes;
    this.editedVulnerabilityTools = this.selectedVulnerabilityTools;
    this.editedProductMetric = { ...this.productMetric };
  } else {
    this.editedFunctionTypes = [];
    this.editedVulnerabilityTools = [];
    this.editedProductMetric = [];
  }
  this.cdr.detectChanges();
}

  enableEditHosting(val: boolean) {
    this.editHosting = val;
    if (val) {
      this.editedProductTechSpec = { ...this.productTechSpec };
      this.editedDistributions = this.selectedDistributions;
      this.editedPlatforms = this.selectedPlatforms;
      this.editedTechStacks = this.selectedTechStacks;
      this.editedProviders = this.selectedProviders;
      this.editedAuthenticationMechanisms = this.selectedAuthenticationMechanisms;
      this.editedEnvironments = this.selectedEnvironments;
      this.editedHostingDescription = this.productTechSpec?.EnvironmentComment ?? '';
      this.editedDatabases = this.selectedDatabases.map(item => item.id); // Use only the ids for the multiSelect
      this.editedApplications = this.selectedApplications.map(item => item.id); // Use only the ids for the multiSelect
      this.editedProgrammingLanguages = this.selectedProgrammingLanguages.map(item => item.id); // Use only the ids for the multiSelect
      this.editedUXUIs = this.selectedUXUIs.map(item => item.id); // Use only the ids for the multiSelect
      this.editedAutomatedHA = this.productTechSpec?.AutomatedHAId ?? 0;
    } else {
      this.editedDistributions = [];
      this.editedPlatforms = [];
      this.editedTechStacks = [];
      this.editedAuthenticationMechanisms = [];
      this.editedEnvironments = [];
      this.editedProviders = [];
      this.editedHostingDescription = '';
      this.editedDatabases = [];
      this.editedApplications = [];
      this.editedProgrammingLanguages = [];
      this.editedUXUIs = [];
      this.editedAutomatedHA = 0;

    }
    this.cdr.detectChanges();
  }

  enableEditBuilding(val: boolean) {
  this.editBuilding = val;
  if (val) {
    this.editedProductTechSpec = { ...this.productTechSpec };

    this.editedSourceControlTypes = this.selectedSourceControlTypes;
    this.editedSourceControlProviders = this.selectedSourceControlProviders.map(item => item.id); // Use only the ids for the multiSelect
    this.editedBuildTools = this.selectedBuildTools.map(item => item.id); // Use only the ids for the multiSelect
    this.editedDeploymentTools = this.selectedDeploymentTools.map(item => item.id); // Use only the ids for the multiSelect
    this.editedBacklogTools = this.selectedBacklogTools.map(item => item.id); // Use only the ids for the multiSelect
    this.editedPipelineDescription = this.productTechSpec?.CiCdComment ?? '';
  } else {
    this.editedSourceControlTypes = [];
    this.editedSourceControlProviders = [];
    this.editedBuildTools = [];
    this.editedDeploymentTools = [];
    this.editedBacklogTools = [];
    this.editedPipelineDescription = '';
  }
  this.cdr.detectChanges();
}

  enableEditStatus(val: boolean) {
    this.editStatus = val;
    if (val) {
      this.editedStatus = this.product?.ActiveStatus ?? false;
      this.editedEoLDate = this.product?.EoLDate ?? undefined;
    } else {
      this.editedLogo = '';
      this.editedEoLDate = undefined;
    }
    this.cdr.detectChanges();
  }

  enableEditBasicDetails(val: boolean) {

    this.editBasicDetails = val;

    if (val) {
      // Create a copy of the product to edit
      this.editedProduct = { ...this.product };
      this.editedProductMetric = { ...this.productMetric };
    } else {
      // ResetProduct if cancel is clicked
      this.editedProduct = null;
    }

    this.editingAliases = [];

    //loop through aliases and add the name to editingAliases
    this.product?.Aliases?.forEach((newAlias: Alias) => {
      if (newAlias.Name) {
        this.editingAliases.push(newAlias.Name);
      }
    });

    this.cdr.detectChanges();
  }

  enableEditProcess(val: boolean) {
    this.editProcess = val;
    if (val) {
      this.editedProductTechPortfolio = { ...this.productTechPortfolio };
      this.editedJMLProcess = this.productTechPortfolio?.JMLProcess ?? '';
      this.editedUserReportProcess = this.productTechPortfolio?.UserReportProcess ?? '';
    } else {
      this.editedJMLProcess = '';
      this.editedUserReportProcess = '';
    }
    this.cdr.detectChanges();
  }

  enableEditStrategy(val: boolean) {
    this.editStrategy = val;
    if (val) {
      this.editedProductTechPortfolio = { ...this.productTechPortfolio };
      this.editedCustomerOlderVersion = this.productTechPortfolio?.CustomerOlderVersion ?? '';
      this.editedRoadmapInitiatives = this.productTechPortfolio?.RoadmapInitiatives ?? '';
      this.editedTechDebt = this.productTechPortfolio?.TechDebt ?? '';
      this.editedRandDResourceAllocation = this.productTechPortfolio?.RandDResourceAllocation ?? '';
    } else {
      this.editedCustomerOlderVersion = '';
      this.editedRoadmapInitiatives = '';
      this.editedTechDebt = '';
      this.editedRandDResourceAllocation = '';
    }
    this.cdr.detectChanges();
  }

  enableEditTechStack(val: boolean) {
    this.editTechStack = val;
    if (val) {
      this.editedProductTechPortfolio = { ...this.productTechPortfolio };
      this.editedDatabases = this.selectedDatabases;
      this.editedApplications = this.selectedApplications;
      this.editedProgrammingLanguages = this.selectedProgrammingLanguages;
      this.editedUXUIs = this.selectedUXUIs;
    } else {
      this.editedDatabases = [];
      this.editedApplications = [];
      this.editedProgrammingLanguages = [];
      this.editedUXUIs = [];
    }
    this.cdr.detectChanges();
  }

  enableEditCustomisation(val: boolean) {
    this.editCustomisation = val;
    if (val) {
      this.editedProductTechPortfolio = { ...this.productTechPortfolio };

      // this.editedCustomizationEnabled = this.productTechPortfolio?.CustomizationEnabledId ?? 0;
      this.editedCustomizationEnabledMaintainers = this.productTechPortfolio?.CustomizationEnabledMaintainers ?? '';
      this.editedDegreeOfCustomization = this.productTechPortfolio?.DegreeOfCustomization ?? '';
    }
    this.cdr.detectChanges();
  }

  openFileDialog() {
    const fileInput = document.getElementById('fileInput') as HTMLInputElement;
    fileInput.click();
  }

  onFileSelected(event: Event) {
    const fileInput = event.target as HTMLInputElement;
    if (fileInput.files && fileInput.files.length > 0) {
      const file = fileInput.files[0];
      if (file.size > 100 * 1024) { // 100 KB in bytes
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'File is too large. Please choose a smaller file.', life: 3000 });
      } else {
        this.convertToBase64(file);
      }
      this.cdr.detectChanges();
    }
  }

  convertToBase64(file: File) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.editedLogo = reader.result as string;
      if (this.product) {
        this.product.Logo = this.editedLogo;
      }
    };
    reader.onerror = (error) => {
      console.error('Error: ', error);
    };
  }

  clearLogo() {
    this.editedLogo = "";
    if(this.product){
      this.product.Logo = "";
    }
  }

  updateSDLC() {
    if (this.productMetric) {
      var oldProductMetric = { ...this.productMetric };

      this.productMetric.SDLCUsage = this.editedSDLCStatus;
      this.productMetric.NumberOfComponents = this.editedNumberOfComponents;
      this.productMetric.ComponentManagementId = this.editedComponentManagementTool;
      this.productMetricService.updateProductMetric(this.productMetric.Id ?? 0, this.productMetric).subscribe(
        (response: any) => {
          this.logService.sendInfoMessage('UPDATE', 'product/' + this.productId + '/details/' + this.productMetric, oldProductMetric);
          this.messageService.add({ severity: 'success', summary: 'Successful', detail: `SDLC details updated`, life: 3000 });
        },
        (error) => {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Could not update SDLC details', life: 3000 });
        });
    }
    this.editSDLC = false;
    this.cdr.detectChanges();
  }

  updatePenTest() {
    if (this.editedProductMetric) {
      this.productMetricService.updateProductMetric(this.productMetric.Id ?? 0, this.editedProductMetric).subscribe(
        (response: any) => {
          this.logService.sendInfoMessage('UPDATE', 'product/' + this.productId + '/details/' + this.editedProductMetric, this.productMetric);
          this.productMetric = { ...this.editedProductMetric };
          this.messageService.add({ severity: 'success', summary: 'Successful', detail: `Pen Test details updated`, life: 3000 });
        },
        (error) => {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Could not update pen test details', life: 3000 });
        });
    }
    this.editPenTest = false;
    this.cdr.detectChanges();
  }

  updateCE() {

    if (this.productMetric) {
      var oldProductMetric = { ...this.productMetric };

      this.productMetric.InCEPlus = this.editedCEStatus;
      this.productMetric.CEPlusComment = this.editedCEDescription;
      this.productMetricService.updateProductMetric(this.productMetric.Id ?? 0, this.productMetric).subscribe(
        (response: any) => {
          this.logService.sendInfoMessage('UPDATE', 'product/' + this.productId + '/details/' + this.productMetric, oldProductMetric);
          this.messageService.add({ severity: 'success', summary: 'Successful', detail: `CE+ details updated`, life: 3000 });
        },
        (error) => {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Could not update CE+ details', life: 3000 });
        });
    }
    this.editCE = false;
    this.cdr.detectChanges();
  }

  updateSecMetrics() {

    if (this.productMetric) {
      this.productMetricService.updateProductMetric(this.productMetric.Id ?? 0, this.editedProductMetric).subscribe(
        (response: any) => {
          this.messageService.add({ severity: 'success', summary: 'Successful', detail: `Security metrics updated`, life: 3000 });
          this.logService.sendInfoMessage('UPDATE', 'product/' + this.productId + '/details/' + this.editedProductMetric, this.productMetric);
          this.productMetric = this.editedProductMetric;
          this.productMetric.ProductDataTypeName = response.ProductDataTypeName;
          this.productMetric.ProdSecMonitoringId = response.ProdSecMonitoringId;
        },
        (error) => {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Could not update security metrics', life: 3000 });
        });
    }

    // Function Types
    const originalFunctionTypes = this.productMetric?.ProductMetricFunctionTypes?.map((item: any) => ({ Id: item.Id, FunctionTypeId: item.FunctionTypeId }));
    const addedFunctionTypes = this.editedFunctionTypes.filter(item => !originalFunctionTypes?.some((origItem: any) => origItem.FunctionTypeId === item));
    const removedFunctionTypes = originalFunctionTypes?.filter((item: any) => !this.editedFunctionTypes.includes(item.FunctionTypeId)).map((item: any) => item.Id) ?? [];

    if (addedFunctionTypes.length > 0) {
      addedFunctionTypes.forEach(itemId => {
      this.productMetricService.addProductMetricFunctionType({
        ProductMetricId: this.productMetric?.Id,
        FunctionTypeId: itemId,
      }).subscribe((response: any) => {
        this.messageService.add({ severity: 'success', summary: 'Successful', detail: `Function added`, life: 1000 });
        this.productMetric?.ProductMetricFunctionTypes?.push({ Id: response.Id, FunctionTypeId: response.FunctionTypeId });
      }, (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Could not add function ' + itemId, life: 3000 });
      });
      });
    }

    if (removedFunctionTypes.length > 0) {
      removedFunctionTypes.forEach((id: any) => {
      if (id != undefined) {
        this.productMetricService.removeProductMetricFunctionType(id).subscribe(() => {
        this.messageService.add({ severity: 'success', summary: 'Successful', detail: `Function removed`, life: 1000 });
        if (this.productMetric && this.productMetric.ProductMetricFunctionTypes) {
          this.productMetric.ProductMetricFunctionTypes = this.productMetric.ProductMetricFunctionTypes.filter((item: any) => item.Id !== id);
        }
        }, (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Could not remove function ' + id, life: 3000 });
        });
      }
      });
    }
    this.selectedFunctionTypes = this.editedFunctionTypes;

    // Vulnerability Tools
    const originalVulnerabilityTools = this.productMetric?.ProductMetricVulnerabilityManagements?.map((item: any) => ({ Id: item.Id, VulnerabilityManagementId: item.VulnerabilityManagementId }));
    const addedVulnerabilityTools = this.editedVulnerabilityTools.filter(item => !originalVulnerabilityTools?.some((origItem: any) => origItem.VulnerabilityManagementId === item));
    const removedVulnerabilityTools = originalVulnerabilityTools?.filter((item: any) => !this.editedVulnerabilityTools.includes(item.VulnerabilityManagementId)).map((item: any) => item.Id) ?? [];

    if (addedVulnerabilityTools.length > 0) {
      addedVulnerabilityTools.forEach(itemId => {
      this.productMetricService.addProductMetricVulnerabilityManagement({
        ProductMetricId: this.productMetric?.Id,
        VulnerabilityManagementId: itemId,
      }).subscribe((response: any) => {
        this.messageService.add({ severity: 'success', summary: 'Successful', detail: `Vulnerability tool added`, life: 1000 });
        this.productMetric?.ProductMetricVulnerabilityManagements?.push({ Id: response.Id, VulnerabilityManagementId: response.VulnerabilityManagementId });
      }, (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Could not add vulnerability tool ' + itemId, life: 3000 });
      });
      });
    }

    if (removedVulnerabilityTools.length > 0) {
      removedVulnerabilityTools.forEach((id: any) => {
      if (id != undefined) {
        this.productMetricService.removeProductMetricVulnerabilityManagement(id).subscribe(() => {
        this.messageService.add({ severity: 'success', summary: 'Successful', detail: `Vulnerability tool removed`, life: 1000 });
        if (this.productMetric && this.productMetric.ProductMetricVulnerabilityManagements) {
          this.productMetric.ProductMetricVulnerabilityManagements = this.productMetric.ProductMetricVulnerabilityManagements.filter((item: any) => item.Id !== id);
        }
        }, (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Could not remove vulnerability tool ' + id, life: 3000 });
        });
      }
      });
    }
    this.selectedVulnerabilityTools = this.editedVulnerabilityTools;

    this.editSecMetrics = false;
    this.cdr.detectChanges();
  }

  updateHosting() {
    // Distributions
    const originalDistributions = this.productTechSpec?.TechSpecDistributions?.map(dist => ({ Id: dist.Id, DistributionId: dist.DistributionId }));
    const addedDistributions = this.editedDistributions.filter(dist => !originalDistributions?.some(origDist => origDist.DistributionId === dist));
    const removedDistributions = originalDistributions?.filter(dist => !this.editedDistributions.includes(dist.DistributionId)).map(dist => dist.Id) ?? [];

    if (addedDistributions.length > 0) {
      addedDistributions.forEach(distributionId => {
        this.productTechSpecService.addTechSpecDistribution({
          ProductTechSpecId: this.productTechSpec?.Id,
          DistributionId: distributionId,
        }).subscribe((response: any) => {
          this.messageService.add({ severity: 'success', summary: 'Successful', detail: `Distribution added`, life: 1000 });
  
          // Manually update the productTechSpec to reflect the new distribution
          this.productTechSpec?.TechSpecDistributions?.push({ Id: response.Id, DistributionId: response.DistributionId });
        }, (error) => {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Could not add distribution ' + distributionId, life: 3000 });
        });
      });
    }
  
    // Remove old distributions
    if (removedDistributions.length > 0) {
      removedDistributions.forEach(id => {
        if (id != undefined) {
          this.productTechSpecService.removeTechSpecDistribution(id).subscribe(() => {
            this.messageService.add({ severity: 'success', summary: 'Successful', detail: `Distribution removed`, life: 1000 });
  
            // Manually update the productTechSpec to remove the distribution
            if (this.productTechSpec && this.productTechSpec.TechSpecDistributions) {
              this.productTechSpec.TechSpecDistributions = this.productTechSpec.TechSpecDistributions.filter(dist => dist.Id !== id);
            }
            
          }, (error) => {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Could not remove distribution ' + id, life: 3000 });
          });
        }
      });
    }
    this.selectedDistributions = this.editedDistributions;

    // Platforms
    const originalPlatforms = this.productTechSpec?.TechSpecPlatforms?.map(platform => ({ Id: platform.Id, PlatformId: platform.PlatformId }));
    const addedPlatforms = this.editedPlatforms.filter(platform => !originalPlatforms?.some(origPlat => origPlat.PlatformId === platform));
    const removedPlatforms = originalPlatforms?.filter(platform => !this.editedPlatforms.includes(platform.PlatformId)).map(platform => platform.Id) ?? [];

    if (addedPlatforms.length > 0) {
      addedPlatforms.forEach(platformId => {
        this.productTechSpecService.addTechSpecPlatform({
          ProductTechSpecId: this.productTechSpec?.Id,
          PlatformId: platformId,
        }).subscribe((response: any) =>
        {
          this.messageService.add({ severity: 'success', summary: 'Successful', detail: `Platform added`, life: 1000 });
          this.productTechSpec?.TechSpecPlatforms?.push({ Id: response.Id, PlatformId: response.PlatformId });
        }, (error) => {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Could not add platform ' + platformId, life: 3000 });
        }
        );
      })
    }

    if (removedPlatforms.length > 0) {
      removedPlatforms.forEach(id => {
        if (id != undefined) {
          this.productTechSpecService.removeTechSpecPlatform(id).subscribe(() => {
            this.messageService.add({ severity: 'success', summary: 'Successful', detail: `Platform removed`, life: 1000 });
          }, (error) => {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Could not remove platform ' + id, life: 3000 });

            if (this.productTechSpec && this.productTechSpec.TechSpecPlatforms) {
              this.productTechSpec.TechSpecPlatforms = this.productTechSpec.TechSpecPlatforms.filter(platform => platform.Id !== id);
            }
          }
          );
        }
      });
    }
    this.selectedPlatforms = this.editedPlatforms;

    // TechStacks
    const originalTechStacks = this.productTechSpec?.TechSpecStacks?.map(item => ({ Id: item.Id, TechStackId: item.TechStackId }));
    const addedTechStacks = this.editedTechStacks.filter(item => !originalTechStacks?.some(origItem => origItem.TechStackId === item));
    const removedTechStacks = originalTechStacks?.filter(item => !this.editedTechStacks.includes(item.TechStackId)).map(item => item.Id) ?? [];

    if (addedTechStacks.length > 0) {
      addedTechStacks.forEach(stackId => {
      this.productTechSpecService.addTechSpecStack({
        ProductTechSpecId: this.productTechSpec?.Id,
        TechStackId: stackId,
      }).subscribe((response: any) => {
        this.messageService.add({ severity: 'success', summary: 'Successful', detail: `Tech stack added`, life: 1000 });
        this.productTechSpec?.TechSpecStacks?.push({ Id: response.Id, TechStackId: response.TechStackId });
      }, (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Could not add tech stack ' + stackId, life: 3000 });
      });
      });
    }

    if (removedTechStacks.length > 0) {
      removedTechStacks.forEach(id => {
      if (id != undefined) {
        this.productTechSpecService.removeTechSpecStack(id).subscribe(() => {
        this.messageService.add({ severity: 'success', summary: 'Successful', detail: `Tech stack removed`, life: 1000 });
        if (this.productTechSpec && this.productTechSpec.TechSpecStacks) {
          this.productTechSpec.TechSpecStacks = this.productTechSpec.TechSpecStacks.filter(stack => stack.Id !== id);
        }
        }, (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Could not remove tech stack ' + id, life: 3000 });
        });
      }
      });
    }
    this.selectedTechStacks = this.editedTechStacks;

    // Providers
    const originalProviders = this.productTechSpec?.TechSpecHostingProviders?.map(item => ({ Id: item.Id, HostingProviderId: item.HostingProviderId }));
    const addedProviders = this.editedProviders.filter(item => !originalProviders?.some(origItem => origItem.HostingProviderId === item));
    const removedProviders = originalProviders?.filter(item => !this.editedProviders.includes(item.HostingProviderId)).map(item => item.Id) ?? [];

    if (addedProviders.length > 0) {
      addedProviders.forEach(providerId => {
      this.productTechSpecService.addTechSpecHostingProvider({
        ProductTechSpecId: this.productTechSpec?.Id,
        HostingProviderId: providerId,
      }).subscribe((response: any) => {
        this.messageService.add({ severity: 'success', summary: 'Successful', detail: `Hosting provider added`, life: 1000 });
        this.productTechSpec?.TechSpecHostingProviders?.push({ Id: response.Id, HostingProviderId: response.HostingProviderId });
      }, (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Could not add hosting provider ' + providerId, life: 3000 });
      });
      });
    }

    if (removedProviders.length > 0) {
      removedProviders.forEach(id => {
      if (id != undefined) {
        this.productTechSpecService.removeTechSpecHostingProvider(id).subscribe(() => {
        this.messageService.add({ severity: 'success', summary: 'Successful', detail: `Hosting provider removed`, life: 1000 });
        if (this.productTechSpec && this.productTechSpec.TechSpecHostingProviders) {
          this.productTechSpec.TechSpecHostingProviders = this.productTechSpec.TechSpecHostingProviders.filter(provider => provider.Id !== id);
        }
        }, (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Could not remove hosting provider ' + id, life: 3000 });
        });
      }
      });
    }
    this.selectedProviders = this.editedProviders;

    // Authentication Mechanisms
    const originalAuthenticationMechanisms = this.productTechSpec?.TechSpecAuthenticationMechanisms?.map(item => ({ Id: item.Id, AuthenticationMechanismId: item.AuthenticationMechanismId }));
    const addedAuthenticationMechanisms = this.editedAuthenticationMechanisms.filter(item => !originalAuthenticationMechanisms?.some(origItem => origItem.AuthenticationMechanismId === item));
    const removedAuthenticationMechanisms = originalAuthenticationMechanisms?.filter(item => !this.editedAuthenticationMechanisms.includes(item.AuthenticationMechanismId)).map(item => item.Id) ?? [];

    if (addedAuthenticationMechanisms.length > 0) {
      addedAuthenticationMechanisms.forEach(itemId => {
      this.productTechSpecService.addTechSpecAuthenticationMechanism({
        ProductTechSpecId: this.productTechSpec?.Id,
        AuthenticationMechanismId: itemId,
      }).subscribe((response: any) => {
        this.messageService.add({ severity: 'success', summary: 'Successful', detail: `Authentication mechanism added`, life: 1000 });
        this.productTechSpec?.TechSpecAuthenticationMechanisms?.push({ Id: response.Id, AuthenticationMechanismId: response.AuthenticationMechanismId });
      }, (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Could not add authentication mechanism ' + itemId, life: 3000 });
      });
      });
    }

    if (removedAuthenticationMechanisms.length > 0) {
      removedAuthenticationMechanisms.forEach(id => {
      if (id != undefined) {
        this.productTechSpecService.removeTechSpecAuthenticationMechanism(id).subscribe(() => {
        this.messageService.add({ severity: 'success', summary: 'Successful', detail: `Authentication mechanism removed`, life: 1000 });
        if (this.productTechSpec && this.productTechSpec.TechSpecAuthenticationMechanisms) {
          this.productTechSpec.TechSpecAuthenticationMechanisms = this.productTechSpec.TechSpecAuthenticationMechanisms.filter(auth => auth.Id !== id);
        }
        }, (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Could not remove authentication mechanism ' + id, life: 3000 });
        });
      }
      });
    }
    this.selectedAuthenticationMechanisms = this.editedAuthenticationMechanisms;

    // Environments
    const originalEnvironments = this.productTechSpec?.TechSpecEnvironments?.map(item => ({ Id: item.Id, EnvironmentTypeId: item.EnvironmentTypeId }));
    const addedEnvironments = this.editedEnvironments.filter(item => !originalEnvironments?.some(origItem => origItem.EnvironmentTypeId === item));
    const removedEnvironments = originalEnvironments?.filter(item => !this.editedEnvironments.includes(item.EnvironmentTypeId)).map(item => item.Id) ?? [];

    if (addedEnvironments.length > 0) {
      addedEnvironments.forEach(envId => {
      this.productTechSpecService.addTechSpecEnvironment({
        ProductTechSpecId: this.productTechSpec?.Id,
        EnvironmentTypeId: envId,
      }).subscribe((response: any) => {
        this.messageService.add({ severity: 'success', summary: 'Successful', detail: `Environment added`, life: 1000 });
        this.productTechSpec?.TechSpecEnvironments?.push({ Id: response.Id, EnvironmentTypeId: response.EnvironmentTypeId });
      }, (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Could not add environment ' + envId, life: 3000 });
      });
      });
    }

    if (removedEnvironments.length > 0) {
      removedEnvironments.forEach(id => {
      if (id != undefined) {
        this.productTechSpecService.removeTechSpecEnvironment(id).subscribe(() => {
        this.messageService.add({ severity: 'success', summary: 'Successful', detail: `Environment removed`, life: 1000 });
        if (this.productTechSpec && this.productTechSpec.TechSpecEnvironments) {
          this.productTechSpec.TechSpecEnvironments = this.productTechSpec.TechSpecEnvironments.filter(env => env.Id !== id);
        }
        }, (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Could not remove environment ' + id, life: 3000 });
        });
      }
      });
    }
    this.selectedEnvironments = this.editedEnvironments;
    

    // Data Platform
    const dataPlatformToAdd = this.editedDatabases.filter(db => 
      !this.selectedDatabases.some(d => d.id === db) // Check if db.id is already in selectedDatabases
    );

    // Find which databases are in the selectedDatabases list but not in the editedDatabases list
    const dataPlatformToRemove = this.selectedDatabases.filter(db => 
      !this.editedDatabases.includes(db.id) // Check if the db.id is not in editedDatabases
    );

    // Only handle adding if there are items to add
    if (dataPlatformToAdd.length > 0) {
      dataPlatformToAdd.forEach(db => {
        this.approvedSoftwareService.createProductSoftwareLink({ 
          ProductId: Number(this.productId), 
          SoftwareId: db, 
          ModifiedBy: this.userName,
          TechStackGroupId: 1
        }).subscribe({
          next: (response) => {
            // Find the full object corresponding to the `id`
            const addedDatabase = this.databases.find(database => database.id === db);
            if (addedDatabase) {
              // Push the correct object structure into selectedDatabases
              this.selectedDatabases.push({
                id: addedDatabase.id,
                productSoftwareId: response.productSoftwareId,  // Add productSoftwareId
                name: addedDatabase.name,
                softwareDisplaySettings: addedDatabase.displaySetting
              });

               // Add to productSoftwareLinks
               const newProductSoftwareLink: any = {
                productSoftwareId: response.productSoftwareId,
                productId: Number(this.productId),
                softwareDetails: {
                  id: addedDatabase.id,
                  name: addedDatabase.name,
                  vendorName: addedDatabase.vendorName || 'Unknown Vendor', // Default value
                  activelyDeveloped: addedDatabase.activelyDeveloped || false, // Default value
                  maintained: addedDatabase.maintained || false, // Default value
                  trackVersions: addedDatabase.trackVersions || false, // Default value
                  displaySetting: addedDatabase.displaySetting,
                  vendorId: addedDatabase.vendorId, // Optional property
                },
                lastModified: new Date(), // Default value
                techStackGroup: {
                  id: 1,
                  name: 'Data Platform'
                }
              };
    
              // Create a new array reference to trigger change detection
              this.productSoftwareLinks = [...this.productSoftwareLinks, newProductSoftwareLink];
            }
          },
          error: (err) => {
            console.error("Error creating product software link:", err);
          }
        });
      });
    }

    // Only handle removing if there are items to remove
    if (dataPlatformToRemove.length > 0) {
      dataPlatformToRemove.forEach(db => {
        this.approvedSoftwareService.deleteProductSoftwareLink(db.productSoftwareId).subscribe(() => {
          this.selectedDatabases = this.selectedDatabases.filter(d => d.productSoftwareId !== db.productSoftwareId);
          this.productSoftwareLinks = this.productSoftwareLinks.filter(f => f.productSoftwareId !== db.productSoftwareId);
        });
      });
    }

    // Applications
    const applicationToAdd = this.editedApplications.filter(app =>
      !this.selectedApplications.some(a => a.id === app)
    );

    const applicationToRemove = this.selectedApplications.filter(app =>
      !this.editedApplications.includes(app.id)
    );

    if (applicationToAdd.length > 0) {
      applicationToAdd.forEach(app => {
        this.approvedSoftwareService.createProductSoftwareLink({
          ProductId: Number(this.productId),
          SoftwareId: app,
          ModifiedBy: this.userEmail,
          TechStackGroupId: 2
        }).subscribe({
          next: (response) => {
            const addedApplication = this.applications.find(application => application.id === app);
            if (addedApplication) {
              this.selectedApplications.push({
                id: addedApplication.id,
                productSoftwareId: response.productSoftwareId,
                name: addedApplication.name,
                softwareDisplaySettings: addedApplication.displaySetting
              });
    
              const newProductSoftwareLink: any = {
                productSoftwareId: response.productSoftwareId,
                productId: Number(this.productId),
                softwareDetails: {
                  id: addedApplication.id,
                  name: addedApplication.name,
                  vendorName: addedApplication.vendorName || 'Unknown Vendor',
                  activelyDeveloped: addedApplication.activelyDeveloped || false,
                  maintained: addedApplication.maintained || false,
                  trackVersions: addedApplication.trackVersions || false,
                  displaySetting: addedApplication.displaySetting,
                  vendorId: addedApplication.vendorId,
                },
                lastModified: new Date(),
                techStackGroup: {
                  id: 2,
                  name: 'Application'
                }
              };
    
              this.productSoftwareLinks = [...this.productSoftwareLinks, newProductSoftwareLink];
            }
          },
          error: (err) => {
            console.error("Error creating product software link:", err);
          }
        });
      });
    }

    if (applicationToRemove.length > 0) {
      applicationToRemove.forEach(app => {
        this.approvedSoftwareService.deleteProductSoftwareTechStackGroup(app.productSoftwareId, 2).subscribe({
          next: () => {
            this.selectedApplications = this.selectedApplications.filter(a => a.productSoftwareId !== app.productSoftwareId);
            this.productSoftwareLinks = this.productSoftwareLinks.filter(f => f.productSoftwareId !== app.productSoftwareId);
          },
          error: (err) => {
            console.error("Error deleting product software link:", err);
          }
        });
      });
    }

    // Programming Languages
    const programmingLanguageToAdd = this.editedProgrammingLanguages.filter(programmingLanguage =>
      !this.selectedProgrammingLanguages.some(pl => pl.id === programmingLanguage)
    );

    const programmingLanguageToRemove = this.selectedProgrammingLanguages.filter(programmingLanguage =>
      !this.editedProgrammingLanguages.includes(programmingLanguage.id)
    );

    if (programmingLanguageToAdd.length > 0) {
      programmingLanguageToAdd.forEach(programmingLanguage => {
        this.approvedSoftwareService.createProductSoftwareLink({
          ProductId: Number(this.productId),
          SoftwareId: programmingLanguage,
          ModifiedBy: this.userEmail,
          TechStackGroupId: 8
        }).subscribe({
          next: (response) => {
            const addedProgrammingLanguage = this.programmingLanguages.find(pl => pl.id === programmingLanguage);
            if (addedProgrammingLanguage) {
              this.selectedProgrammingLanguages.push({
                id: addedProgrammingLanguage.id,
                productSoftwareId: response.productSoftwareId,
                name: addedProgrammingLanguage.name,
                softwareDisplaySettings: addedProgrammingLanguage.displaySetting
              });

              const newProductSoftwareLink: any = {
                productSoftwareId: response.productSoftwareId,
                productId: Number(this.productId),
                softwareDetails: {
                  id: addedProgrammingLanguage.id,
                  name: addedProgrammingLanguage.name,
                  vendorName: addedProgrammingLanguage.vendorName || 'Unknown Vendor',
                  activelyDeveloped: addedProgrammingLanguage.activelyDeveloped || false,
                  maintained: addedProgrammingLanguage.maintained || false,
                  trackVersions: addedProgrammingLanguage.trackVersions || false,
                  displaySetting: addedProgrammingLanguage.displaySetting,
                  vendorId: addedProgrammingLanguage.vendorId,
                },
                lastModified: new Date(),
                techStackGroup: {
                  id: 4,
                  name: 'Programming Language'
                }
              };

              this.productSoftwareLinks = [...this.productSoftwareLinks, newProductSoftwareLink];
            }
          },
          error: (err) => {
            console.error("Error creating product software link:", err);
          }
        });
      });
    }

    if (programmingLanguageToRemove.length > 0) {
      programmingLanguageToRemove.forEach(programmingLanguage => {
        this.approvedSoftwareService.deleteProductSoftwareTechStackGroup(programmingLanguage.productSoftwareId, 8).subscribe({
          next: () => {
            this.selectedProgrammingLanguages = this.selectedProgrammingLanguages.filter(pl => pl.productSoftwareId !== programmingLanguage.productSoftwareId);
            this.productSoftwareLinks = this.productSoftwareLinks.filter(f => f.productSoftwareId !== programmingLanguage.productSoftwareId);
          },
          error: (err) => {
            console.error("Error deleting product software link:", err);
          }
        });
      });
    }

    // Front-End/Mobile
    const frontEndToAdd = this.editedUXUIs.filter(frontEnd =>
      !this.selectedUXUIs.some(f => f.id === frontEnd)
    );

    const frontEndToRemove = this.selectedUXUIs.filter(frontEnd =>
      !this.editedUXUIs.includes(frontEnd.id)
    );

    if (frontEndToAdd.length > 0) {
      frontEndToAdd.forEach(frontEnd => {
        this.approvedSoftwareService.createProductSoftwareLink({
          ProductId: Number(this.productId),
          SoftwareId: frontEnd,
          ModifiedBy: this.userEmail,
          TechStackGroupId: 3
        }).subscribe({
          next: (response) => {
            const addedFrontEnd = this.uxuis.find(front => front.id === frontEnd);
            if (addedFrontEnd) {
              this.selectedUXUIs.push({
                id: addedFrontEnd.id,
                productSoftwareId: response.productSoftwareId,
                name: addedFrontEnd.name,
                softwareDisplaySettings: addedFrontEnd.displaySetting
              });
    
              const newProductSoftwareLink: any = {
                productSoftwareId: response.productSoftwareId,
                productId: Number(this.productId),
                softwareDetails: {
                  id: addedFrontEnd.id,
                  name: addedFrontEnd.name,
                  vendorName: addedFrontEnd.vendorName || 'Unknown Vendor',
                  activelyDeveloped: addedFrontEnd.activelyDeveloped || false,
                  maintained: addedFrontEnd.maintained || false,
                  trackVersions: addedFrontEnd.trackVersions || false,
                  displaySetting: addedFrontEnd.displaySetting,
                  vendorId: addedFrontEnd.vendorId,
                },
                lastModified: new Date(),
                techStackGroup: {
                  id: 3,
                  name: 'Front-End / Mobile'
                }
              };
    
              this.productSoftwareLinks = [...this.productSoftwareLinks, newProductSoftwareLink];
            }
          },
          error: (err) => {
            console.error("Error creating product software link:", err);
          }
        });
      });
    }

    if (frontEndToRemove.length > 0) {
      frontEndToRemove.forEach(frontEnd => {
        this.approvedSoftwareService.deleteProductSoftwareTechStackGroup(frontEnd.productSoftwareId, 3).subscribe({
          next: () => {
            this.selectedUXUIs = this.selectedUXUIs.filter(f => f.productSoftwareId !== frontEnd.productSoftwareId);
            this.productSoftwareLinks = this.productSoftwareLinks.filter(f => f.productSoftwareId !== frontEnd.productSoftwareId);
          },
          error: (err) => {
            console.error("Error deleting product software link:", err);
          }
        });
      });
    }
    

    if (this.productTechSpec) {
      this.productTechSpec.EnvironmentComment = this.editedHostingDescription;
      this.productTechSpec.AutomatedHAId = this.editedProductTechSpec.AutomatedHAId;
      this.productTechSpecService.updateProductTechSpec(this.productTechSpec.Id ?? 0, this.productTechSpec).subscribe(
        () => {
          if (this.productTechSpec && this.editedProductTechSpec.AutomatedHAId) {
            this.productTechSpec.AutomatedHAName = this.getAutomatedHANameById(this.editedProductTechSpec.AutomatedHAId);
          }

          this.messageService.add({ severity: 'success', summary: 'Successful', detail: `Hosting updated`, life: 3000 });
        },
        (error) => {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Could not update hosting', life: 3000 });
        });
    }

    this.editHosting = false;
    this.cdr.detectChanges();

  }

  updateBuilding() {

    // SourceControlTypes
    const originalSourceControlTypes = this.productTechSpec?.TechSpecSourceControlTypes?.map(item => ({ Id: item.Id, SourceControlTypeId: item.SourceControlTypeId }));
    const addedSourceControlTypes = this.editedSourceControlTypes.filter(item => !originalSourceControlTypes?.some(origItem => origItem.SourceControlTypeId === item));
    const removedSourceControlTypes = originalSourceControlTypes?.filter(item => !this.editedSourceControlTypes.includes(item.SourceControlTypeId)).map(item => item.Id) ?? [];

    if (addedSourceControlTypes.length > 0) {
      addedSourceControlTypes.forEach(itemId => {
        this.productTechSpecService.addTechSpecSourceControlType({
          ProductTechSpecId: this.productTechSpec?.Id,
          SourceControlTypeId: itemId,
        }).subscribe((response: any) => {
          this.messageService.add({ severity: 'success', summary: 'Successful', detail: `Source control type added`, life: 1000 });
          this.productTechSpec?.TechSpecSourceControlTypes?.push({ Id: response.Id, SourceControlTypeId: response.SourceControlTypeId });
        }, (error) => {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Could not add source control type ' + itemId, life: 3000 });
        });
      });
    }

    if (removedSourceControlTypes.length > 0) {
      removedSourceControlTypes.forEach(id => {
        if (id != undefined) {
          this.productTechSpecService.removeTechSpecSourceControlType(id).subscribe(() => {
            this.messageService.add({ severity: 'success', summary: 'Successful', detail: `Source control type removed`, life: 1000 });
            if (this.productTechSpec && this.productTechSpec.TechSpecSourceControlTypes) {
              this.productTechSpec.TechSpecSourceControlTypes = this.productTechSpec.TechSpecSourceControlTypes.filter(item => item.Id !== id);
            }
          }, (error) => {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Could not remove source control type ' + id, life: 3000 });
          });
        }
      });
    }
    this.selectedSourceControlTypes = this.editedSourceControlTypes;


    // Source Control Providers
    const sourceControlProvidersToAdd = this.editedSourceControlProviders.filter(provider =>
      !this.selectedSourceControlProviders.some(p => p.id === provider)
    );

    const sourceControlProvidersToRemove = this.selectedSourceControlProviders.filter(provider =>
      !this.editedSourceControlProviders.includes(provider.id)
    );

    if (sourceControlProvidersToAdd.length > 0) {
      sourceControlProvidersToAdd.forEach(provider => {
        requestQueue.add(async () => {
          try {
            const response = await this.approvedSoftwareService.createProductSoftwareLink({
              ProductId: Number(this.productId),
              SoftwareId: provider,
              ModifiedBy: this.userEmail,
              TechStackGroupId: 4
            }).toPromise();
    
            const addedProvider = this.sourceControlProviders.find(p => p.id === provider);
            if (addedProvider) {
              this.selectedSourceControlProviders.push({
                id: addedProvider.id,
                productSoftwareId: response.productSoftwareId,
                name: addedProvider.name,
                softwareDisplaySettings: addedProvider.displaySetting
              });
    
              // Add to productSoftwareLinks
              const newProductSoftwareLink: any = {
                productSoftwareId: response.productSoftwareId,
                productId: Number(this.productId),
                softwareDetails: {
                  id: addedProvider.id,
                  name: addedProvider.name,
                  vendorName: addedProvider.vendorName || 'Unknown Vendor',
                  activelyDeveloped: addedProvider.activelyDeveloped || false,
                  maintained: addedProvider.maintained || false,
                  trackVersions: addedProvider.trackVersions || false,
                  displaySetting: addedProvider.displaySetting,
                  vendorId: addedProvider.vendorId,
                },
                lastModified: new Date(),
                techStackGroup: {
                  id: 4,
                  name: 'Source Control Providers'
                }
              };
    
              this.productSoftwareLinks = [...this.productSoftwareLinks, newProductSoftwareLink];
            }
          } catch (error) {
            console.error("Error creating product software link:", error);
          }
        });
      });
    }
    
    if (sourceControlProvidersToRemove.length > 0) {
      sourceControlProvidersToRemove.forEach(provider => {
        requestQueue.add(async () => {
          try {
            await this.approvedSoftwareService.deleteProductSoftwareTechStackGroup(provider.productSoftwareId, 4).toPromise();
            this.selectedSourceControlProviders = this.selectedSourceControlProviders.filter(p => p.productSoftwareId !== provider.productSoftwareId);
            this.productSoftwareLinks = this.productSoftwareLinks.filter(f => f.productSoftwareId !== provider.productSoftwareId);
          } catch (error) {
            console.error("Error deleting product software link:", error);
          }
        });
      });
    }

    // Build Tools
    const buildToolsToAdd = this.editedBuildTools.filter(tool =>
      !this.selectedBuildTools.some(t => t.id === tool)
    );

    const buildToolsToRemove = this.selectedBuildTools.filter(tool =>
      !this.editedBuildTools.includes(tool.id)
    );

    if (buildToolsToAdd.length > 0) {
      buildToolsToAdd.forEach(tool => {
        requestQueue.add(async () => {
          try {
            const response = await this.approvedSoftwareService.createProductSoftwareLink({
              ProductId: Number(this.productId),
              SoftwareId: tool,
              ModifiedBy: this.userEmail,
              TechStackGroupId: 5
            }).toPromise();
    
            const addedTool = this.buildTools.find(t => t.id === tool);
            if (addedTool) {
              this.selectedBuildTools.push({
                id: addedTool.id,
                productSoftwareId: response.productSoftwareId,
                name: addedTool.name,
                softwareDisplaySettings: addedTool.displaySetting
              });
    
              // Add to productSoftwareLinks
              const newProductSoftwareLink: any = {
                productSoftwareId: response.productSoftwareId,
                productId: Number(this.productId),
                softwareDetails: {
                  id: addedTool.id,
                  name: addedTool.name,
                  vendorName: addedTool.vendorName || 'Unknown Vendor',
                  activelyDeveloped: addedTool.activelyDeveloped || false,
                  maintained: addedTool.maintained || false,
                  trackVersions: addedTool.trackVersions || false,
                  displaySetting: addedTool.displaySetting,
                  vendorId: addedTool.vendorId,
                },
                lastModified: new Date(),
                techStackGroup: {
                  id: 5,
                  name: 'Build Tools'
                }
              };
    
              this.productSoftwareLinks = [...this.productSoftwareLinks, newProductSoftwareLink];
            }
          } catch (error) {
            console.error("Error creating product software link:", error);
          }
        });
      });
    }
    
    if (buildToolsToRemove.length > 0) {
      buildToolsToRemove.forEach(tool => {
        requestQueue.add(async () => {
          try {
            await this.approvedSoftwareService.deleteProductSoftwareTechStackGroup(tool.productSoftwareId, 5).toPromise();
            this.selectedBuildTools = this.selectedBuildTools.filter(t => t.productSoftwareId !== tool.productSoftwareId);
            this.productSoftwareLinks = this.productSoftwareLinks.filter(f => f.productSoftwareId !== tool.productSoftwareId);
          } catch (error) {
            console.error("Error deleting product software link:", error);
          }
        });
      });
    }

    // Deployment Tools
    const deploymentToolsToAdd = this.editedDeploymentTools.filter(tool =>
      !this.selectedDeploymentTools.some(t => t.id === tool)
    );

    const deploymentToolsToRemove = this.selectedDeploymentTools.filter(tool =>
      !this.editedDeploymentTools.includes(tool.id)
    );

    if (deploymentToolsToAdd.length > 0) {
      deploymentToolsToAdd.forEach(tool => {
        requestQueue.add(async () => {
          try {
            const response = await this.approvedSoftwareService.createProductSoftwareLink({
              ProductId: Number(this.productId),
              SoftwareId: tool,
              ModifiedBy: this.userEmail,
              TechStackGroupId: 6
            }).toPromise();
    
            const addedTool = this.deploymentTools.find(t => t.id === tool);
            if (addedTool) {
              this.selectedDeploymentTools.push({
                id: addedTool.id,
                productSoftwareId: response.productSoftwareId,
                name: addedTool.name,
                softwareDisplaySettings: addedTool.displaySetting
              });
    
              // Add to productSoftwareLinks
              const newProductSoftwareLink: any = {
                productSoftwareId: response.productSoftwareId,
                productId: Number(this.productId),
                softwareDetails: {
                  id: addedTool.id,
                  name: addedTool.name,
                  vendorName: addedTool.vendorName || 'Unknown Vendor',
                  activelyDeveloped: addedTool.activelyDeveloped || false,
                  maintained: addedTool.maintained || false,
                  trackVersions: addedTool.trackVersions || false,
                  displaySetting: addedTool.displaySetting,
                  vendorId: addedTool.vendorId,
                },
                lastModified: new Date(),
                techStackGroup: {
                  id: 6,
                  name: 'Deployment'
                }
              };
    
              this.productSoftwareLinks = [...this.productSoftwareLinks, newProductSoftwareLink];
            }
          } catch (error) {
            console.error("Error creating product software link:", error);
          }
        });
      });
    }
    
    if (deploymentToolsToRemove.length > 0) {
      deploymentToolsToRemove.forEach(tool => {
        requestQueue.add(async () => {
          try {
            await this.approvedSoftwareService.deleteProductSoftwareTechStackGroup(tool.productSoftwareId, 6).toPromise();
            this.selectedDeploymentTools = this.selectedDeploymentTools.filter(t => t.productSoftwareId !== tool.productSoftwareId);
            this.productSoftwareLinks = this.productSoftwareLinks.filter(f => f.productSoftwareId !== tool.productSoftwareId);
          } catch (error) {
            console.error("Error deleting product software link:", error);
          }
        });
      });
    }

    // Backlog Tools
    const backlogToolsToAdd = this.editedBacklogTools.filter(tool =>
      !this.selectedBacklogTools.some(t => t.id === tool)
    );

    const backlogToolsToRemove = this.selectedBacklogTools.filter(tool =>
      !this.editedBacklogTools.includes(tool.id)
    );

    if (backlogToolsToAdd.length > 0) {
      backlogToolsToAdd.forEach(tool => {
        requestQueue.add(async () => {
          try {
            const response = await this.approvedSoftwareService.createProductSoftwareLink({
              ProductId: Number(this.productId),
              SoftwareId: tool,
              ModifiedBy: this.userEmail,
              TechStackGroupId: 7
            }).toPromise();
    
            const addedTool = this.backlogTools.find(t => t.id === tool);
            if (addedTool) {
              this.selectedBacklogTools.push({
                id: addedTool.id,
                productSoftwareId: response.productSoftwareId,
                name: addedTool.name,
                softwareDisplaySettings: addedTool.displaySetting
              });
    
              // Add to productSoftwareLinks
              const newProductSoftwareLink: any = {
                productSoftwareId: response.productSoftwareId,
                productId: Number(this.productId),
                softwareDetails: {
                  id: addedTool.id,
                  name: addedTool.name,
                  vendorName: addedTool.vendorName || 'Unknown Vendor',
                  activelyDeveloped: addedTool.activelyDeveloped || false,
                  maintained: addedTool.maintained || false,
                  trackVersions: addedTool.trackVersions || false,
                  displaySetting: addedTool.displaySetting,
                  vendorId: addedTool.vendorId,
                },
                lastModified: new Date(),
                techStackGroup: {
                  id: 7,
                  name: 'Backlog'
                }
              };
    
              this.productSoftwareLinks = [...this.productSoftwareLinks, newProductSoftwareLink];
            }
          } catch (error) {
            console.error("Error creating product software link:", error);
          }
        });
      });
    }
    
    if (backlogToolsToRemove.length > 0) {
      backlogToolsToRemove.forEach(tool => {
        requestQueue.add(async () => {
          try {
            await this.approvedSoftwareService.deleteProductSoftwareTechStackGroup(tool.productSoftwareId, 7).toPromise();
            this.selectedBacklogTools = this.selectedBacklogTools.filter(t => t.productSoftwareId !== tool.productSoftwareId);
            this.productSoftwareLinks = this.productSoftwareLinks.filter(f => f.productSoftwareId !== tool.productSoftwareId);
          } catch (error) {
            console.error("Error deleting product software link:", error);
          }
        });
      });
    }

    if (this.productTechSpec) {
      this.productTechSpec.CiCdComment = this.editedPipelineDescription;
      this.productTechSpec.CodeVolatilityId = this.editedProductTechSpec.CodeVolatilityId;
      this.productTechSpec.ReleaseCadenceId = this.editedProductTechSpec.ReleaseCadenceId;
      this.productTechSpec.LevelOfTestAutomationId = this.editedProductTechSpec.LevelOfTestAutomationId;

      this.productTechSpecService.updateProductTechSpec(this.productTechSpec.Id ?? 0, this.productTechSpec).subscribe(
        () => {
          if (this.productTechSpec && this.editedProductTechSpec.CodeVolatilityId) {
            this.productTechSpec.CodeVolatilityName = this.getCodeVolatilityNameById(this.editedProductTechSpec.CodeVolatilityId);
          }
          if (this.productTechSpec && this.editedProductTechSpec.ReleaseCadenceId) {
            this.productTechSpec.ReleaseCadenceName = this.getReleaseCadenceNameById(this.editedProductTechSpec.ReleaseCadenceId);
          }
          if (this.productTechSpec && this.editedProductTechSpec.LevelOfTestAutomationId) {
            this.productTechSpec.LevelOfTestAutomationName = this.getLevelOfTestAutomationNameById(this.editedProductTechSpec.LevelOfTestAutomationId);
          }

          this.messageService.add({ severity: 'success', summary: 'Successful', detail: `Pipeline comment updated`, life: 3000 });
        },
        (error) => {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Could not update pipeline comment', life: 3000 });
        });
    }

    this.editBuilding = false;
    this.cdr.detectChanges();

  }

  updateStatus() {
    if (this.product) {
      var oldProductStatus = { ...this.product };

      this.product.ActiveStatus = this.editedStatus;

      if (this.editedEoLDate == undefined) {
        this.product.EoLDate = undefined;
      } else {
        this.product.EoLDate = new Date(this.editedEoLDate);
      }

      this.product.EoLDate = this.editedEoLDate;
      this.productService.updateProduct(this.productId, this.product).subscribe(
        (response: any) => {
          this.messageService.add({ severity: 'success', summary: 'Successful', detail: `Product status updated`, life: 3000 });
          this.logService.sendInfoMessage('UPDATE', 'product/' + this.productId + '/details/' + this.product, oldProductStatus);
        },
        (error) => {
          console.error('Error updating product status:', error);
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Could not update status', life: 3000 });
        });
      
      this.editStatus = false;
      this.cdr.detectChanges();
    } 

  }

  updateLogo() {

    if (this.product) {
      this.product.Logo = this.editedLogo;
      this.productService.updateProduct(this.productId, this.product).subscribe(
        (response: any) => {
          this.messageService.add({ severity: 'success', summary: 'Successful', detail: `Product logo updated`, life: 3000 });
          this.logService.sendInfoMessage('UPDATE', 'product/' + this.productId + '/details/' + this.product?.Logo);
        },
        (error) => {
          console.error('Error updating basic product logo:', error);
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Could not update logo', life: 3000 });
        });

      this.editedLogo = '';
      this.editLogo = false;
      this.cdr.detectChanges();
    } 
  }

  updateDescription() {

    if (this.product) {
      var oldProductDescription = this.product.Description;
      this.product.Description = this.editedDescription;
      this.productService.updateProduct(this.productId, this.product).subscribe(
        (response: any) => {
          this.messageService.add({ severity: 'success', summary: 'Successful', detail: `Product description updated`, life: 3000 });
          this.logService.sendInfoMessage('UPDATE', 'product/' + this.productId + '/details/' + this.editedDescription, oldProductDescription);
        },
        (error) => {
          console.error('Error updating basic product description:', error);
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Could not update description', life: 3000 });
        });

      this.editedDescription = '';
      this.editDescription = false;
      this.cdr.detectChanges();
    }
  }

  fetchProductSoftwareLinks() {
    this.approvedSoftwareService.getProductSoftwareLinks(Number(this.productId)).subscribe((response: ProductSoftwareDto[]) => {
      this.productSoftwareLinks = response;
    });
  }

  updateBasicDetails() {

    this.submitted = true;

    if (this.editedProduct.Name == null || this.editedProduct.Name.trim() === '') {
      return;
    }

    const tierHasChanged = (this.editedProductMetric?.TierId !== this.productMetric?.TierId);

    // Update basic product details
    this.productService.updateProduct(this.productId, this.editedProduct).subscribe(
      (response: any) => {
        this.product = { ...this.editedProduct };

        // Update related properties if needed (SuiteName, DivisionName, ProductTypeName)
        if (this.product && this.editedProduct.SuiteId) {
          this.product.SuiteName = this.getSuiteNameById(this.editedProduct.SuiteId);
        }
        if (this.product && this.editedProduct.DivisionId) {
          this.product.DivisionName = this.getDivisionNameById(this.editedProduct.DivisionId);
        }
        if (this.product && this.editedProduct.ProductTypeId) {
          this.product.ProductTypeName = this.getProductTypeNameById(this.editedProduct.ProductTypeId);
        }
        if (this.product && this.editedProduct.CountryId) {
          this.product.CountryName = this.getCountryNameById(this.editedProduct.CountryId);
        }
        if (this.product && this.editedProduct.TenancyId) {
          this.product.TenancyName = this.getTenancyNameById(this.editedProduct.TenancyId);
        }

        this.messageService.add({ severity: 'success', summary: 'Successful', detail: `Product details updated`, life: 3000 });

        // Handle alias changes
        this.updateAliases();

        // Check if product metric tier has changed
        if (this.editedProductMetric && tierHasChanged) {
          this.updateProductMetric();          

        }

      },
      (error) => {
        console.error('Error updating basic product details:', error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Could not update details', life: 3000 });
      }
    );

    this.editBasicDetails = false;
    this.submitted = false;
    this.cdr.detectChanges();
  }

  updateAliases() {
    const originalAliases = this.product?.Aliases ?? [];

    // Find new aliases to create
    this.editingAliases.forEach((newAlias: string) => { 
      if (!originalAliases.some(alias => alias.Name === newAlias)) {
        this.productService.createAlias({ Name: newAlias ?? '', ProductId: parseInt(this.productId) }).subscribe(
          (response: any) => {
            const newAlias: Alias = {
              Id: response.id,
              Name: response.name,
              ProductId: response.productId
            };
            this.product?.Aliases?.push(newAlias);

            this.aliases = this.displayAliases(this.product?.Aliases);

          },
          (error) => {
            console.error(`Error creating alias '${newAlias}':`, error);
          }
        );
      }
    });

    // Find aliases to delete
    originalAliases.forEach(originalAlias => {
      if (!this.editingAliases.some((alias: string) => alias === originalAlias.Name)) {
        if (originalAlias?.Id) {
          this.productService.deleteAlias(originalAlias.Id).subscribe(
            (response: any) => {
              if (this.product?.Aliases) {
                this.product.Aliases = this.product?.Aliases.filter(alias => alias.Id !== originalAlias.Id);
                this.aliases = this.displayAliases(this.product.Aliases);
              }

            },
            (error) => {
              console.error(`Error deleting alias with id '${originalAlias.Id}':`, error);
            }
          );
        }
      }
    });
  }

  // Function to update product metric if tier has changed
  updateProductMetric() {
    var oldProductMetric = { ...this.productMetric };

    this.productMetricService.updateProductMetric(this.editedProductMetric.Id ?? 0, this.editedProductMetric).subscribe(
      (response: any) => {
        if (this.product) {
          this.product.ProductMetric = { ...this.editedProductMetric };
          this.productMetric = { ...this.editedProductMetric };

          if (this.editedProductMetric.TierId) {
            if (this.productMetric) {
              this.productMetric.TierName = this.getTierNameById(this.editedProductMetric.TierId);
            }
          }
          this.messageService.add({ severity: 'success', summary: 'Successful', detail: `Product tier updated`, life: 3000 });
          this.logService.sendInfoMessage('UPDATE', 'product/' + this.productId + '/details/' + this.productMetric, oldProductMetric);
        }
      },
      (error) => {
        console.error('Error updating product tier:', error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Could not update the product tier', life: 3000 });
      }
    );
  }

  updateProcess() {
    this.productTechPortfolio = { ...this.editedProductTechPortfolio };
    
    if (this.productTechPortfolio) {
      this.productTechPortfolio.JMLProcess = this.editedJMLProcess;
      this.productTechPortfolio.UserReportProcess = this.editedUserReportProcess;

      this.productTechPortfolioService.updateProductTechPortfolio(this.productTechPortfolio?.Id ?? 0, this.productTechPortfolio).subscribe(
        (response: any) => {
          this.messageService.add({ severity: 'success', summary: 'Successful', detail: `Product tech portfolio updated`, life: 3000 });
        },
        (error) => {
          console.error('Error updating product tech portfolio:', error);
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Could not update tech portfolio', life: 3000 });
        });
  
      this.editProcess = false;
      this.cdr.detectChanges();
    }
  }

  updateStrategy() {
    // this.productTechPortfolio = { ...this.editedProductTechPortfolio };

    if (this.productTechPortfolio) {
      this.productTechPortfolio.UXUIModernisationId = this.editedProductTechPortfolio.UXUIModernisationId;
      this.productTechPortfolio.NetNewOrExistingCodeId = this.editedProductTechPortfolio.NetNewOrExistingCodeId;
      this.productTechPortfolio.CustomerOlderVersion = this.editedCustomerOlderVersion;
      this.productTechPortfolio.TechDebt = this.editedTechDebt;
      this.productTechPortfolio.RandDResourceAllocation = this.editedRandDResourceAllocation;
      this.productTechPortfolio.RoadmapInitiatives = this.editedRoadmapInitiatives;
    

    this.productTechPortfolioService.updateProductTechPortfolio(this.productTechPortfolio?.Id ?? 0, this.productTechPortfolio).subscribe(
      (response: any) => {
        if (this.productTechPortfolio && this.editedProductTechPortfolio.UXUIModernisationId) {
          this.productTechPortfolio.UXUIModernisationName = this.getUXUIModernisationNameById(this.editedProductTechPortfolio.UXUIModernisationId);
        }

        if (this.productTechPortfolio && this.editedProductTechPortfolio.NetNewOrExistingCodeId) {
          this.productTechPortfolio.NetNewOrExistingCodeName = this.getNetNewOrExistingCodeNameById(this.editedProductTechPortfolio.NetNewOrExistingCodeId);
        }

        this.messageService.add({ severity: 'success', summary: 'Successful', detail: `Product tech portfolio updated`, life: 3000 });
      },
      (error) => {
        console.error('Error updating product tech portfolio:', error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Could not update tech portfolio', life: 3000 });
      });

    this.editStrategy = false;
    this.cdr.detectChanges();
    }
  }


  updateCustomisation() {
    if (this.productTechPortfolio) {
      this.productTechPortfolio.CustomizationEnabledId = this.editedProductTechPortfolio.CustomizationEnabledId;
      this.productTechPortfolio.CustomizationEnabledMaintainers = this.editedCustomizationEnabledMaintainers;
      this.productTechPortfolio.DegreeOfCustomization = this.editedDegreeOfCustomization;

      this.productTechPortfolioService.updateProductTechPortfolio(this.productTechPortfolio?.Id ?? 0, this.productTechPortfolio).subscribe(
        () => {
          if (this.productTechPortfolio && this.editedProductTechPortfolio.CustomizationEnabledId) {
            this.productTechPortfolio.CustomizationEnabledName = this.getCustomizationEnabledNameById(this.editedProductTechPortfolio.CustomizationEnabledId);
          }
          
          this.messageService.add({ severity: 'success', summary: 'Successful', detail: `Customisation updated`, life: 3000 });
        },
        (error) => {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Could not update customisation', life: 3000 });
        });
    }

    this.editCustomisation = false;
    this.cdr.detectChanges();
  }


  getSuiteNameById(suiteId: string): string {
    const selectedSuite = this.suites.find(suite => suite.Id === suiteId);
    return selectedSuite ? selectedSuite.Name : '';
  }

  getDivisionNameById(divisionId: string): string {
    const selectedDivisions = this.divisions.find(division => division.Id === divisionId);
    return selectedDivisions ? selectedDivisions.Name : '';
  }

  getTierNameById(tierId: string): string {
    const selectedTier = this.tiers.find(tier => tier.Id === tierId);
    return selectedTier ? selectedTier.Name : '';
  }

  getProductTypeNameById(productTypeId: string): string {
    const selectedProductTypes = this.productTypes.find(type => type.Id === productTypeId);
    return selectedProductTypes ? selectedProductTypes.Name : '';
  }

  getCountryNameById(countryId: string): string {
    const selectedCountry = this.countries.find(country => country.Id === countryId);
    return selectedCountry ? selectedCountry.Name : '';
  }

  getTenancyNameById(tenancyId: string): string {
    const selectedTenancy = this.tenancies.find(tenancy => tenancy.Id === tenancyId);
    return selectedTenancy ? selectedTenancy.Name : '';
  }

  getAutomatedHANameById(automatedHAId: string): string {
    const selectedAutomatedHA = this.automatedHAs.find(automatedHA => automatedHA.Id === automatedHAId);
    return selectedAutomatedHA ? selectedAutomatedHA.Name : '';
  }

  getUXUINameById(uxuiId: string): string {
    const selectedUXUI = this.uxuiModernisations.find(uxui => uxui.Id === uxuiId);
    return selectedUXUI ? selectedUXUI.Name : '';
  }

  getCustomizationEnabledNameById(customizationEnabledId: string): string {
    const selectedCustomizationEnabled = this.customizationsEnabled.find(customizationEnabled => customizationEnabled.Id === customizationEnabledId);
    return selectedCustomizationEnabled ? selectedCustomizationEnabled.Name : '';
  }

  getReleaseCadenceNameById(releaseCadenceId: string): string {
    const selectedReleaseCadence = this.releaseCadences.find(releaseCadence => releaseCadence.Id === releaseCadenceId);
    return selectedReleaseCadence ? selectedReleaseCadence.Name : '';
  }

  getLevelOfTestAutomationNameById(levelOfTestAutomationId: string): string {
    const selectedLevelOfTestAutomation = this.levelOfTestAutomations.find(levelOfTestAutomation => levelOfTestAutomation.Id === levelOfTestAutomationId);
    return selectedLevelOfTestAutomation ? selectedLevelOfTestAutomation.Name : '';
  }

  getCodeVolatilityNameById(codeVolatilityId: string): string {
    const selectedCodeVolatility = this.codeVolatilities.find(codeVolatility => codeVolatility.Id === codeVolatilityId);
    return selectedCodeVolatility ? selectedCodeVolatility.Name : '';
  }

  getNetNewOrExistingCodeNameById(netNewOrExistingCodeId: string): string {
    const selectedNetNewOrExistingCode = this.netNewOrExistingCodes.find(netNewOrExistingCode => netNewOrExistingCode.Id === netNewOrExistingCodeId);
    return selectedNetNewOrExistingCode ? selectedNetNewOrExistingCode.Name : '';
  }

  getApplicationNameById(applicationId: string): string {
    const selectedApplication = this.applications.find(app => app.Id === applicationId);
    return selectedApplication ? selectedApplication.Name : '';
  }

  getProgrammingLanguageNameById(languageId: string): string {
    const selectedLanguage = this.programmingLanguages.find(language => language.Id === languageId);
    return selectedLanguage ? selectedLanguage.Name : '';
  }

  getDatabaseNameById(databaseId: string): string {
    const selectedDatabase = this.databases.find(database => database.Id === databaseId);
    return selectedDatabase ? selectedDatabase.Name : '';
  }

  getUXUIModernisationNameById(uxuiId: string): string {
    const selectedUXUI = this.uxuiModernisations.find(uxui => uxui.Id === uxuiId);
    return selectedUXUI ? selectedUXUI.Name : '';
  }

  trimOption(option: string | undefined): string {
    return option ? option.trim() : '';
  }

  displayNiceDate(date: Date | undefined): string {
    if (!date) {
      return '';
    }
    const newDate = new Date(date);
    return newDate.toLocaleDateString('en-GB', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  }

  getDistributionName(id: number): string {
    const distribution = this.distributions.find(dist => dist.Id === id);
    return distribution ? distribution.Name : '';
  }

  getPlatformName(id: number): string {
    const platform = this.platforms.find(platform => platform.Id === id);
    return platform ? platform.Name : '';
  }

  getTechStackName(id: number): string {
    const techStack = this.techStacks.find(ts => ts.Id === id);
    return techStack ? techStack.Name : '';
  }

  getProviderName(id: number): string {
    const provider = this.providers.find(pv => pv.Id === id);
    return provider ? provider.Name : '';
  }

  getAuthenticationMechanismName(id: number): string {
    const item = this.authenticationMechanisms.find(it => it.Id === id);
    return item ? item.Name : '';
  }

  getEnvironmentName(id: number): string {
    const environment = this.environments.find(env => env.Id === id);
    return environment ? environment.Name : '';
  }

  getSourceControlTypeName(id: number): string {
    const item = this.sourceControlTypes.find(it => it.Id === id);
    return item ? item.Name : '';
  }

  getSourceControlProviderName(id: number): string {
    const item = this.sourceControlProviders.find(it => it.id === id);
    return item ? item.name : '';
  }

  getBuildToolName(id: number): string {
    const item = this.buildTools.find(it => it.id === id);
    return item ? item.name : '';
  }

  getDeploymentToolName(id: number): string {
    const item = this.deploymentTools.find(it => it.id === id);
    return item ? item.name : '';
  }

  getBacklogToolName(id: number): string {
    const item = this.backlogTools.find(it => it.id === id);
    return item ? item.name : '';
  }

  getFunctionTypeName(id: number): string {
    const item = this.functionTypes.find(it => it.Id === id);
    return item ? item.Name : '';
  }

  getProductDataTypeName(id: number): string {
    const item = this.productDataTypes.find(it => it.Id === id);
    return item ? item.Name : '';
  }

  getSecMonitoringToolName(id: number): string {
    const item = this.secMonitoringTools.find(it => it.Id === id);
    return item ? item.Name : '';
  }

  getVulnerabilityToolName(id: number): string {
    const item = this.vulnerabilityTools.find(it => it.Id === id);
    return item ? item.Name : '';
  }

  getClassificationName(id: number): string {
    const item = this.classifications.find(it => it.Id === id);
    return item ? item.Name : '';
  }

  getComponentManagementToolName(id: number): string {
    const item = this.componentManagementTools.find(it => it.Id === id);
    return item ? item.Name : '';
  }

  // Product Tech Portfolio
  getDatabaseName(id: number): string {
    const item = this.databases.find(it => it.id === id);
    return item ? item.name : '';
  }

  getCountriesName(id: number): string {
    const item = this.countries.find(it => it.Id === id);
    return item ? item.Name : '';
  }

  getTenanciesName(id: number): string {
    const item = this.tenancies.find(it => it.Id === id);
    return item ? item.Name : '';
  }

  getAutomatedHAsName(id: number): string {
    const item = this.automatedHAs.find(it => it.Id === id);
    return item ? item.Name : '';
  }

  getUXUIModernisationsName(id: number): string {
    const item = this.uxuiModernisations.find(it => it.Id === id);
    return item ? item.Name : '';
  }

  getCustomizationsEnabledName(id: number): string {
    const item = this.customizationsEnabled.find(it => it.Id === id);
    return item ? item.Name : '';
  }

  getReleaseCadencesName(id: number): string {
    const item = this.releaseCadences.find(it => it.Id === id);
    return item ? item.Name : '';
  }

  getLevelOfTestAutomationsName(id: number): string {
    const item = this.levelOfTestAutomations.find(it => it.Id === id);
    return item ? item.Name : '';
  }

  getCodeVolatilitiesName(id: number): string {
    const item = this.codeVolatilities.find(it => it.Id === id);
    return item ? item.Name : '';
  }

  getNetNewOrExistingCodesName(id: number): string {
    const item = this.netNewOrExistingCodes.find(it => it.Id === id);
    return item ? item.Name : '';
  }

  getApplicationsName(id: number): string {
    const item = this.applications.find(it => it.id === id);
    return item ? item.name : '';
  }

  getProgrammingLanguagesName(id: number): string {
    const item = this.programmingLanguages.find(it => it.id === id);
    return item ? item.name : '';
  }

  getUXUIsName(id: number): string {
    const item = this.uxuis.find(it => it.id === id);
    return item ? item.name : '';
  }



  tagify(option: string | undefined): string {
    if (!option) {
      return '';
    }
    let tagValue = option.trim();
    tagValue = tagValue.replace(/\s+/g, '_');
    tagValue = tagValue.replace(/[^a-zA-Z0-9_]/g, '');
    return tagValue;
  }

  displayAliases(aliases: any[] | undefined): string {
    if (!aliases) {
      return '';
    }
    const flattened = aliases.map((alias: any) => alias.Name).join(', ');
    return flattened;
  }

  setActiveTab(tab: string) {

    const tabIndex = parseInt(tab);

    // Ensure the tab is a valid number within range
    if (!isNaN(tabIndex) && tabIndex >= 0 && tabIndex < this.tabView.tabs.length) {
      this.tabView.activeIndex = tabIndex;
      this.cdr.detectChanges();
    }
  }

  isAddDialogVisible: boolean = false;

  entryType: 'database' | 'application' | 'programmingLanguage' | 'uxui' | 'techstack' = 'techstack';

  // Method to open the add database dialog
  openAddEntryDialog(type: 'database' | 'application' | 'programmingLanguage' | 'uxui' | 'techstack') {
    this.entryType = type;
    this.addEntryDialog.showDialog();
  }

  onEntryAdded(entry: { type: string, response: any }) {
    switch (entry.type) {
      case 'database':
        this.databases.push(entry.response);
        this.messageService.add({severity:'success', summary: 'Success', detail: 'Database added successfully!'});
        break;
      case 'application':
        this.applications.push(entry.response);
        this.messageService.add({severity:'success', summary: 'Success', detail: 'Application Layer added successfully!'});
        break;
      case 'programmingLanguage':
        this.programmingLanguages.push(entry.response);
        this.messageService.add({severity:'success', summary: 'Success', detail: 'Programming Language added successfully!'});
        break;
      case 'uxui':
        this.uxuis.push(entry.response);
        this.messageService.add({severity:'success', summary: 'Success', detail: 'Client Layer added successfully!'});
        break;
      case 'techstack':
        this.techStacks.push(entry.response);
        this.messageService.add({severity:'success', summary: 'Success', detail: 'Other Tech Stack added successfully!'});
        break;
      default:
        console.warn(`Unknown entry type: ${this.entryType}`);
    }
  }

  getUserGraphId() {
    const account = this.msalService.instance.getAllAccounts()[0];
    if (account && account.idTokenClaims) {
      this.userGraphId = account.idTokenClaims['oid'] || '';
      this.userName = account.idTokenClaims['name'] || '';
      this.userEmail = (account.idTokenClaims['preferred_username'] || account.idTokenClaims['email'] as string) || '';
    } else {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Failed to retrieve user information.',
      });
    }
  }
}

class RequestQueue {
  private queue: (() => Promise<void>)[] = [];
  private isProcessing = false;

  add(request: () => Promise<void>) {
    this.queue.push(request);
    this.processNext();
  }

  private async processNext() {
    if (this.isProcessing || this.queue.length === 0) return;

    this.isProcessing = true;
    const request = this.queue.shift();
    if (request) {
      try {
        await request();
      } catch (error) {
        console.error("Error processing request:", error);
      } finally {
        this.isProcessing = false;
        this.processNext();
      }
    }
  }
}

// Create a global queue instance
const requestQueue = new RequestQueue();