import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Message, MessageService } from 'primeng/api';
import { ProductToolsRefService } from 'src/app/layout/service/product/product-tool-refs.service';
import { LogService } from 'src/app/layout/service/log.service';
import { Observable, of, forkJoin } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

@Component({
  selector: 'app-product-tool-refs',
  templateUrl: './product-tool-refs.component.html'
})
export class ProductToolRefsComponent implements OnInit {

  messages: Message[] = [];
  productId: string = '';
  productType: number = 0;
  matchedProductToolRefs: any[] = [];
  availableTools: any[] = [];
  selectedTool: any = null;
  newRefDialog: boolean = false;
  newRef: string = '';
  submitted: boolean = false;

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private productToolsRefService: ProductToolsRefService,
    private messageService: MessageService,
    private logService: LogService
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.productId = params['id'];
  
      this.getProductTypes().subscribe(() => {
        this.loadData();
      });
    });

    this.messages = [{ severity: 'info', summary: 'Information', detail: 'Tool References are used to link the product to corresponding data in third-party platforms, e.g. code quality data in SonarQube. The reference is the unique identifier of this product in the platform, for example the Org ID in Snyk. Note that if the product has a parent suite, some of the references might be at suite-level rather than at product level. If in any doubt, speak to a member of the Architecture and Security team.' }];
  }

  getProductTypes(): Observable<any> {
    return this.productToolsRefService.getProductTypes().pipe(
      tap((response: any) => {
        if (response && response['@odata.context']) {
          const productTypes = response.value;
          if (Array.isArray(productTypes)) {
            productTypes.forEach((productType: any) => {
              if (productType.Name === 'Product') {
                this.productType = productType.Id;
              }
            });
          } else {
            console.error('Expected an array of product types in OData response, but received:', productTypes);
          }
        } else {
          console.error('Unexpected response format. Expected OData response with @odata.context.');
        }
      }),
      catchError((error) => {
        console.error('Error fetching product types:', error);
        return of(null);
      })
    );
  }

  loadData(): void {
    forkJoin([this.getTools(), this.getProductToolRefs()]).subscribe(() => {
      this.updateAvailableTools();
    });
  }

  getProductToolRefs(): Observable<any> {
    return this.productToolsRefService.getProductToolRefs().pipe(
      tap((response: any) => {
        if (response && response['@odata.context']) {
          const productToolRefs = response.value;
          if (Array.isArray(productToolRefs)) {
            this.matchedProductToolRefs = productToolRefs.filter((productToolRef: any) => 
              productToolRef.ProductTypeId === this.productType && productToolRef.ObjectId === parseInt(this.productId)
            );
          } else {
            console.error('Expected an array of product tool references in OData response, but received:', productToolRefs);
          }
        } else {
          console.error('Unexpected response format. Expected OData response with @odata.context.');
        }
      }),
      catchError((error) => {
        console.error('Error fetching product tool refs:', error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error populating tool references', life: 3000 });
        return of([]);
      })
    );
  }

  openNewRefDialog() {    
    this.newRefDialog = true;
  }
  hideDialog() {    
    this.newRefDialog = false;
  }

  addNewToolRef() {
    this.submitted = true;

    if (!this.selectedTool || !this.newRef) {
      return;
    }

    console.log(`Adding new reference for tool ${this.selectedTool}: ${this.newRef}`);

    const newToolRef = {
      ProductTypeId: this.productType,
      ObjectId: parseInt(this.productId),
      SDLCToolId: this.selectedTool,
      Reference: this.newRef
    };

    this.productToolsRefService.addProductToolRef(newToolRef).subscribe(
      (response: any) => {
        console.log('Successfully added new tool reference:', response);
        this.messageService.add({ severity: 'success', summary: 'Successful', detail: `New reference added`, life: 3000 });
        this.loadData();

        this.logService.sendInfoMessage('CREATE', 'product/' + this.productId + '/tool-refs/', newToolRef);

      },
      (error) => {
        console.error('Error adding new tool reference:', error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Could not add new tool reference', life: 3000 });
      }
    );

    this.selectedTool = null;
    this.newRef = '';
    this.newRefDialog = false;
    this.submitted = false;
    
  }

  deleteProductToolRef(ptr: any) {
    console.log('Deleting product tool reference:', ptr);

    this.productToolsRefService.deleteProductToolRef(ptr.Id).subscribe(
      (response: any) => {
        console.log('Successfully deleted product tool reference:', response);
        this.matchedProductToolRefs = this.matchedProductToolRefs.filter((productToolRef) => productToolRef.Id !== ptr.Id);
        this.messageService.add({ severity: 'success', summary: 'Successful', detail: `Reference removed`, life: 3000 });
        this.loadData(); // Refresh the data after deletion

        this.logService.sendInfoMessage('DELETE', 'product/' + this.productId + '/tool-refs/' + ptr.Id, null, ptr);
      },
      (error) => {
        console.error('Error deleting product tool reference:', error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Failed to remove tool reference', life: 3000 });
      }
    );
  }

  getTools(): Observable<any> {
    return this.productToolsRefService.getSDLCTools().pipe(
      tap((response: any) => {
        if (response && response['@odata.context']) {
          const tools = response.value;
          if (Array.isArray(tools)) {
            this.availableTools = tools
              .filter((tool: any) => tool.Id !== 14)
              .map((tool: any) => ({ label: tool.Name, value: tool.Id }));
            this.availableTools.sort((a, b) => a.label.localeCompare(b.label));

          } else {
            console.error('Expected an array of tools in OData response, but received:', tools);
          }
        } else {
          console.error('Unexpected response format. Expected OData response with @odata.context.');
        }
      }),
      catchError((error) => {
        console.error('Error fetching tools:', error);
        return of([]);
      })
    );
  }

  updateAvailableTools() {
    const usedToolIds = new Set(this.matchedProductToolRefs.map(ref => ref.SDLCToolId));
    this.availableTools = this.availableTools.filter(tool => !usedToolIds.has(tool.value));
    this.availableTools.sort((a, b) => a.label.localeCompare(b.label));
  }
}
