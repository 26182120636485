<div class="widgetCard" [class.collapsed]="SnykVulnCollapsed">
  <div class="widgetCardHeader" (click)="toggleSnykVulnCollapse()">
    <h5>Snyk Vulnerability Trend</h5>
    <div class="chevron">
      <i [class]="SnykVulnCollapsed ? 'chevron-down' : 'chevron-up'"></i>
    </div>
  </div>
  <ng-container *ngIf="!SnykVulnCollapsed">
    <ng-container *ngIf="snykVulnloading; else content">
      <p-skeleton width="10%" height="1rem" margin="5px"></p-skeleton>
    </ng-container>
    <ng-template #content>
      <span class="reportingLevelLabel">{{ snykReportingLevel }}</span>
      <div class="reportingLevelLabel" *ngIf="hasMultipleComponents">
        <p-dropdown appendTo="body" [(ngModel)]="selectedSlugName" [options]="components" placeholder="Select" class="uniform-width-input" [style]="{'minWidth':'40%', 'maxWidth': '400px'}" (onChange)="onDropdownChange($event)">
          <ng-template pTemplate="selectedItem">
            <span *ngIf="selectedSlugName">{{ selectedSlugName }}</span>
          </ng-template>
          <ng-template let-option pTemplate="item">
            <span>{{option}}</span>
          </ng-template>
        </p-dropdown>
      </div>
      <div class="pieWidgetContainer" *ngIf="snykVulnerabilitiesAvailable">
        <div class="vertical-tabs-container">
          <div class="vertical-tabs-nav">
            <button pButton class="vertical-tab-button" (click)="selectTab(0)" [class.active]="activeTab === 0">
              <span class="text">Overview</span>
              <i class="pi pi-table icon"></i>
            </button>
            <button pButton class="vertical-tab-button" (click)="selectTab(1)" [class.active]="activeTab === 1">
              <span class="text">Chart</span>
              <i class="pi pi-chart-line icon"></i>
            </button>
          </div>
          <div class="vertical-tabs-content">
            <div *ngIf="activeTab === 0">
              <table class="summary-table">
                <thead>
                  <tr>
                    <th class="pieWidgetHeader pieWidgetCell"></th>
                    <th class="pieWidgetHeader pieWidgetCell">vs. 7 days ago</th>
                    <th class="pieWidgetHeader pieWidgetCell">vs. 30 days ago</th>
                    <th class="pieWidgetHeader pieWidgetCell">vs. 6 months ago</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="summary-table-critical-title">Critical</td>
                    <td [ngClass]="{'greyed-out': !summary.v7Days.hasSegment}">
                      <span *ngIf="summary.v7Days.hasSegment">
                        <span [ngClass]="{'positive': summary.v7Days.Critical > 0, 'negative': summary.v7Days.Critical < 0}">
                          <span *ngIf="summary.v7Days.Critical === 0" class="zero">-</span>
                          <span *ngIf="summary.v7Days.Critical != 0">{{ absify(summary.v7Days.Critical) }}</span>
                          <span *ngIf="summary.v7Days.Critical > 0">&nbsp;▲</span>
                          <span *ngIf="summary.v7Days.Critical < 0">&nbsp;▼</span>
                        </span>
                      </span>
                    </td>
                    <td [ngClass]="{'greyed-out': !summary.v30Days.hasSegment}">
                      <span *ngIf="summary.v30Days.hasSegment">
                        <span [ngClass]="{'positive': summary.v30Days.Critical > 0, 'negative': summary.v30Days.Critical < 0}">
                          <span *ngIf="summary.v30Days.Critical === 0" class="zero">-</span>
                          <span *ngIf="summary.v30Days.Critical != 0">{{ absify(summary.v30Days.Critical) }}</span>
                          <span *ngIf="summary.v30Days.Critical > 0">&nbsp;▲</span>
                          <span *ngIf="summary.v30Days.Critical < 0">&nbsp;▼</span>
                        </span>
                      </span>
                    </td>
                    <td [ngClass]="{'greyed-out': !summary.v6Months.hasSegment}">
                      <span *ngIf="summary.v30Days.hasSegment">
                        <span [ngClass]="{'positive': summary.v6Months.Critical > 0, 'negative': summary.v6Months.Critical < 0}">
                          <span *ngIf="summary.v6Months.Critical === 0" class="zero">-</span>
                          <span *ngIf="summary.v6Months.Critical != 0">{{ absify(summary.v6Months.Critical) }}</span>
                          <span *ngIf="summary.v6Months.Critical > 0">&nbsp;▲</span>
                          <span *ngIf="summary.v6Months.Critical < 0">&nbsp;▼</span>
                        </span>
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td class="summary-table-high-title">High</td>
                    <td [ngClass]="{'greyed-out': !summary.v7Days.hasSegment}">
                      <span *ngIf="summary.v7Days.hasSegment">
                        <span [ngClass]="{'positive': summary.v7Days.High > 0, 'negative': summary.v7Days.High < 0}">
                          <span *ngIf="summary.v7Days.High === 0" class="zero">-</span>
                          <span *ngIf="summary.v7Days.High != 0">{{ absify(summary.v7Days.High) }}</span>
                          <span *ngIf="summary.v7Days.High > 0">&nbsp;▲</span>
                          <span *ngIf="summary.v7Days.High < 0">&nbsp;▼</span>
                        </span>
                      </span>
                    </td>
                    <td [ngClass]="{'greyed-out': !summary.v30Days.hasSegment}">
                      <span *ngIf="summary.v30Days.hasSegment">
                        <span [ngClass]="{'positive': summary.v30Days.High > 0, 'negative': summary.v30Days.High < 0}">
                          <span *ngIf="summary.v30Days.High === 0" class="zero">-</span>
                          <span *ngIf="summary.v30Days.High != 0">{{ absify(summary.v30Days.High) }}</span>
                          <span *ngIf="summary.v30Days.High > 0">&nbsp;▲</span>
                          <span *ngIf="summary.v30Days.High < 0">&nbsp;▼</span>
                        </span>
                      </span>
                    </td>
                    <td [ngClass]="{'greyed-out': !summary.v6Months.hasSegment}">
                      <span *ngIf="summary.v30Days.hasSegment">
                        <span [ngClass]="{'positive': summary.v6Months.High > 0, 'negative': summary.v6Months.High < 0}">
                          <span *ngIf="summary.v6Months.High === 0" class="zero">-</span>
                          <span *ngIf="summary.v6Months.High != 0">{{ absify(summary.v6Months.High) }}</span>
                          <span *ngIf="summary.v6Months.High > 0">&nbsp;▲</span>
                          <span *ngIf="summary.v6Months.High < 0">&nbsp;▼</span>
                        </span>
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td class="summary-table-medium-title">Medium</td>
                    <td [ngClass]="{'greyed-out': !summary.v7Days.hasSegment}">
                      <span *ngIf="summary.v7Days.hasSegment">
                        <span [ngClass]="{'positive': summary.v7Days.Medium > 0, 'negative': summary.v7Days.Medium < 0}">
                          <span *ngIf="summary.v7Days.Medium === 0" class="zero">-</span>
                          <span *ngIf="summary.v7Days.Medium != 0">{{ absify(summary.v7Days.Medium) }}</span>
                          <span *ngIf="summary.v7Days.Medium > 0">&nbsp;▲</span>
                          <span *ngIf="summary.v7Days.Medium < 0">&nbsp;▼</span>
                        </span>
                      </span>
                    </td>
                    <td [ngClass]="{'greyed-out': !summary.v30Days.hasSegment}">
                      <span *ngIf="summary.v30Days.hasSegment">
                        <span [ngClass]="{'positive': summary.v30Days.Medium > 0, 'negative': summary.v30Days.Medium < 0}">
                          <span *ngIf="summary.v30Days.Medium === 0" class="zero">-</span>
                          <span *ngIf="summary.v30Days.Medium != 0">{{ absify(summary.v30Days.Medium) }}</span>
                          <span *ngIf="summary.v30Days.Medium > 0">&nbsp;▲</span>
                          <span *ngIf="summary.v30Days.Medium < 0">&nbsp;▼</span>
                        </span>
                      </span>
                    </td>
                    <td [ngClass]="{'greyed-out': !summary.v6Months.hasSegment}">
                      <span *ngIf="summary.v30Days.hasSegment">
                        <span [ngClass]="{'positive': summary.v6Months.Medium > 0, 'negative': summary.v6Months.Medium < 0}">
                          <span *ngIf="summary.v6Months.Medium === 0" class="zero">-</span>
                          <span *ngIf="summary.v6Months.Medium != 0">{{ absify(summary.v6Months.Medium) }}</span>
                          <span *ngIf="summary.v6Months.Medium > 0">&nbsp;▲</span>
                          <span *ngIf="summary.v6Months.Medium < 0">&nbsp;▼</span>
                        </span>
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td class="summary-table-low-title">Low</td>
                    <td [ngClass]="{'greyed-out': !summary.v7Days.hasSegment}">
                      <span *ngIf="summary.v7Days.hasSegment">
                        <span [ngClass]="{'positive': summary.v7Days.Low > 0, 'negative': summary.v7Days.Low < 0}">
                          <span *ngIf="summary.v7Days.Low === 0" class="zero">-</span>
                          <span *ngIf="summary.v7Days.Low != 0">{{ absify(summary.v7Days.Low) }}</span>
                          <span *ngIf="summary.v7Days.Low > 0">&nbsp;▲</span>
                          <span *ngIf="summary.v7Days.Low < 0">&nbsp;▼</span>
                        </span>
                      </span>
                    </td>
                    <td [ngClass]="{'greyed-out': !summary.v30Days.hasSegment}">
                      <span *ngIf="summary.v30Days.hasSegment">
                        <span [ngClass]="{'positive': summary.v30Days.Low > 0, 'negative': summary.v30Days.Low < 0}">
                          <span *ngIf="summary.v30Days.Low === 0" class="zero">-</span>
                          <span *ngIf="summary.v30Days.Low != 0">{{ absify(summary.v30Days.Low) }}</span>
                          <span *ngIf="summary.v30Days.Low > 0">&nbsp;▲</span>
                          <span *ngIf="summary.v30Days.Low < 0">&nbsp;▼</span>
                        </span>
                      </span>
                    </td>
                    <td [ngClass]="{'greyed-out': !summary.v6Months.hasSegment}">
                      <span *ngIf="summary.v30Days.hasSegment">
                        <span [ngClass]="{'positive': summary.v6Months.Low > 0, 'negative': summary.v6Months.Low < 0}">
                          <span *ngIf="summary.v6Months.Low === 0" class="zero">-</span>
                          <span *ngIf="summary.v6Months.Low != 0">{{ absify(summary.v6Months.Low) }}</span>
                          <span *ngIf="summary.v6Months.Low > 0">&nbsp;▲</span>
                          <span *ngIf="summary.v6Months.Low < 0">&nbsp;▼</span>
                        </span>

                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div *ngIf="activeTab === 1">
              <p-chart type="line" width="500px" height="200px" [data]="pieData" [options]="pieOptions"></p-chart>

              <!-- Chart Options Start -->
              <div class="chart-options-header" (click)="toggleChartOptions()">
                <span>Chart Options</span>
                <i class="toggle-icon">
                  <i class="pi" [ngClass]="{'pi-chevron-down': !isOptionsExpanded, 'pi-chevron-up': isOptionsExpanded}"></i>
                </i>
              </div>
              <div class="chart-options-content" *ngIf="isOptionsExpanded">
                <div class="flex flex-wrap gap-3 justify-center scale-options">
                  <div class="flex align-items-center">
                    <p-radioButton name="chartscale"
                                   value="last7days"
                                   [(ngModel)]="chartScale"
                                   (ngModelChange)="onScaleSelectionChange($event)"
                                   inputId="option1" />
                    <label for="option1" class="ml-2">
                      Last 7 Days
                    </label>
                  </div>

                  <div class="flex align-items-center">
                    <p-radioButton name="chartscale"
                                   value="last30days"
                                   [(ngModel)]="chartScale"
                                   (ngModelChange)="onScaleSelectionChange($event)"
                                   inputId="option2" />
                    <label for="option2" class="ml-2">
                      Last 30 Days
                    </label>
                  </div>

                  <div class="flex align-items-center">
                    <p-radioButton name="chartscale"
                                   value="last6months"
                                   [(ngModel)]="chartScale"
                                   (ngModelChange)="onScaleSelectionChange($event)"
                                   inputId="option3" />
                    <label for="option3" class="ml-2">
                      Last 6 Months
                    </label>
                  </div>

                  <div class="flex align-items-center">
                    <p-radioButton name="chartscale"
                                   value="alltime"
                                   [(ngModel)]="chartScale"
                                   (ngModelChange)="onScaleSelectionChange($event)"
                                   inputId="option4" />
                    <label for="option4" class="ml-2">
                      All-Time
                    </label>
                  </div>
                </div>
                <div class="flex flex-wrap gap-3 justify-center scale-options">
                  <div class="flex align-items-center">
                    <p-checkbox [(ngModel)]="chartCritical"
                                inputId="select1"
                                name="chartCritical"
                                (ngModelChange)="onScaleSelectionChange($event)"
                                [binary]="true" />
                    <label for="select1" class="ml-2">
                      Critical <span style="color: #8B0000">■</span>
                    </label>
                  </div>
                  <div class="flex align-items-center">
                    <p-checkbox [(ngModel)]="chartHigh"
                                name="chartHigh"
                                inputId="select2"
                                (ngModelChange)="onScaleSelectionChange($event)"
                                [binary]="true" />
                    <label for="select2" class="ml-2">
                      High <span style="color:#FF4500">■</span>
                    </label>
                  </div>
                  <div class="flex align-items-center">
                    <p-checkbox [(ngModel)]="chartMedium"
                                inputId="select3"
                                name="chartMedium"
                                (ngModelChange)="onScaleSelectionChange($event)"
                                [binary]="true" />
                    <label for="select3" class="ml-2">
                      Medium <span style="color: #FFA500">■</span>
                    </label>
                  </div>
                  <div class="flex align-items-center">
                    <p-checkbox [(ngModel)]="chartLow"
                                inputId="select4"
                                name="chartLow"
                                (ngModelChange)="onScaleSelectionChange($event)"
                                [binary]="true" />
                    <label for="select4" class="ml-2">
                      Low <span style="color: #FFD700">■</span>
                    </label>
                  </div>
                </div>
              </div>
              <!-- Chart Options End -->
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </ng-container>
</div>
