<h1>Assets</h1>

<p-messages [(value)]="messages" [enableService]="false" [closable]="true"></p-messages>

<div class="flex justify-content-between align-items-center mb-2" *ngIf="displayAssets.length > 0">
  <span>
  </span>
  <span>
    <button type="button" pButton pRipple icon="pi pi-file-excel" (click)="exportExcel()" 
      class="p-button-success" 
      pTooltip="XLS" tooltipPosition="bottom">
    </button>
  </span>
</div>

<ng-container *ngIf="displayAssets.length > 0">
  <p-table #dt1 [value]="displayAssets" [columns]="cols" dataKey="id" [rows]="10" [rowHover]="true" styleClass="p-datatable-gridlines" [paginator]="true" [globalFilterFields]="cols"
           [showCurrentPageReport]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" responsiveLayout="scroll"
           sortMode="multiple" stateStorage="session" stateKey="statedemo-session" [rowsPerPageOptions]="[10, 20, 30, 40]">

    <ng-template pTemplate="caption">
      <div class="flex justify-content-between flex-column sm:flex-row">
        <button pButton label="Clear" class="p-button-outlined mb-2" icon="pi pi-filter-slash" (click)="clear(dt1)"></button>

        <span class="p-input-icon-left mb-2">
          <i class="pi pi-search"></i>
          <input pInputText type="text" #filter (input)="onGlobalFilter(dt1, $event)" placeholder="Search Keyword" class="w-full" />
        </span>
      </div>
    </ng-template>

    <ng-template pTemplate="header" let-columns>
      <tr>
        <th style="min-width: 8rem" pSortableColumn="Name">
          <div class="flex justify-content-between align-items-center">
            <div>
              Name
              <p-sortIcon field="Name"></p-sortIcon>
            </div>
            <p-columnFilter type="text" field="Name" display="menu" placeholder="Search by Name" (click)="$event.stopPropagation()"></p-columnFilter>
          </div>
        </th>
        <th style="min-width: 8rem" pSortableColumn="Type">
          <div class="flex justify-content-between align-items-center">
            <div>
              Type
              <p-sortIcon field="Type"></p-sortIcon>
            </div>
            <p-columnFilter type="text" field="Type" display="menu" placeholder="Search by Type" (click)="$event.stopPropagation()"></p-columnFilter>
          </div>
        </th>
        <th style="min-width: 8rem" pSortableColumn="OperatingSystem">
          <div class="flex justify-content-between align-items-center">
            <div>
              Operating System
              <p-sortIcon field="OperatingSystem"></p-sortIcon>
            </div>
            <p-columnFilter type="text" field="OperatingSystem" display="menu" placeholder="Search by Operating System" (click)="$event.stopPropagation()"></p-columnFilter>
          </div>
        </th>
        <th style="min-width: 8rem" pSortableColumn="FQDN">
          <div class="flex justify-content-between align-items-center">
            <div>
              FQDN
              <p-sortIcon field="FQDN"></p-sortIcon>
            </div>
            <p-columnFilter type="text" field="FQDN" display="menu" placeholder="Search by FQDN" (click)="$event.stopPropagation()"></p-columnFilter>
          </div>
        </th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-asset>
      <tr>
        <td><a [routerLink]="['/asset', asset.id, 'details']">{{ asset.Name }}</a></td>
        <td>
          <a [routerLink]="['/asset', asset.id, 'details']">
            <p-tag value="{{ asset.Type }}" [styleClass]="'cursor-pointer hostingprovider-badge provider-' + tagify(asset.Type)"
                   pTooltip="{{ asset.Type }}" tooltipPosition="top"></p-tag>
          </a>
        </td>
        <td>
          <a [routerLink]="['/asset', asset.id, 'details']">
            <p-tag value="{{ asset.OperatingSystem }}" [styleClass]="'cursor-pointer techplatform-badge platform-' + tagify(asset.OperatingSystem)"
                   pTooltip="{{ asset.OperatingSystem }}" tooltipPosition="top"></p-tag>
          </a>
        </td>
        <td><a [routerLink]="['/asset', asset.id, 'details']">{{ asset.FQDN }}</a></td>

      </tr>
    </ng-template>

    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="10">Loading asset data. Please wait...</td>
      </tr>
    </ng-template>
    <ng-template pTemplate="loadingbody">
      <tr>
        <td colspan="10">Loading asset data. Please wait...</td>
      </tr>
    </ng-template>
  </p-table>
</ng-container>
<ng-container *ngIf="displayAssets.length == 0 && !isAssetsLoading">
  <span>There are no assets for this product. You will need to set a valid 'Nessus' tool reference in the left-hand menu</span>
</ng-container>
<ng-container *ngIf="isAssetsLoading">
  <p-skeleton width="10%" height="1rem"></p-skeleton>
</ng-container>
