import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppMainComponent } from './layout/app-main/app.main.component';
import { NotfoundComponent } from './layout/notfound/notfound.component';
import { DashboardComponent } from './layout/components/home/dashboard/dashboard.component';
import { ProductMasterListComponent } from './layout/components/home/product-master-list/product-master-list.component';
import { SuiteMasterListComponent } from './layout/components/home/suite-master-list/suite-master-list.component';
import { TechRadarComponent } from './layout/components/home/tech-radar/tech-radar.component';
import { ApprovedSoftwareComponent } from './layout/components/home/approved-software/approved-software.component';
import { ProductDashboardComponent } from './layout/components/product/product-dashboard/product-dashboard.component';
import { ProductLinksComponent } from './layout/components/product/product-links/product-links.component';
import { ProductDetailsComponent } from './layout/components/product/product-details/product-details.component';
import { ProductSDLCComponent } from './layout/components/product/product-sdlc/product-sdlc.component';
import { ThreatModellingComponent } from './layout/components/product/product-sdlc/threat-modelling/threat-modelling.component';
import { SCAComponent } from './layout/components/product/product-sdlc/sca/sca.component';
import { NPDASTComponent } from './layout/components/product/product-sdlc/npdast/npdast.component';
import { OneDayPentestComponent } from './layout/components/product/product-sdlc/oneday-pentest/oneday-pentest.component';
import { PentestComponent } from './layout/components/product/product-sdlc/pentest/pentest.component';
import { IncidentResponseComponent } from './layout/components/product/product-sdlc/incident-response/incident-response.component';
import { SASTComponent } from './layout/components/product/product-sdlc/sast/sast.component';
import { DASTComponent } from './layout/components/product/product-sdlc/dast/dast.component';
import { ProductTeamComponent } from './layout/components/product/product-team/product-team.component';
import { ProductToolRefsComponent } from './layout/components/product/product-tool-refs/product-tool-refs.component';
import { BrowserUtils } from '@azure/msal-browser';
import { MsalGuard } from '@azure/msal-angular';
import { SDLCApprovalComponent } from './layout/components/admin/sdlcapproval/sdlcapproval.component';
import { VersionComponent } from './layout/components/version/version.component';
import { RoleGuard } from './role.guard';
import { OnedayPentestManagerComponent } from './layout/components/admin/oneday-pentest-manager/oneday-pentest-manager.component';
import { ProductExistsGuard } from './product-exist.guard';
import { UploadFindingsComponent } from './layout/components/admin/upload-findings/upload-findings.component';
import { SuiteDashboardComponent } from './layout/components/suite/suite-dashboard/suite-dashboard.component';
import { SuiteDetailsComponent } from './layout/components/suite/suite-details/suite-details.component';
import { SuiteToolRefsComponent } from './layout/components/suite/suite-tool-refs/suite-tool-refs.component';
import { ProductAssetsComponent } from './layout/components/product/product-assets/product-assets.component';
import { AssetDetailsComponent } from './layout/components/asset/asset-details/asset-details.component';
import { SuiteAssetsComponent } from './layout/components/suite/suite-assets/suite-assets.component';
import { ProductArbsComponent } from './layout/components/product/product-architecture/product-arbs.component';
import { SuiteArbsComponent } from './layout/components/suite/suite-architecture/suite-arbs.component';
import { ArbListComponent } from './layout/components/arbs/list/arb-list.component';
import { ArbDetailsComponent} from './layout/components/arbs/details/arb-details.component';
import { SDLCDashboardComponent } from './layout/components/product/product-sdlc/dashboard/dashboard.component';
import { PracticeMaturityLevelsComponent } from './layout/components/admin/sdlcpractices/practicematuritylevels/practicematuritylevels.component';
import { SDLCPracticeMaturityComponent } from './layout/components/admin/sdlcpractices/sdlcpracticematurity/sdlcpracticematurity.component';
import { PracticeComponent } from './layout/components/admin/sdlcpractices/practice/practice.component';
import { SDLCMaturityReport } from './layout/components/reports/sdlcmaturity/sdlcmaturityreport.component';
import { VulnDetailsComponent } from './layout/components/vulnerabilities/vuln-details/vuln-details.component';
import { VulnSearchComponent } from './layout/components/vulnerabilities/vuln-search/vuln-search.component';
import { VulnChangeComponent } from './layout/components/vulnerabilities/vuln-change/vuln-change.component';
import { AdminDashboardComponent } from './layout/components/admin/admin-dashboard/admin-dashboard.component';
import { PortfolioOverviewReport} from './layout/components/reports/portfoliooverview/portfoliooverview.component';
import { ElementsQuicksightComponent } from './layout/components/reports/elements-quicksight/elements-quicksight.component';
import { UsefulToolsComponent } from './layout/components/home/useful-tools/useful-tools.component';
import { NFRCategoriesComponent } from './layout/components/admin/nfrs//nfr-categories/nfr-categories';
import { NFRComponent } from './layout/components/admin/nfrs/nfrs/nfrs';
import { ProductNFRComponent } from './layout/components/nfrs/nfrs';
import { MasterVulnReportReport } from './layout/components/reports/mastervulnreport/mastervulnreport.component';
import { ReportRecipientComponent } from './layout/components/reports/report-recipient/report-recipient.component';
import { ReportSubscriptionComponent } from './layout/components/reports/report-subscription/report-subscription.component';
import { TechDebtReport } from './layout/components/reports/techdebt/techdebtreport.component';

const routes: Routes = [
  {
    path: '',
    component: AppMainComponent,
    canActivate: [MsalGuard],
    children: [
      { path: 'home/dashboard', component: DashboardComponent },
      { path: 'home/products', component: ProductMasterListComponent },
      { path: 'home/suites', component: SuiteMasterListComponent },
      { path: 'home/arb-list', component: ArbListComponent },
      { path: 'home/tech-radar', component: TechRadarComponent },
      { path: 'home/approved-software', component: ApprovedSoftwareComponent },
      { path: 'home/useful-tools', component: UsefulToolsComponent },
      {
        path: 'reports',
        children: [
          { path: 'sdlcmaturity', component: SDLCMaturityReport },
          { path: 'portfoliooverview', component: PortfolioOverviewReport },
          { path: 'mastervulnreport', component: MasterVulnReportReport },
          { path: 'techdebt', component: TechDebtReport },
          { path: 'reportrecipients', component: ReportRecipientComponent },
          
          { path: 'subscriptions', component: ReportSubscriptionComponent }
        ]
      },

      // { path: 'product/:id/dashboard', component: ProductDashboardComponent },
      // { path: 'product/:id/team', component: ProductTeamComponent },
      // { path: 'product/:id/links', component: ProductLinksComponent },
      {
        path: 'product/:id',
        canActivate: [ProductExistsGuard],
        children: [
          { path: 'dashboard', component: ProductDashboardComponent },
          { path: 'details', component: ProductDetailsComponent },
          { path: 'team', component: ProductTeamComponent },
          { path: 'links', component: ProductLinksComponent },
          { path: 'tool-refs', component: ProductToolRefsComponent },
          { path: 'assets', component: ProductAssetsComponent },
          { path: 'nfrs', component: ProductNFRComponent },
          { path: 'sdlc', component: ProductSDLCComponent },
          { path: 'sdlc/dashboard', component: SDLCDashboardComponent },
          { path: 'sdlc/threatmodelling', component: ThreatModellingComponent },
          { path: 'sdlc/sca', component: SCAComponent },
          { path: 'sdlc/dast', component: DASTComponent },
          { path: 'sdlc/sast', component: SASTComponent },
          { path: 'sdlc/npdast', component: NPDASTComponent },
          { path: 'sdlc/onedaypentest', component: OneDayPentestComponent },
          { path: 'sdlc/pentests', component: PentestComponent },
          { path: 'sdlc/incidentresponse', component: IncidentResponseComponent },
          { path: 'architecture/arbs', component: ProductArbsComponent },
          { path: "elements-quicksight", component: ElementsQuicksightComponent }
        ]
      },
      {
        path: 'suite/:suiteId',
        canActivate: [],
        children: [
          { path: 'dashboard', component: SuiteDashboardComponent },
          { path: 'details', component: SuiteDetailsComponent },
          { path: 'tool-refs', component: SuiteToolRefsComponent },
          { path: 'assets', component: SuiteAssetsComponent },
          { path: 'nfrs', component: ProductNFRComponent },
          { path: 'architecture/arbs', component: SuiteArbsComponent },
          { path: "elements-quicksight", component: ElementsQuicksightComponent }
        ]
      },
      {
        path: 'asset/:assetId',
        canActivate: [],
        children: [
          { path: 'details', component: AssetDetailsComponent },
        ]
      },      
      {
        path: 'vulnerabilities',
        canActivate: [],
        children: [
          { path: 'search', component: VulnSearchComponent },
          { path: 'changes', component: VulnChangeComponent },
          {
            path: ':vulnId',
            canActivate: [],
            children: [
              { path: 'details', component: VulnDetailsComponent },
            ]
          },
        ]
      },
      {
        path: 'arb/:arbCode',
        canActivate: [],
        children: [
          { path: 'details', component: ArbDetailsComponent },
        ]
      },
      { path: "elements-quicksight", component: ElementsQuicksightComponent },
      {
        path: 'admin',
        canActivate: [MsalGuard, RoleGuard],
        data : { expectedRole: 'Lens.Admin' },
        children: [
          { path: '', component: AdminDashboardComponent },
          { path: 'sdlc/approval', component: SDLCApprovalComponent },
          { path: 'sdlc/onedaymanager', component: OnedayPentestManagerComponent },
          { path: 'sdlc/uploadfindings', component: UploadFindingsComponent },
          { path: 'sdlc/practices', component: PracticeComponent },
          {
            path: 'sdlc/practices/:practiceId',
            canActivate: [],
            children: [              
              { path: 'maturitylevels', component: PracticeMaturityLevelsComponent },
              { path: 'sdlcpracticematurity', component: SDLCPracticeMaturityComponent },

            ]
          },
          {
            path: 'nfrs',
            canActivate: [],
            children: [
              { path: 'categories', component: NFRCategoriesComponent },
              { path: 'requirements', component: NFRComponent },
            ]
          }
        ]
      },
      { path: "", redirectTo: "/home/dashboard", pathMatch: "full" },
      { path: "api/auth/callback/azure-ad", redirectTo: "/home/dashboard", pathMatch: "full" },
    ]
  },
  { path: 'version', component: VersionComponent },
  { path: '**', component: NotfoundComponent },
]

const isIframe = window !== window.parent && !window.opener;

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      // scrollOffset: [0, 64],
      
      initialNavigation:
      !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup()
        ? "enabledNonBlocking"
        : "disabled", // Set to enabledBlocking to use Angular Universal
      }
    ),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
