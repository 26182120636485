<h1>Details</h1>

<p-toast></p-toast>
  <p-tabView #tabView>
    <p-tabPanel header="Basic">
      <div *ngIf="isBasicLoaded">
        <div class="productdetails-container">
          <div class="productdetails-top-row">
            <div class="productdetails-left-column">
              <p-panel>
                <ng-template pTemplate="header">
                  <div class="details-panel-header-container">
                    <span class="font-bold details-panel-header-title">
                      Info
                    </span>
                    <button type="button" pbutton="" icon="pi pi-pencil" class="p-element p-button p-component p-button-icon-only details-panel-header-edit-button" ng-reflect-icon="pi pi-pencil" title="Edit Details" (click)="enableEditBasicDetails(true)" [ngClass]="{'edit-button-hidden': editBasicDetails}">
                      <span class="p-button-icon pi pi-pencil" aria-hidden="true" style="margin-right:10px"></span>
                    </button>
                  </div>
                </ng-template>
                <div class="productdetails-attribute">
                  <div class="productdetails-label">Product Name:</div>
                  <div class="productdetails-value" *ngIf="!editBasicDetails">{{ product?.Name ?? "" }}</div>
                  <div class="productdetails-value" *ngIf="editBasicDetails && editedProduct">
                    <input type="text" pInputText id="name" class="uniform-width-input" [(ngModel)]="editedProduct.Name" required autofocus [ngClass]="{'ng-invalid ng-dirty' : submitted && !editedProduct.Name}" />
                  </div>
                </div>
                <div class="productdetails-attribute">
                  <div class="productdetails-label">Product Aliases:</div>
                  <div class="productdetails-value" *ngIf="!editBasicDetails">{{ aliases }}</div>
                  <div class="productdetails-value" *ngIf="editBasicDetails">
                    <p-chips [(ngModel)]="editingAliases" class="uniform-width-input" [style]="{'minWidth':'90%', 'maxWidth': '400px'}"></p-chips>
                  </div>
                </div>
                <div class="productdetails-attribute">
                  <div class="productdetails-label">Product Reference:</div>
                  <div class="productdetails-value" *ngIf="!editBasicDetails">{{ product?.ProductRef ?? "" }}</div>
                  <div class="productdetails-value" *ngIf="editBasicDetails && editedProduct">
                    <input type="text" pInputText id="productRef" class="uniform-width-input" [(ngModel)]="editedProduct.ProductRef" />
                  </div>
                </div>
                <div class="productdetails-attribute">
                  <div class="productdetails-label">Parent Suite:</div>
                  <div class="productdetails-value" *ngIf="!editBasicDetails">
                    <ng-container *ngIf="product?.SuiteName">
                      <a [routerLink]="['/suite', product?.SuiteId ?? 0, 'dashboard']" target="_blank" class="no-decoration">
                        <p-tag value="{{ product?.SuiteName }}" [styleClass]="'cursor-pointer suite-badge suite-' + tagify(product?.SuiteName)" pTooltip="Suite" tooltipPosition="top"></p-tag>
                      </a>
                    </ng-container>
                  </div>
                  <div class="productdetails-value" *ngIf="editBasicDetails && editedProduct">
                    <p-dropdown appendTo="body" [(ngModel)]="editedProduct.SuiteId" inputId="SuiteId" [options]="suites" placeholder="Select" optionValue="Id" class="uniform-width-input" [style]="{'minWidth':'90%', 'maxWidth': '400px'}">
                      <ng-template pTemplate="selectedItem">
                        <span *ngIf="editedProduct.SuiteId" [class]="'suite-badge suite-' + trimOption(getSuiteNameById(editedProduct.SuiteId))">{{ getSuiteNameById(editedProduct.SuiteId) }}</span>
                      </ng-template>
                      <ng-template let-option pTemplate="item">
                        <span [class]="'suite-badge suite-' + trimOption(option.Name)">{{option.Name}}</span>
                      </ng-template>
                    </p-dropdown>
                  </div>
                </div>
                <div class="productdetails-attribute">
                  <div class="productdetails-label">Division:</div>
                  <div class="productdetails-value" *ngIf="!editBasicDetails">
                    <ng-container *ngIf="product?.DivisionName">
                      <p-tag value="{{ product?.DivisionName }}" [styleClass]="'cursor-pointer division-badge division-' + tagify(product?.DivisionName)" pTooltip="Division" tooltipPosition="top"></p-tag>
                    </ng-container>
                  </div>
                  <div class="productdetails-value" *ngIf="editBasicDetails && editedProduct">
                    <p-dropdown appendTo="body" [(ngModel)]="editedProduct.DivisionId" inputId="DivisionId" [options]="divisions" placeholder="Select" optionValue="Id" class="uniform-width-input" [style]="{'minWidth':'90%', 'maxWidth': '400px'}">
                      <ng-template pTemplate="selectedItem">
                        <span *ngIf="editedProduct.DivisionId" [class]="'division-badge division-' + trimOption(getDivisionNameById(editedProduct.DivisionId))">{{ getDivisionNameById(editedProduct.DivisionId) }}</span>
                      </ng-template>
                      <ng-template let-option pTemplate="item">
                        <span [class]="'division-badge division-' + trimOption(option.Name)">{{option.Name}}</span>
                      </ng-template>
                    </p-dropdown>
                  </div>
                </div>
                <div class="productdetails-attribute">
                  <div class="productdetails-label">Tier:</div>
                  <div class="productdetails-value" *ngIf="!editBasicDetails">
                    <ng-container *ngIf="productMetric?.TierName">
                      <a [href]="'https://irissoftware.sharepoint.com/sites/EngineeringIntranet/SitePages/Secure-Software-Development-Lifecycle.aspx#product-tiers'" target="_blank">
                        <p-tag value="{{ productMetric?.TierName }}" [styleClass]="'cursor-pointer tier-badge tier-' + tagify(productMetric?.TierName)" pTooltip="Product Tier" tooltipPosition="top"></p-tag>
                      </a>
                    </ng-container>
                  </div>

                  <div class="productdetails-value" *ngIf="editBasicDetails">
                    <p-dropdown appendTo="body" [(ngModel)]="editedProductMetric.TierId" inputId="TierId" [options]="tiers" placeholder="Select" optionValue="Id" class="uniform-width-input" [style]="{'minWidth':'90%', 'maxWidth': '400px'}" *appHasRole="['Lens.Admin']">
                      <ng-template pTemplate="selectedItem">
                        <span *ngIf="editedProductMetric.TierId" [class]="'tier-badge tier-' + tagify(getTierNameById(editedProductMetric.TierId))">{{ getTierNameById(editedProductMetric.TierId) }}</span>
                      </ng-template>
                      <ng-template let-option pTemplate="item">
                        <span [class]="'tier-badge tier-' + tagify(option.Name)">{{option.Name}}</span>
                      </ng-template>
                    </p-dropdown>
                  </div>
                </div>
                <div class="productdetails-attribute">
                  <div class="productdetails-label">Country:</div>
                  <div class="productdetails-value" *ngIf="!editBasicDetails">
                    <ng-container *ngIf="product?.CountryName">
                      <a target="_blank" class="no-decoration">
                        <p-tag value="{{ product?.CountryName }}" [styleClass]="'cursor-pointer suite-badge suite-' + tagify(product?.CountryName)" pTooltip="Country" tooltipPosition="top"></p-tag>
                        </a>
                    </ng-container>
                  </div>
                  <div class="productdetails-value" *ngIf="editBasicDetails">
                    <p-dropdown appendTo="body" [(ngModel)]="editedProduct.CountryId" inputId="CountryId" [options]="countries" placeholder="Select" optionValue="Id" class="uniform-width-input" [style]="{'minWidth':'90%', 'maxWidth': '400px'}">
                      <ng-template pTemplate="selectedItem">
                        <span *ngIf="editedProduct.CountryId" [class]="'suite-badge suite-' + trimOption(getCountryNameById(editedProduct.CountryId))">{{ getCountryNameById(editedProduct.CountryId) }}</span>
                      </ng-template>
                      <ng-template let-option pTemplate="item">
                        <span [class]="'suite-badge suite-' + trimOption(option.Name)">{{option.Name}}</span>
                      </ng-template>
                    </p-dropdown>
                  </div>
                </div>

                <div class="productdetails-attribute">
                  <div class="productdetails-label">Tenancy:</div>
                  <div class="productdetails-value" *ngIf="!editBasicDetails">
                    <ng-container *ngIf="product?.TenancyName">
                      <a target="_blank" class="no-decoration">
                        <p-tag value="{{ product?.TenancyName }}" [styleClass]="'cursor-pointer suite-badge suite-' + tagify(product?.TenancyName)" pTooltip="Tenancy" tooltipPosition="top"></p-tag>
                        </a>
                    </ng-container>
                  </div>
                  <div class="productdetails-value" *ngIf="editBasicDetails">
                    <p-dropdown appendTo="body" [(ngModel)]="editedProduct.TenancyId" inputId="TenancyId" [options]="tenancies" placeholder="Select" optionValue="Id" class="uniform-width-input" [style]="{'minWidth':'90%', 'maxWidth': '400px'}">
                      <ng-template pTemplate="selectedItem">
                        <span *ngIf="editedProduct.TenancyId" [class]="'suite-badge suite-' + trimOption(getTenanciesName(editedProduct.TenancyId))">{{ getTenanciesName(editedProduct.TenancyId) }}</span>
                      </ng-template>
                      <ng-template let-option pTemplate="item">
                        <span [class]="'suite-badge suite-' + trimOption(option.Name)">{{option.Name}}</span>
                      </ng-template>
                    </p-dropdown>
                  </div>
                </div>

                <div class="productdetails-attribute">
                  <div class="productdetails-label">Product Type:</div>
                  <div class="productdetails-value" *ngIf="!editBasicDetails">
                    <ng-container *ngIf="product?.ProductTypeName">
                      <p-tag value="{{ product?.ProductTypeName }}" [styleClass]="'cursor-pointer productType-badge productType-' + tagify(product?.ProductTypeName)" pTooltip="Product Type" tooltipPosition="top"></p-tag>
                    </ng-container>
                  </div>
                  <div class="productdetails-value" *ngIf="editBasicDetails && editedProduct">
                    <p-dropdown appendTo="body" [(ngModel)]="editedProduct.ProductTypeId" inputId="ProductTypeId" [options]="productTypes" placeholder="Select" optionValue="Id" class="uniform-width-input" [style]="{'minWidth':'90%', 'maxWidth': '400px'}">
                      <ng-template pTemplate="selectedItem">
                        <span *ngIf="editedProduct.ProductTypeId" [class]="'productType-badge productType-' + trimOption(getProductTypeNameById(editedProduct.ProductTypeId))">{{ getProductTypeNameById(editedProduct.ProductTypeId) }}</span>
                      </ng-template>
                      <ng-template let-option pTemplate="item">
                        <span [class]="'productType-badge productType-' + trimOption(option.Name)">{{option.Name}}</span>
                      </ng-template>
                    </p-dropdown>
                  </div>
                </div>
                <div class="productdetails-attribute">
                  <div class="productdetails-label">Origin Company:</div>
                  <div class="productdetails-value" *ngIf="!editBasicDetails">{{ product?.OriginCompany ?? "" }}</div>
                  <div class="productdetails-value" *ngIf="editBasicDetails">
                    <input type="text" pInputText id="originCompany" class="uniform-width-input" [(ngModel)]="editedProduct.OriginCompany" />
                  </div>
                </div>
                <div class="productdetails-attribute">
                  <div class="productdetails-label">Product Home Page:</div>
                  <div class="productdetails-value" *ngIf="!editBasicDetails">{{ product?.HomepageURI ?? "" }}</div>
                  <div class="productdetails-value" *ngIf="editBasicDetails">
                    <input type="text" pInputText id="HomepageURI" class="uniform-width-input" [(ngModel)]="editedProduct.HomepageURI" />
                  </div>
                </div>
                <ng-template pTemplate="footer">
                  <div *ngIf="editBasicDetails">
                    <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="enableEditBasicDetails(false)"></button>
                    <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text" (click)="updateBasicDetails()"></button>
                  </div>
                </ng-template>
              </p-panel>
            </div>
            <div class="productdetails-right-column">
              <p-panel>
                <ng-template pTemplate="header">
                  <div class="details-panel-header-container">
                    <span class="font-bold details-panel-header-title">
                      Status
                    </span>
                    <button type="button" pbutton="" icon="pi pi-pencil" class="p-element p-button p-component p-button-icon-only details-panel-header-edit-button" ng-reflect-icon="pi pi-pencil" title="Edit Product Status" (click)="enableEditStatus(true)" [ngClass]="{'edit-button-hidden': editStatus}">
                      <span class="p-button-icon pi pi-pencil" aria-hidden="true" style="margin-right:10px"></span>
                    </button>
                  </div>
                </ng-template>
                <div class="productdetails-status-box" *ngIf="!editStatus" [ngClass]="{'active': product?.ActiveStatus, 'inactive': !product?.ActiveStatus}">
                  {{ product?.ActiveStatus ? 'Active' : 'Inactive' }}
                </div>
                <div class="productdetails-attribute" *ngIf="editStatus">
                  <div class="productdetails-label"><label for="chkEoL">Is Active?:</label></div>
                  <div class="productdetails-value"><input id="chkEoL" type="checkbox" [(ngModel)]="editedStatus" /></div>
                </div>
                <div class="productdetails-attribute" *ngIf="!editStatus" style="margin-top:10px;">
                  <div class="productdetails-label">EOL Date:</div>
                  <div class="productdetails-value">{{ displayNiceDate(product?.EoLDate) }}</div>
                </div>
                <div class="productdetails-attribute" *ngIf="editStatus" style="margin-top:10px;">
                  <div class="productdetails-label">EOL Date:</div>
                  <div class="productdetails-value">
                    <p-calendar appendTo="body" [showIcon]="true" [(ngModel)]="editedEoLDate" id="eolDate" dateFormat="dd/mm/yy"></p-calendar>
                  </div>
                </div>
                <ng-template pTemplate="footer">
                  <div *ngIf="editStatus">
                    <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="enableEditStatus(false)"></button>
                    <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text" (click)="updateStatus()"></button>
                  </div>
                </ng-template>
              </p-panel>
              <p-panel styleClass="details-panel">
                <ng-template pTemplate="header">
                  <div class="details-panel-header-container">
                    <span class="font-bold details-panel-header-title">
                      Product Logo
                    </span>
                    <button type="button" pbutton="" icon="pi pi-pencil" class="p-element p-button p-component p-button-icon-only details-panel-header-edit-button" ng-reflect-icon="pi pi-pencil" title="Edit Logo" (click)="enableEditLogo(true)" [ngClass]="{'edit-button-hidden': editLogo}">
                      <span class="p-button-icon pi pi-pencil" aria-hidden="true" style="margin-right:10px"></span>
                    </button>
                  </div>
                </ng-template>
                <img [src]="product?.Logo" *ngIf="product?.Logo" class="product-details-logo-display" />
                <span *ngIf="!product?.Logo">(No logo provided)</span>

                <div class="logo-buttons" *ngIf="editLogo">
                  <button type="button" pbutton="" icon="pi pi-upload" class="logo-button p-element p-button p-component p-button-icon-only details-panel-header-edit-button" ng-reflect-icon="pi pi-upload" title="Upload File" (click)="openFileDialog()">
                    <span class="p-button-icon pi pi-upload" aria-hidden="true" style="margin-right:12px"></span>
                  </button>
                  <button type="button" pbutton="" icon="pi pi-trash" class="logo-button p-element p-button p-button-danger p-component p-button-icon-only details-panel-header-edit-button" ng-reflect-icon="pi pi-trash" title="Clear Logo" (click)="clearLogo()">
                    <span class="p-button-icon pi pi-trash" aria-hidden="true" style="margin-right:12px"></span>
                  </button>
                  <input type="file" id="fileInput" (change)="onFileSelected($event)" accept="image/*" style="display: none;">
                </div>

                <ng-template pTemplate="footer">
                  <div *ngIf="editLogo">
                    <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="enableEditLogo(false)"></button>
                    <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text" (click)="updateLogo()"></button>
                  </div>
                </ng-template>
              </p-panel>
            </div>
          </div>
          <div class="productdetails-bottom-row">
            <p-panel>
              <ng-template pTemplate="header">
                <div class="details-panel-header-container">
                  <span class="font-bold details-panel-header-title">
                    Description
                  </span>
                  <button type="button" pbutton="" icon="pi pi-pencil" class="p-element p-button p-component p-button-icon-only details-panel-header-edit-button" ng-reflect-icon="pi pi-pencil" title="Edit Details" (click)="enableEditDescription(true)" [ngClass]="{'edit-button-hidden': editDescription}">
                    <span class="p-button-icon pi pi-pencil" aria-hidden="true" style="margin-right:10px"></span>
                  </button>
                </div>
              </ng-template>
              <div class="productdetails-description">
                <span *ngIf="!editDescription">{{ product?.Description ?? "" }}</span>
                <span *ngIf="editDescription">
                  <textarea pInputTextarea id="editedDescription" class="uniform-width-input" rows="4" [(ngModel)]="editedDescription"></textarea>
                </span>
              </div>
              <ng-template pTemplate="footer">
                <div *ngIf="editDescription">
                  <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="enableEditDescription(false)"></button>
                  <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text" (click)="updateDescription()"></button>
                </div>
              </ng-template>
            </p-panel>
          </div>
        </div>
      </div>
      <div *ngIf="!isBasicLoaded">
          <p-skeleton width="10%" height="1rem"></p-skeleton>
      </div>
    </p-tabPanel>
    <p-tabPanel header="Technical">
      <div *ngIf="isTechLoaded">
        <div class="productdetails-container">
          <div class="button-container">
            <a 
              href="https://irissoftware.sharepoint.com/sites/EngineeringIntranet/SitePages/SDLM---Tech-Specs.aspx" 
              class="p-button p-button-rounded p-button-help p-mt-2 tech-help-button"
              target="_blank" 
              title="Go to Tech Specs Help Page">
              <i class="pi pi-question-circle"></i>
            </a>
          </div>

          <div class="productdetails-top-row">
            <div class="productdetails-left-column">
              <p-panel>
                <ng-template pTemplate="header">
                  <div class="details-panel-header-container">
                    <div class="flex align-items-center gap-2">
                      <span class="p-button-icon pi pi-server" style="font-size:x-large" aria-hidden="true"></span>
                      <span class="font-bold">
                        Host &amp; Run
                      </span>
                    </div>
                    <button type="button" pbutton="" icon="pi pi-pencil" class="p-element p-button p-component p-button-icon-only details-panel-header-edit-button" ng-reflect-icon="pi pi-pencil" title="Edit Hosting Details" (click)="enableEditHosting(true)" [ngClass]="{'edit-button-hidden': editHosting}">
                      <span class="p-button-icon pi pi-pencil" aria-hidden="true" style="margin-right:10px"></span>
                    </button>
                  </div>
                </ng-template>
                <div class="productdetails-attribute">
                  <div class="productdetails-label">Distributions:</div>
                  <div class="productdetails-value" *ngIf="!editHosting">
                    <ng-container *ngFor="let distro of selectedDistributions">
                      <p-tag value="{{ getDistributionName(distro) }}" [styleClass]="'cursor-pointer techdistribution-badge distro-' + tagify(getDistributionName(distro))"
                             pTooltip="{{ getDistributionName(distro) }}" tooltipPosition="top"></p-tag>
                    </ng-container>
                  </div>
                  <div class="productdetails-value" *ngIf="editHosting">
                    <p-multiSelect class="custom-multiSelect uniform-width-input" appendTo="body" [options]="distributions" [(ngModel)]="editedDistributions" defaultLabel="Select a Distribution" optionLabel="Name" optionValue="Id"
                                   [style]="{'minWidth':'90%', 'maxWidth': '400px'}"
                                   display="chip"></p-multiSelect>
                  </div>
                </div>
                <div class="productdetails-attribute">
                  <div class="productdetails-label">Platforms:</div>
                  <div class="productdetails-value" *ngIf="!editHosting">
                    <ng-container *ngFor="let platform of selectedPlatforms">
                      <p-tag value="{{ getPlatformName(platform) }}" [styleClass]="'cursor-pointer techplatform-badge platform-' + tagify(getPlatformName(platform))"
                             pTooltip="{{getPlatformName(platform) }}" tooltipPosition="top"></p-tag>
                    </ng-container>
                  </div>
                  <div class="productdetails-value" *ngIf="editHosting">
                    <p-multiSelect class="custom-multiSelect uniform-width-input" appendTo="body" [options]="platforms" [(ngModel)]="editedPlatforms" defaultLabel="Select a Platform" optionLabel="Name" optionValue="Id"
                                   [style]="{'minWidth':'90%', 'maxWidth': '400px'}"
                                   display="chip"></p-multiSelect>
                  </div>
                </div>
                <div class="productdetails-attribute">
                  <div class="productdetails-label">Data Platform:</div>
                  <div class="productdetails-value" *ngIf="!editHosting">
                    <ng-container *ngFor="let db of selectedDatabases">
                      <p-tag 
                        [appStyledTag]="{ 
                          colourCode: db.softwareDisplaySettings?.colourCode,
                          logo: db.softwareDisplaySettings?.logo,
                          backgroundColourCode: db.softwareDisplaySettings?.backgroundColourCode,
                          borderColor: db.softwareDisplaySettings?.borderColor || 'gray', 
                          name: db.name,
                          badgeType: 'techstack-badge' 
                        }"
                        [ngClass]="'cursor-pointer'"
                        pTooltip="{{ db.name }}" 
                        tooltipPosition="top">
                      </p-tag>
                    </ng-container>
                  </div>
                  <div class="productdetails-value flex" *ngIf="editHosting">
                    <div>
                      <p-multiSelect class="custom-multiSelect uniform-width-input" appendTo="body" [options]="databases" [(ngModel)]="editedDatabases" defaultLabel="Select a Data Platform" optionLabel="name" optionValue="id"
                                     [style]="{'minWidth':'100%', 'maxWidth': '220px'}"
                                     display="chip"></p-multiSelect>
                    </div>
                  </div>
                </div>
                
                <!-- <app-add-entry-dialog #addEntryDialog (entryAdded)="onEntryAdded($event)" [entryType]="entryType"></app-add-entry-dialog> -->
                
                <div class="productdetails-attribute">
                  <div class="productdetails-label">Application:</div>
                  <div class="productdetails-value" *ngIf="!editHosting">
                    <ng-container *ngFor="let app of selectedApplications">
                      <p-tag 
                        [appStyledTag]="{ 
                          colourCode: app.softwareDisplaySettings?.colourCode,
                          logo: app.softwareDisplaySettings?.logo,
                          backgroundColourCode: app.softwareDisplaySettings?.backgroundColourCode,
                          borderColor: app.softwareDisplaySettings?.borderColor || 'gray', 
                          name: app.name,
                          badgeType: 'techstack-badge' 
                        }"
                        [ngClass]="'cursor-pointer'"
                        pTooltip="{{ app.name }}" 
                        tooltipPosition="top">
                      </p-tag>
                    </ng-container>
                  </div>
                  <div class="productdetails-value flex" *ngIf="editHosting">
                    <div>
                      <p-multiSelect class="custom-multiSelect uniform-width-input" appendTo="body" [options]="applications" [(ngModel)]="editedApplications" defaultLabel="Select an Application" optionLabel="name" optionValue="id"
                                     [style]="{'minWidth':'90%', 'maxWidth': '220px'}"
                                     display="chip"></p-multiSelect>
                    </div>
                  </div>
                </div>
                
                <div class="productdetails-attribute">
                  <div class="productdetails-label">Front-End/Mobile:</div>
                  <div class="productdetails-value" *ngIf="!editHosting">
                    <ng-container *ngFor="let uxui of selectedUXUIs">
                      <p-tag 
                        [appStyledTag]="{ 
                          colourCode: uxui.softwareDisplaySettings?.colourCode,
                          logo: uxui.softwareDisplaySettings?.logo,
                          backgroundColourCode: uxui.softwareDisplaySettings?.backgroundColourCode,
                          borderColor: uxui.softwareDisplaySettings?.borderColor || 'gray', 
                          name: uxui.name,
                          badgeType: 'techstack-badge' 
                        }"
                        [ngClass]="'cursor-pointer'"
                        pTooltip="{{ uxui.name }}" 
                        tooltipPosition="top">
                      </p-tag>
                    </ng-container>
                  </div>
                  <div class="productdetails-value flex" *ngIf="editHosting">
                    <div>
                      <p-multiSelect class="custom-multiSelect uniform-width-input" appendTo="body" [options]="uxuis" [(ngModel)]="editedUXUIs" defaultLabel="Select a Front-End/Mobile tool" optionLabel="name" optionValue="id"
                                     [style]="{'minWidth':'90%', 'maxWidth': '220px'}"
                                     display="chip"></p-multiSelect>
                    </div>
                  </div>
                </div>

                <div class="productdetails-attribute">
                  <div class="productdetails-label">Programming Languages:</div>
                  <div class="productdetails-value" *ngIf="!editHosting">
                    <ng-container *ngFor="let lang of selectedProgrammingLanguages">
                      <p-tag 
                        [appStyledTag]="{ 
                          colourCode: lang.softwareDisplaySettings?.colourCode,
                          logo: lang.softwareDisplaySettings?.logo,
                          backgroundColourCode: lang.softwareDisplaySettings?.backgroundColourCode,
                          borderColor: lang.softwareDisplaySettings?.borderColor || 'gray', 
                          name: lang.name,
                          badgeType: 'techstack-badge' 
                        }"
                        [ngClass]="'cursor-pointer'"
                        pTooltip="{{ lang.name }}" 
                        tooltipPosition="top">
                      </p-tag>
                    </ng-container>
                  </div>
                  <div class="productdetails-value flex" *ngIf="editHosting">
                    <div>
                      <p-multiSelect class="custom-multiSelect uniform-width-input" appendTo="body" [options]="programmingLanguages" [(ngModel)]="editedProgrammingLanguages" defaultLabel="Select a Language" optionLabel="name" optionValue="id"
                                     [style]="{'minWidth':'90%', 'maxWidth': '220px'}"
                                     display="chip"></p-multiSelect>
                    </div>
                  </div>
                </div>

                <!-- <div class="productdetails-attribute">
                  <div class="productdetails-label">Other:</div>
                  <div class="productdetails-value" *ngIf="!editHosting">
                    <ng-container *ngFor="let techstack of selectedTechStacks">
                      <p-tag value="{{ getTechStackName(techstack) }}" [styleClass]="'cursor-pointer techstack-badge stack-' + tagify(getTechStackName(techstack))"
                             pTooltip="{{ getTechStackName(techstack) }}" tooltipPosition="top"></p-tag>
                    </ng-container>
                  </div>
                  <div class="productdetails-value flex" *ngIf="editHosting">
                    <div>
                      <p-multiSelect class="custom-multiSelect uniform-width-input" appendTo="body" [options]="techStacks" [(ngModel)]="editedTechStacks" defaultLabel="Select a Tech Stack" optionLabel="Name" optionValue="Id"
                                     [style]="{'minWidth':'90%', 'maxWidth': '220px'}"
                                     display="chip"></p-multiSelect>
                    </div>
                    <button pButton pRipple icon="pi pi-plus" class="p-button-success p-button-rounded ml-1" (click)="openAddEntryDialog('techstack')"></button>

                  </div>
                </div> -->
                <div class="productdetails-attribute">
                  <div class="productdetails-label">Providers:</div>
                  <div class="productdetails-value" *ngIf="!editHosting">
                    <ng-container *ngFor="let provider of selectedProviders">
                      <p-tag value="{{ getProviderName(provider) }}" [styleClass]="'cursor-pointer hostingprovider-badge provider-' + tagify(getProviderName(provider))"
                             pTooltip="{{ getProviderName(provider) }}" tooltipPosition="top"></p-tag>
                    </ng-container>
                  </div>
                  <div class="productdetails-value" *ngIf="editHosting">
                    <p-multiSelect class="custom-multiSelect uniform-width-input" appendTo="body" [options]="providers" [(ngModel)]="editedProviders" defaultLabel="Select a Hosting Provider" optionLabel="Name" optionValue="Id"
                                   [style]="{'minWidth':'90%', 'maxWidth': '400px'}"
                                   display="chip"></p-multiSelect>
                  </div>
                </div>

                <div class="productdetails-attribute">
                  <div class="productdetails-label">Authentication Mechanism:</div>
                  <div class="productdetails-value" *ngIf="!editHosting">
                    <ng-container *ngFor="let mechanism of selectedAuthenticationMechanisms">
                      <p-tag value="{{ getAuthenticationMechanismName(mechanism) }}" [styleClass]="'cursor-pointer authMechanism-badge auth-' + tagify(getAuthenticationMechanismName(mechanism))"
                             pTooltip="{{ getAuthenticationMechanismName(mechanism) }}" tooltipPosition="top"></p-tag>
                    </ng-container>
                  </div>
                  <div class="productdetails-value" *ngIf="editHosting">
                    <p-multiSelect class="custom-multiSelect uniform-width-input" appendTo="body" [options]="authenticationMechanisms" [(ngModel)]="editedAuthenticationMechanisms" defaultLabel="Select a Auth Mechanism" optionLabel="Name" optionValue="Id"
                                   [style]="{'minWidth':'90%', 'maxWidth': '400px'}"
                                   display="chip"></p-multiSelect>
                  </div>
                </div>

                <div class="productdetails-attribute">
                  <div class="productdetails-label">Automated Scaling enabled?</div>
                  <div class="productdetails-value" *ngIf="!editHosting">
                    <ng-container *ngIf="productTechSpec?.AutomatedHAName">
                      <a target="_blank" class="no-decoration">
                        <p-tag value="{{ productTechSpec?.AutomatedHAName }}" [styleClass]="'cursor-pointer suite-badge suite-' + tagify(productTechSpec?.AutomatedHAName)" pTooltip="AutomatedHA" tooltipPosition="top"></p-tag>
                        </a>
                    </ng-container>
                  </div>
                  <div class="productdetails-value" *ngIf="editHosting">
                    <p-dropdown appendTo="body" [(ngModel)]="editedProductTechSpec.AutomatedHAId" inputId="AutomatedHAId" [options]="automatedHAs" placeholder="Select" optionValue="Id" class="uniform-width-input" [style]="{'minWidth':'90%', 'maxWidth': '400px'}">
                      <ng-template pTemplate="selectedItem">
                        <span *ngIf="editedProductTechSpec.AutomatedHAId" [class]="'suite-badge suite-' + trimOption(getAutomatedHANameById(editedProductTechSpec.AutomatedHAId))">{{ getAutomatedHANameById(editedProductTechSpec.AutomatedHAId) }}</span>
                      </ng-template>
                      <ng-template let-option pTemplate="item">
                        <span [class]="'suite-badge suite-' + trimOption(option.Name)">{{option.Name}}</span>
                      </ng-template>
                    </p-dropdown>
                  </div>
                </div>

                <div class="productdetails-attribute">
                  <div class="productdetails-label">Environments:</div>
                  <div class="productdetails-value" *ngIf="!editHosting">
                    <ng-container *ngFor="let env of selectedEnvironments">
                      <p-tag value="{{ getEnvironmentName(env) }}" [styleClass]="'cursor-pointer techenvironments-badge'"
                             pTooltip="{{ getEnvironmentName(env) }}" tooltipPosition="top"></p-tag>
                    </ng-container>
                  </div>
                  <div class="productdetails-value" *ngIf="editHosting">
                    <p-multiSelect class="custom-multiSelect uniform-width-input" appendTo="body" [options]="environments" [(ngModel)]="editedEnvironments" defaultLabel="Select an Environment" optionLabel="Name" optionValue="Id"
                                   [style]="{'minWidth':'90%', 'maxWidth': '400px'}"
                                   display="chip"></p-multiSelect>
                  </div>
                </div>
                <div>
                  <div class="productdetails-description-label productdetails-attribute">Environment Comment:</div>
                  <div class="productdetails-description" *ngIf="!editHosting">{{ productTechSpec?.EnvironmentComment ?? "" }}</div>
                  <div class="productdetails-description" *ngIf="editHosting">
                    <textarea pInputTextarea id="editedHostingDescription" class="uniform-width-input" rows="4" [(ngModel)]="editedHostingDescription"></textarea>
                  </div>
                </div>
                <ng-template pTemplate="footer">
                  <div *ngIf="editHosting">
                    <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="enableEditHosting(false)"></button>
                    <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text" (click)="updateHosting()"></button>
                  </div>
                </ng-template>
              </p-panel>
            </div>
            <div class="productdetails-right-column">
              <p-panel>
                <ng-template pTemplate="header">
                  <div class="details-panel-header-container">
                    <div class="flex align-items-center gap-2">
                      <span class="p-button-icon pi pi-code" style="font-size:x-large" aria-hidden="true"></span>
                      <span class="font-bold">
                        Design &amp; Build
                      </span>
                    </div>
                    <button type="button" pbutton="" icon="pi pi-pencil" class="p-element p-button p-component p-button-icon-only details-panel-header-edit-button" ng-reflect-icon="pi pi-pencil" title="Edit Build Details" (click)="enableEditBuilding(true)" [ngClass]="{'edit-button-hidden': editBuilding}">
                      <span class="p-button-icon pi pi-pencil" aria-hidden="true" style="margin-right:10px"></span>
                    </button>
                  </div>
                </ng-template>
                <div class="productdetails-attribute">
                  <div class="productdetails-label">Source Control Types:</div>
                  <div class="productdetails-value" *ngIf="!editBuilding">
                    <ng-container *ngFor="let item of selectedSourceControlTypes">
                      <p-tag value="{{ getSourceControlTypeName(item) }}" [styleClass]="'cursor-pointer sourcecontroltypes-badge'"
                             pTooltip="{{ getSourceControlTypeName(item)  }}" tooltipPosition="top"></p-tag>
                    </ng-container>
                  </div>
                  <div class="productdetails-value" *ngIf="editBuilding">
                    <p-multiSelect class="custom-multiSelect uniform-width-input" appendTo="body" [options]="sourceControlTypes" [(ngModel)]="editedSourceControlTypes" defaultLabel="Select a Source Control Type" optionLabel="Name" optionValue="Id"
                                   [style]="{'minWidth':'90%', 'maxWidth': '400px'}"
                                   display="chip"></p-multiSelect>
                  </div>
                </div>
                <div class="productdetails-attribute">
                  <div class="productdetails-label">Source Control Providers:</div>
                  <div class="productdetails-value" *ngIf="!editBuilding">
                    <ng-container *ngFor="let item of selectedSourceControlProviders">
                      <p-tag 
                        [appStyledTag]="{ 
                          colourCode: item.softwareDisplaySettings?.colourCode,
                          logo: item.softwareDisplaySettings?.logo,
                          backgroundColourCode: item.softwareDisplaySettings?.backgroundColourCode,
                          borderColor: item.softwareDisplaySettings?.borderColor || 'gray', 
                          name: item.name,
                          badgeType: 'sourcecontrolproviders-badge' 
                        }"
                        [ngClass]="'cursor-pointer'"
                        pTooltip="{{ item.name }}" 
                        tooltipPosition="top">
                      </p-tag>
                    </ng-container>
                  </div>
                  <div class="productdetails-value" *ngIf="editBuilding">
                    <p-multiSelect class="custom-multiSelect uniform-width-input" appendTo="body" [options]="sourceControlProviders" [(ngModel)]="editedSourceControlProviders" defaultLabel="Select a Source Control Provider" optionLabel="name" optionValue="id"
                                   [style]="{'minWidth':'90%', 'maxWidth': '400px'}"
                                   display="chip"></p-multiSelect>
                  </div>
                </div>
                
                <div class="productdetails-attribute">
                  <div class="productdetails-label">Build Tools:</div>
                  <div class="productdetails-value" *ngIf="!editBuilding">
                    <ng-container *ngFor="let item of selectedBuildTools">
                      <p-tag 
                        [appStyledTag]="{ 
                          colourCode: item.softwareDisplaySettings?.colourCode,
                          logo: item.softwareDisplaySettings?.logo,
                          backgroundColourCode: item.softwareDisplaySettings?.backgroundColourCode,
                          borderColor: item.softwareDisplaySettings?.borderColor || 'gray', 
                          name: item.name,
                          badgeType: 'buildtools-badge' 
                        }"
                        [ngClass]="'cursor-pointer'"
                        pTooltip="{{ item.name }}" 
                        tooltipPosition="top">
                      </p-tag>
                    </ng-container>
                  </div>
                  <div class="productdetails-value" *ngIf="editBuilding">
                    <p-multiSelect class="custom-multiSelect uniform-width-input" appendTo="body" [options]="buildTools" [(ngModel)]="editedBuildTools" defaultLabel="Select a Build Tool" optionLabel="name" optionValue="id"
                                   [style]="{'minWidth':'90%', 'maxWidth': '400px'}"
                                   display="chip"></p-multiSelect>
                  </div>
                </div>
                
                <div class="productdetails-attribute">
                  <div class="productdetails-label">Deployment Tools:</div>
                  <div class="productdetails-value" *ngIf="!editBuilding">
                    <ng-container *ngFor="let item of selectedDeploymentTools">
                      <p-tag 
                        [appStyledTag]="{ 
                          colourCode: item.softwareDisplaySettings?.colourCode,
                          logo: item.softwareDisplaySettings?.logo,
                          backgroundColourCode: item.softwareDisplaySettings?.backgroundColourCode,
                          borderColor: item.softwareDisplaySettings?.borderColor || 'gray', 
                          name: item.name,
                          badgeType: 'deploymenttools-badge' 
                        }"
                        [ngClass]="'cursor-pointer'"
                        pTooltip="{{ item.name }}" 
                        tooltipPosition="top">
                      </p-tag>
                    </ng-container>
                  </div>
                  <div class="productdetails-value" *ngIf="editBuilding">
                    <p-multiSelect class="custom-multiSelect uniform-width-input" appendTo="body" [options]="deploymentTools" [(ngModel)]="editedDeploymentTools" defaultLabel="Select a Deployment Tool" optionLabel="name" optionValue="id"
                                   [style]="{'minWidth':'90%', 'maxWidth': '400px'}"
                                   display="chip"></p-multiSelect>
                  </div>
                </div>
                
                <div class="productdetails-attribute">
                  <div class="productdetails-label">Backlog Tools:</div>
                  <div class="productdetails-value" *ngIf="!editBuilding">
                    <ng-container *ngFor="let item of selectedBacklogTools">
                      <p-tag 
                        [appStyledTag]="{ 
                          colourCode: item.softwareDisplaySettings?.colourCode,
                          logo: item.softwareDisplaySettings?.logo,
                          backgroundColourCode: item.softwareDisplaySettings?.backgroundColourCode,
                          borderColor: item.softwareDisplaySettings?.borderColor || 'gray', 
                          name: item.name,
                          badgeType: 'backlogtools-badge' 
                        }"
                        [ngClass]="'cursor-pointer'"
                        pTooltip="{{ item.name }}" 
                        tooltipPosition="top">
                      </p-tag>
                    </ng-container>
                  </div>
                  <div class="productdetails-value" *ngIf="editBuilding">
                    <p-multiSelect class="custom-multiSelect uniform-width-input" appendTo="body" [options]="backlogTools" [(ngModel)]="editedBacklogTools" defaultLabel="Select a Backlog Tool" optionLabel="name" optionValue="id"
                                   [style]="{'minWidth':'90%', 'maxWidth': '400px'}"
                                   display="chip"></p-multiSelect>
                  </div>
                </div>

                <div class="productdetails-attribute mt-2">
                  <div class="productdetails-label">Release Cadence:</div>
                  <div class="productdetails-value" *ngIf="!editBuilding">
                    <ng-container *ngIf="productTechSpec?.ReleaseCadenceName">
                      <a target="_blank" class="no-decoration">
                        <p-tag value="{{ productTechSpec?.ReleaseCadenceName }}" [styleClass]="'cursor-pointer suite-badge suite-' + tagify(productTechSpec?.ReleaseCadenceName)" pTooltip="Release Cadence" tooltipPosition="top"></p-tag>
                        </a>
                    </ng-container>
                  </div>
                  <div class="productdetails-value" *ngIf="editBuilding">
                    <p-dropdown appendTo="body" [(ngModel)]="editedProductTechSpec.ReleaseCadenceId" inputId="ReleaseCadenceId" [options]="releaseCadences" placeholder="Select" optionValue="Id" class="uniform-width-input" [style]="{'minWidth':'90%', 'maxWidth': '400px'}">
                      <ng-template pTemplate="selectedItem">
                        <span *ngIf="editedProductTechSpec.ReleaseCadenceId" [class]="'suite-badge suite-' + trimOption(getReleaseCadenceNameById(editedProductTechSpec.ReleaseCadenceId))">{{ getReleaseCadenceNameById(editedProductTechSpec.ReleaseCadenceId) }}</span>
                      </ng-template>
                      <ng-template let-option pTemplate="item">
                        <span [class]="'suite-badge suite-' + trimOption(option.Name)">{{option.Name}}</span>
                      </ng-template>
                    </p-dropdown>
                  </div>
                </div>

                <div class="productdetails-attribute">
                  <div class="productdetails-label">Level of Test Automation:</div>
                  <div class="productdetails-value" *ngIf="!editBuilding">
                    <ng-container *ngIf="productTechSpec?.LevelOfTestAutomationName">
                      <a target="_blank" class="no-decoration">
                        <p-tag value="{{ productTechSpec?.LevelOfTestAutomationName }}" [styleClass]="'cursor-pointer suite-badge suite-' + tagify(productTechSpec?.LevelOfTestAutomationName)" pTooltip="LevelOfTestAutomation" tooltipPosition="top"></p-tag>
                        </a>
                    </ng-container>
                  </div>
                  <div class="productdetails-value" *ngIf="editBuilding">
                    <p-dropdown appendTo="body" [(ngModel)]="editedProductTechSpec.LevelOfTestAutomationId" inputId="LevelOfTestAutomationId" [options]="levelOfTestAutomations" placeholder="Select" optionValue="Id" class="uniform-width-input" [style]="{'minWidth':'90%', 'maxWidth': '400px'}">
                      <ng-template pTemplate="selectedItem">
                        <span *ngIf="editedProductTechSpec.LevelOfTestAutomationId" [class]="'suite-badge suite-' + trimOption(getLevelOfTestAutomationNameById(editedProductTechSpec.LevelOfTestAutomationId))">{{ getLevelOfTestAutomationNameById(editedProductTechSpec.LevelOfTestAutomationId) }}</span>
                      </ng-template>
                      <ng-template let-option pTemplate="item">
                        <span [class]="'suite-badge suite-' + trimOption(option.Name)">{{option.Name}}</span>
                      </ng-template>
                    </p-dropdown>
                  </div>
                </div>

                <div class="productdetails-attribute">
                  <div class="productdetails-label">Code Volatility:</div>
                  <div class="productdetails-value" *ngIf="!editBuilding">
                    <ng-container *ngIf="productTechSpec?.CodeVolatilityName">
                      <a target="_blank" class="no-decoration">
                        <p-tag value="{{ productTechSpec?.CodeVolatilityName }}" [styleClass]="'cursor-pointer suite-badge suite-' + tagify(productTechSpec?.CodeVolatilityName)" pTooltip="CodeVolatility" tooltipPosition="top"></p-tag>
                        </a>
                    </ng-container>
                  </div>
                  <div class="productdetails-value" *ngIf="editBuilding">
                    <p-dropdown appendTo="body" [(ngModel)]="editedProductTechSpec.CodeVolatilityId" inputId="CodeVolatilityId" [options]="codeVolatilities" placeholder="Select" optionValue="Id" class="uniform-width-input" [style]="{'minWidth':'90%', 'maxWidth': '400px'}">
                      <ng-template pTemplate="selectedItem">
                        <span *ngIf="editedProductTechSpec.CodeVolatilityId" [class]="'suite-badge suite-' + trimOption(getCodeVolatilityNameById(editedProductTechSpec.CodeVolatilityId))">{{ getCodeVolatilityNameById(editedProductTechSpec.CodeVolatilityId) }}</span>
                      </ng-template>
                      <ng-template let-option pTemplate="item">
                        <span [class]="'suite-badge suite-' + trimOption(option.Name)">{{option.Name}}</span>
                      </ng-template>
                    </p-dropdown>
                  </div>
                </div>
                <div>
                  <div class="productdetails-description-label productdetails-attribute">Pipeline Comment:</div>
                  <div class="productdetails-description" *ngIf="!editBuilding">{{ productTechSpec?.CiCdComment ?? "" }}</div>
                  <div class="productdetails-description" *ngIf="editBuilding">
                    <textarea pInputTextarea id="editedPipelineDescription" class="uniform-width-input" rows="4" [(ngModel)]="editedPipelineDescription"></textarea>
                  </div>
                </div>
                <ng-template pTemplate="footer">
                  <div *ngIf="editBuilding">
                    <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="enableEditBuilding(false)"></button>
                    <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text" (click)="updateBuilding()"></button>
                  </div>
                </ng-template>
              </p-panel>
<!-- NEW -->
              <p-panel styleClass="details-panel">
                <ng-template pTemplate="header">
                  <div class="details-panel-header-container">
                    <div class="flex align-items-center gap-2">
                      <span class="font-bold">
                        Customisation
                      </span>
                    </div>
                    <button type="button" pbutton="" icon="pi pi-pencil" class="p-element p-button p-component p-button-icon-only details-panel-header-edit-button" ng-reflect-icon="pi pi-pencil" title="Edit SDLC Metrics" (click)="enableEditCustomisation(true)" [ngClass]="{'edit-button-hidden': editCustomisation}">
                      <span class="p-button-icon pi pi-pencil" aria-hidden="true" style="margin-right:10px"></span>
                    </button>
                  </div>
                </ng-template>

                <div class="productdetails-attribute">
                  <div class="productdetails-label">Can product be customised?:</div>
                  <div class="productdetails-value" *ngIf="!editCustomisation">
                    <ng-container *ngIf="productTechPortfolio?.CustomizationEnabledName">
                      <a target="_blank" class="no-decoration">
                        <p-tag value="{{ productTechPortfolio?.CustomizationEnabledName }}" [styleClass]="'cursor-pointer suite-badge suite-' + tagify(productTechPortfolio?.CustomizationEnabledName)" pTooltip="Customization Enabled" tooltipPosition="top"></p-tag>
                        </a>
                    </ng-container>
                  </div>
                  <div class="productdetails-value" *ngIf="editCustomisation && editedProductTechPortfolio">
                    <p-dropdown appendTo="body" [(ngModel)]="editedProductTechPortfolio.CustomizationEnabledId" inputId="CustomizationEnabledId" [options]="customizationsEnabled" placeholder="Select" optionValue="Id" class="uniform-width-input" [style]="{'minWidth':'90%', 'maxWidth': '400px'}">
                      <ng-template pTemplate="selectedItem">
                        <span *ngIf="editedProductTechPortfolio.CustomizationEnabledId" [class]="'suite-badge suite-' + trimOption(getCustomizationEnabledNameById(editedProductTechPortfolio.CustomizationEnabledId))">{{ getCustomizationEnabledNameById(editedProductTechPortfolio.CustomizationEnabledId) }}</span>
                      </ng-template>
                      <ng-template let-option pTemplate="item">
                        <span [class]="'suite-badge suite-' + trimOption(option.Name)">{{option.Name}}</span>
                      </ng-template>
                    </p-dropdown>
                  </div>
                </div>

                <div>
                  <div class="productdetails-description-label productdetails-attribute">How are customisations enabled?</div>
                  <div class="productdetails-description" *ngIf="!editCustomisation">{{ productTechPortfolio?.CustomizationEnabledMaintainers ?? "" }}</div>
                  <div class="productdetails-description" *ngIf="editCustomisation">
                    <textarea pInputTextarea id="editedCustomizationEnabledMaintainers" class="uniform-width-input" rows="4" [(ngModel)]="editedCustomizationEnabledMaintainers"></textarea>
                  </div>
                </div>

                <div>
                  <div class="productdetails-description-label productdetails-attribute">What is the degree of customisation?</div>
                  <div class="productdetails-description" *ngIf="!editCustomisation">{{ productTechPortfolio?.DegreeOfCustomization ?? "" }}</div>
                  <div class="productdetails-description" *ngIf="editCustomisation">
                    <textarea pInputTextarea id="editedDegreeOfCustomization" class="uniform-width-input" rows="4" [(ngModel)]="editedDegreeOfCustomization"></textarea>
                  </div>
                </div>

                <ng-template pTemplate="footer">
                  <div *ngIf="editCustomisation">
                    <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="enableEditCustomisation(false)"></button>
                    <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text" (click)="updateCustomisation()"></button>
                  </div>
                </ng-template>
              </p-panel>

            </div>
          </div>
        </div>
      </div>
      <div *ngIf="!isTechLoaded">
        <p-skeleton width="10%" height="1rem"></p-skeleton>
      </div>
    </p-tabPanel>
    <p-tabPanel header="Security Metrics">
      <div *ngIf="isMetricLoaded">
        <div class="productdetails-container">
          <div class="button-container">
            <a 
              href="https://irissoftware.sharepoint.com/sites/EngineeringIntranet/SitePages/SDLM%20-%20Security%20Metrics.aspx" 
              class="p-button p-button-rounded p-button-help p-mt-2 tech-help-button"
              target="_blank" 
              title="Go to Security Metrics Help Page">
              <i class="pi pi-question-circle"></i>
            </a>
          </div>
          <div class="productdetails-top-row">
            <div class="productdetails-left-column">
              <p-panel>
                <ng-template pTemplate="header">
                  <div class="details-panel-header-container">
                    <div class="flex align-items-center gap-2">
                      <span class="font-bold">
                        Security Metrics
                      </span>
                    </div>
                    <button type="button" pbutton="" icon="pi pi-pencil" class="p-element p-button p-component p-button-icon-only details-panel-header-edit-button" ng-reflect-icon="pi pi-pencil" title="Edit Security Metrics" (click)="enableEditSecMetrics(true)" [ngClass]="{'edit-button-hidden': editSecMetrics}">
                      <span class="p-button-icon pi pi-pencil" aria-hidden="true" style="margin-right:10px"></span>
                    </button>
                  </div>
                </ng-template>
                <div class="productdetails-attribute">
                  <div class="productdetails-label">Functions:</div>
                  <div class="productdetails-value" *ngIf="!editSecMetrics">
                    <ng-container *ngFor="let item of selectedFunctionTypes">
                      <p-tag value="{{ getFunctionTypeName(item) }}" [styleClass]="'cursor-pointer functiontype-badge type-' + tagify(getFunctionTypeName(item))"
                             pTooltip="{{ getFunctionTypeName(item) }}" tooltipPosition="top"></p-tag>
                    </ng-container>
                  </div>
                  <div class="productdetails-value" *ngIf="editSecMetrics">
                    <p-multiSelect class="custom-multiSelect uniform-width-input" appendTo="body" [options]="functionTypes" [(ngModel)]="editedFunctionTypes" defaultLabel="Select a Function" optionLabel="Name" optionValue="Id"
                                   [style]="{'minWidth':'90%', 'maxWidth': '400px'}"
                                   display="chip"></p-multiSelect>
                  </div>
                </div>
                <div class="productdetails-attribute">
                  <div class="productdetails-label">Product Classification:</div>
                  <div class="productdetails-value" *ngIf="!editSecMetrics">
                    {{  getClassificationName(productMetric.ProductContributionId) }}
                  </div>
                  <div class="productdetails-value" *ngIf="editSecMetrics">
                    <p-dropdown appendTo="body" [(ngModel)]="editedProductMetric.ProductContributionId" inputId="ProductContributionId" [options]="classifications" placeholder="Select" optionValue="Id" class="uniform-width-input" [style]="{'minWidth':'90%', 'maxWidth': '400px'}">
                      <ng-template pTemplate="selectedItem">
                        <span *ngIf="editedProductMetric.ProductContributionId">{{ getClassificationName(editedProductMetric.ProductContributionId) }}</span>
                      </ng-template>
                      <ng-template let-option pTemplate="item">
                        <span>{{option.Name}}</span>
                      </ng-template>
                    </p-dropdown>
                  </div>
                </div>
                <div class="productdetails-attribute">
                  <div class="productdetails-label">Data Sensitivity:</div>
                  <div class="productdetails-value" *ngIf="!editSecMetrics">
                    {{ getProductDataTypeName(productMetric?.ProductDataTypeId ?? 0) }}
                  </div>
                  <div class="productdetails-value" *ngIf="editSecMetrics">
                    <p-dropdown appendTo="body" [(ngModel)]="editedProductMetric.ProductDataTypeId" inputId="ProductDataTypeId" [options]="productDataTypes" placeholder="Select" optionValue="Id" class="uniform-width-input" [style]="{'minWidth':'90%', 'maxWidth': '400px'}">
                      <ng-template pTemplate="selectedItem">
                        <span *ngIf="editedProductMetric.ProductDataTypeId">{{ getProductDataTypeName(editedProductMetric.ProductDataTypeId) }}</span>
                      </ng-template>
                      <ng-template let-option pTemplate="item">
                        <span [class]="'suite-badge suite-' + trimOption(option.Name)">{{option.Name}}</span>
                      </ng-template>
                    </p-dropdown>
                  </div>
                </div>
                <div class="productdetails-attribute">
                  <div class="productdetails-label">Approx. Lines of Code:</div>
                  <div class="productdetails-value" *ngIf="!editSecMetrics">{{ productMetric?.LinesOfCode | number:'1.0-0' }}</div>
                  <div class="productdetails-value" *ngIf="editSecMetrics">
                    <input type="text" pInputText id="editedProductMetricLinesOfCode" class="uniform-width-input" [(ngModel)]="editedProductMetric.LinesOfCode" />
                  </div>
                </div>
                <div class="productdetails-attribute">
                  <div class="productdetails-label">Number of Developers:</div>
                  <div class="productdetails-value" *ngIf="!editSecMetrics">{{ productMetric?.DevCount ?? '' }}</div>
                  <div class="productdetails-value" *ngIf="editSecMetrics">
                    <input type="text" pInputText id="editedProductMetricDevCount" class="uniform-width-input" [(ngModel)]="editedProductMetric.DevCount" />
                  </div>
                </div>
                <div class="productdetails-attribute">
                  <div class="productdetails-label">Security Monitoring Tool:</div>
                  <div class="productdetails-value" *ngIf="!editSecMetrics">
                    <ng-container *ngIf="productMetric?.ProdSecMonitoringName">
                      <p-tag value="{{ getSecMonitoringToolName(productMetric?.ProdSecMonitoringId ?? 0) }}" [styleClass]="'cursor-pointer secmonitoringtool-badge tool-' + tagify(getSecMonitoringToolName(productMetric?.ProdSecMonitoringId ?? 0))"
                             pTooltip="{{ getSecMonitoringToolName(productMetric?.ProdSecMonitoringId ?? 0) }}" tooltipPosition="top"></p-tag>
                    </ng-container>
                  </div>
                  <div class="productdetails-value" *ngIf="editSecMetrics">
                    <p-dropdown appendTo="body" [(ngModel)]="editedProductMetric.ProdSecMonitoringId" inputId="ProdSecMonitoringId" [options]="secMonitoringTools" placeholder="Select" optionValue="Id" class="uniform-width-input" [style]="{'minWidth':'90%', 'maxWidth': '400px'}">
                      <ng-template pTemplate="selectedItem">
                        <span *ngIf="editedProductMetric.ProdSecMonitoringId" [class]="'secmonitoringtool-badge tool-' + tagify(getSecMonitoringToolName(editedProductMetric.ProdSecMonitoringId))">{{ getSecMonitoringToolName(editedProductMetric.ProdSecMonitoringId) }}</span>
                      </ng-template>
                      <ng-template let-option pTemplate="item">
                        <span [class]="'secmonitoringtool-badge tool-' + tagify(getSecMonitoringToolName(option.Id))">{{option.Name}}</span>
                      </ng-template>
                    </p-dropdown>
                  </div>
                </div>
                <div class="productdetails-attribute">
                  <div class="productdetails-label">Vulnerability Mgmt. Tools:</div>
                  <div class="productdetails-value" *ngIf="!editSecMetrics">
                    <ng-container *ngFor="let tool of selectedVulnerabilityTools">
                      <p-tag value="{{ getVulnerabilityToolName(tool) }}" [styleClass]="'cursor-pointer vulnmngmttool-badge tool-' + tagify(getVulnerabilityToolName(tool))"
                             pTooltip="{{  getVulnerabilityToolName(tool)  }}" tooltipPosition="top"></p-tag>
                    </ng-container>
                  </div>
                  <div class="productdetails-value" *ngIf="editSecMetrics">
                    <p-multiSelect class="custom-multiSelect uniform-width-input" appendTo="body" [options]="vulnerabilityTools" [(ngModel)]="editedVulnerabilityTools" defaultLabel="Select a Vulnerability Management Tool" optionLabel="Name" optionValue="Id"
                                   [style]="{'minWidth':'90%', 'maxWidth': '400px'}"
                                   display="chip"></p-multiSelect>
                  </div>

                </div>
                <ng-template pTemplate="footer">
                  <div *ngIf="editSecMetrics">
                    <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="enableEditSecMetrics(false)"></button>
                    <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text" (click)="updateSecMetrics()"></button>
                  </div>
                </ng-template>
              </p-panel>
              <p-panel styleClass="details-panel">
                <ng-template pTemplate="header">
                  <div class="details-panel-header-container">
                    <div class="flex align-items-center gap-2">
                      <span class="font-bold">
                        SDLC
                      </span>
                    </div>
                    <button type="button" pbutton="" icon="pi pi-pencil" class="p-element p-button p-component p-button-icon-only details-panel-header-edit-button" ng-reflect-icon="pi pi-pencil" title="Edit SDLC Metrics" (click)="enableEditSDLC(true)" [ngClass]="{'edit-button-hidden': editSDLC}">
                      <span class="p-button-icon pi pi-pencil" aria-hidden="true" style="margin-right:10px"></span>
                    </button>
                  </div>
                </ng-template>
                <div class="productdetails-attribute" *ngIf="!editSDLC">
                  <div class="productdetails-label">All code in the SDLC?:</div>
                  <div class="productdetails-value">
                    {{ productMetric?.SDLCUsage ? 'Yes' : 'No' }}
                  </div>
                </div>
                <div class="productdetails-attribute" *ngIf="editSDLC">
                  <div class="productdetails-label"><label for="chkSDLC">All code in the SDLC?:</label></div>
                  <div class="productdetails-value"><input id="chkSDLC" type="checkbox" [(ngModel)]="editedSDLCStatus" /></div>
                </div>
                <div class="productdetails-attribute">
                  <div class="productdetails-label">Component Management:</div>
                  <div class="productdetails-value" *ngIf="!editSDLC">
                    {{  getComponentManagementToolName(productMetric.ComponentManagementId ?? 0) }}
                  </div>
                  <div class="productdetails-value" *ngIf="editSDLC">
                    <p-dropdown appendTo="body" [(ngModel)]="editedComponentManagementTool" inputId="componentManagementToolId" [options]="componentManagementTools" placeholder="Select" optionValue="Id" class="uniform-width-input" [style]="{'minWidth':'90%', 'maxWidth': '400px'}">
                      <ng-template pTemplate="selectedItem">
                        <span *ngIf="editedComponentManagementTool">{{ getComponentManagementToolName(editedComponentManagementTool) }}</span>
                      </ng-template>
                      <ng-template let-option pTemplate="item">
                        <span>{{option.Name}}</span>
                      </ng-template>
                    </p-dropdown>
                  </div>
                </div>
                <div class="productdetails-attribute">
                  <div class="productdetails-label">Third-Party Components:</div>
                  <div class="productdetails-value" *ngIf="!editSDLC">
                    {{ productMetric?.NumberOfComponents ?? '' }}
                  </div>
                  <div class="productdetails-value" *ngIf="editSDLC">
                    <input type="text" pInputText id="productMetricNumberOfComponents" class="uniform-width-input" [(ngModel)]="editedNumberOfComponents" />
                  </div>
                </div>
                <ng-template pTemplate="footer">
                  <div *ngIf="editSDLC">
                    <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="enableEditSDLC(false)"></button>
                    <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text" (click)="updateSDLC()"></button>
                  </div>
                </ng-template>
              </p-panel>
          </div>
            <div class="productdetails-right-column">
              <p-panel>
                <ng-template pTemplate="header">
                  <div class="details-panel-header-container">
                    <div class="flex align-items-center gap-2">
                      <span class="font-bold">
                        Cyber Essentials+
                      </span>
                    </div>
                    <button type="button" pbutton="" icon="pi pi-pencil" class="p-element p-button p-component p-button-icon-only details-panel-header-edit-button" ng-reflect-icon="pi pi-pencil" title="Edit Cyber Essentials Details" (click)="enableEditCE(true)" [ngClass]="{'edit-button-hidden': editCE}">
                      <span class="p-button-icon pi pi-pencil" aria-hidden="true" style="margin-right:10px"></span>
                    </button>
                  </div>
                </ng-template>
                <div class="productdetails-attribute" *ngIf="!editCE">
                  <div class="productdetails-label">Included in CE+:</div>
                  <div class="productdetails-value">
                    {{ productMetric?.InCEPlus ? "Yes" : "No" }}
                    </div>
                </div>
                  <div class="productdetails-attribute" *ngIf="editCE">
                    <div class="productdetails-label"><label for="chkCE">Included in CE+?:</label></div>
                    <div class="productdetails-value"><input id="chkCE" type="checkbox" [(ngModel)]="editedCEStatus" /></div>
                  </div>
                  <div>
                    <div class="productdetails-description-label productdetails-attribute">CE Comment:</div>
                    <div class="productdetails-description" *ngIf="!editCE">{{ productMetric?.CEPlusComment ?? "" }}</div>
                    <div class="productdetails-description" *ngIf="editCE">
                      <textarea pInputTextarea id="editedCEDescription" class="uniform-width-input" rows="4" [(ngModel)]="editedCEDescription"></textarea>
                    </div>                  
                  </div>
                <ng-template pTemplate="footer">
                  <div *ngIf="editCE">
                    <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="enableEditCE(false)"></button>
                    <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text" (click)="updateCE()"></button>
                  </div>
                </ng-template>
              </p-panel>
              <p-panel styleClass="details-panel">
                <ng-template pTemplate="header">
                  <div class="details-panel-header-container">
                    <div class="flex align-items-center gap-2">
                      <span class="font-bold">
                        SDLC
                      </span>
                    </div>
                    <button type="button" pbutton="" icon="pi pi-pencil" class="p-element p-button p-component p-button-icon-only details-panel-header-edit-button" ng-reflect-icon="pi pi-pencil" title="Edit Pen Test Details" (click)="enableEditPenTest(true)" *appHasRole="['Lens.Admin']" [ngClass]="{'edit-button-hidden': editPenTest}">
                      <span class="p-button-icon pi pi-pencil" aria-hidden="true" style="margin-right:10px"></span>
                    </button>
                  </div>
                </ng-template>
                <div class="productdetails-attribute">
                  <div class="productdetails-label">Tier:</div>
                  <div class="productdetails-value" *ngIf="!editPenTest">
                    <ng-container>
                      <a [href]="'https://irissoftware.sharepoint.com/sites/EngineeringIntranet/SitePages/Secure-Software-Development-Lifecycle.aspx#product-tiers'" target="_blank">
                        <p-tag value="{{ getTierNameById(productMetric?.TierId) }}" [styleClass]="'cursor-pointer tier-badge tier-' + tagify(getTierNameById(productMetric?.TierId))"
                               pTooltip="{{ getTierNameById(productMetric?.TierId) }}" tooltipPosition="top"></p-tag>
                      </a>
                    </ng-container>
                  </div>
                  <div class="productdetails-value" *ngIf="editPenTest">
                    <p-dropdown appendTo="body" [(ngModel)]="editedProductMetric.TierId" inputId="TierId" [options]="tiers" placeholder="Select" optionValue="Id" class="uniform-width-input" [style]="{'minWidth':'90%', 'maxWidth': '400px'}" *appHasRole="['Lens.Admin']">
                      <ng-template pTemplate="selectedItem">
                        <span *ngIf="editedProductMetric.TierId" [class]="'tier-badge tier-' + tagify(getTierNameById(editedProductMetric.TierId))">{{ getTierNameById(editedProductMetric.TierId) }}</span>
                      </ng-template>
                      <ng-template let-option pTemplate="item">
                        <span [class]="'tier-badge tier-' + tagify(option.Name)">{{option.Name}}</span>
                      </ng-template>
                    </p-dropdown>
                  </div>
                </div>
                <div class="productdetails-attribute">
                  <div class="productdetails-label">Test Cycle (Days):</div>
                  <div class="productdetails-value" readonly="true">
                    {{ productMetric?.TierPentestFrequency ?? '' }}
                  </div>
                </div>
                <ng-template pTemplate="footer">
                  <div *ngIf="editPenTest">
                    <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="enableEditPenTest(false)"></button>
                    <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text" (click)="updatePenTest()"></button>
                  </div>
                </ng-template>
              </p-panel>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="!isMetricLoaded">
        <p-skeleton width="10%" height="1rem"></p-skeleton>
      </div>
    </p-tabPanel>









    <p-tabPanel header="Other">
      <div *ngIf="isTechPortfolioLoaded">
        <div class="productdetails-container">
          <div class="productdetails-top-row">
            <div class="productdetails-left-column">
              <p-panel>
                <ng-template pTemplate="header">
                  <div class="details-panel-header-container">
                    <span class="font-bold details-panel-header-title">
                      Process
                    </span>
                    <button type="button" pbutton="" icon="pi pi-pencil" class="p-element p-button p-component p-button-icon-only details-panel-header-edit-button" ng-reflect-icon="pi pi-pencil" title="Edit Details" (click)="enableEditProcess(true)" [ngClass]="{'edit-button-hidden': editProcess}">
                      <span class="p-button-icon pi pi-pencil" aria-hidden="true" style="margin-right:10px"></span>
                    </button>
                  </div>
                </ng-template>


                <div>
                  <div class="productdetails-description-label productdetails-attribute">Joiner-Mover-Leaver-Process:</div>
                  <div class="productdetails-description" *ngIf="!editProcess">{{ productTechPortfolio?.JMLProcess ?? "" }}</div>
                  <div class="productdetails-description" *ngIf="editProcess">
                    <textarea pInputTextarea id="editedJMLProcess" class="uniform-width-input m-2" rows="4" [(ngModel)]="editedJMLProcess"></textarea>
                  </div>
                </div>
                <div>
                  <div class="productdetails-description-label productdetails-attribute">User Report Process:</div>
                  <div class="productdetails-description" *ngIf="!editProcess">{{ productTechPortfolio?.UserReportProcess ?? "" }}</div>
                  <div class="productdetails-description" *ngIf="editProcess">
                    <textarea pInputTextarea id="editedUserReportProcess" class="uniform-width-input" rows="4" [(ngModel)]="editedUserReportProcess"></textarea>
                  </div>
                </div>

                
                <ng-template pTemplate="footer">
                  <div *ngIf="editProcess">
                    <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="enableEditProcess(false)"></button>
                    <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text" (click)="updateProcess()"></button>
                  </div>
                </ng-template>
              </p-panel>
            </div>



            <div class="productdetails-right-column">
              <p-panel>
                <ng-template pTemplate="header">
                  <div class="details-panel-header-container">
                    <span class="font-bold details-panel-header-title">
                      Strategy
                    </span>
                    <button type="button" pbutton="" icon="pi pi-pencil" class="p-element p-button p-component p-button-icon-only details-panel-header-edit-button" ng-reflect-icon="pi pi-pencil" title="Edit Product Status" (click)="enableEditStrategy(true)" [ngClass]="{'edit-button-hidden': editStrategy}">
                      <span class="p-button-icon pi pi-pencil" aria-hidden="true" style="margin-right:10px"></span>
                    </button>
                  </div>
                </ng-template>

                <div class="productdetails-attribute">
                  <div class="productdetails-label">Is the UI/UX Modernised?</div>
                  <div class="productdetails-value" *ngIf="!editStrategy">
                    <ng-container *ngIf="productTechPortfolio?.UXUIModernisationName">
                      <a target="_blank" class="no-decoration">
                        <p-tag value="{{ productTechPortfolio?.UXUIModernisationName }}" [styleClass]="'cursor-pointer suite-badge suite-' + tagify(productTechPortfolio?.UXUIModernisationName)" pTooltip="UXUIModernisation" tooltipPosition="top"></p-tag>
                        </a>
                    </ng-container>
                  </div>
                  <div class="productdetails-value" *ngIf="editStrategy && editedProductTechPortfolio">
                    <p-dropdown appendTo="body" [(ngModel)]="editedProductTechPortfolio.UXUIModernisationId" inputId="UXUIModernisationId" [options]="uxuiModernisations" placeholder="Select" optionValue="Id" class="uniform-width-input" [style]="{'minWidth':'90%', 'maxWidth': '400px'}">
                      <ng-template pTemplate="selectedItem">
                        <span *ngIf="editedProductTechPortfolio.UXUIModernisationId" [class]="'suite-badge suite-' + trimOption(getUXUIModernisationNameById(editedProductTechPortfolio.UXUIModernisationId))">{{ getUXUIModernisationNameById(editedProductTechPortfolio.UXUIModernisationId) }}</span>
                      </ng-template>
                      <ng-template let-option pTemplate="item">
                        <span [class]="'suite-badge suite-' + trimOption(option.Name)">{{option.Name}}</span>
                      </ng-template>
                    </p-dropdown>
                  </div>
                </div>

                <div class="productdetails-attribute mt-2">
                  <div class="productdetails-label">Net New Or Existing Code:</div>
                  <div class="productdetails-value" *ngIf="!editStrategy">
                    <ng-container *ngIf="productTechPortfolio?.NetNewOrExistingCodeName">
                      <a target="_blank" class="no-decoration">
                        <p-tag value="{{ productTechPortfolio?.NetNewOrExistingCodeName }}" [styleClass]="'cursor-pointer suite-badge suite-' + tagify(productTechPortfolio?.NetNewOrExistingCodeName)" pTooltip="NetNewOrExistingCode" tooltipPosition="top"></p-tag>
                        </a>
                    </ng-container>
                  </div>
                  <div class="productdetails-value" *ngIf="editStrategy && editedProductTechPortfolio">
                    <p-dropdown appendTo="body" [(ngModel)]="editedProductTechPortfolio.NetNewOrExistingCodeId" inputId="NetNewOrExistingCodeId" [options]="netNewOrExistingCodes" placeholder="Select" optionValue="Id" class="uniform-width-input" [style]="{'minWidth':'90%', 'maxWidth': '400px'}">
                      <ng-template pTemplate="selectedItem">
                        <span *ngIf="editedProductTechPortfolio.NetNewOrExistingCodeId" [class]="'suite-badge suite-' + trimOption(getNetNewOrExistingCodeNameById(editedProductTechPortfolio.NetNewOrExistingCodeId))">{{ getNetNewOrExistingCodeNameById(editedProductTechPortfolio.NetNewOrExistingCodeId) }}</span>
                      </ng-template>
                      <ng-template let-option pTemplate="item">
                        <span [class]="'suite-badge suite-' + trimOption(option.Name)">{{option.Name}}</span>
                      </ng-template>
                    </p-dropdown>
                  </div>
                </div>

                <div>
                  <div class="productdetails-description-label productdetails-attribute">Are customers on an older version?</div>
                  <div class="productdetails-description" *ngIf="!editStrategy">{{ productTechPortfolio?.CustomerOlderVersion ?? "" }}</div>
                  <div class="productdetails-description" *ngIf="editStrategy">
                    <textarea pInputTextarea id="editedCustomerOlderVersion" class="uniform-width-input" rows="4" [(ngModel)]="editedCustomerOlderVersion"></textarea>
                  </div>
                </div>

                <div>
                  <div class="productdetails-description-label productdetails-attribute">Roadmap Initiatives:</div>
                  <div class="productdetails-description" *ngIf="!editStrategy">{{ productTechPortfolio?.RoadmapInitiatives ?? "" }}</div>
                  <div class="productdetails-description" *ngIf="editStrategy">
                    <textarea pInputTextarea id="editedRoadmapInitiatives" class="uniform-width-input" rows="4" [(ngModel)]="editedRoadmapInitiatives"></textarea>
                  </div>
                </div>

                
                <div>
                  <div class="productdetails-description-label productdetails-attribute">Tech Debt Summary:</div>
                  <div class="productdetails-description" *ngIf="!editStrategy">{{ productTechPortfolio?.TechDebt ?? "" }}</div>
                  <div class="productdetails-description" *ngIf="editStrategy">
                    <textarea pInputTextarea id="editedTechDebt" class="uniform-width-input" rows="4" [(ngModel)]="editedTechDebt"></textarea>
                  </div>
                </div>
                <div>
                  <div class="productdetails-description-label productdetails-attribute">R&D Resource Allocation:</div>
                  <div class="productdetails-description" *ngIf="!editStrategy">{{ productTechPortfolio?.RandDResourceAllocation ?? "" }}</div>
                  <div class="productdetails-description" *ngIf="editStrategy">
                    <textarea pInputTextarea id="editedRandDResourceAllocation" class="uniform-width-input" rows="4" [(ngModel)]="editedRandDResourceAllocation"></textarea>
                  </div>
                </div>
  

                <ng-template pTemplate="footer">
                  <div *ngIf="editStrategy">
                    <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="enableEditStrategy(false)"></button>
                    <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text" (click)="updateStrategy()"></button>
                  </div>
                </ng-template>
              </p-panel>
       
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="!isTechPortfolioLoaded">
          <p-skeleton width="10%" height="1rem"></p-skeleton>
      </div>
    </p-tabPanel>

    <p-tabPanel header="Versions">  
      <app-versions-tab [items]="productSoftwareLinks"></app-versions-tab>
    </p-tabPanel>
  </p-tabView>