import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, map } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable(
    {
        providedIn: 'root'
    }
)

export class ProductToolsRefService {
    baseApiUrl: string = environment.baseApiUrl;
    constructor(private http: HttpClient) { }

    getProductToolRefs(): Observable<any> {
        return this.http.get<any>(`${this.baseApiUrl}/api/ProductToolUsages`);
  }

    getProductToolRefsByReference(reference: string): Observable<any> {
       return this.http.get<any>(`${this.baseApiUrl}/api/ProductToolUsages?reference=` + reference);
    }

    addProductToolRef(productToolRef: any): Observable<any> {
      return this.http.post<any>(`${this.baseApiUrl}/api/ProductToolUsages`, productToolRef);
    }

    deleteProductToolRef(id: number): Observable<any> {
      return this.http.delete<any>(`${this.baseApiUrl}/api/ProductToolUsages/${id}`);
    }

    getProductTypes(): Observable<any> {
        return this.http.get<any>(`${this.baseApiUrl}/api/ProductTypes`);
    }

    getSDLCTools(): Observable<any> {
        return this.http.get<any>(`${this.baseApiUrl}/api/SDLCTools`);
    }

}
