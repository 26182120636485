<div class="grid">
  <div class="col-12 lg:col-6 xl:col-3" *ngFor="let card of cards; let i = index">
    <div class="card mb-0">
      <div class="flex justify-content-between mb-3">
        <div>
          <span class="block text-500 font-medium mb-3">{{ card.label }}</span>
          <div class="text-900 font-medium text-xl">
            <ng-container *ngIf="!isLoading; else loading">
              <!-- Apply click event only to the total products card -->
              <span *ngIf="i === 0" (click)="redirectToProductsPage()" class="cursor-pointer">
                {{ card.value === '-' ? '-' : card.value }}
              </span>
              <span *ngIf="i === 2" (click)="redirectToSuitesPage()" class="cursor-pointer">
                {{ card.value === '-' ? '-' : card.value }}
              </span>
              <span *ngIf="i === 3" (click)="redirectToARBsPage()" class="cursor-pointer">
                {{ card.value === '-' ? '-' : card.value }}
              </span>
              <span *ngIf="i !== 0 && i !== 2 && i !== 3">
                {{ card.value === '-' ? '-' : card.value }}
              </span>
            </ng-container>
            <ng-template #loading>
              <p-skeleton width="100%" height="2rem"></p-skeleton>
            </ng-template>
          </div>
        </div>
        <div class="flex align-items-center justify-content-center" [ngStyle]="{width: '2.5rem', height: '2.5rem'}" [ngClass]="card.bgClass">
          <i [class]="card.iconClass"></i>
        </div>
      </div>
    </div>
  </div>
</div>

<h2 class="font-bold mt-8">Your Favourite Products</h2>
<div *ngIf="favouritedProducts.length === 0 && !isLoadingFavourites">
  <p-messages *ngIf="favouritedProducts.length === 0 && !isLoadingFavourites"
               severity="info"
               [closable]="false">
    <ng-template pTemplate="message">
      You have not favourited any products yet. You can do this through the Products page.
    </ng-template>
  </p-messages>
</div>
<div class="grid favourite-products">
  <ng-container *ngIf="isLoadingFavourites; else favouritesLoaded">
    <div class="col-12 lg:col-6 xl:col-3" *ngFor="let _ of [1]">
      <div class="card m-3 border-1 surface-border p-ripple card-hover cursor-pointer">
        <div class="flex flex-wrap gap-2 align-items-center justify-content-between mb-2">
          <div class="flex align-items-center">
            <span class="division-badge division-placeholder"></span>
          </div>
          <span class="suite-badge suite-placeholder"></span>
        </div>
        <div class="flex flex-column align-items-center text-center mb-3 card-content">
          <div class="w-9 shadow-2 my-3 mx-0 placeholder"></div>
          <div class="text-2xl font-bold placeholder"></div>
          <div class="mb-3 placeholder"></div>
          <span class="tier-badge tier-placeholder"></span>
        </div>
        <div class="flex align-items-center justify-content-between">
          <span class="text-2xl font-semibold placeholder"></span>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-template #favouritesLoaded>
    <div class="col-12 lg:col-6 xl:col-3" *ngFor="let product of favouritedProducts; let i = index">
      <div class="card m-3 border-1 surface-border p-ripple card-hover cursor-pointer" (click)="redirectToProduct(product)">
        <div class="flex flex-wrap gap-2 align-items-center justify-content-between mb-2">
          <div class="flex align-items-center">
            <span [class]="'division-badge division-' + trimOption(product.DivisionName)">{{product.DivisionName}}</span>
          </div>
          <span [class]="'suite-badge suite-' + trimOption(product.SuiteName)">{{product.SuiteName}}</span>
        </div>
        <div class="flex flex-column align-items-center text-center mb-3 card-content">
          <img *ngIf="product.Logo" [src]="product.Logo" [alt]="product.Name" class="w-9 shadow-2 my-3 mx-0"/>
          <div class="text-2xl font-bold">{{product.Name}}</div>
          <div class="mb-3">{{product.ProductRef}}</div>
          <span [class]="'tier-badge tier-' + tagify(product.ProductMetric?.TierName)">{{product.ProductMetric?.TierName}}</span>
        </div>
        <div class="flex align-items-center justify-content-between">
          <span class="text-2xl font-semibold">{{product.Id}}</span>
        </div>
      </div>
    </div>
  </ng-template>
</div>


<!-- Add new section for favourite suites -->
<h2 class="font-bold mt-4">Your Favourite Suites</h2>
<div *ngIf="favouritedSuites.length === 0 && !isLoadingSuites">
  <p-messages *ngIf="favouritedSuites.length === 0 && !isLoadingSuites"
               severity="info"
               [closable]="false">
    <ng-template pTemplate="message">
      You have not favourited any suites yet. You can do this through the Suites page.
    </ng-template>
  </p-messages>
</div>
<div class="grid favourite-suites">
  <ng-container *ngIf="isLoadingSuites; else suitesLoaded">
    <div class="col-12 lg:col-6 xl:col-3" *ngFor="let _ of [1]">
      <div class="card m-3 border-1 surface-border p-ripple card-hover cursor-pointer">
        <div class="flex flex-column align-items-center text-center mb-3 card-content">
          <div class="text-2xl font-bold placeholder"></div>
          <div class="mb-3 placeholder"></div>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-template #suitesLoaded>
    <div class="col-12 lg:col-6 xl:col-3" *ngFor="let suite of favouritedSuites; let i = index">
      <div class="card m-3 border-1 surface-border p-ripple card-hover cursor-pointer" (click)="redirectToSuite(suite)">
        <div class="flex flex-column align-items-center text-center mb-3 card-content">
          <div class="text-2xl font-bold">{{suite.Name}}</div>
          <div class="">{{suite.SuiteRef}}</div>
          <img *ngIf="suite.Logo" [src]="suite.Logo" [alt]="suite.Name" class="w-9 shadow-2 my-3 mx-0"/>
        </div>
      </div>
    </div>
  </ng-template>
</div>


