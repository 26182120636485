import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, map } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable(
  {
    providedIn: 'root'
  }
)

export class SearchService {
  baseApiUrl: string = environment.baseApiUrl;
  constructor(private http: HttpClient) { }

  getSearch(q: string): Observable<any> {
    if (q != "") {
      return this.http.get(this.baseApiUrl + `/api/search?q=` + q);
    } else {
      return new Observable<any>();
    }
  }

}
