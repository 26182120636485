import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Message, MessageService } from 'primeng/api';
import { ProductSDLCService } from 'src/app/layout/service/product/product-sdlc.service';
import { ProductService } from 'src/app/layout/service/product/products.service';
import { Product } from 'src/app/api/product';
import { ProductMetric } from "src/app/api/product-metric";
import { ProductMetricService } from '../../../service/product/product-metric.service';
import { CheckboxColumn, Field } from "src/app/api/portfoliooverview";

@Component({
  selector: 'portfoliooverview',
  templateUrl: './portfoliooverview.component.html',
  styleUrls: ['./portfoliooverview.component.scss']
})

export class PortfolioOverviewReport implements OnInit {


  suites: any[] = [];
  divisions: any[] = [];
  tiers: any[] = [];
  products: Product[] = [];

  selectedTiers: any[] = [];
  selectedDivisions: any[] = [];
  selectedSuites: any[] = [];
  selectedProducts: Product[] = [];

  filteredProducts: any[] = [];

  isLoading = true;

  checkboxColumns: CheckboxColumn[] = [];
  selectedFields: Field[] = [];

  constructor(private route: ActivatedRoute, private productSDLCService: ProductSDLCService, private productService: ProductService, private productMetricService: ProductMetricService, private messageService: MessageService) { }

  ngOnInit() {

    this.route.params.subscribe(params => {

         this.productService.getAllSuites().subscribe((suites) => {
            this.suites = suites.sort((a, b) => {
              const nameA = a.Name ?? '';
              const nameB = b.Name ?? '';
              return nameA.localeCompare(nameB);
            });
          });
          this.productService.getAllDivisions().subscribe((divisions) => {
            this.divisions = divisions.sort((a, b) => {
              const nameA = a.Name ?? '';
              const nameB = b.Name ?? '';
              return nameA.localeCompare(nameB);
            });
          });
          this.productMetricService.getAllTiers().subscribe((tiers) => {
            this.tiers = tiers;
          });
          this.productService.getAllProductsData().subscribe((products) => {
            this.products = products.sort((a, b) => {
              const nameA = a.Name ?? '';
              const nameB = b.Name ?? '';
              return nameA.localeCompare(nameB);
            });
            this.filteredProducts = this.products;
          });

          this.checkboxColumns = this.getCheckboxColumns();
          this.isLoading = false;

      });
  }

unselectAll() {
  // Clear the current selected fields
  this.selectedFields = [];

  // Iterate over each column and its fields
  this.checkboxColumns.forEach(column => {
    column.fields.forEach(field => {
      if (!field.mandatory) { // Only update non-mandatory fields
        field.checked = false;
        // Add the field's systemName to the selectedFields array
        const index = this.selectedFields.indexOf(field);
        if (index >= 0) {
          this.selectedFields.splice(index, 1);
        }
      }
    });
  });
}

selectAll() {
  // Clear the current selected fields
  this.selectedFields = [];

  // Iterate over each column and its fields
  this.checkboxColumns.forEach(column => {
    column.fields.forEach(field => {
      if (!field.mandatory) { // Only update non-mandatory fields
        field.checked = true;
        // Add the field's systemName to the selectedFields array
        if (!this.selectedFields.includes(field)) {
          this.selectedFields.push(field);
        }
      }
    });
  });
}

    getCheckboxColumns(): CheckboxColumn[] {
    // Fetch or define your checkbox data here.

    return [
      {
        title: 'Basic',
        fields: [
          { description: 'ID', systemName: 'Id', checked: true, mandatory: true },
          { description: 'Name', systemName: 'Name', checked: true, mandatory: true },
          { description: 'Code', systemName: 'ProductRef', checked: true, mandatory: true },
          { description: 'Status', systemName: 'ActiveStatus', checked: true, mandatory: true },
          { description: 'Tier', systemName: 'TierName', checked: true, mandatory: true },
          { description: 'Aliases', systemName: 'Aliases', checked: false, mandatory: false },
          { description: 'Description', systemName: 'Description', checked: false, mandatory: false },
          { description: 'Suite', systemName: 'SuiteName', checked: false, mandatory: false },
          { description: 'Division', systemName: 'DivisionName', checked: false, mandatory: false },
          { description: 'Country', systemName: 'CountryName', checked: false, mandatory: false },
          { description: 'Tenancy Type', systemName: 'TenancyName', checked: false, mandatory: false },
          { description: 'Product Type', systemName: 'ProductTypeName', checked: false, mandatory: false },
          { description: 'Origin Company', systemName: 'OriginCompany', checked: false, mandatory: false },
          { description: 'Homepage URI', systemName: 'HomepageURI', checked: false, mandatory: false },
          { description: 'End-of-Life Date', systemName: 'EoLDate', checked: false, mandatory: false },
        ]
      },
      {
        title: 'Technical',
        fields: [
          { description: 'Distributions', systemName: 'xVx-TechSpecDistributions', checked: false, mandatory: false },
          { description: 'Platforms', systemName: 'xVx-TechSpecPlatforms', checked: false, mandatory: false },
          { description: 'Database Layer', systemName: 'xVx-TechPortfolioDatabases', checked: false, mandatory: false },
          { description: 'Application Layer', systemName: 'xVx-TechPortfolioProgrammingLanguages', checked: false, mandatory: false },
          { description: 'Client Layer', systemName: 'xVx-TechPortfolioUXUIs', checked: false, mandatory: false },
          { description: 'Other Tech Stack', systemName: 'xVx-TechSpecStacks', checked: false, mandatory: false },
          { description: 'Hosting Providers', systemName: 'xVx-TechSpecHostingProviders', checked: false, mandatory: false },
          { description: 'Authentication', systemName: 'xVx-TechSpecAuthenticationMechanisms', checked: false, mandatory: false },
          { description: 'HA Automation', systemName: 'AutomatedHAName', checked: false, mandatory: false },
          { description: 'Environments', systemName: 'xVx-TechSpecEnvironments', checked: false, mandatory: false },
          { description: 'Environment Comment', systemName: 'EnvironmentComment', checked: false, mandatory: false },

          // { description: 'Tech Stack', systemName: 'xVx-TechStack', checked: false, mandatory: false },

          { description: 'Source Control Tools', systemName: 'xVx-TechSpecSourceControlTypes', checked: false, mandatory: false },
          { description: 'Source Control Providers', systemName: 'xVx-TechSpecSourceControlProviders', checked: false, mandatory: false },
          { description: 'Build Tools', systemName: 'xVx-TechSpecBuildTools', checked: false, mandatory: false },
          { description: 'Deployment Tools', systemName: 'xVx-TechSpecDeploymentTools', checked: false, mandatory: false },
          { description: 'Backlog Tools', systemName: 'xVx-TechSpecBacklogTools', checked: false, mandatory: false },
          { description: 'Release Cadence', systemName: 'ReleaseCadenceName', checked: false, mandatory: false },
          { description: 'Test Automation', systemName: 'LevelOfTestAutomationName', checked: false, mandatory: false },
          { description: 'Code Volatility', systemName: 'CodeVolatilityName', checked: false, mandatory: false },
          { description: 'Deployment Comment', systemName: 'CiCdComment', checked: false, mandatory: false },
          { description: 'Customisation', systemName: 'CustomizationEnabledName', checked: false, mandatory: false },
          { description: 'Customisation Enablers', systemName: 'CustomizationEnabledMaintainers', checked: false, mandatory: false },
          { description: 'Degree of Customisation', systemName: 'DegreeOfCustomization', checked: false, mandatory: false },
        ]
      },
      {
        title: 'Security',
        fields: [
          { description: 'Functions', systemName: 'xVx-ProductMetricFunctionTypes', checked: false, mandatory: false },
          { description: 'Classification', systemName: 'ProductContributionName', checked: false, mandatory: false },
          { description: 'Data Sensitivity', systemName: 'ProductDataTypeName', checked: false, mandatory: false },
          { description: 'Lines of Code', systemName: 'LinesOfCode', checked: false, mandatory: false },
          { description: 'Number of Developers', systemName: 'DevCount', checked: false, mandatory: false },
          { description: 'Security Monitoring Tool', systemName: 'SecurityMonitoringTools', checked: false, mandatory: false },
          { description: 'Vulnerability Mngt Tools', systemName: 'xVx-ProductMetricVulnerabilityManagements', checked: false, mandatory: false },
          
          { description: 'Included in CE+', systemName: 'InCEPlus', checked: false, mandatory: false },
          { description: 'CE Comment', systemName: 'CEPlusComment', checked: false, mandatory: false },

          { description: 'All code in the SDLC', systemName: 'SDLCUsage', checked: false, mandatory: false },
          { description: 'Component Management', systemName: 'ComponentManagementName', checked: false, mandatory: false },
          { description: 'Third Party Components', systemName: 'NumberOfComponents', checked: false, mandatory: false },
        ]
      },
            {
        title: 'Other',
        fields: [
          { description: 'Joiner-Mover-Leaver-Process', systemName: 'JMLProcess', checked: false, mandatory: false },
          { description: 'User Report Process', systemName: 'UserReportProcess', checked: false, mandatory: false },

          { description: 'UX/UI Modernised', systemName: 'UXUIModernisationName', checked: false, mandatory: false },
          { description: 'Net New or Existing Code', systemName: 'NetNewOrExistingCodeName', checked: false, mandatory: false },
          { description: 'Customer Older Version', systemName: 'CustomerOlderVersion', checked: false, mandatory: false },
          { description: 'Roadmap Initiatives', systemName: 'RoadmapInitiatives', checked: false, mandatory: false },
          { description: 'Tech Debt', systemName: 'TechDebt', checked: false, mandatory: false },
          { description: 'R&D Resource Allocation', systemName: 'RandDResourceAllocation', checked: false, mandatory: false },
        ]
      },
    ];
  }

  onTiersChange(event: any) {
    this.selectedTiers = event.value; // Update selected tiers
    this.updateFilteredProducts();
  }

  onDivisionsChange(event: any) {
    this.selectedDivisions = event.value; // Update selected divisions
    this.updateFilteredProducts();
  }

  onSuitesChange(event: any) {
    this.selectedSuites = event.value; // Update selected suites
    this.updateFilteredProducts();
  }

  onProductsChange(event: any) {
    this.selectedProducts = event.value; // Update selected products
    this.updateFilteredProducts();
  }

onCheckboxChange(field: Field) {
  // Only process if the checkbox is not mandatory
  if (!field.mandatory) {
    // Check the current value of the field's checked property
    if (field.checked) {
      // If the checkbox is checked, add the system name to the selected fields
      if (!this.selectedFields.includes(field)) {
        this.selectedFields.push(field);
      }
    } else {
      // If the checkbox is unchecked, remove the system name from the selected fields
      const index = this.selectedFields.indexOf(field);
      if (index >= 0) {
        this.selectedFields.splice(index, 1);
      }
    }
  }
}

  updateFilteredProducts() {
    const selectedTierNames = this.selectedTiers.map((tier: any) => tier.Name);
    const selectedDivisionNames = this.selectedDivisions.map((division: any) => division.Name);
    const selectedSuiteNames = this.selectedSuites.map((suite: any) => suite.Name);
    const selectedProductNames = this.selectedProducts.map((product: any) => product.Name);

    this.filteredProducts = this.products;

    this.filteredProducts = this.filteredProducts.filter(product =>
      (selectedTierNames.length === 0 || selectedTierNames.includes(product.ProductMetric?.TierName ?? '')) &&
      (selectedDivisionNames.length === 0 || selectedDivisionNames.includes(product.DivisionName)) &&
      (selectedSuiteNames.length === 0 || selectedSuiteNames.includes(product.SuiteName)) &&
      (selectedProductNames.length === 0 || selectedProductNames.includes(product.Name))
    );

    console.log(this.filteredProducts);
  }

  generateReport() {

    const csvData = [];
    const csvHeader = ['Id','Name','Code','Status', 'Tier'];

    //iterate through the selectedFields
    this.selectedFields.forEach((field) => {
      csvHeader.push(field.description);
    });
    csvData.push(csvHeader);

    this.filteredProducts.forEach((product) => {
      const csvLine = [];
      csvLine.push(product.Id);
      csvLine.push(this.escapeCsvValue(product.Name ?? ''));
      csvLine.push(this.escapeCsvValue(product.ProductRef ?? ''));
      csvLine.push(product.ActiveStatus ?? '');
      csvLine.push(this.escapeCsvValue(product.ProductMetric?.TierName ?? ''));

      this.selectedFields.forEach((field) => {
        csvLine.push(this.escapeCsvValue(this.getFieldValue(product, field.systemName)));
      });

      csvData.push(csvLine);
    });

    let csvContent = 'data:text/csv;charset=utf-8,';
    csvData.forEach((row) => {
      csvContent += row.join(',') + '\r\n';
    });

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'portfolio.csv');
    document.body.appendChild(link);
    link.click();

  }

  getFieldValue(product: Product, fieldName: string) : string {

    switch(fieldName) {
      // Basic
      case "Aliases":
        return product?.Aliases?.map(alias => alias.Name).join('; ') ?? '';
      case "Description":
        return product?.Description ?? '';
        case "SuiteName":
          return product?.SuiteName ?? '';
      case "DivisionName":
        return product?.DivisionName ?? '';
      case "CountryName":
        return product?.CountryName ?? '';
      case "TenancyName":
        return product?.TenancyName ?? '';
      case "ProductTypeName":
        return product?.ProductTypeName ?? '';
      case "OriginCompany":
        return product?.OriginCompany ?? '';
      case "HomepageURI":
        return product?.HomepageURI ?? '';
      case "EoLDate":
        return product?.EoLDate?.toString() ?? '';

      // Technical
      case "xVx-TechSpecDistributions":
        return product?.ProductTechSpec?.TechSpecDistributions?.map(dist => dist.DistributionName).join('; ') ?? '';
      case "xVx-TechSpecPlatforms":
        return product?.ProductTechSpec?.TechSpecPlatforms?.map(platform => platform.PlatformName).join('; ') ?? '';
      case "xVx-TechPortfolioDatabases":
        return product?.ProductTechPortfolio?.TechPortfolioDatabases?.map(db => db.PortfolioDatabaseName).join('; ') ?? '';
      case "xVx-TechPortfolioProgrammingLanguages":
        return product?.ProductTechPortfolio?.TechPortfolioProgrammingLanguages?.map(lang => lang.PortfolioProgrammingLanguageName).join('; ') ?? '';
      case "xVx-TechPortfolioUXUIs":
        return product?.ProductTechPortfolio?.TechPortfolioUXUIs?.map(uxui => uxui.PortfolioUXUIName).join('; ') ?? '';
      case "xVx-TechSpecStacks":
        return product?.ProductTechSpec?.TechSpecStacks?.map(stack => stack.TechStackName).join('; ') ?? '';
      case "xVx-TechSpecHostingProviders":
        return product?.ProductTechSpec?.TechSpecHostingProviders?.map(provider => provider.HostingProviderName).join('; ') ?? '';
      case "xVx-TechSpecAuthenticationMechanisms":
        return product?.ProductTechSpec?.TechSpecAuthenticationMechanisms?.map(auth => auth.AuthenticationMechanismName).join('; ') ?? '';
      case "AutomatedHAName":
        return product?.ProductTechSpec?.AutomatedHAName ?? '';
      case "xVx-TechSpecEnvironments":
        return product?.ProductTechSpec?.TechSpecEnvironments?.map(env => env.EnvironmentTypeName).join('; ') ?? '';
      case "EnvironmentComment":
        return product?.ProductTechSpec?.EnvironmentComment ?? '';

      case "xVx-TechSpecSourceControlTypes":
        return product?.ProductTechSpec?.TechSpecSourceControlTypes?.map(sc => sc.SourceControlTypeName).join('; ') ?? '';
      case "xVx-TechSpecSourceControlProviders":
        return product?.ProductTechSpec?.TechSpecSourceControlProviders?.map(sc => sc.SourceControlProviderName).join('; ') ?? '';
      case "xVx-TechSpecBuildTools":
        return product?.ProductTechSpec?.TechSpecBuildTools?.map(bt => bt.BuildToolName).join('; ') ?? '';
      case "xVx-TechSpecDeploymentTools":
        return product?.ProductTechSpec?.TechSpecDeploymentTools?.map(dt => dt.DeploymentToolName).join('; ') ?? '';
      case "xVx-TechSpecBacklogTools":
        return product?.ProductTechSpec?.TechSpecBacklogTools?.map(bt => bt.BacklogToolName).join('; ') ?? '';
      case "ReleaseCadenceName":
        return product?.ProductTechSpec?.ReleaseCadenceName ?? '';
      case "LevelOfTestAutomationName":
        return product?.ProductTechSpec?.LevelOfTestAutomationName ?? '';
      case "CodeVolatilityName":
        return product?.ProductTechSpec?.CodeVolatilityName ?? '';
      case "CiCdComment":
        return product?.ProductTechSpec?.CiCdComment ?? '';
      case "CustomizationEnabledName":
        return product?.ProductTechPortfolio?.CustomizationEnabledName ?? '';
      case "CustomizationEnabledMaintainers":
        return product?.ProductTechPortfolio?.CustomizationEnabledMaintainers ?? '';
      case "DegreeOfCustomization":
        return product?.ProductTechPortfolio?.DegreeOfCustomization ?? '';

      // Security
      case "xVx-ProductMetricFunctionTypes":
        return product?.ProductMetric?.ProductMetricFunctionTypes?.map(func => func.FunctionTypeName).join('; ') ?? '';
      case "ProductContributionName":
        return product?.ProductMetric?.ProductContributionName ?? '';
      case "ProductDataTypeName":
        return product?.ProductMetric?.ProductDataTypeName ?? '';
      case "LinesOfCode":
        return product?.ProductMetric?.LinesOfCode?.toString() ?? '';
      case "DevCount":
        return product?.ProductMetric?.DevCount?.toString() ?? '';
      case "SecurityMonitoringTools":
        return product?.ProductMetric?.ProdSecMonitoringName ?? '';
      case "xVx-ProductMetricVulnerabilityManagements":
        return product?.ProductMetric?.ProductMetricVulnerabilityManagements?.map(vm => vm.VulnerabilityManagementName).join('; ') ?? '';
      
      case "InCEPlus":
        return product?.ProductMetric?.InCEPlus?.toString() ?? '';
      case "CEPlusComment":
        return product?.ProductMetric?.CEPlusComment ?? '';
      
      case "SDLCUsage":
        return product?.ProductMetric?.SDLCUsage?.toString() ?? '';
      case "ComponentManagementName":
        return product?.ProductMetric?.ComponentManagementName ?? '';
      case "NumberOfComponents":
        return product?.ProductMetric?.NumberOfComponents?.toString() ?? '';

      // Other
      case "JMLProcess":
        return product?.ProductTechPortfolio?.JMLProcess ?? '';
      case "UserReportProcess":
        return product?.ProductTechPortfolio?.UserReportProcess ?? '';
      
      case "UXUIModernisationName":
        return product?.ProductTechPortfolio?.UXUIModernisationName ?? '';
      case "NetNewOrExistingCodeName":
        return product?.ProductTechPortfolio?.NetNewOrExistingCodeName ?? '';
      case "CustomerOlderVersion":
        return product?.ProductTechPortfolio?.CustomerOlderVersion ?? '';
      case "RoadmapInitiatives":
        return product?.ProductTechPortfolio?.RoadmapInitiatives ?? '';
      case "TechDebt":
        return product?.ProductTechPortfolio?.TechDebt ?? '';
      case "RandDResourceAllocation":
        return product?.ProductTechPortfolio?.RandDResourceAllocation ?? '';
      
      default:
        return '';
    }
  }

  escapeCsvValue(value: string): string {

    if (value == null) {
      return '';
    }

    // Convert to string
    value = String(value);

    // Replace newline and carriage return with a space
    value = value.replace(/[\r\n]+/g, ' ');

    // Escape double quotes
    value = value.replace(/"/g, '""');

    //Escape #, as it is a special character in CSV
    value = value.replace(/#/g, '');

    // Trim whitespace
    value = value.trim();

    // Enclose in quotes if it contains special characters
    if (/[,"[\r\n]/.test(value)) {
      value = `"${value}"`;
    }

    return value;
  }

}
