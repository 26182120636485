import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Alias, AliasRequestDto, Product, Suite } from 'src/app/api/product';
import { ProductService } from 'src/app/layout/service/product/products.service';
import { ProductTechSpecService } from 'src/app/layout/service/product/product-tech-spec.service';
import { ProductTechSpec, TechSpecDistributionDto } from 'src/app/api/product-tech-spec';
import { ProductMetricService } from 'src/app/layout/service/product/product-metric.service';
import { ProductMetric } from 'src/app/api/product-metric';
import { ToolExternalService } from 'src/app/layout/service/tool-external.service';
import { FavouritesService } from 'src/app/layout/service/favourite.service';
import { LogService } from 'src/app/layout/service/log.service';
import { ProductSelectionService } from 'src/app/product.selection.service';
import { ProductTechPortfolio } from 'src/app/api/product-tech-portfolio';
import { ProductTechPortfolioService } from 'src/app/layout/service/product/product-tech-portfolio.service';

@Component({
  selector: 'app-product-dashboard',
  templateUrl: './product-dashboard.component.html',
  styleUrls: ['product-dashboard.component.scss']
})
export class ProductDashboardComponent implements OnInit {
  productId: string = '';
  productTechSpecId: number | undefined;
  productMetricId: number | undefined;
  productTechPortfolioId: number | undefined;
  suiteId: string = '';

  isLoading = true;

  product: Product = {};
  suite: Suite = {};
  productTechSpec: ProductTechSpec = {};
  productMetric: ProductMetric = {};
  productTechPortfolio: ProductTechPortfolio = {};
  selectedDistributions: TechSpecDistributionDto[] | undefined = [];
  selectedTechStacks: any[] | undefined = [];
  selectedProviders: any[] | undefined = [];
  selectedPlatforms: any[] | undefined = [];
  selectedAuthenticationMechanisms: any[] | undefined = [];

  selectedTechPortfolioDatabases: any[] | undefined = [];
  selectedTechPortfolioApplicationLayer: any[] | undefined = [];
  selectedTechPortfolioClientLayer: any[] | undefined = [];

  logo: string = '';

  aliases: any[] = [];

  widgetData: any = {};

  favourites: any[] = [];

  constructor(private route: ActivatedRoute,
    private productService: ProductService, 
    private productTechSpecService: ProductTechSpecService, 
    private productMetricService: ProductMetricService, private toolExternalService: ToolExternalService,
    private favouritesService: FavouritesService, private logService: LogService,
    private productSelectionService: ProductSelectionService,
    private productTechPortfolioService: ProductTechPortfolioService) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.productId = params['id'];

      this.productService.getProductByIdTopbar(this.productId).subscribe((product) => {
        this.productSelectionService.setSelectedProduct(product);
      });

      this.resetState();
      this.fetchProductData();

      this.widgetData = {
        objectId: this.productId
      };

      this.loadFavourites();
    });
  }

  handleItemClick(item: any): void {
    item.command(); // Execute the corresponding command associated with the menu item
  }

  private fetchProductData() {
    this.productService.getProductById(this.productId).subscribe((product) => {
      this.product = product;
      this.aliases = product.Aliases ?? [];
      this.productTechSpecId = this.product.ProductTechSpec?.Id;
      this.productMetricId = this.product.ProductMetric?.Id;
      this.productTechPortfolioId = this.product.ProductTechPortfolio?.Id;
      this.suiteId = this.product.SuiteId ?? '';
      this.logo = this.product.Logo ?? '';

      this.fetchProductTechSpecData();
      this.fetchProductMetricData();
      this.fetchProductTechPortfolioData();
      this.fetchSuiteData();
  
      this.isLoading = false;
    });
  }

  aliasDialogVisible: boolean = false;

  showAliases() {
    this.aliasDialogVisible = true;
  }

  private fetchProductTechSpecData() {
    if (this.productTechSpecId !== undefined) {
      this.productTechSpecService.getProductTechSpecById(this.productTechSpecId).subscribe((productTechSpec) => {
        this.productTechSpec = productTechSpec;
        this.selectedDistributions = this.productTechSpec.TechSpecDistributions;
        this.selectedTechStacks = this.productTechSpec.TechSpecStacks;
        this.selectedProviders = this.productTechSpec.TechSpecHostingProviders;
        this.selectedPlatforms = this.productTechSpec.TechSpecPlatforms;
        this.selectedAuthenticationMechanisms = this.productTechSpec.TechSpecAuthenticationMechanisms;
      });
    } else {
      // Handle the case where this.product.ProductTechSpec?.Id is undefined
      console.error('ProductTechSpec Id is undefined');
    }
  }

  private fetchProductTechPortfolioData() {
    if (this.productTechPortfolioId !== undefined) {
      this.productTechPortfolioService.getProductTechPortfolioById(this.productTechPortfolioId).subscribe((productTechPortfolio) => {
        this.productTechPortfolio = productTechPortfolio;
        this.selectedTechPortfolioDatabases = this.productTechPortfolio.TechPortfolioDatabases;
        this.selectedTechPortfolioApplicationLayer = this.productTechPortfolio.TechPortfolioProgrammingLanguages;
        this.selectedTechPortfolioClientLayer = this.productTechPortfolio.TechPortfolioUXUIs;
      });
    } else {
      // Handle the case where this.product.ProductTechPortfolio?.Id is undefined
      console.error('ProductTechPortfolio Id is undefined');
    }
  }


  private fetchSuiteData() {
    if (this.suiteId !== '') {
      this.productService.getSuiteById(this.suiteId).subscribe((suite) => {
        this.suite = suite;

        //If the product itself doesn't have a logo, but the parent suite does, then use that
        if (this.logo == '') {
          this.logo = this.suite?.Logo ?? '';
        }
      });
    }
  }

  private fetchProductMetricData() {
    if (this.productMetricId !== undefined) {
      this.productMetricService.getProductMetricById(this.productMetricId).subscribe((productMetric) => {
        this.productMetric = productMetric;
      });
    } else {
      // Handle the case where this.product.ProductTechSpec?.Id is undefined
      console.error('productMetricId Id is undefined');
    }
  }

  trimOption(option: string | undefined): string {
    return option ? option.trim() : '';
  }

  newAlias: string = '';

  addAlias() {
    if (this.newAlias) {
      const aliasRequest: AliasRequestDto = {
        Name: this.newAlias,
        ProductId: parseInt(this.productId, 10)
      };
      this.productService.createAlias(aliasRequest).subscribe((newAlias) => {
        this.aliases.push({ Id: newAlias.id, Name: newAlias.name, ProductId: newAlias.productId });
        this.newAlias = '';
        this.logService.sendInfoMessage('CREATE', 'product/' + this.productId + '/alias/', newAlias);
      });
    }
  }

  removeAlias(alias: Alias) {
    if (alias.Id) {
      this.productService.deleteAlias(alias.Id).subscribe(() => {
        this.aliases = this.aliases.filter(a => a.Id !== alias.Id);
        this.logService.sendInfoMessage('DELETE', 'product/' + this.productId + '/alias/' + alias.Id, null, alias);
      });
    }
  }

  tagify(option: string | undefined): string {
    if (!option) {
      return '';
    }
    let tagValue = option.trim();
    tagValue = tagValue.replace(/\s+/g, '_');
    tagValue = tagValue.replace(/[^a-zA-Z0-9_]/g, '');
    return tagValue;
  }

  loadFavourites() {
    this.favourites = this.favouritesService.getProductFavourites();
  }

  isFavourite(productId: any): boolean {
    return this.favouritesService.isFavourite(this.favourites, productId);
  }

  toggleFavourite(productId: any) {
    this.favourites = this.favouritesService.toggleFavourite(this.favourites, productId);
    this.favouritesService.saveProductFavourites(this.favourites);
  }

  resetState(): void {
    this.productTechSpecId = undefined;
    this.productMetricId = undefined;

    this.product = {};
    this.productTechSpec = {};
    this.productMetric = {};
    this.productTechPortfolio = {};
    this.selectedDistributions = [];
    this.selectedTechStacks = [];
    this.selectedProviders = [];
    this.selectedPlatforms = [];
    this.selectedAuthenticationMechanisms = [];

    this.selectedTechPortfolioDatabases = [];
    this.selectedTechPortfolioApplicationLayer = [];
    this.selectedTechPortfolioClientLayer = [];

    this.widgetData = {};

    this.aliases = [];

  }
}

