import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ToolExternalService } from 'src/app/layout/service/tool-external.service';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { SonarIssueData, SonarIssueSeverity, SonarFacets, SonarComponent } from 'src/app/api/tools';
import { Router } from '@angular/router';
type SonarFacetType = "Code Smell" | "bug" | "vulnerability";

@Component({
  selector: 'sonar-issues-widget',
  templateUrl: './sonar-issues-widget.component.html'
})



export class SonarIssuesWidgetComponent implements OnInit, OnChanges {

  @Input() data: any;
  pieData: any;
  pieOptions: any;
  sonarMetricsReportingLevel: string = '';
  sonarIssuesLoading: boolean = true;
  sonarLink: string = '';
  sonarIssuesAvailable: boolean = false;
  isCollapsed: boolean = false;
  issueTypes: string[] = ['Code Smell', 'bug', 'vulnerability'];
  sonarIssueData: any;
  sonarSourceData: any;
  sonarIssueOptions: any;

  productType: string = "";

  routingProductType: string = "";
  routingObjectId: string = "";

  selectedMetricName: string = "";
  components: string[] = [];
  hasMultipleComponents: boolean = false;
  selectedComponent: SonarComponent | undefined = undefined;

  widgetContext: string = '';
  isInitialized: boolean = false;

  selectedIssueTypes: { [key: string]: boolean } = this.issueTypes.reduce((acc, type) => {
    acc[type] = true;
    return acc;
  }, {} as { [key: string]: boolean });

  constructor(private toolExternalService: ToolExternalService, private router: Router) { }

  ngOnInit(): void {
    this.widgetContext = this.data.objectId;
    this.initialiseWidgetOnce();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data']) {
      if (this.widgetContext !== this.data.objectId) {
        this.isInitialized = false;
        this.widgetContext = this.data.objectId;
      }
      this.resetWidget();
      this.initialiseWidgetOnce();
    }
  }

  private initialiseWidgetOnce(): void {
    if (!this.isInitialized) {
      this.initialiseWidget();
      this.isInitialized = true;
    }
  }

  initialiseWidget() {
    this.resetWidget();
    this.pieOptions = {
      plugins: {
        legend: {
          display: false
        }
      }
    };



    //convert this.productId to a number
    const prodNumber = parseInt(this.data.objectId);
    this.productType = this.data?.productType ?? null;
    const currentUrl = this.router.url;

    this.toolExternalService.getSonarIssues(prodNumber, this.productType).pipe(
      catchError((error) => {
        if (error.status === 404) {
          this.sonarMetricsReportingLevel = "No SonarQube data available";
        } else {
          this.sonarMetricsReportingLevel = "Unexpected error occured getting Sonar data for this product";
          console.error('An error occurred:', error);
        }
        this.sonarIssuesLoading = false;
        return of(null);
      })
    ).subscribe((sonarIssuesData) => {
      if (!sonarIssuesData) {
        this.sonarIssuesLoading = false;
        this.sonarMetricsReportingLevel = "No SonarQube data available";
        console.error("No Sonar data found");
        return;
      } else {

        if (sonarIssuesData.productType === 'Product') {
          this.sonarMetricsReportingLevel = "";
          this.routingProductType = "Product";
          this.routingObjectId = sonarIssuesData.objectId;
        } else if (sonarIssuesData.productType === 'Suite') {

          this.routingProductType = "Suite";
          this.routingObjectId = sonarIssuesData.objectId;

           if (currentUrl.includes('/suite/')) {
            this.sonarMetricsReportingLevel = "SonarQube data not available";
            this.sonarIssuesLoading = false;
            return;
          }        
        } else {
          this.sonarMetricsReportingLevel = "SonarQube data not available at this product level. Showing data for parent suite: " + sonarIssuesData.objectName;
          this.routingProductType = "Suite";
          this.routingObjectId = sonarIssuesData.objectId;
        }

        this.sonarLink = "https://sonarqube.enterprise.central.ngiris.io/project/issues?status=OPEN&resolved=false&id=" + sonarIssuesData.sonarComponent;

        this.sonarSourceData = sonarIssuesData;
        this.components = [];
        for (const comp of sonarIssuesData.data) {
          this.updateComponentDropdown(comp.component ?? "??");
        }
        this.selectedComponent = sonarIssuesData.data[0];
        this.selectedMetricName = this.selectedComponent?.component ?? "";
        this.hasMultipleComponents = this.components.length > 1;
                
        this.sonarIssueData = this.transformSonarData(this.selectedComponent);

        this.sonarIssuesAvailable = true;
        this.sonarIssuesLoading = false;
      }

    });
  }

  toggleWidgetCollapse() {
    this.isCollapsed = !this.isCollapsed;
  }

  onDropdownChange(event: any) {
    const selectedValue = event.value;
    this.selectedMetricName = selectedValue;

    for (const dataComponent of this.sonarSourceData.data) {
      if (dataComponent.component === selectedValue) {
        this.selectedComponent = dataComponent;
        break;
      }
    }

    this.sonarIssueData = this.transformSonarData(this.selectedComponent);
  }

  private transformSonarData(sonarData?: SonarComponent) {

    if (sonarData === undefined) {
      return {};
    }

    const severityLevels = ['HIGH', 'MEDIUM', 'LOW'];

    const chartData = {
      labels: this.issueTypes.filter(type => this.selectedIssueTypes[type]),
      datasets: severityLevels.map((severityLevel) => {
        const color = this.getSonarChartColour(severityLevel);
        const hoverColor = this.getSonarChartHoverColour(severityLevel);

        return {
          label: severityLevel,
          data: this.issueTypes.filter(type => this.selectedIssueTypes[type]).map(issueType => {
            if (sonarData.severities) {
              const issue = sonarData.severities.find((issue: SonarIssueSeverity) => issue.severity === severityLevel);
              const item = issue?.facets as SonarFacets;
              return issue ? (issue.facets as SonarFacets)[issueType as SonarFacetType] : 0;
            } else {
              return 0;
            }

          }),
          backgroundColor: color,
          hoverBackgroundColor: hoverColor
        };
      })
    };

    this.sonarIssueOptions = {
      plugins: {
        tooltip: {
          enabled: true
        }
      },
      onHover: (event: any, chartElement: any) => {
        event.native.target.style.cursor = chartElement[0] ? 'pointer' : 'default';
      },
      onClick: (event: MouseEvent, elements: any[]) => {

        if (elements.length > 0) {
          // Get the first clicked element
          const element = elements[0];
          // Get the dataset index and data index
          const datasetIndex = element.datasetIndex;
          const dataIndex = element.index;

          // Generate the URL based on the severity level and issue type
          const url = this.generateIssueLink(datasetIndex);

            // Open the link in a new tab
            window.open(url, '_blank');
          
        }
      }
    };

    return chartData;
  };

  private generateIssueLink(severity: number): string {
    // create a url from the root of this site with /vulnerabilities/search
    // and a query string of severity and issueType

    if (this.routingProductType == "Suite") {
      return window.location.origin + `/vulnerabilities/search?severities=${severity}&suites=${this.routingObjectId}&toolName=Sonarqube%20(Enterprise)`;      
    } else {
      return window.location.origin + `/vulnerabilities/search?severities=${severity}&products=${this.routingObjectId}&toolName=Sonarqube%20(Enterprise)`;
    }    
  }

  private getSonarChartColour(severity: string): string {

    switch (severity) {
      case 'HIGH':
        return '#FF4500';
      case 'MEDIUM':
        return '#FFA500';
      case 'LOW':
        return '#FFD700';
      default:
        return '#000000';
    }
  }

  private getSonarChartHoverColour(severity: string): string {

    switch (severity) {
      case 'HIGH':
        return '#D00000';
      case 'MEDIUM':
        return '#B65911';
      case 'LOW':
        return '#AF8F00';
      default:
        return '#000000';
    }
  }

  updateChartData() {
    this.sonarIssueData = this.transformSonarData(this.selectedComponent);
  }

  selectedIssueTypesChange() {
    this.updateChartData();
  }

  updateComponentDropdown(newComponent: string) {
    if (!this.components.includes(newComponent)) {
      this.components.push(newComponent);
    }
  }

  resetWidget() {
    this.sonarIssueData = {};
    this.sonarMetricsReportingLevel = '';
    this.sonarIssuesLoading = true;
    this.sonarLink = '';
    this.sonarIssuesAvailable = false;
    this.isCollapsed = false;
    this.issueTypes = ['Code Smell', 'bug', 'vulnerability'];
    this.sonarIssueData = {};
    this.sonarSourceData = {};
    this.sonarIssueOptions = {};
    this.selectedMetricName = "";
    this.components = [];
    this.hasMultipleComponents = false;
    this.selectedComponent = undefined;
    this.selectedIssueTypes = this.issueTypes.reduce((acc, type) => {
      acc['vulnerability'] = true;
      return acc;
    }, {} as { [key: string]: boolean });
  }

}
