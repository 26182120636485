<h1>Report Recipients</h1>

<p-toast></p-toast>

<p-tabView (onChange)="onTabChange($event)">
  <!-- Dynamically generated tabs based on report types -->
  <p-tabPanel *ngFor="let type of reportTypes" [header]="type.name">
    <p-tabView>
      <!-- Products Tab -->
      <p-tabPanel header="Products">
        <p-table [value]="productContacts" dataKey="Id" expandableRows [(selection)]="selectedEmails">
          <ng-template pTemplate="header">
            <tr>
              <th>
                <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
              </th>
              <th pSortableColumn="Name">Product Name
                <p-columnFilter type="text" field="ProductName" display="menu" (click)="$event.stopPropagation()"></p-columnFilter>
              </th>
              <th pSortableColumn="SuiteName">Suite
                <p-columnFilter type="text" field="SuiteName" matchMode="equals" display="menu" placeholder="Search by suite" (click)="$event.stopPropagation()">
                  <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                    <p-dropdown [ngModel]="value" [options]="suites" optionLabel="Name" optionValue="Name" (onChange)="filter($event.value)" placeholder="Any" [style]="{'min-width': '12rem'}">
                      <ng-template let-option pTemplate="item">
                        <span [class]="'suite-badge suite-' + trimOption(option.Name)">{{option.Name}}</span>
                      </ng-template>
                    </p-dropdown>
                  </ng-template>
                </p-columnFilter>
              </th>
              <th>Expand</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-product>
            <tr [pSelectableRow]="product" [ngClass]="{'expanded-row': product.expanded}">
              <td>
                <p-tableCheckbox [value]="product"></p-tableCheckbox>
              </td>
              <td>{{ product.ProductName }}</td>
              <td>{{ product.SuiteName }}</td>
              <td class="flex justify-content-between align-items-center">
                <button pButton type="button" icon="pi pi-chevron-down" (click)="product.expanded = !product.expanded" (click)="$event.stopPropagation()"></button>
                <i *ngIf="hasNoEmails(product, 'product')" class="pi pi-info-circle" style="color: red;" pTooltip="No Recipents" tooltipPosition="top" title="No ToEmails or CCEmails"></i>
              </td>
            </tr>
            <tr *ngIf="product.expanded" class="expanded-content">
              <td colspan="2">
                <div class="expanded-content-container">
                  <h3 class="expanded-heading">To Recipients</h3>
                  <p-table [value]="product.ToEmails">
                    <ng-template pTemplate="header">
                      <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Action</th>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-contact>
                      <tr>
                        <td>{{ contact.Name }}</td>
                        <td>{{ contact.Mail }}</td>
                        <td>
                          <button pButton type="button" icon="pi pi-angle-double-down" (click)="moveToCC(contact, product)" (click)="$event.stopPropagation()" pTooltip="Move to CC"></button>
                          <button pButton type="button" icon="pi pi-trash" class="p-button-danger ml-2" (click)="handleDelete(contact, product)" (click)="$event.stopPropagation()" pTooltip="Remove Recipient"></button>
                        </td>
                      </tr>
                    </ng-template>
                  </p-table>
                  <button pButton pRipple icon="pi pi-plus" class="p-button-success p-button-rounded mt-1" (click)="openUserDialog('addToToRecipients', product, 'product')" (click)="$event.stopPropagation()"></button>
    
                  <h3 class="expanded-heading">CC Recipients</h3>
                  <p-table [value]="product.CCEmails">
                    <ng-template pTemplate="header">
                      <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Action</th>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-contact>
                      <tr>
                        <td>{{ contact.Name }}</td>
                        <td>{{ contact.Mail }}</td>
                        <td>
                          <button pButton type="button" icon="pi pi-angle-double-up" (click)="moveToTo(contact, product)" (click)="$event.stopPropagation()" pTooltip="Move to To"></button>
                          <button pButton type="button" icon="pi pi-trash" class="p-button-danger ml-2" (click)="handleDelete(contact, product)" (click)="$event.stopPropagation()" pTooltip="Delete Recipient"></button>
                        </td>
                      </tr>
                    </ng-template>
                  </p-table>
                  <button pButton pRipple icon="pi pi-plus" class="p-button-success p-button-rounded mt-1" (click)="openUserDialog('addToCCRecipients', product, 'product')" (click)="$event.stopPropagation()"></button>
                </div>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </p-tabPanel>
    
      <!-- Suites Tab -->
      <p-tabPanel header="Suites">
        <p-table [value]="suiteContacts" dataKey="Id" expandableRows [(selection)]="selectedEmails">
          <ng-template pTemplate="header">
            <tr>
              <th>
                <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
              </th>
              <th>Suite Name</th>
              <th>Expand</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-suite>
            <tr [ngClass]="{'expanded-row': suite.expanded}">
              <td>
                <p-tableCheckbox [value]="suite"></p-tableCheckbox>
              </td>
              <td>{{ suite.SuiteName }}</td>
              <td class="flex justify-content-between align-items-center">
                <button pButton type="button" icon="pi pi-chevron-down" (click)="suite.expanded = !suite.expanded" (click)="$event.stopPropagation()"></button>
                <i *ngIf="hasNoEmails(suite, 'suite')" class="pi pi-info-circle" style="color: red;" pTooltip="No Recipents" tooltipPosition="top" title="No ToEmails or CCEmails"></i>
              </td>
            </tr>
            <tr *ngIf="suite.expanded" class="expanded-content">
              <td colspan="2">
                <div class="expanded-content-container">
                  <h3 class="expanded-heading">To Recipients</h3>
                  <p-table [value]="suite.ToEmails">
                    <ng-template pTemplate="header">
                      <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Action</th>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-contact>
                      <tr>
                        <td>{{ contact.Name }}</td>
                        <td>{{ contact.Mail }}</td>
                        <td>
                          <button pButton type="button" icon="pi pi-angle-double-down" (click)="moveToCC(contact, suite)" (click)="$event.stopPropagation()" pTooltip="Move to CC"></button>
                          <button pButton type="button" icon="pi pi-trash" class="p-button-danger ml-2" (click)="handleDelete(contact, suite)" (click)="$event.stopPropagation()" pTooltip="Delete Recipient"></button>
                        </td>
                      </tr>
                    </ng-template>
                  </p-table>
                  <button pButton pRipple icon="pi pi-plus" class="p-button-success p-button-rounded mt-1" (click)="openUserDialog('addToToRecipients', suite, 'suite')" (click)="$event.stopPropagation()"></button>
    
                  <h3 class="expanded-heading">CC Recipients</h3>
                  <p-table [value]="suite.CCEmails">
                    <ng-template pTemplate="header">
                      <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Action</th>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-contact>
                      <tr>
                        <td>{{ contact.Name }}</td>
                        <td>{{ contact.Mail }}</td>
                        <td>
                          <button pButton type="button" icon="pi pi-angle-double-up" (click)="moveToTo(contact, suite)" (click)="$event.stopPropagation()" pTooltip="Move to To"></button>
                          <button pButton type="button" icon="pi pi-trash" class="p-button-danger ml-2" (click)="handleDelete(contact, suite)" (click)="$event.stopPropagation()" pTooltip="Delete Recipient"></button>
                        </td>
                      </tr>
                    </ng-template>
                  </p-table>
                  <button pButton pRipple icon="pi pi-plus" class="p-button-success p-button-rounded mt-1" (click)="openUserDialog('addToCCRecipients', suite, 'suite')" (click)="$event.stopPropagation()"></button>
                </div>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </p-tabPanel>
    </p-tabView>
  </p-tabPanel>
</p-tabView>

  
  
  <p-dialog [(visible)]="userDialog" [modal]="true" [style]="{width: '750px'}" header="Add new user" [contentStyle]="{'overflow': 'visible'}" class="p-fluid">
    <ng-template pTemplate="content">
      <div class="field">
        <p-dropdown [options]="users" scrollHeight="300px" [(ngModel)]="selectedUser" [style]="{'width':'100%'}" optionLabel="displayName" [filter]="true" (onFilter)="searchUsers($event)" [showClear]="true" placeholder="Select a User">
          <ng-template pTemplate="selectedItem">
              <div class="flex align-items-center gap-2" *ngIf="selectedUser">
                  <div>{{ selectedUser.displayName }}</div>
              </div>
          </ng-template>
          <ng-template let-user pTemplate="item">
              <div class="flex align-items-center gap-2">
                  <img src="assets/layout/images/iris-circle-logo.png" alt="" width="60px" height="" class="shadow-2 border-circle">
                  <div class="flex w-full gap-2 flex-column p-4">
                      <div>{{ user.displayName }}</div>
                      <div>({{ user.mail }})</div>
                  </div>
              </div>
          </ng-template>
      </p-dropdown>
      </div>
    </ng-template>
  
    <ng-template pTemplate="footer">
      <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="hideUserDialog()"></button>
      <button pButton pRipple label="Add" icon="pi pi-check" class="p-button-text" (click)="addUserToRecipients()"></button>
    </ng-template>
  </p-dialog>
  

  <!-- <button pButton type="button" icon="pi pi-envelope" label="Compose Email" (click)="composeEmail(selectedEmails)"></button> -->
  <button pButton type="button" icon="pi pi-envelope" label="Compose Email" (click)="composeEmail(selectedEmails)" class="floating-button"></button>
