<h1>Links</h1>

<p-toast></p-toast>

<p-messages [(value)]="messages" [enableService]="false" [closable]="true"></p-messages>

<div class="flex justify-content-end mb-2">
  <button pButton pRipple label="Add Link" icon="pi pi-plus" class="p-button-success mb-2" (click)="openNewLinkDialog()"></button>
</div>

<div *ngIf="links.length > 0; else noLinks">
  <p-table [value]="links">
    <ng-template pTemplate="header">
      <tr>
        <th style="width:90%">Link</th>
        <th style="width:10%">Actions</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-link>
      <tr>
        <td><a href="{{ link.Url }}" target="_blank">{{ link.Name }}</a></td>
        <td>
          <button type="button" pbutton="" style="margin-right:3px" icon="pi pi-pencil" class="p-element p-button p-component p-button-icon-only" ng-reflect-icon="pi pi-pencil" title="Edit link" (click)="openEditLinkDialog(link)">
            <span class="p-button-icon pi pi-pencil" aria-hidden="true"></span>
          </button>
          <button type="button" pbutton="" icon="pi pi-trash" class="p-element p-button-danger p-button p-component p-button-icon-only" ng-reflect-icon="pi pi-trash" title="Delete link" (click)="deleteLink(link)">
            <span class="p-button-icon pi pi-trash" aria-hidden="true"></span>
          </button>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>

<ng-template #noLinks>
  <div>No links have been added for this product yet</div>
</ng-template>

  <p-dialog [(visible)]="newLinkDialog" [modal]="true" [style]="{width: '750px'}" header="Add New Link" [contentStyle]="{'overflow': 'visible'}" [modal]="true" class="p-fluid">
    <ng-template pTemplate="content">
      <div class="field">
        <input type="text" pInputText placeholder="Link Name / Description" [(ngModel)]="newName" (keydown.enter)="addNewLink()" />
        <div *ngIf="validationErrors.newName" class="validation-error-message">
          {{ validationErrors.newName }}
        </div>
      </div>
      <div class="field">
        <input type="text" pInputText placeholder="URL" [(ngModel)]="newUrl" (keydown.enter)="addNewLink()" />
        <div *ngIf="validationErrors.newUrl" class="validation-error-message">
          {{ validationErrors.newUrl }}
        </div>
      </div>
    </ng-template>
    <ng-template pTemplate="footer">
      <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="hideDialog()"></button>
      <button pButton pRipple label="Add" icon="pi pi-check" class="p-button-text" (click)="addNewLink()"></button>
    </ng-template>

  </p-dialog>

  <p-dialog [(visible)]="editLinkDialog" [modal]="true" [style]="{width: '750px'}" header="Edit Link" [contentStyle]="{'overflow': 'visible'}" [modal]="true" class="p-fluid">
    <ng-template pTemplate="content">
      <div class="field">
        <input type="text" pInputText placeholder="Link Name / Description" [(ngModel)]="editName" (keydown.enter)="updateLink()" />
        <div *ngIf="validationErrors.newName" class="validation-error-message">
          {{ validationErrors.newName }}
        </div>
      </div>
      <div class="field">
        <input type="text" pInputText placeholder="URL" [(ngModel)]="editUrl" (keydown.enter)="updateLink()" />
        <div *ngIf="validationErrors.newUrl" class="validation-error-message">
          {{ validationErrors.newUrl }}
        </div>
      </div>
    </ng-template>
    <ng-template pTemplate="footer">
      <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="hideEditDialog()"></button>
      <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text" (click)="updateLink()"></button>
    </ng-template>

  </p-dialog>
