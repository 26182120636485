<div class="widgetCard" [class.collapsed]="isCollapsed">
  <div class="widgetCardHeader" (click)="toggleWidgetCollapse()">
    <h5>Current Sonar Issues</h5>
    <div class="chevron">
      <i [class]="isCollapsed ? 'chevron-down' : 'chevron-up'"></i>
    </div>
  </div>
  <ng-container *ngIf="!isCollapsed">
    <ng-container *ngIf="sonarIssuesLoading; else sonarIssuesContent">
      <p-skeleton width="10%" height="1rem" margin="5px"></p-skeleton>
    </ng-container>
    <ng-template #sonarIssuesContent>
      <span class="reportingLevelLabel">{{ sonarMetricsReportingLevel }}</span>
      <div class="reportingLevelLabel" *ngIf="hasMultipleComponents">
        <p-dropdown appendTo="body" [(ngModel)]="selectedMetricName" [options]="components" placeholder="Select" class="uniform-width-input" [style]="{'minWidth':'40%', 'maxWidth': '400px'}" (onChange)="onDropdownChange($event)">
          <ng-template pTemplate="selectedItem">
            <span *ngIf="selectedMetricName">{{ selectedMetricName }}</span>
          </ng-template>
          <ng-template let-option pTemplate="item">
            <span>{{option}}</span>
          </ng-template>
        </p-dropdown>
      </div>
      <div class="pieWidgetContainer" *ngIf="sonarIssuesAvailable">
        <div class="filter-container">
          <div class="filter-container-title">Filter</div>
          <label *ngFor="let issueType of issueTypes">
            <input type="checkbox" [(ngModel)]="selectedIssueTypes[issueType]" [value]="issueType" (change)="updateChartData()"> {{ issueType }}
          </label>
          <span class="snykLink"><a href="{{ sonarLink }}" target="_blank">View Issues in Sonar</a></span>
        </div>
        <p-chart type="bar" width="500px" height="250px" [data]="sonarIssueData" [options]="sonarIssueOptions"></p-chart>
      </div>
    </ng-template>
  </ng-container>
</div>
