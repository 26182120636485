<h1>Products</h1>

<div class="grid">
    <div class="col-12">
        <button pButton pRipple label="Add Product" icon="pi pi-plus" class="p-button-success mt-5 mb-5" 
            *appHasRole="['Lens.Admin']" (click)="openNew()"></button>
        <div class="card">
            <div class="flex justify-content-between mb-2">
                <span>
                    <button type="button" pButton pRipple icon="pi pi-file-excel" (click)="exportExcel()" class="p-button-success mr-2"  pTooltip="XLS" tooltipPosition="bottom"></button>
                </span>

                <p-multiSelect [options]="cols" [(ngModel)]="selectedColumns" optionLabel="header"
                    selectedItemsLabel="{0} columns selected" [style]="{'min-width': '200px'}" 
                    placeholder="Choose Columns"></p-multiSelect>
            </div>

            <p-table #dt1 [columns]="selectedColumns" [value]="products" dataKey="Id" [rows]="10" [rowHover]="true" styleClass="p-datatable-gridlines" [paginator]="true"
                [globalFilterFields]="cols" [showCurrentPageReport]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" responsiveLayout="scroll"
                sortMode="multiple" stateStorage="session" stateKey="statedemo-session" [rowsPerPageOptions]="[10, 20, 30, 40]">
                <ng-template pTemplate="caption">
                    <div class="flex justify-content-between flex-column sm:flex-row">
                        <button pButton label="Clear" class="p-button-outlined mb-2" icon="pi pi-filter-slash" (click)="clear(dt1)"></button>

                        <span class="p-input-icon-left mb-2">
                            <i class="pi pi-search"></i>
                            <input pInputText type="text" #filter (input)="onGlobalFilter(dt1, $event)" placeholder="Search Keyword" class="w-full"/>
                        </span>
                    </div>
                </ng-template>
                
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th style="min-width: 4rem">
                            Favourite
                        </th>

                        <th *ngIf="isColumnSelected('Id')" style="min-width: 8rem" pSortableColumn="Id">
                            <div class="flex justify-content-between align-items-center">
                                <div>
                                    ID
                                    <p-sortIcon field="Id"></p-sortIcon>
                                </div>
                                <p-columnFilter type="text" field="Id" display="menu" placeholder="Search by ID" (click)="$event.stopPropagation()"></p-columnFilter>
                            </div>
                        </th>

                        <th *ngIf="isColumnSelected('Name')" style="min-width: 12rem" pSortableColumn="Name">
                            <div class="flex justify-content-between align-items-center">
                                <div>
                                    Name
                                    <p-sortIcon field="Name"></p-sortIcon>
                                </div>
                                <p-columnFilter type="text" field="Name" display="menu" placeholder="Search by name" (click)="$event.stopPropagation()"></p-columnFilter>
                            </div>
                        </th>

                        <th *ngIf="isColumnSelected('ProductRef')" style="min-width: 12rem" pSortableColumn="ProductRef">
                            <div class="flex justify-content-between align-items-center">
                                <div>
                                    ProdRef
                                    <p-sortIcon field="ProductRef"></p-sortIcon>
                                </div>
                                <p-columnFilter type="text" field="ProductRef" display="menu" placeholder="Search by ref" (click)="$event.stopPropagation()"></p-columnFilter>
                            </div>
                        </th>

                        <th *ngIf="isColumnSelected('SuiteName')" style="min-width: 12rem" pSortableColumn="SuiteName">
                            <div class="flex justify-content-between align-items-center">
                                <div>
                                    Suite
                                    <p-sortIcon field="SuiteName"></p-sortIcon>
                                </div>
                                <p-columnFilter type="text" field="SuiteName" matchMode="equals" display="menu" placeholder="Search by suite" (click)="$event.stopPropagation()">
                                    <ng-template pTemplate="filter" let-value let-filter="filterCallback">
										<p-dropdown [ngModel]="value" [options]="suites" optionLabel="Name" optionValue="Name" (onChange)="filter($event.value)" placeholder="Any" [style]="{'min-width': '12rem'}" >
											<ng-template let-option pTemplate="item">
												<span [class]="'suite-badge suite-' + trimOption(option.Name)">{{option.Name}}</span>
											</ng-template>
										</p-dropdown>
									</ng-template>
                                </p-columnFilter>
                            </div>
                        </th>

                        <th *ngIf="isColumnSelected('DivisionName')" style="min-width: 12rem" pSortableColumn="DivisionName">
                            <div class="flex justify-content-between align-items-center">
                                <div>
                                    Division
                                    <p-sortIcon field="DivisionName"></p-sortIcon>
                                </div>
                                <p-columnFilter type="text" field="DivisionName" matchMode="equals" display="menu" placeholder="Search by division" (click)="$event.stopPropagation()">
                                    <ng-template pTemplate="filter" let-value let-filter="filterCallback">
										<p-dropdown [ngModel]="value" [options]="divisions" optionLabel="Name" optionValue="Name" (onChange)="filter($event.value)" placeholder="Any" [style]="{'min-width': '12rem'}" >
											<ng-template let-option pTemplate="item">
												<span [class]="'division-badge division-' + trimOption(option.Name)">{{option.Name}}</span>
											</ng-template>
										</p-dropdown>
									</ng-template>
                                </p-columnFilter>
                            </div>
                        </th>
                        <th *ngIf="isColumnSelected('ProductTypeName')" style="min-width: 13rem" pSortableColumn="ProductTypeName">
                            <div class="flex justify-content-between align-items-center">
                                <div>
                                    Product Type
                                    <p-sortIcon field="ProductTypeName"></p-sortIcon>
                                </div>
                                <p-columnFilter type="text" field="ProductTypeName" matchMode="equals" display="menu" placeholder="Search by Product Type" (click)="$event.stopPropagation()">
                                    <ng-template pTemplate="filter" let-value let-filter="filterCallback">
										<p-dropdown [ngModel]="value" [options]="productTypes" optionLabel="Name" optionValue="Name" (onChange)="filter($event.value)" placeholder="Any" [style]="{'min-width': '12rem'}" >
											<ng-template let-option pTemplate="item">
												<span [class]="'productType-badge productType-' + trimOption(option.Name)">{{option.Name}}</span>
											</ng-template>
										</p-dropdown>
									</ng-template>
                                </p-columnFilter>
                            </div>
                        </th>
                        <th *ngIf="isColumnSelected('OriginCompany')" style="min-width: 14rem" pSortableColumn="OriginCompany">
                            <div class="flex justify-content-between align-items-center">
                                <div>
                                    Origin Company
                                    <p-sortIcon field="OriginCompany"></p-sortIcon>
                                </div>
                                <p-columnFilter type="text" field="OriginCompany" display="menu" placeholder="Search by company" (click)="$event.stopPropagation()"></p-columnFilter>
                            </div>
                        </th>
                        <th *ngIf="isColumnSelected('ActiveStatus')" style="min-width: 10rem" pSortableColumn="ActiveStatus">
                            <div class="flex justify-content-between align-items-center">
                                <div>
                                    Active Status
                                    <p-sortIcon field="ActiveStatus"></p-sortIcon>
                                </div>
                                <p-columnFilter type="boolean" field="ActiveStatus" display="menu" (click)="$event.stopPropagation()"></p-columnFilter>
                            </div>
                        </th>
                        <th *ngIf="isColumnSelected('HomepageURI')" style="min-width: 14rem" pSortableColumn="HomepageURI">
                            <div class="flex justify-content-between align-items-center">
                                <div>
                                    Homepage URI
                                    <p-sortIcon field="HomepageURI"></p-sortIcon>
                                </div>
                                <p-columnFilter type="text" field="HomepageURI" display="menu" (click)="$event.stopPropagation()"></p-columnFilter>
                            </div>
                        </th>
                        <th *ngIf="isColumnSelected('EoLDate')" style="min-width: 12rem" pSortableColumn="EoLDate">
                            <div class="flex justify-content-between align-items-center">
                                <div>
                                    EoL Date
                                    <p-sortIcon field="EolDate"></p-sortIcon>
                                </div>
                                <p-columnFilter type="date" field="EoLDate" display="menu" placeholder="mm/dd/yyyy" (click)="$event.stopPropagation()"></p-columnFilter>
                            </div>
                        </th>
                        <th *ngIf="isColumnSelected('ProductMetric?.TierName')" style="min-width: 12rem" pSortableColumn="ProductMetric.TierName">
                            <div class="flex justify-content-between align-items-center">
                                <div>
                                    Tier
                                    <p-sortIcon field="ProductMetric.TierName"></p-sortIcon>
                                </div>
                                <p-columnFilter type="text" field="ProductMetric.TierName" matchMode="contains" display="menu" (click)="$event.stopPropagation()"></p-columnFilter>
                            </div>
                        </th>
                        <th *ngIf="isColumnSelected('ProductTechSpec?.TechSpecDistributions')" style="min-width: 12rem" pSortableColumn="ProductTechSpec.TechSpecDistributions">
                            <div class="flex justify-content-between align-items-center">
                                <div>
                                    Distributions
                                    <p-sortIcon field="ProductTechSpec.TechSpecDistributions"></p-sortIcon>
                                </div>
                                
                              <p-columnFilter
                              type="text"
                              field="distributions"
                              display="menu"
                              matchMode="contains"
                            />
                            </div>
                        </th>
                    </tr>
                </ng-template>
                
                <ng-template pTemplate="body" let-product>
                    <tr>
                        <td>
                            <i class="pi cursor-pointer text-2xl" [ngClass]="{'pi-star-fill text-yellow-500': isFavourite(product), 'pi-star': !isFavourite(product)}"
                               (click)="toggleFavourite(product)"></i>
                        </td>
                        <td *ngIf="isColumnSelected('Id')"><a [routerLink]="['/product', product.Id, 'dashboard']" (click)="onProductSelected(product)">{{product.Id}}</a></td>
                        <td *ngIf="isColumnSelected('Name')"><a [routerLink]="['/product', product.Id, 'dashboard']" (click)="onProductSelected(product)">{{product.Name}}</a></td>
                        <td *ngIf="isColumnSelected('ProductRef')"><a [routerLink]="['/product', product.Id, 'dashboard']" (click)="onProductSelected(product)">{{product.ProductRef}}</a></td>
                        <td *ngIf="isColumnSelected('SuiteName')" [routerLink]="['/suite', product.SuiteId, 'dashboard']">
                            <span [class]="'cursor-pointer suite-badge suite-' + trimOption(product.SuiteName)">{{product.SuiteName}}</span>
                        </td>
                        <td *ngIf="isColumnSelected('DivisionName')">
                            <span [class]="'division-badge division-' + trimOption(product.DivisionName)">{{product.DivisionName}}</span>
                        </td>
                        <td *ngIf="isColumnSelected('ProductTypeName')">{{product.ProductTypeName}}</td>
                        <td *ngIf="isColumnSelected('OriginCompany')">{{product.OriginCompany}}</td>
                        <td *ngIf="isColumnSelected('ActiveStatus')">
                            <i class="pi" [ngClass]="{'true-icon pi-check-circle text-green-500': product.ActiveStatus, 'false-icon pi-times-circle text-pink-500': !product.ActiveStatus}"></i>
                        </td>
                        <td *ngIf="isColumnSelected('HomepageURI')">
                            <a [href]="product.HomepageURI" target="_blank">{{product.HomepageURI}}</a>
                        </td>
                        <td *ngIf="isColumnSelected('EoLDate')">{{product.EoLDate | date: 'dd/MM/yyyy'}}</td>
                        <td *ngIf="isColumnSelected('ProductMetric?.TierName')">
                            <span [class]="'tier-badge tier-' + tagify(product.ProductMetric?.TierName)">{{product.ProductMetric?.TierName}}</span>
                        </td>
                        <td *ngIf="isColumnSelected('ProductTechSpec?.TechSpecDistributions')">{{ product.distributions?.join(', ') }}</td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="10">Loading product data. Please wait...</td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="loadingbody">
                    <tr>
                        <td colspan="10">Loading product data. Please wait...</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>

        

        <p-dialog [(visible)]="productDialog" [style]="{width: '600px'}" header="Add Product" [modal]="true" class="p-fluid">
            <ng-template pTemplate="content">

                <div class="field">
                    <label for="name">Name</label>
                    <input type="text" pInputText id="name" [(ngModel)]="product.Name" required autofocus [ngClass]="{'ng-invalid ng-dirty' : submitted && !product.Name}"/>
                    <small class="ng-dirty ng-invalid" *ngIf="submitted && !product.Name">Name is required.</small>
                </div>

                <div class="field">
                    <label for="divisions">Division</label>
                    <p-dropdown appendTo="body" [(ngModel)]="product.DivisionId" inputId="DivisionId" [options]="divisions" placeholder="Select" optionValue="Id">
                        <ng-template pTemplate="selectedItem">
                            <span *ngIf="product.DivisionId" [class]="'division-badge division-' + trimOption(getDivisionNameById(product.DivisionId))">{{ getDivisionNameById(product.DivisionId) }}</span>
                        </ng-template>
                        <ng-template let-option pTemplate="item">
                            <span [class]="'division-badge division-' + trimOption(option.Name)">{{option.Name}}</span>
                        </ng-template>
                    </p-dropdown>
                </div>

                <div class="field">
                    <label for="suites">Suite</label>
                    <p-dropdown appendTo="body" [(ngModel)]="product.SuiteId" inputId="SuiteId" [options]="suites" placeholder="Select" optionValue="Id">
                        <ng-template pTemplate="selectedItem">
                            <span *ngIf="product.SuiteId" [class]="'suite-badge suite-' + trimOption(getSuiteNameById(product.SuiteId))">{{ getSuiteNameById(product.SuiteId) }}</span>
                        </ng-template>
                        <ng-template let-option pTemplate="item">
                            <span [class]="'suite-badge suite-' + trimOption(option.Name)">{{option.Name}}</span>
                        </ng-template>
                    </p-dropdown>
                </div>

                <div class="field">
                    <label for="productRef">Product Ref</label>
                    <div class="p-inputgroup">
                        <input type="text" pInputText id="productRef" [(ngModel)]="product.ProductRef">
                        <span class="p-inputgroup-addon">P####</span>
                        <button pButton pRipple icon="pi pi-refresh" (click)="generateProductRef()" type="button"></button>
                    </div>
                </div>
        
                <div class="field">
                    <label for="description">Description</label>
                    <textarea id="description" pInputTextarea [(ngModel)]="product.Description" required rows="3" cols="20"></textarea>
                </div>

        
                <div class="field">
                    <label for="productTypes">Product Type</label>
                    <p-dropdown appendTo="body" [(ngModel)]="product.ProductTypeId" inputId="ProductTypeId" [options]="productTypes" placeholder="Select" optionValue="Id">
                        <ng-template pTemplate="selectedItem">
                            <span *ngIf="product.ProductTypeId" [class]="'productType-badge productType-' + trimOption(getProductTypeNameById(product.ProductTypeId))">{{ getProductTypeNameById(product.ProductTypeId) }}</span>
                        </ng-template>
                        <ng-template let-option pTemplate="item">
                            <span [class]="'productType-badge productType-' + trimOption(option.Name)">{{option.Name}}</span>
                        </ng-template>
                    </p-dropdown>
                </div>
        
                <div class="field">
                    <label class="block" for="activeStatus">Is Product Active?</label>
                    <p-toggleButton class="block" [(ngModel)]="product.ActiveStatus" onLabel="Yes" offLabel="No" [style]="{'width': '10em'}"></p-toggleButton>
                </div>
        
                <div class="field">
                    <label for="originCompany">Origin Company</label>
                    <input type="text" pInputText id="originCompany" [(ngModel)]="product.OriginCompany">
                </div>
        
                <div class="field">
                    <label for="homepageURI">Homepage URI</label>
                    <input type="url" pInputText id="homepageURI" [(ngModel)]="product.HomepageURI">
                </div>
        
                <div class="field">
                    <label for="eolDate">EoL Date</label>
                    <p-calendar [showIcon]="true" [(ngModel)]="product.EoLDate" id="eolDate" dateFormat="dd/mm/yy" ></p-calendar>
                </div>
            </ng-template>

            <ng-template pTemplate="footer">
                <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="hideDialog()"></button>
                <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text" (click)="saveProduct()"></button>
            </ng-template>
        </p-dialog>
    </div>
</div>

<!-- <h2>Favourite Products</h2>
<p-table [value]="favourites" dataKey="Id" [rows]="10" [rowHover]="true" styleClass="p-datatable-gridlines" [paginator]="true"
    [globalFilterFields]="cols" [showCurrentPageReport]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" responsiveLayout="scroll"
    sortMode="multiple" stateStorage="session" stateKey="favourite-products" [rowsPerPageOptions]="[20, 30, 40]">
    <ng-template pTemplate="header" let-columns>
        <tr>
            <th pSortableColumn="Id">
                ID
                <p-sortIcon field="Id"></p-sortIcon>
            </th>
            <th pSortableColumn="Name">
                Name
                <p-sortIcon field="Name"></p-sortIcon>
            </th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-product>
        <tr>
            <td>{{product.Id}}</td>
            <td>{{product.Name}}</td>
        </tr>
    </ng-template>
</p-table> -->