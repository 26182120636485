import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { MsalService } from '@azure/msal-angular';

@Directive({
  selector: '[appHasRole]'
})
export class HasRoleDirective {

  private currentUserRoles: string[] = [];

  constructor(private msalService: MsalService, private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef) {
    this.setCurrentUserRoles();
  }

  @Input() set appHasRole(roles: string[]) {
    this.viewContainer.clear();
    if (this.hasRole(roles)) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
  }

  private setCurrentUserRoles(): void {
    const activeAccount = this.msalService.instance.getAllAccounts()[0];
    if (activeAccount && activeAccount.idTokenClaims) {
      this.currentUserRoles = (activeAccount.idTokenClaims['roles'] as string[]) || (activeAccount.idTokenClaims['role'] as string[]) || [];
    }
  }

  private hasRole(roles: string[]): boolean {
    return roles.some(role => this.currentUserRoles.includes(role));
  }
}
