<h1>
  Vulnerability Report
  <a href="https://irissoftware.sharepoint.com/sites/EngineeringIntranet/SitePages/Weekly-Vulnerability-Report---User-Guide.aspx" target="_blank">
    <p-button icon="pi pi-question"></p-button>
  </a>
</h1>

<p-toast></p-toast>

<div *ngIf="isLoading" class="mb-5">
  <p-skeleton width="10%" height="1rem"></p-skeleton>
</div>

<div *ngIf="!isLoading">
  <div style="display: flex; flex-direction: column; margin-bottom: 20px;">
    <div style="display: flex; flex-wrap: wrap; gap: 5px; max-width: 100%;">
      <p-multiSelect [options]="tiers"
                     [(ngModel)]="selectedTiers"
                     placeholder="Select Tiers"
                     optionLabel="Name"
                     display="chip"
                     [showToggleAll]="false"
                     (onChange)="onTiersChange($event)">
      </p-multiSelect>
      <p-multiSelect [options]="divisions"
                     [(ngModel)]="selectedDivisions"
                     placeholder="Select Divisions"
                     optionLabel="Name"
                     display="chip"
                     [showToggleAll]="false"
                     (onChange)="onDivisionsChange($event)">
      </p-multiSelect>
      <p-multiSelect [options]="suites"
                     [(ngModel)]="selectedSuites"
                     placeholder="Select Suites"
                     optionLabel="Name"
                     display="chip"
                     [showToggleAll]="false"
                     (onChange)="onSuitesChange($event)">
      </p-multiSelect>
      <p-multiSelect [options]="products"
                     [(ngModel)]="selectedProducts"
                     placeholder="Select Products"
                     optionLabel="Name"
                     display="chip"
                     [showToggleAll]="false"
                     (onChange)="onProductsChange($event)">
      </p-multiSelect>
      <label for="timeframe" class="ml-5" style="display:flex; align-items:center">
        Trend vs.
      </label>
      <p-dropdown [options]="timeframeOptions"
                  [(ngModel)]="timeframe"
                  optionLabel="name"
                  inputId="timeframe"
                  optionDisabled="disabled"
                  placeholder="Trend vs." />
      <div class="flex flex-wrap gap-3 ml-5">
        <div class="flex align-items-center">
          <p-radioButton name="aged"
                         value="aged"
                         [(ngModel)]="aged"
                         inputId="aged1" />
          <label for="aged1" class="ml-2">
            Aged Only
          </label>
        </div>

        <div class="flex align-items-center">
          <p-radioButton name="aged"
                         value="total"
                         [(ngModel)]="aged"
                         inputId="aged2" />
          <label for="aged2" class="ml-2">
            All Vulnerabilities
          </label>
        </div>
      </div>
    </div>
  </div>
</div>

<button pButton pRipple label="Search" icon="pi pi-search" (click)="doSearch()" title="Search" class="mb-3"></button>


<app-vulnerability-tree [data]="reportBundle"></app-vulnerability-tree>


