import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { MsalService } from '@azure/msal-angular';

@Injectable({
    providedIn: 'root'
  })
  export class RoleGuard implements CanActivate {
  
    constructor(private authService: MsalService) {}
  
    canActivate(route: ActivatedRouteSnapshot): boolean {
      const expectedRole = route.data['expectedRole'];
      
      let activeAccount = this.authService.instance.getAllAccounts()[0];

      if (!activeAccount || !activeAccount.idTokenClaims || !activeAccount.idTokenClaims.roles) {
        window.alert('Token does not have roles claim. Please ensure that your account is assigned to an app role and then sign-out and sign-in again.');
        return false;
      } else if (!activeAccount.idTokenClaims.roles.includes(expectedRole)) {
        window.alert('You do not have access as expected role is missing. Please ensure that your account is assigned to an app role and then sign-out and sign-in again.');
        return false;
      }
      
      return true;
    }
  }
  