import { ChangeDetectorRef, Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { Message, MessageService, TreeNode } from 'primeng/api';
import { VulnerabilityService } from 'src/app/layout/service/vulnerability.service';
import { VulnerabilityReportBundle } from 'src/app/api/vulnerabilities'; 
import { TreeTable } from 'primeng/treetable';
import { type } from 'os';

interface Vulnerability {
  severity: 'critical' | 'high' | 'medium' | 'low';
  values: {
    current: number;
    previous: number;
  };
}

interface Tool {
  toolName: string;
  vulnerabilities: Vulnerability[];
}

interface Product {
  productTypeName: 'Product';
  objectId: number;
  objectName: string;
  code: string;
  suiteId: number;
  tierId: number;
  divisionId: number;
  children?: Tool[];
  vulnerabilities: Vulnerability[];
}

interface Suite {
  productTypeName: 'Suite';
  objectId: number;
  objectName: string;
  code: string;
  suiteId: number;
  tierId: number;
  divisionId: number;
  children?: Product[];
  vulnerabilities: Vulnerability[];
}

interface Tier {
  tierId: number;
  name: string;
  divisionId: number;
  children: Array<Suite | Product>;
  vulnerabilities: Vulnerability[];
}

interface Division {
  divisionId: number;
  name: string;
  children: Tier[];
  vulnerabilities: Vulnerability[];
}

interface VulnerabilityTotals {
  critical: { current: number; previous: number };
  high: { current: number; previous: number };
  medium: { current: number; previous: number };
  low: { current: number; previous: number };
}

interface TreeNodeData {
  label: string;
  objectType: string;
  objectId: string | number;
  parentType: string;
  parentId: string | number;
  vulnerabilities: VulnerabilityTotals;
}

@Component({
  selector: 'app-vulnerability-tree',
  template: `
    <div *ngIf="hasData">
    <div style="margin-bottom:5px; text-align:right">
      <button type="button" icon="pi pi-file-export" title="Export as CSV" class="p-element p-ripple p-button-success p-button p-component p-button-icon-only ml-7" (click)="buildCSV()">
        <span class="p-button-icon pi pi-file-export" aria-hidden="true"></span>
      </button>
    </div>
    <p-tabView [(activeIndex)]="tabIndex">
      <p-tabPanel header="Summary">
        <p-messages [(value)]="messages" [enableService]="false" [closable]="true"></p-messages>
        <h3>Total Vulnerabilities</h3>
        <p-table [value]="overallTable">
            <ng-template pTemplate="header">
                <tr>
                    <th class="sevCol">Critical</th>
                    <th class="sevCol">High</th>
                    <th class="sevCol">Medium</th>
                    <th class="sevCol">Low</th>
                    <th class="sevCol">CVSS > 7</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData>
                <tr>
                    <td style="background-color: var(--critical)" class="sevCell">
                    {{ rowData.critical.current | number:'1.0-0' || 0 }}
                      <span *ngIf="rowData.critical.current !== rowData.critical.previous"
                          class="pill"
                          [ngClass]="{'text-up': isTrendUp(rowData.critical), 'text-down': isTrendDown(rowData.critical)}">
                          {{ getTrendSymbol(rowData.critical) }}{{ getTrendValue(rowData.critical) }}
                    </span>
                    </td>
                    <td style="background-color: var(--high)" class="sevCell">
                    {{ rowData.high.current | number:'1.0-0' || 0 }}
                      <span *ngIf="rowData.high.current !== rowData.high.previous"
                          class="pill"
                          [ngClass]="{'text-up': isTrendUp(rowData.high), 'text-down': isTrendDown(rowData.high)}">
                          {{ getTrendSymbol(rowData.high) }}{{ getTrendValue(rowData.high) }}
                    </span>
                    </td>
                    <td style="background-color: var(--medium)" class="sevCell">
                    {{ rowData.medium.current | number:'1.0-0' || 0 }}
                      <span *ngIf="rowData.medium.current !== rowData.medium.previous"
                          class="pill"
                          [ngClass]="{'text-up': isTrendUp(rowData.medium), 'text-down': isTrendDown(rowData.medium)}">
                          {{ getTrendSymbol(rowData.medium) }}{{ getTrendValue(rowData.medium) }}
                    </span>
                    </td>
                    <td style="background-color: var(--low)" class="sevCell lowCell">
                    {{ rowData.low.current | number:'1.0-0' || 0 }}
                      <span *ngIf="rowData.low.current !== rowData.low.previous"
                          class="pill"
                          [ngClass]="{'text-up': isTrendUp(rowData.low), 'text-down': isTrendDown(rowData.low)}">
                          {{ getTrendSymbol(rowData.low) }}{{ getTrendValue(rowData.low) }}
                    </span>
                    </td>
                    <td class="sevCell cvssCell">
                    {{ rowData.cvss.current | number:'1.0-0' || 0 }}
                      <span *ngIf="rowData.cvss.current !== rowData.cvss.previous"
                          class="pill"
                          [ngClass]="{'text-up': isTrendUp(rowData.cvss), 'text-down': isTrendDown(rowData.cvss)}">
                          {{ getTrendSymbol(rowData.cvss) }}{{ getTrendValue(rowData.cvss) }}
                    </span>
                    </td>
                </tr>
            </ng-template>
        </p-table>
        <h3 class="mt-4">Vulnerabilities by Division</h3>
        <p-table [value]="treeTotal.divisions">
            <ng-template pTemplate="header">
                <tr>
                    <th class='totalLabelCol'></th>
                    <th class="sevCol">Critical</th>
                    <th class="sevCol">High</th>
                    <th class="sevCol">Medium</th>
                    <th class="sevCol">Low</th>
                    <th class="sevCol">CVSS > 7</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData>
              <tr>
                <td>{{ rowData.objectName }}</td>
                <td style="background-color: var(--critical)" class="sevCell">
                  <a [routerLink]="['/vulnerabilities', 'search']" title="View Vulnerabilities"
                       [queryParams]="getQueryParamsTotal('division', rowData.objectId, 'critical')"
                       class="no-decoration">
                  {{ getTreeTotalValue(rowData.vulnerabilities, 'critical', 'current') | number:'1.0-0'  || 0 }}
                  </a>
                  <span *ngIf="getTreeTotalValue(rowData.vulnerabilities, 'critical', 'current') !== getTreeTotalValue(rowData.vulnerabilities, 'critical', 'previous')"
                      class="pill"
                      [ngClass]="{'text-up': isTrendUpTotal(rowData.vulnerabilities, 'critical'), 'text-down': isTrendDownTotal(rowData.vulnerabilities, 'critical')}">
                      {{ getTrendSymbolTotal(rowData.vulnerabilities, 'critical') }}{{ getTrendValueTotal(rowData.vulnerabilities, 'critical') }}
                    </span>
                </td>
                <td style="background-color: var(--high)" class="sevCell">
                  <a [routerLink]="['/vulnerabilities', 'search']" title="View Vulnerabilities"
                       [queryParams]="getQueryParamsTotal('division', rowData.objectId, 'high')"
                       class="no-decoration">
                      {{ getTreeTotalValue(rowData.vulnerabilities, 'high', 'current') | number:'1.0-0'  || 0 }}
                  </a>
                  <span *ngIf="getTreeTotalValue(rowData.vulnerabilities, 'high', 'current') !== getTreeTotalValue(rowData.vulnerabilities, 'high', 'previous')"
                      class="pill"
                      [ngClass]="{'text-up': isTrendUpTotal(rowData.vulnerabilities, 'high'), 'text-down': isTrendDownTotal(rowData.vulnerabilities, 'high')}">
                      {{ getTrendSymbolTotal(rowData.vulnerabilities, 'high') }}{{ getTrendValueTotal(rowData.vulnerabilities, 'high') }}
                    </span>
                </td>
                <td style="background-color: var(--medium)" class="sevCell">
                  <a [routerLink]="['/vulnerabilities', 'search']" title="View Vulnerabilities"
                       [queryParams]="getQueryParamsTotal('division', rowData.objectId, 'medium')"
                       class="no-decoration">
                        {{ getTreeTotalValue(rowData.vulnerabilities, 'medium', 'current') | number:'1.0-0'  || 0 }}
                  </a>
                  <span *ngIf="getTreeTotalValue(rowData.vulnerabilities, 'medium', 'current') !== getTreeTotalValue(rowData.vulnerabilities, 'medium', 'previous')"
                      class="pill"
                      [ngClass]="{'text-up': isTrendUpTotal(rowData.vulnerabilities, 'medium'), 'text-down': isTrendDownTotal(rowData.vulnerabilities, 'medium')}">
                      {{ getTrendSymbolTotal(rowData.vulnerabilities, 'medium') }}{{ getTrendValueTotal(rowData.vulnerabilities, 'medium') }}
                    </span>
                </td>
                <td style="background-color: var(--low)" class="sevCell lowCell">
                  <a [routerLink]="['/vulnerabilities', 'search']" title="View Vulnerabilities"
                       [queryParams]="getQueryParamsTotal('division', rowData.objectId, 'low')"
                       class="no-decoration">
                      {{ getTreeTotalValue(rowData.vulnerabilities, 'low', 'current') | number:'1.0-0'  || 0 }}
                  </a>
                  <span *ngIf="getTreeTotalValue(rowData.vulnerabilities, 'low', 'current') !== getTreeTotalValue(rowData.vulnerabilities, 'low', 'previous')"
                      class="pill"
                      [ngClass]="{'text-up': isTrendUpTotal(rowData.vulnerabilities, 'low'), 'text-down': isTrendDownTotal(rowData.vulnerabilities, 'low')}">
                      {{ getTrendSymbolTotal(rowData.vulnerabilities, 'low') }}{{ getTrendValueTotal(rowData.vulnerabilities, 'low') }}
                    </span>
                </td>
                <td class="sevCell cvssCell">
                  <a [routerLink]="['/vulnerabilities', 'search']" title="View Vulnerabilities"
                       [queryParams]="getQueryParamsTotal('division', rowData.objectId, 'cvss')"
                       class="no-decoration">
                      {{ getTreeTotalValue(rowData.vulnerabilities, 'cvss', 'current') | number:'1.0-0'  || 0 }}
                  </a>
                  <span *ngIf="getTreeTotalValue(rowData.vulnerabilities, 'cvss', 'current') !== getTreeTotalValue(rowData.vulnerabilities, 'cvss', 'previous')"
                      class="pill"
                      [ngClass]="{'text-up': isTrendUpTotal(rowData.vulnerabilities, 'cvss'), 'text-down': isTrendDownTotal(rowData.vulnerabilities, 'cvss')}">
                      {{ getTrendSymbolTotal(rowData.vulnerabilities, 'cvss') }}{{ getTrendValueTotal(rowData.vulnerabilities, 'cvss') }}
                    </span>
                </td>
              </tr>
            </ng-template>
          </p-table>
        <h3 class="mt-4">Vulnerabilities by Tier</h3>
        <p-table [value]="treeTotal.tiers">
            <ng-template pTemplate="header">
                <tr>
                    <th class='totalLabelCol'></th>
                    <th class="sevCol">Critical</th>
                    <th class="sevCol">High</th>
                    <th class="sevCol">Medium</th>
                    <th class="sevCol">Low</th>
                    <th class="sevCol">CVSS > 7</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData>
              <tr>
                <td>{{ rowData.objectName }}</td>
                <td style="background-color: var(--critical)" class="sevCell">
                  <a [routerLink]="['/vulnerabilities', 'search']" title="View Vulnerabilities"
                       [queryParams]="getQueryParamsTotal('tier', rowData.objectId, 'critical')"
                       class="no-decoration">
                  {{ getTreeTotalValue(rowData.vulnerabilities, 'critical', 'current') | number:'1.0-0'  || 0 }}
                  </a>
                  <span *ngIf="getTreeTotalValue(rowData.vulnerabilities, 'critical', 'current') !== getTreeTotalValue(rowData.vulnerabilities, 'critical', 'previous')"
                      class="pill"
                      [ngClass]="{'text-up': isTrendUpTotal(rowData.vulnerabilities, 'critical'), 'text-down': isTrendDownTotal(rowData.vulnerabilities, 'critical')}">
                      {{ getTrendSymbolTotal(rowData.vulnerabilities, 'critical') }}{{ getTrendValueTotal(rowData.vulnerabilities, 'critical') }}
                    </span>
                </td>
                <td style="background-color: var(--high)" class="sevCell">
                  <a [routerLink]="['/vulnerabilities', 'search']" title="View Vulnerabilities"
                       [queryParams]="getQueryParamsTotal('tier', rowData.objectId, 'high')"
                       class="no-decoration">
                      {{ getTreeTotalValue(rowData.vulnerabilities, 'high', 'current') | number:'1.0-0'  || 0 }}
                  </a>
                  <span *ngIf="getTreeTotalValue(rowData.vulnerabilities, 'high', 'current') !== getTreeTotalValue(rowData.vulnerabilities, 'high', 'previous')"
                      class="pill"
                      [ngClass]="{'text-up': isTrendUpTotal(rowData.vulnerabilities, 'high'), 'text-down': isTrendDownTotal(rowData.vulnerabilities, 'high')}">
                      {{ getTrendSymbolTotal(rowData.vulnerabilities, 'high') }}{{ getTrendValueTotal(rowData.vulnerabilities, 'high') }}
                    </span>
                </td>
                <td style="background-color: var(--medium)" class="sevCell">
                  <a [routerLink]="['/vulnerabilities', 'search']" title="View Vulnerabilities"
                       [queryParams]="getQueryParamsTotal('tier', rowData.objectId, 'medium')"
                       class="no-decoration">
                        {{ getTreeTotalValue(rowData.vulnerabilities, 'medium', 'current') | number:'1.0-0'  || 0 }}
                  </a>
                  <span *ngIf="getTreeTotalValue(rowData.vulnerabilities, 'medium', 'current') !== getTreeTotalValue(rowData.vulnerabilities, 'medium', 'previous')"
                      class="pill"
                      [ngClass]="{'text-up': isTrendUpTotal(rowData.vulnerabilities, 'medium'), 'text-down': isTrendDownTotal(rowData.vulnerabilities, 'medium')}">
                      {{ getTrendSymbolTotal(rowData.vulnerabilities, 'medium') }}{{ getTrendValueTotal(rowData.vulnerabilities, 'medium') }}
                    </span>
                </td>
                <td style="background-color: var(--low)" class="sevCell lowCell">
                  <a [routerLink]="['/vulnerabilities', 'search']" title="View Vulnerabilities"
                       [queryParams]="getQueryParamsTotal('tier', rowData.objectId, 'low')"
                       class="no-decoration">
                      {{ getTreeTotalValue(rowData.vulnerabilities, 'low', 'current') | number:'1.0-0'  || 0 }}
                  </a>
                  <span *ngIf="getTreeTotalValue(rowData.vulnerabilities, 'low', 'current') !== getTreeTotalValue(rowData.vulnerabilities, 'low', 'previous')"
                      class="pill"
                      [ngClass]="{'text-up': isTrendUpTotal(rowData.vulnerabilities, 'low'), 'text-down': isTrendDownTotal(rowData.vulnerabilities, 'low')}">
                      {{ getTrendSymbolTotal(rowData.vulnerabilities, 'low') }}{{ getTrendValueTotal(rowData.vulnerabilities, 'low') }}
                    </span>
                </td>
                <td class="sevCell cvssCell">
                  <a [routerLink]="['/vulnerabilities', 'search']" title="View Vulnerabilities"
                       [queryParams]="getQueryParamsTotal('tier', rowData.objectId, 'cvss')"
                       class="no-decoration">
                      {{ getTreeTotalValue(rowData.vulnerabilities, 'cvss', 'current') | number:'1.0-0'  || 0 }}
                  </a>
                  <span *ngIf="getTreeTotalValue(rowData.vulnerabilities, 'cvss', 'current') !== getTreeTotalValue(rowData.vulnerabilities, 'cvss', 'previous')"
                      class="pill"
                      [ngClass]="{'text-up': isTrendUpTotal(rowData.vulnerabilities, 'cvss'), 'text-down': isTrendDownTotal(rowData.vulnerabilities, 'cvss')}">
                      {{ getTrendSymbolTotal(rowData.vulnerabilities, 'cvss') }}{{ getTrendValueTotal(rowData.vulnerabilities, 'cvss') }}
                    </span>
                </td>
              </tr>
            </ng-template>
          </p-table>
        <h3 class="mt-4">Vulnerabilities by Tool</h3>
        <p-table [value]="treeTotal.tools">
            <ng-template pTemplate="header">
                <tr>
                    <th class='totalLabelCol'></th>
                    <th class="sevCol">Critical</th>
                    <th class="sevCol">High</th>
                    <th class="sevCol">Medium</th>
                    <th class="sevCol">Low</th>
                    <th class="sevCol">CVSS > 7</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData>
              <tr>
                <td>
                  <div style="display: flex; align-items: center;">
                    <img src="../assets/layout/images/{{ getToolImage(rowData.objectId) }}" alt="{{ rowData.label }}" style="height: 12px; margin-right: 15px;">
                    {{ adjustToolName(rowData.objectName) }}
                  </div>
                </td>
                <td style="background-color: var(--critical)" class="sevCell">
                  <a [routerLink]="['/vulnerabilities', 'search']" title="View Vulnerabilities"
                       [queryParams]="getQueryParamsTotal('tool', rowData.objectId, 'critical')"
                       class="no-decoration">
                  {{ getTreeTotalValue(rowData.vulnerabilities, 'critical', 'current') | number:'1.0-0'  || 0 }}
                  </a>
                  <span *ngIf="getTreeTotalValue(rowData.vulnerabilities, 'critical', 'current') !== getTreeTotalValue(rowData.vulnerabilities, 'critical', 'previous')"
                      class="pill"
                      [ngClass]="{'text-up': isTrendUpTotal(rowData.vulnerabilities, 'critical'), 'text-down': isTrendDownTotal(rowData.vulnerabilities, 'critical')}">
                      {{ getTrendSymbolTotal(rowData.vulnerabilities, 'critical') }}{{ getTrendValueTotal(rowData.vulnerabilities, 'critical') }}
                    </span>
                </td>
                <td style="background-color: var(--high)" class="sevCell">
                  <a [routerLink]="['/vulnerabilities', 'search']" title="View Vulnerabilities"
                       [queryParams]="getQueryParamsTotal('tool', rowData.objectId, 'high')"
                       class="no-decoration">
                      {{ getTreeTotalValue(rowData.vulnerabilities, 'high', 'current') | number:'1.0-0'  || 0 }}
                  </a>
                  <span *ngIf="getTreeTotalValue(rowData.vulnerabilities, 'high', 'current') !== getTreeTotalValue(rowData.vulnerabilities, 'high', 'previous')"
                      class="pill"
                      [ngClass]="{'text-up': isTrendUpTotal(rowData.vulnerabilities, 'high'), 'text-down': isTrendDownTotal(rowData.vulnerabilities, 'high')}">
                      {{ getTrendSymbolTotal(rowData.vulnerabilities, 'high') }}{{ getTrendValueTotal(rowData.vulnerabilities, 'high') }}
                    </span>
                </td>
                <td style="background-color: var(--medium)" class="sevCell">
                  <a [routerLink]="['/vulnerabilities', 'search']" title="View Vulnerabilities"
                       [queryParams]="getQueryParamsTotal('tool', rowData.objectId, 'medium')"
                       class="no-decoration">
                        {{ getTreeTotalValue(rowData.vulnerabilities, 'medium', 'current') | number:'1.0-0'  || 0 }}
                  </a>
                  <span *ngIf="getTreeTotalValue(rowData.vulnerabilities, 'medium', 'current') !== getTreeTotalValue(rowData.vulnerabilities, 'medium', 'previous')"
                      class="pill"
                      [ngClass]="{'text-up': isTrendUpTotal(rowData.vulnerabilities, 'medium'), 'text-down': isTrendDownTotal(rowData.vulnerabilities, 'medium')}">
                      {{ getTrendSymbolTotal(rowData.vulnerabilities, 'medium') }}{{ getTrendValueTotal(rowData.vulnerabilities, 'medium') }}
                    </span>
                </td>
                <td style="background-color: var(--low)" class="sevCell lowCell">
                  <a [routerLink]="['/vulnerabilities', 'search']" title="View Vulnerabilities"
                       [queryParams]="getQueryParamsTotal('tool', rowData.objectId, 'low')"
                       class="no-decoration">
                      {{ getTreeTotalValue(rowData.vulnerabilities, 'low', 'current') | number:'1.0-0'  || 0 }}
                  </a>
                  <span *ngIf="getTreeTotalValue(rowData.vulnerabilities, 'low', 'current') !== getTreeTotalValue(rowData.vulnerabilities, 'low', 'previous')"
                      class="pill"
                      [ngClass]="{'text-up': isTrendUpTotal(rowData.vulnerabilities, 'low'), 'text-down': isTrendDownTotal(rowData.vulnerabilities, 'low')}">
                      {{ getTrendSymbolTotal(rowData.vulnerabilities, 'low') }}{{ getTrendValueTotal(rowData.vulnerabilities, 'low') }}
                    </span>
                </td>
                <td class="sevCell cvssCell">
                  <a [routerLink]="['/vulnerabilities', 'search']" title="View Vulnerabilities"
                       [queryParams]="getQueryParamsTotal('tool', rowData.objectId, 'cvss')"
                       class="no-decoration">
                      {{ getTreeTotalValue(rowData.vulnerabilities, 'cvss', 'current') | number:'1.0-0'  || 0 }}
                  </a>
                  <span *ngIf="getTreeTotalValue(rowData.vulnerabilities, 'cvss', 'current') !== getTreeTotalValue(rowData.vulnerabilities, 'cvss', 'previous')"
                      class="pill"
                      [ngClass]="{'text-up': isTrendUpTotal(rowData.vulnerabilities, 'cvss'), 'text-down': isTrendDownTotal(rowData.vulnerabilities, 'cvss')}">
                      {{ getTrendSymbolTotal(rowData.vulnerabilities, 'cvss') }}{{ getTrendValueTotal(rowData.vulnerabilities, 'cvss') }}
                    </span>
                </td>
              </tr>
            </ng-template>
          </p-table>
      </p-tabPanel>
      <p-tabPanel header="Product Tree">
        <div *ngIf="hasData">
          <p-treeTable #tt [value]="treeData">
            <ng-template pTemplate="header">
              <tr>
                <th class='labelCol'></th>
                <th class='sevCol'>Critical</th>
                <th class='sevCol'>High</th>
                <th class='sevCol'>Medium</th>
                <th class='sevCol'>Low</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowNode let-rowData="rowData">
              <tr [ttRow]="rowNode" [attr.id]="'row-' + rowData.key">
                <td [ngClass]="{ toolRow: rowData.objectType == 'Tool' }">
                  <div *ngIf="rowData.objectType == 'Tool'" style="display: flex; align-items: center;">
                    <p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
                    <img src="../assets/layout/images/{{ getToolImage(rowData.objectId) }}" alt="{{ rowData.label }}" style="height: 12px; margin-right: 15px;">
                    <a [routerLink]="['/vulnerabilities', 'search']" title="View Vulnerabilities"
                       [queryParams]="getQueryParams(rowData, '')"
                       class="no-decoration">
                      {{ rowData.label ?? '??' }}
                    </a>                    
                  </div>
                  <div *ngIf="rowData.objectType == 'Suite'" style="display: flex; align-items: center;">
                    <p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
                    <a [routerLink]="['/vulnerabilities', 'search']" title="View Vulnerabilities"
                       [queryParams]="getQueryParams(rowData, '')"
                       class="no-decoration">
                      {{ rowData.label ?? '??' }}
                    </a>
                    <span class="ml-5"></span>
                    <a [routerLink]="['/suite', rowData.objectId, 'dashboard']"
                       class="no-decoration">
                      <span title="Suite Details" style="display: inline-block; width: 20px; height: 20px; border-radius: 50%; background-color: #3B82F6; color: white; text-align: center; font-size: 14px; line-height: 20px;">
                        ?
                      </span>
                    </a>
                  </div>
                  <div *ngIf="rowData.objectType == 'Product'" style="display: flex; align-items: center;">
                    <p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
                    <a [routerLink]="['/vulnerabilities', 'search']" title="View Vulnerabilities"
                       [queryParams]="getQueryParams(rowData, '')"
                       class="no-decoration">
                      {{ rowData.label ?? '??' }}
                    </a>
                    <span class="ml-5"></span>
                    <a [routerLink]="['/product', rowData.objectId, 'dashboard']"
                       class="no-decoration">
                      <span title="Product Details" style="display: inline-block; width: 20px; height: 20px; border-radius: 50%; background-color: #3B82F6; color: white; text-align: center; font-size: 14px; line-height: 20px;">
                        ?
                      </span>
                    </a>
                  </div>
                  <div *ngIf="rowData.objectType != 'Tool' && rowData.objectType != 'Product' && rowData.objectType != 'Suite'" style="display: flex; align-items: center;">
                    <p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
                    <a [routerLink]="['/vulnerabilities', 'search']" title="View Vulnerabilities"
                       [queryParams]="getQueryParams(rowData, '')"
                       class="no-decoration">
                      {{ rowData.label ?? '??' }}
                    </a>
                  </div>
                </td>
                <td style='background-color:var(--critical)' class='sevCell'>
                  <a [routerLink]="['/vulnerabilities', 'search']"
                     [queryParams]="getQueryParams(rowData, 'critical')"
                     class="no-decoration">
                    {{ rowData.vulnerabilities?.critical?.current | number:'1.0-0' || 0 }}
                  </a>
                  <span *ngIf="rowData.vulnerabilities?.critical.current !== rowData.vulnerabilities?.critical.previous"
                        class="pill"
                        [ngClass]="{'text-up': isTrendUp(rowData.vulnerabilities?.critical), 'text-down': isTrendDown(rowData.vulnerabilities?.critical)}">
                    {{ getTrendSymbol(rowData.vulnerabilities?.critical) }}{{ getTrendValue(rowData.vulnerabilities?.critical) }}
                  </span>
                </td>
                <td style='background-color:var(--high)' class='sevCell'>
                  <a [routerLink]="['/vulnerabilities', 'search']" title="View Vulnerabilities"
                     [queryParams]="getQueryParams(rowData, 'high')"
                     class="no-decoration">
                    {{ rowData.vulnerabilities?.high?.current | number:'1.0-0'  || 0 }}
                  </a>
                  <span *ngIf="rowData.vulnerabilities?.high.current !== rowData.vulnerabilities?.high.previous"
                        class="pill"
                        [ngClass]="{'text-up': isTrendUp(rowData.vulnerabilities?.high), 'text-down': isTrendDown(rowData.vulnerabilities?.high)}">
                    {{ getTrendSymbol(rowData.vulnerabilities?.high) }}{{ getTrendValue(rowData.vulnerabilities?.high) }}
                  </span>
                </td>
                <td style='background-color:var(--medium)' class='sevCell'>
                  <a [routerLink]="['/vulnerabilities', 'search']" title="View Vulnerabilities"
                     [queryParams]="getQueryParams(rowData, 'medium')"
                     class="no-decoration">
                    {{ rowData.vulnerabilities?.medium?.current | number:'1.0-0'  || 0 }}
                  </a>
                  <span *ngIf="rowData.vulnerabilities?.medium.current !== rowData.vulnerabilities?.medium.previous"
                        class="pill"
                        [ngClass]="{'text-up': isTrendUp(rowData.vulnerabilities?.medium), 'text-down': isTrendDown(rowData.vulnerabilities?.medium)}">
                    {{ getTrendSymbol(rowData.vulnerabilities?.medium) }}{{ getTrendValue(rowData.vulnerabilities?.medium) }}
                  </span>
                </td>
                <td style='background-color:var(--low)' class='sevCell lowCell'>
                  <a [routerLink]="['/vulnerabilities', 'search']" title="View Vulnerabilities"
                     [queryParams]="getQueryParams(rowData, 'low')"
                     class="no-decoration">
                    {{ rowData.vulnerabilities?.low?.current | number:'1.0-0'  || 0 }}
                  </a>
                  <span *ngIf="rowData.vulnerabilities?.low.current !== rowData.vulnerabilities?.low.previous"
                        class="pill"
                        [ngClass]="{'text-up': isTrendUp(rowData.vulnerabilities?.low), 'text-down': isTrendDown(rowData.vulnerabilities?.low)}">
                    {{ getTrendSymbol(rowData.vulnerabilities?.low) }}{{ getTrendValue(rowData.vulnerabilities?.low) }}
                  </span>
                </td>
              </tr>
            </ng-template>
          </p-treeTable>
        </div>
      </p-tabPanel>
    </p-tabView>
    </div>
    <div *ngIf="isLoading">
      <p-skeleton width="10%" height="1rem"></p-skeleton>
      <p class="mt-5">Building report, please wait...</p>
   </div>
  `,
  styles: [
    `
      .labelCol {
        width: 60%;
        text-align: left;
      }

      .totalLabelCol {
        width: 40%;
        text-align: left;
      }

      .sevCol,
      .sevCell,
      th.centered {
        text-align: center !important;
      }

      .sevCell {
        font-weight: bold;
        color: white;
        font-size: 1em;
        text-align: center !important;
      }

      .cvssCell,
      .lowCell {
        color: black !important;
      }

      .toolRow {
        background-color: #f0f0f0;
      }

      .no-decoration {
        text-decoration:underline;
        text-decoration-style: dotted;
        color: inherit;
      }

      .pill {
        display: inline-block;
        padding: 0 8px;
        font-size: 0.8em;
        background-color: white;
        border-radius: 15px;
        font-weight: bold;
      }

      .text-up {
        color: red;
      }

      .text-down {
        color: green;
      }
    `
  ]
})


export class VulnerabilityTreeComponent implements OnInit {
  @Input() data: VulnerabilityReportBundle | undefined;
  treeData: TreeNode<TreeNodeData>[] = [];
  isLoading: boolean = false;
  hasData: boolean = false;
  parentMap: Map<string, string | null> = new Map();  // Map each node's key to its parent key
  expandKeys: string[] = [];
  treeTotal: any;
  overallTable: any[] = [];
  isAged: boolean = false;
  messages: Message[] = [];
  sourceTree: any[] = [];
  tabIndex: number = 1;

  constructor(private vulnerabilityService: VulnerabilityService, private messageService: MessageService, private cdr: ChangeDetectorRef) { }

  ngOnInit() {

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['data'] && changes['data'].currentValue !== changes['data'].previousValue) {
      this.fetchData();
    }
  }

  getInputValue(event: Event): string {
    const inputElement = event.target as HTMLInputElement; // Cast to HTMLInputElement
    return inputElement.value; // Now this is safe and won't give a null error
  }

  fetchData() {

    this.expandKeys = [];
    this.parentMap.clear();
    this.hasData = false;
    const filter = this.data?.filter || '';
    const agedOnly: boolean = this.data?.aged === 'aged';
    this.isAged = agedOnly;
    this.tabIndex = this.data?.tab || 0;
    const timeframe: number = 0 - (this.data?.timeframe || 7);
    this.messages = [];

    if (filter && filter.length > 0) {
      this.isLoading = true;
      this.vulnerabilityService.getVulnerabilityTree(agedOnly, filter, timeframe).subscribe((data: any) => {

        this.treeData = this.transformData(data.tree);
        this.sourceTree = data.tree;
        this.treeTotal = data.totals;

        this.overallTable = [
          {
            critical: {
              current: this.getOverallTotal('critical', 'current'),
              previous: this.getOverallTotal('critical', 'previous')
            },
            high: {
              current: this.getOverallTotal('high', 'current'),
              previous: this.getOverallTotal('high', 'previous')
            },
            medium: {
              current: this.getOverallTotal('medium', 'current'),
              previous: this.getOverallTotal('medium', 'previous')
            },
            low: {
              current: this.getOverallTotal('low', 'current'),
              previous: this.getOverallTotal('low', 'previous')
            },
            cvss: {
              current: this.getOverallTotal('cvss', 'current'),
              previous: this.getOverallTotal('cvss', 'previous')
            }
          }
        ];

        this.buildParentMap(this.treeData);

        this.expandKeys.forEach(key => {
          this.revealNode(key);
          this.expandNodeByKey(this.treeData, key);
        });

        if (this.isFiltered()) {
          this.messages.push({ severity: 'info', summary: 'Filtered Report', detail: 'The totals in this summary are filtered.' });
        }

        this.isLoading = false;
        this.hasData = true;
      });
    }
  }

  buildParentMap(nodes: TreeNode[], parentKey: string | null = null) {
    nodes.forEach(node => {
      this.parentMap.set(node.key ?? '', parentKey);  // Associate each node with its parent
      if (node.children) {
        this.buildParentMap(node.children, node.key);  // Recurse into children
      }
    });
  }

  getItemLabel(item: Division | Tier | Suite | Product | Tool): string {

    if (this.isDivision(item)) return (item as Division).name || '';
    if (this.isTier(item)) return (item as Tier).name || '';
    if (this.isTool(item)) return this.adjustToolName((item as Tool).toolName) || '';
    if (this.isProduct(item)) return ((item as Product).objectName || '') + ' [' + ((item as Product).code || '') + ']';
    if (this.isSuite(item)) return ((item as Suite).objectName || '') + ' [' + ((item as Suite).code || '') + ']';

    return '??';

  }

  adjustToolName(toolName: string): string {
    if (toolName === 'SharePoint') return 'Internal Pen Testing';
    if (toolName === 'Lens') return 'External Pen Testing';
    if (toolName === 'Sonarqube (Enterprise)') return 'Sonarqube';
    return toolName;
  }

  transformData(data: Division[]): TreeNode<TreeNodeData>[] {
    return data.map((division: Division) => this.createNode(division, 'Division', null));
  }

  isTool(item: any): item is Tool {
    return 'toolName' in item && 'vulnerabilities' in item;
  }

  isProduct(item: any): item is Product {
    return item.productTypeName === 'Product';
  }

  isSuite(item: any): item is Suite {
    return item.productTypeName === 'Suite';
  }

  isDivision(item: any): item is Division {
    return 'divisionId' in item && !('productTypeName' in item) && !('tierId' in item);
  }

  isTier(item: any): item is Tier {
    return 'tierId' in item && !('productTypeName' in item);
  }

  getQueryParams(node: any, severity: string): any {
    const params = {
      toolName: node?.objectType == 'Tool' ? node?.objectId : '',
      divisions: node?.objectType == 'Division' ? node?.objectId : '',
      tiers: node?.objectType == 'Tier' ? node?.objectId : '',
      suites: node?.objectType == 'Suite' ? node?.objectId : '',
      vulnerabilityTypes: 'Vulnerability',
      products: node?.objectType == 'Product' ? node?.objectId : '',
      severities: (severity == '' ? '' : this.getRankedSeverity(severity)),
      ages: this.isAged ? '1' : '',
      activeOnly: true

    };

    if (node.objectType == 'Tool') {
      if (node.parentType == 'Product') {
        params['products'] = node.parentId;
      }
      if (node.parentType == 'Suite') {
        params['suites'] = node.parentId;
      }
    }

    if (node.objectType == 'Tier') {
      if (node.parentType == 'Division') {
        params['divisions'] = node.parentId;
      }
    }

    return params;
  };


  getQueryParamsTotal(type: string, id: string | number, severity: string): any {
    const params = {
      toolName: (type == 'tool' ? id : ''),
      divisions: (type == 'division' ? id : ''),
      tiers: (type == 'tier' ? id : ''),
      suites: '',
      vulnerabilityTypes: 'Vulnerability',
      products: '',
      severities: (severity == '' ? '' : this.getRankedSeverity(severity)),
      ages: this.isAged ? '1' : '',
      activeOnly: true
    };

    return params;
  };


  getRankedSeverity(sev: string): string {

    if (sev == 'cvss') return '0,1';

    if (sev === 'critical') {
      return '0';
    } else if (sev === 'high') {
      return '1';
    } else if (sev === 'medium') {
      return '2';
    } else {
      return '3';
    }
  }

  getObjectId(item: Division | Tier | Suite | Product | Tool | null, type: string): any {
    if (item === null) return '';
    if (type == "Division") return (item as Division).divisionId;
    if (type == "Tier") return (item as Tier).tierId;
    if (type == "Suite") return (item as Suite).objectId;
    if (type == "Product") return (item as Product).objectId;
    if (type == "Tool") return (item as Tool).toolName;
    return '';
  };

  getParentType(item: Division | Tier | Suite | Product | Tool | null): string {
    if (item === null) return '';
    if (this.isTier(item)) return 'Tier';
    if (this.isSuite(item)) return 'Suite';
    if (this.isProduct(item)) return 'Product';
    if (this.isTool(item)) return 'Tool';
    return 'Division';
  }

  getToolImage(objectId: string): string {

    switch (objectId) {
      case "Snyk": return "badgelogos/snyk.png";
      case "Sonarqube (Enterprise)": return "badgelogos/sonarqube.png";
      case "Nessus": return "badgelogos/nessus.png";
      case "Tenable WAS": return "badgelogos/tenable.webp";
      case "SharePoint": return "iris-logo.png";
      case "Lens": return "iris-logo.png";
      default: return "iris-logo.png";
    }
  }

  createNode(item: Division | Tier | Suite | Product | Tool, type: string, parent: Division | Tier | Suite | Product | Tool | null): TreeNode<TreeNodeData> {
    const node: TreeNode<TreeNodeData> = {
      key: type + "-" + this.getObjectId(item, type),
      data: {
        vulnerabilities: this.extractVulnerabilities(item.vulnerabilities),
        label: this.getItemLabel(item),
        objectType: type,
        objectId: this.getObjectId(item, type),
        parentType: this.getParentType(parent),
        parentId: this.getObjectId(parent, this.getParentType(parent))
      },
      children: []
    };

    if ('children' in item && item.children) {
      node.children = item.children.map(child => {
        if (this.isTier(child)) return this.createNode(child, 'Tier', item);
        if (this.isSuite(child)) return this.createNode(child, 'Suite', item);
        if (this.isProduct(child)) return this.createNode(child, 'Product', item);
        if (this.isTool(child)) return this.createNode(child, 'Tool', item);
        return null;
      }).filter(child => child !== null) as TreeNode<TreeNodeData>[];
    }

    // If tools exist, add them to node.children as well
    if ('tools' in item && item.tools) {
      const toolNodes = (item.tools as Tool[]).map(tool => {
        if (this.isTool(tool)) return this.createNode(tool, 'Tool', item);
        return null;
      }).filter(tool => tool !== null) as TreeNode<TreeNodeData>[];

      // Append toolNodes to existing children
      node.children = [...(node.children || []), ...toolNodes];
    }

    if (this.isInFilter(node)) {
      this.expandKeys.push(node.key || '');
    }

    return node;
  }

  isFiltered(): boolean {

    if (this.data?.filter && this.data?.filter.length > 0) {
      const filter = this.data?.filter.toLowerCase();

      //does the filter contain any of: lenssuiteid, lensObjectId, divisionId, tierId
      if (filter.includes('lenssuiteid') || filter.includes('lensobjectid') || filter.includes('lensdivisionid') || filter.includes('lenstierid')) {
        return true;
      }
    }
    return false;
  }

  isInFilter(node: TreeNode<TreeNodeData>): boolean {

    if (this.data?.filter && this.data?.filter.length > 0) {
      const filter = this.data?.filter.toLowerCase();

      //split the oData filter string into an array of strings
      const filterArray = filter.split(' and ');
      //loop through the array of strings
      for (let i = 0; i < filterArray.length; i++) {
        //split the string into an array of strings
        const filterItem = filterArray[i].split(' in ');
        //if the first part of the array 'suites'
        if (filterItem[0] === 'lenssuiteid' || filterItem[0] === 'lensObjectId') {

          //remove opening and closing brackets
          filterItem[1] = filterItem[1].replace('(', '');
          filterItem[1] = filterItem[1].replace(')', '');

          const suiteArray = filterItem[1].split(',');
          //loop through the array of strings
          for (let j = 0; j < suiteArray.length; j++) {
            //if the suiteId matches the suiteId in the filter

            if (node.data?.objectId.toString() == suiteArray[j].toString()) {
              return true;
            }
          }
        }
      }
    }
    return false;
  }

  extractVulnerabilities(vulns: Vulnerability[]): VulnerabilityTotals {
    const vulnerabilities: VulnerabilityTotals = {
      critical: { current: 0, previous: 0 },
      high: { current: 0, previous: 0 },
      medium: { current: 0, previous: 0 },
      low: { current: 0, previous: 0 }
    };

    vulns.forEach(vuln => {
      vulnerabilities[vuln.severity] = vuln.values;
    });

    return vulnerabilities;
  }

  getTrendClass(vuln: { current: number; previous: number }): string {
    if (vuln.current > vuln.previous) {
      return 'up';
    } else if (vuln.current < vuln.previous) {
      return 'down'
    } else {
      return '';
    }
  }

  getTrendSymbol(vuln: { current: number; previous: number }): string {
    if (vuln.current > vuln.previous) {
      return '▲';
    } else if (vuln.current < vuln.previous) {
      return '▼'
    } else {
      return '';
    }
  }

  isTrendUp(vulnerability: any) {
    // Implement logic to check if the trend is "up"
    return this.getTrendSymbol(vulnerability) === '▲';
  }

  isTrendDown(vulnerability: any) {
    // Implement logic to check if the trend is "down"
    return this.getTrendSymbol(vulnerability) === '▼';
  }

  getTrendValue(vuln: any): number | string {
    if (vuln.current == vuln.previous) {
      return ''
    } else {
      return Math.abs(vuln.current - vuln.previous);
    }
  }


  getTrendClassTotal(vuln: any[], sev: string): string {

    let current = 0;
    let previous = 0;

    //loop through the vuln array
    for (let i = 0; i < vuln.length; i++) {
      //if the key matches the severity
      if (vuln[i].severity === sev || sev == 'cvss' && (vuln[i].severity == "critical" || vuln[i].severity == "high")) {
        current += vuln[i].values.current;
        previous += vuln[i].values.previous
      }
    }

    if (current > previous) {
      return 'up';
    } else if (current < previous) {
      return 'down'
    } else {
      return '';
    }
  }

  getTrendSymbolTotal(vuln: any[], sev: string): string {

    let current = 0;
    let previous = 0;

    //loop through the vuln array
    for (let i = 0; i < vuln.length; i++) {
      //if the key matches the severity
      if (vuln[i].severity === sev || sev == 'cvss' && (vuln[i].severity == "critical" || vuln[i].severity == "high")) {
        current += vuln[i].values.current;
        previous += vuln[i].values.previous
      }
    }

    if (current > previous) {
      return '▲';
    } else if (current < previous) {
      return '▼'
    } else {
      return '';
    }
  }

  isTrendUpTotal(vulnerability: any[], sev: string) {
    // Implement logic to check if the trend is "up"
    return this.getTrendSymbolTotal(vulnerability, sev) === '▲';
  }

  isTrendDownTotal(vulnerability: any[], sev: string) {
    // Implement logic to check if the trend is "down"
    return this.getTrendSymbolTotal(vulnerability, sev) === '▼';
  }

  getTrendValueTotal(vuln: any[], sev: string): number | string {

    let current = 0;
    let previous = 0;

    //loop through the vuln array
    for (let i = 0; i < vuln.length; i++) {
      //if the key matches the severity
      if (vuln[i].severity === sev || sev == 'cvss' && (vuln[i].severity == "critical" || vuln[i].severity == "high")) {
        current += vuln[i].values.current;
        previous += vuln[i].values.previous
      }
    }

    if (current == previous) {
      return ''
    } else {
      return Math.abs(current - previous);
    }
  }

  expandToNode(nodeKey: string) {
    let currentKey = nodeKey;

    while (currentKey) {
      const parentKey = this.parentMap.get(currentKey);
      if (parentKey) {
        this.expandNodeByKey(this.treeData, parentKey);  // Expand the parent node
      }
      currentKey = parentKey ?? '';  // Move up to the parent
    }
  }

  expandNodeByKey(nodes: TreeNode[], key: string) {
    for (let node of nodes) {
      if (node.key === key) {
        node.expanded = true;  // Expand the node directly
        return;
      }
      if (node.children) {
        this.expandNodeByKey(node.children, key);  // Recurse into children
      }
    }
  }

  scrollToNode(nodeKey: string) {
    const rowElement = document.getElementById(`row-${nodeKey}`);
    if (rowElement) {
      rowElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }

  revealNode(nodeKey: string) {
    this.expandToNode(nodeKey);  // Expand parent nodes up to the target node
    setTimeout(() => {
      this.scrollToNode(nodeKey);  // Scroll to the target node after expansion
    }, 300);  // Adjust timeout as needed to ensure expansion has occurred
  }

  getOverallTotal(sev: string, type: string): number {

    if (this.treeTotal) {

      let total = 0;

      //loop through the treeTotal overall object
      for (let i = 0; i < this.treeTotal.overall.length; i++) {
        //if the key matches the severity
        if (this.treeTotal.overall[i].severity === sev || sev == 'cvss' && (this.treeTotal.overall[i].severity == "critical" || this.treeTotal.overall[i].severity == "high")) {

          if (type == 'current') {
            total += this.treeTotal.overall[i].values.current;
          }
          if (type == 'previous') {
            total += this.treeTotal.overall[i].values.previous;
          }

        }
      }
      return total;
    }
    return 0;
  }

  getTreeTotalValue(vulns: any[], sev: string, type: string): number {

    let total = 0;

    for (let i = 0; i < vulns.length; i++) {
      if (vulns[i].severity === sev || sev == 'cvss' && (vulns[i].severity == "critical" || vulns[i].severity == "high")) {
        if (type == 'current') {
          total += vulns[i].values.current;
        }
        if (type == 'previous') {
          total += vulns[i].values.previous;
        }
      }
    }

    return total;
  }

  globalSearch(event: Event) {

    const inputValue = (event.target as HTMLInputElement)?.value || '';
    const value = inputValue.trim();

    if (value.length < 3) {
      return;
    }

    this.traverseNodes(this.treeData, value.toLowerCase());
    this.cdr.detectChanges();
  }

  traverseNodes(nodes: TreeNode[], searchValue: string): void {
    for (let node of nodes) {
      // Check if node's label matches the search value
      const nodeLabel = node.data.label?.toLowerCase() || '';
      if (nodeLabel.includes(searchValue)) {
        this.revealNode(node.key ?? '');  // Call revealNode for matching nodes        
        return;
      }

      // Recursively traverse child nodes
      if (node.children && node.children.length) {
        this.traverseNodes(node.children, searchValue);
      }
    }
  }

  buildCSV() {

    const csvData = [];
    const csvHeader = ['divsion', 'tier', 'suite', 'product', 'tool', 'severity', 'time', 'value'];
    csvData.push(csvHeader);

    this.sourceTree.forEach(division => {
      division.children.forEach((tier: any) => {
        tier.children.forEach((suite: any) => {
          suite.children.forEach((product: any) => {            
            product.tools.forEach((tool: any) => {
              tool.vulnerabilities.forEach((vuln: any) => {          
                csvData.push([division.name, tier.name, suite.objectName, product.objectName, tool.toolName, vuln.severity, 'current', vuln.values.current]);
                csvData.push([division.name, tier.name, suite.objectName, product.objectName, tool.toolName, vuln.severity, 'previous', vuln.values.previous]);
              });
            });
          });
          suite.tools.forEach((tool: any) => {
            tool.vulnerabilities.forEach((vuln: any) => {
              if (suite.productTypeName == 'Suite') {
                csvData.push([division.name, tier.name, suite.objectName, '', tool.toolName, vuln.severity, 'current', vuln.values.current]);
                csvData.push([division.name, tier.name, suite.objectName, '', tool.toolName, vuln.severity, 'previous', vuln.values.previous]);
              }
              if (suite.productTypeName == 'Product') {
                csvData.push([division.name, tier.name, '', suite.objectName, tool.toolName, vuln.severity, 'current', vuln.values.current]);
                csvData.push([division.name, tier.name, '', suite.objectName, tool.toolName, vuln.severity, 'previous', vuln.values.previous]);
              }
            });
          });
        });
      });
    });

    let csvContent = 'data:text/csv;charset=utf-8,';
    csvData.forEach((row) => {
      csvContent += row.join(',') + '\r\n';
    });

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'vulnerabilities.csv');
    document.body.appendChild(link);
    link.click();

  }
}
