import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConfirmationService, Message, MessageService } from 'primeng/api';
import { EmailRequestDto, EmailRequestNewDto } from 'src/app/api/email-request';
import { Product, User } from 'src/app/api/product';
import { ProductSDLC, ProductSDLCPutDto, ProductSDLCRequestDto, SDLCPrimaryContact } from 'src/app/api/product-sdlc';
import { EmailTemplateService } from 'src/app/layout/service/email/email-template.service';
import { GraphExplorerService } from 'src/app/layout/service/graphexplorer/graph-explorer.service';
import { LogService } from 'src/app/layout/service/log.service';
import { EmailService } from 'src/app/layout/service/product/email.service';
import { ProductSDLCService } from 'src/app/layout/service/product/product-sdlc.service';
import { ProductService } from 'src/app/layout/service/product/products.service';
import { ToEmailService } from 'src/app/layout/service/product/to-email.service';
import { ProductSelectionService } from 'src/app/product.selection.service';

const GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/me';

type ProfileType = {
  displayName?: string,
  surname?: string,
  userPrincipalName?: string,
  id?: string,
  mail?: string
};

@Component({
  selector: 'app-npdast',
  templateUrl: './npdast.component.html'
})
export class NPDASTComponent implements OnInit {

  productId: string = '';
  productName: string = '';
  productReference: string = '';

  isLoading = true;
  
  tier: string = '';
  selectedProduct: Product | null = null;

  productSDLCNPDAST: ProductSDLC[] = [];
  editedProductSDLC: ProductSDLCPutDto = {};
  productNPDAST: ProductSDLCRequestDto = {};

  filteredProductSDLCNPDAST: ProductSDLC[] = [];

  productSDLCTools: any[] = [];
  productSDLCRequestStatuses: any[] = [];
  productSDLCPracticeStatuses: any[] = [];
  productSDLCScanTypes: any[] = [];
  productSDLCEnvironments: any[] = [];

  hasSelectedOption: boolean = false;
  selectedNPDastId: number | undefined;
  selectedNPDastOption: ProductSDLCPutDto = {};

  status: any[] = [];
  selectedStatus: string | null = null;

  submitted: boolean = false;
  npdastDialog: boolean = false;

  profile?: ProfileType;

  npdastSDLCPracticeId: number | undefined;

  messages: Message[] = [];
  messages2: Message[] = [];
  messages3: Message[] = [];
  messages4: Message[] = [];

  hasApprovedNPDASTMessage: boolean = false;

  userDialog: boolean = false;
  users: User[] = [];
  sdlcPrimaryContacts: SDLCPrimaryContact[] = [];

  selectedUser: User | null = null;

  sdlcNewNpDastContacts: User[] = [];
  userDialogAction: string = '';

  npDastBefore: ProductSDLC = {};
  npDastAfter: ProductSDLC = {};

  emailCreation: EmailRequestDto = {};

  constructor(private route: ActivatedRoute, private productSDLCService: ProductSDLCService, private messageService: MessageService,
    private productSelectionService: ProductSelectionService, private productService: ProductService, private emailService: EmailService,
    private graphExplorerService: GraphExplorerService, private http: HttpClient,
    private confirmationService: ConfirmationService, private toEmailService: ToEmailService, private logService: LogService,
    private emailTemplateService: EmailTemplateService) { }

    ngOnInit() {
      this.route.params.subscribe(params => {
        this.productId = params['id'];
  
        this.getProfile();

        this.productSelectionService.getSelectedProduct().subscribe((product) => {
          this.productName = product?.Name || '';
          this.productReference = product?.ProductRef || '';
        });

        this.fetchNPDASTData();
      });
  
      this.productSDLCService.getAllSDLCTools("NP-DAST").subscribe((sdlcTools) => {
        this.productSDLCTools = sdlcTools;
      });
  
      this.productSDLCService.getAllSDLCRequestStatuses().subscribe((sdlcRequestStatus) => {
        this.productSDLCRequestStatuses = sdlcRequestStatus;
      });

      this.productSDLCService.getAllSDLCNPDASTScanTypes().subscribe((sdlcScanTypes) => {
        this.productSDLCScanTypes = sdlcScanTypes;
      });

      this.productSDLCService.getAllSDLCEnvironments().subscribe((sdlcEnvironments) => {
        this.productSDLCEnvironments = sdlcEnvironments;
      });
  
      this.productSDLCService.getSDLCPractice("NP-DAST").subscribe((sdlcPractice) => {
        if(sdlcPractice.length > 0){
          this.npdastSDLCPracticeId = sdlcPractice[0].Id;
        }

        this.productSDLCService.getMaturityLevelByPracticeId(this.npdastSDLCPracticeId).subscribe((sdlcPracticeStatus) => {
          this.productSDLCPracticeStatuses = sdlcPracticeStatus;
          this.sortOptions();
        });
      });
  
      this.productService.getProductById(this.productId).subscribe((product) => {
        this.selectedProduct = product;
        this.tier = this.selectedProduct?.ProductMetric?.TierName || '';
        this.isLoading = false;
      });

      this.getAllUsers();
  
      this.messages = [{ severity: 'info', summary: 'Information', detail: 'Select NP-DAST from the menu to view' }];
      this.messages2 = [{ severity: 'warn', summary: 'Warning', detail: 'Currently not following the Np-DAST Practice! Please request or wait for approval.' }];
    }

    sortOptions() {
      this.productSDLCPracticeStatuses.sort((a, b) => a.Order - b.Order);
    }

    getAllUsers() {
      this.graphExplorerService.getAllADUsers().subscribe((users) => {
        this.users = users;
      });
    }

    getProfile() {
      this.http.get(GRAPH_ENDPOINT)
        .subscribe(profile => {
          this.profile = profile;
        });
    }

    searchUsers(event?: any) {
      if (event.filter) {
        this.graphExplorerService.searchADUsers(event.filter).subscribe((users) => {
          this.users = users;
        });
      } else {
        this.getAllUsers();
      }
    }

    getPracticeStatusNameById(id: string): string {
      const status = this.productSDLCPracticeStatuses.find(status => status.Id === id);
      return status ? status.Name : '';
    }
  
    getToolNameById(toolId: string | undefined): string {
      const selectedTool = this.productSDLCTools.find(tool => tool.Id === toolId);
      return selectedTool ? selectedTool.Name : '';
    }

    getScanTypeNameById(scanTypeId: number | undefined): string {
      const selectedScanType = this.productSDLCScanTypes.find(scanType => scanType.Id === scanTypeId);
      return selectedScanType ? selectedScanType.Name : '';
    }

    getEnvironmentNameById(environmentId: number): string {
      const selectedEnvironment = this.productSDLCEnvironments.find(environment => environment.Id === environmentId);
      return selectedEnvironment ? selectedEnvironment.Name : '';
    }

    isTenableWASSelected = false;

    onToolChange(event: any) {
      const toolName = this.getToolNameById(this.productNPDAST.SDLCToolId);
      this.isTenableWASSelected = toolName === 'Tenable WAS';
      if (this.isTenableWASSelected) {
        this.productNPDAST.SDLCNPDastScanTypeId = 1; // Set to 'Unauthenticated'
        this.messages3 = [];
      } else {
        this.productNPDAST.SDLCNPDastScanTypeId = undefined;
      }
    }

    showNPDastDetails(selectedNPDast: ProductSDLC) {
      // Set the selected DAST data to be displayed in the second splitter form
      this.selectedNPDastOption = selectedNPDast;
      this.hasSelectedOption = true;
      this.selectedNPDastOption.EnrolDate = this.selectedNPDastOption.EnrolDate ? new Date(this.selectedNPDastOption.EnrolDate) : undefined;
      this.selectedNPDastOption.NextReviewDate = this.selectedNPDastOption.NextReviewDate ? new Date(this.selectedNPDastOption.NextReviewDate) : undefined;
      
      this.selectedNPDastId = selectedNPDast.Id;
      this.editedProductSDLC = {...this.selectedNPDastOption};
      
      this.npDastBefore = selectedNPDast;

      this.fetchProductSDLCContacts();
    }

    fetchProductSDLCContacts() {
      if (this.selectedNPDastId) {
        this.productSDLCService.getSDLCPrimaryContacts(this.selectedNPDastId).subscribe((sdlcPrimaryContacts) => {
          this.sdlcPrimaryContacts = sdlcPrimaryContacts;
          // I need this.sdlcPrimaryContacts to only contain the 
        });
      }
    }

    fetchNPDASTData(shouldReset: boolean = true, setSelectedStatus?: string | null) {
      this.productSDLCService.getProductSDLCNPDAST(this.productId).subscribe((sdlcNPDAST) => {
        if (sdlcNPDAST && sdlcNPDAST.value.length > 0){
          this.productSDLCNPDAST = sdlcNPDAST.value;
          this.filteredProductSDLCNPDAST = this.productSDLCNPDAST.sort((a, b) => (b.Id || 0) - (a.Id || 0));
  
          const hasApprovedNPDAST = this.productSDLCNPDAST.some(npdast => npdast.SDLCRequestStatusName === 'Approved');
  
          if (setSelectedStatus) {
            this.selectedStatus = this.selectedStatus;
            this.onStatusChange(shouldReset);
          }

          if (hasApprovedNPDAST && !setSelectedStatus) {
            this.selectedStatus = 'Approved';
            this.hasApprovedNPDASTMessage = true;
            this.onStatusChange(shouldReset);
          } else {
            this.selectedStatus = 'Requested';
            this.onStatusChange(shouldReset);
          }
        }

        this.showNPDastDetails(this.filteredProductSDLCNPDAST[0]);
      });
      this.selectedStatus = 'Requested';
    }

    openNew() {
      this.productNPDAST = {};
      this.submitted = false;
      this.npdastDialog = true;
    }
  
    hideDialog() {
      this.npdastDialog = false;
      this.submitted = false;
      this.sdlcNewNpDastContacts = [];
    }

    openUserDialog(action: string) {
      this.userDialogAction = action;
      this.userDialog = true;
    }
  
    hideUserDialog() {
      this.userDialog = false;
      this.selectedUser = null;
    }
  
    saveNewNPDAST() {
      this.submitted = true;
  
      if (this.productNPDAST.SDLCToolId) {
        this.productNPDAST.ProductId = this.productId;
        this.productNPDAST.SDLCPracticeId = this.npdastSDLCPracticeId;
        this.productNPDAST.EnrolDate = this.convertToUTC(this.productNPDAST.EnrolDate);
        this.productNPDAST.NextReviewDate = this.convertToUTC(this.productNPDAST.NextReviewDate);

        this.productNPDAST.SDLCRequestStatusId = this.productSDLCRequestStatuses.find(status => status.Name === 'Requested')?.Id;
  
        this.productSDLCService.createProductSDLC(this.productNPDAST).subscribe((newNpDast) => {
          const npDastId = newNpDast.Id;
          this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Created NP-DAST Request', life: 3000 });

          
          this.sdlcNewNpDastContacts.forEach((contact) => {
            const productUser = {
              UserGraphId: contact.id,
              Name: contact.displayName,
              Mail: contact.mail,
              ProductSDLCId: npDastId
            };
            
            if (productUser.UserGraphId && productUser.Name && productUser.Mail && npDastId) {
              this.productSDLCService.createSDLCPrimaryContact(productUser).subscribe((response) => {
                this.messageService.add({ severity: 'success', summary: 'Successful', detail: `${productUser.Name} added`, life: 3000 });
                this.logService.sendInfoMessage('CREATE', 'product/' + this.productId + '/sdlc/npdast/' + npDastId + "/users", productUser);
              });
            }
          });

          const toEmails = this.toEmailService.getToEmailsNpDast();

          // Define the list of CC recipients
          const ccEmails = this.sdlcNewNpDastContacts.map(contact => contact.mail);

          const emailContent = this.emailTemplateService.generateEmailContent('NPDAST', this.productName, this.productId, this.productReference, npDastId, 'sdlc/npdast');
          this.sendEmail(toEmails, ccEmails, `NPDAST Created - ${this.productName} (${this.productId})`, emailContent);

          this.logService.sendInfoMessage('CREATE', 'product/' + this.productId + '/sdlc/npdast/', this.productNPDAST);
  
          this.npdastDialog = false;
          this.productNPDAST = {};
          this.sdlcNewNpDastContacts = [];
          this.selectedNPDastId = undefined;
          this.submitted = false;
          
          this.fetchNPDASTData(true, 'Requested');
        });
      }
      this.messages4 = [];
    }

    addNewNpDastContact() {
      if (this.selectedUser != null) {
        const userExists = this.sdlcNewNpDastContacts.some(contact => contact.id === this.selectedUser!.id);
        if (userExists) {
          this.messageService.add({ severity: 'warn', summary: 'Warning', detail: 'User already added as new contact', life: 3000 });
        } else {
          this.sdlcNewNpDastContacts.push(this.selectedUser);
          this.hideUserDialog();
        }
      }
      this.selectedUser = null;
    }
  
    removeNewNpDastContact(contact: User) {
      const index = this.sdlcNewNpDastContacts.indexOf(contact);
      if (index !== -1) {
          this.sdlcNewNpDastContacts.splice(index, 1);
      }
    }
  
    onStatusChange(shouldReset: boolean = true) {
      if (shouldReset) {
        this.selectedNPDastId = undefined;
        this.selectedNPDastOption = {};
        this.editedProductSDLC = {};
        this.sdlcPrimaryContacts = [];
        this.hasSelectedOption = false;
      }
  
      if (this.selectedStatus) {
        this.filteredProductSDLCNPDAST = this.productSDLCNPDAST.filter(npdast => npdast.SDLCRequestStatusName === this.selectedStatus);
      } else {
        // If no status selected, reset the array to the original data
        this.filteredProductSDLCNPDAST = this.productSDLCNPDAST;
      }
    }

    sendEmail(toEmails: string[], ccEmails: string[], subject: string, htmlContent: string) {
      const emailRequest: EmailRequestNewDto = {
          toEmails: toEmails,
          ccEmails: ccEmails,
          subject: subject,
          htmlContent: htmlContent,
          plainTextContent: '' // Assuming no plain text content in this case
      };
  
      this.emailService.sendEmailNew(emailRequest).subscribe(response => {
          console.log('Email sent successfully', response);
      }, error => {
          console.error('Error sending email', error);
      });
    }
    
    onSubmit() {
      if (this.submitted) {
        return;
      }


      this.submitted = true;
  
      if (this.selectedNPDastOption && this.selectedNPDastId) {
        this.editedProductSDLC.EnrolDate = this.convertToUTC(this.editedProductSDLC.EnrolDate);
        this.editedProductSDLC.NextReviewDate = this.convertToUTC(this.editedProductSDLC.NextReviewDate);
        
        this.productSDLCService.updateProductSDLC(this.selectedNPDastId, this.editedProductSDLC).subscribe((response) => {
          this.messageService.add({ severity: 'success', summary: 'NP-DAST updated successfully', });
          this.submitted = false;
          // this.fetchNPDASTData(false);

          if (this.productId && this.selectedNPDastId)
            {
              this.productSDLCService.getProductSDLCNPDastById(this.productId, this.selectedNPDastId).subscribe((npDast) => {
                this.npDastAfter = npDast.value[0];

                const htmlContent = this.createEmailContent(this.npDastBefore, this.npDastAfter);
                // Define the list of To recipients
                const toEmails = this.toEmailService.getToEmailsNpDast();
                    
                // Define the list of CC recipients
                const ccEmails = this.sdlcPrimaryContacts.map(contact => contact.Mail);
                console.log(this.sdlcPrimaryContacts);

                this.sendEmail(toEmails, ccEmails, `NPDAST Updated - ${this.productName} (${this.productId})`, htmlContent);
                this.logService.sendInfoMessage('UPDATE', 'product/' + this.productId + '/sdlc/npdast/', this.npDastAfter, this.npDastBefore);
              });
            }
        }, (error) => {
          this.messageService.add({ severity: 'error', summary: 'Error! Possibly no changes made to the form. Please try again.', });
          this.submitted = false;
          console.error('Error updating SDLC', error);
        });
      } else {
        this.messageService.add({ severity: 'error', summary: 'Select a practice from the left menu', });
        this.submitted = false;
      }
    }
  
    getToolImageUrl(toolName: string | undefined): string {
      switch (toolName) {
        case 'Tenable WAS':
          return 'assets/layout/images/TenableWAS-logo.png';
        // Add cases for other tools as needed
        default:
          return 'assets/layout/images/default-tool-logo.png'; // Fallback image
      }
    }

    onEnvironmentChange(event: any) {
      const selectedScanTypeDropdown = this.getScanTypeNameById(event.value);

      if (selectedScanTypeDropdown === 'Authenticated') {
        this.messages3 = [{ severity: 'info', summary: 'Authenticated Scan Type Selected', detail: 'Please select Prod Dast Environment and record deviations in the comment field' +
        `<a href="https://irissoftware.sharepoint.com/sites/EngineeringIntranet/SitePages/Cyber-Essentials-Plus.aspx" target="_blank"><i class="pi pi-question-circle"></i></a>` }];
      } else {
        this.messages3 = [{ severity: 'info', summary: 'Unauthenticated Scan Type Selected', detail: 'Please select Non-Prod Dast environment and record deviations in the comment field' +
        `<a href="https://irissoftware.sharepoint.com/sites/EngineeringIntranet/SitePages/Cyber-Essentials-Plus.aspx" target="_blank"><i class="pi pi-question-circle"></i></a>` }];
      }
    }

    onEnvironmentNewChange(event: any) {
      const selectedScanTypeDropdown = this.getScanTypeNameById(event.value);
      if (selectedScanTypeDropdown === 'Authenticated') {
        this.messages4 = [{ severity: 'info', summary: 'Authenticated Scan Type Selected', detail: 'Please select Prod Dast Environment and record deviations in the comment field' +
        `<a href="https://irissoftware.sharepoint.com/sites/EngineeringIntranet/SitePages/Cyber-Essentials-Plus.aspx" target="_blank"><i class="pi pi-question-circle"></i></a>` }];
      } else {
        this.messages4 = [{ severity: 'info', summary: 'Unauthenticated Scan Type Selected', detail: 'Please select Non-Prod Dast environment and record deviations in the comment field' +
        `<a href="https://irissoftware.sharepoint.com/sites/EngineeringIntranet/SitePages/Cyber-Essentials-Plus.aspx" target="_blank"><i class="pi pi-question-circle"></i></a>` }];
      }
    }

    addPrimaryContact() {
      if (this.selectedUser && this.productId) {
        const userExists = this.sdlcPrimaryContacts.some(contact => contact.UserGraphId === this.selectedUser!.id);
        if (userExists) {
          this.messageService.add({ severity: 'warn', summary: 'Warning', detail: 'User already added', life: 3000 });
        } else {
          const productUser = {
            UserGraphId: this.selectedUser.id,
            Name: this.selectedUser.displayName,
            Mail: this.selectedUser.mail,
            ProductSDLCId: this.selectedNPDastId
          };
    
          if (productUser.UserGraphId && productUser.Name && productUser.Mail && productUser.ProductSDLCId && this.selectedNPDastId) {
            this.productSDLCService.createSDLCPrimaryContact(productUser).subscribe((response) => {
              this.messageService.add({ severity: 'success', summary: 'Successful', detail: `${productUser.Name} added`, life: 3000 });
    
              this.fetchProductSDLCContacts();
              this.hideUserDialog();
            });
          }
        }
      }
    }
  
    removeUser(contact: SDLCPrimaryContact) {
      this.confirmationService.confirm({
          message: 'Are you sure you want to remove this user?',
          accept: () => {
              // User confirmed, perform deletion
              const index = this.sdlcPrimaryContacts.indexOf(contact);
              if (index !== -1 && contact.Id) {
                  this.sdlcPrimaryContacts.splice(index, 1);
                  // Perform the actual deletion from your backend here if necessary
                  this.productSDLCService.deleteSDLCPrimaryContact(contact.Id).subscribe((response) => {
                    this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'User removed', life: 3000 });
                  });
              }
          }
      });
   }

   deleteNPDast(npdast: ProductSDLC) {
    this.confirmationService.confirm({
        message: `Are you sure you want to delete this NPDAST? (Id: ${npdast.Id})`,
        header: 'Delete Confirmation',
        icon: 'pi pi-info-circle',
        acceptButtonStyleClass:"p-button-danger p-button-text",
        rejectButtonStyleClass:"p-button-text p-button-text",
        acceptIcon:"none",
        rejectIcon:"none",
        accept: () => {
            // User confirmed, perform deletion
            if (npdast.Id) {
                this.productSDLCService.deleteProductSDLC(npdast.Id).subscribe((response) => {
                    this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'NPDAST deleted', life: 3000 });
                    this.fetchNPDASTData(false, this.selectedStatus);
                    this.logService.sendInfoMessage('DELETE', 'product/' + this.productId + '/sdlc/npdast/' + npdast.Id, null, npdast);
                });
            }
        },
        reject: () => {
          this.messageService.add({ severity: 'error', summary: 'Rejected', detail: 'You have rejected' });
      }
    });
  }

  createEmailContent(before: ProductSDLC, after: ProductSDLC): string {
    const primaryContactNames = this.sdlcPrimaryContacts.map(contact => contact.Name).join(', ');
  
    const highlightIfChanged = (newValue: any, oldValue: any) => {
      return newValue !== oldValue ? 'style="background-color: #e9e9e9;"' : '';
    };
  
    return `
      <div style="font-family: Arial, sans-serif; line-height: 1.6; color: #333;">
        <h3 style="color: #0056b3;">NP-DAST Update Notification</h3>
        <p>The following changes have been made to NP-DAST:</p>
        <table style="border-collapse: collapse; width: 100%; margin-bottom: 20px; border: 1px solid #ddd;">
          <thead>
            <tr>
              <th style="padding: 8px; border: 1px solid #ddd; background-color: #f2f2f2;">Item</th>
              <th style="padding: 8px; border: 1px solid #ddd; background-color: #f2f2f2;">New Value</th>
              <th style="padding: 8px; border: 1px solid #ddd; background-color: #f2f2f2;">Previous Value</th>
            </tr>
          </thead>
          <tbody>
            <tr ${highlightIfChanged(after.Id, before.Id)}>
              <td style="padding: 8px; border: 1px solid #ddd;">Request Id</td>
              <td style="padding: 8px; border: 1px solid #ddd;" ${highlightIfChanged(after.Id, before.Id)}>${after.Id || ''}</td>
              <td style="padding: 8px; border: 1px solid #ddd;" ${highlightIfChanged(after.Id, before.Id)}>${before.Id || ''}</td>
            </tr>
            <tr ${highlightIfChanged(after.SDLCRequestStatusName, before.SDLCRequestStatusName)}>
              <td style="padding: 8px; border: 1px solid #ddd;">Status</td>
              <td style="padding: 8px; border: 1px solid #ddd;" ${highlightIfChanged(after.SDLCRequestStatusName, before.SDLCRequestStatusName)}>${after.SDLCRequestStatusName || ''}</td>
              <td style="padding: 8px; border: 1px solid #ddd;" ${highlightIfChanged(after.SDLCRequestStatusName, before.SDLCRequestStatusName)}>${before.SDLCRequestStatusName || ''}</td>
            </tr>
            <tr ${highlightIfChanged(after.ProductName, before.ProductName)}>
              <td style="padding: 8px; border: 1px solid #ddd;">Product</td>
              <td style="padding: 8px; border: 1px solid #ddd;" ${highlightIfChanged(after.ProductName, before.ProductName)}>${after.ProductName || ''}</td>
              <td style="padding: 8px; border: 1px solid #ddd;" ${highlightIfChanged(after.ProductName, before.ProductName)}>${before.ProductName || ''}</td>
            </tr>
            <tr ${highlightIfChanged(after.SDLCPracticeStatusName, before.SDLCPracticeStatusName)}>
              <td style="padding: 8px; border: 1px solid #ddd;">Maturity</td>
              <td style="padding: 8px; border: 1px solid #ddd;" ${highlightIfChanged(after.SDLCPracticeStatusName, before.SDLCPracticeStatusName)}>${after.SDLCPracticeStatusName || ''}</td>
              <td style="padding: 8px; border: 1px solid #ddd;" ${highlightIfChanged(after.SDLCPracticeStatusName, before.SDLCPracticeStatusName)}>${before.SDLCPracticeStatusName || ''}</td>
            </tr>
            <tr ${highlightIfChanged(after.SDLCNPDastScanTypeName, before.SDLCNPDastScanTypeName)}>
              <td style="padding: 8px; border: 1px solid #ddd;">Scan type</td>
              <td style="padding: 8px; border: 1px solid #ddd;" ${highlightIfChanged(after.SDLCNPDastScanTypeName, before.SDLCNPDastScanTypeName)}>${after.SDLCNPDastScanTypeName || ''}</td>
              <td style="padding: 8px; border: 1px solid #ddd;" ${highlightIfChanged(after.SDLCNPDastScanTypeName, before.SDLCNPDastScanTypeName)}>${before.SDLCNPDastScanTypeName || ''}</td>
            </tr>
            <tr ${highlightIfChanged(after.SDLCEnvironmentName, before.SDLCEnvironmentName)}>
              <td style="padding: 8px; border: 1px solid #ddd;">Env</td>
              <td style="padding: 8px; border: 1px solid #ddd;" ${highlightIfChanged(after.SDLCEnvironmentName, before.SDLCEnvironmentName)}>${after.SDLCEnvironmentName || ''}</td>
              <td style="padding: 8px; border: 1px solid #ddd;" ${highlightIfChanged(after.SDLCEnvironmentName, before.SDLCEnvironmentName)}>${before.SDLCEnvironmentName || ''}</td>
            </tr>
            <tr ${highlightIfChanged(after.DastURL, before.DastURL)}>
              <td style="padding: 8px; border: 1px solid #ddd;">URLs</td>
              <td style="padding: 8px; border: 1px solid #ddd;" ${highlightIfChanged(after.DastURL, before.DastURL)}>${after.DastURL || ''}</td>
              <td style="padding: 8px; border: 1px solid #ddd;" ${highlightIfChanged(after.DastURL, before.DastURL)}>${before.DastURL || ''}</td>
            </tr>
            <tr ${highlightIfChanged(after.Comment, before.Comment)}>
              <td style="padding: 8px; border: 1px solid #ddd;">Comment</td>
              <td style="padding: 8px; border: 1px solid #ddd;" ${highlightIfChanged(after.Comment, before.Comment)}>${after.Comment || ''}</td>
              <td style="padding: 8px; border: 1px solid #ddd;" ${highlightIfChanged(after.Comment, before.Comment)}>${before.Comment || ''}</td>
            </tr>
            <tr>
              <td style="padding: 8px; border: 1px solid #ddd;">Primary Contacts</td>
              <td style="padding: 8px; border: 1px solid #ddd;">${primaryContactNames || ''}</td>
              <td style="padding: 8px; border: 1px solid #ddd;">n/a</td>
            </tr>
            <tr>
              <td style="padding: 8px; border: 1px solid #ddd;">Requestor</td>
              <td style="padding: 8px; border: 1px solid #ddd;">${this.profile?.displayName || ''}</td>
              <td style="padding: 8px; border: 1px solid #ddd;">n/a</td>
            </tr>
          </tbody>
        </table>
      </div>
    `;
  }
  

  convertToUTC(date: Date | undefined): Date | undefined {
    if (date) {
      return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
    }
    return undefined;
  }
}
