// to-email.service.ts
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ToEmailService {
  private toEmails: string[] = ['marko.kennedy@iris.co.uk'];
  private toEmailsDev: string[] = ['wasim.ramzan@iris.co.uk'];
  private toEmailsNPDast: string[] = ['marko.kennedy@iris.co.uk', 'chakrapani.siranam@iris.co.uk'];
  private toEmailsPentest: string[] = ['marko.kennedy@iris.co.uk', 'ops-requests@ngiris.com'];

  constructor() { }

  getToEmails(): string[] {
    return this.toEmails;
  }

  getToEmailsNpDast(): string[] {
    return this.toEmailsNPDast;
  }

  getToEmailsPentest(): string[] {
    return this.toEmailsPentest;
  }

  getEmailContent(baseContent: string): string {
    if (environment.production) {
      return baseContent;
    } else {
      return `${baseContent}<br><br>This is a message from the Dev Environment.`; // Append dev environment message
    }
  }
}