import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, map } from "rxjs";
import { PentestContact, PentestContactRequestDto, PentestPreReqUserDto, PentestPreReqxDto, PentestProgressDto, PentestProviderDto, PentestRequestedByDto, PentestRiskDto, ProductPentestPutDto, ProductPentestRequestDto } from "src/app/api/product-pentest";

import { environment } from "src/environments/environment";

@Injectable(
    {
        providedIn: 'root'
    }
)

export class ProductPentestService {
    baseApiUrl: string = environment.baseApiUrl;
    constructor(private http: HttpClient) { }

    getProductPentest(productID: string): Observable<any> {
        return this.http.get<any>(`${this.baseApiUrl}/api/ProductPentests/${productID}`);
    }

    getProductPentestById(id: number | undefined): Observable<any> {
        return this.http.get<any>(this.baseApiUrl + `/api/ProductPentests?$filter=Id eq ${id}`);
    }

    getProductPentestDocuments(productID: string, pentestId: string): Observable<any> {
        return this.http.get<any>(`${this.baseApiUrl}/api/PentestDocuments/${productID}/${pentestId}`);
        // return this.http.get<any>(`${this.baseApiUrl}/api/PentestDocuments/2/273`);
    }

    updateProductPentest(id: number, productPentestPutDto: ProductPentestPutDto) {
        return this.http.put<any>(`${this.baseApiUrl}/api/ProductPentests/${id}/`, productPentestPutDto);
    }

    createProductPentest(productPentestRequestDto: ProductPentestRequestDto) {
        return this.http.post<any>(`${this.baseApiUrl}/api/ProductPentests/`, productPentestRequestDto);
    }

    deleteProductPentest(id: number): Observable<any> {
        return this.http.delete<any>(`${this.baseApiUrl}/api/ProductPentests/${id}`);
    }

    getAllPentestRisks(): Observable<PentestRiskDto[]> {
        return this.http.get<any>(`${this.baseApiUrl}/api/PentestRisks/`).pipe(
            map((response) => {
                // Check if the response has a "value" property
                if (response && response.value) {
                    // Return the array of objects from the "value" property
                    return response.value;
                } else {
                    // If there is no "value" property, return the entire response
                    return response;
                }
            })
        );

    }

    getAllPentestProgress(): Observable<PentestProgressDto[]> {
        return this.http.get<any>(`${this.baseApiUrl}/api/PentestProgresses/`).pipe(
            map((response) => {
                // Check if the response has a "value" property
                if (response && response.value) {
                    // Return the array of objects from the "value" property
                    return response.value;
                } else {
                    // If there is no "value" property, return the entire response
                    return response;
                }
            })
        );
    }

    getAllPentestProviders(): Observable<PentestProviderDto[]> {
        return this.http.get<any>(`${this.baseApiUrl}/api/PentestProviders/`).pipe(
            map((response) => {
                // Check if the response has a "value" property
                if (response && response.value) {
                    // Return the array of objects from the "value" property
                    return response.value;
                } else {
                    // If there is no "value" property, return the entire response
                    return response;
                }
            })
        );
    }

    getAllPentestRequestedBy(): Observable<PentestRequestedByDto[]> {
        return this.http.get<any>(`${this.baseApiUrl}/api/PentestRequestedBys/`).pipe(
            map((response) => {
                // Check if the response has a "value" property
                if (response && response.value) {
                    // Return the array of objects from the "value" property
                    return response.value;
                } else {
                    // If there is no "value" property, return the entire response
                    return response;
                }
            })
        );
    }

    getAllPreReqxs(): Observable<PentestPreReqxDto[]> {
        return this.http.get<any>(`${this.baseApiUrl}/api/PentestPreReqxs/`).pipe(
            map((response) => {
                // Check if the response has a "value" property
                if (response && response.value) {
                    // Return the array of objects from the "value" property
                    return response.value;
                } else {
                    // If there is no "value" property, return the entire response
                    return response;
                }
            })
        );
    }

    getPentestContacts(ProductPentestId: number): Observable<PentestContact[]> {
        return this.http.get<any>(`${this.baseApiUrl}/api/PentestContacts/?$filter=ProductPentestId eq ${ProductPentestId}`).pipe(
            map((response) => {
                // Check if the response has a "value" property
                if (response && response.value) {
                    // Return the array of objects from the "value" property
                    return response.value;
                } else {
                    // If there is no "value" property, return the entire response
                    return response;
                }
            })
        );
    }

    createPentestContact(pentestContactRequestDto: PentestContactRequestDto): Observable<PentestContactRequestDto> {
        return this.http.post<PentestContactRequestDto>(`${this.baseApiUrl}/api/PentestContacts/`, pentestContactRequestDto);
    }

    deletePentestContact(id: number): Observable<any> {
        return this.http.delete<any>(`${this.baseApiUrl}/api/PentestContacts/${id}`);
    }

    getPreReqUserContacts(ProductPentestId: number): Observable<PentestPreReqUserDto[]> {
        return this.http.get<any>(`${this.baseApiUrl}/api/PentestPreReqUsers/?$filter=ProductPentestId eq ${ProductPentestId}`).pipe(
            map((response) => {
                // Check if the response has a "value" property
                if (response && response.value) {
                    // Return the array of objects from the "value" property
                    return response.value;
                } else {
                    // If there is no "value" property, return the entire response
                    return response;
                }
            })
        );
    }

    createPreReqUserContact(pentestPreReqUserRequestDto: any): Observable<any> {
        return this.http.post<any>(`${this.baseApiUrl}/api/PentestPreReqUsers/`, pentestPreReqUserRequestDto);
    }

    deletePreReqUserContact(id: number): Observable<any> {
        return this.http.delete<any>(`${this.baseApiUrl}/api/PentestPreReqUsers/${id}`);
    }

    uploadDocument(productId: string, formData: FormData): Observable<any> {
        return this.http.post<any>(`${this.baseApiUrl}/api/PentestDocuments/upload/${productId}`, formData);
    }

    updateDocumentMetadata(productId: string, fileName: string, metadata: any): Observable<any> {
        return this.http.patch<any>(`${this.baseApiUrl}/api/PentestDocuments/metadata/${productId}/${fileName}`, metadata);
    }

    deleteDocument(productId: string, fileName: string): Observable<any> {
        return this.http.delete<any>(`${this.baseApiUrl}/api/PentestDocuments/${productId}/${fileName}`);
    }
}