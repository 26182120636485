import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ToolExternalService } from 'src/app/layout/service/tool-external.service';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';
//import 'chartjs-adapter-date-fns';
import { Router } from '@angular/router';

@Component({
  selector: 'sonar-metrics-trend-widget',
  templateUrl: './sonar-metrics-trend-widget.component.html',
  styleUrls: ['./sonar-metrics-trend-widget.component.scss']
})

export class SonarMetricsTrendWidgetComponent implements OnInit, OnChanges {

  @Input() data: any;
  pieData: any;
  pieOptions: any;
  sonarReportingLevel: string = '';
  sonarLoading: boolean = true;
  sonarLink: string = '';
  sonarMetricsAvailable: boolean = false;
  SonarMetricsCollapsed: boolean = false;

  components: string[] = [];
  hasMultipleComponents: boolean = false;
  sourceData: any[] = [];
  selectedComponent: any;
  selectedComponentName: string = '';

  isInitialized: boolean = false;
  activeTab: number = 0;
  isOptionsExpanded: boolean = false;

  chartScale: string = 'alltime';

  ncloc_data: SnapshotSummary = new SnapshotSummary();
  techdebt_data: SnapshotSummary = new SnapshotSummary();
  complexity_data: SnapshotSummary = new SnapshotSummary();
  hotspot_data: SnapshotSummary = new SnapshotSummary();
  coverage_data: SnapshotSummary = new SnapshotSummary();
  reliability_data: SnapshotSummary = new SnapshotSummary();
  maintainability_data: SnapshotSummary = new SnapshotSummary();
  security_data: SnapshotSummary = new SnapshotSummary();
  securityReview_data: SnapshotSummary = new SnapshotSummary();

  ncloc_source: any[] = [];
  techdebt_source: any[] = [];
  complexity_source: any[] = [];
  hotspot_source: any[] = [];
  coverage_source: any[] = [];
  reliability_source: any[] = [];
  maintainability_source: any[] = [];
  security_source: any[] = [];
  securityReview_source: any[] = [];

  chartMessage: string = '';

  basePieOptons: any = {
    responsive: true,
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        mode: 'index',
        intersect: false
      }
    },
    scales: {
      x: {
        grid: {
          display: false
        },
        type: 'time',
        time: {
          unit: 'day'
        }
      },
      y: {
        beginAtZero: true,
      }
    }
  };

  availableMeasures: string[] = ["Lines of Code", "Technical Debt", "Complexity", "Security Hotspots", "Coverage", "Reliability Rating", "Maintainability Rating", "Security Rating", "Security Review Rating"];
  selectedMeasure: string = 'Lines of Code';

  summary: SnapshotSummary = new SnapshotSummary();
  widgetContext: string = '';

  constructor(private toolExternalService: ToolExternalService, private router: Router) { }

  ngOnInit(): void {
    this.widgetContext = this.data.objectId;
    this.initialiseWidgetOnce();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data']) {
      if (this.widgetContext !== this.data.objectId) {
        this.isInitialized = false;
        this.widgetContext = this.data.objectId;
      }
      this.resetWidget();
      this.initialiseWidgetOnce();
    }
  }

  private initialiseWidgetOnce(): void {
    if (!this.isInitialized) {
      this.initialiseWidget();
      this.isInitialized = true;
    }
  }


  initialiseWidget() {
    this.pieOptions = this.basePieOptons;

    //convert this.productId to a number
    const prodNumber = parseInt(this.data.objectId);
    const productType = this.data?.productType ?? null;
    const currentUrl = this.router.url;

    this.toolExternalService.getSonarMetricsMeta(prodNumber, productType).pipe(
      catchError((error) => {
        if (error.status === 404) {
          this.sonarReportingLevel = `No Sonar data available`;
          this.pieData = {
            labels: [],
            datasets: []
          };
        } else {
          console.error('An error occurred:', error);
          this.sonarReportingLevel = "Unexpected error occured loading Sonar data";
          this.pieData = {
            labels: [],
            datasets: []
          };
        }
        this.sonarLoading = false;
        return of(null);
      })
    ).subscribe((sonarData) => {
      if (!sonarData) {
        this.sonarLoading = false;
        return;
      }

      const pt = sonarData?.productType ?? "";

      if (pt === 'Product') {
        this.sonarReportingLevel = "";
      } else if (pt === 'Suite' && currentUrl.includes('/suite/')) {
        this.sonarReportingLevel = "Sonar data not available for this suite";
        this.sonarMetricsAvailable = false;
        return;
      } else {
        this.sonarReportingLevel = "Sonar data not available at this product level. Showing data for parent suite: " + sonarData.objectName;
      }

      this.components = [];
      for (const dataItem of sonarData.sonarComponents) {
        this.components.push(dataItem ?? "??");
      }
      this.selectedComponentName = sonarData.sonarComponents[0];
      this.hasMultipleComponents = this.components.length > 1;

      this.populateData();
      this.populateChart();
      this.sonarMetricsAvailable = true;
      this.sonarLoading = false;
    });
  }

  toggleSnykVulnCollapse() {
    this.SonarMetricsCollapsed = !this.SonarMetricsCollapsed;
  }

  selectTab(index: number) {
    this.activeTab = index;
    if(this.activeTab === 1) {
      this.populateChart();
    };
  }

  onScaleSelectionChange(value: string): void {
    this.populateData();
    this.populateChart();
  }

  toggleChartOptions() {
    this.isOptionsExpanded = !this.isOptionsExpanded;
  }

  onDropdownChange(event: any) {

    this.activeTab = 0;

    const selectedValue = event.value;
    this.selectedComponentName = selectedValue;

    this.summary = new SnapshotSummary();
    this.ncloc_data = new SnapshotSummary();
    this.techdebt_data = new SnapshotSummary();
    this.complexity_data = new SnapshotSummary();
    this.hotspot_data = new SnapshotSummary();
    this.coverage_data = new SnapshotSummary();
    this.reliability_data = new SnapshotSummary();
    this.maintainability_data = new SnapshotSummary();
    this.security_data = new SnapshotSummary();
    this.securityReview_data = new SnapshotSummary();
    this.ncloc_source = [];
    this.techdebt_source = [];
    this.complexity_source = [];
    this.hotspot_source = [];
    this.coverage_source = [];
    this.reliability_source = [];
    this.maintainability_source = [];
    this.security_source = [];
    this.securityReview_source = [];
    this.selectedMeasure = 'Lines of Code';
    this.chartMessage = '';
    this.pieOptions = this.basePieOptons;

    this.populateData();
    this.populateChart();
    
  }

  onMeasureDropdownChange(event: any) {
    this.populateChart();
  }

  populateChart() {

    switch (this.selectedMeasure) {
      case 'Lines of Code':
        this.pieData = this.transformDataToChartData(this.ncloc_source, 'ncloc');
        this.chartMessage = "Lines of Code, specifically NCLOC (Non-Comment Lines of Code), measures the number of lines of code excluding comments and blank lines, providing an indicator of the codebase size and complexity.";
        this.pieOptions.scales.y = {
          beginAtZero: false,
          suggestedMax: (data: any) => {
            const maxValue = Math.max(...this.pieData.datasets[0].data);
            return maxValue * 1.2;
          },
          suggestedMin: (data: any) => {
            const minValue = Math.min(...this.pieData.datasets[0].data);
            if (minValue - (minValue * 0.2) < 0) {
              return 0;
            } else {
              return minValue - (minValue * 0.2);
            }
          }
        };
        break;
      case 'Technical Debt':
        this.pieData = this.transformDataToChartData(this.techdebt_source, 'sqale_index');
        this.chartMessage = "Techical Debt is calculated based on the estimated minutes of work required to all remediate known code deficiencies. For simplicity purposes, this is converted to days on the summary tab; 1 day = 480 minutes.";
        this.pieOptions.scales.y = {
          beginAtZero: false,
          suggestedMax: (data: any) => {
            const maxValue = Math.max(...this.pieData.datasets[0].data);
            return maxValue * 1.2;
          },
          suggestedMin: (data: any) => {
            const minValue = Math.min(...this.pieData.datasets[0].data);
            if (minValue - (minValue * 0.2) < 0) {
              return 0;
            } else {
              return minValue - (minValue * 0.2);
            }
          }
        };
        break;
      case 'Complexity':
        this.pieData = this.transformDataToChartData(this.complexity_source, 'complexity');
        this.chartMessage = "Complexity measures the intricacy of the code's control flow, calculated as the number of decision points such as loops and conditionals, which can indicate higher maintenance efforts.";
        this.pieOptions.scales.y = {
          beginAtZero: false,
          suggestedMax: (data: any) => {
            const maxValue = Math.max(...this.pieData.datasets[0].data);
            return maxValue * 1.2;
          },
          suggestedMin: (data: any) => {
            const minValue = Math.min(...this.pieData.datasets[0].data);
            if (minValue - (minValue * 0.2) < 0) {
              return 0;
            } else {
              return minValue - (minValue * 0.2);
            }
          }
        };
        break;
      case 'Security Hotspots':
        this.pieData = this.transformDataToChartData(this.hotspot_source, 'security_hotspots');
        this.chartMessage = "Security Hotspots highlight portions of code that require manual review to ensure they do not introduce potential security vulnerabilities.";
        this.pieOptions.scales.y = {
          beginAtZero: false,
          suggestedMax: (data: any) => {
            const maxValue = Math.max(...this.pieData.datasets[0].data);
            return maxValue * 1.2;
          },
          suggestedMin: (data: any) => {
            const minValue = Math.min(...this.pieData.datasets[0].data);
            if (minValue - (minValue * 0.2) < 0) {
              return 0;
            } else {
              return minValue - (minValue * 0.2);
            }
          }
        };
        break;
      case 'Coverage':
        this.pieData = this.transformDataToChartData(this.coverage_source, 'coverage');
        this.chartMessage = "Coverage indicates the percentage of overall code that is executed by unit tests, helping to identify untested and potentially vulnerable areas of the codebase.";
        this.pieOptions.scales.y = {
          min: 0,
          max: 100
        };
        break;
      case 'Reliability Rating':
        this.pieData = this.transformDataToChartData(this.reliability_source, 'reliability_rating');
        this.chartMessage = "Reliability Rating rates the code's robustness by assessing the number of bugs, with ratings from 1 (A) (0 bugs) to 5 (E) (≥21 bugs), where fewer bugs, and therefore a lower score, indicate higher reliability.";
        this.pieOptions.scales.y = {
          beginAtZero: false,  
          min: 1,
          max: 4,
          ticks: {
            stepSize: 1,
            callback: function (value: any) {
              return Number.isInteger(value) ? value : '';
              }
            }
          };
        break;
      case 'Maintainability Rating':
        this.pieData = this.transformDataToChartData(this.maintainability_source, 'sqale_rating');
        this.chartMessage = "Maintainability Rating (or the SQALE rating) rates the code's ease of maintainability by assessing the Technical Debt Ratio, which is the ratio of the time needed to fix code issues to the time it would take to develop the code from scratch, with ratings from 1 (A) (≤5%) to 5 (E) (>50%), where a lower ratio, and therefore a lower score, indicates easier maintainability.";
        this.pieOptions.scales.y = {
          beginAtZero: false,
          min: 1,
          max: 4,
          ticks: {
            stepSize: 1,
            callback: function (value: any) {
              return Number.isInteger(value) ? value : '';
            }
          }
        };
        break;
      case 'Security Rating':
        this.pieData = this.transformDataToChartData(this.security_source, 'security_rating');
        this.chartMessage = "Security Rating rates the severity of security vulnerabilities in the codebase, with ratings from 1 (A) (no vulnerabilities) to 5 (E) (≥2 critical vulnerabilities), where fewer and less severe vulnerabilities, and therefore a lower score, indicate higher security.";
        this.pieOptions.scales.y = {
          beginAtZero: false,
          min: 1,
          max: 4,
          ticks: {
            stepSize: 1,
            callback: function (value: any) {
              return Number.isInteger(value) ? value : '';
            }
          }
        };
        break;
      case 'Security Review Rating':
        this.pieData = this.transformDataToChartData(this.securityReview_source, 'security_review_rating');
        this.chartMessage = "Security Review Rating assesses the proportion of reviewed security hotspots, with ratings from 1 (A) (≥80% reviewed) to 5 (E) (<20% reviewed), where a higher percentage of reviewed hotspots, and therefore a lower score, indicates better security management.";
        this.pieOptions.scales.y = {
          beginAtZero: false,
          min: 1,
          max: 4,
          ticks: {
            stepSize: 1,
            callback: function (value: any) {
              return Number.isInteger(value) ? value : '';
            }
          }
        };
        break;
      default:
        break;
    }
  }


  populateData() {

    this.toolExternalService.getSonarMetricsTrendData(this.selectedComponentName, "ncloc").subscribe((data) => {
      this.ncloc_source = data.days;
      this.ncloc_data = this.getMetricSummary(data);
    });
    this.toolExternalService.getSonarMetricsTrendData(this.selectedComponentName, "sqale_index").subscribe((data) => {
      this.techdebt_source = data.days;
      this.techdebt_data = this.getMetricSummary(data);
    });
    this.toolExternalService.getSonarMetricsTrendData(this.selectedComponentName, "complexity").subscribe((data) => {
      this.complexity_source = data.days;
      this.complexity_data = this.getMetricSummary(data);
    });
    this.toolExternalService.getSonarMetricsTrendData(this.selectedComponentName, "security_hotspots").subscribe((data) => {
      this.hotspot_source = data.days;
      this.hotspot_data = this.getMetricSummary(data);
    });
    this.toolExternalService.getSonarMetricsTrendData(this.selectedComponentName, "coverage").subscribe((data) => {
      this.coverage_source = data.days;
      this.coverage_data = this.getMetricSummary(data);
    });
    this.toolExternalService.getSonarMetricsTrendData(this.selectedComponentName, "reliability_rating").subscribe((data) => {
      this.reliability_source = data.days;
      this.reliability_data = this.getMetricSummary(data);
    });
    this.toolExternalService.getSonarMetricsTrendData(this.selectedComponentName, "sqale_rating").subscribe((data) => {
      this.maintainability_source = data.days;
      this.maintainability_data = this.getMetricSummary(data);
    });
    this.toolExternalService.getSonarMetricsTrendData(this.selectedComponentName, "security_rating").subscribe((data) => {
      this.security_source = data.days;
      this.security_data = this.getMetricSummary(data);
    });
    this.toolExternalService.getSonarMetricsTrendData(this.selectedComponentName, "security_review_rating").subscribe((data) => {
      this.securityReview_source = data.days;
      this.securityReview_data = this.getMetricSummary(data);
    });



    //this.pieData = this.transformDataToChartData(this.selectedComponent.results);
  }

  resetWidget() {
    this.sonarLoading = true;
    this.sonarReportingLevel = '';
    this.sonarLink = '';
    this.sonarMetricsAvailable = false;
    this.SonarMetricsCollapsed = false;
    this.components = [];
    this.hasMultipleComponents = false;
    this.sourceData = [];
    this.selectedComponent = {};
    this.selectedComponentName = '';
    this.isInitialized = false;
    this.activeTab = 0;
    this.isOptionsExpanded = false;
    this.chartScale = 'alltime';
    this.summary = new SnapshotSummary();
    this.ncloc_data = new SnapshotSummary();
    this.techdebt_data = new SnapshotSummary();
    this.complexity_data = new SnapshotSummary();
    this.hotspot_data = new SnapshotSummary();
    this.coverage_data = new SnapshotSummary();
    this.reliability_data = new SnapshotSummary();
    this.maintainability_data = new SnapshotSummary();
    this.security_data = new SnapshotSummary();
    this.securityReview_data = new SnapshotSummary();
    this.ncloc_source = [];
    this.techdebt_source = [];
    this.complexity_source = [];
    this.hotspot_source = [];
    this.coverage_source = [];
    this.reliability_source = [];
    this.maintainability_source = [];
    this.security_source = [];
    this.securityReview_source = [];
    this.selectedMeasure = 'Lines of Code';
    this.chartMessage = '';
    this.pieOptions = this.basePieOptons;
  }

  absify(value: number): string {
    const newVal = Math.abs(value);
    return newVal.toLocaleString();
  }

  convertTechDebtToDays(value: number): string {
    const sqale = Math.abs(value);
    return (sqale / 480).toFixed(1);
  }


getMetricSummary(data: any) {
  const summary = new SnapshotSummary();
  const results = data.days;

  if (results.length === 0) {
    return summary; // No data to process
  }

  // Assume results are sorted by date in descending order
  const latestResult = results[0];
  const sevenDaysAgo = new Date();
  sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);
  const thirtyDaysAgo = new Date();
  thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
  const sixMonthsAgo = new Date();
  sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);

  let result7DaysAgo: any = null;
  let result30DaysAgo: any = null;
  let result6MonthsAgo: any = null;

  for (const result of results) {
    const resultDate = new Date(result.day);

    if (resultDate >= sevenDaysAgo) {
      result7DaysAgo = result;
    }

    if (resultDate >= thirtyDaysAgo) {
      result30DaysAgo = result;
    }

    if (resultDate >= sixMonthsAgo) {
      result6MonthsAgo = result;
    } else {
      break;
    }
  }

  // Populate v7Days
  if (result7DaysAgo) {
    summary.v7Days.hasSegment = true;
    summary.v7Days.Value = parseInt(latestResult.value) - parseInt(result7DaysAgo.value);
  }

  // Populate v30Days
  if (result30DaysAgo) {
    summary.v30Days.hasSegment = true;
    summary.v30Days.Value = parseInt(latestResult.value) - parseInt(result30DaysAgo.value);
  }

  // Populate v6Months
  if (result6MonthsAgo) {
    summary.v6Months.hasSegment = true;
    summary.v6Months.Value = parseInt(latestResult.value) - parseInt(result6MonthsAgo.value);
  }

  return summary;
}


  transformDataToChartData(data: any[], measureName: string): any {
    const labels: string[] = [];
    const values: number[] = [];

    // Get the current date
    const now = new Date();

    // Filter the data based on the selected chartScale
    const filteredData = data.filter(result => {
      const resultDate = new Date(result.day);
      const daysDiff = Math.floor((now.getTime() - resultDate.getTime()) / (1000 * 60 * 60 * 24));

      switch (this.chartScale) {
        case 'last7days':
          return daysDiff < 7;
        case 'last30days':
          return daysDiff < 30;
        case 'last6months':
          return daysDiff < 180;
        case 'alltime':
          return true; // Include all data
        default:
          return false;
      }
    });

    // Extract labels and series data for each severity level
    filteredData.forEach(result => {
      labels.push(result.day);
      values.push(result.value);
    });

    // Create the chart data object
    const datasets: any[] = [];

    datasets.push({
      label: measureName,
      data: values,
      fill: false,
      borderColor: '#2c2cbf',
      tension: 0.1,
      borderWidth: 2,
      pointRadius: 0
    });   

    const chartData: any = {
      labels: labels,
      datasets: datasets
    };

    return chartData;
  }
}

export class SnapshotSummary {
  v7Days: SnapshotSummarySegment;
  v30Days: SnapshotSummarySegment;
  v6Months: SnapshotSummarySegment;

  constructor() {
    this.v7Days = new SnapshotSummarySegment();
    this.v30Days = new SnapshotSummarySegment();
    this.v6Months = new SnapshotSummarySegment();
  }
}

export class SnapshotSummarySegment {
  hasSegment: boolean;
  Value: number;

  constructor() {
    this.hasSegment = false;
    this.Value = 0;
  }
}
