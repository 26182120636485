// role.service.ts
import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';

@Injectable({
  providedIn: 'root',
})
export class CheckRoleService {
  private currentUserRoles: string[] = [];

  constructor(private msalService: MsalService) {
    this.setCurrentUserRoles();
  }

  private setCurrentUserRoles(): void {
    const activeAccount = this.msalService.instance.getAllAccounts()[0];
    if (activeAccount && activeAccount.idTokenClaims) {
      this.currentUserRoles = (activeAccount.idTokenClaims['roles'] as string[]) || 
                              (activeAccount.idTokenClaims['role'] as string[]) || [];
    }
  }

  public hasRole(roles: string[]): boolean {
    return roles.some(role => this.currentUserRoles.includes(role));
  }
}
