<p-panel header="Useful Tools">
    <div class="useful-links">
      <p-card header="Securely Share Secrets" styleClass="useful-card">
        <p>IRIS hosted tool to encrypt secrets and share with others. Based on Yopass.</p>
        <a href="https://external.secrets.ngiris.com/" target="_blank">
          <button pButton type="button" icon="pi pi-share-alt" label="Go to Site"></button>
        </a>
      </p-card>
    </div>
  </p-panel>
  