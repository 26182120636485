<div
  class="layout-wrapper"
  [ngClass]="{
    'layout-overlay': isOverlay(),
    'layout-static': isStatic(),
    'layout-theme-light': !config.dark,
    'layout-theme-dark': config.dark,
    'layout-overlay-sidebar-active': overlayMenuActive,
    'layout-static-sidebar-inactive': staticMenuInactive,
    'layout-mobile-sidebar-active': menuActiveMobile,
    'p-ripple-disabled': !config.ripple,
    'p-input-filled': config.inputStyle === 'filled'
  }"
>
  <app-topbar></app-topbar>
  <div class="layout-sidebar">
    <app-menu></app-menu>
  </div>
  <div class="layout-main-container">
    <div class="layout-main">
      <div class="container">
        <!--This is to avoid reload during acquireTokenSilent() because of hidden iframe -->
        <router-outlet *ngIf="!isIframe"></router-outlet>
      </div>
    </div>
    <app-footer></app-footer>
  </div>
  <app-config></app-config>
  <div *ngIf="menuActiveMobile" class="layout-mask p-component-overlay"></div>
</div>
