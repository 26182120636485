import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ChangeDetectionStrategy, isDevMode, Inject } from '@angular/core';
import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { InteractionStatus, RedirectRequest } from '@azure/msal-browser';
import { PrimeNGConfig } from 'primeng/api';
import { Subject, filter, takeUntil } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})

export class AppComponent implements OnInit {
  title = 'Iris Lens';



  products: any[] = [];

  menuMode = 'static';

  constructor(private http: HttpClient, private primengConfig: PrimeNGConfig) {
    // console.log(isDevMode());
  }

  sidebarVisible: boolean = true;

  ngOnInit() {
    this.primengConfig.ripple = true;
    document.documentElement.style.fontSize = '14px';
  }
}
