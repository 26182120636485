import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Message, MessageService } from 'primeng/api';
import { SDLCReport, SDCLReportPractice, SDLCReportExternalPenTest } from 'src/app/api/sdlc-maturity'; 
import { ProductSDLCService } from 'src/app/layout/service/product/product-sdlc.service';
import { MeterItem } from 'primeng/metergroup';

@Component({
  selector: 'app-sdlc-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})

export class SDLCDashboardComponent implements OnInit {

  productId: string = '';
  reportedPractices: SDCLReportPractice[] = [];
  reportedPenTests: SDLCReportExternalPenTest[] = [];
  tierName: string = '';
  maturityPct: number = 0;
  isLoading = true;
  messages: Message[] = [];
  constructor(private route: ActivatedRoute, private productSDLCService: ProductSDLCService, private messageService: MessageService) { }

  ngOnInit() {

    //this.messages = [{ severity: 'info', summary: 'Information', detail: 'Certain SDLC practices are mandatory and, depending on the tier, there is an expectation of how mature the level of adoption is for each product. Over time, expectations will rise and this will be reflected in the report. This dashboard indicates which SDLC practices are currently being achieved to at least the desired maturity level for this product, and which are falling short.' }];


      this.route.params.subscribe(params => {

        this.productId = params['id'];

        this.productSDLCService.getSdlcMaturtyReportByProduct(this.productId).subscribe((maturityReports) => {
          if (maturityReports) {
            const maturityReport = maturityReports[0];
            this.tierName = maturityReport?.tierName || '';
            maturityReport?.productSDLCs?.forEach((productSDLC: SDCLReportPractice) => {
              this.reportedPractices.push(productSDLC);
            });
            console.log(maturityReport);
            const pt = maturityReport?.externalPentest;
            console.log(pt);
            if (pt) {

              this.reportedPenTests.push(pt);
            } else {
              const pt: SDLCReportExternalPenTest = {
                sdlcPracticeName: 'Ext. Penetration Test',
                progressName: 'N/A',
                status: 'Not Achieving'
              };
              this.reportedPenTests.push(pt);
            }

            this.calculateMaturity();
            this.isLoading = false;
          } else {
            this.maturityPct = 0;
            this.isLoading = false;
          }
        });


        //this.productService.getProductById(this.productId).subscribe((product) => {

          //console.log(product);
          //const tier = product.ProductMetric?.TierId;
          //this.tierName = product.ProductMetric?.TierName || '';

          //this.productSDLCService.getSDLCPractices().subscribe((practices) => {

          //  if (practices) {
          //    //loop through each practice
          //    practices.forEach((practice: any) => {
          //      //Check if the practice is included in the SDLC Maturity Report
          //      if (practice.Included == true) {
          //        //create a new ReportedPractice object

          //        this.productSDLCService.getSDLCPracticeMaturitiesByTier(practice.Id, tier).subscribe((maturities) => {

          //          let reportedPractice: ReportedPractice = {
          //            name: practice.Name,
          //            expectedMaturity: maturities[0]?.MinimumMaturityLevelId,
          //            expectedMaturityName: maturities[0]?.MinimumMaturityLevelName,
          //            actualMaturityName: 'None',
          //            status: 'Not Achieving'
          //          };

          //          this.reportedPractices.push(reportedPractice);

          //        });
          //      }
          //    });
          //  }

          //  this.productSDLCService.getSdlcMaturtyReportByProduct(this.productId).subscribe((maturityReports) => {
          //    if (maturityReports) {
          //      const maturityReport = maturityReports[0];
          //      maturityReport?.productSDLCs?.forEach((productSDLC: SDCLReportPractice) => {

          //        this.reportedPractices.forEach((reportedPractice: ReportedPractice) => {

          //          if (reportedPractice.name == productSDLC.sdlcPracticeName) {
          //            if (productSDLC.actualMaturity) {
          //              reportedPractice.actualMaturityName = productSDLC.actualMaturity;
          //            }
          //            if (productSDLC.status) {
          //              reportedPractice.status = productSDLC.status;
          //            }
          //          }

          //        });

          //        this.calculateMaturity();
          //        this.isLoading = false;

          //      });
          //    } else {
          //      this.maturityPct = 0;
          //      this.isLoading = false;
          //    }

          //  });           
          //});
        //});
      });
  };

  calculateMaturity() {

    let total: number = 0;
    let achieved: number = 0;


    this.reportedPractices.forEach((practice: SDCLReportPractice) => {

      if (practice.status != 'Exempt') {
        total++;
      }

      if(practice.status == 'Achieving') {
        achieved++;
      }
    });

    this.reportedPenTests.forEach((practice: SDLCReportExternalPenTest) => {

      if (practice.status != 'Exempt') {
        total++;
      }

      if (practice.status == 'Achieving' || practice.status == 'In Progress') {
        achieved++;
      }
    });

    this.maturityPct = (achieved / total) * 100;

  }

  tagify(option: string | undefined): string {
    if (!option) {
      return '';
    }
    let tagValue = option.trim();
    tagValue = tagValue.replace(/\s+/g, '_');
    tagValue = tagValue.replace(/[^a-zA-Z0-9_]/g, '');
    return tagValue;
  }

  cleanDate(date: string | undefined): string {

    if(!date) {
      return '';
    }

    function parseDate(dateStr: string): Date {
      const [day, month, year] = dateStr.split('-').map(Number);
      // Months are zero-indexed in JavaScript Date objects (0 = January, 11 = December)
      return new Date(year, month - 1, day);
    }

    const firstDateObj = parseDate(date);
    return firstDateObj.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    }).replace('.', ''); // Removes the period from the abbreviated month

  };

  getOverdue(duedate: string | undefined): string {

    if (!duedate) {
    return '';
  }

    // Function to parse date strings in DD-MM-YYYY format
    function parseDate(dateStr: string): Date {
      const [day, month, year] = dateStr.split('-').map(Number);
      // Months are zero-indexed in JavaScript Date objects (0 = January, 11 = December)
      return new Date(year, month - 1, day);
    }

    const firstDateObj = parseDate(duedate);
    const secondDateObj = new Date();

  // Calculate the difference in milliseconds
    const diffTime = secondDateObj.getTime() - firstDateObj.getTime();

  // Convert the difference from milliseconds to days
  const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));

  return `${diffDays} days`;
}

}
