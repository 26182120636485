<h1>Report Subscriptions</h1>

<p-toast></p-toast>

<!-- Add Subscription Button -->
<div class="flex justify-content-end mb-2">
  <button
    pButton
    label="Add Subscription"
    icon="pi pi-plus"
    class="p-button-success mb-2"
    (click)="openAddSubscriptionDialog()"
  ></button>
</div>

<!-- Subscriptions Table -->
<p-table
  [value]="subscriptions"
  [paginator]="true"
  [rows]="20"
  [loading]="loading"
  [filterDelay]="300"
  responsiveLayout="scroll"
  [showCurrentPageReport]="true"
  currentPageReportTemplate="Showing {first} to {last} of {totalRecords} subscriptions"
>
  <ng-template pTemplate="header">
    <tr>
      <th pSortableColumn="SuiteName">
        Type
      </th>
      <th>
        Name
      </th>
      <th style="min-width: 20rem; width: 20rem;">
        <div class="flex justify-content-between align-items-center">
            Report Type
          <p-columnFilter 
            type="text" 
            field="ReportTypeName" 
            matchMode="equals" 
            display="menu" 
            placeholder="Search by report type" 
            (click)="$event.stopPropagation()"
          >
            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
              <p-dropdown 
                [ngModel]="value" 
                [options]="reportTypes" 
                optionLabel="Name" 
                optionValue="Name" 
                (onChange)="filter($event.value)" 
                placeholder="Any" 
                [style]="{ 'min-width': '6rem' }"
              >
                <ng-template let-option pTemplate="item">
                  <span [class]="'report-type-badge'" [ngStyle]="{'background-color': option.Colour}">
                    {{ option.Name }}
                  </span>
                </ng-template>
              </p-dropdown>
            </ng-template>
          </p-columnFilter>
        </div>
      </th>
      <th>Actions</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-subscription>
    <tr>
      <td>
        <span
          class="badge"
          [ngClass]="subscription.SuiteId ? 'suite-badge' : 'product-badge'"
        >
          {{ subscription.SuiteId ? 'Suite' : 'Product' }}
        </span>
      </td>
      <td>{{ subscription.SuiteId ? subscription.SuiteName : subscription.ProductName }}</td>
      <td>
        <span
          class="badge report-type-badge"
          [ngStyle]="{ 'background-color': subscription.ReportTypeColour }"
        >
          {{ subscription.ReportTypeName }}
        </span>
      </td>
      
      <td>
        <button
          pButton
          label="Unsubscribe"
          icon="pi pi-trash"
          class="p-button-danger p-button-rounded p-button-sm"
          (click)="confirmUnsubscribe(subscription.Id)"
        ></button>
      </td>
      
    </tr>
  </ng-template>
</p-table>

<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>


<!-- Add Subscription Dialog -->
<p-dialog
  header="Add Subscription"
  [(visible)]="addSubscriptionDialog"
  [closable]="false"
  [modal]="true"
  [style]="{ width: '700px' }"
  [closable]="true"
  (onHide)="onDialogClose()"
>
<div *ngIf="step === 1" class="step-container">
  <h3>Step 1: Select Subscription Type</h3>
  <p>Select whether you want to subscribe to a Product or a Suite report.</p>
  <div class="field-radiobutton">
    <p-radioButton
      name="type"
      value="Product"
      label="Product"
      [(ngModel)]="selectedType"
      inputId="product"
    ></p-radioButton>
  </div>
  <div class="field-radiobutton">
    <p-radioButton
      name="type"
      value="Suite"
      label="Suite"
      [(ngModel)]="selectedType"
      inputId="suite"
    ></p-radioButton>
  </div>
  <div class="dialog-buttons">
    <button pButton label="Next" [disabled]="!selectedType" (click)="step = 2"></button>
  </div>
</div>


<div *ngIf="step === 2" class="step-container">
  <h3>Step 2: Select {{ selectedType }}</h3>
  <p>Select a {{ selectedType }} from the list below.</p>
  <p-dropdown
    [options]="selectedType === 'Product' ? products : suites"
    optionLabel="Name"
    [(ngModel)]="selectedProductOrSuite"
    [filter]="true"
    filterPlaceholder="Search..."
    [style]="{ width: '100%' }"
    appendTo="body"
  ></p-dropdown>
  <div class="dialog-buttons">
    <button pButton label="Back" (click)="step = 1"></button>
    <button
      pButton
      label="Next"
      [disabled]="!selectedProductOrSuite"
      (click)="step = 3"
    ></button>
  </div>
</div>


<div *ngIf="step === 3" class="step-container">
  <!-- Step Title -->
  <h3>Step 3: Select Report Type</h3>
  <p>Select a report type from the list below.</p>

  <!-- Report Type Listbox -->
  <p-listbox
    [options]="reportTypes"
    optionLabel="Name"
    [(ngModel)]="selectedReportType"
    [style]="{ width: '100%' }"
    appendTo="body"
  ></p-listbox>

  <!-- Buttons -->
  <div class="dialog-buttons">
    <button pButton label="Back" (click)="step = 2"></button>
    <button
      pButton
      label="Subscribe"
      [disabled]="!selectedReportType"
      class="p-button-success"
      (click)="addSubscription()"
    ></button>
  </div>
</div>

</p-dialog>
