
<div class="flex gap-2 align-items-center">
    <h1>Pentests</h1>
    <!-- <a href="" target="_blank">
        <p-button icon="pi pi-question"></p-button>
    </a> -->
</div>

<div class="flex gap-3 mb-2" style="justify-content: flex-end">
    <button pButton pRipple label="View Docs" class="p-button-info" [disabled]="!hasSelectedOption" (click)="redirectToSPLibrary()"></button>
    <div class="button-container" style="position: relative; display: inline-block;">
        <button pButton pRipple label="View Findings" class="p-button-info" [disabled]="!hasSelectedOption" (click)="showPTT()"></button>
        <span class="badge" *ngIf="hasSelectedOption">{{ this.pentestPTT.length }}</span>
    </div>
    <button pButton pRipple label="Request a Pentest" icon="pi pi-plus" class="p-button-success" (click)="openNew()"></button>
    <p-toast></p-toast>
</div>

<p-splitter [panelSizes]="[25, 75]" [style]="{ height: 'auto' }" styleClass="mb-5">
    <ng-template pTemplate let-splitter1>
        <div class="col flex flex-column align-items-center gap-3" style="height: 100%; overflow-y: auto;">
            <div [style.background-color]="pentest.Id === selectedPentestId ? 'var(--primary-color)' : 'inherit'" class="card mb-0 border-round w-full" *ngFor="let pentest of visibleProductPentests">
                <div class="flex justify-content-between align-items-center mb-3 gap-5">

                    <!-- <div class="flex align-items-center justify-content-center border-round" [ngStyle]="{width: '6.5rem', height: '6.5rem'}">
                        <img alt="" width="100%">
                    </div> -->
                    <div>
                        <span [style.color]="pentest.Id === selectedPentestId ? 'var(--primary-color-text)' : 'inherit'" class="font-bold text-xl mb-3">Id: {{ pentest.Id }}</span>
                        <div [style.color]="pentest.Id === selectedPentestId ? 'var(--primary-color-text)' : 'inherit'" class="font-bold mb-1">Status: {{ pentest.ProgressName}} </div>
                        <div [style.color]="pentest.Id === selectedPentestId ? 'var(--primary-color-text)' : 'inherit'" class="font-bold mb-1">Requested By: {{ pentest.RequestedByName }}</div>
                    </div>
                    <div class="flex flex-column gap-2">
                        <button [style.color]="pentest.Id === selectedPentestId ? 'var(--primary-color-text)' : 'inherit'" pButton icon="pi pi-chevron-right" class="p-button-outlined p-button-raised" *ngIf="pentest.Id !== undefined" (click)="showPentestDetails(pentest.Id)"></button>
                        <button *appHasRole="['Lens.Admin']" pButton icon="pi pi-trash" class="p-button-outlined p-button-raised p-button-danger" (click)="deletePentest(pentest)"></button>
                    </div>
                    
                </div>
            </div>
        <p-paginator [rows]="pageSize" [totalRecords]="productPentests.length" (onPageChange)="onPageChange($event)"></p-paginator>
        </div>
    </ng-template>

    <ng-template pTemplate let-splitter2>
        <div class="col flex justify-content-center">
            <div class="col-12">
                <div class="card">
                    <div *ngIf="!hasSelectedOption">
                        <p-messages [(value)]="messages" [enableService]="false" [closable]="true"></p-messages>
                    </div>

                    <div [hidden]="!hasSelectedOption">
                        <h5>Pentest Form</h5>
                        <div class="p-fluid p-formgrid grid">
                        <div class="field col-3 md:col-3">
                            <label for="pentestId">Pentest Id</label>
                            <input type="text" pInputText id="pentestId" [(ngModel)]="editedProductPentest.Id" [readOnly]="true" [disabled]="true" >
                        </div>
                        <div class="col-12"></div>
                        <div class="field col-12 md:col-6">
                            <label for="pentestProgress">Progress</label>
                            <p-dropdown appendTo="body" [(ngModel)]="editedProductPentest.ProgressId" inputId="ProgressId" [options]="pentestProgresses" placeholder="Select" optionValue="Id">
                                <ng-template pTemplate="selectedItem">
                                    <span *ngIf="editedProductPentest.ProgressId">{{ getPentestProgressNameById(editedProductPentest.ProgressId) }}</span>
                                </ng-template>
                                <ng-template let-option pTemplate="item">
                                    <span>{{option.Name}}</span>
                                </ng-template>
                            </p-dropdown>
                        </div>
                        <div class="field col-12 md:col-6">
                            <label for="pentestRisk">Risk</label>
                            <p-dropdown appendTo="body" [(ngModel)]="editedProductPentest.RiskId" inputId="PentestRiskId" [options]="pentestRisks" placeholder="Select" optionValue="Id">
                                <ng-template pTemplate="selectedItem">
                                    <span *ngIf="editedProductPentest.RiskId">{{ getPentestRiskNameById(editedProductPentest.RiskId) }}</span>
                                </ng-template>
                                <ng-template let-option pTemplate="item">
                                    <span>{{option.Name}}</span>
                                </ng-template>
                            </p-dropdown>
                        </div>
                        <div class="field col-12 md:col-4">
                            <label for="testStartDate">Test start date</label>
                            <p-calendar [showIcon]="true" id="testStartDate" dateFormat="dd/mm/yy" [(ngModel)]="editedProductPentest.TestDate"></p-calendar>
                        </div>
                        <div class="field col-12 md:col-6">
                            <label for="testDuration">Test Duration</label>
                            <input type="number" pInputText id="testDuration" [(ngModel)]="editedProductPentest.TestDuration">
                        </div>
                        
                        <div class="field col-12 md:col-4">
                            <label for="devWorkCompleted">Dev work completed</label>
                            <p-calendar [showIcon]="true" id="devWorkCompleted" dateFormat="dd/mm/yy" [(ngModel)]="editedProductPentest.RemediationsCompleted"></p-calendar>
                        </div>
                        <div class="field col-12"></div>
                        <div class="field col-8 md:col-4">
                            <label for="retestStartDate">Retest start date</label>
                            <p-calendar [showIcon]="true" id="retestStartDate" dateFormat="dd/mm/yy" [(ngModel)]="editedProductPentest.RetestDate"
                                [disabled]="!editedProductPentest.WithRetest"></p-calendar>
                        </div>
                        <div class="field col-4 md:6">
                            <label for="switch">With retest</label>
                            <div>
                                <p-inputSwitch id="switch" [(ngModel)]="editedProductPentest.WithRetest"></p-inputSwitch>
                            </div>
                        </div>
                        <div class="field col-12 md:col-6">
                            <label for="retestDuration">Retest duration (days)</label>
                            <input type="number" pInputText id="testDuration" [(ngModel)]="editedProductPentest.TestDuration" [disabled]="!editedProductPentest.WithRetest">
                        </div>
                        <div class="field col-12 md:col-12">
                            <!-- <p-confirmDialog></p-confirmDialog> -->
                            <label for="primaryContact">Primary Contacts</label>
                            <div>
                                <ng-container *ngFor="let contact of pentestContacts">
                                    <p-chip styleClass="m-1">
                                        <img src="assets/layout/images/iris-circle-logo.png" alt="" width="60px" height="" class="shadow-2 border-circle">
                                        <span pTooltip={{contact.Mail}} tooltipPosition="bottom" [hideDelay]="2000">{{ contact.Name }}</span>
                                        <button pButton icon="pi pi-times" class="p-button-rounded p-button-danger p-button-text" (click)="removeUser(contact)"></button>
                                    </p-chip>
                                </ng-container>
                                <button pButton pRipple icon="pi pi-plus" class="p-button-success p-button-rounded" (click)="openUserDialog('addPrimaryContact')" [disabled]="!hasSelectedOption"></button>
                            </div>
                        </div>
                        <div class="field col-12 md:col-6">
                            <label for="Requirements">Requirements</label>
                            <p-dropdown appendTo="body" [(ngModel)]="editedProductPentest.PreReqxId" inputId="PreReqxId" [options]="pentestPreReqx" placeholder="Select" optionValue="Id">
                                <ng-template pTemplate="selectedItem">
                                    <span *ngIf="editedProductPentest.PreReqxId">{{ getPentestPreReqxNameById(editedProductPentest.PreReqxId) }}</span>
                                </ng-template>
                                <ng-template let-option pTemplate="item">
                                    <span>{{option.Name}}</span>
                                </ng-template>
                            </p-dropdown>
                        </div>
                        <div class="field col-12 md:col-12">
                            <p-confirmDialog></p-confirmDialog>
                            <label for="primaryContact">PreReq User</label>
                            <div>
                                <ng-container *ngFor="let contact of preReqxContacts">
                                    <p-chip styleClass="m-1">
                                        <img src="assets/layout/images/iris-circle-logo.png" alt="" width="60px" height="" class="shadow-2 border-circle">
                                        <span pTooltip={{contact.Mail}} tooltipPosition="bottom" [hideDelay]="2000">{{ contact.Name }}</span>
                                        <button pButton icon="pi pi-times" class="p-button-rounded p-button-danger p-button-text" (click)="removePreReqUser(contact)"></button>
                                    </p-chip>
                                </ng-container>
                                <button pButton pRipple icon="pi pi-plus" class="p-button-success p-button-rounded" (click)="openUserDialog('addPreReqUserContact')" [disabled]="!hasSelectedOption"></button>
                            </div>
                        </div>
                        <!-- <div class="field col-12 md:col-12">
                            <p-confirmDialog></p-confirmDialog>
                            <label for="confirmedBy">Confirmed By</label>
                            <div>
                                <ng-container>
                                    <p-chip styleClass="m-1">
                                        <img src="assets/layout/images/iris-circle-logo.png" alt="" width="60px" height="" class="shadow-2 border-circle">
                                        <span tooltipPosition="bottom"></span>
                                        <button pButton icon="pi pi-times" class="p-button-rounded p-button-danger p-button-text"></button>
                                    </p-chip>
                                </ng-container>
                                <button pButton pRipple icon="pi pi-plus" class="p-button-success p-button-rounded"></button>
                            </div>
                        </div> -->
                        <div class="field col-12 md:col-12">
                            <label for="comment">Comment</label>
                            <div><textarea [(ngModel)]="editedProductPentest.Comments" pInputTextarea style="width: 100%" name="" id="" cols="30" rows="5" [(ngModel)]="editedProductPentest.Comments"></textarea></div>
                        </div>
                        <div class="field col-12 md:col-6">
                            <label for="provider">Provider</label>
                            <p-dropdown appendTo="body" [(ngModel)]="editedProductPentest.ProviderId" inputId="ProviderId"
                                [options]="pentestProviders" placeholder="Select" optionValue="Id">
                                <ng-template pTemplate="selectedItem">
                                    <span *ngIf="editedProductPentest.ProviderId">{{ getPentestProviderNameById(editedProductPentest.ProviderId) }}</span>
                                </ng-template>
                                <ng-template let-option pTemplate="item">
                                    <span>{{option.Name}}</span>
                                </ng-template>
                            </p-dropdown>
                        </div>
                        <div class="field col-12 md:col-6">
                            <label for="cost">Cost</label>
                            <input type="number" pInputText id="cost" [(ngModel)]="editedProductPentest.Cost">
                        </div>
                        <div class="field col-12 md:col-6">
                            <label for="timezone">Purchase Order</label>
                            <input type="text" pInputText id="timezone" [(ngModel)]="editedProductPentest.PO">
                        </div>
                        <div class="field col-12 md:col-6">
                            <label for="requestedBy">Requested By</label>
                            <p-dropdown appendTo="body" [(ngModel)]="editedProductPentest.RequestedById" inputId="RequestedById" [options]="pentestRequestedBy" placeholder="Select" optionValue="Id">
                                <ng-template pTemplate="selectedItem">
                                    <span *ngIf="editedProductPentest.RequestedById">{{ getPentestRequestedByNameById(editedProductPentest.RequestedById) }}</span>
                                </ng-template>
                                <ng-template let-option pTemplate="item">
                                    <span>{{option.Name}}</span>
                                </ng-template>
                            </p-dropdown>
                        </div>
                                        </div>
                                        <div style="display: flex; justify-content: flex-end;">
                        <p-button label="Submit" (click)="onSubmit()" [disabled]="!hasSelectedOption"></p-button>
                                        </div>
                    </div>
            </div>
        </div>
    </div>
    </ng-template>
</p-splitter>



<p-dialog [(visible)]="pentestDialog" [style]="{width: '750px'}" header="Request new Pentest" [modal]="true" class="p-fluid">
    <ng-template pTemplate="content">

        <div class="p-fluid p-formgrid grid">
            <div class="field col-6">
                <label for="pentestProgress">Pentest Progress</label>
                <p-dropdown appendTo="body" [(ngModel)]="productPentest.ProgressId" inputId="ProgressId" 
                    [options]="pentestProgresses" placeholder="Select" optionValue="Id" [required]="true" [ngClass]="{'ng-invalid ng-dirty' : submitted && !productPentest.ProgressId}">
                    <ng-template pTemplate="selectedItem">
                        <span *ngIf="productPentest.ProgressId">{{ getPentestProgressNameById(productPentest.ProgressId) }}</span>
                    </ng-template>
                    <ng-template let-option pTemplate="item">
                        <span>{{option.Name}}</span>
                    </ng-template>
                </p-dropdown>
            </div>

            <div class="field col-12 md:col-6">
                <label for="pentestRisk">Risk</label>
                <p-dropdown appendTo="body" [(ngModel)]="productPentest.RiskId" inputId="PentestRiskId" 
                    [options]="pentestRisks" placeholder="Select" optionValue="Id">
                    <ng-template pTemplate="selectedItem">
                        <span *ngIf="productPentest.RiskId">{{ getPentestRiskNameById(productPentest.RiskId) }}</span>
                    </ng-template>
                    <ng-template let-option pTemplate="item">
                        <span>{{option.Name}}</span>
                    </ng-template>
                </p-dropdown>
            </div>

            <div class="field col-12 md:col-4">
                <label for="testStartDate">Test start date</label>
                <p-calendar [showIcon]="true" id="testStartDate" dateFormat="dd/mm/yy" [(ngModel)]="productPentest.TestDate"
                    [required]="true" [ngClass]="{'ng-invalid ng-dirty' : submitted && !productPentest.TestDate}"></p-calendar>
            </div>

            <div class="field col-12 md:col-6">
                <label for="testDuration">Test Duration</label>
                <input type="number" pInputText id="testDuration" [(ngModel)]="productPentest.TestDuration">
            </div>

            <div class="field col-12 md:col-4">
                <label for="retestStartDate">Retest start date</label>
                <p-calendar [showIcon]="true" id="retestStartDate" dateFormat="dd/mm/yy" [(ngModel)]="productPentest.RetestDate"></p-calendar>
            </div>

            <div class="field col-12 md:col-6">
                <label for="retestDuration">Retest duration (days)</label>
                <input type="number" pInputText id="testDuration" [(ngModel)]="productPentest.RetestDuration">
            </div>

            <div class="field col-12 md:col-12">
                <p-confirmDialog></p-confirmDialog>
                <label for="primaryContact">Primary Contacts</label>
                <div>
                    <ng-container *ngFor="let contact of pentestNewContacts">
                        <p-chip styleClass="m-1">
                            <img src="assets/layout/images/iris-circle-logo.png" alt="" width="60px" height="" class="shadow-2 border-circle">
                            <span pTooltip={{contact.mail}} tooltipPosition="bottom" [hideDelay]="2000">{{ contact.displayName }}</span>
                            <button pButton icon="pi pi-times" class="p-button-rounded p-button-danger p-button-text" (click)="removeNewPentestContact(contact)"></button>
                        </p-chip>
                    </ng-container>
                    <button pButton pRipple icon="pi pi-plus" class="p-button-success p-button-rounded" (click)="openUserDialog('addNewPentestContact')"></button>
                </div>
            </div>

            <div class="field col-12 md:col-12">
                <p-confirmDialog></p-confirmDialog>
                <label for="prerequser">Pre Req User</label>
                <div>
                    <ng-container *ngFor="let contact of preReqxNewContacts">
                        <p-chip styleClass="m-1">
                            <img src="assets/layout/images/iris-circle-logo.png" alt="" width="60px" height="" class="shadow-2 border-circle">
                            <span pTooltip={{contact.mail}} tooltipPosition="bottom" [hideDelay]="2000">{{ contact.displayName }}></span>
                            <button pButton icon="pi pi-times" class="p-button-rounded p-button-danger p-button-text" (click)="removeNewPreReqUserContact(contact)"></button>
                        </p-chip>
                    </ng-container>
                    <button pButton pRipple icon="pi pi-plus" class="p-button-success p-button-rounded" (click)="openUserDialog('addNewPreReqUserContact')"></button>
                </div>
            </div>

            <div class="field col-12 md:col-6">
                <label for="Requirements">Requirements</label>
                <p-dropdown appendTo="body" [(ngModel)]="productPentest.PreReqxId" inputId="PreReqxId" [options]="pentestPreReqx" placeholder="Select" optionValue="Id">
                    <ng-template pTemplate="selectedItem">
                        <span *ngIf="productPentest.PreReqxId">{{ getPentestPreReqxNameById(productPentest.PreReqxId) }}</span>
                    </ng-template>
                    <ng-template let-option pTemplate="item">
                        <span>{{option.Name}}</span>
                    </ng-template>
                </p-dropdown>
            </div>

            <!-- <div class="field col-12 md:col-12">
                <p-confirmDialog></p-confirmDialog>
                <label for="confirmedBy">Confirmed By</label>
                <div>
                    <ng-container>
                        <p-chip styleClass="m-1">
                            <img src="assets/layout/images/iris-circle-logo.png" alt="" width="60px" height="" class="shadow-2 border-circle">
                            <span tooltipPosition="bottom"></span>
                            <button pButton icon="pi pi-times" class="p-button-rounded p-button-danger p-button-text"></button>
                        </p-chip>
                    </ng-container>
                    <button pButton pRipple icon="pi pi-plus" class="p-button-success p-button-rounded"></button>
                </div>
            </div> -->

            <div class="field col-12 md:col-12">
                <label for="comment">Comment</label>
                <div><textarea [(ngModel)]="productPentest.Comments" pInputTextarea style="width: 100%" name="" id="" cols="30" rows="5"></textarea></div>
            </div>

            <div class="field col-12 md:6">
                <label for="switch">With retest</label>
                <p-inputSwitch id="switch" [(ngModel)]="productPentest.WithRetest"></p-inputSwitch>
            </div>

            <div class="field col-12 md:col-6">
                <label for="requestedBy">Requested By</label>
                <p-dropdown appendTo="body" [(ngModel)]="productPentest.RequestedById" inputId="RequestedById" 
                    [options]="pentestRequestedBy" placeholder="Select" optionValue="Id" [required]="true" [ngClass]="{'ng-invalid ng-dirty' : submitted && !productPentest.RequestedById}">
                    <ng-template pTemplate="selectedItem">
                        <span *ngIf="productPentest.RequestedById">{{ getPentestRequestedByNameById(productPentest.RequestedById) }}</span>
                    </ng-template>
                    <ng-template let-option pTemplate="item">
                        <span>{{option.Name}}</span>
                    </ng-template>
                </p-dropdown>
            </div>
        </div>
    </ng-template>

    <ng-template pTemplate="footer">
        <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="hideDialog()"></button>
        <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text" (click)="saveNewPentest()"></button>
    </ng-template>
</p-dialog>


<p-dialog [(visible)]="userDialog" [modal]="true" [style]="{width: '750px'}" header="Add new user" [contentStyle]="{'overflow': 'visible'}" [modal]="true" class="p-fluid">
    <ng-template pTemplate="content">
        <div class="field">
            <p-dropdown [options]="users" scrollHeight="300px" [(ngModel)]="selectedUser" [style]="{'width':'100%'}" optionLabel="displayName" [filter]="true" (onFilter)="searchUsers($event)" [showClear]="true" placeholder="Select a User">
                <ng-template pTemplate="selectedItem">
                    <div class="flex align-items-center gap-2" *ngIf="selectedUser">
                        <div>{{ selectedUser.displayName }}</div>
                    </div>
                </ng-template>
                <ng-template let-user pTemplate="item">
                    <div class="flex align-items-center gap-2">
                        <img src="assets/layout/images/iris-circle-logo.png" alt="" width="60px" height="" class="shadow-2 border-circle">
                        <div class="flex w-full gap-2 flex-column p-4">
                            <div>{{ user.displayName }}</div>
                            <div>({{ user.mail }})</div>
                        </div>
                    </div>
                </ng-template>
            </p-dropdown>
        </div>
    </ng-template>

    <ng-template pTemplate="footer">
        <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="hideUserDialog()"></button>
        <button *ngIf="userDialogAction === 'addPrimaryContact'" pButton pRipple label="Add" icon="pi pi-check" class="p-button-text" (click)="addPentestContact()"></button>
        <button *ngIf="userDialogAction === 'addPreReqUserContact'" pButton pRipple label="Add" icon="pi pi-check" class="p-button-text" (click)="addPreReqUserContact()"></button>
        <button *ngIf="userDialogAction === 'addNewPentestContact'" pButton pRipple label="Add" icon="pi pi-check" class="p-button-text" (click)="addNewPentestContact()"></button>
        <button *ngIf="userDialogAction === 'addNewPreReqUserContact'" pButton pRipple label="Add" icon="pi pi-check" class="p-button-text" (click)="addNewPreReqUserContact()"></button>

    </ng-template>
</p-dialog>



<p-table [value]="pentestDocuments" [tableStyle]="{'min-width': '60rem'}" dataKey="id" editMode="row">

    <ng-template pTemplate="caption">
        <div class="flex align-items-center justify-content-between">
            Documents
            <p-fileUpload [disabled]="!hasSelectedOption" mode="basic" [auto]="true" name="file[]" url="https://www.primefaces.org/cdn/api/upload.php" accept=".pdf,.doc,.docx,.jpg,.jpeg,.png"  chooseLabel="Upload" (onUpload)="onFileUpload($event)"></p-fileUpload>
        </div>
    </ng-template>
    <ng-template pTemplate="header">
        <tr>
            <th style="width:20%">Image</th>
            <th style="width:20%">Name</th>
            <th style="width:20%">Doc Type</th>
            <th style="width:20%">Doc Status</th>
            <th style="width:20%">Comment</th>
            <th style="width:20%">Actions</th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-pentestDocument let-editing="editing" let-ri="rowIndex">
        <tr [pEditableRow]="pentestDocument">
            <td>Thumbnail</td>
            <td>{{pentestDocument.name}}</td>
            <td>
                <p-cellEditor>
                    <ng-template pTemplate="input">
                        <p-dropdown 
                            [options]="docTypes" 
                            appendTo="body" 
                            [(ngModel)]="selectedDocType"
                            [style]="{'width':'100%'}"
                            placeholder="Select a Doc Type"
                        ></p-dropdown>
                    </ng-template>
                    <ng-template pTemplate="output">
                        <p-tag [value]="pentestDocument['listItem']['fields']['DocType']"></p-tag>
                    </ng-template>
                </p-cellEditor>
            </td>

            <td>
                <p-cellEditor>
                    <ng-template pTemplate="input">
                        <p-dropdown 
                            [options]="docStatuses" 
                            appendTo="body" 
                            [(ngModel)]="selectedDocStatus" 
                            [style]="{'width':'100%'}"
                            placeholder="Select a Doc Status"
                        ></p-dropdown>
                    </ng-template>
                    <ng-template pTemplate="output">
                        <p-tag 
                        [value]="pentestDocument['listItem']['fields']['DocStatus']"
                        [severity]="getStatus(pentestDocument['listItem']['fields']['DocStatus'])" />
                    </ng-template>
                </p-cellEditor>
            </td>

            <td>Click View Docs above</td>

            <td>
                <!-- <a [href]="pentestDocument['@microsoft.graph.downloadUrl']" target="_blank" class="p-button p-button-text" role="button">
                    <span class="pi pi-download"></span>
                </a> -->
                <a [href]="pentestDocument['webUrl']" target="_blank" class="p-button p-button-text" role="button">
                    <span class="pi pi-external-link"></span>
                </a>

                <div class="flex flex-row align-items-center justify-content-center gap-2">
                    <button 
                        *ngIf="!editing" 
                        pButton 
                        pRipple 
                        type="button" 
                        pInitEditableRow 
                        icon="pi pi-pencil" 
                        
                        class="p-button-rounded p-button-text">
                    </button>
                    <button 
                        *ngIf="editing" 
                        pButton 
                        pRipple 
                        type="button" 
                        pSaveEditableRow 
                        icon="pi pi-check" 
                        (click)="onRowEditSave(pentestDocument, selectedDocType, selectedDocStatus)" 
                        class="p-button-rounded p-button-text p-button-success mr-2">
                    </button>
                    <button 
                        *ngIf="editing" 
                        pButton pRipple 
                        type="button" 
                        pCancelEditableRow 
                        icon="pi pi-times" 
                        
                        class="p-button-rounded p-button-text p-button-danger">
                    </button>

                    <button 
                        pButton 
                        pRipple 
                        type="button" 
                        icon="pi pi-trash" 
                        class="p-button-rounded p-button-text p-button-danger" 
                        (click)="onDeleteDocument(pentestDocument)">
                    </button>
                </div>
            
            <!-- <td><p-tag [value]="product.inventoryStatus" [severity]="getSeverity(product.inventoryStatus)"></p-tag></td> -->
        </tr>
    </ng-template>
    <ng-template pTemplate="summary">
        <div class="flex align-items-center justify-content-between">
            In total there are {{pentestDocuments ? pentestDocuments.length : 0 }} documents.
        </div>
    </ng-template>
</p-table>


<p-dialog 
    header="Issues" 
    [modal]="true"
    [(visible)]="pentestPTTDialog" 
    [style]="{ width: '100rem' }"
    [maximizable]="true">
    <!-- <div style="margin-bottom: 1rem;">
        <button pButton type="button" label="Add Finding" icon="pi pi-plus" class="p-button-success" (click)="addFinding()"></button>
    </div> -->
    <p-table #dt [value]="pentestPTT" responsiveLayout="stack" [breakpoint]="'960px'" responsiveLayout="scroll" appendTo="body" [rows]="10" [paginator]="true" [rowsPerPageOptions]="[10,20,30]" [showCurrentPageReport]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" selectionMode="multiple" [rowHover]="true" dataKey="id">
        <ng-template pTemplate="header">
            <tr>
                <th *ngFor="let col of columns" [pSortableColumn]="col.field">
                    {{col.header}}
                    <p-columnFilter *ngIf="col.filter" type="text" field="{{col.field}}" display="menu" placeholder="Search by {{col.header}}" (click)="$event.stopPropagation()"></p-columnFilter>
                    <p-sortIcon [field]="col.field"></p-sortIcon>
                </th>
                <th>Actions</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-pentestPTT>
            <tr>
                <td>{{pentestPTT.ProductPentestId}}</td>
                <td>{{pentestPTT.SeverityName}}</td>
                <td>{{pentestPTT.CVSS}}</td>
                <td>{{pentestPTT.PTTProgressName}}</td>
                <td>{{ pentestPTT.Issue }}</td>
                <td>
                    <button *ngIf="pentestPTT.Remediation" pButton type="button" icon="pi pi-search" (click)="viewFullText(pentestPTT.Remediation, 'Remediation')" class="p-button-text"></button>
                </td>
                <td>{{pentestPTT.PriorityName}}</td>
                <td>{{ pentestPTT.FixDate | date:'dd/MM/yyyy' }}</td>
                <td>{{pentestPTT.FixDateNum}}</td>
                <td>{{pentestPTT.ComplexityName}}</td>
                <td>{{pentestPTT.XRef}}</td>
                <td>{{pentestPTT.Ref}}</td>
                <td>{{pentestPTT.RefOld}}</td>
                <td>{{pentestPTT.CatName}}</td>
                <td>
                    <ul>
                        <li *ngFor="let user of pentestPTT.AssignedTo">{{user.Name}}</li>
                    </ul>
                </td>
                <td>
                    <button *ngIf="pentestPTT.Comment" pButton type="button" icon="pi pi-search" (click)="viewFullText(pentestPTT.Comment, 'Comment')" class="p-button-text"></button>
                </td>
                
                <td>{{ pentestPTT.TestDate | date:'dd/MM/yyyy' }}</td>
                <td>{{pentestPTT.NextAction}}</td>
                <td>{{pentestPTT.Vector}}</td>
                <td>
                    <div class="flex">
                        <button pButton pRipple icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2" (click)="editPTT(pentestPTT)"></button>
                        <button pButton pRipple icon="pi pi-trash" class="p-button-rounded p-button-danger mr-2" (click)="deletePTTFinding(pentestPTT.Id)"></button>
                    </div>
                </td>
            </tr>
        </ng-template>
    </p-table>
</p-dialog>

<p-dialog [(visible)]="editPTTDialog" [style]="{width: '750px'}" header="Issue Details" [modal]="true" class="p-fluid">
    <ng-template pTemplate="content">
        <div class="p-fluid p-formgrid grid">
        <div class="field col-6 sm:col-6">
            <label for="pentestId">Pentest Id</label>
            <p-inputNumber type="number" inputId="integeronly" id="pentestId" [disabled]="true" [(ngModel)]="editPentestPTT.ProductPentestId"></p-inputNumber>
        </div>

        <div class="field col-6 md:col-6">
            <label for="severity">Severity</label>
            <p-dropdown appendTo="body" [(ngModel)]="editPentestPTT.SeverityId" inputId="SeverityId" [options]="pttSeverity" placeholder="Select" optionValue="Id">
                <ng-template pTemplate="selectedItem">
                    <span *ngIf="editPentestPTT.SeverityId">{{ getSeverityNameById(editPentestPTT.SeverityId) }}</span>
                </ng-template>
                <ng-template let-option pTemplate="item">
                    <span>{{option.Name}}</span>
                </ng-template>
            </p-dropdown>
        </div>

        <div class="field col-6 md:col-6">
            <label for="cvss">CVSS</label>
            <input type="number" pInputText id="cvss" [(ngModel)]="editPentestPTT.CVSS" [step]="0.1">
        </div>

        <div class="field col-6 sm:col-6">
            <label for="progress">Progress</label>
            <p-dropdown appendTo="body" [(ngModel)]="editPentestPTT.PTTProgressId" inputId="ProgressId" [options]="pttProgress" placeholder="Select" optionValue="Id">
                <ng-template pTemplate="selectedItem">
                    <span *ngIf="editPentestPTT.PTTProgressId">{{ getProgressNameById(editPentestPTT.PTTProgressId) }}</span>
                </ng-template>
                <ng-template let-option pTemplate="item">
                    <span>{{option.Name}}</span>
                </ng-template>
            </p-dropdown>
        </div>

        <div class="field col-12">
            <label for="issue">Issue</label>
            <textarea pInputTextarea type="text" pInputText id="issue" [(ngModel)]="editPentestPTT.Issue" rows="3" cols="20"></textarea>
        </div>

        <div class="field col-12">
            <label for="remediation">Remediation</label>
            <textarea pInputTextarea type="text" pInputText id="remediation" [(ngModel)]="editPentestPTT.Remediation" rows="3" cols="20"></textarea>
        </div>

        <div class="field col-6">
            <label for="priority">Priority</label>
            <p-dropdown appendTo="body" [(ngModel)]="editPentestPTT.PriorityId" inputId="PriorityId" [options]="pttPriority" placeholder="Select" optionValue="Id">
                <ng-template pTemplate="selectedItem">
                    <span *ngIf="editPentestPTT.PriorityId">{{ getPriorityNameById(editPentestPTT.PriorityId) }}</span>
                </ng-template>
                <ng-template let-option pTemplate="item">
                    <span>{{option.Name}}</span>
                </ng-template>
            </p-dropdown>
        </div>

        <div class="field col-6">
            <label for="fixDateEta">Fix Date (ETA)</label>
            <p-calendar [showIcon]="true" id="fixDateEta" dateFormat="dd/mm/yy" [(ngModel)]="editPentestPTT.FixDate"></p-calendar>
        </div>

        <div class="field col-6">
            <label for="fixDateNum">Fix Date (Num)</label>
            <input type="number" pInputText id="fixDateNum" [(ngModel)]="editPentestPTT.FixDateNum">
        </div>

        <div class="field col-6">
            <label for="complexity">Complexity</label>
            <p-dropdown appendTo="body" [(ngModel)]="editPentestPTT.ComplexityId" inputId="ComplexityId" [options]="pttComplexity" placeholder="Select" optionValue="Id">
                <ng-template pTemplate="selectedItem">
                    <span *ngIf="editPentestPTT.ComplexityId">{{ getComplexityNameById(editPentestPTT.ComplexityId) }}</span>
                </ng-template>
                <ng-template let-option pTemplate="item">
                    <span>{{option.Name}}</span>
                </ng-template>
            </p-dropdown>
        </div>

        <div class="field col-6">
            <label for="xRef">XRef</label>
            <input type="text" pInputText id="xRef" [(ngModel)]="editPentestPTT.XRef">
        </div>

        <div class="field col-6">
            <label for="ref">Ref</label>
            <input type="text" pInputText id="ref" [(ngModel)]="editPentestPTT.Ref">
        </div>

        <div class="field col-6">
            <label for="refOld">Ref Old</label>
            <input type="text" pInputText id="refOld" [(ngModel)]="editPentestPTT.RefOld">
        </div>

        <div class="field col-6">
            <label for="complexity">Cat</label>
            <p-dropdown appendTo="body" [(ngModel)]="editPentestPTT.CatId" inputId="ComplexityId" [options]="pttCat" placeholder="Select" optionValue="Id">
                <ng-template pTemplate="selectedItem">
                    <span *ngIf="editPentestPTT.CatId">{{ getCatNameById(editPentestPTT.CatId) }}</span>
                </ng-template>
                <ng-template let-option pTemplate="item">
                    <span>{{option.Name}}</span>
                </ng-template>
            </p-dropdown>
        </div>

        <div class="field col-12">
            <label for="comment">Comment</label>
            <textarea pInputTextarea type="text" pInputText id="comment" [(ngModel)]="editPentestPTT.Comment" rows="3" cols="20"></textarea>
        </div>

        <div class="field col-6">
            <label for="testDate">Test Date</label>
            <p-calendar [showIcon]="true" id="testDate" dateFormat="dd/mm/yy" [(ngModel)]="editPentestPTT.TestDate"></p-calendar>
        </div>

        <div class="field col-12">
            <label for="nextAction">Next Action</label>
            <textarea pInputTextarea type="text" pInputText id="nextAction" [(ngModel)]="editPentestPTT.NextAction" rows="3" cols="20"></textarea>
        </div>

        <div class="field col-6">
            <label for="vector">Vector</label>
            <input type="text" pInputText id="vector" [(ngModel)]="editPentestPTT.Vector">
        </div>

        <div class="field col-12 md:col-12">
            <label for="assignedTo">Assigned to</label>
            <div>
                <ng-container *ngFor="let contact of pttContacts">
                    <p-chip styleClass="m-1">
                        <img src="assets/layout/images/iris-circle-logo.png" alt="" width="60px" height="" class="shadow-2 border-circle">
                        <span pTooltip={{contact.Mail}} tooltipPosition="bottom" [hideDelay]="2000">{{ contact.Name }}</span>
                        <button pButton icon="pi pi-times" class="p-button-rounded p-button-danger p-button-text" (click)="removePTTContact(contact)"></button>
                    </p-chip>
                </ng-container>
            </div>
            <button pButton pRipple icon="pi pi-plus" class="p-button-success p-button-rounded" (click)="openPTTContactDialog()" [disabled]="!hasSelectedOption"></button>
        </div>

        </div>
    </ng-template>

    <ng-template pTemplate="footer">
        <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="hideEditPTTDialog()"></button>
        <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text" (click)="updatePTT()"></button>
    </ng-template>
</p-dialog>


<p-dialog header="{{selectedTitle}}" [(visible)]="displayFullTextDialog" [modal]="true" [style]="{ width: '50vw' }">
    <p>{{ selectedText }}</p>
</p-dialog>






<p-dialog [(visible)]="pttContactDialog" [modal]="true" [style]="{width: '750px'}" header="Assign to User" [contentStyle]="{'overflow': 'visible'}" [modal]="true" class="p-fluid">
    <ng-template pTemplate="content">
        <div class="field">
            <p-dropdown [options]="users" scrollHeight="300px" [(ngModel)]="selectedUser" [style]="{'width':'100%'}" optionLabel="displayName" [filter]="true" (onFilter)="searchUsers($event)" [showClear]="true" placeholder="Select a User">
                <ng-template pTemplate="selectedItem">
                    <div class="flex align-items-center gap-2" *ngIf="selectedUser">
                        <div>{{ selectedUser.displayName }}</div>
                    </div>
                </ng-template>
                <ng-template let-user pTemplate="item">
                    <div class="flex align-items-center gap-2">
                        <img src="assets/layout/images/iris-circle-logo.png" alt="" width="60px" height="" class="shadow-2 border-circle">
                        <div class="flex w-full gap-2 flex-column p-4">
                            <div>{{ user.displayName }}</div>
                            <div>({{ user.mail }})</div>
                        </div>
                    </div>
                </ng-template>
            </p-dropdown>
        </div>
    </ng-template>

    <ng-template pTemplate="footer">
        <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="hidePTTContactDialog()"></button>
        <button pButton pRipple label="Add" icon="pi pi-check" class="p-button-text" (click)="addPTTContact()"></button>
    </ng-template>
</p-dialog>