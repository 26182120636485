import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, map } from "rxjs";
import { User } from "src/app/api/product";
import { environment } from "src/environments/environment";

// export interface User {
//     id: string;
//     displayName: string;
//     mail: string;
//     ProductContactGroupId?: number; // not from graph
// }

@Injectable(
    {
        providedIn: 'root'
    }
)

export class GraphExplorerService {
    baseApiUrl: string = environment.baseApiUrl;
    GRAPH_USERS_ENDPOINT = 'https://graph.microsoft.com/v1.0/users';

    constructor(private http: HttpClient) { }

    getAllADUsers(): Observable<User[]> {
        return this.http.get<any>(this.GRAPH_USERS_ENDPOINT).pipe(
            map((response) => {
            // Check if the response has a "value" property
            if (response && response.value) {
                // Return the array of objects from the "value" property
                return response.value;
            } else {
                // If there is no "value" property, return the entire response
                return response;
            }
            })
        );
    }

    searchADUsers(query: string): Observable<User[]> {
        const searchUrl = `${this.GRAPH_USERS_ENDPOINT}?$filter=startswith(displayName, '${query}') or startswith(surname, '${query}')`;
        return this.http.get<any>(searchUrl).pipe(
            map((response) => {
            // Check if the response has a "value" property
            if (response && response.value) {
                // Return the array of objects from the "value" property
                return response.value;
            } else {
                // If there is no "value" property, return the entire response
                return response;
            }
            })
        );
    }

    getUserPhoto(userId: string): Observable<Blob> {
        const photoUrl = `https://graph.microsoft.com/v1.0/users/${userId}/photo/$value`;
        return this.http.get(photoUrl, { responseType: 'blob' });
    }
}