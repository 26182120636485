import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ProductService } from './layout/service/product/products.service';

@Injectable({
  providedIn: 'root'
})
export class ProductExistsGuard implements CanActivate {

  constructor(private productService: ProductService, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
    const productId = next.params['id'];
    
    // Use ProductService to check if the product exists
    return this.productService.checkProductExists(productId).pipe(
      map(productExists => {
        if (productExists) {
          return true; // Allow navigation if the product exists
        } else {
          this.router.navigate(['/pages/notfound']); // Redirect to not-found page if the product doesn't exist
          return false;
        }
      })
    );
  }
}
