import { Component, OnInit } from '@angular/core';
import { ProductSDLCService } from 'src/app/layout/service/product/product-sdlc.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-practice',
  templateUrl: './practice.component.html'
})
export class PracticeComponent implements OnInit {

  practices: any[] = [];

  constructor(private route: ActivatedRoute, private sdlcService: ProductSDLCService) { }

  ngOnInit(): void {

    this.sdlcService.getSDLCPractices().subscribe(
      (data: any) => {
        this.practices = data;
      });

  }
}
