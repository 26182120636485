import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, map } from "rxjs";
import { ProductContact, ProductContactRequestDto, ProductReportTypeGroupDto, ReportRecipientDto, ReportType, SuiteReportRecipientDto, SuiteReportTypeGroupDto, UserSubscription, UserSubscriptionRequestDto } from "src/app/api/product";
import { environment } from "src/environments/environment";

@Injectable(
    {
        providedIn: 'root'
    }
)

export class ReportRecipientsService {
    baseApiUrl: string = environment.baseApiUrl;
    constructor(private http: HttpClient) { }

    getAllProductReportRecipients(reportType: string): Observable<ProductReportTypeGroupDto[]> {
        return this.http.get<any>(this.baseApiUrl + `/api/reports/productreportrecipients?reportType=${reportType}`).pipe(
          map((response) => {
            // Check if the response has a "value" property
            if (response && response.value) {
              // Return the array of objects from the "value" property
              return response.value;
            } else {
              // If there is no "value" property, return the entire response
              return response;
            }
          })
        );
    }

    getAllSuiteReportRecipients(reportType: string): Observable<SuiteReportTypeGroupDto[]> {
        return this.http.get<any>(this.baseApiUrl + `/api/reports/suitereportrecipients?reportType=${reportType}`).pipe(
          map((response) => {
            // Check if the response has a "value" property
            if (response && response.value) {
              // Return the array of objects from the "value" property
              return response.value;
            } else {
              // If there is no "value" property, return the entire response
              return response;
            }
          })
        );
    }

    getProductReportRecipients(productId: string): Observable<ProductReportTypeGroupDto[]> {
      return this.http.get<any>(this.baseApiUrl + `/api/reports/productreportrecipients?productId=${productId}`).pipe(
        map((response) => {
          // Check if the response has a "value" property
          if (response && response.value) {
            // Return the array of objects from the "value" property
            return response.value;
          } else {
            // If there is no "value" property, return the entire response
            return response;
          }
        })
      );
    }


    getAllUserSubscriptions(): Observable<UserSubscription[]> {
      return this.http.get<any>(this.baseApiUrl + '/api/UserSubscriptions').pipe(
        map((response) => {
          // Check if the response has a "value" property
          if (response && response.value) {
            // Return the array of objects from the "value" property
            return response.value;
          } else {
            // If there is no "value" property, return the entire response
            return response;
          }
        })
      );
    }

    getUserSubscriptions(userGraphId: string): Observable<UserSubscription[]> {
      return this.http.get<UserSubscription[]>(`${this.baseApiUrl}/api/UserSubscriptions?$filter=UserGraphId eq '${userGraphId}'`);
    }
    

    addReportRecipient(productContact: UserSubscriptionRequestDto): Observable<UserSubscriptionRequestDto> {
        return this.http.post<any>(this.baseApiUrl + '/api/UserSubscriptions', productContact);
    }

    patchProductContact(id: string, ProductContactRequestDto: any): Observable<UserSubscriptionRequestDto> {
        return this.http.patch<UserSubscriptionRequestDto>(this.baseApiUrl + `/api/UserSubscriptions(${id})`, ProductContactRequestDto);
    }
    

    deleteReportRecipient(id: number): Observable<any> {
        return this.http.delete<any>(this.baseApiUrl + `/api/UserSubscriptions/${id}`);
    }

    getAllReportTypes(): Observable<ReportType[]> {
        return this.http.get<any>(this.baseApiUrl + '/api/reporttypes').pipe(
          map((response) => {
            // Check if the response has a "value" property
            if (response && response.value) {
              // Return the array of objects from the "value" property
              return response.value;
            } else {
              // If there is no "value" property, return the entire response
              return response;
            }
          })
        );
    }
}