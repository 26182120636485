<div class="flex gap-2 align-items-center">
    <h1>1-Day Pentest</h1>
    <a href="https://irissoftware.sharepoint.com/sites/EngineeringIntranet/SitePages/SDLC---1-Day-Pen-Test.aspx#completing-the-request" target="_blank">
        <p-button icon="pi pi-question"></p-button>
    </a>
</div>

<div class="flex gap-3 mb-2" style="justify-content: flex-end; position: relative;">
    <div class="button-container" style="position: relative; display: inline-block;">
        <button pButton pRipple label="View Findings" class="p-button-info" [disabled]="!hasSelectedOption" (click)="showPTT()"></button>
        <span class="badge" *ngIf="hasSelectedOption">{{ this.pentestPTT.length }}</span>
    </div>
    <button pButton pRipple label="New Request" icon="pi pi-plus" class="p-button-success" (click)="openNew()"></button>
    <p-toast></p-toast>
</div>


<p-splitter [panelSizes]="[25, 75]" [style]="{ height: 'auto' }" styleClass="mb-5">
    <ng-template pTemplate let-splitter1>
        <div class="col flex flex-column align-items-center gap-3" style="height: 100%; overflow-y: auto;">
            <div [style.background-color]="oneDayTest.Id === selectedOneDayPentestId ? 'var(--primary-color)' : 'inherit'" class="card mb-0 border-round w-full" *ngFor="let oneDayTest of visibleProductOneDayPentests">
                <div class="flex justify-content-between align-items-center mb-3 gap-5">
                    <div>
                        <span [style.color]="oneDayTest.Id === selectedOneDayPentestId ? 'var(--primary-color-text)' : 'inherit'" class="font-bold text-xl mb-3">Id: {{ oneDayTest.Id }}</span>
                        <div [style.color]="oneDayTest.Id === selectedOneDayPentestId ? 'var(--primary-color-text)' : 'inherit'" class="font-bold mb-1">Start Date: {{ oneDayTest.OneDayTestBatchNumber?.StartDate | date: 'dd MMM yyyy' }} </div>
                        <div [style.color]="oneDayTest.Id === selectedOneDayPentestId ? 'var(--primary-color-text)' : 'inherit'" class="font-bold mb-1">Status: {{ oneDayTest.OneDayTestBatchNumber?.OneDayTestBatchStatusName }}</div>
                    </div>
                    <div class="flex flex-column gap-2">
                        <button [style.color]="oneDayTest.Id === selectedOneDayPentestId ? 'var(--primary-color-text)' : 'inherit'" pButton icon="pi pi-chevron-right" class="p-button-outlined p-button-raised" *ngIf="oneDayTest.Id !== undefined" (click)="showOneDayTestDetails(oneDayTest.Id)"></button>
                        <button *appHasRole="['Lens.Admin']" pButton icon="pi pi-trash" class="p-button-outlined p-button-raised p-button-danger" (click)="deleteOneDayTest(oneDayTest)"></button>
                    </div>
                </div>
            </div>
            <p-paginator [rows]="pageSize" [totalRecords]="productOneDayPentests.length" (onPageChange)="onPageChange($event)"></p-paginator>
        </div>
    </ng-template>

    <ng-template pTemplate let-splitter2>
        <div class="col flex justify-content-center">
            <div class="col-12">
                <div class="card">
                    <div *ngIf="!hasSelectedOption">
                        <p-messages [(value)]="messages" [enableService]="false" [closable]="true"></p-messages>
                    </div>

                    <div [hidden]="!hasSelectedOption">
                        <h5>1-Day Pen test Form</h5>
                        <div class="p-fluid p-formgrid grid">
                        <div class="field col-12 md:col-6">
                            <label for="hostingEnvironment">Hosting Environment</label>
                            <p-dropdown appendTo="body" [(ngModel)]="editedProductOneDayPentest.HostingEnvironmentId" inputId="HostingEnvironmentId" [options]="hostingEnvironments" placeholder="Select" optionValue="Id">
                                <ng-template pTemplate="selectedItem">
                                    <span *ngIf="editedProductOneDayPentest.HostingEnvironmentId">{{ getHostingEnvironmentNameById(editedProductOneDayPentest.HostingEnvironmentId) }}</span>
                                </ng-template>
                                <ng-template let-option pTemplate="item">
                                    <span>{{option.Name}}</span>
                                </ng-template>
                            </p-dropdown>
                        </div>
                        <div class="field col-12 md:col-6">
                            <label for="environment">Environment</label>
                            <p-dropdown appendTo="body" [(ngModel)]="editedProductOneDayPentest.SDLCEnvironmentId" inputId="SDLCEnvironmentId" [options]="sdlcEnvironments" placeholder="Select" optionValue="Id">
                                <ng-template pTemplate="selectedItem">
                                    <span *ngIf="editedProductOneDayPentest.SDLCEnvironmentId">{{ getSDLCEnvironmentNameById(editedProductOneDayPentest.SDLCEnvironmentId) }}</span>
                                </ng-template>
                                <ng-template let-option pTemplate="item">
                                    <span>{{option.Name}}</span>
                                </ng-template>
                            </p-dropdown>
                        </div>
                        <div class="field col-12 md:col-6">
                            <label for="testTrigger">Reason for this test</label>
                            <p-dropdown appendTo="body" [(ngModel)]="editedProductOneDayPentest.TestTriggerId" inputId="TestTriggerId" [options]="testTriggers" placeholder="Select" optionValue="Id">
                                <ng-template pTemplate="selectedItem">
                                    <span *ngIf="editedProductOneDayPentest.TestTriggerId">{{ getTestTriggerNameById(editedProductOneDayPentest.TestTriggerId) }}</span>
                                </ng-template>
                                <ng-template let-option pTemplate="item">
                                    <span>{{option.Name}}</span>
                                </ng-template>
                            </p-dropdown>
                        </div>
                        <div class="field col-12 md:col-6">
                            <label for="timezone">TimeZone</label>
                            <input type="text" pInputText id="timezone" [(ngModel)]="editedProductOneDayPentest.TimeZone">
                        </div>
                        <div class="field col-12 md:col-6">
                            <label for="remediationProgress">Remediation Progress</label>
                            <p-dropdown appendTo="body" [(ngModel)]="editedProductOneDayPentest.TestRemediationStatusId" inputId="TestRemediationStatusId" [options]="testRemediationStatuses" placeholder="Select" optionValue="Id">
                                <ng-template pTemplate="selectedItem">
                                    <span *ngIf="editedProductOneDayPentest.TestRemediationStatusId">{{ getTestRemediationStatusNameById(editedProductOneDayPentest.TestRemediationStatusId) }}</span>
                                </ng-template>
                                <ng-template let-option pTemplate="item">
                                    <span>{{option.Name}}</span>
                                </ng-template>
                            </p-dropdown>
                        </div>
                        <div class="field col-12 md:col-6">
                            <label for="testTarget">Test Targets</label>
                            <p-multiSelect appendTo="body" [options]="testTargetTypes" [(ngModel)]="selectedOneDayPentestOptionTargetTypes" defaultLabel="Select a Test Target" optionLabel="Name" optionValue="Id"
                                display="chip"></p-multiSelect>
                        </div>
                        <div class="field col-12 md:col-12">
                            <label for="swaggerURL">Swagger URL</label>
                            <div><textarea [(ngModel)]="editedProductOneDayPentest.SwaggerURL" pInputTextarea style="width: 100%" name="" id="" cols="30" rows="1" [disabled]="true"></textarea></div>
                        </div>
                        <div class="field col-12 md:col-12">
                            <label for="URLs">URLs</label>
                            <div><textarea [(ngModel)]="editedProductOneDayPentest.URLs" pInputTextarea style="width: 100%" name="" id="" cols="30" rows="3"></textarea></div>
                        </div>
                        <div class="field col-12 md:col-12">
                            <label for="userRoles">User Roles</label>
                            <div><textarea [(ngModel)]="editedProductOneDayPentest.UserRoles" pInputTextarea style="width: 100%" name="" id="" cols="30" rows="5"></textarea></div>
                        </div>
                        <div class="field col-12 md:col-12">
                            <label for="primaryContact">Contact Details</label>
                            <div><textarea [(ngModel)]="editedProductOneDayPentest.PrimaryContact" pInputTextarea style="width: 100%" name="" id="" cols="30" rows="5"></textarea></div>
                        </div>
                        <div class="field col-12 md:col-12">
                            <p-confirmDialog></p-confirmDialog>
                            <label for="oneDayMainContacts">Main Contacts</label>
                            <div>
                                <ng-container *ngFor="let contact of oneDayTestContacts">
                                    <p-chip styleClass="m-1">
                                        <img src="assets/layout/images/iris-circle-logo.png" alt="" width="60px" height="" class="shadow-2 border-circle">
                                        <span pTooltip={{contact.Mail}} tooltipPosition="bottom" [hideDelay]="2000">{{ contact.Name }}</span>
                                        <button pButton icon="pi pi-times" class="p-button-rounded p-button-danger p-button-text" (click)="removeUser(contact)"></button>
                                    </p-chip>
                                </ng-container>
                                <button pButton pRipple icon="pi pi-plus" class="p-button-success p-button-rounded" (click)="openUserDialog('addPrimaryContact')" [disabled]="!hasSelectedOption"></button>
                            </div>
                        </div>
                        <div class="field col-12 md:col-12">
                            <label for="exclusions">Exclusions</label>
                            <div><textarea [(ngModel)]="editedProductOneDayPentest.Exclusions" pInputTextarea style="width: 100%" name="" id="" cols="30" rows="5"></textarea></div>
                        </div>
                        <div class="field col-12 md:col-6">
                            <label for="batchNumber">Batch Number</label>
                            <input type="text" pInputText id="batchNumber" [(ngModel)]="editedProductOneDayPentest.OneDayTestBatchNumberId" [readOnly]="true">
                        </div>
                        
                                        </div>
                                        <div style="display: flex; justify-content: flex-end;">
                        <p-button *ngIf="hasSelectedOption" label="Submit" (click)="onSubmit()"></p-button>
                                        </div>
                    </div>
            </div>
        </div>
    </div>
    </ng-template>
</p-splitter>



<p-dialog [(visible)]="oneDayPentestDialog" [style]="{width: '750px'}" header="Request new One Day Pentest Tool" [modal]="true" class="p-fluid">
    <ng-template pTemplate="content">

        <div class="p-fluid p-formgrid grid">
            <div class="field col-6">
                <label for="hostingEnvironment">Hosting Environment</label>
                <p-dropdown appendTo="body" [(ngModel)]="productOneDayPentest.HostingEnvironmentId" inputId="HostingEnvironmentId" 
                    [options]="hostingEnvironments" placeholder="Select" optionValue="Id" [required]="true" [ngClass]="{'ng-invalid ng-dirty' : submitted && !productOneDayPentest.HostingEnvironmentId}">
                    <ng-template pTemplate="selectedItem">
                        <span *ngIf="productOneDayPentest.HostingEnvironmentId">{{ getHostingEnvironmentNameById(productOneDayPentest.HostingEnvironmentId) }}</span>
                    </ng-template>
                    <ng-template let-option pTemplate="item">
                        <span>{{option.Name}}</span>
                    </ng-template>
                </p-dropdown>
            </div>
            <div class="field col-6">
                <label for="environment">Environment</label>
                <p-dropdown appendTo="body" [(ngModel)]="productOneDayPentest.SDLCEnvironmentId" inputId="SDLCEnvironmentId" 
                    [options]="sdlcEnvironments" placeholder="Select" optionValue="Id" [required]="true" [ngClass]="{'ng-invalid ng-dirty' : submitted && !productOneDayPentest.SDLCEnvironmentId}">
                    <ng-template pTemplate="selectedItem">
                        <span *ngIf="productOneDayPentest.SDLCEnvironmentId">{{ getSDLCEnvironmentNameById(productOneDayPentest.SDLCEnvironmentId) }}</span>
                    </ng-template>
                    <ng-template let-option pTemplate="item">
                        <span>{{option.Name}}</span>
                    </ng-template>
                </p-dropdown>
            </div>
            <div class="field col-6 md:col-6">
                <label for="testTrigger">Reason for this test</label>
                <p-dropdown appendTo="body" [(ngModel)]="productOneDayPentest.TestTriggerId" inputId="TestTriggerId" [options]="testTriggers" placeholder="Select" optionValue="Id">
                    <ng-template pTemplate="selectedItem">
                        <span *ngIf="productOneDayPentest.TestTriggerId">{{ getTestTriggerNameById(productOneDayPentest.TestTriggerId) }}</span>
                    </ng-template>
                    <ng-template let-option pTemplate="item">
                        <span>{{option.Name}}</span>
                    </ng-template>
                </p-dropdown>
            </div>
            <div class="field col-6 md:col-6">
                <label for="timezone">TimeZone</label>
                <input type="text" pInputText id="timezone" [(ngModel)]="productOneDayPentest.TimeZone">
            </div>
            <div class="field col-6 md:col-6">
                <label for="remediationProgress">Remediation Progress</label>
                <p-dropdown appendTo="body" [(ngModel)]="productOneDayPentest.TestRemediationStatusId" inputId="TestRemediationStatusId" [options]="testRemediationStatuses" placeholder="Select" optionValue="Id">
                    <ng-template pTemplate="selectedItem">
                        <span *ngIf="productOneDayPentest.TestRemediationStatusId">{{ getTestRemediationStatusNameById(productOneDayPentest.TestRemediationStatusId) }}</span>
                    </ng-template>
                    <ng-template let-option pTemplate="item">
                        <span>{{option.Name}}</span>
                    </ng-template>
                </p-dropdown>
            </div>
            <div class="field col-6 md:col-6">
                <label for="testTarget">Test Targets</label>
                        <p-multiSelect appendTo="body" [options]="testTargetTypes" [(ngModel)]="selectedTestTargetTypes" defaultLabel="Select a Test Target" optionLabel="Name" optionValue="Id"
                            display="chip"></p-multiSelect>
            </div>
            <div class="field col-12 md:col-12">
                <label for="swaggerURL">Swagger URL</label>
                <div><textarea [(ngModel)]="productOneDayPentest.SwaggerURL" pInputTextarea style="width: 100%" name="" id="" cols="30" rows="1"></textarea></div>
            </div>
            <div class="field col-12 md:col-12">
                <label for="URLs">URLs</label>
                <div><textarea [(ngModel)]="productOneDayPentest.URLs" pInputTextarea style="width: 100%" name="" id="" cols="30" rows="3"></textarea></div>
            </div>
            <div class="field col-12 md:col-12">
                <label for="userRoles">User Roles</label>
                <div><textarea [(ngModel)]="productOneDayPentest.UserRoles" pInputTextarea style="width: 100%" name="" id="" cols="30" rows="5"></textarea></div>
            </div>
            <div class="field col-12 md:col-12">
                <label for="primaryContact">Contact Details</label>
                <div><textarea [(ngModel)]="productOneDayPentest.PrimaryContact" pInputTextarea style="width: 100%" name="" id="" cols="30" rows="5" 
                    [required]="true" [ngClass]="{'ng-invalid ng-dirty' : submitted && !productOneDayPentest.PrimaryContact}"></textarea></div>
            </div>

            <!-- main contact -->
            <div class="field col-12 md:col-12">
                <p-confirmDialog></p-confirmDialog>
                <label for="primaryContact">Primary Contacts</label>
                <div>
                    <ng-container *ngFor="let contact of sdlcNewOneDayContacts">
                        <p-chip styleClass="m-1">
                            <img src="assets/layout/images/iris-circle-logo.png" alt="" width="60px" height="" class="shadow-2 border-circle">
                            <span pTooltip={{contact.mail}} tooltipPosition="bottom" [hideDelay]="2000">{{ contact.displayName }}</span>
                            <button pButton icon="pi pi-times" class="p-button-rounded p-button-danger p-button-text" (click)="removeNewOneDayContact(contact)"></button>
                        </p-chip>
                    </ng-container>
                    <button pButton pRipple icon="pi pi-plus" class="p-button-success p-button-rounded" (click)="openUserDialog('addNewOneDayContact')"></button>
                </div>
            </div>

            <div class="field col-6 md:col-12">
                <label for="exclusions">Exclusions</label>
                <div><textarea [(ngModel)]="productOneDayPentest.Exclusions" pInputTextarea style="width: 100%" name="" id="" cols="30" rows="5"></textarea></div>
            </div>
            <div class="field col-6 md:col-6">
                <label for="batchNumber">Batch Number</label>
                <input type="text" pInputText id="batchNumber" [(ngModel)]="productOneDayPentest.OneDayTestBatchNumberId" [readOnly]="true">
            </div>
        </div>
    </ng-template>

    <ng-template pTemplate="footer">
        <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="hideDialog()"></button>
        <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text" (click)="saveNewOneDayPentest()"></button>
    </ng-template>
</p-dialog>


<p-dialog [(visible)]="userDialog" [modal]="true" [style]="{width: '750px'}" header="Add new user" [contentStyle]="{'overflow': 'visible'}" [modal]="true" class="p-fluid">
    <ng-template pTemplate="content">
        <div class="field">
            <p-dropdown [options]="users" scrollHeight="300px" [(ngModel)]="selectedUser" [style]="{'width':'100%'}" optionLabel="displayName" [filter]="true" (onFilter)="searchUsers($event)" [showClear]="true" placeholder="Select a User">
                <ng-template pTemplate="selectedItem">
                    <div class="flex align-items-center gap-2" *ngIf="selectedUser">
                        <div>{{ selectedUser.displayName }}</div>
                    </div>
                </ng-template>
                <ng-template let-user pTemplate="item">
                    <div class="flex align-items-center gap-2">
                        <img src="assets/layout/images/iris-circle-logo.png" alt="" width="60px" height="" class="shadow-2 border-circle">
                        <div class="flex w-full gap-2 flex-column p-4">
                            <div>{{ user.displayName }}</div>
                            <div>({{ user.mail }})</div>
                        </div>
                    </div>
                </ng-template>
            </p-dropdown>
        </div>
    </ng-template>

    <ng-template pTemplate="footer">
        <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="hideUserDialog()"></button>
        <!-- <button pButton pRipple label="Add" icon="pi pi-check" class="p-button-text" (click)="addOneDayTestContact()"></button> -->
        <button *ngIf="userDialogAction === 'addPrimaryContact'" pButton pRipple label="Add" icon="pi pi-check" class="p-button-text" (click)="addOneDayTestContact()"></button>
        <button *ngIf="userDialogAction === 'addNewOneDayContact'" pButton pRipple label="Add" icon="pi pi-check" class="p-button-text" (click)="addNewOneDayContact()"></button>
    </ng-template>
</p-dialog>




<p-dialog 
    header="Issues" 
    [modal]="true"
    [(visible)]="pentestPTTDialog" 
    [style]="{ width: '100rem' }"
    [maximizable]="true">
    <p-table #dt [value]="pentestPTT" responsiveLayout="stack" [breakpoint]="'960px'" responsiveLayout="scroll" appendTo="body" [rows]="10" [paginator]="true" [rowsPerPageOptions]="[10,20,30]" [showCurrentPageReport]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" selectionMode="multiple" [rowHover]="true" dataKey="id">
        <ng-template pTemplate="header">
            <tr>
                <th>1d Pentest Id</th>
                <th>Severity</th>
                <th>CVSS</th>
                <th>Progress</th>
                <th>Issue Title</th>
                <th>Issue</th>
                <th>Issue Location</th>
                <th>Remediation</th>
                <th>Priority</th>
                <th>Fix Date (ETA)</th>
                <th>Fix Date (Num)</th>
                <th>Complexity</th>
                <th>Assigned to</th>
                <th>Comment</th>
                <th>Test Date</th>
                <th>Next Action</th>
                <th>Vector</th>
                <th></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-pentestPTT>
            <tr>
                <td>{{pentestPTT.OneDayTestBatchNumberId}}</td>
                <td>{{pentestPTT.SeverityName}}</td>
                <td>{{pentestPTT.CVSS}}</td>
                <td>{{pentestPTT.PTTProgressName}}</td>
                <td>{{ pentestPTT.IssueTitle }}</td>
                <td>
                    <button *ngIf="pentestPTT.Issue" pButton type="button" icon="pi pi-search" (click)="viewFullText(pentestPTT.Issue, 'Issue')" class="p-button-text"></button>
                </td>
                <td>{{ pentestPTT.IssueLocation }}</td>
                <td>
                    <button *ngIf="pentestPTT.Remediation" pButton type="button" icon="pi pi-search" (click)="viewFullText(pentestPTT.Remediation, 'Remediation')" class="p-button-text"></button>
                </td>
                <td>{{pentestPTT.PriorityName}}</td>
                <td>{{ pentestPTT.FixDate | date:'dd/MM/yyyy' }}</td>
                <td>{{pentestPTT.FixDateNum}}</td>
                <td>{{pentestPTT.ComplexityName}}</td>
                <td>
                    <ul>
                        <li *ngFor="let user of pentestPTT.AssignedTo">{{user.Name}}</li>
                    </ul>
                </td>
                <td>
                    <button *ngIf="pentestPTT.Comment" pButton type="button" icon="pi pi-search" (click)="viewFullText(pentestPTT.Comment, 'Comment')" class="p-button-text"></button>
                </td>
                
                <td>{{ pentestPTT.TestDate | date:'dd/MM/yyyy' }}</td>
                <td>{{pentestPTT.NextAction}}</td>
                <td>{{pentestPTT.Vector}}</td>
                <td>
                    <div class="flex">
                        <button pButton pRipple icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2" (click)="editPTT(pentestPTT)"></button>
                        <button pButton pRipple icon="pi pi-trash" class="p-button-rounded p-button-danger mr-2" (click)="deletePTTFinding(pentestPTT.Id)"></button>
                    </div>
                </td>
            </tr>
        </ng-template>
    </p-table>
</p-dialog>

<p-dialog [(visible)]="editPTTDialog" [style]="{width: '750px'}" header="Issue Details" [modal]="true" class="p-fluid">
    <ng-template pTemplate="content">
        <div class="p-fluid p-formgrid grid">
        <div class="field col-6 sm:col-6">
            <label for="pentestId">1d Pentest Id</label>
            <p-inputNumber type="number" inputId="integeronly" id="pentestId" [disabled]="true" [(ngModel)]="editPentestPTT.OneDayTestBatchNumberId"></p-inputNumber>
        </div>

        <div class="field col-6 md:col-6">
            <label for="severity">Severity</label>
            <p-dropdown appendTo="body" [(ngModel)]="editPentestPTT.SeverityId" inputId="SeverityId" [options]="pttSeverity" placeholder="Select" optionValue="Id">
                <ng-template pTemplate="selectedItem">
                    <span *ngIf="editPentestPTT.SeverityId">{{ getSeverityNameById(editPentestPTT.SeverityId) }}</span>
                </ng-template>
                <ng-template let-option pTemplate="item">
                    <span>{{option.Name}}</span>
                </ng-template>
            </p-dropdown>
        </div>

        <div class="field col-6 md:col-6">
            <label for="cvss">CVSS</label>
            <input type="number" pInputText id="cvss" [(ngModel)]="editPentestPTT.CVSS" [step]="0.1">
        </div>

        <div class="field col-6 sm:col-6">
            <label for="progress">Progress</label>
            <p-dropdown appendTo="body" [(ngModel)]="editPentestPTT.PTTProgressId" inputId="ProgressId" [options]="pttProgress" placeholder="Select" optionValue="Id">
                <ng-template pTemplate="selectedItem">
                    <span *ngIf="editPentestPTT.PTTProgressId">{{ getProgressNameById(editPentestPTT.PTTProgressId) }}</span>
                </ng-template>
                <ng-template let-option pTemplate="item">
                    <span>{{option.Name}}</span>
                </ng-template>
            </p-dropdown>
        </div>

        <div class="field col-12">
            <label for="issue">Issue Title</label>
            <input type="text" pInputText id="issueTitle" [(ngModel)]="editPentestPTT.IssueTitle">
        </div>

        <div class="field col-12">
            <label for="issue">Issue</label>
            <textarea pInputTextarea type="text" pInputText id="issue" [(ngModel)]="editPentestPTT.Issue" rows="3" cols="20"></textarea>
        </div>

        <div class="field col-12">
            <label for="issue">Issue Location</label>
            <input type="text" pInputText id="issueLocation" [(ngModel)]="editPentestPTT.IssueLocation">
        </div>

        <div class="field col-12">
            <label for="remediation">Remediation</label>
            <textarea pInputTextarea type="text" pInputText id="remediation" [(ngModel)]="editPentestPTT.Remediation" rows="3" cols="20"></textarea>
        </div>

        <div class="field col-6">
            <label for="priority">Priority</label>
            <p-dropdown appendTo="body" [(ngModel)]="editPentestPTT.PriorityId" inputId="PriorityId" [options]="pttPriority" placeholder="Select" optionValue="Id">
                <ng-template pTemplate="selectedItem">
                    <span *ngIf="editPentestPTT.PriorityId">{{ getPriorityNameById(editPentestPTT.PriorityId) }}</span>
                </ng-template>
                <ng-template let-option pTemplate="item">
                    <span>{{option.Name}}</span>
                </ng-template>
            </p-dropdown>
        </div>

        <div class="field col-6">
            <label for="fixDateEta">Fix Date (ETA)</label>
            <p-calendar appendTo="body" [showIcon]="true" id="fixDateEta" dateFormat="dd/mm/yy" [(ngModel)]="editPentestPTT.FixDate"></p-calendar>
        </div>

        <div class="field col-6">
            <label for="fixDateNum">Fix Date (Num)</label>
            <input type="number" pInputText id="fixDateNum" [(ngModel)]="editPentestPTT.FixDateNum">
        </div>

        <div class="field col-6">
            <label for="complexity">Complexity</label>
            <p-dropdown appendTo="body" [(ngModel)]="editPentestPTT.ComplexityId" inputId="ComplexityId" [options]="pttComplexity" placeholder="Select" optionValue="Id">
                <ng-template pTemplate="selectedItem">
                    <span *ngIf="editPentestPTT.ComplexityId">{{ getComplexityNameById(editPentestPTT.ComplexityId) }}</span>
                </ng-template>
                <ng-template let-option pTemplate="item">
                    <span>{{option.Name}}</span>
                </ng-template>
            </p-dropdown>
        </div>

        <div class="field col-12">
            <label for="comment">Comment</label>
            <textarea pInputTextarea type="text" pInputText id="comment" [(ngModel)]="editPentestPTT.Comment" rows="3" cols="20"></textarea>
        </div>

        <div class="field col-6">
            <label for="testDate">Test Date</label>
            <p-calendar appendTo="body" [showIcon]="true" id="testDate" dateFormat="dd/mm/yy" [(ngModel)]="editPentestPTT.TestDate"></p-calendar>
        </div>

        <div class="field col-12">
            <label for="nextAction">Next Action</label>
            <textarea pInputTextarea type="text" pInputText id="nextAction" [(ngModel)]="editPentestPTT.NextAction" rows="3" cols="20"></textarea>
        </div>

        <div class="field col-6">
            <label for="vector">Vector</label>
            <input type="text" pInputText id="vector" [(ngModel)]="editPentestPTT.Vector">
        </div>

        <div class="field col-12 md:col-12">
            <label for="assignedTo">Assigned to</label>
            <div>
                <ng-container *ngFor="let contact of pttContacts">
                    <p-chip styleClass="m-1">
                        <img src="assets/layout/images/iris-circle-logo.png" alt="" width="60px" height="" class="shadow-2 border-circle">
                        <span pTooltip={{contact.Mail}} tooltipPosition="bottom" [hideDelay]="2000">{{ contact.Name }}</span>
                        <button pButton icon="pi pi-times" class="p-button-rounded p-button-danger p-button-text" (click)="removePTTContact(contact)"></button>
                    </p-chip>
                </ng-container>
            </div>
            <button pButton pRipple icon="pi pi-plus" class="p-button-success p-button-rounded" (click)="openPTTContactDialog()" [disabled]="!hasSelectedOption"></button>
        </div>

        </div>
    </ng-template>

    <ng-template pTemplate="footer">
        <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="hideEditPTTDialog()"></button>
        <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text" (click)="updatePTT()"></button>
    </ng-template>
</p-dialog>


<p-dialog header="{{selectedTitle}}" [(visible)]="displayFullTextDialog" [modal]="true" [style]="{ width: '50vw' }">
    <p>{{ selectedText }}</p>
</p-dialog>




<p-dialog [(visible)]="pttContactDialog" [modal]="true" [style]="{width: '750px'}" header="Assign to User" [contentStyle]="{'overflow': 'visible'}" [modal]="true" class="p-fluid">
    <ng-template pTemplate="content">
        <div class="field">
            <p-dropdown [options]="users" scrollHeight="300px" [(ngModel)]="selectedUser" [style]="{'width':'100%'}" optionLabel="displayName" [filter]="true" (onFilter)="searchUsers($event)" [showClear]="true" placeholder="Select a User">
                <ng-template pTemplate="selectedItem">
                    <div class="flex align-items-center gap-2" *ngIf="selectedUser">
                        <div>{{ selectedUser.displayName }}</div>
                    </div>
                </ng-template>
                <ng-template let-user pTemplate="item">
                    <div class="flex align-items-center gap-2">
                        <img src="assets/layout/images/iris-circle-logo.png" alt="" width="60px" height="" class="shadow-2 border-circle">
                        <div class="flex w-full gap-2 flex-column p-4">
                            <div>{{ user.displayName }}</div>
                            <div>({{ user.mail }})</div>
                        </div>
                    </div>
                </ng-template>
            </p-dropdown>
        </div>
    </ng-template>

    <ng-template pTemplate="footer">
        <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="hidePTTContactDialog()"></button>
        <button pButton pRipple label="Add" icon="pi pi-check" class="p-button-text" (click)="addPTTContact()"></button>
    </ng-template>
</p-dialog>