import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ToolExternalService } from 'src/app/layout/service/tool-external.service';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { SonarMetrics, SonarMeasure } from 'src/app/api/sonar-metrics';
import { Router } from '@angular/router';

@Component({
  selector: 'sonar-metrics-widget',
  templateUrl: './sonar-metrics-widget.component.html'
})
export class SonarMetricsWidgetComponent implements OnInit, OnChanges {

  @Input() data: any;
  pieLicenceData: any;
  pieOptions: any;
  sonarReportingLevel: string = '';
  sonarMetricsLoading: boolean = true;
  snykLicenceLink: string = '';
  sonarMetricsAvailable: boolean = false;
  isCollapsed: boolean = false;

  sonarMetrics: SonarMetrics[] = [];
  selectedMetric: SonarMetrics = {};
  selectedMetricName: string = "";
  components: string[] = [];
  hasMultipleComponents: boolean = false;


  sonarMeasuresLoc: string = "";
  sonarMeasuresTechDebt: string = "";
  sonarMeasuresCoverage: number = 0;
  sonarMeasuresComplexity: string = "";
  sonarMeasuresReliability: number = 0;
  sonarMeasuresMaintainability: number = 0;
  sonarMeasuresSecurity: number = 0;
  sonarMeasuresSecurityReview: number = 0;
  sonarMeasuresSecurityHotspots: string = "";

  isInitialized: boolean = false;
  widgetContext: string = '';
  sourceType: string = '';
  sourceObjectName: string = '';

  constructor(private toolExternalService: ToolExternalService, private router: Router) { }

  ngOnInit(): void {
    this.widgetContext = this.data.objectId;
    this.initialiseWidgetOnce();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data']) {
      if (this.widgetContext !== this.data.objectId) {
        this.isInitialized = false;
        this.widgetContext = this.data.objectId;
      }
      this.resetWidget();
      this.initialiseWidgetOnce();
    }
  }

  private initialiseWidgetOnce(): void {
    if (!this.isInitialized) {
      this.initialiseWidget();
      this.isInitialized = true;
    }
  }

  initialiseWidget() {
    this.resetWidget();
    this.pieOptions = {
      plugins: {
        legend: {
          display: false
        }
      }
    };

    //convert this.productId to a number
    const prodNumber = parseInt(this.data.objectId);
    const productType = this.data?.productType ?? null;

    this.toolExternalService.getSonarMetrics(prodNumber, productType).pipe(
      catchError((error) => {
        if (error.status === 404) {
          this.sonarReportingLevel = "No SonarQube data available";
        } else {
          this.sonarReportingLevel = "Unexpected error occured getting Sonar data for this product";
          console.error('An error occurred:', error);
        }
        this.sonarMetricsLoading = false;
        return of(null);
      })
    ).subscribe((sonarIssuesData) => {
      if (!sonarIssuesData) {
        this.sonarMetricsLoading = false;
        this.sonarReportingLevel = "No SonarQube data available";
        console.error("No Sonar data found");
        return;
      } else {

        this.sourceType = sonarIssuesData.productType;
        this.sourceObjectName = sonarIssuesData.objectName;
        this.sonarMetrics = sonarIssuesData.metrics;
        this.components = [];
        for (const metric of this.sonarMetrics) {
          this.updateComponentDropdown(metric.component ?? "??");
        }

        this.selectedMetric = this.sonarMetrics[0];
        this.selectedMetricName = this.selectedMetric.component ?? "";
        this.hasMultipleComponents = this.components.length > 1;

        this.populateMetrics();


        this.sonarMetricsLoading = false;
      }
    });

  }

  updateComponentDropdown(newComponent: string) {
    if (!this.components.includes(newComponent)) {
      this.components.push(newComponent);
    }   
  }

  onDropdownChange(event: any) {
    const selectedValue = event.value;
    this.selectedMetricName = selectedValue;

    for (const metric of this.sonarMetrics) {
      if (metric.component === selectedValue) {
        this.selectedMetric = metric;
        break;
      }
    }

    this.populateMetrics();
  }

  toggleWidgetCollapse() {
    this.isCollapsed = !this.isCollapsed;
  }

  getGaugeColor(): string {
    if (this.sonarMeasuresCoverage < 50) {
      return '#f44336'; // Red color
    } else if (this.sonarMeasuresCoverage >= 50 && this.sonarMeasuresCoverage < 80) {
      return '#ff9800'; // Yellow color
    } else {
      return '#28a745'; // Green color
    }
  }

  getGradeLetter(value: number): string {
    switch (value) {
      case 1: return 'A';
      case 2: return 'B';
      case 3: return 'C';
      case 4: return 'D';
      case 5: return 'E';
      default: return '';
    }
  }

  populateMetrics() {

    let measureCount = 0;

    if (this.selectedMetric?.measures) {

      measureCount = this.selectedMetric.measures?.length ?? 0;

      for (const measure of this.selectedMetric?.measures) {
        switch (measure.metric) {
          case 'ncloc':
            this.sonarMeasuresLoc = parseInt(measure.value).toLocaleString();
            break;
          case "sqale_index":
            const sqale = parseInt(measure.value);
            if (sqale < 0) {
              this.sonarMeasuresTechDebt = "0 days";
            } else {
              this.sonarMeasuresTechDebt = this.getTechDebtDays(sqale);
            }
            break;
          case "coverage":
            this.sonarMeasuresCoverage = parseFloat(measure.value);
            break;
          case "complexity":
            this.sonarMeasuresComplexity = parseInt(measure.value).toLocaleString();
            break;
          case "security_hotspots":
            this.sonarMeasuresSecurityHotspots = parseInt(measure.value).toLocaleString();
            break;
          case "reliability_rating":
            this.sonarMeasuresReliability = parseInt(measure.value);
            break;
          case "security_rating":
            this.sonarMeasuresSecurity = parseInt(measure.value);
            break;
          case "security_review_rating":
            this.sonarMeasuresSecurityReview = parseInt(measure.value);
            break;
          case "sqale_rating":
            this.sonarMeasuresMaintainability = parseInt(measure.value);
            break;
          default:
            break;
        }
      }
    }

    const currentUrl = this.router.url;

    if (measureCount == 0) {
      this.sonarReportingLevel = "No metrics found for this product"
      this.sonarMetricsAvailable = false;
    } else {
      this.sonarMetricsAvailable = true;
      if (this.sourceType === 'Product') {
        this.sonarReportingLevel = "";
      } else if (this.sourceType === 'Suite' && currentUrl.includes('/suite/')) {
        this.sonarReportingLevel = "Sonar data not available for this suite";
        this.sonarMetricsAvailable = false;
        return;
      } else {
        this.sonarReportingLevel = "Sonar data not available at this product level. Showing data for parent suite: " + this.sourceObjectName;
      }
    }

  }

getTechDebtDays(sqale: number): string {
  const days = sqale / 480;
  // Convert days to a string with a maximum of 1 decimal place
  const daysString = days.toFixed(1);
  // Check if the decimal part is zero and adjust the output accordingly
  if (parseFloat(daysString) % 1 === 0) {
    return `${Math.floor(days)} days`;
  } else {
    return `${daysString} days`;
  }
}

  getGradeClass(value: number): string {
    return this.getGradeLetter(value);
  }

  resetWidget() {
    this.sonarMeasuresLoc = "";
    this.sonarMeasuresTechDebt = "";
    this.sonarMeasuresCoverage = 0;
    this.sonarMeasuresComplexity = "";
    this.sonarMeasuresReliability = 0;
    this.sonarMeasuresMaintainability = 0;
    this.sonarMeasuresSecurity = 0;
    this.sonarMeasuresSecurityReview = 0;
    this.sonarMeasuresSecurityHotspots = "";
    this.sonarMetrics = [];
    this.selectedMetric = {};
    this.selectedMetricName = "";
    this.components = [];
    this.hasMultipleComponents = false;
    this.sonarReportingLevel = '';
    this.sonarMetricsLoading = true;
    this.sonarMetricsAvailable = false;
    this.isCollapsed = false;
  }
}
