import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Message, MessageService } from 'primeng/api';
import { ProductToolsRefService } from 'src/app/layout/service/product/product-tool-refs.service';
import { Observable, of, forkJoin } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

@Component({
  selector: 'app-suite-tool-refs',
  templateUrl: './suite-tool-refs.component.html'
})
export class SuiteToolRefsComponent implements OnInit {

  messages: Message[] = [];
  suiteId: string = '';
  productType: number = 0;
  matchedProductToolRefs: any[] = [];
  availableTools: any[] = [];
  selectedTool: any = null;
  newRefDialog: boolean = false;
  newRef: string = '';
  submitted: boolean = false;

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private productToolsRefService: ProductToolsRefService,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.suiteId = params['suiteId'];

      this.getProductTypes().subscribe(() => {
        this.loadData();
      });
    });
  }

  getProductTypes(): Observable<any> {
    return this.productToolsRefService.getProductTypes().pipe(
      tap((response: any) => {
        if (response && response['@odata.context']) {
          const productTypes = response.value;
          if (Array.isArray(productTypes)) {
            productTypes.forEach((productType: any) => {
              if (productType.Name === 'Suite') {
                this.productType = productType.Id;
              }
            });
          } else {
            console.error('Expected an array of product types in OData response, but received:', productTypes);
          }
        } else {
          console.error('Unexpected response format. Expected OData response with @odata.context.');
        }
      }),
      catchError((error) => {
        console.error('Error fetching product types:', error);
        return of(null);
      })
    );
  }

  loadData(): void {
    forkJoin([this.getTools(), this.getProductToolRefs()]).subscribe(() => {
      this.updateAvailableTools();
    });
  }

  getProductToolRefs(): Observable<any> {
    return this.productToolsRefService.getProductToolRefs().pipe(
      tap((response: any) => {
        if (response && response['@odata.context']) {
          const productToolRefs = response.value;
          if (Array.isArray(productToolRefs)) {
            this.matchedProductToolRefs = productToolRefs.filter((productToolRef: any) =>
              productToolRef.ProductTypeId === this.productType && productToolRef.ObjectId === parseInt(this.suiteId)
            );
          } else {
            console.error('Expected an array of product tool references in OData response, but received:', productToolRefs);
          }
        } else {
          console.error('Unexpected response format. Expected OData response with @odata.context.');
        }
      }),
      catchError((error) => {
        console.error('Error fetching product tool refs:', error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error populating tool references', life: 3000 });
        return of([]);
      })
    );
  }

  openNewRefDialog() {
    this.newRefDialog = true;
  }
  hideDialog() {
    this.newRefDialog = false;
  }

  addNewToolRef() {
    this.submitted = true;

    if (!this.selectedTool || !this.newRef) {
      return;
    }

    console.log(`Adding new reference for tool ${this.selectedTool}: ${this.newRef}`);

    const newToolRef = {
      ProductTypeId: this.productType,
      ObjectId: parseInt(this.suiteId),
      SDLCToolId: this.selectedTool,
      Reference: this.newRef
    };

    this.productToolsRefService.addProductToolRef(newToolRef).subscribe(
      (response: any) => {
        console.log('Successfully added new tool reference:', response);
        this.messageService.add({ severity: 'success', summary: 'Successful', detail: `New reference added`, life: 3000 });
        this.loadData();
      },
      (error) => {
        console.error('Error adding new tool reference:', error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Could not add new tool reference', life: 3000 });
      }
    );

    this.selectedTool = null;
    this.newRef = '';
    this.newRefDialog = false;
    this.submitted = false;

  }

  deleteProductToolRef(ptr: any) {
    console.log('Deleting product tool reference:', ptr);

    this.productToolsRefService.deleteProductToolRef(ptr.Id).subscribe(
      (response: any) => {
        console.log('Successfully deleted product tool reference:', response);
        this.matchedProductToolRefs = this.matchedProductToolRefs.filter((productToolRef) => productToolRef.Id !== ptr.Id);
        this.messageService.add({ severity: 'success', summary: 'Successful', detail: `Reference removed`, life: 3000 });
        this.loadData(); // Refresh the data after deletion
      },
      (error) => {
        console.error('Error deleting product tool reference:', error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Failed to remove tool reference', life: 3000 });
      }
    );
  }

  getTools(): Observable<any> {
    return this.productToolsRefService.getSDLCTools().pipe(
      tap((response: any) => {
        if (response && response['@odata.context']) {
          const tools = response.value;
          if (Array.isArray(tools)) {
            this.availableTools = tools.map((tool: any) => ({ label: tool.Name, value: tool.Id }));
            this.availableTools.sort((a, b) => a.label.localeCompare(b.label));

          } else {
            console.error('Expected an array of tools in OData response, but received:', tools);
          }
        } else {
          console.error('Unexpected response format. Expected OData response with @odata.context.');
        }
      }),
      catchError((error) => {
        console.error('Error fetching tools:', error);
        return of([]);
      })
    );
  }

  updateAvailableTools() {
    const usedToolIds = new Set(this.matchedProductToolRefs.map(ref => ref.SDLCToolId));
    this.availableTools = this.availableTools.filter(tool => !usedToolIds.has(tool.value));
    this.availableTools.sort((a, b) => a.label.localeCompare(b.label));
  }
}
