import { Component, OnInit, ViewChild } from '@angular/core';
import { Table } from 'primeng/table';
import { ProductSDLC, ProductSDLCPutDto } from 'src/app/api/product-sdlc';
import { ProductSDLCService } from 'src/app/layout/service/product/product-sdlc.service';

@Component({
  selector: 'app-sdlcapproval',
  templateUrl: './sdlcapproval.component.html',
  styleUrls: ['./sdlcapproval.component.scss']
})
export class SDLCApprovalComponent implements OnInit {
  @ViewChild('dt1') table!: Table;

  allSDLCs: ProductSDLC[] = [];

  sdlcRequestStatuses: any[] = [];
  sdlcApprovedStatusId: number = 0;
  sdlcDeclinedStatusId: number = 0;

  visible: boolean = false;

  selectedSDLC: ProductSDLC = {};
  isApprove: boolean = false;

  constructor(private productSDLCService: ProductSDLCService) { }
  
  ngOnInit(): void {
    this.getAllSDLCs();
    this.getAllSDLCRequestStatuses();
  }
  
  getAllSDLCs() {
    this.productSDLCService.getAllSDLCRequests().subscribe((response) => {
      this.allSDLCs = response;
    });
  }

  getAllSDLCRequestStatuses() {
    this.productSDLCService.getAllSDLCRequestStatuses().subscribe((statuses) => {
      this.sdlcRequestStatuses = statuses;

      const approvedStatus = this.sdlcRequestStatuses.find((status) => status.Name === "Approved");
      this.sdlcApprovedStatusId = approvedStatus ? approvedStatus.Id : null;
      
      const declinedStatus = this.sdlcRequestStatuses.find((status) => status.Name === "Declined");
      this.sdlcDeclinedStatusId = declinedStatus ? declinedStatus.Id : null;
    });
  }

  approveSDLC(sdlc: ProductSDLC) {
    this.selectedSDLC = sdlc;
    this.isApprove = true;
    this.showDialog(sdlc);
  }

  declineSDLC(sdlc: ProductSDLC) {
    this.selectedSDLC = sdlc;
    this.isApprove = false;
    this.showDialog(sdlc);
  }

  showDialog(sdlc: ProductSDLC, isApprove: boolean = true) {
    this.visible = true;
  }

  submitComment(sdlc: ProductSDLC) {
    if (this.selectedSDLC.Id) {
      const sdlcPutDto: ProductSDLCPutDto = {
        RequestComment: this.selectedSDLC.RequestComment,
        SDLCRequestStatusId: this.isApprove ? this.sdlcApprovedStatusId : this.sdlcDeclinedStatusId
      };
      this.productSDLCService.updateProductSDLCStatus(this.selectedSDLC.Id, sdlcPutDto).subscribe((response) => {
        this.allSDLCs = this.allSDLCs.filter((s) => s.Id !== sdlc.Id);
        this.visible = false;
        this.selectedSDLC = {};
      });
    }
    this.selectedSDLC = {};
  }

  onGlobalFilter(table: Table, event: Event) {
    table.filterGlobal((event.target as HTMLInputElement).value, 'contains');
  }

  clear(table: Table) {
    table.clear();
    this.table.filterGlobal('', 'contains');
  }

  // @Input() get selectedColumns(): any[] {
  //   return this._selectedColumns;
  // }

  // set selectedColumns(val: any[]) {
  //     //restore original order
  //     this._selectedColumns = this.cols.filter(col => val.includes(col));
  // }

  // isColumnSelected(field: string): boolean {
  //   return this._selectedColumns.some(col => col.field === field);
  // }
}
