import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConfirmationService, Message, MessageService } from 'primeng/api';
import { EmailRequestDto, EmailRequestNewDto } from 'src/app/api/email-request';
import { Product, User } from 'src/app/api/product';
import { ProductSDLC, ProductSDLCPutDto, ProductSDLCRequestDto, SDLCPrimaryContact } from 'src/app/api/product-sdlc';
import { GraphExplorerService } from 'src/app/layout/service/graphexplorer/graph-explorer.service';
import { EmailService } from 'src/app/layout/service/product/email.service';
import { ProductSDLCService } from 'src/app/layout/service/product/product-sdlc.service';
import { ProductService } from 'src/app/layout/service/product/products.service';
import { ToEmailService } from 'src/app/layout/service/product/to-email.service';
import { ProductSelectionService } from 'src/app/product.selection.service';
import { LogService } from 'src/app/layout/service/log.service';
import { EmailTemplateService } from 'src/app/layout/service/email/email-template.service';

@Component({
  selector: 'app-sast',
  templateUrl: './sast.component.html'
})
export class SASTComponent implements OnInit {
  productId: string = '';
  productName: string = '';
  productReference: string = '';

  isLoading = true;
  
  tier: string = '';
  selectedProduct: Product | null = null;

  productSDLCSAST: ProductSDLC[] = [];
  editedProductSDLC: ProductSDLCPutDto = {};
  productSAST: ProductSDLCRequestDto = {};

  filteredProductSDLCSAST: ProductSDLC[] = [];

  productSDLCTools: any[] = [];
  productSDLCRequestStatuses: any[] = [];
  productSDLCPracticeStatuses: any[] = [];

  hasSelectedOption: boolean = false;
  selectedSastId: number | undefined;
  selectedSastOption: ProductSDLCPutDto = {};

  status: any[] = [];
  selectedStatus: string | null = null;

  submitted: boolean = false;
  sastDialog: boolean = false;

  sastSDLCPracticeId: number | undefined;

  messages: Message[] = [];
  messages2: Message[] = [];

  hasApprovedSASTMessage: boolean = false;

  userDialog: boolean = false;
  users: User[] = [];
  sdlcPrimaryContacts: SDLCPrimaryContact[] = [];

  selectedUser: User | null = null;

  sdlcNewSastContacts: User[] = [];
  userDialogAction: string = '';

  constructor(private route: ActivatedRoute, private productSDLCService: ProductSDLCService, private messageService: MessageService,
    private productSelectionService: ProductSelectionService, private productService: ProductService,
    private graphExplorerService: GraphExplorerService, private confirmationService: ConfirmationService,
    private emailService: EmailService, private toEmailService: ToEmailService, private logService: LogService,
    private emailTemplateService: EmailTemplateService) { }

    ngOnInit() {
      this.route.params.subscribe(params => {
        this.productId = params['id'];
  
        this.productSelectionService.getSelectedProduct().subscribe((product) => {
          this.productName = product?.Name || '';
          this.productReference = product?.ProductRef || '';
        });

        this.fetchSASTData();
      });
  
      this.productSDLCService.getAllSDLCTools("SAST").subscribe((sdlcTools) => {
        this.productSDLCTools = sdlcTools;
      });
  
      this.productSDLCService.getAllSDLCRequestStatuses().subscribe((sdlcRequestStatus) => {
        this.productSDLCRequestStatuses = sdlcRequestStatus;
      });
  
      this.productSDLCService.getSDLCPractice("SAST").subscribe((sdlcPractice) => {
        if(sdlcPractice.length > 0){
          this.sastSDLCPracticeId = sdlcPractice[0].Id;
        }

        this.productSDLCService.getMaturityLevelByPracticeId(this.sastSDLCPracticeId).subscribe((sdlcPracticeStatus) => {
          this.productSDLCPracticeStatuses = sdlcPracticeStatus;
          this.sortOptions();
        });
      });
  
      this.productService.getProductById(this.productId).subscribe((product) => {
        this.selectedProduct = product;
        this.tier = this.selectedProduct?.ProductMetric?.TierName || '';
        this.isLoading = false;
      });

      this.getAllUsers();

      this.messages = [{ severity: 'info', summary: 'Information', detail: 'Select SAST from the menu to view' }];
      this.messages2 = [{ severity: 'warn', summary: 'Warning', detail: 'Currently not following the SAST Practice! Please request or wait for approval.' }];
    }

    sortOptions() {
      this.productSDLCPracticeStatuses.sort((a, b) => a.Order - b.Order);
    }

    getAllUsers() {
      this.graphExplorerService.getAllADUsers().subscribe((users) => {
        this.users = users;
      });
    }
    
    searchUsers(event?: any) {
      if (event.filter) {
        this.graphExplorerService.searchADUsers(event.filter).subscribe((users) => {
          this.users = users;
        });
      } else {
        this.getAllUsers();
      }
    }

    getPracticeStatusNameById(id: string): string {
      const status = this.productSDLCPracticeStatuses.find(status => status.Id === id);
      return status ? status.Name : '';
    }
  
    getToolNameById(toolId: string): string {
      const selectedTool = this.productSDLCTools.find(tool => tool.Id === toolId);
      return selectedTool ? selectedTool.Name : '';
    }

    showSastDetails(selectedSast: ProductSDLC) {
      // Set the selected SAST data to be displayed in the second splitter form
      this.selectedSastOption = selectedSast;
      this.hasSelectedOption = true;
      this.selectedSastOption.EnrolDate = this.selectedSastOption.EnrolDate ? new Date(this.selectedSastOption.EnrolDate) : undefined;
      this.selectedSastOption.NextReviewDate = this.selectedSastOption.NextReviewDate ? new Date(this.selectedSastOption.NextReviewDate) : undefined;
  
      this.selectedSastId = selectedSast.Id;
      this.editedProductSDLC = {...this.selectedSastOption};

      this.fetchProductSDLCContacts();
    }

    fetchProductSDLCContacts() {
      if (this.selectedSastId) {
        this.productSDLCService.getSDLCPrimaryContacts(this.selectedSastId).subscribe((sdlcPrimaryContacts) => {
          this.sdlcPrimaryContacts = sdlcPrimaryContacts;
          // I need this.sdlcPrimaryContacts to only contain the 
        });
      }
    }

    fetchSASTData(shouldReset: boolean = true, setSelectedStatus?: string | null) {
      this.productSDLCService.getProductSDLCSAST(this.productId).subscribe((sdlcSAST) => {
        if (sdlcSAST && sdlcSAST.value.length > 0){
          this.productSDLCSAST = sdlcSAST.value;
          this.filteredProductSDLCSAST = this.productSDLCSAST;
  
          const hasApprovedSAST = this.productSDLCSAST.some(sast => sast.SDLCRequestStatusName === 'Approved');

          if (setSelectedStatus) {
            this.selectedStatus = this.selectedStatus;
            this.onStatusChange(shouldReset);
          }
  
          if (hasApprovedSAST && !setSelectedStatus) {
            this.selectedStatus = 'Approved';
            this.hasApprovedSASTMessage = true;
            this.onStatusChange(shouldReset);
          } else {
            this.selectedStatus = 'Requested';
            this.onStatusChange(shouldReset);
          }

          this.showSastDetails(this.filteredProductSDLCSAST[0]);
        }


      });
      this.selectedStatus = 'Requested';
    }

    openNew() {
      this.productSAST = {};
      this.submitted = false;
      this.sastDialog = true;
    }
  
    hideDialog() {
      this.sastDialog = false;
      this.submitted = false;
      this.sdlcNewSastContacts = [];
    }

    openUserDialog(action: string) {
      this.userDialogAction = action;
      // Open the dialog
      this.userDialog = true;
    }
  
    hideUserDialog() {
      this.userDialog = false;
      this.selectedUser = null;
    }
  
    saveNewSAST() {
      // if (this.submitted) {
      //   return;
      // }
  
      this.submitted = true;
  
      if (this.productSAST.SDLCToolId) {
        this.productSAST.ProductId = this.productId;
        this.productSAST.SDLCPracticeId = this.sastSDLCPracticeId;
        this.productSAST.EnrolDate = this.convertToUTC(this.productSAST.EnrolDate);
        this.productSAST.NextReviewDate = this.convertToUTC(this.productSAST.NextReviewDate);

        this.productSAST.SDLCRequestStatusId = this.productSDLCRequestStatuses.find(status => status.Name === 'Requested')?.Id;
  
        this.productSDLCService.createProductSDLC(this.productSAST).subscribe((newSast) => {
          const sastId = newSast.Id;
          this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Created SAST Request', life: 3000 });
          this.logService.sendInfoMessage('CREATE', 'product/' + this.productId + '/sdlc/sast/', this.productSAST);

          this.sdlcNewSastContacts.forEach((contact) => {
            const productUser = {
              UserGraphId: contact.id,
              Name: contact.displayName,
              Mail: contact.displayName,
              ProductSDLCId: sastId
            };
  
            if (productUser.UserGraphId && productUser.Name && productUser.Mail && sastId) {
              this.productSDLCService.createSDLCPrimaryContact(productUser).subscribe((response) => {
                this.messageService.add({ severity: 'success', summary: 'Successful', detail: `${productUser.Name} added`, life: 3000 });
                this.logService.sendInfoMessage('CREATE', 'product/' + this.productId + '/sdlc/sast/' + sastId + "/users", productUser);
              });
            }
          });

          const toEmails = this.toEmailService.getToEmails();

          // Define the list of CC recipients
          const ccEmails = this.sdlcNewSastContacts.map(contact => contact.mail);

          const emailContent = this.emailTemplateService.generateEmailContent('SAST', this.productName, this.productId, this.productReference, sastId, 'sdlc/sast');
          this.sendEmail(toEmails, ccEmails, `SAST Created - ${this.productName} (${this.productId})`, emailContent);

          this.sastDialog = false;
          this.productSAST = {};
          this.sdlcNewSastContacts = [];
          this.selectedSastId = undefined;
          this.submitted = false;
          
          this.fetchSASTData(true, 'Requested');
        });
      }
      // this.submitted = false;
    }

    addNewSastContact() {
      if (this.selectedUser != null) {
        const userExists = this.sdlcNewSastContacts.some(contact => contact.id === this.selectedUser!.id);
        if (userExists) {
          this.messageService.add({ severity: 'warn', summary: 'Warning', detail: 'User already added as new contact', life: 3000 });
        } else {
          this.sdlcNewSastContacts.push(this.selectedUser);
          this.hideUserDialog();
        }
      }
      this.selectedUser = null;
    }
  
    removeNewSastContact(contact: User) {
      const index = this.sdlcNewSastContacts.indexOf(contact);
      if (index !== -1) {
          this.sdlcNewSastContacts.splice(index, 1);
      }
    }
  
    onStatusChange(shouldReset: boolean = true) {
      if (shouldReset) {
        this.selectedSastId = undefined;
        this.selectedSastOption = {};
        this.editedProductSDLC = {};
        this.sdlcPrimaryContacts = [];
        this.hasSelectedOption = false;
      }
  
      if (this.selectedStatus) {
        this.filteredProductSDLCSAST = this.productSDLCSAST.filter(sast => sast.SDLCRequestStatusName === this.selectedStatus);
      } else {
        // If no status selected, reset the array to the original data
        this.filteredProductSDLCSAST = this.productSDLCSAST;
      }
    }
    
    onSubmit() {
      if (this.submitted) {
        return;
      }
  
      this.submitted = true;
  
      if (this.selectedSastOption && this.selectedSastId) {
        this.editedProductSDLC.EnrolDate = this.convertToUTC(this.editedProductSDLC.EnrolDate);
        this.editedProductSDLC.NextReviewDate = this.convertToUTC(this.editedProductSDLC.NextReviewDate);
        
        this.productSDLCService.updateProductSDLC(this.selectedSastId, this.editedProductSDLC).subscribe((response) => {
          this.messageService.add({ severity: 'success', summary: 'SAST updated successfully', });
          this.submitted = false;
          this.fetchSASTData(false);
          this.logService.sendInfoMessage('UPDATE', 'product/' + this.productId + '/sdlc/sast/', this.editedProductSDLC, this.selectedSastOption);
        }, (error) => {
          this.messageService.add({ severity: 'error', summary: 'Error! Possibly no changes made to the form. Please try again.', });
          this.submitted = false;
          console.error('Error updating SDLC', error);
        });
      } else {
        this.messageService.add({ severity: 'error', summary: 'Select a practice from the left menu', });
        this.submitted = false;
      }
    }
  
    getToolImageUrl(toolName: string | undefined): string {
      switch (toolName) {
        case 'Sonarqube':
        case 'Sonarqube (Enterprise)': 
          return 'assets/layout/images/Sonarqube-logo.png';
        case 'Sonarcloud':
          return 'assets/layout/images/Sonarcloud-logo.png';
        // Add cases for other tools as needed
        default:
          return 'assets/layout/images/default-tool-logo.png'; // Fallback image
      }
    }

    addPrimaryContact() {
      if (this.selectedUser && this.productId) {
        const userExists = this.sdlcPrimaryContacts.some(contact => contact.UserGraphId === this.selectedUser!.id);
        if (userExists) {
          this.messageService.add({ severity: 'warn', summary: 'Warning', detail: 'User already added', life: 3000 });
        } else {
          const productUser = {
            UserGraphId: this.selectedUser.id,
            Name: this.selectedUser.displayName,
            Mail: this.selectedUser.mail,
            ProductSDLCId: this.selectedSastId
          };
    
          if (productUser.UserGraphId && productUser.Name && productUser.Mail && productUser.ProductSDLCId && this.selectedSastId) {
            this.productSDLCService.createSDLCPrimaryContact(productUser).subscribe((response) => {
              this.messageService.add({ severity: 'success', summary: 'Successful', detail: `${productUser.Name} added`, life: 3000 });
              this.logService.sendInfoMessage('CREATE', 'product/' + this.productId + '/sdlc/sast/' + this.selectedSastId + "/users", productUser);

              this.fetchProductSDLCContacts();
              this.hideUserDialog();
            });
          }
        }
      }
    }
  
    removeUser(contact: SDLCPrimaryContact) {
      this.confirmationService.confirm({
          message: 'Are you sure you want to remove this user?',
          accept: () => {
              // User confirmed, perform deletion
              const index = this.sdlcPrimaryContacts.indexOf(contact);
              if (index !== -1 && contact.Id) {
                  this.sdlcPrimaryContacts.splice(index, 1);
                  // Perform the actual deletion from your backend here if necessary
                  this.productSDLCService.deleteSDLCPrimaryContact(contact.Id).subscribe((response) => {
                    this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'User removed', life: 3000 });
                    this.logService.sendInfoMessage('DELETE', 'product/' + this.productId + '/sdlc/sast/' + this.selectedSastId + "/users/" + contact.Id, null, contact);
                  });
              }
          },
          reject: () => {
            this.messageService.add({ severity: 'error', summary: 'Rejected', detail: 'You have rejected' });
        }
      });
   }

   deleteSast(sast: ProductSDLC) {
    this.confirmationService.confirm({
        message: `Are you sure you want to delete this SAST? (Id: ${sast.Id})`,
        header: 'Delete Confirmation',
        icon: 'pi pi-info-circle',
        acceptButtonStyleClass:"p-button-danger p-button-text",
        rejectButtonStyleClass:"p-button-text p-button-text",
        acceptIcon:"none",
        rejectIcon:"none",
        accept: () => {
            // User confirmed, perform deletion
            if (sast.Id) {
                this.productSDLCService.deleteProductSDLC(sast.Id).subscribe((response) => {
                    this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'SAST deleted', life: 3000 });
                    this.fetchSASTData(false, this.selectedStatus);
                    this.logService.sendInfoMessage('DELETE', 'product/' + this.productId + '/sdlc/sast/' + sast.Id, null, sast);
                });
            }
        }
    });
  }

    // Emails

    sendEmail(toEmails: string[], ccEmails: string[], subject: string, htmlContent: string) {
      const emailRequest: EmailRequestNewDto = {
          toEmails: toEmails,
          ccEmails: ccEmails,
          subject: subject,
          htmlContent: htmlContent,
          plainTextContent: '' // Assuming no plain text content in this case
      };
  
      this.emailService.sendEmailNew(emailRequest).subscribe(response => {
          console.log('Email sent successfully', response);
      }, error => {
          console.error('Error sending email', error);
      });
    }
    convertToUTC(date: Date | undefined): Date | undefined {
      if (date) {
        return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
      }
      return undefined;
    }
  }
