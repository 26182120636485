<p-progressSpinner class="progress-spinner" *ngIf="isLoading"></p-progressSpinner>

<div class="flex gap-2 align-items-center">
    <h1>DAST</h1>
    <a href="https://irissoftware.sharepoint.com/sites/EngineeringIntranet/SitePages/SDLC--DAST-with-ZAP.aspx" target="_blank">
        <p-button icon="pi pi-question"></p-button>
    </a>
</div>

<div>
    <div *ngIf="!hasSelectedOption && !hasApprovedDASTMessage">
        <p-messages [(value)]="messages2" [enableService]="false" [closable]="false"></p-messages>
    </div>
    <p-toast></p-toast>
    
    <div class="flex justify-content-between mb-2">
        <p-dropdown [options]="productSDLCRequestStatuses" [(ngModel)]="selectedStatus"
                    placeholder="Select Status" (onChange)="onStatusChange()" optionLabel="Name" optionValue="Name">
            <ng-template let-option pTemplate="item">
                <div [ngClass]="'sdlcrequeststatus-badge sdlc-' + option.Name">
                    {{ option.Name }}
                </div>
            </ng-template>
            <ng-template let-option pTemplate="selectedItem">
                <div [ngClass]="'sdlcrequeststatus-badge sdlc-' + option.Name">
                    {{ option.Name }}
                </div>
            </ng-template>
        </p-dropdown>
        <button *ngIf="(tier === 'Tier 0' || tier === 'Tier 1')" pButton pRipple label="New Request" icon="pi pi-plus" class="p-button-success" (click)="openNew()"></button>
    </div>
    <p-splitter [panelSizes]="[25, 75]" [style]="{ height: 'auto' }" styleClass="mb-5">
        <ng-template pTemplate let-splitter1>
            <div class="col flex flex-column align-items-center gap-3" style="height: 100%; overflow-y: auto;">
                <div [style.background-color]="dast.Id === selectedDastId ? '#e0ecff' : 'inherit'" class="card mb-0 border-round w-full" *ngFor="let dast of filteredProductSDLCDAST">
                    <div class="flex justify-content-between align-items-center mb-3 gap-5">
                        <div class="flex align-items-center justify-content-center border-round" [ngStyle]="{width: '6.5rem', height: '6.5rem'}">
                            <img [src]="getToolImageUrl(dast.SDLCToolName)" alt="" width="100%">
                        </div>
    
                        <div class="flex flex-column justify-content-center gap-1" [ngStyle]="{width: '8rem'}">
                            <span class="block text-900 font-medium text-xl">{{ dast.SDLCToolName }}</span>
                            <div class="text-500 font-medium">{{ dast.PracticeMaturityLevelName }}</div>
                            <div class="text-500 font-bold">{{ dast.Id }}</div>
                            <div [class]="'sdlcrequeststatus-badge sdlc-' + dast.SDLCRequestStatusName">{{ dast.SDLCRequestStatusName }}</div>
                        </div>
                        <div class="flex flex-column gap-2">
                            <button pButton icon="pi pi-chevron-right" class="p-button-outlined p-button-raised" (click)="showDastDetails(dast)"></button>
                            <button *appHasRole="['Lens.Admin']" pButton icon="pi pi-trash" class="p-button-outlined p-button-raised p-button-danger" (click)="deleteDast(dast)"></button>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
    
        <ng-template pTemplate let-splitter2>
            <div class="col flex justify-content-center">
                <div class="col-12">
                    <div class="card">
                        <div *ngIf="!hasSelectedOption && hasApprovedDASTMessage">
                            <p-messages [(value)]="messages" [enableService]="false" [closable]="true"></p-messages>
                        </div>
    
                        <div [hidden]="!hasSelectedOption">
                            <h5>DAST Form</h5>
                            <div class="p-fluid p-formgrid grid">
                            <div class="field col-12 md:col-6">
                                <label for="tmTool">Tool</label>
                                <p-dropdown appendTo="body" [(ngModel)]="editedProductSDLC.SDLCToolId" inputId="SDLCToolId" [options]="productSDLCTools" placeholder="Select" optionValue="Id">
                                    <ng-template pTemplate="selectedItem">
                                        <span *ngIf="editedProductSDLC.SDLCToolId">{{ getToolNameById(editedProductSDLC.SDLCToolId) }}</span>
                                    </ng-template>
                                    <ng-template let-option pTemplate="item">
                                        <span>{{option.Name}}</span>
                                    </ng-template>
                                </p-dropdown>
                            </div>
                            <div class="field col-12 md:col-4">
                                <label for="nextReviewDate">Next Review Date</label>
                                <p-calendar appendTo="body" [(ngModel)]="editedProductSDLC.NextReviewDate" [showIcon]="true" id="nextReviewDate" dateFormat="dd/mm/yy"></p-calendar>
                            </div>
                            <div class="field col-12 md:col-6">
                                <label for="dastMaturity">Maturity</label>
                                <p-dropdown appendTo="body" [(ngModel)]="editedProductSDLC.PracticeMaturityLevelId" inputId="PracticeMaturityLevelId" [options]="productSDLCPracticeStatuses" placeholder="Select" optionValue="Id">
                                    <ng-template pTemplate="selectedItem">
                                        <span *ngIf="editedProductSDLC.PracticeMaturityLevelId">{{ getPracticeStatusNameById(editedProductSDLC.PracticeMaturityLevelId) }}</span>
                                    </ng-template>
                                    <ng-template let-option pTemplate="item">
                                        <span>{{option.Name}}</span>
                                    </ng-template>
                                </p-dropdown>
                            </div>
                            <div class="field col-12 md:col-6" *ngIf="getToolNameById(editedProductSDLC.SDLCToolId) == 'Tenable WAS'">
                                <label for="dastScanType">Scan Type</label>
                                <!-- <p-messages [(value)]="messages4" [enableService]="false" [closable]="false" [escape]="false"></p-messages> -->
                                <p-dropdown appendTo="body" [(ngModel)]="editedProductSDLC.SDLCNPDastScanTypeId" inputId="SDLCNPDastScanTypeId" [disabled]="true" [options]="productSDLCScanTypes" placeholder="Select" optionValue="Id">
                                    <ng-template pTemplate="selectedItem">
                                        <span *ngIf="editedProductSDLC.SDLCNPDastScanTypeId">{{ getScanTypeNameById(editedProductSDLC.SDLCNPDastScanTypeId) }}</span>
                                    </ng-template>
                                    <ng-template let-option pTemplate="item">
                                        <span>{{option.Name}}</span>
                                    </ng-template>
                                </p-dropdown>
                            </div>
                            <div class="field col-12 md:col-6">
                                <label for="dastEnvironment">DAST Environment</label>
                                <p-dropdown appendTo="body" [(ngModel)]="editedProductSDLC.SDLCEnvironmentId" inputId="SDLCDastEnvironmentId" [options]="productSDLCEnvironments" placeholder="Select" optionValue="Id">
                                    <ng-template pTemplate="selectedItem">
                                        <span *ngIf="editedProductSDLC.SDLCEnvironmentId">{{ getEnvironmentNameById(editedProductSDLC.SDLCEnvironmentId) }}</span>
                                    </ng-template>
                                    <ng-template let-option pTemplate="item">
                                        <span>{{option.Name}}</span>
                                    </ng-template>
                                </p-dropdown>
                            </div>
                            <!-- DAST URL -->
                            <div class="field col-12 md:col-12">
                                <label for="dastUrl">DAST URLs</label>
                                <div><textarea [(ngModel)]="editedProductSDLC.DastURL" pInputTextarea style="width: 100%" name="" id="" cols="30" rows="3"></textarea></div>
                            </div>
                            <div class="field col-12 md:col-12">
                                <p-confirmDialog></p-confirmDialog>
                                <label for="primaryContact">Primary Contacts</label>
                                <div>
                                    <ng-container *ngFor="let contact of sdlcPrimaryContacts">
                                        <p-chip styleClass="m-1">
                                            <img src="assets/layout/images/iris-circle-logo.png" alt="" width="60px" height="" class="shadow-2 border-circle">
                                            <span pTooltip={{contact.Mail}} tooltipPosition="bottom" [hideDelay]="2000">{{ contact.Name }}</span>
                                            <button *ngIf="{isIncludedInSDLC}" pButton icon="pi pi-times" class="p-button-rounded p-button-danger p-button-text" (click)="removeUser(contact)"></button>
                                        </p-chip>
                                    </ng-container>
                                </div>
                                <button *ngIf="{isIncludedInSDLC}" pButton pRipple icon="pi pi-plus" class="p-button-success p-button-rounded" (click)="openUserDialog('addPrimaryContact')" [disabled]="!hasSelectedOption"></button>
                            </div>
                            <div class="field col-12 md:col-4">
                                <label for="enrolDate">Enrol Date</label>
                                <p-calendar appendTo="body" [(ngModel)]="editedProductSDLC.EnrolDate" [showIcon]="true" id="eolDate" dateFormat="dd/mm/yy"></p-calendar>
                            </div>
                            <div class="field col-12 md:col-12">
                                <label for="comment">Comment</label>
                                <div><textarea [(ngModel)]="editedProductSDLC.Comment" pInputTextarea style="width: 100%" name="" id="" cols="30" rows="5"></textarea></div>
                            </div>
                                </div>
                                    <div style="display: flex; justify-content: flex-end;">
                                    <p-button *ngIf="{isIncludedInSDLC}" label="Submit" (click)="onSubmit()" [disabled]="!hasSelectedOption"></p-button>
                                </div>
                        </div>
                </div>
            </div>
        </div>
        </ng-template>
    </p-splitter>
    
    
    
    <p-dialog [(visible)]="dastDialog" [style]="{width: '600px'}" header="Request new DAST Tool" [modal]="true" class="p-fluid">
        <ng-template pTemplate="content">
    
            <div class="field">
                <label for="tmTool">Tool</label>
                <p-dropdown appendTo="body" [(ngModel)]="productDAST.SDLCToolId" inputId="SDLCToolId" [options]="productSDLCTools"
                            placeholder="Select" optionValue="Id" (onChange)="onToolChange($event)" [required]="true">
                    <ng-template pTemplate="selectedItem">
                        <span *ngIf="productDAST.SDLCToolId">{{ getToolNameById(productDAST.SDLCToolId) }}</span>
                    </ng-template>
                    <ng-template let-option pTemplate="item">
                        <span [ngClass]="{'recommended-option': option.Name === 'Tenable WAS'}">
                            {{ option.Name }}
                            <span *ngIf="option.Name === 'Tenable WAS'" class="recommended-label">(Required)</span>
                            <span *ngIf="option.Name !== 'Tenable WAS'" class="optional-label">(Optional)</span>
                        </span>
                    </ng-template>
                </p-dropdown>
            </div>
    
            <p-messages [(value)]="messages3" [enableService]="false" [closable]="true" [escape]="false"></p-messages>

            <div class="field">
                <label for="dastMaturity">Maturity</label>
                <p-dropdown appendTo="body" [(ngModel)]="productDAST.PracticeMaturityLevelId" inputId="PracticeMaturityLevelId" [options]="productSDLCPracticeStatuses" placeholder="Select" optionValue="Id">
                    <ng-template pTemplate="selectedItem">
                        <span *ngIf="productDAST.PracticeMaturityLevelId">{{ getPracticeStatusNameById(productDAST.PracticeMaturityLevelId) }}</span>
                    </ng-template>
                    <ng-template let-option pTemplate="item">
                        <span>{{option.Name}}</span>
                    </ng-template>
                </p-dropdown>
            </div>
            <div class="field" *ngIf="isTenableWASSelected">
                <label for="dastScanType">Scan Type</label>
                <p-dropdown appendTo="body" [(ngModel)]="productDAST.SDLCNPDastScanTypeId" inputId="SDLCNPDastScanTypeId"
                            [options]="productSDLCScanTypes" [disabled]="true" placeholder="Authenticated">
                    <ng-template pTemplate="selectedItem">
                        <span>{{ getScanTypeNameById(productDAST.SDLCNPDastScanTypeId) }}</span>
                    </ng-template>
                    <ng-template let-option pTemplate="item">
                        <span>{{ option.Name }}</span>
                    </ng-template>
                </p-dropdown>
            </div>
    
            <div class="field col-12 md:col-12">
                <p-confirmDialog></p-confirmDialog>
                <label for="primaryContact">Primary Contacts</label>
                <div>
                    <ng-container *ngFor="let contact of sdlcNewDastContacts">
                        <p-chip styleClass="m-1">
                            <img src="assets/layout/images/iris-circle-logo.png" alt="" width="60px" height="auto" class="shadow-2 border-circle">
                            <span pTooltip={{contact.mail}} tooltipPosition="bottom" [hideDelay]="2000">{{ contact.displayName }}</span>
                            <button pButton icon="pi pi-times" class="p-button-rounded p-button-danger p-button-text" (click)="removeNewDastContact(contact)"></button>
                        </p-chip>
                    </ng-container>
                    <button pButton pRipple icon="pi pi-plus" class="p-button-success p-button-rounded" (click)="openUserDialog('addNewDastContact')"></button>
                </div>
            </div>
    
            <div class="field">
                <label for="enrolDate">Enrol Date</label>
                <p-calendar appendTo="body" [(ngModel)]="productDAST.EnrolDate" [showIcon]="true" id="eolDate" dateFormat="dd/mm/yy"></p-calendar>
            </div>
    
            <div class="field">
                <label for="nextReviewDate">Next Review Date</label>
                <p-calendar appendTo="body" [(ngModel)]="productDAST.NextReviewDate" [showIcon]="true" id="nextReviewDate" dateFormat="dd/mm/yy"></p-calendar>
            </div>

            <div class="field col-12 md:col-12">
                <label for="dastUrl">DAST URLs</label>
                <div><textarea [(ngModel)]="productDAST.DastURL" pInputTextarea style="width: 100%" name="" id="" cols="30" rows="3"></textarea></div>
            </div>
    
            <div class="field col-12 md:col-12">
                <label for="comment">Comment</label>
                <div><textarea [(ngModel)]="productDAST.Comment" pInputTextarea style="width: 100%" name="" id="" cols="30" rows="5"></textarea></div>
            </div>
        </ng-template>
    
        <ng-template pTemplate="footer">
            <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="hideDialog()"></button>
            <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text" (click)="saveNewDAST()"></button>
        </ng-template>
    </p-dialog>
</div>

<p-dialog [(visible)]="userDialog" [modal]="true" [style]="{width: '750px'}" header="Add new user" [contentStyle]="{'overflow': 'visible'}" class="p-fluid">
    <ng-template pTemplate="content">
        <div class="field">
            <p-dropdown [options]="users" scrollHeight="300px" [(ngModel)]="selectedUser" [style]="{'width':'100%'}" optionLabel="displayName" [filter]="true" (onFilter)="searchUsers($event)" [showClear]="true" placeholder="Select a User">
                <ng-template pTemplate="selectedItem">
                    <div class="flex align-items-center gap-2" *ngIf="selectedUser">
                        <div>{{ selectedUser.displayName }}</div>
                    </div>
                </ng-template>
                <ng-template let-user pTemplate="item">
                    <div class="flex align-items-center gap-2">
                        <img src="assets/layout/images/iris-circle-logo.png" alt="" width="60px" height="" class="shadow-2 border-circle">
                        <div class="flex w-full gap-2 flex-column p-4">
                            <div>{{ user.displayName }}</div>
                            <div>({{ user.mail }})</div>
                        </div>
                    </div>
                </ng-template>
            </p-dropdown>
        </div>
    </ng-template>

    <ng-template pTemplate="footer">
        <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="hideUserDialog()"></button>
        <button *ngIf="userDialogAction === 'addPrimaryContact'" pButton pRipple label="Add" icon="pi pi-check" class="p-button-text" (click)="addPrimaryContact()"></button>
        <button *ngIf="userDialogAction === 'addNewDastContact'" pButton pRipple label="Add" icon="pi pi-check" class="p-button-text" (click)="addNewDastContact()"></button>
    </ng-template>
</p-dialog>

<div *ngIf="!isLoading && !isIncludedInSDLC || tier == ''">
    <div class="alert alert-danger">
      <strong>Read only mode</strong> Only Cloud products can request DAST.
    </div>
</div>
