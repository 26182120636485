import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, map } from "rxjs";
import { NFRCategoryDto, NFRCategoryRequestDto, NonFunctionalRequirementDto, NonFunctionalRequirementRequestDto, ProductNFRDto, ProductNFRRequestDto } from "src/app/api/product-nfr";
import { environment } from "src/environments/environment";

@Injectable(
    {
        providedIn: 'root'
    }
)

export class ProductNFRService {
    baseApiUrl: string = environment.baseApiUrl;
    constructor(private http: HttpClient) { }

    // Product NFR
    getProductNFRs(objectId: number, productTypeId: number): Observable<ProductNFRDto[]> {
        return this.http.get<any>(this.baseApiUrl + `/api/ProductNFRs?objectId=${objectId}&productTypeId=${productTypeId}`).pipe(
            map((response) => {
            // Check if the response has a "value" property
            if (response && response.value) {
                // Return the array of objects from the "value" property
                return response.value;
            } else {
                // If there is no "value" property, return the entire response
                return response;
            }
            })
        );
    }

    getProductNFRById(productNFRId: number): Observable<ProductNFRDto> {
        return this.http.get<ProductNFRDto>(this.baseApiUrl + `/api/ProductNFRs/${productNFRId}`);
    }

    createProductNFR(productNFR: ProductNFRRequestDto): Observable<ProductNFRRequestDto> {
        return this.http.post<ProductNFRDto>(this.baseApiUrl + `/api/ProductNFRs`, productNFR);
    }

    updateProductNFR(productNFRId: number, productNFR: ProductNFRRequestDto): Observable<ProductNFRRequestDto> {
        return this.http.put<ProductNFRDto>(this.baseApiUrl + `/api/ProductNFRs/${productNFRId}`, productNFR);
    }

    deleteProductNFR(productNFRId: number): Observable<ProductNFRDto> {
        return this.http.delete<ProductNFRDto>(this.baseApiUrl + `/api/ProductNFRs/${productNFRId}`);
    }

    // NFR Categories

    getAllNFRCategories(): Observable<NFRCategoryDto[]> {
        return this.http.get<any>(this.baseApiUrl + `/api/NFRCategories`).pipe(
            map((response) => {
            // Check if the response has a "value" property
            if (response && response.value) {
                // Return the array of objects from the "value" property
                return response.value;
            } else {
                // If there is no "value" property, return the entire response
                return response;
            }
            })
        );
    }

    getNFRCategoriesById(nfrCategoryId: number): Observable<NFRCategoryDto> {
        return this.http.get<NFRCategoryDto>(this.baseApiUrl + `/api/NFRCategories/${nfrCategoryId}`);
    }

    createNFRCategories(nfrCategory: NFRCategoryRequestDto): Observable<NFRCategoryRequestDto> {
        return this.http.post<NFRCategoryRequestDto>(this.baseApiUrl + `/api/NFRCategories`, nfrCategory);
    }

    updateNFRCategories(nfrCategoryId: number, nfrCategory: NFRCategoryRequestDto): Observable<NFRCategoryRequestDto> {
        return this.http.put<NFRCategoryRequestDto>(this.baseApiUrl + `/api/NFRCategories/${nfrCategoryId}`, nfrCategory);
  }

  deleteNFRCategories(nfrCategoryId: number): Observable<NFRCategoryDto> {
    return this.http.delete<NFRCategoryDto>(this.baseApiUrl + `/api/NFRCategories/${nfrCategoryId}`);
    }

    // Non-Functional Requirements

    getAllNonFunctionalRequirements(): Observable<ProductNFRDto[]> {
        return this.http.get<any>(this.baseApiUrl + `/api/NonFunctionalRequirements`).pipe(
            map((response) => {
            // Check if the response has a "value" property
            if (response && response.value) {
                // Return the array of objects from the "value" property
                return response.value;
            } else {
                // If there is no "value" property, return the entire response
                return response;
            }
            })
        );
    }

    getNonFunctionalRequirementById(nonFunctionalRequirementId: number): Observable<ProductNFRDto> {
        return this.http.get<ProductNFRDto>(this.baseApiUrl + `/api/NonFunctionalRequirements/${nonFunctionalRequirementId}`);
    }

  createNonFunctionalRequirement(nonFunctionalRequirement: NonFunctionalRequirementRequestDto): Observable<NonFunctionalRequirementDto> {
    return this.http.post<NonFunctionalRequirementRequestDto>(this.baseApiUrl + `/api/NonFunctionalRequirements`, nonFunctionalRequirement);
    }

  updateNonFunctionalRequirement(nonFunctionalRequirementId: number, nonFunctionalRequirement: NonFunctionalRequirementRequestDto): Observable<NonFunctionalRequirementDto> {
    return this.http.put<NonFunctionalRequirementRequestDto>(this.baseApiUrl + `/api/NonFunctionalRequirements/${nonFunctionalRequirementId}`, nonFunctionalRequirement);
  }

  deleteNonFunctionalRequirement(nonFunctionalRequirementId: number): Observable<NonFunctionalRequirementDto> {
    return this.http.delete<NonFunctionalRequirementDto>(this.baseApiUrl + `/api/NonFunctionalRequirements/${nonFunctionalRequirementId}`);
  }

}
