import { HttpClient, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, map, catchError, throwError } from "rxjs";
import { PracticeMaturityLevelDto, ProductSDLC, ProductSDLCPutDto, ProductSDLCRequestDto, SDLCIRStatusDto, SDLCPracticeStatusDto, SDLCPrimaryContact, SDLCPrimaryContactRequestDto, SDLCRequestStatusDto, SDLCTMStatusDto, SDLCToolDto } from "src/app/api/product-sdlc";
import { SDLCReport } from "src/app/api/sdlc-maturity";
import { environment } from "src/environments/environment";
import { PracticeMaturityLevel, PracticeMaturityLevelRequestDto, PracticeMaturityLevelPutDto, SDLCPracticeMaturity, SDLCPracticeMaturityPutDto } from "../../../api/sdlc-maturity";

@Injectable(
    {
        providedIn: 'root'
    }
)

export class ProductSDLCService {
    baseApiUrl: string = environment.baseApiUrl;
    constructor(private http: HttpClient) { }

    getAllSDLCRequests(): Observable<ProductSDLC[]> {
        const sdlcNames = ['SCA', 'DAST', 'SAST', 'NP-DAST']; // Define the SDLC practice names
        const filterString = sdlcNames.map(name => `(SDLCPracticeName eq '${name}')`).join(' or ');
    
        return this.http.get<any>(`${this.baseApiUrl}/api/ProductSDLCs?$filter=${filterString}`).pipe(
            map((response) => {
                // Check if the response has a "value" property
                if (response && response.value) {
                    // Return the array of objects from the "value" property
                    return response.value;
                } else {
                    // If there is no "value" property, return the entire response
                    return response;
                }
            })
        );
    }

    getProductSDLCs(productID: string): Observable<any> {
        return this.http.get<any>(`${this.baseApiUrl}/api/ProductSDLCs/${productID}/`);
    }

    getProductSDLCThreatModelling(productID: string): Observable<any> {
        return this.http.get<any>(`${this.baseApiUrl}/api/ProductSDLCs/${productID}?$filter=SDLCPracticeName eq 'Threats'`);
    }

    updateProductSDLC(id: number, productSDLCPutDto: ProductSDLCPutDto): Observable<ProductSDLCPutDto> {
        return this.http.put<ProductSDLCPutDto>(`${this.baseApiUrl}/api/ProductSDLCs/${id}/`, productSDLCPutDto).pipe(
            map((response) => {
                return response;
            }
        ));
    }

    deleteProductSDLC(id: number): Observable<any> {
        return this.http.delete<any>(`${this.baseApiUrl}/api/ProductSDLCs/${id}`);
    }

    updateProductSDLCStatus(id: number, newStatus: any): Observable<any> {
        return this.http.put<any>(`${this.baseApiUrl}/api/ProductSDLCs/${id}/`, newStatus);
    }

    createProductSDLC(ProductSDLCRequestDto: ProductSDLCRequestDto): Observable<any> {
        return this.http.post<any>(`${this.baseApiUrl}/api/ProductSDLCs/`, ProductSDLCRequestDto);
    }

    getAllSDLCTools(toolType: string): Observable<SDLCToolDto[]> {
        return this.http.get<any>(`${this.baseApiUrl}/api/SDLCTools/?$filter=SDLCPracticeName eq '${toolType}'`).pipe(
            map((response) => {
            // Check if the response has a "value" property
            if (response && response.value) {
                // Return the array of objects from the "value" property
                return response.value;
            } else {
                // If there is no "value" property, return the entire response
                return response;
            }
            })
        );
    }

    getAllSDLCTMStatuses(): Observable<SDLCTMStatusDto[]> {
        return this.http.get<any>(`${this.baseApiUrl}/api/SDLCTMStatuses/`).pipe(
            map((response) => {
            // Check if the response has a "value" property
            if (response && response.value) {
                // Return the array of objects from the "value" property
                return response.value;
            } else {
                // If there is no "value" property, return the entire response
                return response;
            }
            })
        );
    }



    // SCA

    // get Product SDLC SCA
    getProductSDLCSCA(productID: string): Observable<any> {
        return this.http.get<any>(`${this.baseApiUrl}/api/ProductSDLCs/${productID}?$filter=SDLCPracticeName eq 'SCA'`);
    }

    getProductSDLCDAST(productID: string): Observable<any> {
        return this.http.get<any>(`${this.baseApiUrl}/api/ProductSDLCs/${productID}?$filter=SDLCPracticeName eq 'DAST'`);
    }

    getProductSDLCSAST(productID: string): Observable<any> {
        return this.http.get<any>(`${this.baseApiUrl}/api/ProductSDLCs/${productID}?$filter=SDLCPracticeName eq 'SAST'`);
    }

    getProductSDLCNPDAST(productID: string): Observable<any> {
        return this.http.get<any>(`${this.baseApiUrl}/api/ProductSDLCs/${productID}?$filter=SDLCPracticeName eq 'NP-DAST'`);
    }

    getProductSDLCNPDastById(productID: string, sdlcId: number): Observable<any> {
        return this.http.get<any>(`${this.baseApiUrl}/api/ProductSDLCs/${productID}?$filter=SDLCPracticeName eq 'NP-DAST' and Id eq ${sdlcId}`);
    }

    // get SDLC request statuses
    getAllSDLCRequestStatuses(): Observable<SDLCRequestStatusDto[]> {
        return this.http.get<any>(`${this.baseApiUrl}/api/SDLCRequestStatuses/`).pipe(
            map((response) => {
            // Check if the response has a "value" property
            if (response && response.value) {
                // Return the array of objects from the "value" property
                return response.value;
            } else {
                // If there is no "value" property, return the entire response
                return response;
            }
            })
        );
    }

    getSDLCPractice(sdlcPractice: string): Observable<any> {
        return this.http.get<any>(`${this.baseApiUrl}/api/SDLCPractices/?$filter=Name eq '${sdlcPractice}'`).pipe(
            map((response) => {
            // Check if the response has a "value" property
            if (response && response.value) {
                // Return the array of objects from the "value" property
                return response.value;
            } else {
                // If there is no "value" property, return the entire response
                return response;
            }
            })
        );
  }

  getSDLCPractices(): Observable<any> {
    return this.http.get<any>(`${this.baseApiUrl}/api/SDLCPractices`).pipe(
      map((response) => {
        // Check if the response has a "value" property
        if (response && response.value) {
          // Return the array of objects from the "value" property
          return response.value;
        } else {
          // If there is no "value" property, return the entire response
          return response;
        }
      })
    );
  }

  getSDLCPracticeById(practiceId: string): Observable<any> {
    return this.http.get<any>(`${this.baseApiUrl}/api/SDLCPractices/${practiceId}`).pipe(
      map((response) => {
        // Check if the response has a "value" property
        if (response && response.value) {
          // Return the array of objects from the "value" property
          return response.value;
        } else {
          // If there is no "value" property, return the entire response
          return response;
        }
      })
    );
  }

    getAllSDLCPracticeStatuses(): Observable<SDLCPracticeStatusDto[]> {
        return this.http.get<any>(`${this.baseApiUrl}/api/SDLCPracticeStatuses/`).pipe(
            map((response) => {
            // Check if the response has a "value" property
            if (response && response.value) {
                // Return the array of objects from the "value" property
                return response.value;
            } else {
                // If there is no "value" property, return the entire response
                return response;
            }
            })
        );
    }

    getAllSDLCNPDASTScanTypes(): Observable<any> {
        return this.http.get<any>(`${this.baseApiUrl}/api/SDLCNPDASTScanTypes/`).pipe(
            map((response) => {
            // Check if the response has a "value" property
            if (response && response.value) {
                // Return the array of objects from the "value" property
                return response.value;
            } else {
                // If there is no "value" property, return the entire response
                return response;
            }
            })
        );
    }

    getAllSDLCEnvironments(): Observable<any> {
        return this.http.get<any>(`${this.baseApiUrl}/api/SDLCEnvironments/`).pipe(
            map((response) => {
            // Check if the response has a "value" property
            if (response && response.value) {
                // Return the array of objects from the "value" property
                return response.value;
            } else {
                // If there is no "value" property, return the entire response
                return response;
            }
            })
        );
    }

    getProductSDLCIncidentResponse(productID: string): Observable<any> {
        return this.http.get<any>(`${this.baseApiUrl}/api/ProductSDLCs/${productID}?$filter=SDLCPracticeName eq 'IR'`);
    }

    getAllSDLCIRStatuses(): Observable<SDLCIRStatusDto[]> {
        return this.http.get<any>(`${this.baseApiUrl}/api/SDLCIRStatuses/`).pipe(
            map((response) => {
            // Check if the response has a "value" property
            if (response && response.value) {
                // Return the array of objects from the "value" property
                return response.value;
            } else {
                // If there is no "value" property, return the entire response
                return response;
            }
            })
        );
    }

    getSDLCPrimaryContacts(ProductSDLCId: number): Observable<SDLCPrimaryContact[]> {
        return this.http.get<any>(`${this.baseApiUrl}/api/SDLCPrimaryContacts/?$filter=ProductSDLCId eq ${ProductSDLCId}`).pipe(
            map((response) => {
            // Check if the response has a "value" property
            if (response && response.value) {
                // Return the array of objects from the "value" property
                return response.value;
            } else {
                // If there is no "value" property, return the entire response
                return response;
            }
            })
        );
    }

    createSDLCPrimaryContact(SDLCPrimaryContactRequestDto: SDLCPrimaryContactRequestDto): Observable<SDLCPrimaryContactRequestDto> {
        return this.http.post<any>(`${this.baseApiUrl}/api/SDLCPrimaryContacts/`, SDLCPrimaryContactRequestDto);
    }

    deleteSDLCPrimaryContact(id: number): Observable<any> {
        return this.http.delete<any>(`${this.baseApiUrl}/api/SDLCPrimaryContacts/${id}`);
  }

  getPracticeMaturityLevels(practiceId: string): Observable<PracticeMaturityLevel[]> {
    return this.http.get<any>(`${this.baseApiUrl}/api/practicematuritylevels/?$filter=sdlcPracticeId eq ${practiceId}`).pipe(
      map((response) => {
        if (response && response.value) {
          return response.value;
        } else {
          return response;
        }
      })
    );
  }

  createPracticeMaturityLevel(practiceMaturityLevel: PracticeMaturityLevelRequestDto): Observable<PracticeMaturityLevel> {
    return this.http.post<any>(`${this.baseApiUrl}/api/practicematuritylevels`, practiceMaturityLevel);
  }

  deletePracticeMaturityLevel(id: number): Observable<any> {
    return this.http.delete<any>(`${this.baseApiUrl}/api/practicematuritylevels/${id}`);
  }

  updatePracticeMaturityLevel(id: number, practiceMaturityLevel: PracticeMaturityLevelPutDto): Observable<PracticeMaturityLevel> {
    return this.http.put<any>(`${this.baseApiUrl}/api/practicematuritylevels/${id}`, practiceMaturityLevel);
  }

  getSDLCPracticeMaturities(practiceId: string): Observable<SDLCPracticeMaturity[]> {
    return this.http.get<any>(`${this.baseApiUrl}/api/sdlcpracticematurities/?$filter=sdlcPracticeId eq ${practiceId}`).pipe(
      map((response) => {
        if (response && response.value) {
          return response.value;
        } else {
          return response;
        }
      })
    );
  }

  getSDLCPracticeMaturitiesByTier(practiceId: string, tierId: number | undefined): Observable<SDLCPracticeMaturity[]> {
    return this.http.get<any>(`${this.baseApiUrl}/api/sdlcpracticematurities/?$filter=sdlcPracticeId eq ${practiceId} and tierId eq ${tierId}`).pipe(
      map((response) => {
        if (response && response.value) {
          return response.value;
        } else {
          return response;
        }
      })
    );
  }

  createSDLCPracticeMaturity(sdlcPracticeMaturity: SDLCPracticeMaturityPutDto): Observable<SDLCPracticeMaturity> {
    return this.http.post<any>(`${this.baseApiUrl}/api/sdlcpracticematurities`, sdlcPracticeMaturity);
  }

  updateSDLCPracticeMaturity(id: number, sdlcPracticeMaturity: SDLCPracticeMaturityPutDto): Observable<SDLCPracticeMaturity> {
    return this.http.put<any>(`${this.baseApiUrl}/api/sdlcpracticematurities/${id}`, sdlcPracticeMaturity);
  }

  // New Practice Levels
  getAllSDLCPracticeMaturityLevels(): Observable<PracticeMaturityLevelDto[]> {
    return this.http.get<any>(`${this.baseApiUrl}/api/PracticeMaturityLevels/`).pipe(
        map((response) => {
        // Check if the response has a "value" property
        if (response && response.value) {
            // Return the array of objects from the "value" property
            return response.value;
        } else {
            // If there is no "value" property, return the entire response
            return response;
        }
        })
    );
  }

  getMaturityLevelByPracticeId(practiceId: number | undefined): Observable<PracticeMaturityLevelDto[]> {
      return this.http.get<any>(`${this.baseApiUrl}/api/PracticeMaturityLevels/?$filter=SDLCPracticeId eq ${practiceId}`).pipe(
          map((response) => {
          // Check if the response has a "value" property
          if (response && response.value) {
              // Return the array of objects from the "value" property
              return response.value;
          } else {
              // If there is no "value" property, return the entire response
              return response;
          }
          })
      );
  }

  getSdlcMaturtyReportByProduct(productID: string): Observable<SDLCReport[]> {
    return this.http.get<any>(`${this.baseApiUrl}/api/reports/sdlcmaturityreport?id=${productID}`).pipe(
      map((response) => {
        // Check if the response has a "value" property
        if (response && response.value) {
          // Return the array of objects from the "value" property
          return response.value;
        } else {
          // If there is no "value" property, return the entire response
          return response;
        }
      })
    );
  }

  getSdlcMaturtyReport(): Observable<SDLCReport[]> {
    return this.http.get<any>(`${this.baseApiUrl}/api/reports/sdlcmaturityreport`).pipe(
      map((response) => {
        // Check if the response has a "value" property
        if (response && response.value) {
          // Return the array of objects from the "value" property
          return response.value;
        } else {
          // If there is no "value" property, return the entire response
          return response;
        }
      })
    );
  }

}
