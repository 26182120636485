import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AssetDto, TagDto } from 'src/app/api/asset';
import { ToolExternalService } from 'src/app/layout/service/tool-external.service';
import { Message, MessageService } from 'primeng/api';

@Component({
  selector: 'app-asset-details',
  templateUrl: './asset-details.component.html'
})
export class AssetDetailsComponent implements OnInit {
  assetId: string = '';
  asset?: AssetDto = undefined;
  tags: TagDto[] = [];
  isLoading = true;
  assetName: string = '';

  isAWS: boolean = false;
  isAzure: boolean = false;

  messages: Message[] = [];

  opSystemTags: string[] = [];
  platformTags: string[] = [];

  constructor(private route: ActivatedRoute,
    private toolExternalService: ToolExternalService, private MessageService: MessageService) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.assetId = params['assetId'];

      this.toolExternalService.getAsset(this.assetId).subscribe((data: any) => {
        this.asset = data;
        this.tags = this.asset?.tags ?? [];
        this.assetName = this.asset?.hostnames?.[0] || this.assetId;

        this.messages = [{ severity: 'info', summary: 'Information', detail: 'Asset data is pulled into Lens nightly from Tenable Nessus. This information may not be fully up-to-date.' }];


        if (this.asset?.system_types?.includes("aws-ec2-instance")) {
          this.isAWS = true;
          this.platformTags.push("AWS");
        }
        if (this.asset?.system_types?.includes("azure-instance")) {
          this.isAzure = true;
          this.platformTags.push("Azure");
        }

        if (!this.isAWS && !this.isAzure) {
          this.platformTags.push("Rackspace");
        }

        if (this.asset?.operating_systems?.some(os => os.includes("Windows"))) {
          this.opSystemTags.push("Windows");
        }

        if (this.asset?.operating_systems?.some(os => os.includes("Linux"))) {
          this.opSystemTags.push("Linux");
        }


      });

    });
  }

  niceDate(date?: Date): string {
    if (date == null) {
      return "";
    }
    const options: Intl.DateTimeFormatOptions = {
      day: '2-digit',
      month: 'long',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false
    };
    return new Date(date).toLocaleString('en-GB', options);
  }

  getTenableLink(id: string): string {
    return "https://cloud.tenable.com/tio/app.html#/assets-uw/all-assets/list/details/host/" + id + "/findings";
  }

  tagify(option: string | undefined): string {
    if (!option) {
      return '';
    }
    let tagValue = option.trim();
    tagValue = tagValue.replace(/\s+/g, '_');
    tagValue = tagValue.replace(/[^a-zA-Z0-9_]/g, '');
    return tagValue;
  }
    
}
