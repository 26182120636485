import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, map } from "rxjs";
import { PentestContact, PentestContactRequestDto, PentestPreReqxDto, PentestProgressDto, PentestProviderDto, PentestRequestedByDto, PentestRiskDto, ProductPentestPutDto, ProductPentestRequestDto } from "src/app/api/product-pentest";
import { PTTCatDto, PTTComplexityDto, PTTContactDto, PTTContactRequestDto, PTTPriorityDto, PTTProgressDto, PTTSeverityDto, ProductPTTPutDto, ProductPTTRequestDto } from "src/app/api/product-ptt";

import { environment } from "src/environments/environment";

@Injectable(
    {
        providedIn: 'root'
    }
)

export class ProductPTTService {
    baseApiUrl: string = environment.baseApiUrl;
    constructor(private http: HttpClient) { }

    getProductPTT(productID: string): Observable<any> {
        return this.http.get<any>(`${this.baseApiUrl}/api/ProductPTTs/${productID}`);
    }

    getProductPTTByPentestId(pentestId: string): Observable<any> {
        return this.http.get<any>(`${this.baseApiUrl}/api/ProductPTTs?$expand=AssignedTo&$filter=ProductPentestId eq ${pentestId}`);
    }

    getProductPTTByOneDayPentestId(oneDayPentestId: string, productId: string): Observable<any> {
        return this.http.get<any>(`${this.baseApiUrl}/api/ProductPTTs?$expand=AssignedTo&$filter=OneDayTestBatchNumberId eq ${oneDayPentestId} and ProductId eq ${productId}`);
    }

    createProductPTT(productPTTRequestDto: ProductPTTRequestDto): Observable<ProductPTTRequestDto> {
        return this.http.post<any>(`${this.baseApiUrl}/api/ProductPTTs/`, productPTTRequestDto);
    }

    updateProductPTT(id: number, productPTTRequestDto: ProductPTTPutDto) {
        return this.http.put<any>(`${this.baseApiUrl}/api/ProductPTTs/${id}/`, productPTTRequestDto);
    }

    getAllPTTCat(): Observable<PTTCatDto[]> {
        return this.http.get<any>(`${this.baseApiUrl}/api/PTTCats/`).pipe(
            map((response) => {
                // Check if the response has a "value" property
                if (response && response.value) {
                    // Return the array of objects from the "value" property
                    return response.value;
                } else {
                    // If there is no "value" property, return the entire response
                    return response;
                }
            })
        );
    }

    getAllPTTComplexity(): Observable<PTTComplexityDto[]> {
        return this.http.get<any>(`${this.baseApiUrl}/api/PTTComplexities/`).pipe(
            map((response) => {
                // Check if the response has a "value" property
                if (response && response.value) {
                    // Return the array of objects from the "value" property
                    return response.value;
                } else {
                    // If there is no "value" property, return the entire response
                    return response;
                }
            })
        );
    }

    getAllPTTPriority(): Observable<PTTPriorityDto[]> {
        return this.http.get<any>(`${this.baseApiUrl}/api/PTTPriorities/`).pipe(
            map((response) => {
                // Check if the response has a "value" property
                if (response && response.value) {
                    // Return the array of objects from the "value" property
                    return response.value;
                } else {
                    // If there is no "value" property, return the entire response
                    return response;
                }
            })
        );
    }

    getAllPTTProgress(): Observable<PTTProgressDto[]> {
        return this.http.get<any>(`${this.baseApiUrl}/api/PTTProgresses/`).pipe(
            map((response) => {
                // Check if the response has a "value" property
                if (response && response.value) {
                    // Return the array of objects from the "value" property
                    return response.value;
                } else {
                    // If there is no "value" property, return the entire response
                    return response;
                }
            })
        );
    }

    getAllPTTSeverity(): Observable<PTTSeverityDto[]> {
        return this.http.get<any>(`${this.baseApiUrl}/api/PTTSeverities/`).pipe(
            map((response) => {
                // Check if the response has a "value" property
                if (response && response.value) {
                    // Return the array of objects from the "value" property
                    return response.value;
                } else {
                    // If there is no "value" property, return the entire response
                    return response;
                }
            })
        );
    }

    getPTTContacts(ProductPTTId: number): Observable<PTTContactDto[]> {
        return this.http.get<any>(`${this.baseApiUrl}/api/PTTContacts?$filter=ProductPTTId eq ${ProductPTTId}`).pipe(
            map((response) => {
                // Check if the response has a "value" property
                if (response && response.value) {
                    // Return the array of objects from the "value" property
                    return response.value;
                } else {
                    // If there is no "value" property, return the entire response
                    return response;
                }
            })
        );
    }

    createPTTContact(PentestPTTContactRequestDto: PTTContactRequestDto): Observable<PTTContactRequestDto> {
        return this.http.post<any>(`${this.baseApiUrl}/api/PTTContacts/`, PentestPTTContactRequestDto);
    }

    deletePTTContact(id: number): Observable<any> {
        return this.http.delete<any>(`${this.baseApiUrl}/api/PTTContacts/${id}`);
    }

    deletePTTFinding(id: number): Observable<any> {
        return this.http.delete<any>(`${this.baseApiUrl}/api/ProductPTTs/${id}`);
    }
}