import { Component, OnInit } from '@angular/core';
import { ProductNFRService } from 'src/app/layout/service/product/product-nfr.service';
import { ActivatedRoute } from '@angular/router';
import { Message, MessageService } from 'primeng/api';
import { NFRCategoryRequestDto, NFRCategoryDto } from 'src/app/api/product-nfr';

@Component({
  selector: 'nfr-categories',
  templateUrl: './nfr-categories.html'
})
export class NFRCategoriesComponent implements OnInit {

  categories: NFRCategoryDto[] = [];
  messages: Message[] = [];
  newCategoryDialog: boolean = false;
  UpdateCategoryDialog: boolean = false;
  submitted: boolean = false;

  newName: string = '';
  newPriority: string = '';
  existingId: number = 0;

  constructor(private route: ActivatedRoute, private nfrService: ProductNFRService, private messageService: MessageService) { }

  ngOnInit(): void {

    this.nfrService.getAllNFRCategories().subscribe(
      (data: any) => {
        this.categories = data;
      });

  }

  openNewDialog() {
    this.newCategoryDialog = true;
  }
  hideDialog() {
    this.newCategoryDialog = false;
  }

  openUpdateDialog(item: NFRCategoryDto) {
    this.existingId = item.Id ?? 0;
    this.newName = item.Name ?? '';
    this.newPriority = item.Priority?.toString() ?? '';
    this.UpdateCategoryDialog = true;
  }

  hideExistingDialog() {
    this.UpdateCategoryDialog = false;
  }

  addNewCategory() {

    this.submitted = true;

    if (!this.newName) {
      return;
    }

    if (!this.newPriority) {
      return;
    }

    //convert priority to number
    const newPriorityNum = parseInt(this.newPriority);


    const newCategory: NFRCategoryRequestDto = {
      Name: this.newName,
      Priority: newPriorityNum
    };

    this.nfrService.createNFRCategories(newCategory).subscribe(
      (data: any) => {
        const newCategory: NFRCategoryDto = {
          Id: data.id,
          Name: data.name,
          Priority: data.priority
        }

        this.categories.push(newCategory);
        this.newName = '';
        this.newPriority = '';
        this.existingId = 0;
        this.newCategoryDialog = false;
        this.UpdateCategoryDialog = false;
        this.submitted = false;

        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'NFR Category added' });
      });
  }

  deleteCategory(item: NFRCategoryDto) {

    const id: number = item.Id ?? 0;

    //escape if id is 0
    if (id === 0) {
      return;
    }

    this.nfrService.deleteNFRCategories(id).subscribe(
      (data: any) => {
        this.categories = this.categories.filter(category => category.Id !== id);
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'NFR Category deleted' });
      });
  }

  updateCategory() {

    this.submitted = true;

    if (!this.newName) {
      return;
    }

    if (!this.newPriority) {
      return;
    }

    //convert priority to number
    const newPriorityNum = parseInt(this.newPriority);

    const newCategory: NFRCategoryRequestDto = {
      Name: this.newName,
      Priority: newPriorityNum
    };

    console.log(newCategory);

    this.nfrService.updateNFRCategories(this.existingId, newCategory).subscribe(
      (data: any) => {

        //update the category in the list
        this.categories.forEach((item, index) => {
          if (item.Id === this.existingId) {
            item.Name = this.newName;
            item.Priority = newPriorityNum;
          }
        });

        this.newName = '';
        this.newPriority = '';
        this.existingId = 0;
        this.newCategoryDialog = false;
        this.UpdateCategoryDialog = false;
        this.submitted = false;
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'NFR Category updated' });

      });
  }
}
