import { Component, OnInit } from '@angular/core';
import { Message, MessageService } from 'primeng/api';
import { OneDayTestBatchNumberDto, OneDayTestBatchNumberRequestDto, ProductOneDayPentestPutDto } from 'src/app/api/product-onedaypentest';
import { ProductOneDayPentestService } from 'src/app/layout/service/product/product-onedaypentest.service';

@Component({
  selector: 'app-oneday-pentest-manager',
  templateUrl: './oneday-pentest-manager.component.html',
  styleUrls: ['./oneday-pentest-manager.component.scss']
})
export class OnedayPentestManagerComponent implements OnInit {
  unassignedBatches: any[] = [];

  oneDayTestBatchNumbers: OneDayTestBatchNumberDto[] = [];
  oneDayBatchNumber: OneDayTestBatchNumberDto = {}; 
  
  oneDayTestBatch: OneDayTestBatchNumberRequestDto = {};
  oneDayUnassignedPentest: ProductOneDayPentestPutDto = {};

  oneDayTestBatchStatuses: any[] = [];

  oneDayBatchDialog: boolean = false;
  deleteBatchDialog: boolean = false;
  unassignedBatchDialog: boolean = false;

  messages: Message[] = [];

  cols: any[] = [];
  unassignedCols: any[] = [];

  constructor(private oneDayPentesetService: ProductOneDayPentestService, private messageService: MessageService) { }

  ngOnInit(): void {
    this.getAllOneDayTestBatchNumbers();
    this.getAllUnassignedOneDayPentests();

    this.oneDayPentesetService.getAllOneDayTestBatchStatuses().subscribe((data) => {
      this.oneDayTestBatchStatuses = data;
    });

    this.cols = [
      { field: 'Id', header: 'Id' },
      { field: 'OneDayTestBatchStatusName', header: 'Batch Status' },
      { field: 'StartDate', header: 'Start Date' },
      { field: 'EndDate', header: 'End Date' },
      { field: 'SupplierContact', header: 'Supplier Contact' },
    ];

    this.unassignedCols = [
      { field: 'Id', header: 'Id' },
      { field: 'ProductId', header: 'Product ID' },
      { field: 'ProductName', header: 'Product Name' },
    ];
  }


  getAllUnassignedOneDayPentests() {
    this.oneDayPentesetService.getAllUnassignedOneDayPentests().subscribe((data) => {
      this.unassignedBatches = data;
    });
  }

  getAllOneDayTestBatchNumbers() {
    this.oneDayPentesetService.getAllOneDayTestBatchNumbers().subscribe((data) => {
      this.oneDayTestBatchNumbers = data;
    });
  }

  getBatchNameById(id: number | undefined) {
    const batch = this.oneDayTestBatchStatuses.find((status) => status.Id === id);
    return batch ? batch.Name : '';
  }

  openNew() {
    this.oneDayBatchDialog = true;
  }

  saveNewBatch() {
    this.oneDayTestBatch.StartDate = this.convertToUTC(this.oneDayTestBatch.StartDate);
    this.oneDayTestBatch.EndDate = this.convertToUTC(this.oneDayTestBatch.EndDate);
    
    if (!this.oneDayTestBatch.Id) {
      this.oneDayPentesetService.createOneDayTestBatchNumber(this.oneDayTestBatch).subscribe(() => {
        this.getAllOneDayTestBatchNumbers(); // Consider optimizing to update array without a full fetch
        this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Batch Created', life: 3000 });
      });
    } else {
      this.oneDayPentesetService.updateOneDayTestBatchNumber(this.oneDayTestBatch.Id, this.oneDayTestBatch).subscribe(() => {
        this.getAllOneDayTestBatchNumbers(); // Consider optimizing to update array without a full fetch
        this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Batch Updated', life: 3000 });
      });
    }

    this.oneDayBatchDialog = false;
    this.oneDayTestBatch = {};
  }

  editBatch(batch: OneDayTestBatchNumberDto) {
    this.oneDayTestBatch = { ...batch };
    this.oneDayTestBatch.StartDate = this.oneDayTestBatch.StartDate ? new Date(this.oneDayTestBatch.StartDate) : undefined;
    this.oneDayTestBatch.EndDate = this.oneDayTestBatch.EndDate ? new Date(this.oneDayTestBatch.EndDate) : undefined;
    this.oneDayBatchDialog = true;
  }

  deleteBatch(batch: OneDayTestBatchNumberDto) {
    this.oneDayBatchNumber = { ...batch };
    this.deleteBatchDialog = true;
  }

  confirmDeleteBatch() {
    if (this.oneDayBatchNumber.Id) {
      this.oneDayPentesetService.deleteOneDayTestBatchNumber(this.oneDayBatchNumber.Id).subscribe(() => {
        this.oneDayTestBatchNumbers = this.oneDayTestBatchNumbers.filter((val) => val.Id !== this.oneDayBatchNumber.Id);
        this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Batch Deleted', life: 3000 });
      });
    }

    this.deleteBatchDialog = false;
  }

  hideDialog() {
    this.oneDayBatchDialog = false;
    this.unassignedBatchDialog = false;
    this.oneDayTestBatch = {};
  }

  editUnassignedBatch(batch: any) {
    this.oneDayUnassignedPentest = { ...batch };
    this.unassignedBatchDialog = true;
  }

  saveAssignedBatch() {
    // Logic to assign batch
    if (this.oneDayUnassignedPentest.Id) {
      this.oneDayPentesetService.updateProductOneDayPentest(this.oneDayUnassignedPentest.Id, this.oneDayUnassignedPentest).subscribe(() => {
        this.getAllUnassignedOneDayPentests();
        this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Batch Assigned', life: 3000 });
      });
  
      this.unassignedBatchDialog = false;
      this.oneDayTestBatch = {};
    }
  }

  assignedPentests: any[] = [];
  assignedPentestsDialog: boolean = false;

  viewAssignedPentests(batch: OneDayTestBatchNumberDto) {
    if (batch.Id) {
      this.oneDayPentesetService.getPentestsByBatchId(batch.Id).subscribe((data) => {
        this.assignedPentests = data;
        this.assignedPentestsDialog = true;
      });
    }
  }

  hideAssignedPentestsDialog() {
    this.assignedPentestsDialog = false;
    this.assignedPentests = [];
  }

  convertToUTC(date: Date | undefined): Date | undefined {
    if (date) {
      return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
    }
    return undefined;
  }
}
