import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { AppMainComponent } from '../app-main/app.main.component';
import { Product } from 'src/app/api/product';
import { ProductService } from '../service/product/products.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ProductSelectionService } from 'src/app/product.selection.service';
import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { Subject, filter, takeUntil } from 'rxjs';
import { InteractionStatus, RedirectRequest } from '@azure/msal-browser';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AppConfigComponent } from '../app-config/app.config.component';
import { ConfigService } from '../service/app.config.service';

const GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/me';
const redirectUri = environment.redirectUri;

type ProfileType = {
  displayName?: string,
  surname?: string,
  userPrincipalName?: string,
  id?: string,
  mail?: string
};

@Component({
  selector: 'app-topbar',
  templateUrl: './app.topbar.component.html',
  styleUrls: ['app.topbar.component.css']
})
export class AppTopbarComponent implements OnInit {
  items?: MenuItem[];
  products: Product[] = [];

  filteredProducts: any[] = [];
  selectedProduct: Product | null = null;

  displayRoutes: string[] = ['/product'];
  
  isIframe = false;
  loginDisplay = false;
  private readonly _destroying$ = new Subject<void>();

  profile?: ProfileType;
  displayName: string | undefined;
  profilePhoto: string | undefined;

  constructor(public appMain: AppMainComponent, private productsService: ProductService,
    private route: ActivatedRoute, private http: HttpClient,
    private router: Router, private productSelectionService: ProductSelectionService,
    private cdr: ChangeDetectorRef, private authService: MsalService, 
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration, 
    private broadcastService: MsalBroadcastService, private configService: ConfigService) { }

  ngOnInit(): void {
    this.items = [
      {
        label: 'Admin',
        icon: 'pi pi-cog',
        command: () => this.goToAdmin()
      },
      {
        separator: true
      },
      {
        label: 'Logout',
        icon: 'pi pi-sign-out',
        command: () => this.logout()
      }
    ];

    this.isIframe = window !== window.parent && !window.opener;

    this.getProfile();
    this.getProfilePhoto();

    this.broadcastService.inProgress$
    .pipe(
      filter((status: InteractionStatus) => status === InteractionStatus.None),
      takeUntil(this._destroying$)
    )
    .subscribe(() => {
      this.setLoginDisplay();
    })

    this.productsService.getAllProductsTopbar().subscribe((products) => {
      this.products = products.sort((a, b) => (a.Name ?? '').localeCompare(b.Name ?? ''));
    });

    this.route.firstChild?.paramMap.subscribe(params => {
      const productId = params.get('id');

      if (productId) {
        // Load the product details based on the ID
        this.productsService.getProductByIdTopbar(productId).subscribe((product) => {
          this.selectedProduct = product;
          this.productSelectionService.setSelectedProduct(this.selectedProduct);
        });
      }

    });

    this.productSelectionService.getSelectedProduct().subscribe((selectedProduct) => {
      this.selectedProduct = selectedProduct;
    });

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // Check if the current route is within the specified displayRoutes
        const shouldDisplayDropdown = this.displayRoutes.some((route) => event.url.includes(route));

        // Update selectedProduct based on route
        this.selectedProduct = shouldDisplayDropdown ? this.selectedProduct : null;
      }
    });
  }

  filterProduct(event: any) {
    const filtered: any[] = [];
    const query = event.query.toLowerCase();
    
    for (let i = 0; i < this.products.length; i++) {
      const product = this.products[i];
      let foundMatch = false;
      
      // Check if the product name matches the query
      if (product.Name?.toLowerCase().includes(query)) {
        filtered.push(product);
        foundMatch = true;
      }
      
      // Check aliases if no product name match yet
      if (!foundMatch && product.Aliases) {
        const matchingAliases = product.Aliases.filter(alias => alias.Name && alias.Name.toLowerCase().includes(query));
        
        // If any aliases match, add the product to filtered with matching aliases
        if (matchingAliases.length > 0) {
          filtered.push({
            ...product,
            Aliases: matchingAliases
          });
          foundMatch = true;
        }
      }
    }
  
    this.filteredProducts = filtered;
  }  

  onProductSelect(event: any) {
    const selectedProductId = event.Id;
    this.router.navigate(['/product', selectedProductId, 'dashboard']);
  }

  login() {
    if (this.msalGuardConfig.authRequest){
      this.authService.loginRedirect({...this.msalGuardConfig.authRequest} as RedirectRequest);
    } else {
      this.authService.loginRedirect();
    }
  }
  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }

  logout() { // Add log out function here
    this.authService.logoutRedirect({
      postLogoutRedirectUri: redirectUri
    });
  }

  goToAdmin() {
    this.router.navigate(['/admin']);
  }

  goToSubscriptions() {
    this.router.navigate(['reports/subscriptions']);
  }

  getProfile() {
    this.http.get(GRAPH_ENDPOINT)
      .subscribe(profile => {
        this.profile = profile;
      });
  }

  getProfilePhoto() {
    this.http.get(`${GRAPH_ENDPOINT}/photo/$value`, { responseType: 'blob' }).subscribe(
      (photoBlob) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          this.profilePhoto = reader.result as string;
          this.cdr.detectChanges(); // Ensure Angular change detection picks up the new value
        };
        reader.readAsDataURL(photoBlob);
      },
      (error) => {
        console.error('Error fetching profile photo:', error);
      }
    );
  }

  onConfigButtonClick(event: MouseEvent) {
    this.configService.notifyConfigButtonClick();
  }

  ngOnDestroy() {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
