import { Component } from '@angular/core';

@Component({
  selector: 'app-tech-radar',
  templateUrl: './tech-radar.component.html',
  // styleUrls: ['./tech-radar.component.css']
})
export class TechRadarComponent {

}
