<h1>SDLC Requests</h1>

<p-table
    #dt1
    [value]="allSDLCs"
    dataKey="ProductId"
    [rows]="10"
    [showCurrentPageReport]="true"
    [rowsPerPageOptions]="[10, 25, 50]"

    [paginator]="true"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
    [globalFilterFields]="['ProductId', 'ProductName', 'SDLCPracticeName', 'SDLCToolName', 'SDLCRequestStatusName']"
>
    <ng-template pTemplate="caption">
        <div class="flex">
            <button pButton label="Clear" class="p-button-outlined" icon="pi pi-filter-slash" (click)="clear(dt1)"></button>
            <span class="p-input-icon-left ml-auto">
                <!-- <i class="pi pi-search"></i> -->
                <!-- <input pInputText type="text" (input)="dt1.filterGlobal($event.target.value, 'contains')" placeholder="Search keyword" /> -->
            </span>
        </div>
    </ng-template>
    <ng-template pTemplate="header">
        <tr>
            <th style="min-width:10rem">
                <div class="flex align-items-center">
                    SDLC Id
                    <p-columnFilter type="text" field="SDLCId" display="menu"></p-columnFilter>
                </div>
            </th>
            <th style="min-width:10rem">
                <div class="flex align-items-center">
                    Product Id
                    <p-columnFilter type="text" field="ProductId" display="menu"></p-columnFilter>
                </div>
            </th>
            <th style="min-width:15rem">
                <div class="flex align-items-center">
                    Product Name
                    <p-columnFilter type="text" field="ProductName" display="menu"></p-columnFilter>
                </div>
            </th>
            <th style="min-width:12rem">
                <div class="flex align-items-center">
                    SDLC Practice
                    <p-columnFilter type="text" field="SDLCPracticeName" display="menu"></p-columnFilter>
                </div>
            </th>
            <th style="min-width:15rem">
                <div class="flex align-items-center">
                    NP-Dast Scan Type
                    <p-columnFilter type="text" field="SDLCNPDastScanTypeName" display="menu"></p-columnFilter>
                </div>
            </th>
            <th style="min-width:15rem">
                <div class="flex align-items-center">
                    Tool
                    <p-columnFilter type="text" field="SDLCToolName" display="menu"></p-columnFilter>
                </div>
            </th>
            <th style="min-width:15rem">
                <div class="flex align-items-center">
                    Status
                    <p-columnFilter type="text" field="SDLCRequestStatusName" display="menu"></p-columnFilter>
                </div>
            </th>
            <th style="min-width:15rem">
                <div class="flex align-items-center">
                    Actions
                </div>
            </th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-sdlc>
        <tr>
            <td>{{ sdlc.Id }}</td>
            <td>{{ sdlc.ProductId }}</td>
            <td>{{ sdlc.ProductName }}</td>
            <td>{{ sdlc.SDLCPracticeName }}</td>
            <td>{{ sdlc.SDLCNPDastScanTypeName }}</td>
            <td>{{ sdlc.SDLCToolName }}</td>
            <td>{{ sdlc.SDLCRequestStatusName }}</td>
            <td class="flex align-items-center gap-1">
                <button pButton type="button" (click)="approveSDLC(sdlc)" class="p-button-success">Approve</button>
                <button pButton type="button" (click)="declineSDLC(sdlc)" class="p-button-danger">Decline</button>
            </td>
        </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
        <tr>
            <td colspan="6">No SDLC requests found.</td>
        </tr>
    </ng-template>
</p-table>

<p-dialog header="Leave a comment" [(visible)]="visible" [modal]="true" [style]="{ width: '50rem' }" [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }">
    <div class="flex flex-column gap-1">
        <textarea pInputTextarea rows="5" cols="30" [(ngModel)]="selectedSDLC.RequestComment" placeholder="Leave a comment"></textarea>
    </div>
    <ng-template pTemplate="footer">
        <p-button icon="pi pi-check" (click)="visible = false" label="Ok" pAutoFocus [autofocus]="true" (onClick)="submitComment(selectedSDLC)"></p-button>
    </ng-template>
</p-dialog>