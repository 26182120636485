import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, map } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable(
    {
        providedIn: 'root'
    }
)

export class ArchitectureService {
  baseApiUrl: string = environment.baseApiUrl;
  constructor(private http: HttpClient) { }

  getHistoricARBs(objectRef: string): Observable<any> {
    return this.http.get(this.baseApiUrl + `/api/architecture/ARBs?reference=` + objectRef);
  }

  getAllARBs(): Observable<any> {
    return this.http.get(this.baseApiUrl + `/api/architecture/ARBs`);
  }

  getAllARBsCount(): Observable<any> {
    return this.http.get(this.baseApiUrl + `/api/architecture/ARBs/count`);
  }

  getARB(code: string): Observable<any> {
    return this.http.get(this.baseApiUrl + `/api/architecture/ARBs/` + code);
  }
}
