// product-selection.service.ts
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Product } from './api/product';

@Injectable({
  providedIn: 'root',
})
export class ProductSelectionService {
  private selectedProductSource = new BehaviorSubject<Product | null>(null);
  selectedProduct$ = this.selectedProductSource.asObservable();

  setSelectedProduct(product: Product | null): void {
    this.selectedProductSource.next(product);
  }

  getSelectedProduct(): Observable<Product | null> {
    return this.selectedProduct$;
  }
}