<h1>SDLC Practices</h1>
<div>
  <p-table [value]="practices">
    <ng-template pTemplate="header">
      <tr>
        <th>ID</th>
        <th>Name</th>
        <th>Levels</th>
        <th>SDLC Maturity</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-item let-rowIndex="rowIndex">
      <tr>
        <td>{{ item.Id }}</td>
        <td>{{ item.Name }}</td>
        <td>
          <a [routerLink]="['/admin','sdlc','practices', item.Id, 'maturitylevels']">Set Levels</a>
        </td>
        <td>
          <a [routerLink]="['/admin','sdlc','practices', item.Id, 'sdlcpracticematurity']">Set Minimum Maturity</a>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
