import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, map } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable(
  {
    providedIn: 'root'
  }
)

export class VulnerabilityService {
  baseApiUrl: string = environment.baseApiUrl;
  constructor(private http: HttpClient) { }

  getVulnerability(id: string, tool?: string): Observable<any> {
    return this.http.get(this.baseApiUrl + `/api/vulnerabilities/` + id + `?tool=` + tool)
  }
  searchVulnerabilities(filter: string): Observable<any> {
    return this.http.get(this.baseApiUrl + `/api/vulnerabilities/search?` + filter)
  }
  baseVulnerabilities(): Observable<any> {
    return this.http.get(this.baseApiUrl + `/api/vulnerabilities/base`)
  }
  getVulnerabilityTree(agedOnly: boolean, filter: string, timeframe: number): Observable<any> {
    return this.http.get(this.baseApiUrl + `/api/vulnerabilities/tree?agedOnly=` + agedOnly + `&timeframe=` + timeframe + `&` + filter)
  }

  getBaseVulnHierarchy(baseVulns: string): Observable<any> {
    return this.http.get(this.baseApiUrl + `/api/vulnerabilities/base/hierarchy?baseVulnerabilities=` + baseVulns)
  }
}
