import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, map } from "rxjs";
import { AliasRequestDto, Division, Product, ProductContact, ProductContactRequestDto, ProductPutDto, ProductRequestDto, ProductType, Suite, SuiteRequest } from "src/app/api/product";
import { environment } from "src/environments/environment";

@Injectable(
  {
    providedIn: 'root'
  }
)

export class ProductService {
  baseApiUrl: string = environment.baseApiUrl;
  constructor(private http: HttpClient) { }

  getAllProductsTopbar(): Observable<Product[]> {
    return this.http.get<any>(this.baseApiUrl + `/api/Products?$select=id,name,suiteName&$expand=Aliases`).pipe(
      map((response) => {
        // Check if the response has a "value" property
        if (response && response.value) {
          // Return the array of objects from the "value" property
          return response.value;
        } else {
          // If there is no "value" property, return the entire response
          return response;
        }
      })
    );
  }

  getProductByIdTopbar(productId: string): Observable<Product> {
    return this.http.get<Product>(this.baseApiUrl + `/api/Products/${productId}?$select=id,name,productRef`);
  }

  getAllProducts(): Observable<Product[]> {
    return this.http.get<any>(this.baseApiUrl + `/api/Products?$expand=productmetric($select=TierName)`).pipe(
      map((response) => {
        // Check if the response has a "value" property
        if (response && response.value) {
          // Return the array of objects from the "value" property
          return response.value;
        } else {
          // If there is no "value" property, return the entire response
          return response;
        }
      })
    );
  }

  getAllProductsData(): Observable<Product[]> {
    return this.http.get<any>(this.baseApiUrl + `/api/reports/productdata`).pipe(
      map((response) => {
        // Check if the response has a "value" property
        if (response && response.value) {
          // Return the array of objects from the "value" property
          return response.value;
        } else {
          // If there is no "value" property, return the entire response
          return response;
        }
      })
    );
  }

  getAllProductsMasterList(): Observable<Product[]> {
    return this.http.get<any>(this.baseApiUrl + `/api/Products?$expand=productmetric,producttechspec($expand=TechSpecDistributions)`).pipe(
      map((response) => {
        // Check if the response has a "value" property
        if (response && response.value) {
          // Return the array of objects from the "value" property
          return response.value;
        } else {
          // If there is no "value" property, return the entire response
          return response;
        }
      })
    );
  }

  getProductCount(): Observable<number> {
    return this.http.get<number>(this.baseApiUrl + `/api/Products/$count`)
  }

  getProductById(productId: string): Observable<Product> {
    return this.http.get<Product>(this.baseApiUrl + `/api/Products/${productId}?$expand=ProductTechSpec,ProductMetric,ProductTechPortfolio,Aliases`);
  }

  getFavouritesById(productId: string): Observable<Product> {
    return this.http.get<Product>(this.baseApiUrl + `/api/Products/${productId}?$expand=ProductMetric`);
  }

  updateProduct(id: string, updateProductRequest: ProductPutDto): Observable<ProductPutDto> {
    return this.http.put<ProductPutDto>(this.baseApiUrl + `/api/Products/${id}`, updateProductRequest);
  }

  createProduct(product: ProductRequestDto): Observable<ProductRequestDto> {
    return this.http.post<ProductRequestDto>(this.baseApiUrl + `/api/Products`, product);
  }

  getAllSuites(): Observable<Suite[]> {
    return this.http.get<any>(this.baseApiUrl + `/api/Suites`).pipe(
      map((response) => {
        // Check if the response has a "value" property
        if (response && response.value) {
          // Return the array of objects from the "value" property
          return response.value;
        } else {
          // If there is no "value" property, return the entire response
          return response;
        }
      })
    );
  }

  getSuiteCount(): Observable<number> {
    return this.http.get<number>(this.baseApiUrl + `/api/Suites/$count`)
  }

  getSuiteById(suiteId: string): Observable<Suite> {
    return this.http.get<Suite>(this.baseApiUrl + `/api/Suites/${suiteId}?$expand=Products`);
  }

  getSuiteByIdSimple(suiteId: string): Observable<Suite> {
    return this.http.get<Suite>(this.baseApiUrl + `/api/Suites/${suiteId}`);
  }

  updateSuite(suiteId: string, updateSuite: SuiteRequest): Observable<Suite> {
    return this.http.put<Suite>(this.baseApiUrl + `/api/Suites/${suiteId}`, updateSuite);
  }

  deleteSuite(suiteId: number): Observable<any> {
    return this.http.delete<any>(this.baseApiUrl + `/api/Suites/${suiteId}`);
  }

  createSuite(newSuite: SuiteRequest): Observable<Suite> {
    return this.http.post<any>(this.baseApiUrl + `/api/Suites`, newSuite);
  }

  getAllDivisions(): Observable<Division[]> {
    return this.http.get<any>(this.baseApiUrl + `/api/Divisions`).pipe(
      map((response) => {
        // Check if the response has a "value" property
        if (response && response.value) {
          // Return the array of objects from the "value" property
          return response.value;
        } else {
          // If there is no "value" property, return the entire response
          return response;
        }
      })
    );
  }

  getDivisionCount(): Observable<number> {
    return this.http.get<number>(this.baseApiUrl + `/api/Divisions/$count`)
  }


  getAllProductTypes(): Observable<ProductType[]> {
    return this.http.get<any>(this.baseApiUrl + `/api/ProductTypes`).pipe(
      map((response) => {
        // Check if the response has a "value" property
        if (response && response.value) {
          // Return the array of objects from the "value" property
          return response.value;
        } else {
          // If there is no "value" property, return the entire response
          return response;
        }
      })
    );
  }

  getProductContacts(productId: string): Observable<ProductContact[]> {
    return this.http.get<any>(this.baseApiUrl + `/api/ProductContacts?$filter=ProductId eq ${productId}`).pipe(
      map((response) => {
        // Check if the response has a "value" property
        if (response && response.value) {
          // Return the array of objects from the "value" property
          return response.value;
        } else {
          // If there is no "value" property, return the entire response
          return response;
        }
      })
    );
  }


  createProductContact(ProductContactRequestDto: ProductContactRequestDto): Observable<ProductContactRequestDto> {
    return this.http.post<ProductContactRequestDto>(this.baseApiUrl + `/api/ProductContacts/`, ProductContactRequestDto);
  }

  updateProductContact(id: number, ProductContactRequestDto: ProductContactRequestDto): Observable<ProductContactRequestDto> {
    return this.http.put<ProductContactRequestDto>(this.baseApiUrl + `/api/ProductContacts/${id}`, ProductContactRequestDto);
  }

  deleteProductContact(id: number): Observable<any> {
    return this.http.delete<any>(this.baseApiUrl + `/api/ProductContacts/${id}`);
  }

  getAllProductContactGroups(): Observable<any> {
    return this.http.get<any>(this.baseApiUrl + `/api/ProductContactGroups`).pipe(
      map((response) => {
        // Check if the response has a "value" property
        if (response && response.value) {
          // Return the array of objects from the "value" property
          return response.value;
        } else {
          // If there is no "value" property, return the entire response
          return response;
        }
      })
    );
  }

  createAlias(AliasRequestDto: AliasRequestDto): Observable<any> {
    return this.http.post<AliasRequestDto>(this.baseApiUrl + `/api/Aliases/`, AliasRequestDto);
  }

  deleteAlias(id: number): Observable<any> {
    return this.http.delete<any>(this.baseApiUrl + `/api/Aliases/${id}`);
  }

  checkProductExists(productId: string): Observable<boolean> {
    return this.http.get<any>(`${this.baseApiUrl}/api/Products?$filter=id eq ${productId}&$count=true`).pipe(
      map(response => {
        const count = response['@odata.count']; // Extract the count from the response
        return count > 0; // Check if the count is greater than 0
      })
    );
  }

  getProductToolUsage(id: string): Observable<any> {
    return this.http.get<any>(`${this.baseApiUrl}/api/ProductToolUsages?$filter=ObjectId eq ${id}`).pipe(
      map((response) => {
        // Check if the response has a "value" property
        if (response && response.value) {
          // Return the array of objects from the "value" property
          return response.value;
        } else {
          // If there is no "value" property, return the entire response
          return response;
        }
      })
    );
  }

  getProductToolUsageByObjectId(id: string): Observable<any> {
    return this.http.get<any>(`${this.baseApiUrl}/api/ProductToolUsages?$filter=ObjectId eq ${id}`).pipe(
      map((response) => {
        // Check if the response has a "value" property
        if (response && response.value) {
          // Return the array of objects from the "value" property
          return response.value;
        } else {
          // If there is no "value" property, return the entire response
          return response;
        }
      })
    );
  }

  getProductToolUsageByProductTypeId(id: string): Observable<any> {
    return this.http.get<any>(`${this.baseApiUrl}/api/ProductToolUsages?$filter=ProductTypeId eq ${id}`).pipe(
      map((response) => {
        // Check if the response has a "value" property
        if (response && response.value) {
          // Return the array of objects from the "value" property
          return response.value;
        } else {
          // If there is no "value" property, return the entire response
          return response;
        }
      })
    );
  }

  createProductToolUsage(ProductToolUsageRequestDto: any): Observable<any> {
    return this.http.post<any>(this.baseApiUrl + `/api/ProductToolUsages/`, ProductToolUsageRequestDto);
  }

  updateProductToolUsage(id: string, ProductToolUsageRequestDto: any): Observable<any> {
    return this.http.put<any>(this.baseApiUrl + `/api/ProductToolUsages/${id}`, ProductToolUsageRequestDto);
  }

  deleteProductToolUsage(id: number): Observable<any> {
    return this.http.delete<any>(this.baseApiUrl + `/api/ProductToolUsages/${id}`);
  }

}
