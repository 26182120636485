import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Message, MessageService } from 'primeng/api';
import { ProductService } from 'src/app/layout/service/product/products.service';
import { ProductMetricService } from 'src/app/layout/service/product/product-metric.service';
import { VulnerabilityService } from 'src/app/layout/service/vulnerability.service';
import { Product } from 'src/app/api/product';
import { VulnerabilityReportBundle } from 'src/app/api/vulnerabilities';
import { forkJoin } from 'rxjs';
import { TabView } from 'primeng/tabview';

@Component({
  selector: 'mastervulnreport',
  templateUrl: './mastervulnreport.component.html',
  styleUrls: ['./mastervulnreport.component.scss']
})

export class MasterVulnReportReport implements OnInit {

  isLoading = true;
  isSummaryLoaded = true;
  isProductListLoaded = false;
  dataIsLoading= false;
  isTabReady = false;

  data: any[] = [];

  products: any[] = [];
  suites: any[] = [];
  divisions: any[] = [];
  tiers: any[] = [];
  aged: string = 'aged';

  selectedTiers: any[] = [];
  selectedDivisions: any[] = [];
  selectedSuites: any[] = [];
  selectedProducts: any[] = [];

  selectedTab: string = '';

  treeFilter: string = '';
  divisionData: any[] = [];

  timeframeOptions: any[] = [
    { id: 1, name: 'Yesterday', disabled: false },
    { id: 7, name: 'Last Week', disabled: false },
    { id: 14, name: 'Two Weeks Ago', disabled: false },
    { id: 30, name: 'Last Month', disabled: true }
  ];

  timeframe: any = { id: 7, name: 'Last Week', disabled: false };


  reportBundle: VulnerabilityReportBundle | undefined;

  @ViewChild('tabView', { static: true }) tabView!: TabView;

  constructor(private route: ActivatedRoute,
    private productService: ProductService,
    private productMetricService: ProductMetricService,
    private cdr: ChangeDetectorRef,
    private vulnerabilityService: VulnerabilityService,
    private messageService: MessageService) { }

  ngOnInit(): void {
    forkJoin({
      suites: this.productService.getAllSuites(),
      divisions: this.productService.getAllDivisions(),
      tiers: this.productMetricService.getAllTiers(),
      products: this.productService.getAllProducts()
    }).subscribe(({ suites, divisions, tiers, products }) => {
      // Set the loaded data
      this.suites = suites.sort((a, b) => (a.Name ?? '').localeCompare(b.Name ?? ''));
      this.divisions = divisions.sort((a, b) => (a.Name ?? '').localeCompare(b.Name ?? ''));
      this.tiers = tiers;
      this.products = products.sort((a, b) => (a.Name ?? '').localeCompare(b.Name ?? ''));
      
      this.route.queryParams.subscribe(params => {
        this.selectedTab = params['tab'];
        this.isTabReady = true;

        this.selectedTiers = this.getSelectedItems(params['tiers'], this.tiers);
        this.selectedDivisions = this.getSelectedItems(params['divisions'], this.divisions);
        this.selectedSuites = this.getSelectedItems(params['suites'], this.suites);
        this.selectedProducts = this.getSelectedItems(params['products'], this.products);
        this.timeframe = this.timeframeOptions.find((item: any) => item.id == params['timeframe']) || this.timeframeOptions[1];
        this.aged = params['aged'] || 'aged';

        

        //If a filter is provided, then pre-run the report without requiring the user to click the search button
        if (params['tiers'] || params['divisions'] || params['suites'] || params['products']) {
          this.fetchVulnerabilities();
        }
      });

      this.isLoading = false;

    });
  }

  private getSelectedItems(paramValues: string, allItems: any[]): any[] {
    if (!paramValues) {
      return [];
    }
    const ids = paramValues.split(',').map(Id => Id.trim());
    return allItems.filter(item => ids.includes(item.Id.toString()));
  }

  onTiersChange(event: any) {
    this.selectedTiers = event.value;
  }

  onDivisionsChange(event: any) {
    this.selectedDivisions = event.value;
  }

  onSuitesChange(event: any) {
    this.selectedSuites = event.value;
  }

  onProductsChange(event: any) {
    this.selectedProducts = event.value;
  }

  private fetchVulnerabilities(): void {

    let filter = "lensActiveStatus eq true and vulnerabilityType eq 'Vulnerability' and ";
    if (this.selectedTiers.length > 0) {
      filter += "lensTierId in (" + this.selectedTiers.map(item => item.Id).join(",") + ") and ";
    }
    if (this.selectedDivisions.length > 0) {
      filter += "lensDivisionId in (" + this.selectedDivisions.map(item => item.Id).join(",") + ") and ";
    }
    if (this.selectedSuites.length > 0) {
      filter += "lensSuiteId in (" + this.selectedSuites.map(item => item.Id).join(",") + ") and ";
    }
    if (this.selectedProducts.length > 0) {
      filter += "(lensObjectId in (" + this.selectedProducts.map(item => item.Id).join(",") + ") and lensProductTypeName eq 'Product') and ";
    }

    // Check if there is an 'and' at the end of the sentence, and remove it
    if (filter.endsWith(" and ")) {
      filter = filter.slice(0, -5);
    }

    if (filter !== "") {
      filter = "$filter=" + filter;
    }
    console.log(filter);
    this.dataIsLoading = true;

    const vulnerabilityReportBundle: VulnerabilityReportBundle = {
      filter: filter,
      aged: this.aged,
      timeframe: this.timeframe.id,
      tab: parseInt(this.selectedTab) || 0
    }

    this.reportBundle = vulnerabilityReportBundle;

  }

  doSearch(): void {
    this.fetchVulnerabilities();
  }

}
