import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MessageService } from 'primeng/api';
import { PortfolioDatabaseRequestDto, PortfolioProgrammingLanguageRequestDto, PortfolioUXUIRequestDto } from 'src/app/api/product-tech-portfolio';
import { TechStackRequestDto } from 'src/app/api/product-tech-spec';
import { ProductTechPortfolioService } from 'src/app/layout/service/product/product-tech-portfolio.service';
import { ProductTechSpecService } from 'src/app/layout/service/product/product-tech-spec.service';

@Component({
  selector: 'app-add-entry-dialog',
  templateUrl: './add-entry-dialog.component.html',
})
export class AddEntryDialogComponent {
  @Input() entryType: 'database' | 'programmingLanguage' | 'uxui' | 'techstack' = 'techstack';
  @Output() entryAdded: EventEmitter<{ type: string; response: any }> = new EventEmitter<{ type: string; response: any }>();
  
  visible: boolean = false;
  entryName: string = '';

  constructor(private productTechPortfolioService: ProductTechPortfolioService, 
    private productTechSpecService: ProductTechSpecService, private messageService: MessageService) {}

  showDialog() {
    this.visible = true;
  }

  hideDialog() {
    this.visible = false;
    this.resetFields();
  }

  resetFields() {
    this.entryName = '';
  }

  get dialogTitle(): string {
    if (this.entryType === 'database') {
      return 'Add Database';
    } else if (this.entryType === 'programmingLanguage') {
      return 'Add Application Layer';
    } else if (this.entryType === 'uxui') {
      return 'Add UX/UI';
    } else if (this.entryType === 'techstack') {
        return 'Add Tech Stack';
    }
    return 'Add Entry';
  }

   get placeholderText(): string {
    if (this.entryType === 'database') {
      return 'Database';
    } else if (this.entryType === 'programmingLanguage') {
      return 'Application Layer';
    } else if (this.entryType === 'uxui') {
      return 'UX/UI';
    } else if (this.entryType === 'techstack') {
        return 'Tech Stack';
    }
    return 'Entry';
  }

  addEntry() {
    if (!this.entryName || this.entryName.trim() === '') {
      // Show error toast if the entry name is empty or only contains whitespace
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Entry name is required' });
      return;
    }
  
    if (this.entryType === 'database') {
      const newDatabase: PortfolioDatabaseRequestDto = {
        Name: this.entryName
      };
      this.productTechPortfolioService.addDatabase(newDatabase).subscribe({
        next: (response) => {
          this.entryAdded.emit({ type: 'database', response: response });
          this.hideDialog();
        },
        error: (err: any) => {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error adding database' });
          console.error('Error adding database:', err);
        }
      });
    } else if (this.entryType === 'programmingLanguage') {
      const newProgrammingLanguage: PortfolioProgrammingLanguageRequestDto = {
        Name: this.entryName
      };
      this.productTechPortfolioService.addProgrammingLanguage(newProgrammingLanguage).subscribe({
        next: (response) => {
          this.entryAdded.emit({ type: 'programmingLanguage', response: response });
          this.hideDialog();
        },
        error: (err: any) => {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error adding Application Layer' });
          console.error('Error adding Application Layer:', err);
        }
      });
    } else if (this.entryType === 'uxui') {
      const newUxUi: PortfolioUXUIRequestDto = {
        Name: this.entryName
      };
      this.productTechPortfolioService.addUXUI(newUxUi).subscribe({
        next: (response) => {
          this.entryAdded.emit({ type: 'uxui', response: response });
          this.hideDialog();
        },
        error: (err: any) => {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error adding Client Layer' });
          console.error('Error adding Client Layer:', err);
        }
      });
    } else if (this.entryType === 'techstack') {
      const newTechStack: TechStackRequestDto = {
        Name: this.entryName
      };
      this.productTechSpecService.addTechStack(newTechStack).subscribe({
        next: (response) => {
          this.entryAdded.emit({ type: 'techstack', response: response });
          this.hideDialog();
        },
        error: (err: any) => {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error adding other Tech Stack' });
          console.error('Error adding other Tech Stack:', err);
        }
      });
    }
  }
}
