import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConfirmationService, Message, MessageService } from 'primeng/api';
import { EmailRequestDto, EmailRequestNewDto } from 'src/app/api/email-request';
import { User } from 'src/app/api/product';
import { ProductSDLC, ProductSDLCPutDto, ProductSDLCRequestDto, SDLCPrimaryContact } from 'src/app/api/product-sdlc';
import { EmailTemplateService } from 'src/app/layout/service/email/email-template.service';
import { GraphExplorerService } from 'src/app/layout/service/graphexplorer/graph-explorer.service';
import { LogService } from 'src/app/layout/service/log.service';
import { EmailService } from 'src/app/layout/service/product/email.service';
import { ProductSDLCService } from 'src/app/layout/service/product/product-sdlc.service';
import { ToEmailService } from 'src/app/layout/service/product/to-email.service';
import { ProductSelectionService } from 'src/app/product.selection.service';

@Component({
  selector: 'app-incident-response',
  templateUrl: './incident-response.component.html'
})
export class IncidentResponseComponent implements OnInit {
  productId: string = '';
  productName: string = '';
  productReference: string = '';

  productSDLC: ProductSDLC = {};

  productSDLCIRStatuses: any[] = [];

  hasProductSDLC: boolean = false;

  newProductSDLC: ProductSDLCRequestDto = {};
  editedProductSDLC: ProductSDLCPutDto = {};

  messages: Message[] = [];

  incidentResponseSDLCPracticeId: number | undefined;

  userDialog: boolean = false;
  users: User[] = [];
  sdlcPrimaryContacts: SDLCPrimaryContact[] = [];

  selectedUser: User | null = null;

  userDialogAction: string = '';
  incidentResponseDialog: boolean = false;
  irNewContacts: User[] = [];

  constructor(private route: ActivatedRoute, private productSDLCService: ProductSDLCService, 
    private graphExplorerService: GraphExplorerService, private productSelectionService: ProductSelectionService,
    private messageService: MessageService, private confirmationService: ConfirmationService, 
    private emailService: EmailService, private toEmailService: ToEmailService, private logService: LogService,
  private emailTemplateService: EmailTemplateService) { }

  ngOnInit(): void {

    this.route.params.subscribe(params => {
      this.productId = params['id'];

      this.productSelectionService.getSelectedProduct().subscribe((product) => {
        this.productName = product?.Name || '';
        this.productReference = product?.ProductRef || '';
      });

      this.fetchSDLCData();
    });

    this.productSDLCService.getSDLCPractice("IR").subscribe((sdlcPractice) => {
      if(sdlcPractice.length > 0){
        this.incidentResponseSDLCPracticeId = sdlcPractice[0].Id;
      }
      this.productSDLCService.getMaturityLevelByPracticeId(this.incidentResponseSDLCPracticeId).subscribe((sdlcIRStatuses) => {
        this.productSDLCIRStatuses = sdlcIRStatuses;
      });
    });

    this.getAllUsers();

    this.messages = [{ severity: 'warn', summary: 'Warning', detail: 'No Incident Response for this product' }];
  }

  getAllUsers() {
    this.graphExplorerService.getAllADUsers().subscribe((users) => {
      this.users = users;
    });
  }
  
  searchUsers(event?: any) {
    if (event.filter) {
      this.graphExplorerService.searchADUsers(event.filter).subscribe((users) => {
        this.users = users;
      });
    } else {
      this.getAllUsers();
    }
  }

  fetchSDLCData() {
    this.productSDLCService.getProductSDLCIncidentResponse(this.productId).subscribe((sdlcIncidentResponse) => {
      if (sdlcIncidentResponse && sdlcIncidentResponse.value.length > 0) {
        this.productSDLC = sdlcIncidentResponse["value"][0];
        this.productSDLC.EnrolDate = this.productSDLC.EnrolDate ? new Date(this.productSDLC.EnrolDate) : undefined;
        this.hasProductSDLC = true;

        this.editedProductSDLC = {...this.productSDLC};

        this.irBefore = this.productSDLC;

        this.fetchProductSDLCContacts();
      } else {
        this.productSDLC = {};
        this.hasProductSDLC = false;
      }
    });
  }

  fetchProductSDLCContacts() {
    if (this.productSDLC.Id) {
      this.productSDLCService.getSDLCPrimaryContacts(this.productSDLC.Id).subscribe((sdlcPrimaryContacts) => {
        this.sdlcPrimaryContacts = sdlcPrimaryContacts;
        // I need this.sdlcPrimaryContacts to only contain the 
      });
    }
  }

  getIRStatusNameById(irStatusId: string): string {
    const selectedIRStatus = this.productSDLCIRStatuses.find(irStatus => irStatus.Id === irStatusId);
    return selectedIRStatus ? selectedIRStatus.Name : '';
  }

  openUserDialog(action: string) {
    this.userDialogAction = action;
    this.userDialog = true;
  }

  hideDialog() {
    this.userDialog = false;
    this.selectedUser = null;
  }

  onSubmit() {
    // Check if productSDLC exists
    if (this.hasProductSDLC && this.productSDLC.Id) {
      this.editedProductSDLC.EnrolDate = this.convertToUTC(this.editedProductSDLC.EnrolDate);

        // Update existing productSDLC with a PUT request
        this.productSDLCService.updateProductSDLC(this.productSDLC.Id, this.editedProductSDLC).subscribe(
            (updatedProductSDLC) => {
                // Handle successful update
                this.productSDLCService.getProductSDLCIncidentResponse(this.productId).subscribe((ir) => {
                    this.irAfter = ir.value[0];
                    console.log('irAfter:', this.irAfter);

                    const htmlContent = this.createEmailContent(this.irBefore, this.irAfter);

                    // Define the list of To recipients
                    const toEmails = this.toEmailService.getToEmails();
                    
                    // Define the list of CC recipients
                    const ccEmails = this.sdlcPrimaryContacts.map(contact => contact.Mail);

                    this.sendEmail(toEmails, ccEmails, `Incident Response Plan Updated - ${this.productName} (${this.productId})`, htmlContent);

                    this.logService.sendInfoMessage('UPDATE', 'product/' + this.productId + '/sdlc/incidentresponse/', this.irAfter, this.irBefore);
                });
                this.messageService.add({ severity: 'success', summary: 'Successful', detail: `Incident Response Updated`, life: 3000 });
            },
            (error) => {
                // Handle update error
                console.error('Error updating product SDLC:', error);
            }
        );
    }
  }


  addPrimaryContact() {
    if (this.selectedUser && this.productId) {
      const userExists = this.sdlcPrimaryContacts.some(contact => contact.UserGraphId === this.selectedUser!.id);
      if (userExists) {
        this.messageService.add({ severity: 'warn', summary: 'Warning', detail: 'User already added', life: 3000 });
      } else {
        const productUser = {
          UserGraphId: this.selectedUser.id,
          Name: this.selectedUser.displayName,
          Mail: this.selectedUser.mail,
          ProductSDLCId: this.productSDLC.Id
        };
  
        if (productUser.UserGraphId && productUser.Name && productUser.Mail && productUser.ProductSDLCId) {
          this.productSDLCService.createSDLCPrimaryContact(productUser).subscribe((response) => {
            this.messageService.add({ severity: 'success', summary: 'Successful', detail: `${productUser.Name} added`, life: 3000 });
            this.logService.sendInfoMessage('CREATE', 'product/' + this.productId + '/sdlc/incidentresponse/' + this.productSDLC.Id + "/users", productUser);

            this.fetchProductSDLCContacts();
            this.hideDialog();
          });
        }
      }
    }
  }
  
  

  removeUser(contact: SDLCPrimaryContact) {
    this.confirmationService.confirm({
        message: 'Are you sure you want to remove this user?',
        accept: () => {
            // User confirmed, perform deletion
            const index = this.sdlcPrimaryContacts.indexOf(contact);
            if (index !== -1 && contact.Id) {
                this.sdlcPrimaryContacts.splice(index, 1);
                // Perform the actual deletion from your backend here if necessary
                this.productSDLCService.deleteSDLCPrimaryContact(contact.Id).subscribe((response) => {
                  this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'User removed', life: 3000 });
                  this.logService.sendInfoMessage('DELETE', 'product/' + this.productId + '/sdlc/incidentresponse/' + this.productSDLC.Id + "/users/" + contact.Id, null, contact);
                });
            }
        }
    });
 }

  openNew() {
    this.editedProductSDLC = {};
    this.incidentResponseDialog = true;
  }

  
  addNewIRContact() {
    if (this.selectedUser != null) {
      const userExists = this.irNewContacts.some(contact => contact.id === this.selectedUser?.id);
      if (userExists) {
        this.messageService.add({ severity: 'warn', summary: 'Warning', detail: 'User already added as new contact', life: 3000 });
      } else {
        this.irNewContacts.push(this.selectedUser);
        this.hideDialog();
      }
    }
    this.selectedUser = null;
  }

  removeNewIRContact(contact: User) {
    const index = this.irNewContacts.indexOf(contact);
    if (index !== -1) {
      this.irNewContacts.splice(index, 1);
    }
  }

  saveNewIncidentResponse() {
    this.newProductSDLC.ProductId = this.productId;
    this.newProductSDLC.SDLCPracticeId = this.incidentResponseSDLCPracticeId;
    this.newProductSDLC.EnrolDate = this.convertToUTC(this.newProductSDLC.EnrolDate);

    this.productSDLCService.createProductSDLC(this.newProductSDLC).subscribe(
        (createdProductSDLC: any) => {
            // Handle successful creation
            const newEntityId = createdProductSDLC.Id;

            this.irNewContacts.forEach((contact) => {
                const productUser = {
                    UserGraphId: contact.id,
                    Name: contact.displayName,
                    Mail: contact.mail,
                    ProductSDLCId: newEntityId
                };

                if (productUser.UserGraphId && productUser.Name && productUser.Mail && newEntityId) {
                    this.productSDLCService.createSDLCPrimaryContact(productUser).subscribe((response) => {
                        this.messageService.add({ severity: 'success', summary: 'Successful', detail: `${productUser.Name} added`, life: 3000 });
                        this.logService.sendInfoMessage('CREATE', 'product/' + this.productId + '/sdlc/incidentresponse/' + this.incidentResponseSDLCPracticeId + "/users", productUser);
                    });
                }
            });

            this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Incident Response created', life: 3000 });

            this.logService.sendInfoMessage('CREATE', 'product/' + this.productId + '/sdlc/incidentresponse/', this.newProductSDLC);

            // Define the list of To recipients (default recipients)
            const toEmails = this.toEmailService.getToEmails();

            // Define the list of CC recipients
            const ccEmails = this.irNewContacts.map(contact => contact.mail);

            const emailContent = this.emailTemplateService.generateEmailContent('Incident Response', this.productName, this.productId, this.productReference, newEntityId, 'sdlc/incidentresponse');
            this.sendEmail(toEmails, ccEmails, `Incident Response Plan Created - ${this.productName} (${this.productId})`, emailContent);

            this.fetchSDLCData();
        },
        (error) => {
            // Handle create error
            console.error('Error creating product SDLC:', error);
        }
    );

    this.incidentResponseDialog = false;
    this.newProductSDLC = {};
  }


  // Emails
  irBefore: ProductSDLC = {};
  irAfter: ProductSDLC = {};
  emailCreation: EmailRequestDto = {};

  sendEmail(toEmails: string[], ccEmails: string[], subject: string, htmlContent: string) {
    const emailRequest: EmailRequestNewDto = {
        toEmails: toEmails,
        ccEmails: ccEmails,
        subject: subject,
        htmlContent: htmlContent,
        plainTextContent: '' // Assuming no plain text content in this case
    };

    this.emailService.sendEmailNew(emailRequest).subscribe(response => {
        console.log('Email sent successfully', response);
    }, error => {
        console.error('Error sending email', error);
    });
  }


  createEmailContent(before: ProductSDLC, after: ProductSDLC) {
    const primaryContactNames = this.sdlcPrimaryContacts.map(contact => contact.Name).join(', ');
  
    const formatDate = (date: Date | undefined) => {
      if (!date) return '';
      const d = new Date(date);
      const localDate = new Date(d.getTime() - (d.getTimezoneOffset() * 60000)); // Adjust for time zone offset
      const day = localDate.getDate().toString().padStart(2, '0');
      const month = (localDate.getMonth() + 1).toString().padStart(2, '0');
      const year = localDate.getFullYear().toString().slice(-2);
      return `${day}/${month}/${year}`;
  };
  
    const highlightIfChanged = (newValue: any, oldValue: any, isDate: boolean = false) => {
      const newFormatted = isDate ? formatDate(newValue) : newValue;
      const oldFormatted = isDate ? formatDate(oldValue) : oldValue;
      return newFormatted !== oldFormatted ? 'style="background-color: #e9e9e9;"' : '';
    };
  
    return `
      <h3>Incident Response Plan</h3>
      <p>The following changes have been made to the Incident Response Plan for ${this.productSDLC.ProductName}:</p>
      <table border="1">
        <thead>
          <tr>
            <th>Item</th>
            <th>New Value</th>
            <th>Previous Value</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Primary Contacts</td>
            <td>${primaryContactNames || ''}</td>
            <td>n/a</td>
          </tr>
          <tr ${highlightIfChanged(after.EnrolDate, before.EnrolDate, true)}>
            <td>Enrol Date</td>
            <td ${highlightIfChanged(after.EnrolDate, before.EnrolDate, true)}>${formatDate(after.EnrolDate)}</td>
            <td ${highlightIfChanged(after.EnrolDate, before.EnrolDate, true)}>${formatDate(before.EnrolDate)}</td>
          </tr>
          <tr ${highlightIfChanged(after.SDLCIRStatusName, before.SDLCIRStatusName)}>
            <td>TM Status</td>
            <td ${highlightIfChanged(after.SDLCIRStatusName, before.SDLCIRStatusName)}>${after.SDLCIRStatusName || ''}</td>
            <td ${highlightIfChanged(after.SDLCIRStatusName, before.SDLCIRStatusName)}>${before.SDLCIRStatusName || ''}</td>
          </tr>
          <tr ${highlightIfChanged(after.Comment, before.Comment)}>
            <td>Comment</td>
            <td ${highlightIfChanged(after.Comment, before.Comment)}>${after.Comment || ''}</td>
            <td ${highlightIfChanged(after.Comment, before.Comment)}>${before.Comment || ''}</td>
          </tr>
        </tbody>
      </table>`;
  }

  convertToUTC(date: Date | undefined): Date | undefined {
    if (date) {
      return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
    }
    return undefined;
  }
}
