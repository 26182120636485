import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Product, ProductContact, ProductContactRequestDto, UserSubscription, UserSubscriptionRequestDto } from 'src/app/api/product';
import { GraphExplorerService } from 'src/app/layout/service/graphexplorer/graph-explorer.service';
import { ProductService } from 'src/app/layout/service/product/products.service';
import { ReportRecipientsService } from 'src/app/layout/service/product/report-recipients.service';

@Component({
  selector: 'app-report-recipient',
  standalone: false,
  templateUrl: './report-recipient.component.html',
  styleUrl: './report-recipient.component.scss'
})
export class ReportRecipientComponent implements OnInit {
  // reportTypes = [
  //   { id: 1, name: 'Vulnerability Report' },
  //   { id: 2, name: 'Product Report' },
  // ]; // Static list of report types

  reportTypes: any[] = [];

  productContacts: any[] = [];
  suiteContacts: any[] = [];
  suites: any[] = [];
  products: any[] = [];

  activeReportType = this.reportTypes[0]; // Default to the first report type
  selectedReportType: string = 'Vulnerability Report';
  selectedReportTypeId: number = 1;

  
  constructor(private reportRecipientsService: ReportRecipientsService, private graphExplorerService: GraphExplorerService, 
    private productService: ProductService, private messageService: MessageService) {
   
  }

  ngOnInit(): void {
    this.getAllReportTypes();
    this.getAllProducts();
    this.getAllSuites();
    this.getAllUsers();
  }

  onTabChange(event: any) {
    const selectedReportType = this.reportTypes[event.index]?.name;
    const selectedReportTypeId = this.reportTypes[event.index]?.id;
    this.onReportTypeSelected(selectedReportType, selectedReportTypeId);
  }

  onReportTypeSelected(reportType: string, reportTypeId: number) {
    this.selectedReportType = reportType; // Set the selected report type
    this.selectedReportTypeId = reportTypeId; // Set the selected report type ID

    this.loadProductContacts(); // Reload the product contacts based on the selected report type
    this.loadSuiteContacts(); // Reload the suite contacts based on the selected report type
  }

  getAllProducts() {
    this.productService.getAllProductsTopbar().subscribe((data) => {
      this.products = data;
      this.loadProductContacts();
    });
  }

  loadProductContacts() {
    if (this.selectedReportType) {
      this.reportRecipientsService.getAllProductReportRecipients(this.selectedReportType).subscribe((data) => {
        // Flatten the data into productContacts
        const existingProductContacts = data.flatMap((report: any) =>
          report.Products.map((product: any) => ({
            ...product,
            expanded: false,
            ToEmails: product.ToEmails || [],
            CCEmails: product.CCEmails || [],
          }))
        );
  
        // Match all products with productContacts
        this.productContacts = this.products.map(product => {
          // Find if the product already exists in the productContacts
          const existingProduct = existingProductContacts.find(contact => contact.Id === product.Id);
          
          if (existingProduct) {
            return existingProduct; // Use the existing product data
          } else {
            // Add default data for missing products
            return {
              Id: product.Id,
              ProductName: product.Name,
              SuiteName: product.SuiteName,
              expanded: false,
              ToEmails: [],
              CCEmails: []
            };
          }
        });
      });
    }
  }
  

  loadSuiteContacts() {
    this.reportRecipientsService.getAllSuiteReportRecipients(this.selectedReportType).subscribe((data) => {
      // Flatten the data into suiteContacts
      const existingSuiteContacts = data.flatMap((report: any) =>
        report.Suites.map((suite: any) => ({
          ...suite,
          expanded: false,
          ToEmails: suite.ToEmails || [],
          CCEmails: suite.CCEmails || [],
        }))
      );

      // Match all suites with suiteContacts
      this.suiteContacts = this.suites.map(suite => {
        // Find if the suite already exists in the suiteContacts
        const existingSuite = existingSuiteContacts.find(contact => contact.Id === suite.Id);
        
        if (existingSuite) {
          return existingSuite; // Use the existing suite data
        } else {
          // Add default data for missing suites
          return {
            Id: suite.Id,
            SuiteName: suite.Name,
            expanded: false,
            ToEmails: [],
            CCEmails: []
          };
        }
      });
    });
  }

  
  getAllSuites() {
    this.productService.getAllSuites().subscribe((suites) => {
      this.suites = suites.map(suite => ({Id: suite.Id, Name: suite.Name}));
      this.loadSuiteContacts();
    });
  }

  getAllReportTypes() {
    this.reportRecipientsService.getAllReportTypes().subscribe((reportTypes) => {
      this.reportTypes = reportTypes.map(reportType => ({id: reportType.Id, name: reportType.Name}));
    });

  }


  trimOption(option: string): string {
    return option ? option.trim() : '';
  }

  // Move contact from "To" to "CC"
  moveToCC(contact: UserSubscription, product: any) {
    product.ToEmails = product.ToEmails.filter((c: any) => c.Id !== contact.Id);
    contact.IsToEmail = false;
    product.CCEmails.push(contact);
    
    this.updateContact(contact);
  }

  // Move contact from "CC" to "To"
  moveToTo(contact: UserSubscription, product: any) {
    product.CCEmails = product.CCEmails.filter((c: any) => c.Id !== contact.Id);
    contact.IsToEmail = true;
    product.ToEmails.push(contact);

    this.updateContact(contact);
  }

  updateContact(contact: UserSubscription): void {
    const updatedContact = {
      Id: contact.Id,
      IsToEmail: contact.IsToEmail,
    };

    if (contact.Id) {
      this.reportRecipientsService.patchProductContact(contact.Id.toString(), updatedContact)
        .subscribe(() => {
        });
    }
  }

  handleDelete(contact: UserSubscription, product: any): void {
      if (contact.Id !== undefined) {
        this.reportRecipientsService.deleteReportRecipient(contact.Id).subscribe(() => {
          product.ToEmails = product.ToEmails.filter((c: any) => c.Id !== contact.Id);
          product.CCEmails = product.CCEmails.filter((c: any) => c.Id !== contact.Id);
        });
      } else {
        console.error('Contact ID is undefined. Cannot delete the contact.');
      }
    }


  // Adding Users
  getAllUsers() {
    this.graphExplorerService.getAllADUsers().subscribe(users => {
      this.users = users;
    });
  }

  searchUsers(event?: any) {
    if (event.filter) {
      this.graphExplorerService.searchADUsers(event.filter).subscribe((users) => {
        this.users = users;
      });
    } else {
      this.getAllUsers();
    }
  }


  userDialog: boolean = false;
  users: any[] = [];
  selectedUser: any = null;
  userDialogAction: string = '';
  targetEntry: any = null;
  targetEntryType: 'product' | 'suite' = 'product';

  openUserDialog(action: string, entry: any, type: 'product' | 'suite') {
    this.userDialog = true;
    this.userDialogAction = action;
    this.targetEntry = entry;
    this.targetEntryType = type;
  }

  hideUserDialog() {
    this.userDialog = false;
    this.selectedUser = null;
  }

  addUserToRecipients() {
    const newContact: UserSubscriptionRequestDto = {
      UserGraphId: this.selectedUser.id,
      Name: this.selectedUser.displayName,
      Mail: this.selectedUser.mail,
      ProductId: this.targetEntryType === 'product' ? this.targetEntry.Id : undefined,
      SuiteId: this.targetEntryType === 'suite' ? this.targetEntry.Id : undefined,
      IsToEmail: this.userDialogAction === 'addToToRecipients',
      ReportTypeId: this.selectedReportTypeId
    };

    this.reportRecipientsService.addReportRecipient(newContact).subscribe((response) => {
      console.log('response from createProductContact', response);
      if (newContact.IsToEmail) {
        this.targetEntry.ToEmails.push(response);
      } else {
        this.targetEntry.CCEmails.push(response);
      }
      this.hideUserDialog();
    });
  }

  selectedEmails: any[] = [];  // Holds the selected rows

  composeEmail(selectedEmails: any[]): void {
    let toEmails: string[] = [];
    let ccEmails: string[] = [];
  
    selectedEmails.forEach(product => {
      if (product.ToEmails) {
        product.ToEmails.forEach((contact: any) => {
          toEmails.push(contact.Mail);
        });
      }
      if (product.CCEmails) {
        product.CCEmails.forEach((contact: any) => {
          ccEmails.push(contact.Mail);
        });
      }
    });
  
    const toEmailString = toEmails.join(';');
    const ccEmailString = ccEmails.join(';');
  
    const subject = 'Subject of the Email';
    const body = 'Body of the email content';
  
    let mailtoLink = `mailto:${toEmailString}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
  
    if (ccEmailString) {
      mailtoLink += `&cc=${encodeURIComponent(ccEmailString)}`;
    }
  
    window.location.href = mailtoLink;
  }

  hasNoEmails(entry: any, type: 'product' | 'suite'): boolean {
    if (type === 'product') {
      return (!entry.ToEmails || entry.ToEmails.length === 0) &&
             (!entry.CCEmails || entry.CCEmails.length === 0);
    } else if (type === 'suite') {
      return (!entry.ToEmails || entry.ToEmails.length === 0) &&
             (!entry.CCEmails || entry.CCEmails.length === 0);
    }
    return false;
  }
  
}

