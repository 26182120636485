import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, map } from "rxjs";
import { CategoriesDto, CategoriesExpandedDto, NewProductSoftwareDto, NewProductSoftwareVersionDto, NewProductSoftwareVersionLogDto, ProductSoftwareDto, ProductSoftwareVersionDto, ProductSoftwareVersionLogDto, SoftwareAndVersionsDto, SoftwareDto, TechStackGroupDto, UpdateProductSoftwareDto } from "src/app/api/approved-software";
import { environment } from "src/environments/environment";

@Injectable(
    {
        providedIn: 'root'
    }
)

export class ApprovedSoftwareService {
    baseApiUrl: string = environment.baseApiUrl;
    constructor(private http: HttpClient) { }

    // Categories

    getAllCategories(): Observable<CategoriesDto[]> {
        return this.http.get<any>(this.baseApiUrl + `/api/architecture/software/`).pipe(
            map((response) => {
            // Check if the response has a "value" property
            if (response && response.value) {
                // Return the array of objects from the "value" property
                return response.value;
            } else {
                // If there is no "value" property, return the entire response
                return response;
            }
            })
        );
    }

    getCategoriesExpanded(): Observable<CategoriesExpandedDto[]> {
        return this.http.get<any>(this.baseApiUrl + `/api/architecture/software/Categories/Expanded`).pipe(
            map((response) => {
            // Check if the response has a "value" property
            if (response && response.value) {
                // Return the array of objects from the "value" property
                return response.value;
            } else {
                // If there is no "value" property, return the entire response
                return response;
            }
            })
        );
    }



    // Software

    getAllSoftware(): Observable<SoftwareDto[]> {
        return this.http.get<any>(this.baseApiUrl + `/api/architecture/Software`).pipe(
            map((response) => {
            // Check if the response has a "value" property
            if (response && response.value) {
                // Return the array of objects from the "value" property
                return response.value;
            } else {
                // If there is no "value" property, return the entire response
                return response;
            }
            })
        );
    }

    getSoftwareById(softwareId: number): Observable<SoftwareDto> {
        return this.http.get<SoftwareDto>(this.baseApiUrl + `/api/architecture/Software/${softwareId}`);
    }

    getSoftwareByCategory(categoryId: number): Observable<SoftwareDto[]> {
        return this.http.get<any>(this.baseApiUrl + `/api/architecture/Software/bycategory/${categoryId}`).pipe(
            map((response) => {
            // Check if the response has a "value" property
            if (response && response.value) {
                // Return the array of objects from the "value" property
                return response.value;
            } else {
                // If there is no "value" property, return the entire response
                return response;
            }
            })
        );
    }

    getByTechGroupId(techGroupId: number): Observable<SoftwareDto[]> {
        return this.http.get<any>(this.baseApiUrl + `/api/architecture/Software/bytechgroup?id=${techGroupId}`).pipe(
            map((response) => {
            // Check if the response has a "value" property
            if (response && response.value) {
                // Return the array of objects from the "value" property
                return response.value;
            } else {
                // If there is no "value" property, return the entire response
                return response;
            }
            })
        );
    }

    getByTechGroupName(techGroupName: string): Observable<SoftwareDto[]> {
        return this.http.get<any>(this.baseApiUrl + `/api/architecture/Software/bytechgroup?name=${techGroupName}`).pipe(
            map((response) => {
            // Check if the response has a "value" property
            if (response && response.value) {
                // Return the array of objects from the "value" property
                return response.value;
            } else {
                // If there is no "value" property, return the entire response
                return response;
            }
            })
        );
    }

    getSoftwareVersions(softwareId: number): Observable<SoftwareAndVersionsDto> {
        return this.http.get<SoftwareAndVersionsDto>(this.baseApiUrl + `/api/architecture/Software/${softwareId}/versions`);
    }


    // Tech Stack Groups

    getAllTechStackGroups(): Observable<TechStackGroupDto[]> {
        return this.http.get<any>(this.baseApiUrl + `/api/architecture/TechStackGroups`).pipe(
            map((response) => {
            // Check if the response has a "value" property
            if (response && response.value) {
                // Return the array of objects from the "value" property
                return response.value;
            } else {
                // If there is no "value" property, return the entire response
                return response;
            }
            })
        );
    }



    // Product Software Links
    getProductSoftwareLinks(productId: number): Observable<ProductSoftwareDto[]> {
        return this.http.get<any>(this.baseApiUrl + `/api/architecture/products/${productId}/softwareLinks`).pipe(
            map((response) => {
            // Check if the response has a "value" property
            if (response && response.value) {
                // Return the array of objects from the "value" property
                return response.value;
            } else {
                // If there is no "value" property, return the entire response
                return response;
            }
            })
        );
    }

    getSoftwareLinkById(softwareId: number): Observable<ProductSoftwareDto> {
        return this.http.get<ProductSoftwareDto>(this.baseApiUrl + `/api/architecture/products/softwareLinks/${softwareId}`);
    }

    createProductSoftwareLink(productSoftware: NewProductSoftwareDto): Observable<any> {
        return this.http.post<NewProductSoftwareDto>(this.baseApiUrl + `/api/architecture/products/softwareLinks`, productSoftware);
    }

    updateProductSoftwareLink(id: number, productSoftware: UpdateProductSoftwareDto): Observable<UpdateProductSoftwareDto> {
        return this.http.put<UpdateProductSoftwareDto>(this.baseApiUrl + `/api/architecture/products/softwareLinks/${id}`, productSoftware);
    }

    deleteProductSoftwareLink(softwareId: number): Observable<any> {
        return this.http.delete(this.baseApiUrl + `/api/architecture/products/softwareLinks/${softwareId}`);
    }

    // Product Software Version Links

    getProductSoftwareVersionLinks(productId: number, softwareId: number): Observable<ProductSoftwareVersionDto[]> {
        return this.http.get<any>(this.baseApiUrl + `/api/architecture/products/${productId}/software/${softwareId}/versions`).pipe(
            map((response) => {
            // Check if the response has a "value" property
            if (response && response.value) {
                // Return the array of objects from the "value" property
                return response.value;
            } else {
                // If there is no "value" property, return the entire response
                return response;
            }
            })
        );
    }

    createProductSoftwareVersionLink(productId: number, softwareId: number, productSoftwareVersion: NewProductSoftwareVersionDto): Observable<NewProductSoftwareVersionDto> {
        return this.http.post<NewProductSoftwareVersionDto>(this.baseApiUrl + `/api/architecture/products/${productId}/software/${softwareId}/versions`, productSoftwareVersion);
    }

    deleteProductSoftwareVersionLink(versionId: number): Observable<any> {
        return this.http.delete(this.baseApiUrl + `/api/architecture/products/softwareVersionLinks/${versionId}`);
    }

    // Product Software Version Logs
    createProductSoftwareVersionLog(productId: number, softwareId: number, productSoftwareVersionLog: NewProductSoftwareVersionLogDto): Observable<NewProductSoftwareVersionLogDto> {
        return this.http.post<NewProductSoftwareVersionLogDto>(this.baseApiUrl + `/api/architecture/products/${productId}/software/${softwareId}/versions/logEntries`, productSoftwareVersionLog);
    }

    getLogEntryById(id: number): Observable<ProductSoftwareVersionLogDto> {
        return this.http.get<ProductSoftwareVersionLogDto>(this.baseApiUrl + `/api/architecture/products/software/versions/logentries/${id}`);
    }

    deleteProductSoftwareTechStackGroup(productSoftwareId: number, techStackGroupId: number): Observable<any> {
        return this.http.delete(this.baseApiUrl + `/api/architecture/products/softwareLinks/${productSoftwareId}/techStackGroups/${techStackGroupId}`);
    }
}