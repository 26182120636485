<h1>
  SDLC Maturity Report
  <a href="https://irissoftware.sharepoint.com/sites/EngineeringIntranet/SitePages/IRIS-Lens--SDLC-Maturity-Report.aspx" target="_blank">
    <p-button icon="pi pi-question"></p-button>
  </a>
</h1>

<p-toast></p-toast>

<div *ngIf="isLoading">
  <p-skeleton width="10%" height="1rem"></p-skeleton>
</div>

<div *ngIf="!isLoading">

  <div style="display: flex; justify-content: space-between; align-items: center; margin-bottom: 10px;">
    <div style="display: flex; gap: 5px;">
      <p-multiSelect [options]="tiers"
                     [(ngModel)]="selectedTiers"
                     placeholder="Select Tiers"
                     optionLabel="Name"
                     display="chip"
                     [showToggleAll]="false"
                     (onChange)="onTiersChange($event)">
      </p-multiSelect>
      <p-multiSelect [options]="divisions"
                     [(ngModel)]="selectedDivisions"
                     placeholder="Select Divisions"
                     optionLabel="Name"
                     display="chip"
                     [showToggleAll]="false"
                     (onChange)="onDivisionsChange($event)">
      </p-multiSelect>
      <p-multiSelect [options]="suites"
                     [(ngModel)]="selectedSuites"
                     placeholder="Select Suites"
                     optionLabel="Name"
                     display="chip"
                     [showToggleAll]="false"
                     (onChange)="onSuitesChange($event)">
      </p-multiSelect>
      <p-multiSelect [options]="products"
                     [(ngModel)]="selectedProducts"
                     placeholder="Select Products"
                     optionLabel="Name"
                     display="chip"
                     [showToggleAll]="false"
                     (onChange)="onProductsChange($event)">
      </p-multiSelect>
      <button type="button" icon="pi pi-file-export" title="Export as CSV" class="p-element p-ripple p-button-success p-button p-component p-button-icon-only ml-7" (click)="buildCSV()">
        <span class="p-button-icon pi pi-file-export" aria-hidden="true"></span>
      </button>

    </div>
    <div class="total-maturity">
      Average Maturity: {{ getTotalMaturity()}}
    </div>
    
  </div>


  <p-table [value]="filteredReports">
    <ng-template pTemplate="header">
      <tr>
        <th style="width:35%">Product</th>
        <th style="width:5%">Maturity</th>
        <th *ngFor="let column of columns" style="width: 12%; text-align: center">{{ column }}</th>
        <th style="width: 12%; text-align: center">Ext. Pen Test</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-product>
      <tr>
        <td style="width: 35%; border-bottom: none !important;">
          <a [routerLink]="['/product', product.id, 'sdlc','dashboard']">
            {{ displayProductRef(product.productRef) }}{{ product.name }}
          </a>
        </td>
        <td rowspan="2">{{ calculateMaturity(product).toFixed(0) }}%</td>
        <td *ngFor="let column of columns" rowspan="2" style="text-align:center">
          <span [ngClass]="getStatusClass(getStatus(product.productSDLCs, column))" [pTooltip]="getExplanation(product.productSDLCs, column)" [escape]="false" tooltipPosition="bottom" placeholder="bottom" style="cursor:pointer">
            {{ getStatus(product.productSDLCs, column) }}
          </span>
        </td>
        <td rowspan="2" style="text-align:center">
          <span [ngClass]="getStatusClass(getPenTestStatus(product.externalPentest))" [pTooltip]="getPenTestExplanation(product.externalPentest)" [escape]="false" tooltipPosition="bottom" placeholder="bottom" style="cursor:pointer">
            {{ getPenTestStatus(product.externalPentest) }}
          </span>
        </td>
      </tr>
      <tr class="no-top-border">
        <td colspan="1">
          <div style="display: flex; gap: 10px;">
            <span style="width: 30%" class="small-attribute">{{ product.tierName }}</span>
            <span style="width: 40%" class="small-attribute">{{ product.divisionName }}</span>
            <span style="width: 30%" class="small-attribute">{{ product.suiteName }}</span>
          </div>
        </td>
      </tr>
    </ng-template>
  </p-table>


</div>
