import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, map } from "rxjs";
import { ComponentManagementDto, FunctionTypeDto, LastIncidentSeverityDto, ProdSecMonitoringDto, ProductContributionDto, ProductDataTypeDto, ProductMetric, ProductMetricFunctionTypeRequestDto, ProductMetricPutDto, ProductMetricVulnerabilityManagementRequestDto, TierDto, VulnerabilityManagementDto } from "src/app/api/product-metric";
import { environment } from "src/environments/environment";

@Injectable(
    {
        providedIn: 'root'
    }
)

export class ProductMetricService {
    baseApiUrl: string = environment.baseApiUrl;
    constructor(private http: HttpClient) { }

    getProductMetricById(productMetricId: number): Observable<ProductMetric> {
      return this.http.get<ProductMetric>(this.baseApiUrl + `/api/ProductMetrics/${productMetricId}?$expand=ProductMetricVulnerabilityManagements, ProductMetricFunctionTypes`);
    }

    updateProductMetric(id: number, productMetricPutDto: ProductMetricPutDto): Observable<ProductMetricPutDto> {
      return this.http.put<ProductMetricPutDto>(this.baseApiUrl + `/api/ProductMetrics/${id}`, productMetricPutDto);
    }

    // Tiers

    getAllTiers(): Observable<TierDto[]> {
        return this.http.get<any>(this.baseApiUrl + `/api/Tiers`).pipe(
            map((response) => {
            // Check if the response has a "value" property
            if (response && response.value) {
                // Return the array of objects from the "value" property
                return response.value;
            } else {
                // If there is no "value" property, return the entire response
                return response;
            }
            })
        );
    }

    getTierCount(): Observable<number> {
        return this.http.get<number>(this.baseApiUrl + `/api/Tiers/$count`)
    }

    // Product Data Types

    getAllProductDataTypes(): Observable<ProductDataTypeDto[]> {
        return this.http.get<any>(this.baseApiUrl + `/api/ProductDataTypes`).pipe(
            map((response) => {
            // Check if the response has a "value" property
            if (response && response.value) {
                // Return the array of objects from the "value" property
                return response.value;
            } else {
                // If there is no "value" property, return the entire response
                return response;
            }
            })
        );
    }

    getProductDataTypeCount(): Observable<number> {
        return this.http.get<number>(this.baseApiUrl + `/api/ProductDataTypes/$count`)
    }

    // Product Contributions

    getAllProductContributions(): Observable<ProductContributionDto[]> {
        return this.http.get<any>(this.baseApiUrl + `/api/ProductContributions`).pipe(
            map((response) => {
            // Check if the response has a "value" property
            if (response && response.value) {
                // Return the array of objects from the "value" property
                return response.value;
            } else {
                // If there is no "value" property, return the entire response
                return response;
            }
            })
        );
    }

    getProductContributionCount(): Observable<number> {
        return this.http.get<number>(this.baseApiUrl + `/api/ProductContributions/$count`)
    }

    // Last Incident Severity

    getAllLastIncidentSeverities(): Observable<LastIncidentSeverityDto[]> {
        return this.http.get<any>(this.baseApiUrl + `/api/LastIncidentSeverities`).pipe(
            map((response) => {
            // Check if the response has a "value" property
            if (response && response.value) {
                // Return the array of objects from the "value" property
                return response.value;
            } else {
                // If there is no "value" property, return the entire response
                return response;
            }
            })
        );
    }

    getLastIncidentSeverityCount(): Observable<number> {
        return this.http.get<number>(this.baseApiUrl + `/api/LastIncidentSeverities/$count`)
    }

    // Component Management

    getAllComponentManagements(): Observable<ComponentManagementDto[]> {
        return this.http.get<any>(this.baseApiUrl + `/api/ComponentManagements`).pipe(
            map((response) => {
            // Check if the response has a "value" property
            if (response && response.value) {
                // Return the array of objects from the "value" property
                return response.value;
            } else {
                // If there is no "value" property, return the entire response
                return response;
            }
            })
        );
    }

    getComponentManagementCount(): Observable<number> {
        return this.http.get<number>(this.baseApiUrl + `/api/ComponentManagements/$count`)
    }

    // Prod Sec Monitoring

    getAllProdSecMonitorings(): Observable<ProdSecMonitoringDto[]> {
        return this.http.get<any>(this.baseApiUrl + `/api/ProdSecMonitorings`).pipe(
            map((response) => {
            // Check if the response has a "value" property
            if (response && response.value) {
                // Return the array of objects from the "value" property
                return response.value;
            } else {
                // If there is no "value" property, return the entire response
                return response;
            }
            })
        );
    }

    getProdSecMonitoringCount(): Observable<number> {
        return this.http.get<number>(this.baseApiUrl + `/api/ProdSecMonitorings/$count`)
    }

    // Vulnerability Management

    getAllVulnerabilityManagements(): Observable<VulnerabilityManagementDto[]> {
        return this.http.get<any>(this.baseApiUrl + `/api/VulnerabilityManagements`).pipe(
            map((response) => {
            // Check if the response has a "value" property
            if (response && response.value) {
                // Return the array of objects from the "value" property
                return response.value;
            } else {
                // If there is no "value" property, return the entire response
                return response;
            }
            })
        );
    }

    addProductMetricVulnerabilityManagement(productMetricVulnerabilityManagementRequest: ProductMetricVulnerabilityManagementRequestDto)
    : Observable<ProductMetricVulnerabilityManagementRequestDto> {
        return this.http.post<ProductMetricVulnerabilityManagementRequestDto>(this.baseApiUrl + `/api/ProductMetricVulnerabilityManagements`, 
            productMetricVulnerabilityManagementRequest);
    }

    removeProductMetricVulnerabilityManagement(id: number): Observable<any> {
        return this.http.delete<any>(this.baseApiUrl + `/api/ProductMetricVulnerabilityManagements/${id}`);
    }

    // Function Type

    getAllFunctionTypes(): Observable<FunctionTypeDto[]> {
        return this.http.get<any>(this.baseApiUrl + `/api/FunctionTypes`).pipe(
            map((response) => {
            // Check if the response has a "value" property
            if (response && response.value) {
                // Return the array of objects from the "value" property
                return response.value;
            } else {
                // If there is no "value" property, return the entire response
                return response;
            }
            })
        );
    }

    addProductMetricFunctionType(productMetricFunctionTypeRequest: ProductMetricFunctionTypeRequestDto) 
    : Observable<ProductMetricFunctionTypeRequestDto> {
        return this.http.post<ProductMetricFunctionTypeRequestDto>(this.baseApiUrl + `/api/ProductMetricFunctionTypes`, 
            productMetricFunctionTypeRequest);
    }

    removeProductMetricFunctionType(id: number): Observable<any> {
        return this.http.delete<any>(this.baseApiUrl + `/api/ProductMetricFunctionTypes/${id}`);
    }
}