import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { VulnerabilityService } from 'src/app/layout/service/vulnerability.service';
import { ProductService } from 'src/app/layout/service/product/products.service';
import { Message, MessageService } from 'primeng/api';
import { ProductMetricService } from '../../../service/product/product-metric.service';
import { forkJoin } from 'rxjs';
import { SortEvent } from 'primeng/api';

@Component({
  selector: 'app-vuln-search',
  templateUrl: './vuln-search.component.html',
  styleUrls: ['./vuln-search.component.scss']
})

export class VulnSearchComponent implements OnInit {

  messages: Message[] = [];
  isLoading = true;
  products: any[] = [];
  suites: any[] = [];
  divisions: any[] = [];
  tiers: any[] = [];
  bases: string[] = [];
  severities: any[] = [{ Id: 0, Name: "critical" }, { Id: 1, Name: "high" }, { Id: 2, Name: "medium" }, { Id: 3, Name: "low" }];
  ages: any[] = [{ Id: 0, Name: "new" }, { Id: 1, Name: "aged" }];
  types: any[] = [{ Id: 0, Name: "Vulnerability" }, { Id: 1, Name: "Licence" }];
  tools: any[] = [{ Id: 0, Name: "Snyk" }, { Id: 1, Name: "Sonarqube (Enterprise)" }, { Id: 2, Name: "Tenable WAS" }, { Id: 3, Name: "Nessus" }, { Id: 4, Name: "SharePoint" }, { Id: 5, Name: "Lens" }];
  practices: any[] = [{ Id: 0, Name: "SCA" }, { Id: 1, Name: "SAST" }, { Id: 2, Name: "DAST" }, { Id: 3, Name: "NP-DAST" }, { Id: 4, Name: "External Pen Test" }, { Id: 5, Name: "Internal Pen Test" }, { Id: 6, Name: "VA" }];

  selectedTiers: any[] = [];
  selectedDivisions: any[] = [];
  selectedSuites: any[] = [];
  selectedProducts: any[] = [];
  selectedSeverities: any[] = [];
  selectedAges: any[] = [];
  selectedTools: any[] = [];
  selectedPractices: any[] = [];
  selectedTypes: any[] = [];
  selectedBases: string[] = [];

  showActiveOnly: boolean = false;
  showFixableOnly: boolean = false;

  keyTerms: string[] = [];

  data: any[] = [];
  dataIsLoading: boolean = false;

  isReady: boolean = false;

  summaryOpen: boolean = true;

  selectedValue: string = 'Instances';

  criticals: number = 0;
  highs: number = 0;
  mediums: number = 0;
  lows: number = 0;

  sortField: string = "rankedSeverity";
  sortOrder: number = 1;

  rowsPerPage: number = 10; // Default rows per page
  rowsOptions: { label: string, value: number }[] = [
    { label: '5', value: 5 },
    { label: '10', value: 10 },
    { label: '20', value: 20 },
    { label: '50', value: 50 },
    { label: '100', value: 100 },
    { label: '250', value: 250 },
    { label: '500', value: 500 },
  ];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private vulnerabilityService: VulnerabilityService,
    private messageService: MessageService,
    private productMetricService: ProductMetricService,
    private productService: ProductService,

  ) { }

  ngOnInit(): void {
    forkJoin({
      suites: this.productService.getAllSuites(),
      divisions: this.productService.getAllDivisions(),
      tiers: this.productMetricService.getAllTiers(),
      products: this.productService.getAllProducts(),
      bases: this.vulnerabilityService.baseVulnerabilities(),
    }).subscribe(({ suites, divisions, tiers, products, bases }) => {
      // Set the loaded data
      this.suites = suites.sort((a, b) => (a.Name ?? '').localeCompare(b.Name ?? ''));
      this.divisions = divisions.sort((a, b) => (a.Name ?? '').localeCompare(b.Name ?? ''));
      this.tiers = tiers;
      this.products = products.sort((a, b) => (a.Name ?? '').localeCompare(b.Name ?? ''));
      this.bases = bases;

      // Parse the query parameters to set selected values
      this.route.queryParams.subscribe(params => {
        this.selectedTiers = this.getSelectedItems(params['tiers'], this.tiers);
        this.selectedDivisions = this.getSelectedItems(params['divisions'], this.divisions);
        this.selectedSuites = this.getSelectedItems(params['suites'], this.suites);
        this.selectedBases = params['bases'] ? params['bases'].split(',') : [];
        this.selectedProducts = this.getSelectedItems(params['products'], this.products);
        this.selectedSeverities = this.getSelectedItems(params['severities'], this.severities);
        this.selectedAges = this.getSelectedItems(params['ages'], this.ages);
        this.selectedTools = this.getSelectedItems(params['tools'], this.tools);
        this.selectedPractices = this.getSelectedItems(params['practices'], this.practices);
        this.showActiveOnly = params['activeOnly'] === 'true';
        this.showFixableOnly = params['fixableOnly'] === 'true';
        this.keyTerms = params['keyTerms'] ? params['keyTerms'].split(',') : [];
        this.rowsPerPage = params['rowsPerPage'] ? parseInt(params['rowsPerPage']) : 10;

        const toolName = params['toolName'] ?? '';
        if (toolName !== '') {
          // Find the tool in the list of tools
          const tool = this.tools.find(item => item.Name === toolName);
          if (tool) {
            this.selectedTools = [tool];
          }
        }

        const typeName = params['vulnerabilityTypes'] ?? '';
        if (typeName !== '') {
          // Find the tool in the list of tools
          const typen = this.types.find(item => item.Name === typeName);
          if (typen) {
            this.selectedTypes = [typen];
          }
        }

        //If the page is pre-filtered, then display the data
        if (this.selectedTiers.length > 0 || this.selectedDivisions.length > 0 || this.selectedSuites.length > 0 ||
          this.selectedProducts.length > 0 || this.selectedSeverities.length > 0 || this.selectedAges.length > 0 ||
          this.selectedTools.length > 0 || this.selectedPractices.length > 0 || this.keyTerms.length > 0 || this.showActiveOnly == true || this.showFixableOnly == true) {
          this.fetchVulnerabilities();
        }

      });

      this.isLoading = false;
    });
  }

  private getSelectedItems(paramValues: string, allItems: any[]): any[] {
    if (!paramValues) {
      return [];
    }
    const ids = paramValues.split(',').map(Id => Id.trim());
    return allItems.filter(item => ids.includes(item.Id.toString()));
  }

  private parseQueryParam(param: string, collection: any[]): any[] {
    if (!param) return [];
    const values = param.split(',');
    return collection.filter(item => values.includes(item.Id.toString()) || values.includes(item.Name));
  }

  // Update the URL query parameters when a selection changes
  updateQueryParams(): void {
    const urlTree = this.router.createUrlTree([], {
      queryParams: {
        tiers: this.selectedTiers.map(item => item.Id).join(','),
        divisions: this.selectedDivisions.map(item => item.Id).join(','),
        suites: this.selectedSuites.map(item => item.Id).join(','),
        products: this.selectedProducts.map(item => item.Id).join(','),
        severities: this.selectedSeverities.map(item => item.Id).join(','),
        ages: this.selectedAges.map(item => item.Id).join(','),
        tools: this.selectedTools.map(item => item.Id).join(','),
        practices: this.selectedPractices.map(item => item.Id).join(','),
        types: this.selectedTypes.map(item => item.Id).join(','),
        keyTerms: this.keyTerms.toString(),
        bases: this.selectedBases.map(item => item).join(','),
        activeOnly: this.showActiveOnly,
        fixableOnly: this.showFixableOnly,
        rowsPerPage: this.rowsPerPage,
        toolName: ""
      },
      queryParamsHandling: 'merge'
    });

    const fullUrl = window.location.origin + this.router.serializeUrl(urlTree);
    console.log(fullUrl);

    navigator.clipboard.writeText(fullUrl)
      .then(() => {
        console.log('URL copied to clipboard!');
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Filter URL copied to clipboard', life: 3000 });       
      })
      .catch(err => {
        console.error('Failed to copy the URL:', err);
      });
  }

  private fetchVulnerabilities(): void {

    console.log("Fetching vulnerabilities");

    this.isReady = true;

    let filter = "toolName ne 'ControlRecord' and ";

    if (this.selectedTiers.length > 0) {
      filter += "lensTierId in (" + this.selectedTiers.map(item => item.Id).join(",") + ") and ";
    }
    if (this.selectedDivisions.length > 0) {
      filter += "lensDivisionId in (" + this.selectedDivisions.map(item => item.Id).join(",") + ") and ";
    }
    if (this.selectedSuites.length > 0) {
      filter += "lensSuiteId in (" + this.selectedSuites.map(item => item.Id).join(",") + ") and ";
    }
    if (this.selectedProducts.length > 0) {
      filter += "lensObjectId in (" + this.selectedProducts.map(item => item.Id).join(",") + ") and ";
    }
    if (this.selectedSeverities.length > 0) {
      filter += "severity in (" + this.selectedSeverities.map(item => "'" + item.Name + "'").join(",") + ") and ";
    }
    if (this.selectedTools.length > 0) {
      filter += "toolName in (" + this.selectedTools.map(item => "'" + item.Name + "'").join(",") + ") and ";
    }
    if (this.selectedTypes.length > 0) {
      filter += "vulnerabilityType in (" + this.selectedTypes.map(item => "'" + item.Name + "'").join(",") + ") and ";
    }
    if (this.selectedPractices.length > 0) {
      filter += "practiceName in (" + this.selectedPractices.map(item => "'" + item.Name + "'").join(",") + ") and ";
    }
    if (this.selectedBases.length > 0) {
      //loop through the bases and add them to the filter
      filter += "("
      this.selectedBases.forEach((base, index) => {
        filter += "contains(baseVulnerabilities, '" + base + "') or ";        
      });
      filter = filter.slice(0, -4);
      filter += ") and "
    }
    if (this.showActiveOnly) {
      filter += "lensActiveStatus eq true and ";
    }
    if (this.showFixableOnly) {
      filter += "isFixable eq true and ";
    }

    if (this.keyTerms.length > 0) {
      filter += '('
      //loop through the key terms and add them to the filter
      this.keyTerms.forEach((term, index) => {

        term = term.trim();

        //escape single and double quotes
        term = term.replace(/'/g, "''");
        term = term.replace(/"/g, '""');

        filter += "contains(synopsis, '" + term + "') or ";
        filter += "contains(title, '" + term + "') or ";
        filter += "contains(name, '" + term + "') or ";
        filter += "contains(scanTarget, '" + term + "') or ";
        filter += "contains(pluginName, '" + term + "') or ";
        filter += "contains(remediation, '" + term + "') or ";
        filter += "contains(description, '" + term + "') or ";
        filter += "contains(createdBy, '" + term + "') or ";
        filter += "contains(key, '" + term + "') or ";
        filter += "contains(org, '" + term + "') or ";
        filter += "contains(slug, '" + term + "') or ";
        filter += "contains(package, '" + term + "') or ";
        filter += "contains(project, '" + term + "') or ";
        filter += "contains(component, '" + term + "') or ";
        filter += "contains(author, '" + term + "') or ";
        filter += "contains(hostname, '" + term + "') or ";
      });

      //remove the last 'or' from the filter
      filter = filter.slice(0, -4);

      filter += ')'
    }

    // Check if there is an 'and' at the end of the sentence, and remove it
    if (filter.endsWith(" and ")) {
      filter = filter.slice(0, -5);
    }

    if (filter !== "") {
      filter = "$filter=" + filter;
    }
    console.log(filter);
    this.dataIsLoading = true;

    this.vulnerabilityService.searchVulnerabilities(filter).subscribe(data => {

      let tmpData = data;

      console.log(tmpData.length);

      if (this.selectedAges.length > 0) {
        //if selectedages only contains 'new' then filter out aged vulnerabilities
        if (this.selectedAges.length === 1 && this.selectedAges[0].Name === 'new') {
          tmpData = data.filter((vuln: any) => vuln.isAged === "new");
        }
        //if selectedages only contains 'aged' then filter out new vulnerabilities
        else if (this.selectedAges.length === 1 && this.selectedAges[0].Name === 'aged') {
          tmpData = data.filter((vuln: any) => vuln.isAged === "aged");
        }
      }

      this.data = tmpData;
      this.calculateTotals();
      this.dataIsLoading = false;
    });
  }

  calculateTotals() {

    this.criticals = 0;
    this.highs = 0;
    this.mediums = 0;
    this.lows = 0;

    if (this.selectedValue === 'Instances') {

      this.criticals += this.data
        .filter((vuln: any) => vuln.severity.toLowerCase() === 'critical')
        .reduce((sum: number, vuln: any) => sum + vuln.instances, 0);

      this.highs += this.data
        .filter((vuln: any) => vuln.severity.toLowerCase() === 'high')
        .reduce((sum: number, vuln: any) => sum + vuln.instances, 0);

      this.mediums += this.data
        .filter((vuln: any) => vuln.severity.toLowerCase() === 'medium')
        .reduce((sum: number, vuln: any) => sum + vuln.instances, 0);

      this.lows += this.data
        .filter((vuln: any) => vuln.severity.toLowerCase() === 'low')
        .reduce((sum: number, vuln: any) => sum + vuln.instances, 0);
    } else {

      this.criticals += this.data
        .filter((vuln: any) => vuln.severity.toLowerCase() === 'critical')
        .length;

      this.highs += this.data
        .filter((vuln: any) => vuln.severity.toLowerCase() === 'high')
        .length;

      this.mediums += this.data
        .filter((vuln: any) => vuln.severity.toLowerCase() === 'medium')
        .length;

      this.lows += this.data
        .filter((vuln: any) => vuln.severity.toLowerCase() === 'low')
        .length;
    }
  }

  doSearch() {
    this.fetchVulnerabilities();
  }

  // Event handlers for each selection change
  onTiersChange(event: any) {
    this.selectedTiers = event.value;
  }

  onDivisionsChange(event: any) {
    this.selectedDivisions = event.value;
  }

  onSuitesChange(event: any) {
    this.selectedSuites = event.value;
  }

  onProductsChange(event: any) {
    this.selectedProducts = event.value;
  }

  onSeveritiesChange(event: any) {
    this.selectedSeverities = event.value;
  }

  onBasesChange(event: any) {
    this.selectedBases = event.value;
  }

  onAgedChange(event: any) {
    this.selectedAges = event.value;
  }

  onToolsChange(event: any) {
    this.selectedTools = event.value;
  }

  onPracticesChange(event: any) {
    this.selectedPractices = event.value;
  }

  onTypeChange(event: any) {
    this.selectedTypes = event.value;
  }

  onShowActiveOnlyChange(event: any) {
    this.showActiveOnly = event.checked;
  }

  onShowFixableOnlyChange(event: any) {
    this.showFixableOnly = event.checked;
  }

  onSort(event: any) {
    console.log(event);
    this.sortField = event.field ?? "rankedSeverity";
    this.sortOrder = event.order ?? 1;
    this.sortData();
  }

  onRowsPerPageChange(value: number) {
    this.rowsPerPage = value;
  }

  sortData() {
    this.data = [...this.data].sort((a, b) => {
      const order = this.sortOrder === 1 ? 1 : -1; // Ascending or descending
      if (a[this.sortField] < b[this.sortField]) return -1 * order;
      if (a[this.sortField] > b[this.sortField]) return 1 * order;
      return 0;
    });
  }

  onKeyTermsAdd(event: any) {

    if (this.keyTerms.length > 1) {
      this.keyTerms.pop();
    }
  }

  onKeyTermsRemove(event: any) {
  }

  toggleCollapse() {
    this.summaryOpen = !this.summaryOpen;
  }

  onValueChange() {
    this.calculateTotals();
  }

  enhancedSynopsis(rowData: any) {

    switch (rowData.toolName) {
      case "Nessus":
        if (rowData.instances > 1) {
          return rowData.name + " on " + rowData.instances + " assets";
        } else {
          return this.shortenSynopsis(rowData.synopsis);
        }
      case "Snyk":
        if (rowData.package) {
          return rowData.synopsis + " in " + rowData.package + " (v" + rowData.version + ")";
        } else {
          return this.shortenSynopsis(rowData.synopsis);
        }
      case "Sonarqube (Enterprise)":
        if (rowData.instances > 1) {
          return rowData.synopsis + " in " + rowData.instances + " projects";
        } else {
          return this.shortenSynopsis(rowData.synopsis);
        }
      case "Tenable WAS":
        return this.shortenSynopsis(rowData.description);
      default:
        return this.shortenSynopsis(rowData.synopsis);
    }
  }

  shortenSynopsis(synopsis: string) {

    if (synopsis.length > 200) {
      return synopsis.substring(0, 200) + '...';
    } else {
      return synopsis;
    }

  }

  shortenToolName(toolName: string) {

    if (toolName.length > 15) {
      return toolName.substring(0, 15) + '...';
    } else {
      return toolName;
    }

  }

  buildCSV() {

    const csvData = [];
    const csvHeader = ['id', 'vulnerabilityType', 'productType', 'productId', 'productName', 'productCode', 'suiteId', 'divisionId', 'activeStatus', 'toolName', 'practiceNae', 'createdAt', 'age', 'severity', 'instances', 'baseVulnerabilities', 'synopsis', 'tenable.cvssBaseScore'];
    const csvSharePointHeader = ['pentest.type', 'pentest.progress', 'pentest.testId', 'pentest.title', 'pentest.remediation'];
    const csvNessusHeader = ['nessus.assetId', 'nessus.assetTag', 'nessus.hostname', 'tenable.pluginId', 'tenable.pluginName', 'nessus.state', 'tenable.description', 'tenable.solution'];
    const csvWasHeader = ['was.scanName', 'was.scanId', 'was.configId', 'was.scanType', 'was.scanTarget', 'was.scanDate'];
    const csvSnykHeader = ['snyk.key', 'snyk.slug', 'snyk.org', 'snyk.package', 'snyk.version', 'snyk.score', 'snyk.isFixable'];
    const csvSonarHeader = ['sonar.effort', 'sonar.debt', 'sonar.rule', 'sonar.ruleName','sonar.language'];

    csvHeader.push(...csvSharePointHeader);
    csvHeader.push(...csvNessusHeader);
    csvHeader.push(...csvWasHeader);
    csvHeader.push(...csvSnykHeader);
    csvHeader.push(...csvSonarHeader);
    csvData.push(csvHeader);

    this.data.forEach((item: any) => {
      const csvRow = [];
      csvRow.push(item.id);
      csvRow.push(item.vulnerabilityType);
      csvRow.push(item.lensProductTypeName);
      csvRow.push(item.lensObjectId ?? "");
      csvRow.push(item.lensObjectName ?? "");
      csvRow.push(item.lensObjectCode ?? "");
      csvRow.push(item.lensSuiteId ?? "");
      csvRow.push(item.lensDivisionId ?? "");
      csvRow.push(item.lensActiveStatus ?? "");
      csvRow.push(item.toolName ?? "");
      csvRow.push(item.practiceName ?? "");
      csvRow.push(item.createdAt ?? "");
      csvRow.push(item.age ?? "");
      csvRow.push(item.severity ?? "");
      csvRow.push(item.instances ?? "");
      csvRow.push(this.escapeCsvValue(item.baseVulnerabilities) ?? "");
      csvRow.push(this.escapeCsvValue(item.synopsis) ?? "");
      csvRow.push(item.cvssBaseScore ?? "");
      csvRow.push(item.type ?? "");
      csvRow.push(item.progress ?? "");
      csvRow.push(item.testId ?? "");
      csvRow.push(this.escapeCsvValue(item.title) ?? "");
      csvRow.push(this.escapeCsvValue(item.remediation) ?? "");
      csvRow.push(item.assetId ?? "");
      csvRow.push(this.escapeCsvValue(item.assetTag) ?? "");
      csvRow.push(this.escapeCsvValue(item.hostname) ?? "");
      csvRow.push(item.pluginId ?? "");
      csvRow.push(this.escapeCsvValue(item.name) ?? "");
      csvRow.push(item.state ?? "");
      csvRow.push(this.escapeCsvValue(item.description) ?? "");
      csvRow.push(this.escapeCsvValue(item.solution) ?? "");
      csvRow.push(this.escapeCsvValue(item.scanName) ?? "");
      csvRow.push(item.scanId ?? "");
      csvRow.push(item.configId ?? "");
      csvRow.push(item.scanType ?? "");
      csvRow.push(this.escapeCsvValue(item.scanTarget) ?? "");
      csvRow.push(item.scanDate ?? "");
      csvRow.push(this.escapeCsvValue(item.key) ?? "");
      csvRow.push(this.escapeCsvValue(item.slug) ?? "");
      csvRow.push(item.org ?? "");
      csvRow.push(this.escapeCsvValue(item.package) ?? "");
      csvRow.push(this.escapeCsvValue(item.version) ?? "");
      csvRow.push(item.score ?? "");
      csvRow.push(item.isFixable ?? "");
      csvRow.push(item.effort ?? "");
      csvRow.push(item.debt ?? "");
      csvRow.push(this.escapeCsvValue(item.rule) ?? "");
      csvRow.push(this.escapeCsvValue(item.ruleDetails.name) ?? "");
      csvRow.push(this.escapeCsvValue(item.ruleDetails.langName) ?? "");


      csvData.push(csvRow);
    });

    let csvContent = 'data:text/csv;charset=utf-8,';
    csvData.forEach((row) => {
      csvContent += row.join(',') + '\r\n';
    });

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'vulnerabilities.csv');
    document.body.appendChild(link);
    link.click();

  }

  escapeCsvValue(value: string): string {

    if (value == null) {
      return '';
    }

    // Convert to string
    value = String(value);

    // Replace newline and carriage return with a space
    value = value.replace(/[\r\n]+/g, ' ');

    // Escape double quotes
    value = value.replace(/"/g, '""');

    //Escape #, as it is a special character in CSV
    value = value.replace(/#/g, '');

    // Trim whitespace
    value = value.trim();

    // Enclose in quotes if it contains special characters
    if (/[,"[\r\n]/.test(value)) {
      value = `"${value}"`;
    }

    return value;
  }


}
