import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, ViewChild } from '@angular/core';
import { ApprovedSoftwareService } from 'src/app/layout/service/approved-software/approved-software.service';
import { ProductSoftwareDto, SoftwareAndVersionsDto, ProductSoftwareVersionDto, NewProductSoftwareVersionDto, ProductSoftwareVersionLogDto, NewProductSoftwareVersionLogDto } from 'src/app/api/approved-software';
import { MessageService } from 'primeng/api';
import { Table } from 'primeng/table';
import { MsalService } from '@azure/msal-angular';
import * as FileSaver from 'file-saver';
import { finalize } from 'rxjs';

@Component({
  selector: 'app-versions-tab',
  templateUrl: './versions-tab.component.html',
  styleUrls: ['./versions-tab.component.scss']
})
export class VersionsTabComponent implements OnInit {
  @Input() items: ProductSoftwareDto[] = [];
  @Output() versionsUpdated = new EventEmitter<{ itemId: number, selectedVersions: string[] }>();

  versions: { [key: number]: { id: number, name: string, versions: { id: number, version: string }[] } } = {};
  originalVersions: { [key: number]: { id: number, version: string, productSoftwareVersionId: number, supportingUrl: string }[] } = {}; 
  editedVersions: { [key: number]: { id: number, version: string, productSoftwareVersionId: number, supportingUrl: string }[] } = {}; 

  newlyAddedVersions: { [key: number]: { id: number, version: string }[] } = {};

  // Metrics
  totalSoftware: number = 0;
  totalVersions: number = 0;
  totalVersionsNotTagged: number = 0;

  showOnlyUntagged: boolean = false;

  // Modal and comment properties
  displayCommentModal: boolean = false;
  comment: string = '';
  userGraphId: string = '';

  versionToRemove: {
    productId: number;
    softwareId: number;
    versionId: number;
    softwareName: string; // Add software name
    version: string; // Add version
  } | null = null;

  // Queue for versions to be removed
  removalQueue: { productId: number, softwareId: number, versionId: number, softwareName: string, version: string }[] = [];

  isLoading: boolean = true;

  constructor(private approvedSoftwareService: ApprovedSoftwareService, private messageService: MessageService, private msalService: MsalService) {}

  ngOnInit(): void {
    this.getUserGraphId();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['items'] && changes['items'].currentValue) {
      this.items = this.items.filter(item => item.softwareDetails.trackVersions);
      this.fetchVersions();
    }
  }

  
  fetchVersions() {
    // Fetch versions for each unique item
    this.items.forEach(item => {
      const softwareId = item.softwareDetails.id;
  
      // Fetch software versions
      this.approvedSoftwareService.getSoftwareVersions(softwareId).subscribe({
        next: (softwareVersions) => {
          const sortedVersions = softwareVersions.versions
            .map((v: any) => ({
              id: v.id,
              version: v.version,
              supportingUrl: v.supportingUrl,
            }))
            .sort((a: any, b: any) => this.compareVersions(b.version, a.version)); // Sort descending
  
          this.versions[softwareId] = {
            id: softwareId,
            name: item.softwareDetails.name,
            versions: sortedVersions,
          };
  
          // Fetch product software version links
          this.approvedSoftwareService.getProductSoftwareVersionLinks(item.productId, softwareId).subscribe({
            next: (selectedVersions) => {
              this.originalVersions[softwareId] = selectedVersions
                .map((v: any) => {
                  const versionDetails = softwareVersions.versions.find((version: any) => version.id === v.softwareVersionId);
                  return {
                    id: v.softwareVersionId,
                    version: v.versionLabel,
                    productSoftwareVersionId: v.productSoftwareVersionId,
                    supportingUrl: versionDetails?.supportingUrl || '',
                  };
                })
                .sort((a: any, b: any) => this.compareVersions(b.version, a.version)); // Sort descending
  
              this.editedVersions[softwareId] = [...this.originalVersions[softwareId]];
  
              // Check if all items have been processed
              if (this.items.every(i => this.versions[i.softwareDetails.id] && this.originalVersions[i.softwareDetails.id])) {
                this.isLoading = false;
                this.calculateMetrics();
              }
            },
            error: () => {
              // Suppress errors (including 404) without logging anything
              this.versions[softwareId] = { id: softwareId, name: item.softwareDetails.name, versions: [] };
              this.originalVersions[softwareId] = [];
              this.editedVersions[softwareId] = [];
  
              // Check if all items have been processed
              if (this.items.every(i => this.versions[i.softwareDetails.id] && this.originalVersions[i.softwareDetails.id])) {
                this.isLoading = false;
                this.calculateMetrics();
              }
            }
          });
        },
        error: (error) => {
          // Handle errors for getSoftwareVersions (unchanged)
          console.error('Error fetching software versions:', error);
  
          this.versions[softwareId] = { id: softwareId, name: item.softwareDetails.name, versions: [] };
          this.originalVersions[softwareId] = [];
          this.editedVersions[softwareId] = [];
  
          // Check if all items have been processed
          if (this.items.every(i => this.versions[i.softwareDetails.id] && this.originalVersions[i.softwareDetails.id])) {
            this.isLoading = false;
            this.calculateMetrics();
          }
        }
      });
    });
  }
  

  compareVersions(versionA: string, versionB: string): number {
    const partsA = versionA.split('.').map(Number);
    const partsB = versionB.split('.').map(Number);
  
    for (let i = 0; i < Math.max(partsA.length, partsB.length); i++) {
      const partA = partsA[i] || 0;
      const partB = partsB[i] || 0;
  
      if (partA < partB) return -1;
      if (partA > partB) return 1;
    }
  
    return 0;
  }


  calculateMetrics() {
    // Total Software
    this.totalSoftware = this.items.length;

    // Total Versions
    this.totalVersions = Object.values(this.originalVersions).reduce((acc, versions) => acc + versions.length, 0);

    // Total Versions Not Tagged
    this.totalVersionsNotTagged = this.items.filter(item => {
      const softwareId = item.softwareDetails.id;
      return (this.originalVersions[softwareId] ?? []).length === 0;
    }).length;

  }

  handleVersionChange(item: ProductSoftwareDto, event: any) {
    const softwareId = item.softwareDetails.id;
    this.editedVersions[softwareId] = [...event.value];
  }

  saveChanges(item: ProductSoftwareDto) {
    const softwareId = item.softwareDetails.id;
    const selectedVersions = this.editedVersions[softwareId];
    const previousVersions = this.originalVersions[softwareId] || [];
  
    // Find versions that were added (present in selectedVersions but not in previousVersions)
    const addedVersions = selectedVersions.filter((version) => 
      !previousVersions.some((prevVersion) => prevVersion.id === version.id)
    );
  
    // Find versions that were removed (present in previousVersions but not in selectedVersions)
    const removedVersions = previousVersions.filter((version) => 
      !selectedVersions.some((selectedVersion) => selectedVersion.id === version.id)
    );
  
    // Create product version links for added versions
    addedVersions.forEach((version) => {
      const versionId = version.id; // Use id directly
      this.approvedSoftwareService.createProductSoftwareVersionLink(item.productId, softwareId, { SoftwareVersionId: versionId })
        .subscribe(
          (response: any) => {
            // Assign the response productSoftwareVersionId to the version
            version.productSoftwareVersionId = response.productSoftwareVersionId;
  
            // Find and update the version in originalVersions
            const originalVersionList = this.originalVersions[softwareId] || [];
            const versionIndex = originalVersionList.findIndex(v => v.id === version.id);
            if (versionIndex !== -1) {
              originalVersionList[versionIndex].productSoftwareVersionId = response.productSoftwareVersionId;
            } else {
              // If the version is new, push it into the original list
              originalVersionList.push(version);
            }
            this.originalVersions[softwareId] = [...originalVersionList];
  
            this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Version added successfully' });
            this.calculateMetrics();
          },
          (error) => {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error adding version' });
          }
        );
    });
  
    // Handle the removal of versions from the originalVersions array
    removedVersions.forEach((version) => {
      // Add removed versions to the removal queue
      this.removalQueue.push({
        productId: item.productId,
        softwareId: softwareId,
        versionId: version.productSoftwareVersionId,
        softwareName: item.softwareDetails.name, // Add software name
        version: version.version // Add version
      });
      this.calculateMetrics();
    });
  
    // Process the removal queue
    this.processRemovalQueue();
  
    // Set isEditing to false
    item.isEditing = false;
  }
  

  processRemovalQueue() {
    if (this.removalQueue.length > 0 && !this.displayCommentModal) {
      this.versionToRemove = this.removalQueue.shift()!; // Get the first item in the queue
      this.displayCommentModal = true; // Show the comment modal
    }
  }

  onCommentModalSubmit() {
    if (this.versionToRemove && this.comment) {
      const logEntry: NewProductSoftwareVersionLogDto = {
        UserId: this.userGraphId, // Replace with actual user ID
        ProductId: this.versionToRemove.productId,
        SoftwareVersionId: this.versionToRemove.versionId,
        SoftwareId: this.versionToRemove.softwareId,
        Notes: this.comment
      };

      // Create the log entry (independent of deletion)
      this.approvedSoftwareService.createProductSoftwareVersionLog(
        this.versionToRemove.productId,
        this.versionToRemove.softwareId,
        logEntry
      ).subscribe(
        (response) => {
          this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Version log created successfully' });
        },
        (error) => {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error creating version log' });
        }
      );
  
      // Delete the version link (independent of log creation)
      this.approvedSoftwareService.deleteProductSoftwareVersionLink(this.versionToRemove.versionId).subscribe(
        (response) => {
          this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Version removed successfully' });
  
          // Remove the version from the array only after successful deletion
          const softwareId = this.versionToRemove?.softwareId;
          const versionId = this.versionToRemove?.versionId;
  
          // Remove the version from the originalVersions array
          if (softwareId !== undefined) {
            this.originalVersions[softwareId] = this.originalVersions[softwareId].filter(v => v.productSoftwareVersionId !== versionId);
          }
          // Reset the modal state
          this.displayCommentModal = false;
          this.comment = '';
          this.versionToRemove = null;
  
          // Process the next item in the removal queue
          this.calculateMetrics();
          this.processRemovalQueue();
        },
        (error) => {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error removing version' });
  
          // Reset the modal state even if deletion fails
          this.displayCommentModal = false;
          this.comment = '';
          this.versionToRemove = null;
  
          // Process the next item in the removal queue
          this.processRemovalQueue();
        }
      );
    }
  }

  onCommentModalCancel() {
    this.displayCommentModal = false;
    this.comment = '';
    this.versionToRemove = null;
    this.processRemovalQueue(); // Process the next item in the queue
  }

  cancelChanges(item: ProductSoftwareDto) {
    const softwareId = item.softwareDetails.id;
    this.editedVersions[softwareId] = [...this.originalVersions[softwareId]];

    item.isEditing = false;
  }

  @ViewChild('table', { static: false }) table!: Table;

  exportExcel() {
    import("xlsx").then(xlsx => {
      const filteredData = this.table.filteredValue ? this.table.filteredValue : this.table.value;
      
      // Convert to JSON (removes any internal references)
      const jsonData = filteredData.map(item => {
        const versions = this.originalVersions[item.softwareDetails?.id] || [];
        
        return {
          Name: item.softwareDetails?.name || '',
          Vendor: item.softwareDetails?.vendorName || '',
          Versions: versions.map((v: any) => v.version).join(', ') || '', // Join versions as a comma-separated string
        };
      });

      const worksheet = xlsx.utils.json_to_sheet(jsonData);
      const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });

      this.saveAsExcelFile(excelBuffer, "SoftwareVersions");
    });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });

    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }

  getFilteredItems(): ProductSoftwareDto[] {
    if (this.showOnlyUntagged) {
      return this.items.filter(item => {
        const softwareId = item.softwareDetails.id;
        return (this.originalVersions[softwareId] ?? []).length === 0;
      });
    } else {
      return this.items; // Return all items if no filter is applied
    }
  }

  filterVersionsNotTagged() {
    this.showOnlyUntagged = !this.showOnlyUntagged; // Toggle the filter state
  }

  getUserGraphId() {
    const account = this.msalService.instance.getAllAccounts()[0];
    if (account && account.idTokenClaims) {
      this.userGraphId = account.idTokenClaims['oid'] || '';
    } else {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Failed to retrieve user information.',
      });
    }
  }

  openSupportingUrl(url: any) {
    if (url) {
      window.open(url, '_blank'); // Opens the URL in a new tab
    } else {
      this.messageService.add({
        severity: 'warn',
        summary: 'No URL Available',
        detail: 'No supporting URL is available for this version.',
      });
    }
  }

  handleLogoError(event: Event): void {
    const imgElement = event.target as HTMLImageElement;
  
    // Replace with a placeholder image
    imgElement.src = 'assets/layout/images/iris-logo.png';
  }

  getUniqueTechStackGroups(categories: any[]): string[] {
    const uniqueGroups = new Set<string>();

    categories.forEach(category => {
      if (category.techStackGroups && category.techStackGroups.length > 0) {
        category.techStackGroups.forEach((group: any) => {
          uniqueGroups.add(group.name); // Add group name to the Set
        });
      }
    });
  
    return Array.from(uniqueGroups); // Convert Set back to an array
  }
}