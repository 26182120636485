import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { AppConfig } from '../appconfig';

@Injectable()
export class ConfigService {
  config: AppConfig = {
    theme: 'lara-light-blue',
    dark: false,
    inputStyle: 'outlined',
    ripple: true,
  };

  private configUpdate = new Subject<AppConfig>();
  private configButtonClickSource = new Subject<void>();
  configButtonClick$ = this.configButtonClickSource.asObservable();

  configUpdate$ = this.configUpdate.asObservable();

  updateConfig(config: AppConfig) {
    this.config = config;
    this.configUpdate.next(config);
  }

  getConfig() {
    return this.config;
  }

  notifyConfigButtonClick() {
    this.configButtonClickSource.next();
  }
}
