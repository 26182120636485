<div class="search-container">
  <div class="search-input-wrapper">
    <input #searchInput type="text"
           (input)="search($event)"
           (focusout)="hideResults()"
           (keydown)="handleKeyDown($event)"
           placeholder="Search IRIS Lens..."
           id="search-input"
           class="search-input" />
    <i class="material-icons search-icon">search</i>
  </div>
  <div class="search-results-wrapper" [class.active]="isActive" *ngIf="searchResults$ | async as results">
    <div class="search-results">
      <div *ngFor="let result of results; let i = index"
           [class.selected]="i === selectedIndex"
           class="search-result"
           (click)="onSelect(result)">
        <span class="material-icons result-icon">{{ getIconClass(result.type) }}</span>
        <div class="result-info">
          <span class="result-name">{{ result.name }}</span>
          <div class="result-row">
            <span class="result-type">{{ getTypeLabel(result.type) }}</span>
            <span class="result-ref">{{ result.ref }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="search-results-wrapper" [class.active]="isEmpty" *ngIf="isEmpty">
    <div class="search-results empty-search">
      <div class="search-result">
        <span class="material-icons result-icon">search_off</span>
        <div class="result-info">
          <span class="result-name">No results found</span>
        </div>
      </div>
    </div>
  </div>
