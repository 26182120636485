import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ToolExternalService } from 'src/app/layout/service/tool-external.service';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { WASI } from 'wasi';
import { Router } from '@angular/router';

@Component({
  selector: 'was-issues-widget',
  templateUrl: './was-issues-widget.component.html'
})
export class WasIssuesWidgetComponent implements OnInit, OnChanges {

  @Input() data: any;
  wasPieData: any;
  pieOptions: any;
  wasReportingLevel: string = '';
  wasIssuesLoading: boolean = true;
  snykLicenceLink: string = '';
  wasIssuesAvailable: boolean = false;
  isCollapsed: boolean = false;

  hasTargets: boolean = false;
  scans: any[] = [];
  components: string[] = [];
  selectedItem: any;
  selectedTarget: string = '';
  scanDate: string = '';
  scanUrl: string = '';

  isInitialized: boolean = false;
  widgetContext: string = '';

  routingProductType: string = "";
  routingObjectId: string = "";

  constructor(private toolExternalService: ToolExternalService, private router: Router) { }

  ngOnInit(): void {
    this.widgetContext = this.data.objectId;
    this.initialiseWidgetOnce();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data']) {
      if (this.widgetContext !== this.data.objectId) {
        this.isInitialized = false;
        this.widgetContext = this.data.objectId;
      }
      this.resetWidget();
      this.initialiseWidgetOnce();
    }
  }

  private initialiseWidgetOnce(): void {
    if (!this.isInitialized) {
      this.initialiseWidget();
      this.isInitialized = true;
    }
  }

  initialiseWidget() {
    this.resetWidget();
    this.pieOptions = {
      plugins: {
        legend: {
          display: false
        }
      }
    };

    //convert this.productId to a number
    const prodNumber = parseInt(this.data.objectId);
    const productType = this.data?.productType ?? null;
    const currentUrl = this.router.url;

    this.toolExternalService.getWasIssues(prodNumber, productType).pipe(
      catchError((error) => {
        if (error.status === 404) {
          if (productType == null){
            this.wasReportingLevel = `No Tenable WAS data available`;
          } else {
            this.wasReportingLevel = `No Tenable WAS data available for this ${productType}`;
          }          
        } else {
          this.wasReportingLevel = "Unexpected error occured getting Tenable WAS data for this product";
          console.error('An error occurred:', error);
        }
        this.wasIssuesLoading = false;
        return of(null);
      })
    ).subscribe((wasIssuesData) => {
      if (!wasIssuesData) {
        this.wasIssuesLoading = false;
        this.wasReportingLevel = `No Tenable WAS data available`;
        return;
      } else {
        const pt = wasIssuesData?.productType ?? "";
        if (pt === 'Product') {
          this.wasReportingLevel = "";
          this.routingProductType = "Product";
          this.routingObjectId = wasIssuesData.objectId;

        } else if (pt === 'Suite' && currentUrl.includes('/suite/')) {
          this.wasReportingLevel = "Tenable WAS data not available for this suite";
          this.wasIssuesLoading = false;
          return;
        } else {
          this.wasReportingLevel = "Tenable WAS data not available at this product level. Showing data for parent suite: " + wasIssuesData.objectName;
        }

        if (pt === 'Suite') {
          this.routingProductType = "Suite";
          this.routingObjectId = wasIssuesData.objectId;
        }

        this.scans = wasIssuesData.data;
        this.components = [];
        for (const item of wasIssuesData.data) {

          this.components.push(item.latestScan.target);
        }
        this.hasTargets = this.components.length > 0;

        this.selectedItem = wasIssuesData.data[0];
        this.selectedTarget = this.selectedItem.latestScan.target;
        this.populateData();

        this.wasIssuesLoading = false;
      }
    });
  }

  getScanType(name: string): string {

    if (name.endsWith("Basic")) {
      return "Basic Unauthenticated";
    }
    if (name.endsWith("Full")) {
      return "Full Authenticated";
    }
    return "Unknown";   
  }

  getQueryParams(sev: string) {
    const params = {
      toolName: 'Tenable WAS',
      keyTerms: this.selectedTarget,
      suites: '',
      products: '',
      severities: this.getRankedSeverity(sev)

    };

    if (this.routingProductType === 'Suite') {
      params['suites'] = this.routingObjectId;
    } else {
      params['products'] = this.routingObjectId;
    }

    return params;
  }

  getRankedSeverity(sev: string): number {

    if (sev === 'Critical') {
      return 0;
    } else if (sev === 'High') {
      return 1;
    } else if (sev === 'Medium') {
      return 2;
    } else {
      return 3;
    }
  }


  toggleWidgetCollapse() {
    this.isCollapsed = !this.isCollapsed;
  }

  getSeverityClass(label: string, baseClass: string): string {
    //remove spaces from Label
    label = label.replace(/\s/g, '');
    //Make label lower case
    label = label.toLowerCase();
    const severityClass = 'pieWidget' + label;
    return `${baseClass} ${severityClass}`;
  }

  onDropdownChange(event: any) {
    const selectedValue = event.value;
    this.selectedTarget = selectedValue;

    for (const item of this.scans) {
      if (item.latestScan.target === selectedValue) {
        this.selectedItem = item;
        break;
      }
    }

    this.populateData();
  }

  populateData() {

    // Loop through the array in wasIssuesData.latestScans

      const date = new Date(this.selectedItem.latestScan.finalized_at);
      const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: 'long',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        timeZoneName: 'short'
      };
    this.scanDate = date.toLocaleDateString(undefined, options);

    this.scanUrl = "https://cloud.tenable.com/webapp#/scans/my-scans/scan-details/" + this.selectedItem.latestScan.scanId + "/findings/history/" + this.selectedItem.latestScan.scanId;

    const wasCritical = this.selectedItem.vulnerabilities.critical;
    const wasHigh = this.selectedItem.vulnerabilities.high;
    const wasMedium = this.selectedItem.vulnerabilities.medium;
    const wasLow = this.selectedItem.vulnerabilities.low;
    const documentStyle = getComputedStyle(document.documentElement);

    this.wasPieData = {
      labels: ['Critical', 'High', 'Medium', 'Low'],
      datasets: [
        {
          data: [wasCritical, wasHigh, wasMedium, wasLow],
          backgroundColor: [
            documentStyle.getPropertyValue('--critical'),
            documentStyle.getPropertyValue('--high'),
            documentStyle.getPropertyValue('--medium'),
            documentStyle.getPropertyValue('--low'),
          ],
          hoverBackgroundColor: [
            documentStyle.getPropertyValue('--critical-hover'),
            documentStyle.getPropertyValue('--high-hover'),
            documentStyle.getPropertyValue('--medium-hover'),
            documentStyle.getPropertyValue('--low-hover'),
          ],
        },
      ],
    };

    if (wasCritical !== 0 || wasHigh !== 0 || wasMedium !== 0 || wasLow !== 0) {
      this.wasIssuesAvailable = true;
      this.wasReportingLevel = "";
    } else {
      this.wasReportingLevel = "Good news! There are currently no WAS issues";
      this.wasIssuesAvailable = true;
    }

    this.pieOptions = {
      plugins: {
        tooltip: {
          enabled: true
        }
      },
      onHover: (event: any, chartElement: any) => {
        event.native.target.style.cursor = chartElement[0] ? 'pointer' : 'default';
      },
      onClick: (event: MouseEvent, elements: any[]) => {
        if (elements.length > 0) {
          // Get the first clicked element
          const element = elements[0];
          const dataIndex = element.index;

          // Generate the URL based on the severity level and issue type
          const url = this.generateIssueLink(dataIndex);

          // Open the link in a new tab
          window.open(url, '_blank');

        }
      }
    };

  }

  private generateIssueLink(severity: number): string {
    // create a url from the root of this site with /vulnerabilities/search
    // and a query string of severity and issueType

    if (this.routingProductType == "Suite") {
      return window.location.origin + `/vulnerabilities/search?severities=${severity}&suites=${this.routingObjectId}&toolName=Tenable%20WAS&keyTerms=${encodeURIComponent(this.selectedTarget)}`;
    } else {
      return window.location.origin + `/vulnerabilities/search?severities=${severity}&products=${this.routingObjectId}&toolName=Tenable%20WAS&keyTerms=${encodeURIComponent(this.selectedTarget)}`;
    }
  }

  updateComponentDropdown(newComponent: string) {
    if (!this.components.includes(newComponent)) {
      this.components.push(newComponent);
    }
  }

  resetWidget() {
    this.wasPieData = null;
    this.wasReportingLevel = '';
    this.wasIssuesLoading = true;
    this.wasIssuesAvailable = false;
    this.isCollapsed = false;
    this.selectedTarget = '';
    this.selectedItem = null;
    this.scanDate = '';
    this.scanUrl = '';
    this.hasTargets = false;
    this.scans = [];
    this.components = [];
  }
}
