import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Table } from 'primeng/table';
import { Product, ProductRequestDto, Suite } from 'src/app/api/product';
import { ProductService } from 'src/app/layout/service/product/products.service';
import * as FileSaver from 'file-saver';
import { ProductSelectionService } from 'src/app/product.selection.service';
import { FilterService, MessageService } from 'primeng/api';
import { ProductTechSpec } from 'src/app/api/product-tech-spec';
import { CookieService } from 'ngx-cookie-service';
import { FavouritesService } from 'src/app/layout/service/favourite.service';
import { name } from '@azure/msal-angular/packageMetadata';

@Component({
  selector: 'app-product-master-list',
  templateUrl: './product-master-list.component.html',
  // styleUrls: ['./product-master-list.component.css']
})

export class ProductMasterListComponent implements OnInit {

  @ViewChild('dt1') table!: Table;

  products: Product[] = [];
  favourites: any[] = [];

  product: ProductRequestDto = {};

  suites: any[] = [];
  suites2: any = {};
  divisions: any[] = [];
  productTypes: any[] = [];

  count: number = 0;
  cols: any[] = [];
  _selectedColumns: any[] = [];

  submitted: boolean = false;
  productDialog: boolean = false;  

  techSpecDistributions!: TechSpecWithDistributions[];

  constructor(private productsService: ProductService, private productSelectionService: ProductSelectionService, 
    private messageService: MessageService, private filterService: FilterService, private favouritesService: FavouritesService) { }

  ngOnInit(): void {
    this.cols = [
      { field: 'Id', header: 'ID', minWidth: 'min-width: 6rem', type: 'text'},
      { field: 'Name', header: 'Name', minWidth: 'min-width: 12rem', type: 'text' },
      { field: 'ProductRef', header: 'ProductRef', minWidth: 'min-width: 12rem', type: 'text' },
      { field: 'SuiteName', header: 'Suite', minWidth: 'min-width: 12rem', type: 'text' },
      { field: 'DivisionName', header: 'Division',minWidth: 'min-width: 12rem', type: 'text' },
      { field: 'ProductTypeName', header: 'Product Type', minWidth: 'min-width: 12rem', type: 'text' },
      { field: 'OriginCompany', header: 'Origin Company', minWidth: 'min-width: 12rem', type: 'text' },
      { field: 'ActiveStatus', header: 'Active Status', minWidth: 'min-width: 12rem', type: 'boolean' },
      { field: 'HomepageURI', header: 'Homepage URI', minWidth: 'min-width: 12rem', type: 'text' },
      { field: 'EoLDate', header: 'EoL Date', minWidth: 'min-width: 12rem', type: 'date' },
      { field: 'ProductMetric?.TierName', header: 'Tier', minWidth: 'min-width: 6rem', type: 'text' },
      { field: 'ProductTechSpec?.TechSpecDistributions', header: 'Distributions', minWidth: 'min-width: 6rem', type: 'text' }
    ];
    this._selectedColumns = this.cols;

    this.productsService.getAllProductsMasterList().subscribe((products) => {
      this.products = products.map(x => ({
        ...x,
        distributions: x.ProductTechSpec?.TechSpecDistributions?.map(y => y.DistributionName)
      }));
      this.loadFavourites();
    },
      (error) => {
      }
    );

    this.productsService.getAllSuites().subscribe((suites) => {
      this.suites = suites.map(suite => ({Id: suite.Id, Name: suite.Name}));
    });

    this.productsService.getAllDivisions().subscribe((divisions) => {
      this.divisions = divisions;
    });

    this.productsService.getAllProductTypes().subscribe((productTypes) => {
      this.productTypes = productTypes;
    });

    // filter for distributions
    this.filterService.register('distributions-contains', (value: any, filter: any): boolean => {
      if (filter === undefined || filter === null || filter.trim() === '') {
        return true;
      }
      if (value === undefined || value === null) {
        return false;
      }

      return this.filterTechSpecDistributions(value, filter).length > 0;
    });
  }

  // component.ts

  getDistributionNames(product: Product): string {
    if (product && product.ProductTechSpec && product.ProductTechSpec.TechSpecDistributions) {
      return product.ProductTechSpec.TechSpecDistributions.map((d: any) => d.DistributionName).join(', ');
    }
    return '';
  }

  filterTechSpecDistributions(value: any, filter: any): any[] {
    let distributionNames = value.map((x: any) => x.DistributionName);

    return distributionNames.filter((x: any) => x.includes(filter));
  }


  openNew() {
    this.product = {};
    this.submitted = false;
    this.productDialog = true;
  }

  hideDialog() {
    this.productDialog = false;
    this.submitted = false;
  }

  onProductSelected(product: Product): void {
    this.productSelectionService.setSelectedProduct(product || []);
  }

  trimOption(option: string): string {
    return option ? option.trim() : '';
  }

  onGlobalFilter(table: Table, event: Event) {
    table.filterGlobal((event.target as HTMLInputElement).value, 'contains');
  }

  clear(table: Table) {
    table.clear();
    this.table.filterGlobal('', 'contains');
  }

  @Input() get selectedColumns(): any[] {
    return this._selectedColumns;
  }

  set selectedColumns(val: any[]) {
      //restore original order
      this._selectedColumns = this.cols.filter(col => val.includes(col));
  }

  isColumnSelected(field: string): boolean {
    return this._selectedColumns.some(col => col.field === field);
  }

  getSuiteNameById(suiteId: string): string {
    const selectedSuite = this.suites.find(suite => suite.Id === suiteId);
    return selectedSuite ? selectedSuite.Name : '';
  }

  getDivisionNameById(divisionId: string): string {
    const selectedDivisions = this.divisions.find(division => division.Id === divisionId);
    return selectedDivisions ? selectedDivisions.Name : '';
  }

  getProductTypeNameById(productTypeId: string): string {
    const selectedProductTypes = this.productTypes.find(type => type.Id === productTypeId);
    return selectedProductTypes ? selectedProductTypes.Name : '';
  }

  saveProduct() {
    this.submitted = true;

    if (this.product.Name) {
      this.productsService.createProduct(this.product).subscribe((product) => {
        this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Product Created', life: 3000 });

        this.productDialog = false;
        this.product = {};

        this.productsService.getAllProducts().subscribe((products) => {
          this.products = products;
        });
      });
    }
  }

  exportExcel() {
    import("xlsx").then(xlsx => {
      // Apply the filters to the data
      const filteredData = this.table.filteredValue ? this.table.filteredValue : this.table.value;
  
      const selectedColumnsData = filteredData.map(product => {
        const selectedProduct: any = {};
        this._selectedColumns.forEach(col => {
          const fieldParts = col.field.split('?.'); // Split the field by '?.'
          let value = product;
          for (let part of fieldParts) {
            if (value) {
              value = value[part]; // Navigate through the nested properties
            } else {
              break;
            }
          }
          if (Array.isArray(value)) {
            // Handle array of objects (for TechSpecDistributions case)
            if (col.field === 'ProductTechSpec?.TechSpecDistributions') {
              value = value.map(d => d.DistributionName).join(', ');
            }
          }
          selectedProduct[col.field] = value;
        });
        return selectedProduct;
      });
  
      const worksheet = xlsx.utils.json_to_sheet(selectedColumnsData);
      const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, "products");
    });
  }
  

  saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }

  tagify(option: string | undefined): string {
    if (!option) {
      return '';
    }
    let tagValue = option.trim();
    tagValue = tagValue.replace(/\s+/g, '_');
    tagValue = tagValue.replace(/[^a-zA-Z0-9_]/g, '');
    return tagValue;
  }

  loadFavourites() {
    this.favourites = this.favouritesService.getProductFavourites();
  }

  isFavourite(product: any): boolean {
    return this.favouritesService.isFavourite(this.favourites, product.Id);
  }

  toggleFavourite(product: any) {
    this.favourites = this.favouritesService.toggleFavourite(this.favourites, product.Id);
    this.favouritesService.saveProductFavourites(this.favourites);
  }

  generateProductRef() {
    if (this.product.Name) {
      const productName = this.product.Name.trim();
      const nameParts = productName.split(' ');
      const maxChars = 8;
      let refPrefix = '';
  
      // Calculate the minimum characters needed from each word (1 from each word)
      let charsRemaining = maxChars - nameParts.length;
  
      // Array to hold how many characters to pick from each word, starting with 1 per word
      let charsPerWord = Array(nameParts.length).fill(1);
  
      // Distribute remaining characters randomly among words
      while (charsRemaining > 0) {
        const randomIndex = Math.floor(Math.random() * nameParts.length);
        charsPerWord[randomIndex]++;
        charsRemaining--;
      }
  
      // Generate the reference prefix based on charsPerWord
      nameParts.forEach((word, index) => {
        const upperWord = word.toUpperCase();
        let chars = [upperWord[0]]; // Always pick the first character
  
        // Calculate additional characters to pick for this word
        const charsToPick = charsPerWord[index] - 1;
        const remainingChars = upperWord.slice(1);
  
        // Randomly pick additional characters while maintaining order
        const orderedRandomIndexes = this.getOrderedRandomIndexes(remainingChars.length, charsToPick);
  
        orderedRandomIndexes.forEach(i => {
          chars.push(remainingChars[i]);
        });
  
        refPrefix += chars.join('');
      });
  
      // Limit the refPrefix to max allowed characters
      refPrefix = refPrefix.slice(0, maxChars);
  
      // Get the division code (first 3 characters of division name)
      const divisionName = this.getDivisionNameById(this.product.DivisionId || '');
      const divisionCode = divisionName ? divisionName.slice(0, 3).toUpperCase() : 'CEN';
  
      // Combine product reference and division code, ensuring a max length of 11 characters
      this.product.ProductRef = (refPrefix + divisionCode).slice(0, 11);
    }
  }
  
  // Helper function to get ordered random indexes from a word
  getOrderedRandomIndexes(length: number, count: number): number[] {
    const indexes = new Set<number>();
  
    while (indexes.size < count) {
      const index = Math.floor(Math.random() * length);
      indexes.add(index);
    }
  
    // Convert to array and sort to maintain order
    return Array.from(indexes).sort((a, b) => a - b);
  }
  
  
}


export type TechSpecWithDistributions = ProductTechSpec & {
  techSpecDistributions: string[] | null;
} 