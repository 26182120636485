import { Directive, ElementRef, Input, Renderer2, AfterViewInit } from '@angular/core';

@Directive({
  selector: '[appStyledTag]'
})
export class StyledTagDirective implements AfterViewInit {
  @Input() set appStyledTag(styleData: { colourCode?: string, logo?: string, name: string, backgroundColourCode?: string, borderColor?: string, badgeType?: string }) {
    if (styleData) {
      // Apply styles
      this.renderer.setStyle(this.el.nativeElement, 'background-color', styleData.backgroundColourCode || '#ffffff');
      this.renderer.setStyle(this.el.nativeElement, 'border', '1px solid ' + styleData.borderColor || 'gray');
      this.renderer.setStyle(this.el.nativeElement, 'color', styleData.colourCode || '#000000');

      this.renderer.setStyle(this.el.nativeElement, 'background-image', styleData.logo ? `url("${styleData.logo}")` : 'none');

      this.renderer.setStyle(this.el.nativeElement, 'background-repeat', 'no-repeat');
      this.renderer.setStyle(this.el.nativeElement, 'background-position', '8px center');
      this.renderer.setStyle(this.el.nativeElement, 'background-size', '16px 16px');
      this.renderer.setStyle(this.el.nativeElement, 'padding-left', styleData.logo ? '32px' : '0.5rem');

      // Add the base badge class
      this.renderer.addClass(this.el.nativeElement, styleData.badgeType || 'techstack-badge');

      this.name = styleData.name;
    }
  }

  private name: string = '';

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngAfterViewInit(): void {
    if (this.name) {
      // Remove the internal span if it exists
      const internalSpan = this.el.nativeElement.querySelector('.p-tag');
      if (internalSpan) {
        this.renderer.removeChild(this.el.nativeElement, internalSpan);
      }
  
      // Append the text directly to the p-tag element
      const text = this.renderer.createText(this.name);
      this.renderer.appendChild(this.el.nativeElement, text);
    }
  }
}