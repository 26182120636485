import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ToolExternalService } from 'src/app/layout/service/tool-external.service';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'snyk-licence-widget',
  templateUrl: './snyk-licence-widget.component.html'
})
export class SnykLicenceWidgetComponent implements OnInit, OnChanges {

  @Input() data: any;
  pieLicenceData: any;
  pieOptions: any;
  snykLicenceReportingLevel: string = '';
  snykLicenceConcernsloading: boolean = true;
  snykLicenceLink: string = '';
  snykLicenceConcernsAvailable: boolean = false;
  isCollapsed: boolean = false;

  components: string[] = [];
  hasMultipleComponents: boolean = false;
  sourceData: any[] = [];
  selectedComponent: any;
  selectedSlugName: string = '';

  isInitialized: boolean = false;
  widgetContext: string = '';
  sourceType: string = '';
  sourceObjectName: string = '';

  constructor(private toolExternalService: ToolExternalService, private router: Router) { }

  ngOnInit(): void {
    this.widgetContext = this.data.objectId;    
    this.initialiseWidgetOnce();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data']) {
      if (this.widgetContext !== this.data.objectId) {
        this.isInitialized = false;
        this.widgetContext = this.data.objectId;   
      }
      this.resetWidget();
      this.initialiseWidgetOnce();
    }
  }

  private initialiseWidgetOnce(): void {
    if (!this.isInitialized) {
      this.initialiseWidget();
      this.isInitialized = true;
    }
  }

  initialiseWidget() {
    this.resetWidget();

    this.pieOptions = {
      plugins: {
        legend: {
          display: false
        }
      }
    };

      //convert this.productId to a number
      const prodNumber = parseInt(this.data.objectId);
      const productType = this.data?.productType ?? null;

      this.toolExternalService.getSnykLicenceData(prodNumber, productType).pipe(
        catchError((error) => {
          if (error.status === 404) {
            this.snykLicenceReportingLevel = "No Snyk data available";
            this.pieLicenceData = {
              labels: [],
              datasets: []
            };
          } else {
            console.error('An error occurred:', error);
            this.snykLicenceReportingLevel = "Unexpected error occured loading Snyk data";
            this.pieLicenceData = {
              labels: [],
              datasets: []
            };
          }
          this.snykLicenceConcernsloading = false;
          return of(null);
        })
      ).subscribe((snykLicenceData) => {
        if (!snykLicenceData) {
          this.snykLicenceConcernsloading = false;
          return;
        }

      this.sourceType = snykLicenceData.productType;
      this.sourceObjectName = snykLicenceData.objectName;
      this.sourceData = snykLicenceData.data;
      this.components = [];
      for (const dataItem of snykLicenceData.data) {
        this.components.push(dataItem.slug ?? "??");
      }
      this.selectedComponent = this.sourceData[0];
      this.selectedSlugName = this.selectedComponent.slug;
      this.hasMultipleComponents = this.components.length > 1;

        this.populateData();

      this.snykLicenceConcernsloading = false;

    });
  }

  toggleWidgetCollapse() {
      this.isCollapsed = !this.isCollapsed;
  }

  updateComponentDropdown(newComponent: string) {
    if (!this.components.includes(newComponent)) {
      this.components.push(newComponent);
    }
  }

  onDropdownChange(event: any) {
    const selectedValue = event.value;
    this.selectedSlugName = selectedValue;

    for (const component of this.sourceData) {
      if (component.slug === selectedValue) {
        this.selectedComponent = component;
        break;
      }
    }

    this.populateData();
  }

  populateData() {

    if (this.selectedComponent.slug !== "") {
      this.snykLicenceLink = "https://app.snyk.io/org/" + this.selectedComponent.slug + "/reporting?v=1&context[page]=issues-detail&issue_status=%255B%2522Open%2522%255D&issue_by=Severity&table_issues_detail_cols=SEVERITY%257CSCORE%257CCVE%257CCWE%257CPROJECT%257CEXPLOIT%2520MATURITY%257CAUTO%2520FIXABLE%257CINTRODUCED%257CSNYK%2520PRODUCT&issue_type=%255B%2522License%2522%255D";
    } else {
      this.snykLicenceLink = "https://app.snyk.io";
    }

    let licenceActionNeeded = this.selectedComponent.severities.critical + this.selectedComponent.severities.high;
    let licenceInvestigation = this.selectedComponent.severities.medium;
    let licenceLow = this.selectedComponent.severities.low;

    const documentStyle = getComputedStyle(document.documentElement);

    this.pieLicenceData = {

      labels: ['Requires Action', 'Requires Investigation', 'Low Concern'],
      datasets: [
        {
          data: [licenceActionNeeded, licenceInvestigation, licenceLow],
          backgroundColor: [
            documentStyle.getPropertyValue('--requiresaction'),
            documentStyle.getPropertyValue('--requiresinvestigation'),
            documentStyle.getPropertyValue('--lowconcern'),
          ],
          hoverBackgroundColor: [
            documentStyle.getPropertyValue('--requiresaction-hover'),
            documentStyle.getPropertyValue('--requiresinvestigation-hover'),
            documentStyle.getPropertyValue('--lowconcern-hover'),
          ],
        },
      ],
    };

    const currentUrl = this.router.url;

    if (licenceActionNeeded !== 0 || licenceInvestigation !== 0 || licenceLow !== 0) {
      this.snykLicenceConcernsAvailable = true;
      if (this.sourceType === 'Product') {
        this.snykLicenceReportingLevel = "";
      } else if (this.sourceType === 'Suite' && currentUrl.includes('/suite/')) {
        this.snykLicenceReportingLevel = "Snyk data not available for this suite";
        this.snykLicenceConcernsAvailable = false;
        return;
      } else {
        this.snykLicenceReportingLevel = "Snyk data not available at this product level. Showing data for parent suite: " + this.sourceObjectName;
      }
    } else {
      this.snykLicenceReportingLevel = "Good news! There are currently no licence concerns";
      this.snykLicenceConcernsAvailable = false;
    }

  }

    getSeverityClass(label: string, baseClass: string): string {
      //remove spaces from Label
      label = label.replace(/\s/g, '');
      //Make label lower case
      label = label.toLowerCase();
      const severityClass = 'pieWidget' + label;
      return `${baseClass} ${severityClass}`;
    }

    resetWidget() {
      this.snykLicenceReportingLevel = '';
      this.snykLicenceConcernsloading = true;
      this.snykLicenceLink = '';
      this.snykLicenceConcernsAvailable = false;
      this.isCollapsed = false;
      this.components = [];
      this.hasMultipleComponents = false;
      this.sourceData = [];
      this.selectedComponent = {};
      this.selectedSlugName = '';
    }
  }
