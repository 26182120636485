import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Message, MessageService } from 'primeng/api';
import { SDLCReport, SDCLReportPractice, SDLCReportExternalPenTest } from 'src/app/api/sdlc-maturity';
import { ProductSDLCService } from 'src/app/layout/service/product/product-sdlc.service';
import { ProductService } from 'src/app/layout/service/product/products.service';
import { ProductMetric } from "src/app/api/product-metric";
import { MeterItem } from 'primeng/metergroup';
import { ProductMetricService } from '../../../service/product/product-metric.service';

@Component({
  selector: 'sdlcmaturityreport',
  templateUrl: './sdlcmaturityreport.component.html',
  styleUrls: ['./sdlcmaturityreport.component.scss']
})

export class SDLCMaturityReport implements OnInit {

  productReports: SDLCReport[] = [];
  filteredReports: SDLCReport[] = [];
  columns: string[] = [];
  products: any[] = [];

  suites: any[] = [];
  divisions: any[] = [];
  tiers: any[] = [];

  selectedTiers: any[] = [];
  selectedDivisions: any[] = [];
  selectedSuites: any[] = [];
  selectedProducts: any[] = [];

  isLoading = true;

  constructor(private route: ActivatedRoute, private productSDLCService: ProductSDLCService, private productService: ProductService, private productMetricService: ProductMetricService, private messageService: MessageService) { }

  ngOnInit() {

    this.route.params.subscribe(params => {

      this.productSDLCService.getSdlcMaturtyReport().subscribe((maturityReports) => {
        if (maturityReports) {

          this.productReports = maturityReports.sort((a, b) => {
            // Helper function to handle undefined or null values by treating them as the highest priority (putting them at the bottom)
            const compareValues = (val1: string | undefined | null, val2: string | undefined | null): number => {
              if (val1 === undefined || val1 === null) return 1;
              if (val2 === undefined || val2 === null) return -1;
              return val1.localeCompare(val2);
            };

            // Compare tierName
            const tierComparison = compareValues(a.tierName, b.tierName);
            if (tierComparison !== 0) return tierComparison;

            // Compare divisionName if tierName is the same
            const divisionComparison = compareValues(a.divisionName, b.divisionName);
            if (divisionComparison !== 0) return divisionComparison;

            // Compare suiteName if divisionName is the same
            const suiteComparison = compareValues(a.suiteName, b.suiteName);
            if (suiteComparison !== 0) return suiteComparison;

            // Compare name if suiteName is the same
            return compareValues(a.name, b.name);
          });

          this.filteredReports = maturityReports;

          //Pull out the names of the practices which are in scope from the first item in the report
          const maturityReport = maturityReports[0];
          maturityReport.productSDLCs?.forEach((productSDLC: SDCLReportPractice) => {
            if (productSDLC.sdlcPracticeName) {
              this.columns.push(productSDLC.sdlcPracticeName);
            }
          });

          this.productService.getAllSuites().subscribe((suites) => {
            this.suites = suites.sort((a, b) => {
              const nameA = a.Name ?? '';
              const nameB = b.Name ?? '';
              return nameA.localeCompare(nameB);
            });
          });
          this.productService.getAllDivisions().subscribe((divisions) => {
            this.divisions = divisions.sort((a, b) => {
              const nameA = a.Name ?? '';
              const nameB = b.Name ?? '';
              return nameA.localeCompare(nameB);
            });
          });
          this.productMetricService.getAllTiers().subscribe((tiers) => {
            this.tiers = tiers;
          });
          this.productService.getAllProducts().subscribe((products) => {
            this.products = products.sort((a, b) => {
              const nameA = a.Name ?? '';
              const nameB = b.Name ?? '';
              return nameA.localeCompare(nameB);
            });
          });



          this.isLoading = false;
        } else {
          this.isLoading = false;
        }
      });
    });


    

  };

  displayProductRef(productRef: string): string {
    return productRef ? "[" + productRef + "] - " : '';
  };

  calculateMaturity(productItem: SDLCReport ) : number {

    let total: number = 0;
    let achieved: number = 0;

    if (productItem.productSDLCs) {
      productItem.productSDLCs.forEach((practice: SDCLReportPractice) => {

        if (practice.status != 'Exempt' && practice.status != 'Not Required') {
          total++;
        }

        if (practice.status == 'Achieving') {
          achieved++;
        }
      });
    } else {
      total = this.columns.length;
    }

    const pt = productItem.externalPentest;

    if (pt) {
      if (pt.status != 'Exempt') {
        total++;
      }

      if (pt.status == 'Achieving' || pt.status == 'In Progress') {
        achieved++;
      }
    } else {
      total++;
    }

    const maturityPct = (achieved / total) * 100;
    return maturityPct;

  }

  getTotalMaturity(): string {

    if (this.filteredReports.length == 0) {
      return "0%";
    }

    let total: number = 0;

    this.filteredReports.forEach((productItem: SDLCReport) => {
      total = total + this.calculateMaturity(productItem);      
    });

    const maturityPct = (total / this.filteredReports.length);
    return maturityPct.toFixed(0) + '%';
  }

  getStatus(practices: SDCLReportPractice[], column: string): string {

    if(practices.length == 0) {
      return 'Not Achieving';
    };

    if(!column) {
      return 'Not Achieving';
    }

    const practice = practices.find(practice => practice.sdlcPracticeName == column);
    if (practice) {
      return practice.status ?? 'Not Achieving';
    } else {
      return 'Not Required';
    }
  }

  getExplanation(practices: SDCLReportPractice[], column: string): string {

    if (practices.length == 0) {
      return '';
    };

    if (!column) {
      return '';
    };

    const practice = practices.find(practice => practice.sdlcPracticeName == column);
    if (practice) {      
      return "Expected Maturity Level: " + (practice.expectedMaturity ?? 'N/A') + ", <br>Actual Maturity Level: " + (practice.actualMaturity ?? 'N/A');
    } else {
      return 'Practice not required for this tier level';
    }
  }

  getPenTestExplanation(penTest: SDLCReportExternalPenTest): string {

    if (!penTest) {
      return 'No Pen Tests';
    } else {
      return "Last Test: " + (penTest.actualMaturity ?? 'N/A') + ", <br>Expected Wait: " + (penTest.minimumMaturityDays ?? 'N/A' + ' days') + ", <br>Next Due: " + (penTest.expectedMaturity ?? 'N/A');
    }

  }  

  getPenTestStatus(penTest: SDLCReportExternalPenTest): string {

    if (!penTest) {
      return 'Not Achieving';
    } else {
      return penTest.status ?? 'Not Achieving';
    }
  }

  getStatusClass(status: string): string {
    switch (status) {
      case 'Achieving':
        return 'status-badge achieved';
      case 'In Progress':
        return 'status-badge achieved';
      case 'Exempt':
        return 'status-badge exempt';
      case 'Not Required':
        return 'status-badge exempt';
      default:
        return 'status-badge not-achieved';
    }
  }

  tagify(option: string | undefined): string {
    if (!option) {
      return '';
    }
    let tagValue = option.trim();
    tagValue = tagValue.replace(/\s+/g, '_');
    tagValue = tagValue.replace(/[^a-zA-Z0-9_]/g, '');
    return tagValue;
  }

  onTiersChange(event: any) {
    this.selectedTiers = event.value; // Update selected tiers
    this.updateFilteredReports();
  }

  onDivisionsChange(event: any) {
    this.selectedDivisions = event.value; // Update selected divisions
    this.updateFilteredReports();
  }

  onSuitesChange(event: any) {
    this.selectedSuites = event.value; // Update selected suites
    this.updateFilteredReports();
  }

  onProductsChange(event: any) {
    this.selectedProducts = event.value; // Update selected products
    this.updateFilteredReports();
  }

  updateFilteredReports() {
    const selectedTierNames = this.selectedTiers.map((tier: any) => tier.Name);
    const selectedDivisionNames = this.selectedDivisions.map((division: any) => division.Name);
    const selectedSuiteNames = this.selectedSuites.map((suite: any) => suite.Name);
    const selectedProductNames = this.selectedProducts.map((product: any) => product.Name);

    this.filteredReports = this.productReports.filter(report =>
      (selectedTierNames.length === 0 || selectedTierNames.includes(report.tierName)) &&
      (selectedDivisionNames.length === 0 || selectedDivisionNames.includes(report.divisionName)) &&
      (selectedSuiteNames.length === 0 || selectedSuiteNames.includes(report.suiteName)) &&
      (selectedProductNames.length === 0 || selectedProductNames.includes(report.name))
    );
  }

  buildCSV() {

    const csvData = [];
    const csvHeader = ['Product', 'Code', 'Tier', 'Division', 'Suite', 'Maturity'];

    this.columns.forEach(column => {
      csvHeader.push(column);
    });

    csvHeader.push('External Pen Test');

    csvData.push(csvHeader);

    this.filteredReports.forEach((productItem: SDLCReport) => {
      const csvRow = [];
      csvRow.push(productItem.name);
      csvRow.push(productItem.productRef);
      csvRow.push(productItem.tierName);
      csvRow.push(productItem.divisionName);
      csvRow.push(productItem.suiteName);
      csvRow.push(this.calculateMaturity(productItem) + '%');

      if (productItem.productSDLCs) {
        productItem.productSDLCs.forEach((practice: SDCLReportPractice) => {
          csvRow.push(practice.status ?? 'Not Achieving');
        });
      } else {
        this.columns.forEach(column => {
          csvRow.push('Not Achieving');
        });
      }

      if (productItem.externalPentest) {
        csvRow.push(productItem.externalPentest.status ?? 'Not Achieving');
      } else {
        csvRow.push('Not Achieving');
      }

      csvData.push(csvRow);
    });

    let csvContent = 'data:text/csv;charset=utf-8,';
    csvData.forEach((row) => {
      csvContent += row.join(',') + '\r\n';
    });

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'sdlc-maturity-report.csv');
    document.body.appendChild(link);
    link.click();


  }

}
