<div id="layout-config" class="layout-config" [ngClass]="{ 'layout-config-active': appMain.configActive }" (click)="appMain.onConfigClick($event)">
  <!-- <a style="cursor: pointer" id="layout-config-button" class="layout-config-button" (click)="onConfigButtonClick($event)">
    <i class="pi pi-chevron-left"></i>
  </a> -->
  <button pButton pRipple type="button" icon="pi pi-times" class="p-button-rounded p-button-danger p-button-text layout-config-close" (click)="onConfigButtonClick($event)"></button>
  <div class="layout-config-content">
    <h6 class="mt-0">Component Scale</h6>
    <div class="config-scale">
      <button pButton pRipple type="button" icon="pi pi-minus" (click)="decrementScale()" class="p-button-rounded p-button-text" [disabled]="scale === scales[0]"></button>
      <i *ngFor="let item of scales" class="pi pi-circle-fill" [ngClass]="{ 'scale-active': item === scale }"></i>
      <button pButton pRipple type="button" icon="pi pi-plus" (click)="incrementScale()" class="p-button-rounded p-button-text" [disabled]="scale === scales[scales.length - 1]"></button>
    </div>

    <h6>Input Style</h6>
    <div class="p-formgroup-inline">
      <div class="field-radiobutton">
        <p-radioButton name="inputStyle" value="outlined" [(ngModel)]="config.inputStyle" (onClick)="onInputStyleChange()" inputId="inputStyle1"></p-radioButton>
        <label for="inputStyle1">Outlined</label>
      </div>
      <div class="field-radiobutton">
        <p-radioButton name="inputStyle" value="filled" [(ngModel)]="config.inputStyle" (onClick)="onInputStyleChange()" inputId="inputStyle2"></p-radioButton>
        <label for="inputStyle2">Filled</label>
      </div>
    </div>

    <h6>Ripple Effect</h6>
    <p-inputSwitch [ngModel]="config.ripple" (onChange)="onRippleChange($event.checked)"></p-inputSwitch>

    <h6>Menu Type</h6>
    <div class="p-formgroup-inline">
      <div class="field-radiobutton">
        <p-radioButton name="menuType" value="static" [(ngModel)]="app.menuMode" (onClick)="appMain.isStatic()" inputId="menuType1"></p-radioButton>
        <label for="menuType1">Static</label>
      </div>
      <div class="field-radiobutton">
        <p-radioButton name="menuType" value="overlay" [(ngModel)]="app.menuMode" (onClick)="appMain.isOverlay()" inputId="menuType2"></p-radioButton>
        <label for="menuType2">Overlay</label>
      </div>

      <h6>PrimeOne Design</h6>
      <div class="grid free-themes">
        <div class="col-3 text-center">
          <button class="p-link" (click)="changeTheme('lara-light-blue', false)">
            <img src="../../../assets/layout/images/themes/lara-light-blue.png" alt="Lara Light Blue" />
          </button>
        </div>
        <div class="col-3 text-center">
          <button class="p-link" (click)="changeTheme('lara-light-indigo', false)">
            <img src="../../../assets/layout/images/themes/lara-light-indigo.png" alt="Lara Light Indigo" />
          </button>
        </div>
        <div class="col-3 text-center">
          <button class="p-link" (click)="changeTheme('lara-light-purple', false)">
            <img src="../../../assets/layout/images/themes/lara-light-purple.png" alt="Lara Light Purple" />
          </button>
        </div>
        <div class="col-3 text-center">
          <button class="p-link" (click)="changeTheme('lara-light-teal', false)">
            <img src="../../../assets/layout/images/themes/lara-light-teal.png" alt="Lara Light Teal" />
          </button>
        </div>
      </div>
      <div class="grid free-themes">
        <div class="col-3 text-center">
          <button class="p-link" (click)="changeTheme('vela-blue', true)">
            <img src="../../../assets/layout/images/themes/vela-blue.png" alt="Vela Blue" />
          </button>
        </div>
        <div class="col-3 text-center">
          <button class="p-link" (click)="changeTheme('vela-green', true)">
            <img src="../../../assets/layout/images/themes/vela-green.png" alt="Vela Green" />
          </button>
        </div>
        <div class="col-3 text-center">
          <button class="p-link" (click)="changeTheme('vela-orange', true)">
            <img src="../../../assets/layout/images/themes/vela-orange.png" alt="Vela Orange" />
          </button>
        </div>
        <div class="col-3 text-center">
          <button class="p-link" (click)="changeTheme('vela-purple', true)">
            <img src="../../../assets/layout/images/themes/vela-purple.png" alt="Vela Purple" />
          </button>
        </div>
        <div class="col-3 text-center">
          <button class="p-link" (click)="changeTheme('arya-blue', true)">
            <img src="../../../assets/layout/images/themes/arya-blue.png" alt="Arya Blue" />
          </button>
        </div>
        <div class="col-3 text-center">
          <button class="p-link" (click)="changeTheme('arya-green', true)">
            <img src="../../../assets/layout/images/themes/arya-green.png" alt="Arya Green" />
          </button>
        </div>
        <div class="col-3 text-center">
          <button class="p-link" (click)="changeTheme('arya-orange', true)">
            <img src="../../../assets/layout/images/themes/arya-orange.png" alt="Arya Orange" />
          </button>
        </div>
        <div class="col-3 text-center">
          <button class="p-link" (click)="changeTheme('arya-purple', true)">
            <img src="../../../assets/layout/images/themes/arya-purple.png" alt="Arya Purple" />
          </button>
        </div>
      </div>

      <h6>Luna</h6>
      <div class="grid free-themes">
        <div class="col-3 text-center">
          <button class="p-link" (click)="changeTheme('luna-amber', true)">
            <img src="../../../assets/layout/images/themes/luna-amber.png" alt="Luna Amber" />
          </button>
        </div>
        <div class="col-3 text-center">
          <button class="p-link" (click)="changeTheme('luna-blue', true)">
            <img src="../../../assets/layout/images/themes/luna-blue.png" alt="Luna Blue" />
          </button>
        </div>
        <div class="col-3 text-center">
          <button class="p-link" (click)="changeTheme('luna-green', true)">
            <img src="../../../assets/layout/images/themes/luna-green.png" alt="Luna Green" />
          </button>
        </div>
        <div class="col-3 text-center">
          <button class="p-link" (click)="changeTheme('luna-pink', true)">
            <img src="../../../assets/layout/images/themes/luna-pink.png" alt="Luna Pink" />
          </button>
        </div>
    </div>
  </div>
</div>
